import { connect } from 'react-redux';
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthSupportProps } from 'contracts/auth';
import { withAuth } from 'services/AuthProvider';
import { PermissionAuth0 } from 'models/Permissions';
import { GenericPropsType, Store } from 'store/storeTypes';
import { appUserToRoles } from 'selectors/uiSelectors';
import { defaultAppPath } from 'contracts/constants';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';

interface PrivateRouteProps extends AuthSupportProps, RouteProps {
  allowedRoles?: PermissionAuth0[];
  appUserRolesFromStore: PermissionAuth0[];
}

const mapStateToProps = (state: Store) => ({
  appUserRolesFromStore: appUserToRoles(state),
});

const ProtectedRoute = ({
  component: Component,
  render,
  authService,
  appUserRolesFromStore,
  allowedRoles,
  ...rest
}: PrivateRouteProps) => {
  const appUserRoles = appUserRolesFromStore.length
    ? appUserRolesFromStore
    : (authService.getUserRoles() as PermissionAuth0[]);

  const protectedRender = (props: GenericPropsType) => {
    if (authService.isAuthenticated()) {
      KonnectAnalytics.trackHistory(props.location);
      if (allowedRoles) {
        const userAllowedAccess =
          appUserRoles && allowedRoles.some(role => appUserRoles.includes(role));

        if (!userAllowedAccess) {
          return <Redirect to={defaultAppPath} />;
        }
      }
      // render prop is stronger than component prop
      if (render) return render(props);
      if (Component) return <Component {...props} />;
    } else {
      return <Redirect to={defaultAppPath} />;
    }

    return null;
  };

  return <Route {...rest} render={protectedRender} />;
};

export default connect(mapStateToProps)(withAuth(ProtectedRoute));
