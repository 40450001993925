export enum Permission {
  Admin = 1,
  CreateReports = 2,
  SetGoals = 4,
  ManageApi = 5,
  Certificate = 6,
  ReadOnly = 7,
}

export type Permissions = Permission[];

export enum PermissionAuth0 {
  Admin = 'admin',
  GoalsSetter = 'goals-setter',
  Scheduler = 'email-scheduler',
  ManageApi = 'api-user',
  Certificate = 'certificate-enabled',
  ReadOnly = 'read-only',
}
