import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { UserSettings, UserSystemGroupStrings } from 'store/storeTypes';
import { authService } from 'services/AuthProvider';
import { makeApiUrl } from 'actions/_shared/utils';
import apiAction, { apiActionSimple } from 'actions/_shared/apiAction';
import { setSystemGroup } from './userUiActions';
import handleErrorAction from './_shared/handleErrorAction';
import { getQueryParams } from 'helpers/navigation';

export const loadAppUserSettingsStarted = createStandardAction('GET_APP_USER_SETTINGS_STARTED')();
export const loadAppUserSettingsSuccess = createStandardAction('GET_APP_USER_SETTINGS_SUCCESS')<
  UserSettings
>();
export const loadAppUserSettingsFailed = createStandardAction('GET_APP_USER_SETTINGS_FAILED')<
  any
>();

export function getAppUserSettings() {
  const { isAuthenticated, idTokenPayload } = authService;

  const url = makeApiUrl('/api/users/user-settings');
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: { url },
      logic: {
        onStarted: (dispatch, state) => dispatch(loadAppUserSettingsStarted()),
        onSuccess: (data, dispatch, state) => {
          const selectedSystemGroupFromUri = getQueryParams().get(
            'selectedSystemGroup'
          ) as UserSystemGroupStrings;
          dispatch(loadAppUserSettingsSuccess(data));
          dispatch(setSystemGroup(selectedSystemGroupFromUri || data.defaultSystemGroup));
        },

        onFailed: (error, dispatch) => {
          dispatch(handleErrorAction(error, `API: ${error.message}`, [loadAppUserSettingsFailed]));
        },
      },
    });
  }
}

export const setAppUserSettingsAC = createAsyncAction(
  'SET_APP_USER_SETTINGS_STARTED',
  'SET_APP_USER_SETTINGS_SUCCESS',
  'SET_APP_USER_SETTINGS_FAILED'
)<UserSettings, Record<string, unknown>, Error>();

export function setAppUserSettings(data: UserSettings) {
  const { isAuthenticated, idTokenPayload } = authService;
  const { userSystemGroup, ...postData } = data;
  const url = makeApiUrl('/api/users/user-settings');
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiActionSimple({
      request: {
        url,
        method: 'POST',
        data: postData,
      },
      actions: [
        setAppUserSettingsAC.request,
        setAppUserSettingsAC.success,
        setAppUserSettingsAC.failure,
      ],
    });
  }
}
