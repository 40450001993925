import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { ReportTypesNew } from 'models/ReportData';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MobileDrawerLogo } from '../../assets/icons/MobileDrawerLogo.svg';
import MobileShellReportsNavItem from './MobileShellReportsNavItem';
import { NavlinkType } from './ShellReportsNav';

const MobileNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`;
const LogoPlaceHolder = styled.div`
  padding: 15px;
  margin-bottom: 35px;
`;

type MobileShellReportsNavProps = {
  activeReport: ReportTypesNew;
  currentReport: string;
  navLinks: Array<NavlinkType>;
  setIsOpen: (isOpen: boolean) => void;
};
const MobileShellReportsNav = (props: MobileShellReportsNavProps) => {
  const { navLinks, currentReport, activeReport, setIsOpen } = props;

  return (
    <MobileNavContainer data-automationid={`mobile_navigation`}>
      <LogoPlaceHolder>
        <MobileDrawerLogo />
      </LogoPlaceHolder>

      {navLinks.map((link, i) => {
        return (
          <MobileShellReportsNavItem
            activeReport={activeReport}
            key={link.path + i}
            link={link}
            currentReport={currentReport}
            setIsOpen={setIsOpen}
          />
        );
      })}
    </MobileNavContainer>
  );
};
export default MobileShellReportsNav;
