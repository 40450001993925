import config from 'config/config';

export const defaultAppPath = '/';
export const authCallbackPath = '/callback';
export const logoutPath = '/logout';
export const unsubscribePath = '/unsubscribe';
export const superUserLogin = '/superUser';

export const userManagementPath = '/users';
export const apiManagementPath = '/api';
export const goalsPath = '/goals';
export const schedulerPath = '/scheduler';
export const settingsPath = '/settings';
export const configurationPath = '/config.js';
export const privacyPolicyPath = '/documents/privacy-policy';
export const termsOfUsePath = '/documents/terms-of-use';
export const productTCPath = '/documents/product-tc';

export const reportsPath = '/reports/:reportType/';
export const overviewReportPath = '/reports/overview';
export const jobsReportPath = '/reports/jobs';
export const systemsReportPath = '/reports/systems';
export const systemsSubReportsPath = '/reports/systems/:subReportType/:systemName';
export const documentViewerPath = '/documents/:docName';
export const authCallbackSuffixes = {
  access_token: 'access_token',
  id_token: 'id_token',
  error: 'error',
};

export const authCallbacksRegex = new RegExp(Object.values(authCallbackSuffixes).join('|'));

export const AUTH0_CLAIMS_NAMESPACE = config.AUTH0_CLAIMS_NAMESPACE;
export const USER_ROLES = {
  USER: 'user',
  ADMIN: 'admin',
  ENROLLMENT: 'enrollment',
};
const blobBaseUrl = config.BLOB_URL;

export const METRICS_GLOSSARY_DTF_PDF_LINK = `${blobBaseUrl}/dynamic-in-app-docs/DTF_Konnect_Glossary.pdf`;
export const METRICS_GLOSSARY_DTG_PDF_LINK = `${blobBaseUrl}/dynamic-in-app-docs/DTG_Konnect_Glossary.pdf`;

export const PRIVACY_POLICY_PDF = `${blobBaseUrl}/dynamic-in-app-docs/Kornit-PrivacyPolicy.pdf`;
export const PRODUCT_TC_PDF = `${blobBaseUrl}/dynamic-in-app-docs/Kornit-Product-Use.pdf`;
export const TERMS_OF_USE_PDF = `${blobBaseUrl}/dynamic-in-app-docs/KonnectwebsiteTermsofUse.pdf`;
export const API_PDF = `${blobBaseUrl}/dynamic-in-app-docs/Data_API.pdf`;

export const KONNECT_TRAINING_LINK_DTG = 'https://www.kornit.com/lps/konnect/#/';
export const KONNECT_TRAINING_LINK_DTF = 'https://kornit.com/lps/konnect/dtf/#/';
