import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { jobsReportPath, overviewReportPath, systemsReportPath } from 'contracts/constants';

import DesktopShellReportsNav from './DesktopShellReportsNav';
import MobileShellReportsNav from './MobileShellReportsNav';
import { ReportTypesNew } from 'models/ReportData';
import config from 'config/config';

export type ShellReportsNavProps = {
  onNavClick?: () => {};
  userEmail?: string;
  tennantName?: string;
  importedTennantId?: string;
  isMobile?: boolean;
  activeReport?: ReportTypesNew;
  setIsOpen?: (isOpen: boolean) => void;
};
export type NavlinkType = {
  text: string;
  path: string;
  image: string;
};
const ShellReportsNav = memo((props: ShellReportsNavProps) => {
  const { isMobile, activeReport, userEmail, setIsOpen } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const currentReport = history.location.pathname;
  const navLinks: Array<NavlinkType> = [
    {
      text: t('reportsNav.overview'),
      path: overviewReportPath,
      image: 'menu-1-overview.svg',
    },
    {
      text: t('reportsNav.systemsAnalysis'),
      path: systemsReportPath,
      image: 'menu-2-sys-analysis.svg',
    },
    {
      text: t('reportsNav.jobsAnalysis'),
      path: jobsReportPath,
      image: 'menu-3-jobs-analysis.svg',
    },
  ];

  return isMobile ? (
    <MobileShellReportsNav
      activeReport={activeReport}
      currentReport={currentReport}
      navLinks={navLinks}
      setIsOpen={setIsOpen}
    />
  ) : (
    <DesktopShellReportsNav currentReport={currentReport} navLinks={navLinks} />
  );
});

export default ShellReportsNav;
