import configDev from './configsPerEnv/config.dev';
import configTest from './configsPerEnv/config.tst';
import configTestDebug from './configsPerEnv/config.tstDebug';
import configStage from './configsPerEnv/config.stg';
import configProd from './configsPerEnv/config.prod';
import configLocal from './configsPerEnv/config.local';
import configDvps from './configsPerEnv/config.dvps';
import configMobileProdIos from './configsPerEnv/config.mobileProdIos';
import configMobileProdAndroid from './configsPerEnv/config.mobileProdAndroid';
import { GenericPropsType } from 'store/storeTypes';
import configMobileDevIos from './configsPerEnv/config.MobileDevIos';
import configMobileDevAndroid from './configsPerEnv/config.mobileDevAndroid';
import configAuto from './configsPerEnv/config.auto';
export interface config {
  AUTH_CONFIG_STR: auth0Config;
  API_ROUTER_HOST: string;
  AUTH0_CLAIMS_NAMESPACE: string;
  SELF_HOST: string;
  USER_LOGOUT_TIMEOUT: number;
  WEEK_START: number;
  FILTER_HIDE: boolean;
  USER_AGREEMENT_VERSION: number;
  SHOW_PBI_REPORT_PAGES_PANE: boolean;
  SHOW_PBI_REPORT_FILTERS_PANE: boolean;
  REPORTS_REFRESH_TIMEOUT_SECONDS: number;
  MAX_LOAD_ATTEMPTS: number;
  SPLIT_WEEK_BY_END_OF_YEAR: boolean;
  ENV_NAME: string;
  SHOW_VERSION_VERSION_LABEL: boolean;
  GENERATE_SOURCEMAP: boolean;
  LOGGER_INSTRUMENTAION_KEY: string;
  [key: string]: GenericPropsType;
  REFRESH_API_INTERVAL: number;
  PLATFORM: string;
  BLOB_URL: string;
  API_URL: string;
  RECAPTCHA_KEY: string;
  REAL_TIME_USER_LIST: Array<string>;
  MIXPANEL_KEY?: string;
}
interface auth0Config {
  domain: string;
  clientID: string;
  redirectUri: string;
  audience: string;
  returnTo: string;
  state?: string;
  protocol?: string;
  response_type?: string;
  scope?: string;
  nonce?: string;
  auth0Client?: string;
}

//@ts-ignore
// const env = 'testDebug';
const env = (window.config?.env as string) || 'local';
const determineConfig = (env: string) => {
  switch (env) {
    case 'local':
      return configLocal;
    case 'dev':
      return configDev;
    case 'test':
      return configTest;
    case 'testDebug':
      return configTestDebug;
    case 'stage':
      return configStage;
    case 'prod':
      return configProd;
    case 'dvps':
      return configDvps;
    case 'prod-ios':
      return configMobileProdIos;
    case 'test-ios':
      return configMobileDevIos;
    case 'prod-android':
      return configMobileProdAndroid;
    case 'test-android':
      return configMobileDevAndroid;
    case 'configCustomAutomation':
      return {
        AUTH_CONFIG_STR: {
          domain: window.domain.domain,
          clientID: window.clientID.clientID,
          redirectUri: window.redirectUri.redirectUri,
          audience: window.audience.audience,
          returnTo: window.returnTo.returnTo,
        },
        API_ROUTER_HOST: window.API_ROUTER_HOST.API_ROUTER_HOST,
        AUTH0_CLAIMS_NAMESPACE: window.AUTH0_CLAIMS_NAMESPACE.AUTH0_CLAIMS_NAMESPACE,
        SELF_HOST: window.SELF_HOST.SELF_HOST,
        USER_LOGOUT_TIMEOUT: window.USER_LOGOUT_TIMEOUT.USER_LOGOUT_TIMEOUT,
        USER_AGREEMENT_VERSION: window.USER_AGREEMENT_VERSION.USER_AGREEMENT_VERSION,
        WEEK_START: window.WEEK_START.WEEK_START,
        FILTER_HIDE: window.FILTER_HIDE.FILTER_HIDE,
        SHOW_PBI_REPORT_PAGES_PANE: window.SHOW_PBI_REPORT_PAGES_PANE.SHOW_PBI_REPORT_PAGES_PANE,
        SHOW_PBI_REPORT_FILTERS_PANE:
          window.SHOW_PBI_REPORT_FILTERS_PANE.SHOW_PBI_REPORT_FILTERS_PANE,
        REPORTS_REFRESH_TIMEOUT_SECONDS:
          window.REPORTS_REFRESH_TIMEOUT_SECONDS.REPORTS_REFRESH_TIMEOUT_SECONDS,
        MAX_LOAD_ATTEMPTS: window.MAX_LOAD_ATTEMPTS.MAX_LOAD_ATTEMPTS,
        SPLIT_WEEK_BY_END_OF_YEAR: window.SPLIT_WEEK_BY_END_OF_YEAR.SPLIT_WEEK_BY_END_OF_YEAR,
        ENV_NAME: 'CustomAutomation',
        PLATFORM: window.PLATFORM.PLATFORM,
        SHOW_VERSION_VERSION_LABEL: window.SHOW_VERSION_VERSION_LABEL.SHOW_VERSION_VERSION_LABEL,
        GENERATE_SOURCEMAP: window.GENERATE_SOURCEMAP.GENERATE_SOURCEMAP,
        LOGGER_INSTRUMENTAION_KEY: window.LOGGER_INSTRUMENTAION_KEY.LOGGER_INSTRUMENTAION_KEY,
        REFRESH_API_INTERVAL: window.REFRESH_API_INTERVAL.REFRESH_API_INTERVAL,
        UNSUBSCRIBE_END_POINT: window.UNSUBSCRIBE_END_POINT.UNSUBSCRIBE_END_POINT,
        SERVICE_TICKET_END_POINT: window.SERVICE_TICKET_END_POINT.SERVICE_TICKET_END_POINT,
        BLOB_URL: window.BLOB_URL.BLOB_URL,
        API_URL: window.API_URL.API_URL,
        RECAPTCHA_KEY: window.RECAPTCHA_KEY.RECAPTCHA_KEY,
        MIXPANEL_KEY: 'e361850fceb820d591e0cc1d6b1732cf',
      };
    case 'auto':
      return configAuto;
    default:
      return configLocal;
  }
};
export default determineConfig(env);
