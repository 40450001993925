import { createSelector } from 'reselect';
import { Reducer, combineReducers } from 'redux';

import userFormReducer from './userFormUiReducer';
import userModalReducer from './userModalUiReducer';
import userTableReducer from './userTableUiReducer';
import userSearchReducer from './userSearchUiReducer';
import notificationQueueReducer from './notificationQueueUiReducer';
import schedulerModalUiReducer from './schedulerModalUiReducer';
import schedulerFormUiReducer from './schedulerFormUiReducer';
import logoutTimerUiReducer from './logoutTimerUiReducer';
import schedulerToggleUiReducer from './schedulerToggleUiReducer';
import schedulerPreviewUiReducer from './schedulerPreviewUiReducer';
import FilterModalReducer from './filterModalUiReducer';
import systemGroupUiReducer from './systemGroupUiReducer';
import systemAnalysisViewsReducer from './systemAnalysisViewsReducer';
import overviewUiReducer from './overviewUiReducer';
import machineFilter from './machineFilterReducer';
import { Store } from 'store/storeTypes';
import captchaReducer from './captchaReducer';

export const uiReducer: Reducer = combineReducers({
  notificationQueue: notificationQueueReducer,
  selectedUserRows: userTableReducer,
  userModal: userModalReducer,
  userForm: userFormReducer,
  userSearch: userSearchReducer,
  schedulerModal: schedulerModalUiReducer,
  schedulerForm: schedulerFormUiReducer,
  schedulerPreview: schedulerPreviewUiReducer,
  logoutTimer: logoutTimerUiReducer,
  toggleSchedulerId: schedulerToggleUiReducer,
  filterCalenderModal: FilterModalReducer,
  selectedSystemGroup: systemGroupUiReducer,
  systemAnalysisView: systemAnalysisViewsReducer,
  overview: overviewUiReducer,
  machineFilter: machineFilter,
  captcha: captchaReducer,
});

export default uiReducer;

const uiState = (state: Store) => {
  return state.ui;
};

export const machineFilterSelector = createSelector([uiState], ui => {
  return ui['machineFilter'];
});
export const isMachineFilterOpenSelector = createSelector(
  [machineFilterSelector],
  machineFilter => {
    return machineFilter['isOpen'];
  }
);
export const filterCalenderModalSelector = createSelector([uiState], ui => {
  return ui['filterCalenderModal'];
});
