import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import MuiSearchIcon from '@material-ui/icons/Search';
import MuiInputBase from '@material-ui/core/InputBase';
import MuiPaper from '@material-ui/core/Paper';

const Paper = styled(MuiPaper)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 2px 11px 0 21px;
  margin-right: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.195121);
  border: 1px solid #c5d0de;
  border-radius: 4px;
  max-height: 30px;
  width: 222px;
`;

const InputBase = styled(MuiInputBase)`
  flex-grow: 1;
  input {
    font-family: Rubik, sans-serif;
    font-size: 12px;

    &::placeholder {
      color: #7f8fa4;
    }
  }
`;

const SearchIcon = styled(MuiSearchIcon)`
  color: #7f8fa4;
  width: 18px;
  height: 18px;
`;

interface SearchBarProps {
  onChange: (newValue: string) => void;
  className?: string;
}

function SearchBar(props: SearchBarProps) {
  const { t } = useTranslation();
  const { onChange, ...rest } = props;

  const handleChange = (e: React.SyntheticEvent) => {
    onChange((e.target as HTMLInputElement).value);
  };

  return (
    <Paper {...rest}>
      <InputBase placeholder={t('search')} onChange={handleChange} />
      <SearchIcon />
    </Paper>
  );
}

export default SearchBar;
