import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { format, isSameDay } from 'date-fns';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconMoreVert from '@material-ui/icons/MoreVert';

import { Frequency } from 'models/Scheduler';
import {
  TableCell as TableCellGeneric,
  TableRow as TableRowGeneric,
  ButtonsTableCell as ButtonsTableCellGeneric,
  TableIconButton,
} from '../_common/Table';
import Switch from '../_common/Switch';
import PopupMenu from '../_common/PopupMenu';
import { SchedulerTableRowProps } from './SchedulerTableRowContainer';
import CircularSpinner from '../_common/CircularSpinner';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const TableRow = styled(TableRowGeneric).attrs({
  'data-automationid': 'Email Scheduler Table',
})`
  &.sending {
    td {
      color: #354052;
    }
  }
`;
const TableCell = styled(TableCellGeneric)`
  font-size: 14px;
  color: #c5d0de;
`;
const SubjectCell = styled<StyledComponentWithAnyType>(TableCell)`
  width: 270px;
  min-width: 220px;
  @media (max-width: 1500px) {
    width: 190px;
    min-width: 190px;
  }
  @media (max-width: 500px) {
    width: 135px;
    min-width: 135px;
  }
  @media (max-width: 360px) {
    width: 115px;
    min-width: 115px;
  }
`;
const ReportCell = styled<StyledComponentWithAnyType>(TableCell)`
  width: 125px;
  min-width: 125px;
  @media (max-width: 1500px) {
    width: 110px;
    min-width: 110px;
  }
  @media (max-width: 500px) {
    min-width: 85px;
    width: 85px;
  }
`;
const TimeCell = styled<StyledComponentWithAnyType>(TableCell)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 26px;
  width: 240px;
  min-width: 240px;
  @media (max-width: 1500px) {
    width: 200px;
    min-width: 200px;
  }
  @media (max-width: 800px) {
    flex-grow: 1;
  }
  @media (max-width: 550px) {
    min-width: 80px;
    width: auto;
  }
  @media (max-width: 400px) {
    line-height: 16px;

    .date {
      font-size: 11px;
    }

    div:first-child {
      margin-bottom: 7px;
    }
  }
  b {
    font-weight: 500;
  }
`;
const RepeatCell = styled<StyledComponentWithAnyType>(TableCell)`
  width: 450px;
  min-width: 150px;
  @media (max-width: 1500px) {
    width: 130px;
    min-width: 130px;
  }
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 1150px) {
    flex-grow: 1;
  }
`;
const LastSentCell = styled<StyledComponentWithAnyType>(TableCell)`
  width: 135px;
  max-width: 135px;
  @media (max-width: 1500px) {
    width: 120px;
    min-width: 120px;
  }
  @media (max-width: 1250px) {
    display: none;
  }
`;
const NotesCell = styled<StyledComponentWithAnyType>(TableCell)`
  flex-grow: 1;
  font-size: 12px;
  @media (max-width: 1150px) {
    display: none;
  }
`;
const SendingCell = styled<StyledComponentWithAnyType>(TableCell)`
  min-width: 130px;
  width: 130px;
  padding: 0;
  @media (max-width: 670px) {
    display: none;
  }
`;
const ButtonsTableCell = styled(ButtonsTableCellGeneric)`
  @media (max-width: 930px) {
    display: none;
  }
`;

const SchedulerTableRow = React.memo((props: SchedulerTableRowProps) => {
  const { t } = useTranslation();
  const { scheduler, isSaving, toggleSchedulerId } = props;
  const {
    toggleSchedulerSending,
    deleteScheduler,
    openModalSchedulerEdit,
    openModalSchedulerPreview,
    openModalSchedulerDuplicate,
  } = props;

  const {
    reportId,
    subject,
    emailReportId,
    startSendDate,
    endSendDate,
    repeat,
    lastSent,
    notes,
    isActive: sending,
  } = scheduler;

  const onToggleChecked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.stopPropagation();
    toggleSchedulerSending(reportId!, checked);
  };

  const onOpenModalPreview = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    openModalSchedulerPreview(reportId!);
  };

  const onOpenModalEdit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    openModalSchedulerEdit(reportId!);
  };

  const onDeleteScheduler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    deleteScheduler(reportId!);
  };

  const onOpenModalDuplicate = (event: React.SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    openModalSchedulerDuplicate(reportId!);
  };

  return (
    <TableRow
      className={sending ? 'sending' : ''}
      // editing modal disabled
      //  onClick={onOpenModalPreview}
    >
      <SubjectCell>{subject}</SubjectCell>

      <ReportCell>{t('schedulerTable.reportTypes')[+emailReportId]}</ReportCell>

      <TimeCell>
        <div>
          {t('schedulerTable.start')}
          <span className="hideOnSmall"> {t('schedulerTable.dateL')}</span>:{' '}
          {isSameDay(new Date(startSendDate).getTime(), Date.now()) ? (
            <span>{t('schedulerTable.today')}</span>
          ) : (
            <span className="date">{format(new Date(startSendDate), 'dd/MM/yyyy')}</span>
          )}
        </div>
        <div>
          {t('schedulerTable.end')}
          <span className="hideOnSmall"> {t('schedulerTable.dateL')}</span>:{' '}
          {endSendDate ? (
            <span className="date">{format(new Date(endSendDate), 'dd/MM/yyyy')}</span>
          ) : (
            <b>{t('schedulerTable.never')}</b>
          )}
        </div>
      </TimeCell>

      <RepeatCell>
        {t('schedulerTable.repeatTypes')[repeat.frequency]}{' '}
        {repeat.frequency === Frequency.Weekly &&
          repeat.dayOfWeek &&
          ` ${t('schedulerTable.on')}${' '} ${repeat.dayOfWeek
            .map((w: string | number) => t('schedulerTable.weekdays')[w])
            .join(', ')}`}
      </RepeatCell>

      <LastSentCell>
        {lastSent ? format(new Date(lastSent), 'dd/MM/yyyy') : <b>{t('schedulerTable.never')}</b>}
      </LastSentCell>

      <NotesCell>{notes && notes.length > 40 ? `${notes.substring(0, 40)}...` : notes}</NotesCell>

      <SendingCell>
        <Switch
          checked={sending}
          onChange={onToggleChecked}
          justify="center"
          onClick={e => e.stopPropagation()}
        />
        {isSaving && toggleSchedulerId === reportId && <CircularSpinner />}
      </SendingCell>
      {/* buttons disabled */}
      <ButtonsTableCell>
        {/* <TableIconButton onClick={onOpenModalEdit}>
          <EditIcon />
        </TableIconButton>

        <TableIconButton onClick={onDeleteScheduler}>
          <DeleteIcon />
        </TableIconButton> */}

        <div onClick={e => e.stopPropagation()}>
          <PopupMenu
            menuItems={[
              {
                text: t('usersTable.duplicate'),
                onClick: onOpenModalDuplicate,
              },
            ]}>
            {/* three dots disabled */}
            {/* <TableIconButton disableRipple>
              <IconMoreVert style={{ color: '#7F8FA4' }} />
            </TableIconButton> */}
          </PopupMenu>
        </div>
      </ButtonsTableCell>
    </TableRow>
  );
});

export default SchedulerTableRow;
