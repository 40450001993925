import { globalColors } from 'globalStyle';
import {
  endOfQuarter,
  format,
  parse,
  parseISO,
  isSameDay,
  startOfWeek,
  startOfMonth,
  startOfDay,
  endOfMonth,
  startOfQuarter,
  startOfYear,
  endOfYear,
  isWithinInterval,
  endOfWeek,
  isValid,
  isSameYear,
  addYears,
} from 'date-fns';
import config from 'config/config';
import { Store, UserSystemGroup } from 'store/storeTypes';
import { TimeFrame } from 'models/ReportData';
import { ReportDateRequest } from 'models/ReportsFilterData';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';

export {
  format,
  parse,
  parseISO,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  isWithinInterval,
};

export const convertDateToString = (date: Date): string => {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

export function getCurrentIsoDateTimeNoMilliAndOffset() {
  const date = new Date(Date.now());
  const timeString = `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}`;

  const timezoneOffset = getTimeOffset();

  return { timeString, timezoneOffset };
}

export function getTimeOffset(date: Date = new Date(Date.now())) {
  return parseFloat(((date.getTimezoneOffset() / 60) * -1).toFixed(1));
}

export const isHistorical = (timeframe: { day?: Date | null; lastUpdate?: Date }): boolean => {
  const { day, lastUpdate } = timeframe;
  const currentDate = lastUpdate ? new Date(lastUpdate.getTime()) : new Date();
  currentDate.setHours(0, 0, 0, 0);
  if (day) {
    return day < currentDate;
  }
  return false;
};

export const apiRefreshInterval = (
  period: { day: Date; timeframe: TimeFrame },
  callback: () => void
) => {
  if (isHistorical(period)) {
    return () => {};
  } else {
    const refreshData = setInterval(callback, config.REFRESH_API_INTERVAL);
    return () => clearInterval(refreshData);
  }
};

export const isToday = (state: Store): boolean => {
  return !isHistorical({
    day: new Date(
      state.reports.filterState[state.ui.selectedSystemGroup]!.selectedDate || '01.01.1990'
    ),
    lastUpdate: state.reports.filterData[state.ui.selectedSystemGroup]!.endDate || new Date(),
  });
};

export const getDayForDate = (date: Date): { startDate: Date; endDate: Date; title: string } => {
  const startDate = new Date(date.getTime());
  const endDate = new Date(date.getTime());
  const title = format(startDate, 'EEE MMMM d yyyy');
  return { startDate, endDate, title };
};

export const getPeriodForCustom = (date: Date) => {
  const startDate = new Date(date.getTime());
  const endDate = new Date(date.getTime());
  const title = format(startDate, 'EEE MMMM d yyyy');
  return { startDate, endDate, title };
};

export const getDayForDateMobile = (
  date: Date
): { startDate: Date; endDate: Date; title: string } => {
  const startDate = new Date(date.getTime());
  const endDate = new Date(date.getTime());
  const title = format(startDate, 'MMM dd yyyy');
  return { startDate, endDate, title };
};
export const getWeekForDate = (date: Date): { startDate: Date; endDate: Date; title: string } => {
  if (!date) {
    return;
  }
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const endDate = endOfWeek(date, { weekStartsOn: 1 });
  let title;
  if (isSameYear(startDate, endDate)) {
    //*regular case
    title = `${format(startDate, 'MMMM d')} - ${format(endDate, 'MMMM d , yyyy')}`;
  } else {
    //*the case of last week in the year. we need to keep prev year prefix for requests in this case.
    const endDateMinusYear = addYears(endDate, -1);
    title = `${format(startDate, 'MMMM d')} - ${format(endDateMinusYear, 'MMMM d , yyyy')}`;
  }
  return { startDate, endDate, title };
};

export const getMonthForDate = (date: Date): { startDate: Date; endDate: Date; title: string } => {
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  const title = format(startDate, 'MMMM, yyyy');
  return { startDate, endDate, title };
};

export const getQuarterForDate = (
  date: Date
): { startDate: Date; endDate: Date; title: string } => {
  const startDate = startOfQuarter(date);
  const endDate = endOfQuarter(date);
  const title = `Q${format(startDate, 'Q, yyyy')}`;
  return { startDate, endDate, title };
};

export const getYearForDate = (date: Date): { startDate: Date; endDate: Date; title: string } => {
  const startDate = startOfYear(date);
  const endDate = endOfYear(date);
  const title = format(startDate, 'yyyy');
  return { startDate, endDate, title };
};

export const generateDatesForPeriod = (
  startDate: Date,
  endDate: Date,
  periodIncrementFn: (date: Date, step: number) => Date,
  startOfPeriodFn: (date: Date) => Date
): Date[] => {
  if (!startDate || !endDate) {
    return;
  }
  const result: Date[] = [];
  const startRoundDate = new Date(startDate.getTime());
  const endRoundDate = new Date(endDate.getTime());
  startRoundDate.setHours(0, 0, 0, 0);
  endRoundDate.setHours(0, 0, 0, 0);
  for (
    let startYearDate = startOfPeriodFn(startRoundDate);
    startYearDate <= startOfPeriodFn(endRoundDate);
    startYearDate = periodIncrementFn(startYearDate, 1)
  ) {
    result.push(startYearDate);
  }
  return result;
};

export const getDateForTimeframe = (date: Date, timeframe: TimeFrame): Date => {
  switch (timeframe) {
    case 'day':
      return getDayForDate(date).startDate;
    case 'week':
      return getWeekForDate(date).startDate;
    case 'month':
      return getMonthForDate(date).startDate;
    case 'quarter':
      return getQuarterForDate(date).startDate;
    case 'year':
      return getYearForDate(date).startDate;
    case 'custom':
      return getPeriodForCustom(date).startDate;
    default:
      return getDayForDate(date).startDate;
  }
};

export const getRequestForTimeFrame = (
  date: Date,
  timeFrame: TimeFrame,
  customTimeframeProperties?: CustomTimeFrameProperties
) => {
  switch (timeFrame) {
    case 'custom':
      return {
        custom: {
          customTimeFrameStartDate: customTimeframeProperties.customTimeFrameStartDate,
          customTimeFrameEndDate: customTimeframeProperties.customTimeFrameEndDate,
          customTimeFrameStartHour: customTimeframeProperties?.customTimeFrameStartHour || 0,
          customTimeFrameEndHour: customTimeframeProperties?.customTimeFrameEndHour || 23,
        },
      };
    case 'day':
      return { day: getDayForDate(date).title };
    case 'week':
      return { week: getWeekForDate(date).title };
    case 'month':
      return { month: getMonthForDate(date).title };
    case 'quarter':
      return { quarter: getQuarterForDate(date).title };
    case 'year':
      return { year: getYearForDate(date).title };
    default:
      return { day: getDayForDate(date).title };
  }
};

export const getDaysHoursAndMinutesFromSeconds = (
  seconds: number,
  timeframe: TimeFrame,
  systemGroup: UserSystemGroup
) => {
  const mins = seconds / 60;
  if (timeframe === 'year' && systemGroup === 'DTG') {
    const days =
      Math.floor(mins / 60 / 24) < 10
        ? `0${Math.floor(mins / 60 / 24)}`
        : `${Math.floor(mins / 60 / 24)}`;
    const daysToMinutes = Number(days) * 24 * 60;
    const rest = mins - daysToMinutes;
    const hrs = rest / 60 < 10 ? `0${Math.floor(rest / 60)}` : Math.floor(rest / 60);
    const minsInHrs = Number(hrs) * 60;
    const minutesDiff = rest - minsInHrs;
    const minResult = minutesDiff < 10 ? `0${minutesDiff.toFixed(0)}` : minutesDiff.toFixed(0);
    return {
      days: days,
      hours: hrs,
      minutes: minResult,
      seconds: null,
    };
  }

  if (timeframe === 'year' && systemGroup === 'DTF') {
    const dtfSeconds = seconds * 3600;
    const dtfMins = dtfSeconds / 60;
    const days =
      Math.floor(dtfMins / 60 / 24) < 10
        ? `0${Math.floor(dtfMins / 60 / 24)}`
        : `${Math.floor(dtfMins / 60 / 24)}`;
    const daysToMinutes = Number(days) * 24 * 60;
    const rest = dtfMins - daysToMinutes;
    const hrs = rest / 60 < 10 ? `0${Math.floor(rest / 60)}` : Math.floor(rest / 60);
    const minsInHrs = Number(hrs) * 60;
    const minutesDiff = rest - minsInHrs;
    const minResult = minutesDiff < 10 ? `0${Math.floor(minutesDiff)}` : Math.floor(minutesDiff);
    return {
      days: days,
      hours: hrs,
      minutes: minResult,
      seconds: null,
    };
  }
  if (systemGroup === 'DTF') {
    const DTFSeconds = seconds * 3600;
    const fullHours = Math.floor(DTFSeconds / 60 / 60);
    const restSeconds = DTFSeconds - fullHours * 60 * 60;
    const fullMinutes = Math.floor(restSeconds / 60);
    const fullSeconds = restSeconds - fullMinutes * 60;
    return {
      days: null,
      hours: fullHours < 10 ? `0${fullHours}` : `${fullHours}`,
      minutes: fullMinutes < 10 ? `0${fullMinutes}` : `${fullMinutes}`,
      seconds: fullSeconds < 10 ? `0${fullSeconds.toFixed(0)}` : `${fullSeconds.toFixed(0)}`,
    };
  }
  if (mins > 60) {
    const hrs = mins / 60 < 10 ? `0${Math.floor(mins / 60)}` : Math.floor(mins / 60);
    const minsInHrs = Number(hrs) * 60;
    const minutesDiff = mins - minsInHrs;
    const minResult = minutesDiff < 10 ? `0${minutesDiff.toFixed(0)}` : minutesDiff.toFixed(0);
    return {
      days: null,
      hours: hrs,
      minutes: minResult,
      seconds: null,
    };
  }
  if (mins < 60) {
    const res = mins > 9 ? mins.toFixed(0) : `0${mins.toFixed(0)}`;
    return {
      days: null,
      hours: '00',
      minutes: res,
      seconds: null,
    };
  }
};
export const isStatusShouldBeShown = (lastupdate: string, isToday: boolean): boolean => {
  if (!lastupdate || !isToday || !isSameDay(new Date(lastupdate), new Date())) {
    return false;
  }
  if (isToday) {
    return true;
  }
};
export const getLastUpdateTextColor = (lastUpdate: string): string => {
  if (!lastUpdate) {
    return globalColors.regular.text.primary;
  }
  if (isSameDay(new Date(lastUpdate), new Date())) {
    return globalColors.regular.text.primary;
  }
  return globalColors.regular.text.red;
};
export const parseHour = (hour, modificator) => {
  if (!modificator) return;
  if (modificator === 'AM') {
    return hour === 12 ? 0 : hour;
  }
  if (modificator === 'PM') {
    return hour === 0 ? 24 : hour + 12;
  }
};
export const getCustomSearchParameters = (
  customTimeframeProperties: CustomTimeFrameProperties
): string => {
  if (
    !customTimeframeProperties ||
    !customTimeframeProperties.customTimeFrameStartDate ||
    !customTimeframeProperties.customTimeFrameEndDate
  ) {
    return undefined;
  } else {
    const requestString = `${
      getDayForDate(customTimeframeProperties.customTimeFrameStartDate).title
    }-${getDayForDate(customTimeframeProperties.customTimeFrameEndDate).title}&startHour=${
      customTimeframeProperties.customTimeFrameStartHour
    }&endHour=${customTimeframeProperties.customTimeFrameEndHour}`;
    return requestString;
  }
};
