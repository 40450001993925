import { createSelector } from 'reselect';
import { format, getDay, getMonth, parseISO } from 'date-fns';

import i18next from 'i18next';
import { Store } from '../store/storeTypes';
import { GoalType } from '../models/GoalsData';
import { userSearchSelector } from './uiSelectors';
import { userIdFromPropsSelector, schedulerIdFromPropsSelector } from './propsSelectors';
import { numberFormatFromString } from 'helpers/conversions';

/**
 * Users
 */
export function usersSelector(state: Store) {
  return state.entities.users.byId;
}
export function userIdsSelector(state: Store) {
  return state.entities.users.allIds;
}
export const usersArraySelector = createSelector(usersSelector, users => Object.values(users));

export const userIdsWithSearchSelector = createSelector(
  [userIdsSelector, usersSelector, userSearchSelector],
  (userIds, users, searchValue) => {
    if (!searchValue || searchValue.length < 2) return userIds;

    const s = searchValue.toLowerCase();
    return userIds.filter(userId => {
      const { lastName, firstName } = users[userId];

      if (lastName && firstName) {
        return lastName.toLowerCase().indexOf(s) > -1 || firstName.toLowerCase().indexOf(s) > -1;
      }
      return false;
    });
  }
);

export const userByIdSelector = createSelector(
  [usersSelector, userIdFromPropsSelector],
  (users, userId) => users[userId]
);

/**
 * Goals
 */
export const goalsSelector = (state: Store) => {
  return state.entities.goals[state.ui.selectedSystemGroup]
    ? state.entities.goals[state.ui.selectedSystemGroup]!.byId
    : [];
};
export const goalsArraySelector = createSelector(goalsSelector, goals => Object.values(goals));

export const getGoalEntryByIdSelector = (state: Store, props: Record<string, number | string>) =>
  state.entities.goals[state.ui.selectedSystemGroup]!.byId[props.goalId];

export const convertGoalTypeSelector = createSelector(getGoalEntryByIdSelector, goal => {
  return goal.goalType === GoalType.Weekly ? 'goals.weekly' : 'goals.yearly';
});

export const formatGoalCurrentValueSelector = createSelector(getGoalEntryByIdSelector, goal =>
  numberFormatFromString(goal.goalValue)
);

export const formatGoalImpressionsValueSelector = createSelector(getGoalEntryByIdSelector, goal =>
  numberFormatFromString(goal.achieved)
);

export const goalLastUpdateNoteSelector = createSelector(getGoalEntryByIdSelector, goal => {
  const updateDate = goal.dateModified
    ? i18next.t('goals.updateDateFormat', {
        date: format(parseISO(goal.dateModified), 'd MMM yyyy'),
      })
    : i18next.t('goals.neverUpdatedDateFormat');

  const { userFirstName, userLastName } = goal;
  const updateAuthor =
    userFirstName && userFirstName.length && userLastName && userLastName.length
      ? i18next.t('goals.updateAuthorFormat', {
          userFirstName,
          userLastName,
        })
      : '';

  return i18next.t('goals.updateNoteFormat', {
    updateAuthor,
    updateDate,
  });
});

export const goalDateIndicatorSelector = createSelector(getGoalEntryByIdSelector, goal => {
  const iterationNum = goal.goalType === GoalType.Weekly ? 7 : 12;
  const currentVal =
    goal.goalType === GoalType.Weekly ? getDay(new Date()) - 1 : getMonth(new Date());
  const passed: number[] = [];
  for (let i = 0; i < iterationNum; i += 1) {
    passed[i] = i <= currentVal ? 1 : 0;
  }

  return passed;
});

/**
 * Customers
 */
export const customersSelector = (state: Store) => state.entities.customers.byId;
export const customerIdsSelector = (state: Store) => state.entities.customers.allIds;
export const customersArraySelector = createSelector(customersSelector, customers =>
  Object.values(customers)
);

/**
 * Sites
 */
export const sitesSelector = (state: Store) => state.entities.sites.byId;
export const siteIdsSelector = (state: Store) => state.entities.sites.allIds;
export const sitesArraySelector = createSelector(sitesSelector, sites => Object.values(sites));

/**
 * Systems
 */
export const systemsSelector = (state: Store) => state.entities.systems.byId;
export const systemIdsSelector = (state: Store) => state.entities.systems.allIds;
export const systemsArraySelector = createSelector(systemsSelector, systems =>
  Object.values(systems)
);

/**
 * Scope
 */
export const scopeSelector = (state: Store) => ({
  customers: state.entities.customers,
  systems: state.entities.systems,
  sites: state.entities.sites,
});

/**
 * Email Schedulers selectors
 */
export const schedulersSelector = (state: Store) => state.entities.schedulers.byId;
export const schedulerIdsSelector = (state: Store) => state.entities.schedulers.allIds;
export const schedulersArraySelector = createSelector(schedulersSelector, schedulers =>
  Object.values(schedulers)
);

export const schedulerByIdSelector = createSelector(
  [schedulersSelector, schedulerIdFromPropsSelector],
  (schedulers, schedulerId) => schedulers[schedulerId]
);
