import { ErrorKind, ErrorsHandler } from 'contracts/errors-handling';

class ErrorsHandlerService implements ErrorsHandler {
  public onError = (error: Error, errorKind: ErrorKind, description?: string): void => {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `Error of '${errorKind}' kind;\n${error},\nDescription: ${description || 'NONE'}`
      );
    }
  };
}

export default new ErrorsHandlerService();
