import React from 'react';
import styled from 'styled-components';
import AllBtn from './AllBtn';
import SiteRow from './SiteRow';
const Wrapper = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => props.height}px;
  overflow: hidden;
  /* padding-bottom: 51px; */
  position: relative;
`;
const ScrollableContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar-track {
    border-left: 0;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7f8fa4;
    border-radius: 10px;
  }
`;
// const FilterFooter = styled.div<{ topOffset: number }>`
//   position: absolute;
//   top: ${props => props.topOffset - 220}px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 70px;
//   border-top: 1px solid #c5d0de;
//   opacity: 0.9;
//   background: rgba(255, 255, 255, 0.8);
//   backdrop-filter: blur(6px);

//   font-family: 'Rubik';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 24px;
//   color: #2ea1f8;
// `;

const LastEmptyBlock = styled.div`
  width: 100%;
  min-height: 70px;
`;
const FilterSystemsPopUpMenu = props => {
  const {
    selectedSystemGroup,
    filterState,
    filterData,
    filterValue,
    toggleFilterRowSystemsSelection,
    toggleFilterModelRowSystemsSelection,
    toggleFilterSerialRowSystemSelection,
    toggleAllSystemsSelection,
    filteredSite,
    filteredModel,
    filteredSystem,
    pbiReportHeight,
    isMobile,
  } = props;

  const { sites, models, systems } = filterData;
  const getFilteredSitesAndModelsAndSerials = (filterValue: string) => {
    //*if site founded => displaying sites.
    if (filteredSite.length > 0) {
      return { filteredSites: filteredSite, filteredModels: null, filteredSerials: null };
    }
    //*byModel - if model founded display models
    let filteredSitesByFilteredModels = new Array();
    for (let i = 0; i < filteredModel.length; i++) {
      const siteId = filteredModel[i].siteId;
      const foundedSite = sites.find(site =>
        site.name.toLowerCase().includes(siteId.toLowerCase())
      );
      const isSiteAlreadyExist = filteredSitesByFilteredModels.find(
        site => site.id === foundedSite.id
      );
      if (!isSiteAlreadyExist) {
        filteredSitesByFilteredModels.push(foundedSite);
      }
    }
    if (filteredSitesByFilteredModels.length > 0) {
      return {
        filteredSites: filteredSitesByFilteredModels,
        filteredModels: filteredModel,
        filteredSerials: null,
      };
    }
    //*byserialNumber and nick name

    let filteredSitesByFilteredSerials = new Array();
    let filteredModelsByFilteredSerials = new Array();
    let filteredSystemsBySerial = new Array();

    for (let i = 0; i < filteredSystem.length; i++) {
      //*get sites to result object
      const siteId = filteredSystem[i].siteId;
      const site = sites.find(site => site.name === siteId);
      const isSiteAlreadyExist = filteredSitesByFilteredSerials.find(site => site.id === siteId);
      if (!isSiteAlreadyExist) {
        filteredSitesByFilteredSerials.push(site);
      }
      //*get models to result object
      const modelId = filteredSystem[i].modelId;
      const model = models.find(model => model.id === modelId);
      const isModelAlreadyExist = filteredModelsByFilteredSerials.find(
        model => model.id === modelId
      );
      if (!isModelAlreadyExist) {
        filteredModelsByFilteredSerials.push(model);
      }
      //*filter all systems by input starts
      if (filteredSystem[i].name.includes(filterValue)) {
        filteredSystemsBySerial.push(filteredSystem[i]);
      }
    }
    return {
      filteredSites: filteredSitesByFilteredSerials,
      filteredModels: filteredModelsByFilteredSerials,
      filteredSerials: filteredSystemsBySerial,
    };
  };
  const { filteredSites, filteredModels, filteredSerials } = getFilteredSitesAndModelsAndSerials(
    filterValue
  );
  return (
    <Wrapper height={pbiReportHeight - 100} data-automationid="machine-filter-slider">
      <ScrollableContainer>
        <AllBtn
          totalSystemCount={filterData.systems.length}
          selectedSystemsCount={filterState.selectedSystemSerials.length}
          toggleAllSystemsSelection={toggleAllSystemsSelection}
          selectedSystemGroup={selectedSystemGroup}
          filterState={filterState}
          filterData={filterData}
        />
        {filteredSites &&
          filteredSites.map(site => {
            const siteModels =
              filteredModels && filteredModels.filter(model => model.siteId === site.id);
            const siteSerials =
              filteredSerials && filteredSerials.filter(serial => serial.siteId === site.id);
            return (
              <SiteRow
                key={site.id}
                {...site}
                filterState={filterState}
                filterData={filterData}
                filterValue={filterValue}
                selectedSystemGroup={selectedSystemGroup}
                filteredModels={siteModels}
                filteredSerials={siteSerials}
                toggleFilterRowSystemsSelection={toggleFilterRowSystemsSelection}
                toggleFilterModelRowSystemsSelection={toggleFilterModelRowSystemsSelection}
                toggleFilterSerialRowSystemSelection={toggleFilterSerialRowSystemSelection}
              />
            );
          })}
        <LastEmptyBlock></LastEmptyBlock>
      </ScrollableContainer>
    </Wrapper>
  );
};
export default FilterSystemsPopUpMenu;
