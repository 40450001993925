import React from 'react';
// import Drawer from 'rc-drawer';
import { ReactComponent as BurgerIcon } from '../../assets/icons/BurgerMobile.svg';
import { SwipeableDrawer } from '@material-ui/core';

import ShellReportsNav from './ShellReportsNav';
import { ReportTypesNew } from 'models/ReportData';

type BurgerMobileProps = {
  activeReport: ReportTypesNew;
};
const BurgerMobile = (props: BurgerMobileProps) => {
  const { activeReport } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = (isOpen: boolean = true) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(isOpen);
  };
  return (
    <>
      <BurgerIcon onClick={() => setIsOpen(true)} />
      <SwipeableDrawer onOpen={toggleDrawer(true)} open={isOpen} onClose={toggleDrawer(false)}>
        <ShellReportsNav isMobile={true} activeReport={activeReport} setIsOpen={setIsOpen} />
      </SwipeableDrawer>
    </>
  );
};

export default BurgerMobile;
