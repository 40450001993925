import { Granularity, GranularityEnum } from './../store/storeTypes';
import { format, isAfter, isSameDay, isSameHour, isSameMonth, isSameWeek, parse } from 'date-fns';
import { TimeFrame } from 'models/ReportData';
import { CartesianGridProps } from 'recharts';
import { ActiveOverviewChart, UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';

//format xaxis label for overview charts
type FormatTickProps = {
  value: string;
  timeframe: TimeFrame;
  selectedSystemGroup: UserSystemGroupStrings;
};

export const formatTicksForInkConsumptionDayAndWeekTimeframe = ({
  value,
  timeframe,
  selectedSystemGroup,
}: FormatTickProps) => {
  if (selectedSystemGroup === UserSystemGroup.DTF) {
    return value;
  } else {
    if (timeframe === 'day') {
      return `${value}`;
    }
    if (timeframe === 'week') {
      //*timeframe is week
      return `${value.split(' ')[0]}`;
    }
    if (timeframe === 'month') {
    } else {
      return value;
    }
  }
};

export const formatTicksForTotalProductionAndAvailabilityDayAndWeekTimeframe = ({
  value,
  timeframe,
  selectedSystemGroup,
}: FormatTickProps) => {
  if (selectedSystemGroup === UserSystemGroup.DTF) {
    return value;
  } else {
    if (timeframe === 'day') {
      return `${value.slice(0, 5)}`;
    } else {
      //*timeframe is week
      return `${value.split(' ')[0]}`;
    }
  }
};
//*tooltip helpers
export const getUnitsForTooltip = (
  systemGroup: string,
  chart: string,
  typeOfChart?: string,
  lengthUnits?: string
): 'imp' | 'm²' | 'ft²' | '%' => {
  if (systemGroup === UserSystemGroup.DTF) {
    if (chart === 'speedChart') {
      const result =
        typeOfChart === ActiveOverviewChart.Utilization
          ? '%'
          : lengthUnits === 'Meter'
          ? 'm²'
          : 'ft²';
      return result;
    }
  }
  return 'imp';
};

export const barChartsVerticalLinesGenerator = (
  args: {
    xAxis: CartesianGridProps['xAxis'];
    width: CartesianGridProps['chartWidth'];
    height: CartesianGridProps['chartHeight'];
    offset: CartesianGridProps['offset'];
  },
  dataLength: number,
  timeFrame?: string,
  chartName?: string
): number[] => {
  const coords = [];
  if (dataLength === 0) {
    return [];
  }
  for (let i = 0; i <= dataLength + 1; i++) {
    let border = args.offset!.left! + (i * args.offset!.width!) / dataLength;
    if (chartName === 'inkConsumption' && timeFrame === 'week') {
      const perCent = border / 100;
      if (i === 1) {
        border = border - 10 * perCent;
      }
      if (i === 2) {
        border = border - 5 * perCent;
      }
      if (i === 5) {
        border = border + 1 * perCent;
      }
      if (i === 6) {
        border = border + 2 * perCent;
      }
    }
    if (chartName === 'inkConsumption' && timeFrame === 'year') {
      const perCent = border / 100;
      if (i === 1) {
        border = border - 15 * perCent;
      }
      if (i === 2) {
        border = border - 7 * perCent;
      }
      if (i === 3) {
        border = border - 3 * perCent;
      }
      if (i === 4) {
        border = border - 2 * perCent;
      }
      if (i === 9) {
        border = border + 1 * perCent;
      }
      if (i === 10) {
        border = border + 2 * perCent;
      }
      if (i === 11) {
        border = border + 2 * perCent;
      }
    }

    coords.push(border);
  }

  return coords;
};

//*job analysis graphs start
export const getXAxisTickFontSizeColorConsumptionTrendChart = (
  screenWidth: number,
  timeFrame: TimeFrame,
  isMobile?: boolean
): number => {
  switch (timeFrame) {
    case 'year':
      return (screenWidth / 100) * 3;

    default:
      break;
  }
};
export const getXAxisPaddingColorConsumptionTrendChart = (
  screenWidth: number,
  timeFrame: TimeFrame
): number => {
  switch (timeFrame) {
    case 'month':
      return (screenWidth / 100) * 8;
    case 'year':
      return (screenWidth / 100) * 3;
    case 'quarter':
      return (screenWidth / 100) * 12;
    case 'week':
      return (screenWidth / 100) * 6;
    default:
      return 0;
  }
};

export const getXAxisTicksNameColorConsumptionTrendChart = (
  args: string,
  timeFrame: TimeFrame,
  isMobile?: boolean
): string => {
  switch (timeFrame) {
    case 'year':
      return getXAxisNameForYear(args);
    case 'quarter':
      return getXAxisNameForQuarterAndYear(args);
    case 'day':
      return args;
    case 'week':
      return getXAxisNameForWeek(args);
    case 'month':
      return '';
    default:
      return '-';
  }
};
const getXAxisNameForMonth = (args: string) => {
  return args;
};
const getXAxisNameForWeek = (args: string): string => {
  return args
    .split('')
    .splice(0, 3)
    .join('');
};
const getXAxisNameForYear = (args: string) => {
  //income str in format "202304"
  //output should be "Apr"
  return getXAxisNameForQuarterAndYear(args);
};
const getXAxisNameForQuarterAndYear = (dateString: string) => {
  //income str in format "202304"
  //output should be "Apr"
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const date = parse(`${year}-${month}-01`, 'yyyy-MM-dd', new Date());
  return format(date, 'MMM');
};

type ChartRadius = { innerRadius: number; outerRadius: number };
export const getPieChartRadius = (screenWidth: number): ChartRadius => {
  if (screenWidth >= 1920) {
    return { innerRadius: 70, outerRadius: 85 };
  }
  if (screenWidth >= 1600 && screenWidth < 1920) {
    return { innerRadius: 70, outerRadius: 85 };
  }
  if (screenWidth >= 1440 && screenWidth < 1600) {
    return { innerRadius: 60, outerRadius: 75 };
  }
  if (screenWidth >= 1366 && screenWidth < 1440) {
    return { innerRadius: 50, outerRadius: 65 };
  }

  return { innerRadius: 110, outerRadius: 120 };
};
type Size = { height: number; width: number };

export const getPieChartButtonSize = (chartWrapperHeight: number): Size => {
  //*occures on 1366*768
  if (chartWrapperHeight > 140 && chartWrapperHeight < 170) {
    return { height: 24, width: 26 };
  }
  //*1600 * 900
  if (chartWrapperHeight > 260 && chartWrapperHeight < 300) {
    return { height: 25, width: 25 };
  }
  //*1920 * 1080
  if (chartWrapperHeight > 400) {
    return { height: 36, width: 36 };
  } else {
    return { height: 36, width: 36 };
  }
};
export const getColorCompareChartHeight = (pbiReportHeight: number): number => {
  if (pbiReportHeight <= 567) {
    return 180;
  }
  if (pbiReportHeight >= 879) {
    return 250;
  }
  return 200;
};
export type GraphMargin = {
  marginRight: number;
  marginLeft: number;
};
export const getMarginForProductionGraphCustomTimeFrameAndDay = (
  columnsCount: number,
  screenWidth: number,
  isMachineFilterOpen: boolean
): GraphMargin => {
  if (screenWidth === 1920) {
    if (columnsCount === 2) {
      const machimeFilterOffset = 54;
      return {
        marginLeft: isMachineFilterOpen ? 442 - machimeFilterOffset : 442,
        marginRight: isMachineFilterOpen ? 442 - machimeFilterOffset : 442,
      };
    }
    if (columnsCount === 3) {
      const machimeFilterOffset = 36;

      return {
        marginLeft: isMachineFilterOpen ? 295 - machimeFilterOffset : 295,
        marginRight: isMachineFilterOpen ? 295 - machimeFilterOffset : 295,
      };
    }
    if (columnsCount === 4) {
      const machimeFilterOffset = 28;

      return {
        marginLeft: isMachineFilterOpen ? 220 - machimeFilterOffset : 220,
        marginRight: isMachineFilterOpen ? 220 - machimeFilterOffset : 220,
      };
    }
    if (columnsCount === 5) {
      const machimeFilterOffset = 20;

      return {
        marginLeft: isMachineFilterOpen ? 175 - machimeFilterOffset : 175,
        marginRight: isMachineFilterOpen ? 175 - machimeFilterOffset : 175,
      };
    }
    if (columnsCount === 6) {
      const machimeFilterOffset = 14;

      return {
        marginLeft: isMachineFilterOpen ? 145 - machimeFilterOffset : 145,
        marginRight: isMachineFilterOpen ? 145 - machimeFilterOffset : 145,
      };
    }
    if (columnsCount === 7) {
      const machimeFilterOffset = 14;

      return {
        marginLeft: isMachineFilterOpen ? 128 - machimeFilterOffset : 128,
        marginRight: isMachineFilterOpen ? 128 - machimeFilterOffset : 128,
      };
    }
    if (columnsCount === 8) {
      const machimeFilterOffset = 10;

      return {
        marginLeft: isMachineFilterOpen ? 110 - machimeFilterOffset : 110,
        marginRight: isMachineFilterOpen ? 110 - machimeFilterOffset : 110,
      };
    }
    if (columnsCount === 9) {
      const machimeFilterOffset = 7;
      return {
        marginLeft: isMachineFilterOpen ? 95 - machimeFilterOffset : 95,
        marginRight: isMachineFilterOpen ? 95 - machimeFilterOffset : 95,
      };
    }
    if (columnsCount === 10) {
      const machimeFilterOffset = 8;

      return {
        marginLeft: isMachineFilterOpen ? 88 - machimeFilterOffset : 88,
        marginRight: isMachineFilterOpen ? 88 - machimeFilterOffset : 88,
      };
    }
    if (columnsCount === 11) {
      const machimeFilterOffset = 6;
      return {
        marginLeft: isMachineFilterOpen ? 80 - machimeFilterOffset : 80,
        marginRight: isMachineFilterOpen ? 80 - machimeFilterOffset : 80,
      };
    }
    if (columnsCount === 12) {
      const machimeFilterOffset = 6;
      return {
        marginLeft: isMachineFilterOpen ? 72 - machimeFilterOffset : 72,
        marginRight: isMachineFilterOpen ? 72 - machimeFilterOffset : 72,
      };
    }
    if (columnsCount === 13) {
      const machimeFilterOffset = 6;
      return {
        marginLeft: isMachineFilterOpen ? 69 - machimeFilterOffset : 69,
        marginRight: isMachineFilterOpen ? 69 - machimeFilterOffset : 69,
      };
    }
    if (columnsCount === 14) {
      const machimeFilterOffset = 6;

      return {
        marginLeft: isMachineFilterOpen ? 65 - machimeFilterOffset : 65,
        marginRight: isMachineFilterOpen ? 65 - machimeFilterOffset : 65,
      };
    }
    if (columnsCount === 15) {
      const machimeFilterOffset = 6;

      return {
        marginLeft: isMachineFilterOpen ? 59 - machimeFilterOffset : 59,
        marginRight: isMachineFilterOpen ? 59 - machimeFilterOffset : 59,
      };
    }
    if (columnsCount === 16) {
      const machimeFilterOffset = 3;

      return {
        marginLeft: isMachineFilterOpen ? 50 - machimeFilterOffset : 50,
        marginRight: isMachineFilterOpen ? 50 - machimeFilterOffset : 50,
      };
    }
    if (columnsCount === 17) {
      const machineFilterOffset = 4;

      return {
        marginLeft: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
        marginRight: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
      };
    }
    if (columnsCount === 18) {
      const machineFilterOffset = 8;

      return {
        marginLeft: isMachineFilterOpen ? 49 - machineFilterOffset : 49,
        marginRight: isMachineFilterOpen ? 49 - machineFilterOffset : 49,
      };
    }
    if (columnsCount === 19) {
      const machineFilterOffset = 7;
      return {
        marginLeft: isMachineFilterOpen ? 48 - machineFilterOffset : 48,
        marginRight: isMachineFilterOpen ? 48 - machineFilterOffset : 48,
      };
    }

    if (columnsCount === 20) {
      const machineFilterOffset = 4;
      return {
        marginLeft: isMachineFilterOpen ? 45 - machineFilterOffset : 45,
        marginRight: isMachineFilterOpen ? 45 - machineFilterOffset : 45,
      };
    }

    if (columnsCount === 21) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 22) {
      return {
        marginLeft: 36,
        marginRight: 36,
      };
    }
    if (columnsCount === 23) {
      return {
        marginLeft: 36,
        marginRight: 36,
      };
    }

    if (columnsCount === 24) {
      const machineFilterOffset = 4;
      return {
        marginLeft: isMachineFilterOpen ? 36 - machineFilterOffset : 36,
        marginRight: isMachineFilterOpen ? 36 - machineFilterOffset : 36,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  }
  if (screenWidth >= 1600 && screenWidth < 1920) {
    if (columnsCount === 2) {
      const machineFilterOffset = 54;

      return {
        marginLeft: isMachineFilterOpen ? 365 - machineFilterOffset : 365,
        marginRight: isMachineFilterOpen ? 365 - machineFilterOffset : 365,
      };
    }
    if (columnsCount === 3) {
      const machineFilterOffset = 37;

      return {
        marginLeft: isMachineFilterOpen ? 243 - machineFilterOffset : 243,
        marginRight: isMachineFilterOpen ? 243 - machineFilterOffset : 243,
      };
    }
    if (columnsCount === 4) {
      const machineFilterOffset = 31;

      return {
        marginLeft: isMachineFilterOpen ? 183 - machineFilterOffset : 183,
        marginRight: isMachineFilterOpen ? 183 - machineFilterOffset : 183,
      };
    }
    if (columnsCount === 5) {
      const machineFilterOffset = 25;

      return {
        marginLeft: isMachineFilterOpen ? 145 - machineFilterOffset : 145,
        marginRight: isMachineFilterOpen ? 145 - machineFilterOffset : 145,
      };
    }
    if (columnsCount === 6) {
      const machineFilterOffset = 16;

      return {
        marginLeft: isMachineFilterOpen ? 120 - machineFilterOffset : 120,
        marginRight: isMachineFilterOpen ? 120 - machineFilterOffset : 120,
      };
    }
    if (columnsCount === 7) {
      const machineFilterOffset = 16;

      return {
        marginLeft: isMachineFilterOpen ? 105 - machineFilterOffset : 105,
        marginRight: isMachineFilterOpen ? 105 - machineFilterOffset : 105,
      };
    }
    if (columnsCount === 8) {
      const machineFilterOffset = 14;

      return {
        marginLeft: isMachineFilterOpen ? 92 - machineFilterOffset : 92,
        marginRight: isMachineFilterOpen ? 92 - machineFilterOffset : 92,
      };
    }
    if (columnsCount === 9) {
      const machineFilterOffset = 12;
      return {
        marginLeft: isMachineFilterOpen ? 82 - machineFilterOffset : 82,
        marginRight: isMachineFilterOpen ? 82 - machineFilterOffset : 82,
      };
    }
    if (columnsCount === 10) {
      const machineFilterOffset = 10;

      return {
        marginLeft: isMachineFilterOpen ? 71 - machineFilterOffset : 71,
        marginRight: isMachineFilterOpen ? 71 - machineFilterOffset : 71,
      };
    }
    if (columnsCount === 11) {
      const machineFilterOffset = 8;

      return {
        marginLeft: isMachineFilterOpen ? 65 - machineFilterOffset : 65,
        marginRight: isMachineFilterOpen ? 65 - machineFilterOffset : 65,
      };
    }
    if (columnsCount === 12) {
      const machineFilterOffset = 9;
      return {
        marginLeft: isMachineFilterOpen ? 63 - machineFilterOffset : 63,
        marginRight: isMachineFilterOpen ? 63 - machineFilterOffset : 63,
      };
    }
    if (columnsCount === 13) {
      const machineFilterOffset = 9;

      return {
        marginLeft: isMachineFilterOpen ? 55 - machineFilterOffset : 55,
        marginRight: isMachineFilterOpen ? 55 - machineFilterOffset : 55,
      };
    }
    if (columnsCount === 14) {
      const machineFilterOffset = 9;

      return {
        marginLeft: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
        marginRight: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
      };
    }
    if (columnsCount === 15) {
      const machineFilterOffset = 7;
      return {
        marginLeft: isMachineFilterOpen ? 47 - machineFilterOffset : 47,
        marginRight: isMachineFilterOpen ? 47 - machineFilterOffset : 47,
      };
    }
    if (columnsCount === 16) {
      const machineFilterOffset = 8;

      return {
        marginLeft: isMachineFilterOpen ? 49 - machineFilterOffset : 49,
        marginRight: isMachineFilterOpen ? 49 - machineFilterOffset : 49,
      };
    }
    if (columnsCount === 17) {
      const machineFilterOffset = 4;

      return {
        marginLeft: isMachineFilterOpen ? 40 - machineFilterOffset : 40,
        marginRight: isMachineFilterOpen ? 40 - machineFilterOffset : 40,
      };
    }
    if (columnsCount === 18) {
      const machineFilterOffset = 7;

      return {
        marginLeft: isMachineFilterOpen ? 42 - machineFilterOffset : 42,
        marginRight: isMachineFilterOpen ? 42 - machineFilterOffset : 42,
      };
    }
    if (columnsCount === 19) {
      const machineFilterOffset = 7;

      return {
        marginLeft: isMachineFilterOpen ? 40 - machineFilterOffset : 40,
        marginRight: isMachineFilterOpen ? 40 - machineFilterOffset : 40,
      };
    }

    if (columnsCount === 20) {
      const machineFilterOffset = 8;
      return {
        marginLeft: isMachineFilterOpen ? 38 - machineFilterOffset : 38,
        marginRight: isMachineFilterOpen ? 38 - machineFilterOffset : 38,
      };
    }

    if (columnsCount === 21) {
      return {
        marginLeft: 35,
        marginRight: 35,
      };
    }
    if (columnsCount === 22) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 23) {
      return {
        marginLeft: 38,
        marginRight: 38,
      };
    }
    if (columnsCount === 24) {
      const machineFilterOffset = 8;
      return {
        marginLeft: isMachineFilterOpen ? 34 - machineFilterOffset : 34,
        marginRight: isMachineFilterOpen ? 34 - machineFilterOffset : 34,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  }
  if (screenWidth >= 1400 && screenWidth < 1600) {
    if (columnsCount === 2) {
      const machineFilterOffset = 55;

      return {
        marginLeft: isMachineFilterOpen ? 325 - machineFilterOffset : 325,
        marginRight: isMachineFilterOpen ? 325 - machineFilterOffset : 325,
      };
    }
    if (columnsCount === 3) {
      const machineFilterOffset = 36;

      return {
        marginLeft: isMachineFilterOpen ? 215 - machineFilterOffset : 215,
        marginRight: isMachineFilterOpen ? 215 - machineFilterOffset : 215,
      };
    }
    if (columnsCount === 4) {
      const machineFilterOffset = 28;

      return {
        marginLeft: isMachineFilterOpen ? 160 - machineFilterOffset : 160,
        marginRight: isMachineFilterOpen ? 160 - machineFilterOffset : 160,
      };
    }
    if (columnsCount === 5) {
      const machineFilterOffset = 20;

      return {
        marginLeft: isMachineFilterOpen ? 130 - machineFilterOffset : 130,
        marginRight: isMachineFilterOpen ? 130 - machineFilterOffset : 130,
      };
    }
    if (columnsCount === 6) {
      const machineFilterOffset = 15;

      return {
        marginLeft: isMachineFilterOpen ? 110 - machineFilterOffset : 110,
        marginRight: isMachineFilterOpen ? 110 - machineFilterOffset : 110,
      };
    }
    if (columnsCount === 7) {
      const machineFilterOffset = 17;

      return {
        marginLeft: isMachineFilterOpen ? 94 - machineFilterOffset : 94,
        marginRight: isMachineFilterOpen ? 94 - machineFilterOffset : 94,
      };
    }
    if (columnsCount === 8) {
      const machineFilterOffset = 14;

      return {
        marginLeft: isMachineFilterOpen ? 80 - machineFilterOffset : 80,
        marginRight: isMachineFilterOpen ? 80 - machineFilterOffset : 80,
      };
    }
    if (columnsCount === 9) {
      const machineFilterOffset = 12;

      return {
        marginLeft: isMachineFilterOpen ? 72 - machineFilterOffset : 72,
        marginRight: isMachineFilterOpen ? 72 - machineFilterOffset : 72,
      };
    }
    if (columnsCount === 10) {
      const machineFilterOffset = 10;

      return {
        marginLeft: isMachineFilterOpen ? 65 - machineFilterOffset : 65,
        marginRight: isMachineFilterOpen ? 65 - machineFilterOffset : 65,
      };
    }
    if (columnsCount === 11) {
      const machineFilterOffset = 8;

      return {
        marginLeft: isMachineFilterOpen ? 56 - machineFilterOffset : 56,
        marginRight: isMachineFilterOpen ? 56 - machineFilterOffset : 56,
      };
    }
    if (columnsCount === 12) {
      const machineFilterOffset = 7;

      return {
        marginLeft: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
        marginRight: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
      };
    }
    if (columnsCount === 13) {
      const machineFilterOffset = 6;

      return {
        marginLeft: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
        marginRight: isMachineFilterOpen ? 50 - machineFilterOffset : 50,
      };
    }
    if (columnsCount === 14) {
      const machineFilterOffset = 5;

      return {
        marginLeft: isMachineFilterOpen ? 45 - machineFilterOffset : 45,
        marginRight: isMachineFilterOpen ? 45 - machineFilterOffset : 45,
      };
    }
    if (columnsCount === 15) {
      const machineFilterOffset = 5;
      return {
        marginLeft: isMachineFilterOpen ? 43 - machineFilterOffset : 43,
        marginRight: isMachineFilterOpen ? 43 - machineFilterOffset : 43,
      };
    }
    if (columnsCount === 16) {
      const machineFilterOffset = 8;
      return {
        marginLeft: isMachineFilterOpen ? 41 - machineFilterOffset : 41,
        marginRight: isMachineFilterOpen ? 41 - machineFilterOffset : 41,
      };
    }
    if (columnsCount === 17) {
      const machineFilterOffset = 8;

      return {
        marginLeft: isMachineFilterOpen ? 40 - machineFilterOffset : 40,
        marginRight: isMachineFilterOpen ? 40 - machineFilterOffset : 40,
      };
    }
    if (columnsCount === 18) {
      const machineFilterOffset = 10;

      return {
        marginLeft: isMachineFilterOpen ? 38 - machineFilterOffset : 38,
        marginRight: isMachineFilterOpen ? 38 - machineFilterOffset : 38,
      };
    }
    if (columnsCount === 19) {
      const machineFilterOffset = 11;
      return {
        marginLeft: isMachineFilterOpen ? 40 - machineFilterOffset : 34,
        marginRight: isMachineFilterOpen ? 40 - machineFilterOffset : 34,
      };
    }

    if (columnsCount === 20) {
      const machineFilterOffset = 9;

      return {
        marginLeft: isMachineFilterOpen ? 35 - machineFilterOffset : 35,
        marginRight: isMachineFilterOpen ? 35 - machineFilterOffset : 35,
      };
    }

    if (columnsCount === 21) {
      return {
        marginLeft: 33,
        marginRight: 33,
      };
    }
    if (columnsCount === 22) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 23) {
      return {
        marginLeft: 45,
        marginRight: 45,
      };
    }
    if (columnsCount === 24) {
      const machineFilterOffset = 12;
      return {
        marginLeft: isMachineFilterOpen ? 38 - machineFilterOffset : 28,
        marginRight: isMachineFilterOpen ? 38 - machineFilterOffset : 28,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  }
  if (screenWidth < 1400) {
    if (columnsCount === 2) {
      return {
        marginLeft: 305,
        marginRight: 305,
      };
    }
    if (columnsCount === 3) {
      return {
        marginLeft: 205,
        marginRight: 205,
      };
    }
    if (columnsCount === 4) {
      return {
        marginLeft: 153,
        marginRight: 153,
      };
    }
    if (columnsCount === 5) {
      return {
        marginLeft: 120,
        marginRight: 120,
      };
    }
    if (columnsCount === 6) {
      return {
        marginLeft: 100,
        marginRight: 100,
      };
    }
    if (columnsCount === 7) {
      return {
        marginLeft: 90,
        marginRight: 90,
      };
    }
    if (columnsCount === 8) {
      return {
        marginLeft: 80,
        marginRight: 80,
      };
    }
    if (columnsCount === 9) {
      return {
        marginLeft: 70,
        marginRight: 70,
      };
    }
    if (columnsCount === 10) {
      return {
        marginLeft: 60,
        marginRight: 60,
      };
    }
    if (columnsCount === 11) {
      return {
        marginLeft: 55,
        marginRight: 55,
      };
    }
    if (columnsCount === 12) {
      return {
        marginLeft: 50,
        marginRight: 50,
      };
    }
    if (columnsCount === 13) {
      return {
        marginLeft: 45,
        marginRight: 45,
      };
    }
    if (columnsCount === 14) {
      return {
        marginLeft: 44,
        marginRight: 44,
      };
    }
    if (columnsCount === 15) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 16) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 17) {
      return {
        marginLeft: 38,
        marginRight: 38,
      };
    }
    if (columnsCount === 18) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 19) {
      return {
        marginLeft: 36,
        marginRight: 36,
      };
    }

    if (columnsCount === 20) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }

    if (columnsCount === 21) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 22) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 23) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    }
    if (columnsCount === 24) {
      return {
        marginLeft: 40,
        marginRight: 40,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  }
  if (screenWidth > 2000) {
    return { marginLeft: 0, marginRight: 0 };
  } else {
    //*some unexpectable case which should never occures because of we should swich onto mobile but we need to keep this check for safety.
    return { marginLeft: 0, marginRight: 0 };
  }
};
type getAdditionalOffsetForXAxisCentralLabelArgsType = {
  granularity: Granularity;
  dataLength: number;
  screenWidth: number;
};
export const getAdditionalOffsetForXAxisCentralLabelTotalProductionChart = ({
  granularity,
  dataLength,
  screenWidth,
}: getAdditionalOffsetForXAxisCentralLabelArgsType): number => {
  switch (granularity) {
    case GranularityEnum.Hour:
      return 17;
    case GranularityEnum.Day:
      return getAdditionalOffsetForXAxisCentralLabelTotalProductionChartForDayGranularityByDataLength(
        dataLength,
        screenWidth
      );
    case GranularityEnum.Week:
      return 22;
    case GranularityEnum.Month:
      return 20;
    default:
      return 0;
  }
};

const offsetMapping = {
  '1920': {
    2: 230,
    3: 156,
    4: 112,
    5: 100,
    6: 82,
    7: 140,
    8: 60,
    9: 56,
    10: 50,
    11: 46,
    12: 44,
    13: 44,
    14: 40,
    15: 40,
    16: 36,
    17: 36,
    18: 34,
    19: 32,
    20: 32,
    21: 32,
  },
  '1600-1920': {
    2: 190,
    3: 132,
    4: 100,
    5: 84,
    6: 70,
    7: 118,
    8: 52,
    9: 48,
    10: 40,
    11: 40,
    12: 38,
    13: 36,
    14: 36,
    15: 36,
    16: 36,
    17: 32,
    18: 32,
    19: 32,
    20: 32,
  },
  '1440-1600': {
    2: 170,
    3: 114,
    4: 88,
    5: 74,
    6: 66,
    7: 108,
    8: 48,
    9: 42,
    10: 38,
    11: 36,
    12: 34,
    13: 32,
    14: 30,
    15: 28,
    16: 28,
    17: 26,
    18: 26,
    19: 26,
    20: 26,
  },
  '1366-1440': {
    2: 160,
    3: 110,
    4: 82,
    5: 70,
    6: 60,
    7: 110,
    8: 48,
    9: 44,
    10: 40,
    11: 38,
    12: 36,
    13: 34,
    14: 32,
    15: 30,
    16: 28,
    17: 26,
    18: 26,
    19: 26,
    20: 26,
  },
};
const getScreenWidthRange = screenWidth => {
  if (screenWidth === 1920) return '1920';
  if (screenWidth < 1920 && screenWidth >= 1600) return '1600-1920';
  if (screenWidth < 1600 && screenWidth >= 1440) return '1440-1600';
  if (screenWidth < 1440 && screenWidth >= 1366) return '1366-1440';
  return null;
};
const getAdditionalOffsetForXAxisCentralLabelTotalProductionChartForDayGranularityByDataLength = (
  dataLength,
  screenWidth
) => {
  const screenRange = getScreenWidthRange(screenWidth);
  if (screenRange && offsetMapping[screenRange] && offsetMapping[screenRange][dataLength]) {
    return offsetMapping[screenRange][dataLength];
  }
  return 17;
};

export const getGraphMarginTotalProduction = (
  screenWidth,
  timeframe,
  data,
  isMachineFilterOpen
): GraphMargin => {
  const machineFilterOffset = 16;
  if (screenWidth === 1920) {
    const marginByTimeFrame = {
      //* after changing buisness logic - for day timeframe margin should be  as for custom
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        // marginLeft: 262,
        // marginRight: 219,
        marginLeft: isMachineFilterOpen ? 262 - machineFilterOffset : 262,
        marginRight: isMachineFilterOpen ? 219 - machineFilterOffset : 219,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }
  if (screenWidth >= 1600 && screenWidth < 1920) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: isMachineFilterOpen ? 220 - machineFilterOffset : 220,
        marginRight: isMachineFilterOpen ? 182 - machineFilterOffset : 182,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }

  if (screenWidth >= 1400 && screenWidth < 1600) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: isMachineFilterOpen ? 202 - machineFilterOffset : 202,
        marginRight: isMachineFilterOpen ? 164 - machineFilterOffset : 164,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }

  if (screenWidth < 1400) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: 193,
        marginRight: 154,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }
  if (screenWidth > 2000) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: 260,
        marginRight: 260,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  } else {
    const marginByTimeFrame = {
      day: {
        marginLeft: 14,
        marginRight: 56,
      },
      week: {
        marginLeft: 0,
        marginRight: 0,
      },
      custom: {
        marginLeft: 0,
        marginRight: 0,
      },
    };
    return marginByTimeFrame[timeframe];

    // more than 1920 -mignt be we need 4k
  }
};
export const getGraphMarginAvailability = (
  screenWidth,
  timeframe,
  data,
  isMachineFilterOpen
): GraphMargin => {
  const machineFilterOffset = 16;

  if (screenWidth === 1920) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: isMachineFilterOpen ? 260 - machineFilterOffset : 260,
        marginRight: isMachineFilterOpen ? 220 - machineFilterOffset : 220,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }
  if (screenWidth >= 1600 && screenWidth < 1920) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: isMachineFilterOpen ? 221 - machineFilterOffset : 221,
        marginRight: isMachineFilterOpen ? 182 - machineFilterOffset : 182,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }

  if (screenWidth >= 1400 && screenWidth < 1600) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: isMachineFilterOpen ? 202 - machineFilterOffset : 202,
        marginRight: isMachineFilterOpen ? 163 - machineFilterOffset : 163,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }

  if (screenWidth < 1400) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: 195,
        marginRight: 153,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  }
  if (screenWidth > 2000) {
    const marginByTimeFrame = {
      day: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
      week: {
        marginLeft: 260,
        marginRight: 260,
      },
      custom: getMarginForProductionGraphCustomTimeFrameAndDay(
        data.length,
        screenWidth,
        isMachineFilterOpen
      ),
    };
    return marginByTimeFrame[timeframe];
  } else {
    const marginByTimeFrame = {
      day: {
        marginLeft: 14,
        marginRight: 56,
      },
      week: {
        marginLeft: 0,
        marginRight: 0,
      },
      custom: {
        marginLeft: 0,
        marginRight: 0,
      },
    };
    return marginByTimeFrame[timeframe];

    // more than 1920 -mignt be we need 4k
  }
};

const getGraphMarginForCustomTimeFrameSpeedChart = (
  columnsCount: number,
  screenWidth: number
): GraphMargin => {
  if (screenWidth >= 1600 && screenWidth < 1920) {
    if (columnsCount === 22) {
      return {
        marginLeft: 6,
        marginRight: 6,
      };
    }

    if (columnsCount === 23) {
      return {
        marginLeft: 8,
        marginRight: 8,
      };
    }
    if (columnsCount === 24) {
      return {
        marginLeft: 10,
        marginRight: 10,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  }
  if (screenWidth >= 1400 && screenWidth < 1600) {
    if (columnsCount === 22) {
      return {
        marginLeft: 10,
        marginRight: 10,
      };
    }

    if (columnsCount === 23) {
      return {
        marginLeft: 15,
        marginRight: 15,
      };
    }
    if (columnsCount === 24) {
      return {
        marginLeft: 10,
        marginRight: 10,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  }
  if (screenWidth < 1400) {
    if (columnsCount === 18) {
      return {
        marginLeft: 5,
        marginRight: 5,
      };
    }
    if (columnsCount === 19) {
      return {
        marginLeft: 7,
        marginRight: 7,
      };
    }
    if (columnsCount === 20) {
      return {
        marginLeft: 9,
        marginRight: 9,
      };
    }
    if (columnsCount === 21) {
      return {
        marginLeft: 12,
        marginRight: 12,
      };
    }
    if (columnsCount === 22) {
      return {
        marginLeft: 13,
        marginRight: 13,
      };
    }

    if (columnsCount === 23) {
      return {
        marginLeft: 15,
        marginRight: 15,
      };
    }
    if (columnsCount === 24) {
      return {
        marginLeft: 15,
        marginRight: 15,
      };
    } else {
      return {
        marginLeft: 0,
        marginRight: 0,
      };
    }
  } else {
    return {
      marginLeft: 0,
      marginRight: 0,
    };
  }
};
export const getGraphMarginSpeedChart = (
  timeframe: TimeFrame,
  screenWidth: number,
  columnsCount: number
) => {
  if (screenWidth === 1920) {
    const marginByTimeFrame = {
      day: {
        marginLeft: 0,
        marginRight: 0,
      },
      week: {
        marginLeft: 153,
        marginRight: 113,
      },
      custom: getGraphMarginForCustomTimeFrameSpeedChart(columnsCount, screenWidth),
    };

    return marginByTimeFrame[timeframe];
  }
  if (screenWidth >= 1600 && screenWidth < 1920) {
    const marginByTimeFrame = {
      day: {
        marginLeft: 0,
        marginRight: 0,
      },
      week: {
        marginLeft: 132,
        marginRight: 94,
      },
      custom: getGraphMarginForCustomTimeFrameSpeedChart(columnsCount, screenWidth),
    };
    return marginByTimeFrame[timeframe];
  }

  if (screenWidth >= 1400 && screenWidth < 1600) {
    const marginByTimeFrame = {
      day: {
        marginLeft: 0,
        marginRight: 0,
      },
      week: {
        marginLeft: 126,
        marginRight: 87,
      },
      custom: getGraphMarginForCustomTimeFrameSpeedChart(columnsCount, screenWidth),
    };
    return marginByTimeFrame[timeframe];
  }
  if (screenWidth < 1400) {
    const marginByTimeFrame = {
      day: {
        marginLeft: 0,
        marginRight: 0,
      },
      week: {
        marginLeft: 120,
        marginRight: 80,
      },
      custom: getGraphMarginForCustomTimeFrameSpeedChart(columnsCount, screenWidth),
    };
    return marginByTimeFrame[timeframe];
  }
  if (screenWidth > 1920) {
    const marginByTimeFrame = {
      day: {
        marginLeft: 0,
        marginRight: 0,
      },
      week: {
        marginLeft: 0,
        marginRight: 0,
      },
      custom: getGraphMarginForCustomTimeFrameSpeedChart(columnsCount, screenWidth),
    };
    return marginByTimeFrame[timeframe];
  }
};
export const getBarSizeSpeedChart = (screenWidth: number, columnCount: number): number => {
  //  //*it is decided that basic resolution for calculation width of column is 1366px;

  //     //* if there is 1-3 columns basic widht =224px;
  //     //* if there is 4-6 columns basic widht =112px;
  //     //* if there is 7-12 columns basic widht =56px;
  //     //* if there is 13-24 columns basic widht =28px;
  //     //* to get actual width of column we need to add to base size of column difference in percentage between Base Resolutin and currnet screenWidth

  //*this graph could not exits if therea are less than 1 columns or more than 24
  if (!columnCount || columnCount > 24) {
    return;
  }
  const BASE_RESOLUTION = 1366;
  const SMALL_BASE_SIZE = 28;
  const MEDIUM_BASE_SIZE = 56;
  const LARGE_BASE_SIZE = 112;
  const EXTRA_LARGE_BASE_SIZE = 224;

  let currentWidth = 0;
  if (columnCount >= 1 && columnCount <= 3) {
    currentWidth = EXTRA_LARGE_BASE_SIZE;
  }
  if (columnCount >= 4 && columnCount <= 6) {
    currentWidth = LARGE_BASE_SIZE;
  }
  if (columnCount >= 7 && columnCount <= 12) {
    currentWidth = MEDIUM_BASE_SIZE;
  }
  if (columnCount >= 13 && columnCount <= 24) {
    currentWidth = SMALL_BASE_SIZE;
  }

  const screenSizeDifferencePercent = (screenWidth - BASE_RESOLUTION) / (BASE_RESOLUTION / 100);
  currentWidth = Math.round(currentWidth + (currentWidth / 100) * screenSizeDifferencePercent);
  return currentWidth;
};

export const parseDate = (date, granularity) => {
  let parsedDate;

  switch (granularity) {
    case GranularityEnum.Hour:
      const hourParts = date.match(/(\d{2}):(\d{2})\s+(\w+)\s+(\d{1,2})\s+(\w+)\s+(\d{4})/);
      if (hourParts) {
        const [, hours, minutes, , day, month, year] = hourParts;
        parsedDate = new Date(
          +year,
          parse(month, 'MMMM', new Date()).getMonth(),
          +day,
          +hours,
          +minutes
        );
      }
      break;
    case GranularityEnum.Day:
      const dayParts = date.match(/(\w+)\s+(\d{1,2})\s+(\w+)\s+(\d{4})/);
      if (dayParts) {
        const [, , day, month, year] = dayParts;
        parsedDate = new Date(+year, parse(month, 'MMMM', new Date()).getMonth(), +day);
      }
      break;
    case GranularityEnum.Week:
      const weekParts = date.match(/(\w+)\s+(\d+)\s+-\s+(\w+)\s+(\d+)\s+, (\d+)/);
      if (weekParts) {
        const [, startMonth, startDay, , , year] = weekParts;
        parsedDate = new Date(+year, parse(startMonth, 'MMMM', new Date()).getMonth(), +startDay);
      }
      break;
    case GranularityEnum.Month:
      const year = date.slice(0, 4);
      const month = date.slice(4);
      parsedDate = new Date(+year, +month - 1, 1);
      break;
  }

  return parsedDate;
};
function isFutureDate(parsedDate, lastUpdate, granularity) {
  if (!parsedDate) return false;
  switch (granularity) {
    case GranularityEnum.Hour:
      return !isSameHour(parsedDate, lastUpdate) && isAfter(parsedDate, lastUpdate);
    case GranularityEnum.Day:
      return !isSameDay(parsedDate, lastUpdate) && isAfter(parsedDate, lastUpdate);
    case GranularityEnum.Week:
      return !isSameWeek(parsedDate, lastUpdate) && isAfter(parsedDate, lastUpdate);
    case GranularityEnum.Month:
      return !isSameMonth(parsedDate, lastUpdate) && isAfter(parsedDate, lastUpdate);
    default:
      return false;
  }
}

export const isFuturePeriod = ({ date, granularity, lastUpdate }) => {
  const parsedDate = parseDate(date, granularity);
  return isFutureDate(parsedDate, new Date(lastUpdate), granularity);
};
