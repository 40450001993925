import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';

import { Store } from '../store/storeTypes';
import { makeApiUrl } from './_shared/utils';
import { apiActionSimple } from './_shared/apiAction';
import { CustomerData } from '../models/CustomerData';
import { SiteData } from '../models/SiteData';
import { SystemData } from '../models/SystemData';

export const loadCustomersStarted = createStandardAction('LOAD_CUSTOMERS_STARTED')();
export const loadCustomersSuccess = createStandardAction('LOAD_CUSTOMERS_SUCCESS')<
  CustomerData[]
>();
export const loadCustomersFailed = createStandardAction('LOAD_CUSTOMERS_FAILED')<any>();

export const loadSitesStarted = createStandardAction('LOAD_SITES_STARTED')();
export const loadSitesSuccess = createStandardAction('LOAD_SITES_SUCCESS')<SiteData[]>();
export const loadSitesFailed = createStandardAction('LOAD_SITES_FAILED')<any>();

export const loadSystemsStarted = createStandardAction('LOAD_SYSTEMS_STARTED')();
export const loadSystemsSuccess = createStandardAction('LOAD_SYSTEMS_SUCCESS')<SystemData[]>();
export const loadSystemsFailed = createStandardAction('LOAD_SYSTEMS_FAILED')<any>();

/** Get entities needed to render user scope selection components */
export function getScopeData() {
  return function(dispatch: ThunkDispatch<Store, any, Action>) {
    dispatch(getCustomersData());
    dispatch(getSitesData());
    dispatch(getSystemsData());
  };
}

export function getCustomersData() {
  const getCustomersUrl = makeApiUrl('/api/data/customers');
  return apiActionSimple({
    request: {
      url: getCustomersUrl,
    },
    actions: [loadCustomersStarted, loadCustomersSuccess, loadCustomersFailed],
    interceptor: state =>
      state.api.isLoadingCustomers || state.entities.customers.allIds.length > 0,
  });
}

export function getSitesData() {
  const getSitesUrl = makeApiUrl('/api/data/sites');
  return apiActionSimple({
    request: {
      url: getSitesUrl,
    },
    actions: [loadSitesStarted, loadSitesSuccess, loadSitesFailed],
    interceptor: state => state.api.isLoadingSites || state.entities.sites.allIds.length > 0,
  });
}

export function getSystemsData() {
  const getSystemsUrl = makeApiUrl('/api/data/systems');
  return apiActionSimple({
    request: {
      url: getSystemsUrl,
    },
    actions: [loadSystemsStarted, loadSystemsSuccess, loadSystemsFailed],
    interceptor: state => state.api.isLoadingSystems || state.entities.systems.allIds.length > 0,
  });
}
