import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { authService } from '../../services/AuthProvider';
import config from 'config/config';

const setTimer = () => setTimeout(authService.logout, config.USER_LOGOUT_TIMEOUT);
const INIT_STATE = setTimer();

export default createReducer<number, RootAction>(INIT_STATE).handleAction(
  actions.ui.updateLogoutTimerAC,
  state => {
    clearTimeout(state);
    return setTimer();
  }
);
