import React from 'react';
import { StyledWrapperLoader } from 'components/SystemAnalysis/SystemsAnalysisStyles';
import newSpinner from '../../assets/icons/newSpinner.gif';

export type SpinnerProps = {
  size?: number;
  height?: string;
};

const SpinnerComponent: React.FC<SpinnerProps> = ({ size = 50, height }) => {
  return (
    <StyledWrapperLoader height={height} data-testid="spinner">
      <img width={size} height={size} src={newSpinner} alt="loader" />
    </StyledWrapperLoader>
  );
};

export default SpinnerComponent;
