import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'store/storeTypes';
import {
  createCertificateRequest,
  getCertificateDetailsRequest,
  deleteCertificateRequest,
  getCertificateFileRequest,
  refreshCertificateRequest,
} from '../../actions/apiManagementActions';

import ApiManagement from './ApiManagement';

const mapStateToProps = (state: Store) => {
  return {
    certificate: state.apiManagement.certificate,
    isLoading: state.apiManagement.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createCertificateRequest,
      getCertificateDetailsRequest,
      deleteCertificateRequest,
      getCertificateFileRequest,
      refreshCertificateRequest,
    },
    dispatch
  );

export type ApiManagementProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ApiManagement);
