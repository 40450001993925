import React, { useEffect, useState } from 'react';
import { ChartRecord, Granularity, UserSystemGroupStrings } from 'store/storeTypes';
import { ReactComponent as FlagIcon } from '../../../assets/icons/timeLineFlagIcon.svg';
import { getTicks } from 'helpers/overviewUtils';
import DesktopProductionChart from './DesktopProductionChart';
import MobileProductionChart from './MobileProductionChart';
import { TimeFrame } from 'models/ReportData';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';

interface IProductionChartProps {
  data: ChartRecord[];
  speedData?: ChartRecord[];
  availabilityData?: ChartRecord[];
  selectedSystemGroup: UserSystemGroupStrings;
  units: string;
  isWeek: boolean;
  legendText: {
    total: string;
    totalAvg: string;
  };
  timeframe?: TimeFrame;
  screenWidth?: number;
  isMobile: boolean;
  isMachineFilterOpen?: boolean;
  maxKeepAlive?: Date | undefined;
  customTimeframeProperties?: CustomTimeFrameProperties;
}

export interface AggregatedRecord {
  date: string;
  total?: number;
  totalAvg?: number;
  speed?: number;
  speedAvg?: number;
  availability?: number;
  availabilityAvg?: number;
  granularity: Granularity;
}

export type CustomizedTickProps = {
  x?: number;
  y?: number;
  width?: number;
  index?: number;
  data: Array<any>;
  isWeek: boolean;
};
export const CustomizedMobileTickProductionChart = props => {
  const { x, y, width, index, isWeek, customTimeframeProperties, data } = props;
  if (index > 6) {
    return null;
  }
  const { tickXcoordinate, tickText, textXCoordinate } = getTicks(index, width, 6, isWeek);

  return (
    <svg>
      <line stroke="#7F8FA4" x1={tickXcoordinate} y1={y} x2={tickXcoordinate} y2={y - 10}></line>
      {tickText === 'flag' ? (
        <FlagIcon x={textXCoordinate + 3} y={y + 5} />
      ) : (
        <text
          fontSize={12}
          x={textXCoordinate}
          y={y + 20}
          fill="#7F8FA4
        ">
          {tickText}
        </text>
      )}
    </svg>
  );
};
export default function ProductionChart(props: IProductionChartProps) {
  const [aggregated, setAggregatedData] = useState<ChartRecord[]>([]);
  const {
    timeframe,
    data,
    speedData,
    availabilityData,
    isWeek,
    units,
    legendText,
    selectedSystemGroup,
    isMobile,
    screenWidth,
    customTimeframeProperties,
    isMachineFilterOpen,
    maxKeepAlive,
  } = props;
  useEffect(() => {
    if (data) {
      const aggregated = data.map((record, index) => {
        return {
          granularity: record.granularity,
          total: record.total,
          totalAvg: record.totalAvg,
          date: record.date,
          speed: speedData && speedData[index] !== undefined ? speedData[index].total : undefined,
          speedAvg:
            speedData && speedData[index] !== undefined ? speedData[index].totalAvg : undefined,
          availability:
            availabilityData && availabilityData[index] !== undefined
              ? availabilityData[index].total
              : undefined,
          availabilityAvg:
            availabilityData && availabilityData[index] !== undefined
              ? availabilityData[index].totalAvg
              : undefined,
        };
      });

      setAggregatedData(aggregated);
    }
  }, [data, speedData, availabilityData]);

  if (isMobile) {
    return (
      <MobileProductionChart aggretatedData={aggregated} isWeek={isWeek} legendText={legendText} />
    );
  } else {
    return (
      <DesktopProductionChart
        maxKeepAlive={maxKeepAlive}
        isMachineFilterOpen={isMachineFilterOpen}
        customTimeframeProperties={customTimeframeProperties}
        timeframe={timeframe}
        aggregated={aggregated}
        isWeek={isWeek}
        units={units}
        selectedSystemGroup={selectedSystemGroup}
        legendText={legendText}
        screenWidth={screenWidth}
        data={data}
      />
    );
  }
}
