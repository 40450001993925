import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components/macro';
import Select from '../_common/Select';
import { PbiFilterQuartersProps } from './PbiFilterQuartersContainer';
import FilterArrows from '../_common/FilterArrows';
import cn from 'classnames';
import {
  generateDatesForPeriod,
  getQuarterForDate,
  getYearForDate,
  startOfQuarter,
} from 'helpers/date';
import { addQuarters, isSameDay, startOfYear, subQuarters } from 'date-fns';
import NewDatePicker from './NewDatePicker';

const MobileQuarterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90px;
  margin: 12px 0;
`;
const MobileQuarterItemHeader = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #1878ba;
`;
const MobileQuarterItemBody = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
const MobileQuarterItemBodyItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 59px;
  height: 34px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.isSelected ? '#2ea1f8' : 'transparent')};
  color: ${props => (props.isSelected ? '#FFFFFF' : '#354052')};
`;
const EmptyLastBlock = styled.div`
  display: flex;
  width: 100%;
  min-height: 250px;
`;
const PbiFilterQuarters = React.memo((props: PbiFilterQuartersProps) => {
  const {
    startDate,
    endDate,
    selectedDate,
    isMobile,
    activeReport,
    closeCalendarModal,
    setActiveDate,
  } = props;

  const quarters = useMemo(
    () => generateDatesForPeriod(startDate, endDate, addQuarters, startOfQuarter),
    [startDate, endDate]
  );

  const reversedData = [...quarters].reverse();

  const onChange = (e: React.BaseSyntheticEvent) => {
    const value = e.target.value || e.target.getAttribute('data-value');
    if (value) {
      const startQuarterDate = quarters.find(q => getQuarterForDate(q).title === value);
      setActiveDate(startQuarterDate);
    }
  };

  const onHandleLeft = () => {
    if (!disableLeft) {
      setActiveDate(subQuarters(selectedDate, 1));
    }
  };

  const onHandleRight = () => {
    if (!disableRight) {
      setActiveDate(addQuarters(selectedDate, 1));
    }
  };

  const mapStructure = (arr: string[]) =>
    arr.reduce((acc, str, index) => {
      const year = str.split(',')[1];
      acc[year] = !acc[year] ? [str] : [...acc[year], str];
      return acc;
    }, {});
  //*quarter name is always in format: 'Q1, 2019'
  const getStartQuarterByQuarterName = quarter => {
    const [quarterName, yearString] = quarter.split(',');
    const year = +yearString.slice(1);
    const [_, quearterNumber] = quarterName.split('');
    const startYear = new Date(year, 0, 1);
    const quertersToAdd = quearterNumber - 1;
    return addQuarters(startYear, quertersToAdd);
  };
  const onMobileQuarterClickHandler = quarter => {
    const selectedQuarter = getStartQuarterByQuarterName(quarter);
    setActiveDate(selectedQuarter);
    closeCalendarModal();
  };
  const dateTitle = getQuarterForDate(selectedDate).title;

  const disableRight = selectedDate >= quarters[quarters.length - 1];
  const disableLeft = selectedDate <= quarters[0];
  if (isMobile) {
    const mapQuarters = mapStructure(quarters.map(q => getQuarterForDate(q).title));
    const quartersKeys = Object.keys(mapQuarters);
    return (
      <>
        {quartersKeys.map((year, index) => {
          const currentQuarters = mapQuarters[year];
          return (
            <MobileQuarterItem key={year + index}>
              <MobileQuarterItemHeader>{year}</MobileQuarterItemHeader>
              <MobileQuarterItemBody>
                {currentQuarters.map((q, index) => {
                  const [quarterName, _] = q.split(',');
                  const currentSelectedDate = selectedDate;
                  const startSelectedQuarter = getStartQuarterByQuarterName(q);
                  const isSelected = isSameDay(currentSelectedDate, startSelectedQuarter);
                  return (
                    <MobileQuarterItemBodyItem
                      isSelected={isSelected}
                      key={q + index}
                      onClick={event => onMobileQuarterClickHandler(q)}>
                      {quarterName}
                    </MobileQuarterItemBodyItem>
                  );
                })}
              </MobileQuarterItemBody>
            </MobileQuarterItem>
          );
        })}
        <EmptyLastBlock />
      </>
    );
  } else {
    return (
      <NewDatePicker
        activeReport={activeReport}
        timeframe={'quarter'}
        onHandleLeft={onHandleLeft}
        onHandleRight={onHandleRight}
        disableLeft={disableLeft}
        disableRight={disableRight}
        date={selectedDate}
        lastUpdate={endDate}
        selectedDate={selectedDate}
        startDate={startDate}
        setActiveDate={setActiveDate}
      />
    );
  }
});

export default PbiFilterQuarters;
