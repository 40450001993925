import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { addDays, format, startOfWeek } from 'date-fns';

import { globalColors } from 'globalStyle';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/prevDateIcon.svg';
import { ReactComponent as NextArrowIcon } from '../../assets/icons/nextDateIcon.svg';
import { ReactComponent as NewCalendarIcon } from '../../assets/icons/newCalendarIcon.svg';
import DatePickerPopUp from './DatePickerPopUp';
import { MainReportTypes, ReportTypesNew, TimeFrame } from 'models/ReportData';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { convertTo12HourFormat } from 'helpers/conversions';
const StyledButton = styled(Button)`
  margin: 0 15px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  min-width: 220px;
  height: 30px;

  &:hover {
    color: ${globalColors.regular.text.primary};
  }
`;
const ArrowWrapper = styled.div<{ isDisable: boolean }>`
  display: flex;
  place-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border:1px solid ${props =>
    props.isDisable
      ? globalColors.regular.buttons.disableBorder
      : globalColors.regular.buttons.regularBorder}; };
  opacity: ${props => (props.isDisable ? 0.5 : 1)};
`;
type NewDatePickerPropsType = {
  onHandleLeft: () => void;
  onHandleRight: () => void;
  setActiveDate: (date: Date) => void;
  setIsCustomTimeFrameOpen?: (isOpen: boolean) => void;
  setTimeframe?: (
    timeframe: TimeFrame,
    startDate?: Date,
    customTimeFrameProperties?: CustomTimeFrameProperties
  ) => void;
  disableLeft: boolean;
  disableRight: boolean;
  date: Date;
  timeframe: TimeFrame;
  prevTimeframe?: TimeFrame;
  lastUpdate: Date;
  selectedDate: Date;
  startDate: Date;
  customTimeframeProperties?: CustomTimeFrameProperties;
  activeReport: ReportTypesNew;
};
const NewDatePicker = (props: NewDatePickerPropsType) => {
  const {
    onHandleLeft,
    onHandleRight,
    disableLeft,
    disableRight,
    date,
    timeframe,
    prevTimeframe,
    lastUpdate,
    selectedDate,
    startDate,
    customTimeframeProperties,
    setActiveDate,
    activeReport,
    setIsCustomTimeFrameOpen,
    setTimeframe,
  } = props;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    if (timeframe === 'custom') {
      //*on this step timeframe of app still not custom - but the reason of timeframe === custom is that we call this component with hardcoded value of timeframe.
      //*and only after user click on OK button we need to call setTimeframe with parameter 'custom'
      setIsDatePickerOpen(true);
      KonnectAnalytics.toggleDatePickerCalendar();
    }
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const getDateStringForCustomTimeframe = (
    customTimeframeProperties: CustomTimeFrameProperties,
    timeframe: TimeFrame
  ) => {
    if (
      !customTimeframeProperties.customTimeFrameStartDate ||
      !customTimeframeProperties.customTimeFrameEndDate
    ) {
      return 'Custom Calendar';
    } else {
      const startDate = customTimeframeProperties.customTimeFrameStartDate;
      const endDate = customTimeframeProperties.customTimeFrameEndDate;
      return `${format(startDate, 'd')} ${format(startDate, 'MMM')}, ${format(
        startDate,
        'yyyy'
      )}, ${convertTo12HourFormat(customTimeframeProperties.customTimeFrameStartHour)} - ${format(
        endDate,
        'd'
      )} ${format(endDate, 'MMM')}, ${format(endDate, 'yyyy')}, ${convertTo12HourFormat(
        customTimeframeProperties.customTimeFrameEndHour
      )}`;
    }
  };
  const getDateString = timeframe => {
    switch (timeframe) {
      case 'day':
        return `${format(date, 'd')} ${format(date, 'MMM')}, ${format(date, 'yyyy')}`;

      case 'week':
        const start = startOfWeek(date, { weekStartsOn: 1 });
        const endOfWeek = addDays(start, 6);
        return `${format(start, 'd')}-${format(endOfWeek, 'd')} ${format(
          endOfWeek,
          'MMM'
        )}, ${format(endOfWeek, 'yyyy')}`;
      case 'month':
        return `${format(date, 'MMM')},${format(date, 'yyyy')}`;
      case 'quarter':
        return `${format(date, `QQQ`)},${format(date, 'yyyy')}`;
      case 'year':
        return `${format(date, 'yyyy')}`;
      case 'custom':
        return getDateStringForCustomTimeframe(customTimeframeProperties, timeframe);
      default:
        return `${format(date, 'd')} ${format(date, 'MMM')}, ${format(date, 'yyyy')}`;
    }
  };
  return (
    <>
      <ArrowWrapper
        data-automationid="prev-date-button"
        isDisable={disableLeft}
        onClick={onHandleLeft}>
        <IconButton>
          <BackArrowIcon
            stroke={
              disableLeft
                ? globalColors.regular.buttons.disableText
                : globalColors.regular.buttons.regularText
            }
          />
        </IconButton>
      </ArrowWrapper>

      <StyledButton
        data-automationid="open-date-picker-button"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        style={{
          textTransform: 'none',
          color: isDatePickerOpen
            ? globalColors.regular.buttons.whiteText
            : globalColors.regular.buttons.regularText,
          backgroundColor: isDatePickerOpen ? globalColors.regular.background.blue : 'transparent',
          border: isDatePickerOpen
            ? globalColors.regular.background.blue
            : `1px solid ${globalColors.regular.buttons.regularBorder}`,
          whiteSpace: 'nowrap',
          padding: '0 10px',
        }}
        startIcon={
          <NewCalendarIcon
            fill={
              isDatePickerOpen && !isHovered
                ? globalColors.regular.background.white
                : globalColors.regular.background.blue
            }
          />
        }
        disabled={false}
        onClick={() => {
          setIsDatePickerOpen(!isDatePickerOpen);
          KonnectAnalytics.toggleDatePickerCalendar();
        }}>
        {getDateString(timeframe)}
      </StyledButton>
      <ArrowWrapper
        data-automationid="next-date-button"
        isDisable={disableRight}
        onClick={onHandleRight}>
        <IconButton>
          <NextArrowIcon
            stroke={
              disableRight
                ? globalColors.regular.buttons.disableText
                : globalColors.regular.buttons.regularText
            }
          />
        </IconButton>
      </ArrowWrapper>

      <DatePickerPopUp
        date={date}
        customTimeframeProperties={customTimeframeProperties}
        isDatePickerOpen={isDatePickerOpen}
        timeframe={timeframe}
        prevTimeframe={prevTimeframe}
        lastUpdate={lastUpdate}
        selectedDate={selectedDate}
        startDate={startDate}
        activeReport={activeReport}
        setIsDatePickerOpen={setIsDatePickerOpen}
        setActiveDate={setActiveDate}
        setIsCustomTimeFrameOpen={setIsCustomTimeFrameOpen}
        setTimeframe={setTimeframe}
      />
    </>
  );
};

export default NewDatePicker;
