import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckedIcon } from '../../assets/icons/CheckedIcon.svg';
import { ReactComponent as HalfCheckedIcon } from '../../assets/icons/HalfCheckedIcon.svg';
import { RowWrapper, LeftPart, CheckBox, NameWrapper, RightPart } from './SiteRow';

export type AllBtnProps = {
  totalSystemCount: number;
  selectedSystemsCount: number;
  selectedSystemGroup: string;
  filterData: any;
  filterState: any;
  toggleAllSystemsSelection: ({}) => void;
};
const AllBtn = (props: AllBtnProps) => {
  const {
    totalSystemCount,
    selectedSystemsCount,
    selectedSystemGroup,
    filterState,
    filterData,
    toggleAllSystemsSelection,
  } = props;
  const getCheckedIcon = (totalCount: number, selectedCount: number) => {
    if (totalCount === selectedCount) {
      return <CheckedIcon fill="white" />;
    }
    if (selectedCount > 0 && selectedCount < totalCount) {
      return <HalfCheckedIcon fill="white" style={{ marginTop: '1px' }} />;
    }
  };
  const CheckedIconJSX = getCheckedIcon(totalSystemCount, selectedSystemsCount);
  return (
    <>
      <RowWrapper style={{ marginTop: '10px' }} data-automationid={`filter_systems_selectAllBtn`}>
        <LeftPart
          onClick={() => {
            toggleAllSystemsSelection({ selectedSystemGroup, filterData, filterState });
          }}>
          <CheckBox checked={selectedSystemsCount > 0}>
            {selectedSystemsCount > 0 && CheckedIconJSX}
          </CheckBox>

          <NameWrapper>{'All'}</NameWrapper>
        </LeftPart>
        <RightPart></RightPart>
      </RowWrapper>
    </>
  );
};
export default AllBtn;
