import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { createStandardAction as crAction } from 'typesafe-actions';

import {
  Store,
  MODAL_STATUS,
  UserSystemGroupStrings,
  SystemAnalysisViews,
} from '../store/storeTypes';
import { ExtendedUserData } from '../models/ExtendedUserData';
import ScopeData from '../models/ScopeData';
import { scopeSelector } from '../selectors/entitiesSelectors';

export const toggleMachineFilterMenu = crAction('TOGGLE_MACHINE_FILTER_MENU')();
export const toggleUserTableRow = crAction('TOGGLE_USER_TABLE_ROW')<string>();
export const toggleUserTableAll = crAction('TOGGLE_USER_TABLE_ALL')<string[]>();

type OpenModalUserCreatePayload = ScopeData;
export const openModalUserCreateAC = crAction('OPEN_MODAL_USER_CREATE')<
  OpenModalUserCreatePayload
>();
export function openModalUserCreate() {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();

    dispatch(openModalUserCreateAC(scopeSelector(state)));
  };
}

interface OpenModalUserEditPayload extends ScopeData {
  user: ExtendedUserData;
}
export const openModalUserEditAC = crAction('OPEN_MODAL_USER_EDIT')<OpenModalUserEditPayload>();
export function openModalUserEdit(id: string) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();
    dispatch(
      openModalUserEditAC({
        user: state.entities.users.byId[id],
        ...scopeSelector(state),
      })
    );
  };
}

interface OpenModalUserDuplicatePayload extends ScopeData {
  user: ExtendedUserData;
}
export const openModalUserDuplicateAC = crAction('OPEN_MODAL_USER_DUPLICATE')<
  OpenModalUserDuplicatePayload
>();
export function openModalUserDuplicate(id: string) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();

    dispatch(
      openModalUserDuplicateAC({
        user: state.entities.users.byId[id],
        ...scopeSelector(state),
      })
    );
  };
}

export const closeModalUser = crAction('CLOSE_MODAL_USER')();

interface ChangeFormPlainValuePayload {
  field: string;
  value: string | boolean | ArrayBuffer;
}
export const changeFormPlainValue = crAction('CHANGE_USER_FORM_PLAIN_VALUE')<
  ChangeFormPlainValuePayload
>();

interface ChangeUserFormScopePayload extends ScopeData {
  selectedNodes: TreeNodeProps[];
}
export const changeUserFormScopeAC = crAction('CHANGE_USER_FORM_SCOPE')<
  ChangeUserFormScopePayload
>();
export function changeUserFormScope(selectedNodes: TreeNodeProps[]) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();
    dispatch(
      changeUserFormScopeAC({
        selectedNodes,
        ...scopeSelector(state),
      })
    );
  };
}

export function changeFormAvatar(avatarUploadedFile: File) {
  return function(dispatch: ThunkDispatch<Store, any, Action>) {
    const reader = new FileReader();

    reader.onload = function(e: any) {
      const avatarBase64 = btoa(String.fromCharCode(...new Uint8Array(e.target.result)));
      dispatch(
        changeFormPlainValue({
          field: 'avatarBase64',
          value: avatarBase64,
        })
      );
    };

    reader.readAsArrayBuffer(avatarUploadedFile);
  };
}

export const changeUserSearchValue = crAction('CHANGE_USER_SEARCH_VALUE')<string>();

export const setStatusModalUser = crAction('SET_STATUS_MODAL_USER')<MODAL_STATUS>();
export const setSystemGroup = crAction('SET_SYSTEM_GROUP')<UserSystemGroupStrings>();
export const setSystemAnalysisView = crAction('SET_SYSTEM_ANALYSIS_VIEW')<SystemAnalysisViews>();
export const setScreenSize = crAction('SET_SCREEN_SIZE')<{ height?: number; width: number }>();
export const setPbiReportSize = crAction('SET_PBI_REPORT_PAGE_SIZE')<{
  height?: number;
  width: number;
}>();
export const setCaptchaIsOpen = crAction('SET_CAPTCHA_IS_OPEN')<{
  isOpen: boolean;
  userId: string;
}>();
