import React from 'react';
import styled from 'styled-components/macro';
import { PRIVACY_POLICY_PDF } from 'contracts/constants';

const BottomPrompt = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  color: #2ea1f8;
  padding: 40px 20px;
  border-top: 1px solid #2ea1f8;
  a {
    color: #2ea1f8;
  }
  opacity: 0;
  transition: all 0.5s ease;
`;

const Dismiss = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 22px;
  line-height: 15px;
  padding: 5px;
  cursor: pointer;
`;

interface CookiesPromptProps {
  action: () => void;
}

function CookiesPrompt(props: CookiesPromptProps) {
  const promptEl = React.useRef<HTMLDivElement>(null);

  function handleChange() {
    props.action();

    promptEl.current!.style.opacity = '0';

    setTimeout(() => {
      promptEl.current!.style.display = 'none';
    }, 500);
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (promptEl.current) promptEl.current.style.opacity = '1';
    }, 500);
  }, [promptEl]);

  return (
    <BottomPrompt ref={promptEl}>
      <Dismiss onClick={handleChange}>&times;</Dismiss>
      Kornit Digital uses cookies to&nbsp;enable the best product match per user. If&nbsp;you
      continue to&nbsp;use our services, we&nbsp;will assume you to&nbsp;agree to&nbsp;use
      of&nbsp;such cookies. Navigate to Terms and Conditions &#8594;
      <a href={PRIVACY_POLICY_PDF} rel="noopener noreferrer" target="_blank" onClick={handleChange}>
        Privacy Policy
      </a>
      .
    </BottomPrompt>
  );
}

export default CookiesPrompt;
