import React, { useMemo, useCallback } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { useTranslation } from 'react-i18next';

import {
  required,
  email as validateEmail,
  uniqueEmail,
  maxLength,
  lettersOnly,
} from '../../helpers/validationRules';
import TextField from '../_common/TextField';
import TreeSelect from '../_common/TreeSelect/TreeSelect';
import Switch from '../_common/Switch';
import ButtonOk from '../_common/ButtonOk';
import ButtonCancel from '../_common/ButtonCancel';
import ButtonSpinner from '../_common/ButtonSpinner';
import FormRow from '../_common/FormRow';
import Grid from '../_common/Grid';
import PopupButtons from '../_common/PopupButtons';
import { UserFormProps } from './UserFormContainer';
import { isEmpty } from 'lodash';
import { StyledTooltip } from 'globalStyle';

export type ValidateValues = {
  email?: string;
  lastname?: string;
  firstname?: string;
};

const UserForm = React.memo((props: UserFormProps) => {
  const { t } = useTranslation();
  const {
    userForm,
    isSaving,
    userScope,
    allUsersButCurrentEmails,
    closeThisModal,
    changeFormPlainValue,
    changeUserFormScope,
    saveUser,
  } = props;
  const modalUserIsNew = !userForm.id;
  const onSubmit = useCallback(() => {
    saveUser();
  }, [saveUser]);

  const validate = useCallback(
    (values: ValidateValues) => ({
      firstname:
        required(values.firstname) ||
        maxLength(100, values.firstname) ||
        lettersOnly(values.firstname),
      lastname:
        required(values.lastname) ||
        maxLength(100, values.lastname) ||
        lettersOnly(values.lastname),
      email:
        required(values.email) ||
        validateEmail(values.email) ||
        uniqueEmail(values.email, allUsersButCurrentEmails) ||
        maxLength(256, values.email),
    }),
    [allUsersButCurrentEmails]
  );

  const initialformData = useMemo(() => ({ ...userForm }), [userForm]);
  const { form, handleSubmit, errors } = useForm({
    onSubmit,
    validate,
    initialValues: initialformData,
  });

  const firstname = useField('firstname', form);
  const lastname = useField('lastname', form);
  const email = useField('email', form);
  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <input type="hidden" name="id" value={userForm.id} />

      <FormRow spacing={3} container className="with-helpers">
        <Grid item xs={12} sm={5}>
          <TextField
            {...firstname.input}
            id="user-firstname"
            label={t('userForm.firstName')}
            error={firstname.meta.touched && firstname.meta.error}
            onChange={(e: React.BaseSyntheticEvent) => {
              changeFormPlainValue({
                field: 'firstname',
                value: e.target.value,
              });
              firstname.input.onChange(e);
            }}
            value={firstname.input.value}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            {...lastname.input}
            id="user-lastname"
            label={t('userForm.lastName')}
            error={lastname.meta.touched && lastname.meta.error}
            onChange={(e: React.BaseSyntheticEvent) => {
              changeFormPlainValue({
                field: 'lastname',
                value: e.target.value,
              });
              lastname.input.onChange(e);
            }}
            value={lastname.input.value}
          />
        </Grid>
      </FormRow>

      <FormRow spacing={3} container className="with-helpers">
        <Grid item xs={12}>
          <StyledTooltip
            disableHoverListener={modalUserIsNew}
            disableFocusListener={modalUserIsNew}
            disableTouchListener={modalUserIsNew}
            title={t('userForm.emailTooltip')}>
            <TextField
              InputProps={{
                readOnly: !modalUserIsNew,
              }}
              {...email.input}
              id="user-email"
              label={t('userForm.email')}
              error={email.meta.touched && email.meta.error}
              value={email.input.value}
              onChange={(e: React.BaseSyntheticEvent) => {
                changeFormPlainValue({
                  field: 'email',
                  value: e.target.value,
                });
                email.input.onChange(e);
              }}
            />
          </StyledTooltip>
        </Grid>
      </FormRow>

      <FormRow spacing={2} container className="with-helpers">
        <Grid item xs={12}>
          <TreeSelect
            label={t('userForm.scope')}
            userScope={userScope}
            data={userForm.systemsTree}
            onChange={(e, selectedNodes: TreeNodeProps[]) => changeUserFormScope(selectedNodes)}
          />
        </Grid>
      </FormRow>

      <Switch
        id="switch-set-goals"
        label={t('userForm.setGoals')}
        onChange={(e: React.BaseSyntheticEvent) => {
          changeFormPlainValue({
            field: 'setGoals',
            value: e.target.checked,
          });
        }}
        checked={userForm.setGoals && userScope.length > 0}
        disabled={userScope.length === 0}
        tip={t('userForm.setGoalsTip')}
      />

      <Switch
        id="switch-admin-permissions"
        label={t('userForm.adminPermissions')}
        onChange={(e: React.BaseSyntheticEvent) => {
          changeFormPlainValue({
            field: 'adminPermissions',
            value: e.target.checked,
          });
        }}
        checked={userForm.adminPermissions}
        disabled={userForm.isRootUser}
        tip={t('userForm.adminPermissionsTip')}
      />

      <PopupButtons>
        <ButtonCancel onClick={closeThisModal}>Cancel</ButtonCancel>
        <ButtonOk type="submit" disabled={!userForm.isFormTouched || isSaving || !isEmpty(errors)}>
          {isSaving ? <ButtonSpinner /> : t('userForm.save')}
        </ButtonOk>
      </PopupButtons>
    </form>
  );
});

export default UserForm;
