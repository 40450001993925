import { Button } from '@material-ui/core';
import { globalColors } from 'globalStyle';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;
export const DatePickerWrapper = styled.div<{ height: number; width: number }>`
    display: flex;
    flex-direction: column;
    align-content: center;

    box-sizing: border-box;
    width: ${props => props.width}px;
    /* height: ${props => `${props.height}px`}; */
    padding: 28px;
    background: #fff;
  `;
export const CustomTimeFrameFormWrapper = styled.div<{ height: number }>`
    display: flex;
    flex-direction: column;
    align-content: center;
    box-sizing: border-box;
    width: 300px;
    /* height: ${props => props.height}px; */
    padding: 28px;
    background-color: #fff;
  `;
export const CustomTimeFrameForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 254px;
  height: 300px;
  padding: 28px;

  background-color: #f8f8f8;
`;
export const ButtonsRow = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 25px;
`;
export const ButtonCancel = styled(Button)`
  width: 125px;
  height: 42px;
  border: 1px solid ${globalColors.regular.background.blue};
  color: ${globalColors.regular.text.blue};
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;
export const ButtonSubmit = styled(Button)`
  width: 125px;
  height: 42px;
  border: 1px solid ${globalColors.regular.background.blue};
  color: ${globalColors.regular.text.white};
  background-color: ${globalColors.regular.background.blue};
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;
export const CustomTimeFrameDateTimeSelectionRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 120px;
  margin-top: 5px;
`;
export const FormLabel = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
`;

export const PickDateButton = styled(Button)`
  height: 32px;
  width: 178px;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #fff;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #354052;
`;
export const TimeSelectRow = styled.div`
  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 178px;
`;

export const TimeSelect = styled.select`
  border-color: transparent;
  height: 28px;
  width: 115px;
`;

export const AMPMtogglerWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  opacity: ${props => (props.isDisabled ? 0.3 : 1)};
`;
export const AmPmValue = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background: ${props => (props.selected ? globalColors.regular.background.blue : 'transparent')};
  color: ${props =>
    props.selected ? globalColors.regular.text.white : globalColors.regular.text.grey};
  border-radius: 4px;
`;
export const YearSelector = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 100%;
`;
export const MonthSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 100%;
`;
export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 280px;
  min-height: 180px;
`;
export const CalendarRow = styled.div`
  display: flex;
  width: 100%;
  min-height: 30px;
  border-radius: 20px;
  position: relative;
`;
export const RowCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  min-height: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
  position: relative;
`;
export const RowCellHighlighted = styled.div`
  display: flex;
  position: absolute;
  width: 40px;
  height: 20px;
  background-color: #d6edff;
  opacity: 0.5;
  z-index: 5;
`;
export const ValueWrapper = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
`;
export const MonthTimeframeCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;
export const MonthButton = styled(Button)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 54px;
  height: 30px;
  margin-bottom: 5px;
`;
export const QuarterTimeframeCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const QuarterButton = styled(Button)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 70px;
  height: 30px;
  margin-bottom: 5px;
`;
export const YearTimeframeCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const YearButton = styled(Button)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 50px;
  height: 30px;
  margin-bottom: 5px;
`;

export const popUpstyle = {
  position: 'absolute' as 'absolute',
  top: 110,
  left: 225,
};
