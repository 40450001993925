import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isString } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconMoreVert from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

import Avatar from '../Avatar/Avatar';
import { Permission } from 'models/Permissions';
import PopupMenu from '../_common/PopupMenu';
import ModalPrompt from '../_common/ModalPrompt';
import { TableCell, TableRow, ButtonsTableCell, TableIconButton } from '../_common/Table';
import { UsersTableRowProps } from './UsersTableRowContainer';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const AvatarTableCell = styled(TableCell)`
  padding-top: 0.85rem;
  padding-right: 37px;
  width: 83px;
  min-width: 83px;
`;

const NameTableCell: StyledComponentWithAnyType = styled(TableCell)`
  width: 130px;
  min-width: 130px;
  b {
    font-weight: 500;
  }
  @media (max-width: 840px) {
    flex-grow: 1;
  }
  @media (max-width: 595px) {
    display: flex;
  }
  @media (max-width: 350px) {
    min-width: 90px;
    font-size: 14px;
  }
`;

const LastNameTableCell = styled(NameTableCell)`
  @media (max-width: 595px) {
    display: none;
  }
`;

const ScopeTableCell = styled(TableCell)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 300px;
  min-width: 300px;
  @media (max-width: 840px) {
    display: none;
  }
`;

const GoalsTableCell = styled(TableCell)`
  width: 90px;
  min-width: 90px;
  justify-content: center;
  @media (max-width: 455px) {
    display: none;
  }
`;

const UsersTableRow = React.memo((props: UsersTableRowProps) => {
  const { t } = useTranslation();
  const {
    deleteUser,
    openModalUserEdit,
    openModalUserDuplicate,
    inviteUser,
    resetUserPassword,
  } = props;
  const { userEntry, userScope, isRowSelected } = props;
  const [isPromptOpen, setPromptOpen] = useState(false);

  const onOpenModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    openModalUserEdit(userEntry.id);
  };

  const onOpenPrompt = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setPromptOpen(true);
  };

  const onClosePrompt = (answer: boolean) => {
    setPromptOpen(false);
    answer && deleteUser(userEntry.id);
  };
  //* if user is shadow user, we don't render it
  if (userEntry.isShadowUser) {
    return <></>;
  }
  return (
    <TableRow selected={isRowSelected}>
      <AvatarTableCell>
        <Avatar
          size="small"
          background={isRowSelected ? '#f2f9ff' : 'white'}
          avatarUrl={userEntry.profilePictureUrl}
          adminStar={userEntry.permissions.includes(Permission.Admin)}
          rootUserStar={userEntry.isRootUser}
        />
      </AvatarTableCell>
      <NameTableCell>
        <Typography noWrap>
          {isString(userEntry.firstName) ? userEntry.firstName : t('usersTable.unnamed')}
        </Typography>
      </NameTableCell>
      <LastNameTableCell>
        <Typography noWrap>
          {isString(userEntry.lastName) ? userEntry.lastName : t('usersTable.unnamed')}
        </Typography>
      </LastNameTableCell>
      <ScopeTableCell>
        {userScope.map(scopePart => (
          <Typography noWrap key={scopePart}>
            {isString(scopePart) ? scopePart : t('usersTable.unscoped')}
          </Typography>
        ))}
      </ScopeTableCell>
      <GoalsTableCell>
        {userEntry.permissions.includes(Permission.SetGoals) ? (
          <img src="/img/goals.svg" alt="Goals" />
        ) : (
          <>&mdash;</>
        )}
      </GoalsTableCell>
      <ButtonsTableCell>
        <TableIconButton
          disableRipple
          onClick={onOpenModal}
          data-automationid="accountManagmentEditUserButton">
          <EditIcon />
        </TableIconButton>

        {!userEntry.isRootUser && (
          <TableIconButton
            onClick={onOpenPrompt}
            data-automationid="accountManagmentDeletUserButton">
            <DeleteIcon />
          </TableIconButton>
        )}

        {isPromptOpen && (
          <ModalPrompt
            title={t('usersTable.deletePromptTitle')}
            text={t('usersTable.deletePromptText', { u: userEntry })}
            cb={onClosePrompt}
          />
        )}

        <PopupMenu
          menuItems={[
            {
              text: t('usersTable.duplicate'),
              onClick: () => openModalUserDuplicate(userEntry.id),
            },
            {
              text: t('usersTable.resetPassword'),
              onClick: () => resetUserPassword(userEntry.id),
            },
            {
              text: t('usersTable.sendInvitation'),
              onClick: () => inviteUser(userEntry.id),
            },
          ]}>
          <TableIconButton disableRipple data-automationid="accountManagmentOpenPopUpButton">
            <IconMoreVert style={{ color: '#7F8FA4' }} />
          </TableIconButton>
        </PopupMenu>
      </ButtonsTableCell>
    </TableRow>
  );
});

export default UsersTableRow;
