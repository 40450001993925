import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { SystemAnalysisViews } from '../../store/storeTypes';

const INIT_STATE: SystemAnalysisViews = 'table'; // any value from enum can work, just for proper init

const { setSystemAnalysisView } = actions.userUi;

export default createReducer<SystemAnalysisViews, any>(INIT_STATE).handleAction(
  setSystemAnalysisView,
  (state, action) => action.payload
);
