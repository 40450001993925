import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { authCallbackPath, overviewReportPath } from 'contracts/constants';
import { WelcomeProps } from './WelcomeContainer';
import LinearProgress from 'components/_common/LinearProgress';

export const Welcome = (props: WelcomeProps) => {
  const { authService, location, getPortalApiUrl, apiUrl } = props;

  useEffect(() => {
    if (authService.isAuthenticated()) {
      getPortalApiUrl();
    }
  }, [apiUrl, authService, getPortalApiUrl]);

  if (authService.isAuthenticated()) {
    if (!apiUrl) {
      return <LinearProgress />;
    }
    return <Redirect to={overviewReportPath} />;
  }

  if (authService.isAuthUrl(location)) {
    return <Redirect to={`${authCallbackPath}${location.hash}`} />;
  }
  authService.login();

  return null;
};
