import React from 'react';
import styled from 'styled-components/macro';
import { subDays, addDays, isSameDay } from 'date-fns';
import FilterDatePicker from '../_common/FilterDatePicker';
import FilterArrows from 'components/_common/FilterArrows';
import { PbiFilterDaysProps } from './PbiFilterDaysContainer';
import { getDayForDate } from 'helpers/date';
import { ReportTypesNew } from 'models/ReportData';

import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import NewDatePicker from './NewDatePicker';

const DaysContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div::before {
    border-bottom: none !important;
  }
  div::after {
    border-bottom: none;
  }
  input {
    font-weight: 400;
    font-size: 16px;
    color: #354052;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const MobileView = styled.div`
  display: none;
  @media (max-width: 750px) {
    display: block;
    width: 100%;
  }
`;

const PbiFilterDays = React.memo((props: PbiFilterDaysProps) => {
  const { lastUpdate, selectedDate, startDate, activeReport, isMobile, setActiveDate } = props;

  const [lowerDate, setLowerDate] = React.useState<Date | null>(null);
  const onChangeActiveDay = (d: Date | undefined) => {
    setActiveDate(d);
  };

  React.useEffect(() => {
    if (lowerDate) setActiveDate(lowerDate);
  }, [lowerDate]); // eslint-disable-line react-hooks/exhaustive-deps
  const renderPicker = (variant: 'dialog' | 'inline' | 'static', activeReport?: ReportTypesNew) => {
    const lower = !!selectedDate && !!lastUpdate && lastUpdate < selectedDate ? lastUpdate : null;
    if (lower && !lowerDate && lowerDate !== lower) setLowerDate(lower);

    return (
      <FilterDatePicker
        data-automationid={`calendar_day_picker`}
        onChange={onChangeActiveDay}
        value={lowerDate || selectedDate || lastUpdate || new Date().toISOString()}
        noHelper
        maxDate={lastUpdate || new Date()}
        minDate={startDate}
        variant={variant}
        disabled={false}
      />
    );
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const disableRight =
    !selectedDate ||
    (lastUpdate && isSameDay(selectedDate, lastUpdate)) ||
    false ||
    (!!lastUpdate && lastUpdate < selectedDate) ||
    (!lastUpdate && today < selectedDate);

  const disableLeft = selectedDate <= startDate;

  const onHandleLeft = () => {
    if (!disableLeft) {
      setActiveDate(subDays(selectedDate || lastUpdate || new Date(), 1));
      KonnectAnalytics.decrementDateInDatePicker();
    }
  };

  const onHandleRight = () => {
    if (!disableRight) {
      setActiveDate(addDays(selectedDate || lastUpdate || new Date(), 1));
      KonnectAnalytics.incrementDateInDatePicker();
    }
  };

  if (!isMobile) {
    const date = lowerDate || selectedDate || lastUpdate;
    return (
      <NewDatePicker
        activeReport={activeReport}
        timeframe={'day'}
        disableLeft={disableLeft}
        disableRight={disableRight}
        date={date}
        lastUpdate={lastUpdate}
        selectedDate={selectedDate}
        startDate={startDate}
        setActiveDate={setActiveDate}
        onHandleLeft={onHandleLeft}
        onHandleRight={onHandleRight}
      />
    );
  } else {
    return (
      <DaysContainer>
        <MobileView>
          <FilterArrows
            dateTitle={getDayForDate(selectedDate).title}
            handleLeft={onHandleLeft}
            handleRight={onHandleRight}
            disableRight={disableRight}
            disableLeft={disableLeft}
          />
          {renderPicker('static')}
        </MobileView>
      </DaysContainer>
    );
  }
});

export default PbiFilterDays;
