import i18next from 'i18next';
import { createStandardAction } from 'typesafe-actions';

import { ExtendedUserData } from '../models/ExtendedUserData';
import { MODAL_STATUS, Entries } from '../store/storeTypes';
import { userUiToApiSaveUser } from '../reducers/_sharedLogic/userConversions';
import apiAction, { apiActionSimple } from './_shared/apiAction';
import handleErrorAction from './_shared/handleErrorAction';
import { makeApiUrl } from './_shared/utils';
import { enqueueNotification } from './uiActions';
import { setCaptchaIsOpen, setStatusModalUser } from './userUiActions';
import config from 'config/config';
import * as _ from 'lodash';
import { AUTH0_CLAIMS_NAMESPACE } from 'contracts/constants';
import { userPermissionsToRoles } from 'helpers/conversions';
import { Method } from 'axios';
const redirectUrl = config.SELF_HOST;
const makeInviteUserUrl = (id: string) => makeApiUrl(`/api/users/${id}/invitation`);
const makeResetUserPasswordUrl = (id: string) => makeApiUrl(`/api/users/${id}/password-reset`);

export const loadUsersStarted = createStandardAction('LOAD_USERS_STARTED')();
export const loadUsersSuccess = createStandardAction('LOAD_USERS_SUCCESS')<ExtendedUserData[]>();
export const loadUsersFailed = createStandardAction('LOAD_USERS_FAILED')<any>();

export interface SaveUserSuccessPayload {
  newUsers: ExtendedUserData[];
  usersById: Entries<ExtendedUserData>;
}

export const saveUserStarted = createStandardAction('SAVE_USER_STARTED')();
export const saveUserSuccess = createStandardAction('SAVE_USER_SUCCESS')<SaveUserSuccessPayload>();
export const saveUserFailed = createStandardAction('SAVE_USER_FAILED')<any>();

export const deleteUserStarted = createStandardAction('DELETE_USER_STARTED')();
export const deleteUserSuccess = createStandardAction('DELETE_USER_SUCCESS')<string>();
export const deleteUserFailed = createStandardAction('DELETE_USER_FAILED')<any>();

export const inviteUserStarted = createStandardAction('INVITE_USER_STARTED')<string>();
export const inviteUserSuccess = createStandardAction('INVITE_USER_SUCCESS')<string>();
export const inviteUserFailed = createStandardAction('INVITE_USER_FAILED')<any>();

export const resetUserPasswordStarted = createStandardAction('RESET_USER_PASSWORD_USER_STARTED')();
export const resetUserPasswordSuccess = createStandardAction('RESET_USER_PASSWORD_USER_SUCCESS')<
  string
>();
export const resetUserPasswordFailed = createStandardAction('RESET_USER_PASSWORD_USER_FAILED')<
  any
>();

export function getUsers() {
  const getExtendedUsersUrl = makeApiUrl('/api/users/extended');
  return apiActionSimple({
    request: { url: getExtendedUsersUrl },
    actions: [loadUsersStarted, loadUsersSuccess, loadUsersFailed],
    interceptor: state => state.api.isLoadingUsers || state.entities.users.allIds.length > 0,
  });
}

export function saveUser() {
  const postUsersUrl = makeApiUrl('/api/users');
  return apiAction({
    request: {
      url: postUsersUrl,
      method: 'POST',
      data: store => [userUiToApiSaveUser(store)],
    },
    logic: {
      onStarted: dispatch => {
        dispatch(saveUserStarted());
        dispatch(setStatusModalUser(MODAL_STATUS.SAVING));
      },
      onSuccess: (data, dispatch, store) => {
        dispatch(
          saveUserSuccess({
            newUsers: data,
            usersById: _.cloneDeep(store.entities.users.byId),
          })
        );
        // For new user - show congrats view, for updated - close popup
        dispatch(
          setStatusModalUser(store.ui.userForm.id ? MODAL_STATUS.CLOSED : MODAL_STATUS.DONE)
        );

        dispatch(
          enqueueNotification(
            i18next.t('notifications.userSaved', {
              u: Array.isArray(data) ? data[0] : data,
            }),
            {
              variant: 'success',
            }
          )
        );
      },

      onFailed: (error, dispatch) => {
        dispatch(setStatusModalUser(MODAL_STATUS.EDITING));
        dispatch(handleErrorAction(error, `API: ${error.message}`, [saveUserFailed]));
      },
    },
  });
}

export function deleteUser(id: string) {
  const deleteUserUrl = makeApiUrl('/api/users');
  return apiAction({
    request: {
      url: `${deleteUserUrl}/${id}`,
      method: 'DELETE',
    },
    logic: {
      onStarted: dispatch => dispatch(deleteUserStarted()),

      onSuccess: (data, dispatch, store) => {
        dispatch(deleteUserSuccess(id));

        const user = store.entities.users.byId[id];
        dispatch(
          enqueueNotification(i18next.t('notifications.userDeleted', { u: user }), {
            variant: 'success',
          })
        );
      },

      onFailed: (error, dispatch) => dispatch(deleteUserFailed(error)),
    },
  });
}

export function inviteUser(id: string) {
  return apiAction({
    request: {
      url: makeInviteUserUrl(id),
      method: 'POST',
      data: { redirectUrl },
    },
    logic: {
      onStarted: dispatch => dispatch(inviteUserStarted(id)),

      onSuccess: (data, dispatch, store) => {
        dispatch(inviteUserSuccess(id));

        const user = store.entities.users.byId[id];
        dispatch(
          enqueueNotification(i18next.t('notifications.userInvited', { u: user }), {
            variant: 'success',
          })
        );
      },

      onFailed: (error, dispatch) => dispatch(inviteUserFailed(error)),
    },
  });
}

export function resetUserPassword(id: string, afterCaptcha?: boolean, captchaToken?: string) {
  const request = {
    url: makeResetUserPasswordUrl(id),
    method: 'POST' as Method,
    data: { redirectUrl },
  };

  return apiAction({
    request: afterCaptcha
      ? {
          ...request,
          headers: {
            'X-Recaptcha-Token': captchaToken,
          },
        }
      : request,

    logic: {
      onStarted: dispatch => dispatch(resetUserPasswordStarted()),

      onSuccess: (data, dispatch, store) => {
        dispatch(resetUserPasswordSuccess(id));

        const user = store.entities.users.byId[id];
        dispatch(
          enqueueNotification(i18next.t('notifications.userPasswordReset', { u: user }), {
            variant: 'success',
          })
        );
      },

      onFailed: (error, dispatch) => {
        dispatch(setCaptchaIsOpen({ isOpen: true, userId: id }));
        dispatch(resetUserPasswordFailed(error));
      },
    },
  });
}
