import styled from 'styled-components/macro';

const Header1 = styled.h1`
  font-family: Rubik, sans-serif;
  font-weight: normal;
  font-size: 22px;
  color: #354052;
  display: flex;
  align-items: center;

  @media (max-width: 440px) {
    margin: 10px 0;
  }
`;

export default Header1;
