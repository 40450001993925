import { OptionsObject } from 'notistack';
import { debounce } from 'lodash';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';

import { ActiveOverviewChart, Store, UserSystemGroup } from '../store/storeTypes';

export const updateLogoutTimerAC = createStandardAction('UPDATE_LOGOUT_TIMER')();
const updateLogoutTimerDebounced = debounce(
  (dispatch: ThunkDispatch<Store, any, Action>) => dispatch(updateLogoutTimerAC()),
  3000
);
export const updateLogoutTimer = () => updateLogoutTimerDebounced;

export interface EnqueueNotificationProps {
  enqueueNotification(message: string, options?: OptionsObject): void;
}

interface EnqueueNotificationPayload {
  message: string;
  key: string;
  options: OptionsObject;
}
export const enqueueNotificationAC = createStandardAction('ENQUEUE_NOTIFICATION')<
  EnqueueNotificationPayload
>();
export function enqueueNotification(message: string, options: OptionsObject = {}) {
  return function(dispatch: ThunkDispatch<Store, any, Action>) {
    dispatch(
      enqueueNotificationAC({
        message,
        key: (new Date().getTime() + Math.random()).toString(),
        options,
      })
    );
  };
}

export const removeNotification = createStandardAction('REMOVE_NOTIFICATION')<string>();

export const setActiveOverviewChart = createStandardAction('SET_ACTIVE_OVERVIEW_CHART')<{
  DTF?: ActiveOverviewChart;
  DTG?: ActiveOverviewChart;
}>();
