import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

import { AuthSupportProps } from 'contracts/auth';

export const AuthCallback = (
  props: AuthSupportProps & RouteComponentProps<Record<string, string>>
) => {
  const { t } = useTranslation();
  const { authService, location } = props;
  authService.handleAuthentication(location);

  return <div>{t('authCallback.loading')}</div>;
};
