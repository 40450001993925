import React, { Fragment } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import {
  createGenerateClassName,
  jssPreset,
  MuiThemeProvider,
  createMuiTheme,
  StylesProvider,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from 'services/AuthProvider';
import history from './helpers/navigation';
import Notifier from './components/_common/Notifier';
import { GlobalStyle } from './globalStyle';
import appTheme from './theme';

import store from './store/store';
import './services/i18n';

import { App } from './App';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const generateClassName = createGenerateClassName();

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const theme = createMuiTheme(appTheme);
export const AppContainer = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <Provider store={store}>
        <ErrorBoundary>
          <Router history={history}>
            <StylesProvider jss={jss} generateClassName={generateClassName}>
              <MuiThemeProvider theme={theme}>
                <AuthProvider>
                  <CssBaseline />

                  <SnackbarProvider
                    maxSnack={4}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    autoHideDuration={10000}>
                    <Notifier />
                  </SnackbarProvider>

                  <App />
                </AuthProvider>
              </MuiThemeProvider>
            </StylesProvider>
          </Router>
        </ErrorBoundary>
      </Provider>
    </Fragment>
  );
};
