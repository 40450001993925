import styled from 'styled-components/macro';

const ModalForm = styled.form`
  padding: 20px 28px 30px 24px;
  &::-webkit-scrollbar-track {
    background: #e3e0e0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1baba;
    border-radius: 10px;
  }
  @media (max-width: 599px) {
    padding-right: 11px;
    padding-left: 11px;
  }
`;

export default ModalForm;
