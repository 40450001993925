import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { GoalType } from '../../models/GoalsData';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const DaysBar = styled.div`
  width: 29px;
  height: 8px;
  background: #c5d0de;
  border-radius: 1px;
  &.passed {
    background: #8963ff;
  }
  @media (max-width: 360px) {
    width: 24px;
  }
`;

const MonthsBar = styled(DaysBar as StyledComponentWithAnyType)`
  width: 34px;
  @media (max-width: 360px) {
    width: 30px;
  }
`;

const DaysContainer = styled(Grid).attrs({
  item: true,
})`
  &:not(:last-child) {
    margin-right: 17px;
  }
`;

const DaysLetter = styled.div`
  font-family: Rubik;
  font-size: 12px;
  text-align: center;
  color: #7f8fa4;
  margin-top: 9px;
`;

const DaysPassed: StyledComponentWithAnyType = styled(Grid).attrs({
  container: true,
  wrap: 'nowrap',
})`
  margin-top: 30px;
  max-width: ${(props: { limit: boolean }) => (props.limit ? '340px' : 'none')};
  &.second-line {
    margin-top: 15px;
  }
`;

interface GoalCalenderProps {
  goalId: GoalType;
  passedDateIndicator: number[];
}

function GoalCalenderIndicator(props: GoalCalenderProps) {
  const { t } = useTranslation();
  const { goalId, passedDateIndicator } = props;

  const calenderLetters =
    goalId === GoalType.Weekly
      ? (t('goals.weekdayLetters') as string[])
      : (t('goals.months') as string[]);

  if (goalId === GoalType.Weekly) {
    return (
      <DaysPassed data-automationid={`weekGoalDayPassedGraph`}>
        {calenderLetters.map((letter, i) => (
          <DaysContainer key={i}>
            <DaysBar className={passedDateIndicator[i] ? 'passed' : ''} />
            <DaysLetter>{letter}</DaysLetter>
          </DaysContainer>
        ))}
      </DaysPassed>
    );
  }

  return (
    <>
      <DaysPassed data-automationid={`yearGoalDayPassedGraph-firstRow`}>
        {calenderLetters
          .filter((v, i) => i < 6)
          .map((letter, i) => (
            <DaysContainer key={i}>
              <MonthsBar className={passedDateIndicator[i] ? 'passed' : ''} />
              <DaysLetter>{letter}</DaysLetter>
            </DaysContainer>
          ))}
      </DaysPassed>
      <DaysPassed data-automationid={`yearGoalDayPassedGraph-secondRow`} className="second-line">
        {calenderLetters
          .filter((v, i) => i >= 6)
          .map((letter, i) => (
            <DaysContainer key={i}>
              <MonthsBar className={passedDateIndicator[i + 6] ? 'passed' : ''} />
              <DaysLetter>{letter}</DaysLetter>
            </DaysContainer>
          ))}
      </DaysPassed>
    </>
  );
}

export default GoalCalenderIndicator;
