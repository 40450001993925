import React from 'react';
import { ErrorMessageType } from './DatePickerPopUp';
import { CalendarRow } from './DatePickerPopUpStyles';
import { globalColors } from 'globalStyle';
import { ReactComponent as WarningIcon } from '../../assets/icons/DatePickerPopUpWarningIcon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/DatePickerPopUpErrorIcon.svg';
import styled from 'styled-components';
type PropsType = {
  error: ErrorMessageType;
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  widht: 100%;
  margin-top: 10px;
  font-size: 10px;
  height: 30px;
`;
const IconWrapper = styled.div`
  display: flex;
`;
const TextWrapper = styled.div<{ color: string }>`
  display: flex;
  margin-left: 10px;
  color: ${props => props.color};
`;
const DatePickerPopUpErrorMessage = (props: PropsType) => {
  const { error } = props;
  const { payload, type } = error;
  const textColor = type === 'error' ? globalColors.regular.text.red : '#F6BB42';
  const icon = type === 'error' ? <ErrorIcon /> : <WarningIcon />;

  return (
    <Wrapper>
      <IconWrapper> {icon}</IconWrapper>
      <TextWrapper color={textColor}>{payload}</TextWrapper>
    </Wrapper>
  );
};

export default DatePickerPopUpErrorMessage;
