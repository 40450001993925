import { combineReducers, Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { Entries, Ids } from '../../store/storeTypes';
import { SystemData } from '../../models/SystemData';

const { loadSystemsSuccess } = actions.scopeApi;

const systemById = createReducer<Entries<SystemData>, RootAction>({}).handleAction(
  loadSystemsSuccess,
  (state, action) => {
    if (action.payload) {
      const newState: Entries<SystemData> = {};

      action.payload.forEach((system: SystemData) => {
        newState[system.id] = system;
      });

      return newState;
    }

    return state;
  }
);

const systemAllIds = createReducer<Ids, RootAction>([]).handleAction(
  loadSystemsSuccess,
  (state, action) => {
    if (action.payload) {
      const newState: Ids = [];

      action.payload.forEach((system: SystemData) => {
        newState.push(system.id);
      });

      return newState;
    }

    return state;
  }
);

const systemEntityReducer: Reducer = combineReducers({
  byId: systemById,
  allIds: systemAllIds,
});

export default systemEntityReducer;
