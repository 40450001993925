import React from 'react';
import { UserSystemGroup } from 'store/storeTypes';
import DesktopDtfTopCards from './DesktopDtfTopCards';
import DesktopDtgApolloTopCards from './DesktopDtgApolloTopCards';
import { ITopCardsProps } from './TopCardsContainer';

const DesktopTopCards = (props: ITopCardsProps) => {
  const { selectedSystemGroup } = props;
  if (UserSystemGroup.DTF !== selectedSystemGroup) {
    return <DesktopDtgApolloTopCards {...props} />;
  } else {
    return <DesktopDtfTopCards {...props} />;
  }
};

export default DesktopTopCards;
