import styled, { css } from 'styled-components/macro';
import MuiModal from 'components/_common/Modal';
import { Typography, Link, FormControlLabel, Checkbox } from '@material-ui/core';

export const StyledModal = styled(MuiModal)(props => {
  return css``;
});

export const StyleText = styled(Typography)(props => {
  return css``;
});

export const StyleLink = styled(Link)(props => {
  return css`
    cursor: pointer;
  `;
});

export const StyledFormControlLabel = styled(FormControlLabel)(props => {
  return css``;
});

export const StyledCheckbox = styled(Checkbox)(props => {
  return css``;
});

export const StyledLayout = styled.div(props => {
  return css`
    padding: 20px;
  `;
});

export const StyledWrapperContent = styled.div(props => {
  return css`
    margin-bottom: 20px;
  `;
});

export const StyledWrapperCheckBox = styled.div(props => {
  return css``;
});
