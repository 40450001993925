import React from 'react';
import MaskProps from './MaskProps';

const MaskSmall = (props: MaskProps) => {
  // tslint:disable: max-line-length
  const { color, height, width } = props;
  return (
    <svg
      width={width || '46'}
      height={height || '52'}
      viewBox="0 0 46 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="avatar-mask-fields"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 0H1V51H45V0ZM42.9186 13.25L23 2L3.08142 13.25V35.75L23 47L42.9186 35.75V13.25Z"
        fill={color || 'white'}
      />
      <g filter="url(#filter0_d)">
        <path
          d="M23 2L42.9186 13.25V35.75L23 47L3.08142 35.75V13.25L23 2Z"
          stroke="black"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.831299"
          y="1.71289"
          width="44.3372"
          height="49.5742"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MaskSmall;
