import { AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { UserModal, MODAL_STATUS } from '../../store/storeTypes';

const INIT_STATE: UserModal = {
  status: MODAL_STATUS.CLOSED,
  lastSavedUserId: '',
  isInvitationSent: false,
};

const {
  openModalUserCreateAC,
  openModalUserEditAC,
  openModalUserDuplicateAC,
  setStatusModalUser,
  closeModalUser,
} = actions.userUi;
const { saveUserSuccess, inviteUserSuccess } = actions.userApi;

export default createReducer<UserModal, RootAction>(INIT_STATE)
  .handleAction([openModalUserCreateAC, openModalUserEditAC, openModalUserDuplicateAC], state => ({
    ...state,
    status: MODAL_STATUS.EDITING,
  }))
  .handleAction(setStatusModalUser, (state, action) => ({
    ...state,
    status: action.payload,
  }))
  .handleAction(closeModalUser, () => ({ ...INIT_STATE }))
  .handleAction(saveUserSuccess, (state, action) => updateLastSavedUserId(state, action))
  .handleAction(inviteUserSuccess, (state, action) => ({
    ...state,
    isInvitationSent: state.lastSavedUserId === action.payload,
  }));

function updateLastSavedUserId(state: UserModal, action: AnyAction): UserModal {
  const savedUser =
    action.payload && Array.isArray(action.payload.newUsers) && action.payload.newUsers[0];

  if (savedUser) {
    return { ...state, lastSavedUserId: savedUser.id };
  }

  return state;
}
