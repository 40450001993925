import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from 'actions';
import { MainReportTypes, ReportTypesNew } from 'models/ReportData';

const { setActiveReportAC } = actions.reportUi;

const activeReportReducer = createReducer<ReportTypesNew, RootAction>(null).handleAction(
  setActiveReportAC,
  (state, action) => action.payload.activeReport
);

export default activeReportReducer;
