import { TreeNodeProps } from 'react-dropdown-tree-select';
import getMinutes from 'date-fns/getMinutes';
import setMinutes from 'date-fns/setMinutes';
import getHours from 'date-fns/getHours';
import setHours from 'date-fns/setHours';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from 'actions';
import { SchedulerForm, AppUser } from 'store/storeTypes';
import { Frequency, Scheduler } from 'models/Scheduler';
import ScopeData from '../../models/ScopeData';
import {
  userDataToTree,
  treeCheckedNodesToUserData,
} from '../_sharedLogic/userScopeTreeConversions';
import { ChangeSchedulerFormScopePayload } from 'actions/schedulerUiActions';
import {
  treeCheckedNodesToSchedulerData,
  appUserDataScopeData,
  schedulerApiToSchedulerForm,
} from '../_sharedLogic/schedulerConversions';

const INIT_STATE: SchedulerForm = {
  reportId: '',
  emailSubject: 'My report',
  report: null,
  startDate: new Date(),
  repeat: null,
  weekdays: [],
  notes: '',
  sending: true,
  customerIds: [], // Selected scope
  siteIds: [],
  systemIds: [],
  userOrgs: { byId: {}, allIds: [] }, // Shown nodes
  userSites: { byId: {}, allIds: [] },
  userSystems: { byId: {}, allIds: [] },
  disabledOrgIds: [], // Shown but unavailable
  disabledSiteIds: [],
  systemsTree: [], // Tree
  isFormTouched: false,
};

const {
  openModalSchedulerCreateAC,
  openModalSchedulerEditAC,
  openModalSchedulerDuplicateAC,
  changeSchedulerFormScopeAC,
  changeSchedulerFormValue,
  changeSchedulerFormStartDate,
  changeSchedulerFormStartTime,
  changeSchedulerFormRepeat,
} = actions.schedulerUi;

export default createReducer<SchedulerForm, RootAction>(INIT_STATE)
  .handleAction(openModalSchedulerCreateAC, (state, action) =>
    openModalSchedulerCreate(state, action.payload)
  )
  .handleAction(openModalSchedulerEditAC, (state, action) =>
    openModalSchedulerEdit(state, action.payload)
  )
  .handleAction(openModalSchedulerDuplicateAC, (state, action) =>
    openModalSchedulerDuplicate(state, action.payload)
  )
  .handleAction(changeSchedulerFormScopeAC, (state, action) =>
    changeSchedulerFormScope(state, action.payload)
  )
  .handleAction(changeSchedulerFormValue, (state, action) =>
    changeFormPlainValue(state, action.payload)
  )
  .handleAction(changeSchedulerFormStartDate, (state, action) =>
    changeFormStartDate(state, action.payload)
  )
  .handleAction(changeSchedulerFormStartTime, (state, action) =>
    changeFormStartTime(state, action.payload)
  )
  .handleAction(changeSchedulerFormRepeat, (state, action) =>
    changeFormRepeat(state, action.payload)
  );

const changeFormStartDate = (state: SchedulerForm, value: Date) => {
  const date = new Date(value);
  const oldDateHours = getHours(date);
  const oldDateMinutes = getMinutes(date);
  let newDate = setHours(value, oldDateHours);
  newDate = setMinutes(newDate, oldDateMinutes);

  return { ...state, startDate: newDate, isFormTouched: true };
};

const changeFormStartTime = (state: SchedulerForm, value: Date) => {
  const time = new Date(value);
  const newDateHours = getHours(time);
  const newDateMinutes = getMinutes(time);
  let newDate = setHours(new Date(state.startDate), newDateHours);
  newDate = setMinutes(newDate, newDateMinutes);

  return { ...state, startDate: newDate, isFormTouched: true };
};

const changeFormRepeat = (state: SchedulerForm, value: Frequency | null) => {
  let weekdays = [...state.weekdays];

  if (value === null || Number(value) !== Frequency.Weekly) {
    weekdays = [];
  }

  return { ...state, repeat: value, weekdays, isFormTouched: true };
};

const openModalSchedulerCreate = (
  state: SchedulerForm,
  payload: ScopeData & { appUser: AppUser }
): SchedulerForm => {
  const { customers, sites, systems } = payload;
  const { customerIds, siteIds, systemIds } = payload.appUser;
  const scope = appUserDataScopeData(customerIds!, siteIds!, systemIds!, customers, sites, systems);

  return {
    ...INIT_STATE,
    userOrgs: scope.customers,
    userSites: scope.sites,
    userSystems: scope.systems,
    systemsTree: userDataToTree(scope.customers, scope.sites, scope.systems),
  };
};

const openModalSchedulerEdit = (
  state: SchedulerForm,
  payload: ScopeData & { appUser: AppUser } & { scheduler: Scheduler }
): SchedulerForm => {
  const { scheduler } = payload;
  const { customers, sites, systems } = payload;
  const { customerIds, siteIds, systemIds } = payload.appUser;
  const scope = appUserDataScopeData(customerIds!, siteIds!, systemIds!, customers, sites, systems);

  return {
    ...schedulerApiToSchedulerForm(scheduler, scope.customers, scope.sites, scope.systems),
    userOrgs: scope.customers,
    userSites: scope.sites,
    userSystems: scope.systems,
  };
};

const openModalSchedulerDuplicate = (
  state: SchedulerForm,
  payload: ScopeData & { appUser: AppUser } & { scheduler: Scheduler }
): SchedulerForm => {
  const { scheduler } = payload;
  const { customers, sites, systems } = payload;
  const { customerIds, siteIds, systemIds } = payload.appUser;
  const scope = appUserDataScopeData(customerIds!, siteIds!, systemIds!, customers, sites, systems);

  return {
    ...schedulerApiToSchedulerForm(scheduler, scope.customers, scope.sites, scope.systems),
    reportId: '0',
    userOrgs: scope.customers,
    userSites: scope.sites,
    userSystems: scope.systems,
  };
};

const changeSchedulerFormScope = (
  state: SchedulerForm,
  payload: ChangeSchedulerFormScopePayload
): SchedulerForm => {
  const { customers, sites, systems } = payload;
  const { userCustomerIds, userSiteIds, userSystemIds } = payload;
  const nodes: TreeNodeProps[] = payload.selectedNodes;
  // Convert selected nodes came from SelectTree component to arrays of IDs
  const { customerIds, siteIds, systemIds } = treeCheckedNodesToUserData(
    nodes,
    state.customerIds,
    state.siteIds
  );
  const { customerDataIds, siteDataIds, systemDataIds } = treeCheckedNodesToSchedulerData(
    nodes,
    state.customerIds,
    state.siteIds,
    userCustomerIds,
    userSiteIds,
    userSystemIds,
    customers,
    sites,
    systems
  );
  // Make new tree for SelectTree
  const systemsTree = userDataToTree(
    // Directories of all entries
    customers,
    sites,
    systems,
    // Currently selected entries
    customerIds,
    siteIds,
    systemIds,
    // Previously selected entries to perform descendants unchecking
    state.customerIds,
    state.siteIds
  );

  return {
    ...state,
    customerIds: customerDataIds,
    siteIds: siteDataIds,
    systemIds: systemDataIds,
    systemsTree,
    isFormTouched: true,
  };
};
export type ChangeFormPlainValuePropTypes = {
  field: string | number | symbol;
  value: Record<string, unknown>;
};
const changeFormPlainValue = (
  state: SchedulerForm,
  payload: ChangeFormPlainValuePropTypes
): SchedulerForm => {
  const { field, value } = payload;

  return { ...state, [field]: value, isFormTouched: true };
};
