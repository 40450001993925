import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import FilterCalenderModal from './FilterCalendarModal';
import { Store, MODAL_STATUS } from 'store/storeTypes';
import { closeCalendarModal } from 'actions/filterCalendarUiAction';
import {
  applyMobileFilters,
  jumpToLatestPeriod,
  setActiveDate,
  setFilterFromUriQuery,
  setTimeframe,
} from 'actions/reportFilterActions';
import {
  pbiReportHeightSelector,
  screenHeightSelector,
  screenWidthSelector,
} from 'reducers/appUserReducer';
import { selectedDateSelector, timeFrameSelector } from 'reducers/reports/filterStateReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { endDateSelector, startDateSelector } from 'reducers/reports/filterDataReducer';
type OwnProps = {
  isMobile?: boolean;
};
const mapStateToProps = (state: Store) => ({
  selectedSystemGroup: systemGroupSelector(state),
  isOpen: state.ui.filterCalenderModal.status !== MODAL_STATUS.CLOSED,
  filterState: state.reports.filterState[state.ui.selectedSystemGroup]!,
  filterData: state.reports.filterData,
  screenWidth: screenWidthSelector(state),
  screenHeight: screenHeightSelector(state),
  pbiReportHeight: pbiReportHeightSelector(state),
  timeframe: timeFrameSelector(state),
  startDate: startDateSelector(state),
  endDate: endDateSelector(state),
  selectedDay: selectedDateSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeCalendarModal,
      applyMobileFilters,
      setActiveDay: setActiveDate,
      jumpToLatestPeriod,
      setFilterFromUriQuery,
      setActiveDate,
      setTimeframe,
    },
    dispatch
  );

export type FilterCalenderModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(FilterCalenderModal);
