import React, { useState } from 'react';
import {
  StyledModal,
  StyleText,
  StyleLink,
  StyledCheckbox,
  StyledFormControlLabel,
  StyledLayout,
  StyledWrapperCheckBox,
  StyledWrapperContent,
} from './TermsModalStyles';
import { PRIVACY_POLICY_PDF, PRODUCT_TC_PDF, TERMS_OF_USE_PDF } from 'contracts/constants';

interface ITermsModalProps {
  action: (isApproved: boolean) => void;
}
export const TermsModal = React.memo((props: ITermsModalProps) => {
  const [checked, setChecked] = useState(false);
  const { action } = props;

  const onClose = () => {
    setChecked(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    action(event.target.checked);
    onClose();
  };

  return (
    <StyledModal isOpen={!checked} onClose={onClose} disableBackdropClick renderCloseButton={false}>
      <StyledLayout>
        <StyledWrapperContent>
          <StyleText>
            Please confirm that by logging-in to and/or using the Kornit Konnect website and/or App.
            you agree to the{' '}
            <StyleLink href={TERMS_OF_USE_PDF} target="_blank" rel="noopener noreferrer">
              Kornit Konnect Terms of Use
            </StyleLink>{' '}
            and you consent to the collection and the processing of your personal data by Kornit,
            including tracking of your activities within Konnect, under the terms of the Kornit
            <StyleLink href={PRIVACY_POLICY_PDF} target="_blank" rel="noopener noreferrer">
              {' '}
              Privacy Policy
            </StyleLink>
          </StyleText>
        </StyledWrapperContent>
        <StyledWrapperCheckBox>
          <StyledFormControlLabel
            control={<StyledCheckbox checked={checked} onChange={handleChange} color="primary" />}
            label="Agree"
          />
        </StyledWrapperCheckBox>
      </StyledLayout>
    </StyledModal>
  );
});
