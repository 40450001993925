import { appearanceEffect } from 'globalStyle';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { TimeFrame } from 'models/ReportData';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
const TimeframeButtonStyled = styled.button<{ isActive: boolean; width: string }>`
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${props => props.width};
  background-color: ${props => (props.isActive ? '#2EA1F8' : '#ffffff')};
  color: ${props => (props.isActive ? '#ffffff' : '#7f8fa4;')};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
const TimeframeButton = props => {
  const { item, timeframe, startDate, setTimeframe, activeReport } = props;

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (timeframe === item.toLowerCase()) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [timeframe]);
  const clickHandler = () => {
    setTimeframe(item.toLowerCase() as TimeFrame, startDate);
  };
  const getElementWidth = (item, activeReport): string => {
    if (activeReport !== 'jobs') {
      return '25%';
    } else {
      if (item === 'Month' || item === 'Quarter') {
        return '25%';
      } else if (item === 'Week') {
        return '18%';
      } else {
        return '16%';
      }
    }
  };
  const elementWidth = getElementWidth(item, activeReport);
  return (
    <TimeframeButtonStyled isActive={isActive} width={elementWidth} onClick={clickHandler}>
      {item}
    </TimeframeButtonStyled>
  );
};
export default TimeframeButton;
