import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { PbiFilterWeeksProps } from './PbiFilterWeeksContainer';
import DateWeekPicker from '../_common/DateWeekPicker';
import FilterArrows from '../_common/FilterArrows';
import { generateDatesForPeriod, getWeekForDate, isSameDay } from '../../helpers/date';
import { add, addWeeks, startOfWeek, subWeeks, isSameYear, endOfWeek } from 'date-fns';
import DateWeekPickerMobile from 'components/_common/DateWeekPickerMobile';
import NewDatePicker from './NewDatePicker';

const Desktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  input {
    cursor: pointer;
  }
  @media (max-width: 750px) {
    display: none;
  }
`;

const WeeksContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div::before {
    border-bottom: none !important;
  }
  div::after {
    border-bottom: none;
  }
  input {
    font-weight: 400;
    font-size: 16px;
    color: #354052;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const PbiFilterWeeks = React.memo((props: PbiFilterWeeksProps) => {
  const {
    activeReport,
    startDate,
    endDate,
    selectedDate,
    isMobile,
    screenWidth,
    setActiveDate,
    closeCalendarModal,
  } = props;
  const weekStartDates = useMemo(
    () =>
      generateDatesForPeriod(startDate, endDate, addWeeks, (date: Date) =>
        startOfWeek(date, { weekStartsOn: 1 })
      ),
    [startDate, endDate]
  );

  const handleChange = (d: Date) => {
    const cloneDate = new Date(d.getTime());
    const i = weekStartDates.findIndex(weekStartDay => {
      return isSameDay(startOfWeek(cloneDate, { weekStartsOn: 1 }), weekStartDay);
    });
    i >= 0 && setActiveDate(d);
  };
  const handleMobileChange = (d: Date) => {
    const cloneDate = new Date(d.getTime());
    const i = weekStartDates.findIndex(weekStartDay => {
      return isSameDay(startOfWeek(cloneDate, { weekStartsOn: 1 }), weekStartDay);
    });
    i >= 0 && setActiveDate(d);
    closeCalendarModal();
  };

  const getActiveWeek = () => {
    const endWeekDay = add(selectedDate, { days: 7 });
    const result = getWeekForDate(selectedDate).title;
    if (!isSameYear(selectedDate, endWeekDay)) {
      const [leftPart, rightPart] = result.split(',');
      const incrementedYear = Number(rightPart) + 1;
      return `${leftPart} , ${incrementedYear}`;
    }
    return result;
  };

  const onHandleLeft = () => {
    if (!disableLeft) {
      setActiveDate(subWeeks(selectedDate, 1));
    }
  };

  const onHandleRight = () => {
    if (!disableRight) {
      setActiveDate(addWeeks(selectedDate, 1));
    }
  };

  const renderPicker = (variant: 'dialog' | 'inline' | 'static') => {
    return (
      <DateWeekPicker
        data-automationid={`calendar_week_picker`}
        label=""
        onChange={handleChange}
        value={selectedDate}
        labelFunc={getActiveWeek}
        noHelper
        minDate={(weekStartDates && weekStartDates[0]) || new Date()}
        maxDate={
          getWeekForDate(weekStartDates && weekStartDates[weekStartDates?.length - 1])?.endDate
        }
        views={['date']}
        variant={variant}
      />
    );
  };
  const renderPickerMobile = (variant: 'dialog' | 'inline' | 'static') => {
    return (
      <DateWeekPickerMobile
        isMobile={isMobile}
        screenWidth={screenWidth}
        data-automationid={`calendar_week_picker`}
        label=""
        onChange={handleMobileChange}
        closeCalendarModal={closeCalendarModal}
        value={selectedDate}
        labelFunc={getActiveWeek}
        noHelper
        minDate={weekStartDates[0]}
        maxDate={getWeekForDate(weekStartDates[weekStartDates.length - 1]).endDate}
        views={['date']}
        variant={variant}
      />
    );
  };
  // const dateTitle = formatWeekTitle(weeks[activeWeekIndex]);
  const disableLeft =
    weekStartDates && startOfWeek(selectedDate, { weekStartsOn: 1 }) <= weekStartDates[0];
  const disableRight =
    weekStartDates &&
    endOfWeek(selectedDate, { weekStartsOn: 1 }) >= weekStartDates[weekStartDates.length - 1];
  if (!isMobile) {
    return (
      <NewDatePicker
        timeframe={'week'}
        onHandleLeft={onHandleLeft}
        onHandleRight={onHandleRight}
        disableLeft={disableLeft}
        disableRight={disableRight}
        date={selectedDate}
        lastUpdate={endDate}
        selectedDate={selectedDate}
        startDate={startDate}
        setActiveDate={setActiveDate}
        activeReport={activeReport}
      />
    );
  } else {
    return (
      <>
        {isMobile ? (
          <> {renderPickerMobile('static')}</>
        ) : (
          <WeeksContainer>
            <Desktop>
              {renderPicker('inline')}
              <FilterArrows
                handleLeft={onHandleLeft}
                handleRight={onHandleRight}
                disableLeft={disableLeft}
                disableRight={disableRight}
              />
            </Desktop>
          </WeeksContainer>
        )}
      </>
    );
  }
});

export default PbiFilterWeeks;
