import styled from 'styled-components/macro';

import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiPaper from '@material-ui/core/Paper';
import MuiIconButton from '@material-ui/core/IconButton';
import { StyledComponentWithAnyType } from 'store/storeTypes';

export const Paper = styled(MuiPaper)`
  border-radius: 0;
  flex-grow: 1;
  display: flex;
  max-height: 100%;
  overflow: auto;
`;

export const Table = styled(MuiTable)`
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  border-radius: 0;
  overflow: auto;
`;

export const TableHead = styled(MuiTableHead)``;

export const TableHeadRow = styled(MuiTableRow)`
  display: flex;
  width: 100%;
  height: inherit;
`;

export const TableHeadCell: StyledComponentWithAnyType = styled(MuiTableCell)`
  display: block;
  color: #354052;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  border-bottom: 1px solid #7f8fa4;
  padding-top: 18px;
  padding-bottom: 16px;
  padding-right: 40px;

  &:not(:first-child) {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 19px;
  }

  @media (max-width: 550px) {
    padding-right: 12px;
    &:first-child {
      padding-left: 12px;
    }
  }
`;

export const TableBody = styled(MuiTableBody)`
  display: block;
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c1baba #e3e0e0;

  &::-webkit-scrollbar-track {
    background: #e3e0e0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1baba;
    border-radius: 10px;
  }
`;

export const TableRow: StyledComponentWithAnyType = styled(MuiTableRow)`
  display: flex;
  height: auto;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  &.selected,
  &.focus,
  &:hover {
    td {
      color: #354052;
      background: #f2f9ff;
    }

    path.avatar-mask-fields {
      fill: #f2f9ff;
    }

    .buttons-cell {
      opacity: 1;
    }
  }

  .buttons-cell {
    opacity: 0;
  }
`;

export const TableCell: StyledComponentWithAnyType = styled(MuiTableCell)`
  padding-top: 0.75rem;
  padding-right: 40px;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #7f8fa4;
  border-bottom: none;

  &:not(:first-child) {
    padding-left: 1px;
  }

  @media (max-width: 550px) {
    padding-right: 12px;
    &:first-child {
      padding-left: 12px;
    }

    .hideOnSmall {
      display: none;
    }
  }
`;
export const TableCellForSystemAnalysisTable: StyledComponentWithAnyType = styled(MuiTableCell)`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #7f8fa4;
  border-bottom: none;

  &:not(:first-child) {
    padding-left: 1px;
  }

  @media (max-width: 550px) {
    padding-right: 12px;
    &:first-child {
      padding-left: 12px;
    }

    .hideOnSmall {
      display: none;
    }
  }
`;

export const ButtonsTableCell: StyledComponentWithAnyType = styled(TableCell).attrs({
  classes: { root: 'buttons-cell' },
})`
  width: 121px;
  max-width: 121px;
  white-space: nowrap;
  padding-right: 19px !important;
  text-align: right;

  svg {
    color: #7f8fa4;
  }
`;

export const TableIconButton = styled(MuiIconButton)`
  padding: 0;
  cursor: pointer;
`;
