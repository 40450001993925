import { endOfMonth, endOfQuarter, endOfWeek, endOfYear, isBefore, startOfMonth } from 'date-fns';
import {
  ColorCompareChart,
  ColorConsumptionChart,
  InkConsumptionChart,
} from 'models/JobAnalysisData';
import { TimeFrameEnum } from 'models/ReportData';
export const getTextForLabelInkConsumptionTrendGraph = (
  timeframe,
  dateName,
  selectedDay,
  index
) => {
  if (timeframe !== 'week') {
    return dateName;
  } else {
    const weekDayShortNames = [
      { short: 'Mon', full: 'Monday' },
      { short: 'Tue', full: 'Tuesday' },
      { short: 'Wed', full: 'Wednesday' },
      { short: 'Thu', full: 'Thursday' },
      { short: 'Fri', full: 'Friday' },
      { short: 'Sat', full: 'Saturday' },
      { short: 'Sun', full: 'Sunday' },
    ];
    const currentDay = weekDayShortNames.filter(day => day.full === dateName);
    const text = timeframe === 'week' ? currentDay[0] && currentDay[0].short : dateName;
    const firstDayOfWeek = new Date(selectedDay);
    const currentDate = new Date(
      firstDayOfWeek.getFullYear(),
      firstDayOfWeek.getMonth(),
      firstDayOfWeek.getDate() + index
    );
    const dateSuffix = `${currentDate.getDate()}/${currentDate.getMonth() + 1}`;
    return `${text} ${dateSuffix}`;
  }
};
export const getOffsetForDayLabel = (index, visibleTicksCount) => {
  if (index <= 2) {
    return 3;
  }
  if (index >= 12 && index <= visibleTicksCount - 4) {
    return 2;
  } else {
    return 0;
  }
};
export const getCurrentDayNameObject = (length, index, selectedDate) => {
  const DaysArray = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const firstDayOfCurrentMonth = startOfMonth(new Date(selectedDate));
  const LAST_INDEX_OF_NEXT_MONTH = length - 1;
  const FIRST_INDEX_OF_CURRENT_MONTH = 3;
  const LAST_INDEX_OF_CURRENT_MONTH = LAST_INDEX_OF_NEXT_MONTH - 3;
  const LAST_INDEX_OF_PREV_MONTH = 2;
  const FIRST_INDEX_OF_NEXT_MONTH = LAST_INDEX_OF_CURRENT_MONTH + 1;

  if (index <= LAST_INDEX_OF_PREV_MONTH) {
    const firstDateOfPrevMont = new Date(
      firstDayOfCurrentMonth.getFullYear(),
      firstDayOfCurrentMonth.getMonth(),
      firstDayOfCurrentMonth.getDate() - 3
    );
    const currentDateDay = new Date(
      firstDateOfPrevMont.getFullYear(),
      firstDateOfPrevMont.getMonth(),
      firstDateOfPrevMont.getDate() + index
    ).getDay();
    const result = {
      currrentIndex: index,
      isPrevMonth: true,
      isNextMonth: false,
      isCurrentMonth: false,
      name: DaysArray[currentDateDay],
      isMonday: currentDateDay === 1,
    };
    return result;
  }
  if (index >= FIRST_INDEX_OF_CURRENT_MONTH && index <= LAST_INDEX_OF_CURRENT_MONTH) {
    const currentDayNumber = index - 3;
    const currentDateDay = new Date(
      firstDayOfCurrentMonth.getFullYear(),
      firstDayOfCurrentMonth.getMonth(),
      firstDayOfCurrentMonth.getDate() + currentDayNumber
    ).getDay();
    const result = {
      currrentIndex: index,
      isPrevMonth: false,
      isNextMonth: false,
      name: DaysArray[currentDateDay],
      isCurrentMonth: true,
      isMonday: currentDateDay === 1,
    };

    return result;
  }
  if (index >= LAST_INDEX_OF_CURRENT_MONTH && index <= LAST_INDEX_OF_NEXT_MONTH) {
    const firstDateOfNextMonth = new Date(
      firstDayOfCurrentMonth.getFullYear(),
      firstDayOfCurrentMonth.getMonth() + 1
    );
    const currentDateIndex = index - FIRST_INDEX_OF_NEXT_MONTH;
    const currentDateDay = new Date(
      firstDateOfNextMonth.getFullYear(),
      firstDateOfNextMonth.getMonth(),
      firstDateOfNextMonth.getDate() + currentDateIndex
    ).getDay();
    const result = {
      currrentIndex: index,
      isPrevMonth: false,
      isNextMonth: true,
      name: DaysArray[currentDateDay],
      isCurrentMonth: false,
      isMonday: currentDateDay === 1,
    };

    return result;
  }
};
export const getColor = (isCurrentMonth, isMonday, index, isPrevMonth, length) => {
  if (!isCurrentMonth) {
    if (index === 0 || index === length - 1) {
      return '#c5d0de52';
    }
    if (index === 1 || index === length - 2) {
      return '#c5d0de8b';
    }
    return '#C5D0DE';
  }
  if (isCurrentMonth && isMonday) {
    return '#354052';
  }
  if (isCurrentMonth) {
    return '#7F8FA4';
  }
};
export const getXCoordinateOffsetInkConsumptionChart = (
  width,
  visibleTicksCount,
  index,
  timeframe,
  x
) => {
  const intervalLength = width / visibleTicksCount;
  const perCent = intervalLength / 100;
  let centerX;
  let leftBorder;
  let rightBorder;
  leftBorder = index * intervalLength;
  rightBorder = leftBorder + intervalLength;
  centerX = (leftBorder + rightBorder) / 2;
  if (index === 2) {
    centerX = centerX + 7 * perCent;
  }
  if (index === 3) {
    centerX = centerX + 7 * perCent;
  }
  if (index === 4) {
    centerX = centerX + 19 * perCent;
  }
  if (index === 5) {
    centerX = centerX + 22 * perCent;
  }
  if (index === 6) {
    centerX = centerX + 22 * perCent;
  }
  const X = timeframe !== 'month' ? centerX : x - 5;
  return X;
};

const isSomeColorInRecordNull = (colorsArray, record) => {
  if (colorsArray.some(color => record[color] === null)) {
    return true;
  } else {
    return false;
  }
};
const setColorValueForFutureDaysOnNull = incomeData => {
  const colors = [...incomeData.colors];
  const records = new Array();
  incomeData.records.forEach(record => {
    const isNull = isSomeColorInRecordNull(colors, record);
    const newRecord = { ...record };
    if (isNull) {
      newRecord['Total'] = null;
    }
    records.push(newRecord);
  });
  const filteredData = {
    colors,
    records,
  };
  return { ...filteredData };
};
export const jobAnalysisIncomeDataMapper = (incomeData, timeframe, selectedDay) => {
  if (!incomeData) return;

  const filteredData = setColorValueForFutureDaysOnNull(incomeData);
  return { ...filteredData };
};
export const getPrinterName = (systemNickName, systemName?, serial?) => {
  if (systemNickName === null) {
    return serial;
  } else if (systemNickName === '') {
    return serial;
  } else {
    return systemNickName;
  }
};
export const getModelnameNickName = (filterData, printer, systemAnalysisData) => {
  if (!printer) {
    return { model: 'no model detected', nickName: 'no nick name detected' };
  } else {
    const systemSerialNumber = printer && printer.serialNumber;
    const currentSystem = filterData.systems.find(system => system.name === systemSerialNumber);
    if (!currentSystem) {
      //*in case of in filterData there is no printer like in arg1. It could happend when selected all printers. then in system analysis displays all systems not only systems from filterData
      const nickName = printer.systemNickName
        ? printer.systemNickName
        : systemAnalysisData.find(system => system.serialNumber === printer.serialNumber)
            .systemNickName;
      return { model: printer.systemModel, nickName: nickName ? nickName : printer.serialNumber };
    }

    const calculatedName = currentSystem.systemNickName;
    const nickName = calculatedName === '' ? printer.serialNumber : calculatedName;

    return { model: printer.systemModel, nickName };
  }
};

type StartEndDateObject = {
  startDate: Date;
  endDate: Date;
};

export const getLocalEmptyStates = (
  totalProduction: number,
  isJobAnalysisChartsLoaded: boolean,
  inkConsumptionChart: InkConsumptionChart,
  colorConsumptionChart: ColorConsumptionChart,
  colorCompareChart: ColorCompareChart
) => {
  if (
    !totalProduction &&
    isJobAnalysisChartsLoaded &&
    !inkConsumptionChart?.colors.length &&
    !colorConsumptionChart?.colors.length &&
    !colorCompareChart?.colors.length
  ) {
    return {
      isBottomBlockHasEmptyState: true,
      isChartsHaveEmptyState: true,
    };
  }
  if (
    totalProduction &&
    isJobAnalysisChartsLoaded &&
    !inkConsumptionChart?.colors.length &&
    !colorConsumptionChart?.colors.length &&
    !colorCompareChart?.colors.length
  ) {
    return {
      isBottomBlockHasEmptyState: false,
      isChartsHaveEmptyState: true,
    };
  }
  return {
    isBottomBlockHasEmptyState: false,
    isChartsHaveEmptyState: false,
  };
};
type getJobAnalysisTopCardsWidthProps = Array<any>;
type TopCardsWidthType = {
  leftCardWidth: number;
  middleCardWidth: number;
  rightCardWidth: number;
};
export const getJobAnalysisTopCardsWidth = (
  filteredBottles: getJobAnalysisTopCardsWidthProps
): TopCardsWidthType => {
  const result = {
    leftCardWidth: 33,
    middleCardWidth: 33,
    rightCardWidth: 33,
  };
  if (!filteredBottles) {
    return result;
  }
  if (filteredBottles.length < 3) {
    // * if in mid card <= 3 types of btls - each card also take 33%;
    return result;
  }
  if (filteredBottles.length >= 3 && filteredBottles.length < 6) {
    result.middleCardWidth = 48.8;
    result.leftCardWidth = 24.2;
    result.rightCardWidth = 24;
    return result;
  }
  if (filteredBottles.length >= 6) {
    result.rightCardWidth = 24;
    result.middleCardWidth = 59;
    result.leftCardWidth = 16;
    return result;
  } else {
    return result;
  }
};
export const isShowInkConsumptionButtonForDtf = (timeframe, selectedDate) => {
  //we have to detect if selected date if end of selected period is before start date of feature, if yes - we have to disable button and show specil tooltip
  const START_FEATURE_DATE_FOR_DTF = new Date('2024-01-31');
  const lastDayOfPeriod = getLastDateOfSelectedTimeFrame(timeframe, new Date(selectedDate));
  if (isBefore(lastDayOfPeriod, START_FEATURE_DATE_FOR_DTF)) {
    return false;
  }
  return true;
};
const getLastDateOfSelectedTimeFrame = (timeframe, selectedDate) => {
  switch (timeframe) {
    case TimeFrameEnum.day:
      return selectedDate;
    case TimeFrameEnum.week:
      return endOfWeek(selectedDate, { weekStartsOn: 1 });
    case TimeFrameEnum.month:
      return endOfMonth(selectedDate);
    case TimeFrameEnum.quarter:
      return endOfQuarter(selectedDate);
    case TimeFrameEnum.year:
      return endOfYear(selectedDate);

    default:
      return selectedDate;
  }
};
export const mapZeroValuesBolltesToNull = data => {
  //* by contract if there was no replacement of bottles there should be empty array or array with null values, now it was decided
  //* that there could be 0 values. we have to transform all 0 values to null to fire empyt state logic for total bottles loaded card.
  return data.totalBottlesLoadedResponse.bottles.map(bottle => {
    if (bottle.colorValue === 0) {
      return { ...bottle, colorValue: null };
    }
    return bottle;
  });
};
