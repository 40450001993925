import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';

import PageContainer from '../_common/PageContainer';
import { PageHeaderUIContainer, PageHeaderUISpacer } from '../_common/PageHeaderUi';
import ButtonHeader from '../_common/ButtonHeader';
import Header1 from '../_common/Header1';
import SchedulerTable from './SchedulerTableContainer';
import SchedulerModal from '../SchedulerModal/SchedulerModalContainer';
import SchedulerPreview from '../SchedulerPreview/SchedulerPreviewContainer';
import { SchedulerProps } from './SchedulerContainer';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const SchedulerButtonHeader = styled<StyledComponentWithAnyType>(ButtonHeader)`
  width: 200px;
  span {
    text-transform: capitalize;
    font-size: 12px;
  }
  @media (max-width: 440px) {
    padding: 0 5px;
    svg {
      display: none;
    }
  }
`;

const Icon = styled.img.attrs({
  src: '/img/email-scheduler-icon.svg',
})`
  margin: 0 16px 3px 0;
  @media (max-width: 360px) {
    display: none;
  }
`;

const Scheduler = React.memo((props: SchedulerProps) => {
  const { t } = useTranslation();
  const {
    isLoadingTypes,
    getAppUserFull,
    openModalSchedulerCreate,
    getScopeData,
    getSchedulers,
    getSchedulersReportTypes,
  } = props;

  useEffect(() => {
    getAppUserFull();
    // getSchedulersReportTypes();
    getScopeData();
    getSchedulers();
  }, [getAppUserFull, getScopeData, getSchedulers, getSchedulersReportTypes]);

  return (
    <PageContainer>
      <PageHeaderUIContainer>
        <Header1>
          <Icon />
          {t('scheduler.header')}
        </Header1>

        <PageHeaderUISpacer>
          {/* create new button disabled */}
          {/* {!isLoadingTypes && (
            <SchedulerButtonHeader onClick={openModalSchedulerCreate}>
              <AddIcon />
              <Typography noWrap variant="button">
                {t('scheduler.createNew')}
              </Typography>
            </SchedulerButtonHeader>
          )} */}
        </PageHeaderUISpacer>
      </PageHeaderUIContainer>

      <SchedulerTable />

      <SchedulerModal />

      <SchedulerPreview />
    </PageContainer>
  );
});

export default Scheduler;
