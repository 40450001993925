import { TreeNodeProps, TreeNode } from 'react-dropdown-tree-select';

interface RootElement {
  id: string;
  name: string;
}
interface ParentElement extends RootElement {
  rootId: string;
}
interface ChildElement extends ParentElement {
  parentId: string;
}
interface TreeSourceData {
  // Roots >> Parents >> Children // named just for convenience until nesting is limited with 3 levels
  // Tree elements
  rootElements: RootElement[];
  parentElements: ParentElement[];
  childElements: ChildElement[];
  // Selected elements
  rootSelectedIds?: string[];
  parentSelectedIds?: string[];
  childSelectedIds?: string[];
  // Just unchecked elements (workaround to properly handle root/parent nodes unchecking)
  rootForceUncheckIds?: string[];
  parentForceUncheckIds?: string[];
  // Disabled tree nodes (descendants are also will be unchecked)
  rootDisabledIds?: string[];
  parentDisabledIds?: string[];
  // Folded tree nodes
  rootFoldedIds?: string[];
  parentFoldedIds?: string[];

  enableLowLevelSelect?: boolean;
}

export function makeTreeData(input: TreeSourceData) {
  const {
    rootElements,
    parentElements,
    childElements,
    rootSelectedIds = [],
    parentSelectedIds = [],
    childSelectedIds = [],
    rootForceUncheckIds = [],
    parentForceUncheckIds = [],
    rootDisabledIds = [],
    parentDisabledIds = [],
    rootFoldedIds = [],
    parentFoldedIds = [],
    enableLowLevelSelect = false,
  } = input;

  return rootElements.map(rootEl => makeRootBranch(rootEl));

  function makeRootBranch(rootEl: RootElement): TreeNodeProps {
    // Root granted => all descendants should be checked and disabled
    const isRootGranted = rootSelectedIds.indexOf(rootEl.id) >= 0;
    // Root just unchecked => force all descendants to be unchecked and enabled
    const isRootUnchecked = !isRootGranted && rootForceUncheckIds.indexOf(rootEl.id) >= 0;

    const isRootDisabled = rootDisabledIds.indexOf(rootEl.id) >= 0;

    const isRootFolded = rootFoldedIds.includes(rootEl.id);

    const rootDescendants: ParentElement[] = parentElements.filter(
      parentEl => parentEl.rootId === rootEl.id
    );

    return {
      label: rootEl.name,
      value: rootEl.id,
      expanded: !isRootFolded,
      checked: isRootGranted,
      disabled: isRootDisabled,
      children: rootDescendants.map(parentEl =>
        makeParentBranch(parentEl, rootEl.id, isRootGranted, isRootUnchecked, isRootFolded)
      ),
    };
  }

  function makeParentBranch(
    parentEl: ParentElement,
    rootId: string,
    isRootGranted: boolean,
    isRootUnchecked: boolean,
    isRootFolded: boolean
  ): TreeNodeProps {
    const isParentGranted = parentSelectedIds.includes(parentEl.id);
    const isParentUnchecked =
      !isRootGranted && !isParentGranted && parentForceUncheckIds.includes(parentEl.id);
    const isParentDisabled = parentDisabledIds.includes(parentEl.id);

    const parentDescendants: ChildElement[] = childElements.filter(
      childEl => childEl.parentId === parentEl.id
    );

    return {
      label: parentEl.name,
      value: parentEl.id,
      expanded: !isRootFolded && !parentFoldedIds.includes(parentEl.id),
      checked: !isRootUnchecked && (isRootGranted || isParentGranted),
      disabled: !enableLowLevelSelect && (isRootGranted || isParentDisabled),
      rootId,
      children: parentDescendants.map(
        (childEl: ChildElement): TreeNode => ({
          label: childEl.name,
          value: childEl.id,
          checked:
            !isParentUnchecked &&
            !isRootUnchecked &&
            (isRootGranted || isParentGranted || childSelectedIds.includes(childEl.id)),
          disabled: !enableLowLevelSelect && (isRootGranted || isParentGranted) && !isRootUnchecked,
          rootId,
          parentId: parentEl.id,
        })
      ),
    };
  }
}

// export function normalizeSelectedNodesWithDisabledSelectedChildren(
//   nodes: TreeNodeProps[],
//   prevRootIds: string[] = [],
//   prevParentIds: string[] = [],
//   explicitChildren = false, // if true - all children ids of selected nodes go to resulting data
//   allRoots: RootElement[] = [],
//   allParents: ParentElement[] = [],
//   allChildren: ChildElement[] = []
// ): {
//   rootIds: string[];
//   parentIds: string[];
//   childIds: string[];
// } {
//   const rootIds: string[] = [];
//   const parentIds: string[] = [];
//   const childIds: string[] = [];
//
//   const nodeIds = nodes.map(n => n.value);
//
//   allRoots.forEach(root => {
//     nodeIds.includes(root.id) && rootIds.push(root.id);
//   });
//
//   allParents.forEach(parent => {
//     !explicitChildren &&
//       nodeIds.includes(parent.id) &&
//       // If root IS selected - skip
//       !nodeIds.includes(parent.rootId) &&
//       // If root WAS selected - skip
//       !prevRootIds.includes(parent.rootId) &&
//       parentIds.push(parent.id);
//
//     explicitChildren &&
//       (nodeIds.includes(parent.id) || nodeIds.includes(parent.rootId)) &&
//       // If root was JUST unselected - skip
//       !(!nodeIds.includes(parent.rootId) && prevRootIds.includes(parent.rootId)) &&
//       parentIds.push(parent.id);
//   });
//
//   allChildren.forEach(child => {
//     !explicitChildren &&
//       nodeIds.includes(child.id) &&
//       // If parent IS selected - skip
//       !nodeIds.includes(child.rootId) &&
//       !nodeIds.includes(child.parentId) &&
//       // If parent WAS selected - skip
//       !prevRootIds.includes(child.rootId) &&
//       !prevParentIds.includes(child.parentId) &&
//       childIds.push(child.id);
//
//     explicitChildren &&
//       (nodeIds.includes(child.id) || nodeIds.includes(child.parentId) || nodeIds.includes(child.rootId)) &&
//       // If parent was JUST unselected - skip
//       !(!nodeIds.includes(child.rootId) && prevRootIds.includes(child.rootId)) &&
//       !(!nodeIds.includes(child.parentId) && prevParentIds.includes(child.parentId)) &&
//       childIds.push(child.id);
//   });
//
//   return { rootIds, parentIds, childIds };
// }

export function normalizeSelectedNodes(
  nodes: TreeNodeProps[],
  explicitChildren = false, // if true - all children ids of selected nodes go to resulting data
  allRoots: RootElement[] = [],
  allParents: ParentElement[] = [],
  allChildren: ChildElement[] = []
): {
  rootIds: string[];
  parentIds: string[];
  childIds: string[];
} {
  const rootIds: string[] = [];
  const parentIds: string[] = [];
  const childIds: string[] = [];

  const nodeIds = nodes.map(n => n.value);

  allRoots.forEach(root => {
    nodeIds.includes(root.id) && rootIds.push(root.id);
  });

  allParents.forEach(parent => {
    !explicitChildren &&
      nodeIds.includes(parent.id) &&
      // If root IS selected - skip
      !nodeIds.includes(parent.rootId) &&
      parentIds.push(parent.id);

    explicitChildren &&
      (nodeIds.includes(parent.id) || nodeIds.includes(parent.rootId)) &&
      parentIds.push(parent.id);
  });

  allChildren.forEach(child => {
    !explicitChildren &&
      nodeIds.includes(child.id) &&
      // If parent IS selected - skip
      !nodeIds.includes(child.rootId) &&
      !nodeIds.includes(child.parentId) &&
      childIds.push(child.id);

    explicitChildren &&
      (nodeIds.includes(child.id) ||
        nodeIds.includes(child.parentId) ||
        nodeIds.includes(child.rootId)) &&
      childIds.push(child.id);
  });

  return { rootIds, parentIds, childIds };
}
