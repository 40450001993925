import styled from 'styled-components/macro';

const PopupButtons = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
  button:not(:last-child) {
    margin-right: 16px;
  }
  @media (max-width: 400px) {
    button {
      flex-grow: 1;
    }
  }
`;

export default PopupButtons;
