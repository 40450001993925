import MuiLinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components/macro';

const LinearProgress = styled(MuiLinearProgress)`
  background-color: #2ea1f8;
  margin: 3rem;
  margin-bottom: 0;
  margin-top: 0.5rem;

  & > div {
    background-color: #354052;
  }
`;

export default LinearProgress;
