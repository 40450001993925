import React from 'react';
import styled from 'styled-components/macro';
import packageJson from '../../../package.json';
import config from 'config/config';
const VersionLabel = styled.div`
  display: inline-block;
  background: #2ea1f8;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #c2e1f9;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  margin-left: 10px;
  @media (max-width: 599px) {
    display: none;
  }
`;

function VersionLabelHeader() {
  const appVersion = packageJson.version;
  const envName = config.ENV_NAME as string;
  return <VersionLabel>{`${envName}: ${appVersion}`}</VersionLabel>;
}

export default VersionLabelHeader;
