import { createReducer } from 'typesafe-actions';
import { IApiManagementState } from 'store/storeTypes';
import { actions, RootAction } from 'actions';

const INIT_STATE: IApiManagementState = {
  certificate: undefined,
  isError: false,
  isLoading: false,
};

const { getCertificateDetailsAC, createCertificateAC, deleteCertificateAC } = actions.apiManagement;
const reducer = createReducer<IApiManagementState, RootAction>(INIT_STATE)
  .handleAction(getCertificateDetailsAC.request, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .handleAction(getCertificateDetailsAC.success, (state, action) => {
    return {
      ...state,
      certificate: action.payload,
      isLoading: false,
    };
  })
  .handleAction(getCertificateDetailsAC.failure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  })
  .handleAction(createCertificateAC.request, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .handleAction(createCertificateAC.success, (state, action) => {
    return {
      ...state,
      certificate: action.payload,
      isLoading: false,
    };
  })
  .handleAction(createCertificateAC.failure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  })
  .handleAction(deleteCertificateAC.request, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .handleAction(deleteCertificateAC.success, (state, action) => {
    return {
      ...state,
      certificate: undefined,
      isLoading: false,
    };
  })
  .handleAction(deleteCertificateAC.failure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  });

export default reducer;
