import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const FilterSearcher = props => {
  const { selectedSystemGroup, filterValue, setFilterValue } = props;
  const handleChange = e => {
    setFilterValue({ value: e.target.value, selectedSystemGroup });
  };
  return (
    <>
      <TextField
        fullWidth
        size="small"
        id="outlined-basic"
        variant="outlined"
        label="Search"
        data-automationid={`filter_systems_search`}
        value={filterValue}
        onChange={event => handleChange(event)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
export default FilterSearcher;
