import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { Notification } from '../../store/storeTypes';

const INIT_STATE: Notification[] = [];

const { enqueueNotificationAC, removeNotification } = actions.ui;

export default createReducer<Notification[], RootAction>(INIT_STATE)
  .handleAction(enqueueNotificationAC, (state, action) => [...state, action.payload])

  .handleAction(removeNotification, (state, action) =>
    state.filter(notification => notification.key !== action.payload)
  );
