import { GenericPropsType } from 'store/storeTypes';

export interface ValidationError {
  key: string;
  values?: GenericPropsType;
}

export type ValidationResult = ValidationError | undefined;

export const required = (value: GenericPropsType): ValidationResult =>
  (value && value.trim().length) || typeof value === 'number'
    ? undefined
    : { key: 'validation.required' };

export const maxLength = (max: number, value: GenericPropsType[] | string): ValidationResult =>
  value && value.length > max ? { key: 'validation.mustBeMaxChars', values: { max } } : undefined;

export const minLength = (min: number, value: GenericPropsType[] | string): ValidationResult =>
  value && value.length < min ? { key: 'validation.mustBeMinChars', values: { min } } : undefined;

export const number = (value: GenericPropsType): ValidationResult =>
  value && isNaN(Number(value)) ? { key: 'validation.mustBeNumber' } : undefined;

export const requiredPositiveInteger = (value: GenericPropsType): ValidationResult => {
  if (!value || isNaN(+value) || +value < 0 || Math.abs(+value - Math.floor(+value)) > 0) {
    return { key: 'validation.mustBePositiveInteger' };
  }
  if (value > 10000000) return { key: 'validation.mustBeMaxEigthNumbers' };
  return undefined;
};

export const minValue = (min: number, value: number): ValidationResult =>
  value && value < min ? { key: 'validation.mustBeAtLeast', values: { min } } : undefined;
export const email = (value: string): ValidationResult => {
  const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return value && value.trim().length && expression.test(String(value).toLowerCase())
    ? undefined
    : { key: 'validation.notValidEmail' };
};

export const minArrayLength = (min: number, value: GenericPropsType[] | string): ValidationResult =>
  value && value.length < min
    ? { key: 'validation.mustContainMinElements', values: { min } }
    : undefined;

export const uniqueEmail = (value: string, existing: string[]): ValidationResult =>
  value && value.trim().length && existing && existing.length !== 0 && existing.indexOf(value) >= 0
    ? { key: 'validation.emailInUse' }
    : undefined;

export const lettersOnly = (str: string) => {
  const regex = /^[a-zA-Z\s]+$/g;
  return str && regex.test(str) ? undefined : { key: 'validation.lettersOnly' };
};

export const isNotNegativeNumber = (val: number) => (val < 0 ? 0 : val);
