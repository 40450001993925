import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import ButtonOk from '../_common/ButtonOk';
import ButtonCancel from '../_common/ButtonCancel';
import ButtonSpinner from '../_common/ButtonSpinner';
import { UserModalSuccessProps } from './UserModalSuccessContainer';

const Congratulations = styled.div`
  margin-top: 3.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pictures {
    position: relative;
    width: 220px;
    text-align: center;
    margin-left: 16px;

    .circle {
      margin-right: 16px;
      width: 68.5px;
      height: 68px;
    }

    .star {
      position: absolute;
      top: 0;

      &.n1 {
        left: 33px;
        top: -12px;
        width: 13.7px;
        height: 11.3px;
      }
      &.n2 {
        left: 0;
        top: 0;
        width: 36px;
        height: 36px;
        opacity: 0.1;
      }
      &.n3 {
        left: 143px;
        top: 0;
        width: 43px;
        height: 43px;
        opacity: 0.1;
      }
      &.n4 {
        left: 202px;
        top: 28px;
        width: 13.7px;
        height: 11.3px;
      }
      &.n5 {
        left: 171px;
        top: 42px;
        width: 24px;
        height: 24px;
      }
    }
  }

  h2 {
    font-family: Rubik, sans-serif;
    font-weight: normal;
    font-size: 36px;
    color: #ff4081;
    margin: 0.5rem 0 0;
  }

  p {
    font-family: Rubik, sans-serif;
    font-size: 18px;
    // text-align: center;
    color: #354052;
    margin: 0.8rem 0 0;
  }

  .buttons {
    margin: 2.5rem 0 5rem;

    button {
      margin: 0 0.5rem;
    }
  }
`;

const UserModalSuccess = React.memo((props: UserModalSuccessProps) => {
  const { t } = useTranslation();
  const { lastSavedUserId, isPendingInvitation, isInvitationSent } = props;
  const { closeModalUser, inviteUser } = props;

  return (
    <Congratulations>
      <div className="pictures" data-testid="starts-pictures-wrapper">
        <img src="/img/check-in-circle.svg" className="circle" alt="" />
        <img src="/img/star.svg" className="star n1" alt="" />
        <img src="/img/star.svg" className="star n2" alt="" />
        <img src="/img/star.svg" className="star n3" alt="" />
        <img src="/img/star.svg" className="star n4" alt="" />
        <img src="/img/star.svg" className="star n5" alt="" />
      </div>

      <h2 data-testid="congratulations-header">{t('userModal.congratulations')}</h2>
      <p data-testid="congratulation-message">{t('userModal.youCreated')}</p>
      {isInvitationSent ? (
        <p data-testid="invitation-sent-message">{t('userModal.invitationSent')}</p>
      ) : (
        <p data-testid="is-invitation-sent-message">{t('userModal.sendInvitationQuestion')}</p>
      )}

      <div className="buttons">
        {isInvitationSent ? (
          <ButtonOk data-testid="OK-button" onClick={closeModalUser}>
            {t('userModal.ok')}
          </ButtonOk>
        ) : (
          <ButtonCancel data-testid="Cancel-button" onClick={closeModalUser}>
            {t('userModal.dontSend')}
          </ButtonCancel>
        )}

        {!isInvitationSent && (
          <ButtonOk
            data-testid="send-button"
            onClick={() => inviteUser(lastSavedUserId)}
            disabled={isPendingInvitation}>
            {isPendingInvitation ? <ButtonSpinner /> : t('userModal.send')}
          </ButtonOk>
        )}
      </div>
    </Congratulations>
  );
});

export default UserModalSuccess;
