import { UserSystemGroupStrings } from 'store/storeTypes';

export enum GoalType {
  Weekly = 0,
  Yearly = 1,
}

export type GoalsData = {
  achieved: number;
  userFirstName: string;
  userLastName: string;
  dateModified: string;
  goalValue: number;
  goalType: GoalType;
};

export type GoalFromApi = {
  achieved: number;
  timezoneOffset: number;
  goalPeriod: number;
  userFirstName: string;
  userLastName: string;
  goalValue: number;
  time: string;
};
export type GoalFromApiByType = {
  [key in UserSystemGroupStrings]: GoalFromApi[];
};

export type GoalsDataDto = {
  impressionsNumber: number;
  userFirstName: string;
  userLastName: string;
  time: string;
  timezoneOffset: string;
  goalValue: number;
  goalPeriod: GoalType;
};
