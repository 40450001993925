import { Store, ReportSystem } from 'store/storeTypes';

export const filterDataSystemsByIdSelector = (state: Store): { [k: string]: ReportSystem } =>
  state.reports.filterData[state.ui.selectedSystemGroup]!.systems.reduce(
    (acc, system) => ({ ...acc, [system.id]: system }),
    {}
  );

export const filterDataLastUpdateSelector = (state: Store) =>
  state.reports.filterData[state.ui.selectedSystemGroup]!.endDate;

export const selectedSystemSerialsSelector = (state: Store) =>
  state.reports.filterState[state.ui.selectedSystemGroup]!.selectedSystemSerials;

export const selectedSystemSerialsSelectorForRequest = (state: Store) => {
  return state.reports.filterState[state.ui.selectedSystemGroup]!.selectedSystemSerials;
};

export const selectedSystemsDataSelector = (state: Store) =>
  state.reports.filterData[state.ui.selectedSystemGroup]!.systems;

export const selectedSystemsNameSelector = (state: Store) =>
  state.reports.filterData[state.ui.selectedSystemGroup]!.systems.filter(s =>
    state.reports.filterState[state.ui.selectedSystemGroup]!.selectedSystemSerials.includes(s.name)
  ).map(s => s.id);
