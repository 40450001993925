import styled from 'styled-components/macro';
import MuiGrid from '@material-ui/core/Grid';

const Grid = styled(MuiGrid)`
  &.ends-grid-item {
    margin-top: 10px;
  }
  @media (max-width: 599px) {
    &.item:not(:first-child) {
      padding-top: 0;
    }
    &.repeat-grid-item {
      padding-bottom: 0;
    }
    &.ends-grid-item {
      margin-top: 20px;
    }
  }
`;

export default Grid;
