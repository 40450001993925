import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import TopCards from './TopCards';

import { Store } from 'store/storeTypes';
import { TimeFrame } from 'models/ReportData';
import {
  overviewBySystemGroupAvailabilitySelector,
  overviewBySystemGroupAvailabilityTotalDurationReportedSelector,
  overviewBySystemGroupavailAbilityTrendSelector,
  overviewBySystemGroupDarkImpressionsPercentageSelector,
  overviewBySystemGroupDarkImpressionsTrendSelector,
  overviewBySystemGroupErrorDurationSelector,
  overviewBySystemGroupErrorDurationTrendSelector,
  overviewBySystemGroupIsNotReportingPeriodSelector,
  overviewBySystemGroupLoadingTimeSelector,
  overviewBySystemGroupLoadingTimeTrendSelector,
  overviewBySystemGroupMaintenanceDurationSelector,
  overviewBySystemGroupMaintenanceDurationTrendSelector,
  overviewBySystemGroupPreparationSelector,
  overviewBySystemGroupPrintingTimeSelector,
  overviewBySystemGroupPrintingTimeTrendSelector,
  overviewBySystemGroupProductionRateSelector,
  overviewBySystemGroupProductionRateTrendSelector,
  overviewBySystemGroupProductionTrendSelector,
  overviewBySystemGroupProductivityChangeSelector,
  overviewBySystemGroupProductivitySelector,
  overviewBySystemGroupProductivityUtilizationSelector,
  overviewBySystemGroupProductivityUtilizationTrendSelector,
  overviewBySystemGroupTotalProductionSelector,
  overviewBySystemGroupTrendPreparationSelector,
  overviewBySystemGroupTrendUtilizationSelector,
  overviewBySystemGroupUtilizationSelector,
} from 'reducers/overviewReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { dataUserSettingsSelector } from 'reducers/userSettingsReducer';
import { customTimeFramePropertiesSelector } from 'reducers/reports/filterStateReducer';
type OwnProps = {
  timeframe: TimeFrame;
  isMobile: boolean;
  isLeftDesktopColumn?: boolean;
  isRightDesktopColumn?: boolean;
  units?: string;
  selectedSystemGroupText?: any;
};
const mapStateToProps = (state: Store) => {
  return {
    availability: overviewBySystemGroupAvailabilitySelector(state),
    availabilityTrend: overviewBySystemGroupavailAbilityTrendSelector(state),
    errorDuration: overviewBySystemGroupErrorDurationSelector(state),
    darkImpressionsPercentage: overviewBySystemGroupDarkImpressionsPercentageSelector(state),
    maintenanceDuration: overviewBySystemGroupMaintenanceDurationSelector(state),
    loadingTime: overviewBySystemGroupLoadingTimeSelector(state),
    productionRate: overviewBySystemGroupProductionRateSelector(state),
    productionRateTrend: overviewBySystemGroupProductionRateTrendSelector(state),
    productionTrend: overviewBySystemGroupProductionTrendSelector(state),
    totalProduction: overviewBySystemGroupTotalProductionSelector(state),
    darkImpressionsTrend: overviewBySystemGroupDarkImpressionsTrendSelector(state),
    loadingTimeTrend: overviewBySystemGroupLoadingTimeTrendSelector(state),
    maintenanceDurationTrend: overviewBySystemGroupMaintenanceDurationTrendSelector(state),
    errorDurationTrend: overviewBySystemGroupErrorDurationTrendSelector(state),
    trendPreparation: overviewBySystemGroupTrendPreparationSelector(state),
    trendUtilization: overviewBySystemGroupTrendUtilizationSelector(state),
    utilization: overviewBySystemGroupUtilizationSelector(state),
    preparation: overviewBySystemGroupPreparationSelector(state),
    productivity: overviewBySystemGroupProductivitySelector(state),
    productivityChange: overviewBySystemGroupProductivityChangeSelector(state),
    printingTime: overviewBySystemGroupPrintingTimeSelector(state),
    printingTimeTrend: overviewBySystemGroupPrintingTimeTrendSelector(state),
    productivityUtilization: overviewBySystemGroupProductivityUtilizationSelector(state),
    productivityUtilizationTrend: overviewBySystemGroupProductivityUtilizationTrendSelector(state),
    selectedSystemGroup: systemGroupSelector(state),
    userSettings: dataUserSettingsSelector(state),
    customTimeframeProperties: customTimeFramePropertiesSelector(state),
    isNotReprotingPeriod: overviewBySystemGroupIsNotReportingPeriodSelector(state),
    availabilityTotalDurationReported: overviewBySystemGroupAvailabilityTotalDurationReportedSelector(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type ITopCardsProps = ReturnType<typeof mapStateToProps> &
  OwnProps &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TopCards);
