import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import ShellHeader from './ShellHeader';
import { withAuth } from 'services/AuthProvider';
import { Store } from 'store/storeTypes';
import {
  isReadOnlyRoleSelector,
  profilePictureUrlSelector,
  screenWidthSelector,
  userEmailSelector,
} from 'reducers/appUserReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { activeSystemsBySystemGroupSelector } from 'reducers/reports/filterDataReducer';
import { importedTenantIdSelector, tenantNameSelector } from 'reducers/userSettingsReducer';
import {
  apolloLastUpdateSelector,
  dtfLastUpdateSelector,
  dtgLastUpdateSelector,
} from 'reducers/apiStateReducer';
import { activeReprotSelector } from 'reducers/reports/filterStateReducer';
import { overviewTimeOfLastMainResponseSelector } from 'reducers/overviewReducer';

const mapStateToProps = (state: Store) => {
  return {
    profilePictureUrl: profilePictureUrlSelector(state),
    activeSystems: activeSystemsBySystemGroupSelector(state),
    lastUpdateDTG: dtgLastUpdateSelector(state),
    lastUpdateDTF: dtfLastUpdateSelector(state),
    lastUpdateApollo: apolloLastUpdateSelector(state),
    selectedSystemGroup: systemGroupSelector(state),
    tennantName: tenantNameSelector(state),
    importedTennantId: importedTenantIdSelector(state),
    screenWidth: screenWidthSelector(state),
    activeReport: activeReprotSelector(state),
    userEmail: userEmailSelector(state),
    isReadOnlyRole: isReadOnlyRoleSelector(state),
    lastResponseUpdateTime: overviewTimeOfLastMainResponseSelector(state),
  };
};

export type ShellHeaderProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<Record<string, string>>;

export default withAuth(connect(mapStateToProps)(withRouter(ShellHeader)));
