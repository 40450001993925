import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';
// import Grid from './Grid';
// import Grid from '@material-ui/core/Grid';

const FormRow = styled(Grid)`
  margin-bottom: 16px;

  &.with-helpers {
    margin-bottom: -4px;
  }
`;

export default FormRow;
