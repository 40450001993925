import config from 'config/config';
const DEFAULT_WEEK_START = 1;
const DEFAULT_FILTER_HIDE = false;
const DEFAULT_SHOW_PBI_REPORT_PAGES_PANE = false;
const DEFAULT_SHOW_PBI_REPORT_FILTERS_PANE = false;
const DEFAULT_MAX_LOAD_ATTEMPTS = 5;
const DEFAULT_REPORTS_REFRESH_TIMEOUT_SECONDS = 300;
const DEFAULT_SPLIT_WEEK_BY_END_OF_YEAR = true;
const DEFAULT_USER_AGREEMENT_VERSION = '1.0';
const SHOW_VERSION_VERSION_LABEL = false;

export function getWeekStartEnv(
  paramName = 'REACT_APP_WEEK_START',
  defValue: 0 | 1 | 2 | 3 | 4 | 5 | 6 = DEFAULT_WEEK_START
) {
  const parsed = Number(config[paramName]);
  if (Number.isNaN(parsed) || parsed < 0 || parsed > 6) return defValue;
  return parsed as 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

export function getFilterHideEnv(
  paramName = 'FILTER_HIDE',
  defValue: boolean = DEFAULT_FILTER_HIDE
): boolean {
  return getBooleanEnv(paramName, defValue);
}

export function getShowPbiReportPagesPaneEnv(
  paramName = 'SHOW_PBI_REPORT_PAGES_PANE',
  defValue: boolean = DEFAULT_SHOW_PBI_REPORT_PAGES_PANE
): boolean {
  return getBooleanEnv(paramName, defValue);
}

export function getShowPbiReportFiltersPaneEnv(
  paramName = 'SHOW_PBI_REPORT_FILTERS_PANE',
  defValue: boolean = DEFAULT_SHOW_PBI_REPORT_FILTERS_PANE
): boolean {
  return getBooleanEnv(paramName, defValue);
}

export function getMaxLoadAttemptsEnv(
  paramName = 'MAX_LOAD_ATTEMPTS',
  defValue: number = DEFAULT_MAX_LOAD_ATTEMPTS
): number {
  return getNumberEnv(paramName, defValue);
}

export function getReportsRefreshTimeoutEnv(
  paramName = 'REPORTS_REFRESH_TIMEOUT_SECONDS',
  defValue: number = DEFAULT_REPORTS_REFRESH_TIMEOUT_SECONDS
): number {
  return getNumberEnv(paramName, defValue);
}

export function getSplitWeekByEndOfYearEnv(
  paramName = 'SPLIT_WEEK_BY_END_OF_YEAR',
  defValue: boolean = DEFAULT_SPLIT_WEEK_BY_END_OF_YEAR
): boolean {
  return getBooleanEnv(paramName, defValue);
}

export function getAppUserAgreementVersionEnv(
  paramName = 'USER_AGREEMENT_VERSION',
  defValue: string = DEFAULT_USER_AGREEMENT_VERSION
): string {
  return config[paramName] || defValue;
}

export function getShowVersionLabel(
  paramName = 'SHOW_VERSION_VERSION_LABEL',
  defValue: boolean = SHOW_VERSION_VERSION_LABEL
): boolean {
  return getBooleanEnv(paramName, defValue);
}

function getBooleanEnv(paramName: string, defValue: boolean): boolean {
  return config[paramName] && [true, false].includes(config[paramName]!)
    ? (config[paramName] as boolean)
    : defValue;
}

function getNumberEnv(paramName: string, defValue: number): number {
  return typeof config[paramName] === 'undefined' || Number.isNaN(Number(config[paramName]))
    ? defValue
    : Number(config[paramName]);
}
