import React from 'react';
import { TimeFrame } from 'models/ReportData';
import { ChartRecord, UserSystemGroupStrings } from 'store/storeTypes';
import DesktopAvailabilityChart from './DesktopAvailabilityChart';
import MobileAvailabilityChart from './MobileAvailabilityChart';
import { TooltipText } from './SpeedChart';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
export interface IAvailabilityChartProps {
  data: ChartRecord[];
  isMobile?: boolean;
  isWeek?: boolean;
  tooltipText?: TooltipText;
  width?: number;
  height?: number;
  timeframe?: TimeFrame;
  screenWidth?: number;
  selectedSystemGroup: UserSystemGroupStrings;
  customTimeframeProperties?: CustomTimeFrameProperties;
  isMachineFilterOpen?: boolean;
  maxKeepAlive?: Date;
}

export default function AvailabilityChart(props: IAvailabilityChartProps) {
  const {
    data,
    tooltipText,
    isMobile,
    isWeek,
    timeframe,
    screenWidth,
    selectedSystemGroup,
    customTimeframeProperties,
    isMachineFilterOpen,
    maxKeepAlive,
  } = props;
  if (isMobile) {
    return (
      <MobileAvailabilityChart
        data={data}
        isWeek={isWeek}
        selectedSystemGroup={selectedSystemGroup}
      />
    );
  } else {
    return (
      <DesktopAvailabilityChart
        maxKeepAlive={maxKeepAlive}
        isMachineFilterOpen={isMachineFilterOpen}
        customTimeframeProperties={customTimeframeProperties}
        data={data}
        isWeek={isWeek}
        tooltipText={tooltipText}
        timeframe={timeframe}
        screenWidth={screenWidth}
        selectedSystemGroup={selectedSystemGroup}
      />
    );
  }
}
