import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;
const Title = styled.div`
  width: 100%;

  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #354052;
  text-align: left;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  height: calc(
    100vh - 96px
  ); // Adjust the Card's height based on the Title's height 36px and nav bar height 60px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
`;

const DocViewer = props => {
  const { docName } = props.match.params;

  const getHeaderName = (documentName: string): string => {
    switch (documentName) {
      case 'privacy-policy':
        return 'Privacy Policy';
      case 'terms-of-use':
        return 'Terms of Use';
      case 'product-tc':
        return 'Product Terms & Conditions';
      default:
        return '';
    }
  };
  return (
    <Wrapper>
      <Title>{getHeaderName(docName)}</Title>
      <Card>
        <iframe
          src={`/docs/${docName}.html`}
          title="Document Viewer"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </Card>
    </Wrapper>
  );
};
export default DocViewer;
