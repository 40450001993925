import { Reducer, combineReducers } from 'redux';

import userEntityReducer from './userEntityReducer';
import customerEntityReducer from './customerEntityReducer';
import siteEntityReducer from './siteEntityReducer';
import systemEntityReducer from './systemEntityReducer';
import goalsEntityReducer from './goalsEntityReducer';
import schedulerEntityReducer from './schedulerEntityReducer';

export const entitiesReducer: Reducer = combineReducers({
  users: userEntityReducer,
  customers: customerEntityReducer,
  sites: siteEntityReducer,
  systems: systemEntityReducer,
  goals: goalsEntityReducer,
  schedulers: schedulerEntityReducer,
});

export default entitiesReducer;
