import React from 'react';
import DropdownTreeSelect, { DropdownTreeSelectProps } from 'react-dropdown-tree-select';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';

import 'react-dropdown-tree-select/dist/styles.css';

import { ValidationError } from 'helpers/validationRules';

const StyledDropdownTreeSelect = styled(DropdownTreeSelect)<{
  hierarchical?: boolean;
  showPartiallySelected?: boolean;
}>`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .dropdown,
    .dropdown-trigger {
        outline: none;
        display: block;
        width: 100%;
        height: 100%;

        &::after,
        .tag-list {
            display: none;
        }
        .dropdown-trigger {
            &.arrow {
                border: 0;
                box-sizing: border-box;
                &:before {
                    content: '';
                    width: 12px;
                    height: 6px;
                    position: absolute;
                    top: 28px;
                    right: 0;
                    background: url('/img/tree-select-arrow.svg') top right no-repeat;
                    transform: rotate(-180deg);
                    transition: 0.1s ease-out;
                }
                &.top:before {
                    transform: rotate(0);
                }
                + .dropdown-content {
                    position:absolute;
                    top: 50px;
                    height: 175px;
                    max-height: 175px;
                    width: 100%;
                    overflow-y: auto;
                    border: 1px solid #89a5ca;
                    box-sizing: border-box;
                    box-shadow: -7px 9px 15px rgba(137, 165, 202, 0.17);
                    border-radius: 4px;
                    margin-right: -12px;

                    &::-webkit-scrollbar-track {
                        border-left: 1px solid #89a5ca;
                    }

                    &::-webkit-scrollbar {
                        width: 12px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #109cf1;
                        border-radius: 0 4px 4px 0;
                    }

                    .node {
                        &[style*='20px'] {
                            margin-left: 5px;
                        }

                        &[style*='40px'] {
                            margin-left: 10px;
                        }

                        label {
                            font-family: Rubik, sans-serif;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: normal;
                            color: #354052;
                        }

                        &.tree {
                            display: flex;

                            label {
                                margin-top: 4px;
                            }

                            .toggle {
                                order: 2;
                                position: relative;
                                width: 12px;
                                height: 12px;
                                margin: 6px 0 0 10px;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    background: url('/img/tree-select-arrow-sm.svg') center no-repeat;
                                    display: block;
                                    transition: 0.1s ease-out;
                                }

                                &.expanded::after {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        &.leaf {
                            .toggle {
                                display: none;
                            }
                        }

                        .checkbox-item {
                            position: relative;
                            width: 1rem;
                            height: 1rem;
                            margin-right: 0.75rem;
                            cursor: pointer;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            outline: 0;
                            vertical-align: middle;
                            border: none;

                            &:before {
                                content: '';
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                z-index: 1;
                                width: 100%;
                                height: 100%;
                                border: 1px solid #354052;
                                box-sizing: border-box;
                                border-radius: 4px;
                            }

                            &:checked:before {
                                border-color: #2196f3;
                                background: #109cf1 url('/img/check-mark-white.svg') no-repeat center;
                            }

                            &:checked ~ span {
                                //font-weight: 500;
                                color: #109cf1;
                            }
                        }

                        &.partial {
                            .checkbox-item:before {
                                background-color: #459edd;
                                border-color: #459edd;
                                outline: 1px solid white;
                                outline-offset: -3px;
                                border-radius: 0;
                            }
                        }

                        &.disabled {
                            .checkbox-item {
                                & ~ span {
                                    color: #ccc;
                                }

                                &:before {
                                    border-color: #bbb;
                                    background-color: #ccc;
                                }
                            }
                        }

                        .searchLoader {
                            display: none;
                        }
                    }
            }
        }
    }
`;

const TreeSelectContainer = styled.div`
  position: relative;
  padding-bottom: 6px;

  .label {
    font-size: 12px;
    font-family: Rubik, sans-serif;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 6px;
    min-height: 14px;
  }

  &.error {
    .label {
      color: rgb(244, 67, 54);
    }
    .rendered-value {
      border-bottom: 2px solid rgb(244, 67, 54);
      color: rgb(244, 67, 54);
    }
  }

  .rendered-value {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    min-height: 17px;
    color: ${(props: { haveValue?: boolean }) =>
      props.haveValue ? '#354052' : 'rgba(0, 0, 0, 0.54)'};
    line-height: 18px;
    padding-top: 1px;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5d0de;

    & > div:not(:last-child) {
      margin-bottom: 0.4rem;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;

interface ITreeSelectProps extends DropdownTreeSelectProps {
  label: string;
  userScope: string[];
  error?: ValidationError | false;
  isPartiallySelectedTree?: boolean;
}

const TreeSelect = React.memo((props: ITreeSelectProps) => {
  const { t } = useTranslation();
  const { label, userScope, error, isPartiallySelectedTree, ...rest } = props;
  const renderedValue = userScope.map((e, i) => <div key={i}>{e}</div>);

  if (isPartiallySelectedTree) {
    return <StyledDropdownTreeSelect {...rest} showPartiallySelected />;
  }

  return (
    <TreeSelectContainer haveValue={!!renderedValue.length} className={error ? 'error' : ''}>
      <div className="label">{renderedValue.length ? label : ''}</div>
      <div className="rendered-value">{renderedValue.length ? renderedValue : label}</div>
      <StyledDropdownTreeSelect hierarchical {...rest} />
      <FormHelperText error>{error && t(error.key, error.values)}</FormHelperText>
    </TreeSelectContainer>
  );
});

export default TreeSelect;
