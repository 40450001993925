import { appearanceEffect } from 'globalStyle';
import { notUndefiend, valueAsString } from 'helpers/conversions';
import React from 'react';
import styled from 'styled-components';
import { ICardProps, StatsData } from './Card';
import { Spinner, SpinnerContainer } from './Styles';
const CardContent = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const UpperRow = styled.div`
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const BottomRow = styled.div`
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-top: 1px solid #c5d0de;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #354052;
`;
const ContentRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  color: #354052;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
const DescriptionRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #7f8fa4;
`;
const Value = styled.div`
  margin-left: 10px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  color: #354052;
  font-size: 32px;
`;
const Units = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #354052;
  margin-top: 15px;
  margin-left: 3px;
`;
const TotalImpressionCardMobile = (props: ICardProps) => {
  const {
    value,
    units,
    description,
    icons,
    stats,
    statsDescription,
    addedData,
    isPercentage,
    cardName,
  } = props;
  const [isNegative, setIsNegative] = React.useState(false);

  React.useEffect(() => {
    if (notUndefiend([stats])) {
      if (stats! < 0) {
        setIsNegative(true);
      } else if (description === 'Average Availability' && (value as number) >= 0.999) {
        setIsNegative(false);
      } else {
        setIsNegative(false);
      }
    }
  }, [stats]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CardContent>
      {notUndefiend([value]) ? (
        <>
          <UpperRow>
            <ContentRow>
              {isNegative ? icons.negative : icons.positive}{' '}
              <Value data-testid="total-impression-value">
                {valueAsString(value, isPercentage)}
              </Value>
              <Units>{units}</Units>
            </ContentRow>
            <DescriptionRow data-testid="total-impression-description">
              {description}
            </DescriptionRow>
          </UpperRow>
          <BottomRow data-testid="total-impression-stats-description">
            {StatsData(stats!)} {statsDescription}
          </BottomRow>
        </>
      ) : (
        <SpinnerContainer> {Spinner()}</SpinnerContainer>
      )}
    </CardContent>
  );
};
export default TotalImpressionCardMobile;
