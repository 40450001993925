import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AuthSupportProps } from 'contracts/auth';
import Shell from './Shell';
import {
  setAppUserParams,
  setAppUserAgreementVersion,
  setAppUserAgreement,
  getPortalApiUrl,
} from 'actions/appUserActions';
import { getAppUserSettings } from 'actions/userSettingsActions';
import { setUserEmail, setUserLastName, setUserFirstName } from 'actions/appUserActions';
import { updateLogoutTimer } from 'actions/uiActions';
import { Store } from 'store/storeTypes';
import { RouteComponentProps } from 'react-router-dom';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import {
  accountTypeSelector,
  importedTenantIdSelector,
  officeSelector,
  tenantNameSelector,
  userSystemGroupSelector,
} from 'reducers/userSettingsReducer';
import { setScreenSize } from 'actions/userUiActions';
import {
  apiUrlSelector,
  isReadOnlyRoleSelector,
  userAgreementSelector,
  userAgreementVersionSelector,
} from 'reducers/appUserReducer';

const mapStateToProps = (state: Store) => ({
  selectedSystemGroup: systemGroupSelector(state),
  userAgreementVersion: userAgreementVersionSelector(state),
  userAgreement: userAgreementSelector(state),
  apiUrl: apiUrlSelector(state),
  tennantName: tenantNameSelector(state),
  importedTennantId: importedTenantIdSelector(state),
  office: officeSelector(state),
  accountType: accountTypeSelector(state),
  userSystemGroup: userSystemGroupSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAppUserParams,
      updateLogoutTimer,
      setAppUserAgreement,
      setAppUserAgreementVersion,
      getPortalApiUrl,
      getAppUserSettings,
      setUserEmail,
      setUserFirstName,
      setUserLastName,
      setScreenSize,
    },
    dispatch
  );

export type ShellProps = RouteComponentProps &
  AuthSupportProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Shell);
