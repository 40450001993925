import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { authService } from 'services/AuthProvider';
import { createAsyncAction, createStandardAction as createAction } from 'typesafe-actions';
import FileDownload from 'js-file-download';
import apiAction from 'actions/_shared/apiAction';
import {
  IJobAnalysisRequest,
  InkConsumptionServerRecord,
  JobAnalysisChartCard,
  ColorRecordBasic,
} from 'models/JobAnalysisData';

import {
  JobAnaylsisByType,
  JobAnaylsisByTypePartial,
  Store,
  UserSystemGroup,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { makeApiUrl } from './_shared/utils';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { getCustomSearchParameters } from 'helpers/date';
import { normalizeGraphData } from 'helpers/apiCoversion';
import { sortByGranularity } from 'helpers/sorting';
import { getRealTimeHeaders } from './_shared/realTimeHeaders';
import { mapZeroValuesBolltesToNull } from 'helpers/jobAnalysisUtils';

export const filterInkComparisonColorRecords = data => {
  return {
    ...data.colorComparisonResponse,
    colors: data.colorComparisonResponse.colors.filter(color => color.colorName !== null),
  };
};
export const mapInkConsumptionTrend = (data: {
  systemGroup: string;
  records: InkConsumptionServerRecord[];
}) => {
  if (!data.records.length) {
    return { colors: [], records: [] };
  }

  const colors = data.records[0].color.map(c => c.colorName);

  colors.unshift('Total');
  const records: any[] = [];
  data.records.forEach((record, index) => {
    //* in each record there is array with records for each record there is field colorValue
    //* and in case it has -2 value we have to set it to null - it means future period
    const mappedRecord: any = { date: record.date };
    let total = 0;
    record.color.forEach(colorRecord => {
      total += colorRecord.colorValue;
      mappedRecord[colorRecord.colorName] =
        colorRecord.colorValue < 0 ? null : colorRecord.colorValue;
    });
    mappedRecord['Total'] = total;
    mappedRecord['granularity'] = record['granularity'];
    records.push(mappedRecord);
  });
  return { colors, records };
};

export const normalizeColorKeys = (key: string) => {
  if (key.toLocaleLowerCase() === 'fof') {
    return 'FOF';
  } else if (key.toLocaleLowerCase() === 'soft') {
    return 'Softener';
  } else if (key.toLocaleLowerCase() === 'color') {
    return 'Color';
  }
};

interface JobAnalysisTotalCardsResponse {
  totalProductionResponse: {
    totalProduction: number;
  };
  totalBottlesLoadedResponse: {
    systemGroup: UserSystemGroupStrings;
    bottles: {
      colorName: string;
      colorValue: number;
    }[];
  };
  totalPrintingTimeResponse: {
    systemGroup: UserSystemGroupStrings;
    totalPrintingTimeSeconds: number;
  };
}

export const jobAnalysisTotalCardsAC = createAsyncAction(
  'GET_JOBANALYSIS_TOTAL_CARDS_STARTED',
  'GET_JOBANALYSIS_TOTAL_CARDS_SUCCESS',
  'GET_JOBANALYSIS_TOTAL_CARDS_FAILED'
)<IJobAnalysisRequest, JobAnaylsisByTypePartial, Error>();

export const jobAnalysisTotalCardsRequest = (
  { month, year, quarter, week, day, custom, systems, SystemGroupType },
  isSilent = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/total-data-cards');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(jobAnalysisTotalCardsAC.failure(error)),
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(jobAnalysisTotalCardsAC.request({})),
        onSuccess: (data: JobAnalysisTotalCardsResponse, dispatch) => {
          const dataWithSystemGroup = {};
          dataWithSystemGroup[SystemGroupType!] = {
            bottlesLoaded: mapZeroValuesBolltesToNull(data),
            totalPrintingTime: data.totalPrintingTimeResponse.totalPrintingTimeSeconds,
            totalProduction: data.totalProductionResponse.totalProduction,
          };
          dispatch(jobAnalysisTotalCardsAC.success(dataWithSystemGroup));
        },
      },
    });
  }
};

interface JobAnalysisChartCardsResponse {
  printTimePerUnitResponse: JobAnalysisChartCard;
  inkConsumptionPerUnitResponse: JobAnalysisChartCard;
  mediaWidthResponse: JobAnalysisChartCard;
}

export const jobAnalysisChartCardsAC = createAsyncAction(
  'GET_JOBANALYSIS_CHART_CARDS_STARTED',
  'GET_JOBANALYSIS_CHART_CARDS_SUCCESS',
  'GET_JOBANALYSIS_CHART_CARDS_FAILED'
)<any, JobAnaylsisByTypePartial, Error>();

export const jobAnalysisChartCardsRequest = (
  {
    custom,
    month,
    year,
    quarter,
    week,
    day,
    systems,
    SystemGroupType,
    DtgFilterParameters,
    DtfFilterParameters,
  }: IJobAnalysisRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/data-per-unit-cards');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          DtgFilterParameters,
          DtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(jobAnalysisChartCardsAC.failure(error)),
        onStarted: isSilent
          ? dispatch => {}
          : dispatch =>
              dispatch(
                jobAnalysisChartCardsAC.request({
                  selectedSystemGroup: SystemGroupType,
                })
              ),
        onSuccess: (data: JobAnalysisChartCardsResponse, dispatch) => {
          const dataWithSystemGroup: JobAnaylsisByTypePartial = {};

          dataWithSystemGroup[SystemGroupType!] = {
            printingTimePerUnit: data.printTimePerUnitResponse,
            inkConsumptionPerUnit: data.inkConsumptionPerUnitResponse,
            mediaWithResponse: data.mediaWidthResponse,
            shouldBeUpdatedAfterGroupSelection: false,
          };

          dispatch(jobAnalysisChartCardsAC.success(dataWithSystemGroup));
        },
      },
    });
  }
};

interface JobAnalysisChartsResponse {
  inkConsumptionTrendResponse: {
    systemGroup: UserSystemGroupStrings;
    records: InkConsumptionServerRecord[];
  };
  colorComparisonResponse: {
    systemGroup: UserSystemGroupStrings;
    colors: ColorRecordBasic[];
  };
  colorConsumptionResponse: {
    systemGroup: UserSystemGroupStrings;
    colors: ColorRecordBasic[];
  };
}

export const jobAnalysisChartsAC = createAsyncAction(
  'GET_JOBANALYSIS_CHARTS_STARTED',
  'GET_JOBANALYSIS_CHARTS_SUCCESS',
  'GET_JOBANALYSIS_CHARTS_FAILED'
)<IJobAnalysisRequest, JobAnaylsisByTypePartial, Error>();

export const jobAnalysisChartsRequest = (
  {
    custom,
    month,
    year,
    quarter,
    week,
    day,
    systems,
    SystemGroupType,
    DtgFilterParameters,
    DtfFilterParameters,
  }: IJobAnalysisRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/graphs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          DtgFilterParameters,
          DtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(jobAnalysisChartsAC.failure(error));
        },
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(jobAnalysisChartsAC.request({})),
        onSuccess: (data: JobAnalysisChartsResponse, dispatch) => {
          const dataWithSystemGroup: JobAnaylsisByTypePartial = {};
          //*in case of DTF we need to normalize the data because the back end sends it in a different format
          if (SystemGroupType === 'DTF') {
            data.inkConsumptionTrendResponse.records = normalizeGraphData({
              records: data.inkConsumptionTrendResponse.records,
              month,
              year,
              quarter,
              week,
              day,
            });
          }
          dataWithSystemGroup[SystemGroupType!] = {
            inkConsumptionChart: mapInkConsumptionTrend(data.inkConsumptionTrendResponse),
            colorConsumptionChart: data.colorConsumptionResponse,
            colorCompareChart: filterInkComparisonColorRecords(data),
          };

          dispatch(jobAnalysisChartsAC.success(dataWithSystemGroup));
        },
      },
    });
  }
};

export const jobAnalysisToggleSelectedColorsAC = createAction('SET_SELECTED_COLORS')<
  Partial<JobAnaylsisByType>
>();

export const jobAnalysisSetSelectedColors = (selectedColors: string[]) => (
  dispatch: ThunkDispatch<Store, any, Action>,
  getState: () => Store
) => {
  const selectedSystemGroup = getState().ui.selectedSystemGroup;
  dispatch(jobAnalysisToggleSelectedColorsAC({ [selectedSystemGroup]: { selectedColors } }));
};

export const isInkConsumptionPerJobValidToDownloadAC = createAsyncAction(
  'IS_INK_CONSUPMTION_PER_JOB_VALID_TO_DOWNLOAD_STARTED',
  'IS_INK_CONSUPMTION_PER_JOB_VALID_TO_DOWNLOAD_SUCCESS',
  'IS_INK_CONSUPMTION_PER_JOB_VALID_TO_DOWNLOAD_FAILED'
)<IJobAnalysisRequest, any, any>();

export const isInkConsumptionPerJobValidToDownload = ({
  month,
  year,
  quarter,
  week,
  day,
  custom,
  systems,
  SystemGroupType,
}: IJobAnalysisRequest) => {
  const url = makeApiUrl('/api/job-analysis-report/check-ink-consumption-per-job-file');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(
            isInkConsumptionPerJobValidToDownloadAC.failure({
              selectedSystemGroup: SystemGroupType,
              name: error.name,
              message: 'something went wrong, try again later',
            })
          );
        },
        onStarted: dispatch => {
          dispatch(
            isInkConsumptionPerJobValidToDownloadAC.request({
              SystemGroupType,
            })
          );
        },
        onSuccess: (data, dispatch, state, status) => {
          // const mockStatus = 204;
          dispatch(
            isInkConsumptionPerJobValidToDownloadAC.success({
              SystemGroupType,
              status,
            })
          );
        },
      },
    });
  }
};

export const downloadInkConsumptionPerJobReportAC = createAsyncAction(
  'DOWNLOAD_INK_CONSUPMTION_PER_JOB_REPORT_STARTED',
  'DOWNLOAD_INK_CONSUPMTION_PER_JOB_REPORT_SUCCESS',
  'DOWNLOAD_INK_CONSUPMTION_PER_JOB_REPORT_FAILED'
)<IJobAnalysisRequest, any, any>();

export const downloadInkConsumptionPerJobReport = ({
  month,
  year,
  quarter,
  week,
  day,
  custom,
  systems,
  SystemGroupType,
}: IJobAnalysisRequest) => {
  const url = makeApiUrl('/api/job-analysis-report/ink-consumption-per-job-file');
  const { isAuthenticated, idTokenPayload } = authService;

  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          responseType: 'blob',
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(
            downloadInkConsumptionPerJobReportAC.failure({
              selectedSystemGroup: SystemGroupType,
              name: error.name,
              message: 'something went wrong, try again later',
            })
          );
        },
        onStarted: dispatch => {
          dispatch(downloadInkConsumptionPerJobReportAC.request({ SystemGroupType }));
        },
        onSuccess: async (data, dispatch, state, status) => {
          //* 2 cases - the file is too big and there is no file.
          const result =
            status === 204
              ? 'not downloaded, no content'
              : status === 205
              ? 'not downloaded, file is too big'
              : 'downloaded';
          const type = data.type;
          const [_, nameWithFileExtension] = type.split('/');
          const [name, __] = nameWithFileExtension.split('.');
          KonnectAnalytics.downloadInkConsumptionPerJobReport({
            result,
          });
          //download only in case of 200 status
          if (status === 200) {
            let underscoreIndex = name.indexOf('_');
            const dateRangeName = name.substring(underscoreIndex);
            FileDownload(data, `InkConsumptionPerJob${dateRangeName}.xlsx`);
            dispatch(
              downloadInkConsumptionPerJobReportAC.success({
                selectedSystemGroup: SystemGroupType,
                status,
              })
            );
          }
        },
      },
    });
  }
};
// new reqeuest - now only for apollo
export const jobAnalysisTotalImpressionsKPIAC = createAsyncAction(
  'GET_JOBANALYSIS_TOTAL_IMPRESSIONS_KPI_STARTED',
  'GET_JOBANALYSIS_TOTAL_IMPRESSIONS_KPI_SUCCESS',
  'GET_JOBANALYSIS_TOTAL_IMPRESSIONS_KPI_FAILED'
)<IJobAnalysisRequest, any, Error>();

export const jobAnalysisTotalImpressionsKPIRequest = (
  {
    custom,
    month,
    year,
    quarter,
    week,
    day,
    systems,
    SystemGroupType,
    DtgFilterParameters,
    DtfFilterParameters,
  }: IJobAnalysisRequest,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/total-impressions');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          DtgFilterParameters,
          DtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(jobAnalysisTotalImpressionsKPIAC.failure(error));
        },
        onStarted: dispatch => dispatch(jobAnalysisTotalImpressionsKPIAC.request({ isSilent })),
        onSuccess: (data: any, dispatch) => {
          dispatch(jobAnalysisTotalImpressionsKPIAC.success({ data, SystemGroupType }));
        },
      },
    });
  }
};

export const jobAnalysisPrintingTimeKPIAC = createAsyncAction(
  'GET_JOBANALYSIS_PRINTING_TIME_KPI_STARTED',
  'GET_JOBANALYSIS_PRINTING_TIME_KPI_SUCCESS',
  'GET_JOBANALYSIS_PRINTING_TIME_KPI_FAILED'
)<IJobAnalysisRequest, any, Error>();

export const jobAnalysisPrintingTimeKPIRequest = (
  {
    custom,
    month,
    year,
    quarter,
    week,
    day,
    systems,
    SystemGroupType,
    DtgFilterParameters,
    DtfFilterParameters,
  }: IJobAnalysisRequest,
  isSilent,
  isRealTime = false
) => {
  const postfix =
    SystemGroupType === UserSystemGroup.Apollo ? 'cycle-time-KPIs' : 'print-time-KPIs';
  const url = makeApiUrl(`/api/job-analysis-report/${postfix}`);
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          DtgFilterParameters,
          DtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(jobAnalysisPrintingTimeKPIAC.failure(error));
        },
        onStarted: dispatch =>
          dispatch(jobAnalysisPrintingTimeKPIAC.request({ isSilent, SystemGroupType })),
        onSuccess: (data: any, dispatch) => {
          dispatch(jobAnalysisPrintingTimeKPIAC.success({ data, SystemGroupType }));
        },
      },
    });
  }
};
export const jobAnalysisInkConsumptionKPIAC = createAsyncAction(
  'GET_JOBANALYSIS_INK_CONSUMPTION_KPI_STARTED',
  'GET_JOBANALYSIS_INK_CONSUMPTION_KPI_SUCCESS',
  'GET_JOBANALYSIS_INK_CONSUMPTION_KPI_FAILED'
)<IJobAnalysisRequest, any, Error>();

export const jobAnalysisInkConsumptionKPIRequest = (
  {
    custom,
    month,
    year,
    quarter,
    week,
    day,
    systems,
    SystemGroupType,
    DtgFilterParameters,
    DtfFilterParameters,
  }: IJobAnalysisRequest,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/ink-consumption-KPIs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          DtgFilterParameters,
          DtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(jobAnalysisInkConsumptionKPIAC.failure(error));
        },
        onStarted: dispatch =>
          dispatch(jobAnalysisInkConsumptionKPIAC.request({ isSilent, SystemGroupType })),
        onSuccess: (data, dispatch, state) => {
          //* records comes from server in unordered way, we have to sort it by date field - date field format depends on granularity
          data.inkConsumptionTrendResponse.records = sortByGranularity({
            granularity: data.inkConsumptionTrendResponse.records[0]?.granularity,
            records: data.inkConsumptionTrendResponse.records,
          });
          const dataWithSystemGroup: JobAnaylsisByTypePartial = {};
          dataWithSystemGroup[SystemGroupType!] = {
            inkConsumptionChart: mapInkConsumptionTrend(data.inkConsumptionTrendResponse),
            colorConsumptionChart: data.colorConsumptionResponse,
            colorCompareChart: filterInkComparisonColorRecords(data),
            inkConsumptionPerUnit: data.inkConsumptionPerUnitResponse,
            lastUpdate: state.apiState.reportsCurrentState.apolloLastUpdate,
          };
          dispatch(jobAnalysisInkConsumptionKPIAC.success(dataWithSystemGroup));
        },
      },
    });
  }
};

export const jobAnalysisTotalBottlesLoadedKPIAC = createAsyncAction(
  'GET_TOTAL_BOTTLES_LOADED_KPI_STARTED',
  'GET_TOTAL_BOTTLES_LOADED_KPI_SUCCESS',
  'GET_TOTAL_BOTTLES_LOADED_KPI_FAILED'
)<IJobAnalysisRequest, any, Error>();

export const jobAnalysisTotalBottlesLoadedKPIRequest = (
  {
    custom,
    month,
    year,
    quarter,
    week,
    day,
    systems,
    SystemGroupType,
    DtgFilterParameters,
    DtfFilterParameters,
  }: IJobAnalysisRequest,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/job-analysis-report/total-bottles-loaded-KPIs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          month,
          year,
          quarter,
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          DtgFilterParameters,
          DtfFilterParameters,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(jobAnalysisTotalBottlesLoadedKPIAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(jobAnalysisTotalBottlesLoadedKPIAC.request({ isSilent, SystemGroupType }));
        },
        onSuccess: (data, dispatch, state) => {
          dispatch(jobAnalysisTotalBottlesLoadedKPIAC.success(data));
        },
      },
    });
  }
};
