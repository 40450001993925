import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SchedulerTable from './SchedulerTable';
import { Store } from 'store/storeTypes';

const mapStateToProps = (state: Store) => {
  const { isLoadingSchedulers } = state.api;
  return {
    schedulerIds: state.entities.schedulers.allIds,
    isLoading: isLoadingSchedulers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type SchedulerTableProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerTable);
