import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import enLocale from 'date-fns/locale/en-US';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import { DatePickerProps } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import FormHelperText from '@material-ui/core/FormHelperText';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { createMuiTheme as createCustomTheme } from '@material-ui/core';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { getWeekStartEnv } from 'helpers/envGetters';
import { ValidationError } from 'helpers/validationRules';
import appTheme from '../../theme';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { activeTheme } from 'components/FiltersCalendarModal/MobileDatePicker';

const FORMAT_DATE = 'MMMM dd, yyyy';
const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const WEEK_START = getWeekStartEnv();

export class LocalizedUtils extends DateFnsUtils {
  getWeekdays = () => {
    return WEEK_DAYS;
  };

  getCalendarHeaderText = (date: Date) => {
    return format(date, FORMAT_DATE, { locale: this.locale });
  };
}

const Picker = styled(DatePicker)`
  width: 100%;
  justify-content: center;

  input,
  label {
    font-family: Rubik, sans-serif;
  }

  input {
    font-size: 14px;
    color: #354052;
  }
`;
const MobilePicker = styled(DatePicker)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  input,
  label {
    font-family: Rubik, sans-serif;
  }

  input {
    font-size: 14px;
    color: #354052;
  }
`;
const PickerIknConsumtion = styled(DatePicker)`
  width: 100%;
  justify-content: center;

  input,
  label {
    font-family: Rubik, sans-serif;
  }

  input {
    font-size: 14px;
    color: #354052;
  }
`;
const theme = (theme: { breakpoints: { only: (string: string) => string | number | symbol } }) =>
  createMuiTheme({
    ...appTheme,

    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          [theme.breakpoints.only('xs')]: {
            minWidth: 'auto',
            padding: '25px 0',
            borderBottom: '1px solid #C5D0DE',
          },
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          [theme.breakpoints.only('xs')]: {
            maxWidth: '100%',
            minWidth: 'auto',
            minHeight: 'auto',
            justifyContent: 'flex-start',
          },
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          [theme.breakpoints.only('xs')]: {},
        },
        week: {
          [theme.breakpoints.only('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {},
      },
      MuiPickersCalendarHeader: {
        dayLabel: {
          width: '32px',
          margin: '0 3px',
        },
        iconButton: {
          [theme.breakpoints.only('xs')]: {
            padding: 0,
            borderRadius: '4px',
            border: '1px solid black',
            '&:disabled': {
              borderColor: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },

        switchHeader: {
          [theme.breakpoints.only('xs')]: {
            display: 'none',
          },
        },
        daysHeader: {
          [theme.breakpoints.only('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
          },
        },
      },
      MuiPickersYearSelection: {
        container: {
          [theme.breakpoints.only('xs')]: {
            height: 'auto',
            width: '100%',
            display: 'grid',
            gridGap: '10px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
          },
        },
      },
      MuiPickersYear: {
        root: {
          [theme.breakpoints.only('xs')]: {
            color: '#354052',
            height: '60px',
            width: '60px',
            border: '1px solid #C5D0DE',
            borderRadius: '4px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            '&:focus': {
              color: 'white',
            },
          },
        },
        yearSelected: {
          [theme.breakpoints.only('xs')]: {
            margin: 0,
            backgroundColor: '#2EA1F8',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '19px',
          },
        },
        yearDisabled: {},
        // current: {},
      },
      MuiPickersMonthSelection: {
        container: {
          [theme.breakpoints.only('xs')]: {
            width: '100%',
            display: 'grid',
            flexWrap: 'initial',
            alignContent: 'initial',
            gridGap: '10px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
          },
        },
      },
      MuiPickersMonth: {
        root: {
          [theme.breakpoints.only('xs')]: {
            color: '#354052',
            height: '60px',
            width: '60px',
            border: '1px solid #C5D0DE',
            borderRadius: '4px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            '&:focus': {
              color: 'white',
            },
          },
        },
        monthSelected: {
          [theme.breakpoints.only('xs')]: {
            backgroundColor: '#2EA1F8',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '19px',
          },
        },
        monthDisabled: {},
      },
      MuiPickersDay: {
        day: {
          [theme.breakpoints.only('xs')]: {
            width: '58px',
            height: '48px',
            margin: '0 3px',
            borderRadius: '4px',
          },
        },
        current: {},
        daySelected: {
          color: 'white',
        },
        dayDisabled: {},
      },
      MuiPickersModal: {
        // root: {},
        // dialogAction: {},
      },
      MuiInput: {
        underline: {
          height: '29px',
          '&:before': { borderBottomColor: 'rgb(197, 208, 222)' },
          // '&:after': { borderBottomColor: 'rgb(32, 112, 173)' },
          '&:after': { borderBottomColor: 'rgb(32, 112, 173)' },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#7f8fa4',
          '&:focus': { color: '#7f8fa4' },
        },
      },
      MuiFormHelperText: {
        root: {
          position: 'absolute',
          bottom: '-8px',
          fontSize: '10px',
          whiteSpace: 'nowrap',
          opacity: 0.5,
        },
      },
    },
  });

interface FilterDatePickerProps
  extends Pick<DatePickerProps, Exclude<keyof DatePickerProps, 'error'>> {
  error?: ValidationError | false;
  noHelper?: boolean;
  variant?: 'dialog' | 'inline' | 'static';
  openTo?: 'year' | 'month' | 'date';
  disableToolbar?: boolean;
  fullWidth?: boolean;
  parentComponent?: string;
  isMobile?: boolean;
  labelFunc?: (date: MaterialUiPickersDate, invalidLabel: string) => string;
  onChange: (d?: Date) => void | Record<string, unknown>;
  closeCalendarModal?: () => void;
}

const FilterDatePicker = React.memo((props: FilterDatePickerProps) => {
  const { t } = useTranslation();
  const {
    id,
    error,
    noHelper,
    variant,
    openTo,
    disableToolbar = true,
    labelFunc,
    onChange,
    closeCalendarModal,
    minDate,
    maxDate,
    parentComponent,
    isMobile,
    ...rest
  } = props;

  const renderPickerByParentComponenet = (parentComponent: string) => {
    if (!parentComponent) {
      return (
        <Picker
          data-automationid={`data_filter`}
          {...rest}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          id={id}
          disableToolbar={disableToolbar}
          variant={variant}
          autoOk
          format={FORMAT_DATE}
          openTo={openTo}
          labelFunc={labelFunc}
        />
      );
    }

    if (parentComponent === 'mobileFilterWeeks') {
      return (
        <MobilePicker
          data-automationid={`data_filter`}
          {...rest}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          id={id}
          disableToolbar={disableToolbar}
          variant={variant}
          autoOk
          format={FORMAT_DATE}
          openTo={openTo}
          labelFunc={labelFunc}
        />
      );
    }
    if (parentComponent === 'mobileFilterMonth') {
      return (
        <MobilePicker
          data-automationid={`data_filter`}
          {...rest}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          id={id}
          disableToolbar={disableToolbar}
          variant={variant}
          autoOk
          format={FORMAT_DATE}
          openTo={openTo}
          labelFunc={labelFunc}
        />
      );
    }
  };
  const getTheme = (parentComponent: string) => {
    if (!parentComponent) {
      return theme;
    }

    if (parentComponent === 'mobileFilterWeeks') {
      return activeTheme;
    }
    if (parentComponent === 'mobileFilterMonth') {
      return activeTheme;
    }
  };
  const currentTheme = getTheme(parentComponent);
  return (
    <MuiThemeProvider theme={currentTheme}>
      <MuiPickersUtilsProvider
        utils={LocalizedUtils}
        locale={{
          ...enLocale,
          options: {
            ...enLocale.options,
            weekStartsOn: WEEK_START,
          },
        }}>
        {renderPickerByParentComponenet(parentComponent)}
      </MuiPickersUtilsProvider>

      {!noHelper && <FormHelperText>{error && t(error.key, error.values)}</FormHelperText>}
    </MuiThemeProvider>
  );
});

export default FilterDatePicker;
