import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@material-ui/core';
import PageContainer from '../_common/PageContainer';
import { PageHeaderUIContainer } from '../_common/PageHeaderUi';
import GoalItemContainer from './GoalItemContainer';
import LinearProgress from '../_common/LinearProgress';
import { GoalsData } from 'models/GoalsData';
import { GoalsProps } from './GoalsContainer';

const Icon = styled.img.attrs({
  src: '/img/goalsImg.png',
})`
  margin: 0 16px 3px 0;
  @media (max-width: 360px) {
    display: none;
  }
`;

const Circular = styled(CircularProgress)`
  size: 128px;
  color: #2ea1f8;
  position: absolute;
  top: 40%;
  left: 50%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 440px) {
    margin: 10px 0;
  }
`;

const Title = styled.h1`
  font-family: Rubik, sans-serif;
  font-weight: normal;
  font-size: 22px;
  color: #354052;
`;

const Goals = React.memo((props: GoalsProps) => {
  const { t } = useTranslation();
  const {
    goalsEntries,
    isLoadingGoals,
    isSavingGoals,
    getGoalsData,
    selectedSystemGroup,
    userSettings,
  } = props;
  useEffect(() => {
    getGoalsData();
  }, [getGoalsData, selectedSystemGroup, userSettings]);
  return (
    <PageContainer>
      <PageHeaderUIContainer>
        <TitleWrapper>
          <Icon />
          <Title>{t('goals.goals')}</Title>
        </TitleWrapper>
      </PageHeaderUIContainer>

      {!isLoadingGoals &&
        goalsEntries.map((entry: GoalsData) => (
          <GoalItemContainer key={entry.goalType} goalId={entry.goalType} />
        ))}

      {isLoadingGoals && <LinearProgress />}

      {isSavingGoals && <Circular />}
    </PageContainer>
  );
});

export default Goals;
