import * as React from 'react';
import { ActiveReportsTypes, UserSystemGroup } from 'store/storeTypes';
import styled from 'styled-components/macro';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { ReactComponent as FabricIcon } from 'assets/icons/fabricIcon.svg';
import { ReactComponent as TshirtIcon } from 'assets/icons/tshirtIcon.svg';
import { ReactComponent as RocketIcon } from 'assets/icons/rocketIcon.svg';
import { PbiFilterSystemGroupProps } from './PbiFilterSystemGroupContainer';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { makeTreeData } from 'components/_common/TreeSelect/treeHelpers';

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  box-sizing: border-box;
  width: 147px;
  height: 36px;
  border: 1px solid #7f8fa4;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;

const ToggleButtonStyled = styled(ToggleButton)`
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  border-radius: 2px !important;
  svg {
    stroke: #c5d0de;
    fill: #c5d0de;
  }
  &.Mui-selected {
    background-color: #2ea1f8;
    stroke: white;
    fill: white;
  }
`;

export default function PbiFilterSystemGroup(props: PbiFilterSystemGroupProps) {
  const {
    setSystemGroup,
    setFilterSystemsState,
    selectedSystemGroup,
    currentActiveReport,
    importedTennantId,
    tennantName,
    filterData,
    userSystemGroup,
  } = props;
  const handleChangeDashboard = (
    event: React.MouseEvent<HTMLElement>,
    newDashboard: UserSystemGroup
  ) => {
    if (selectedSystemGroup !== newDashboard && newDashboard !== null) {
      KonnectAnalytics.changeSystemGroupFilter('ChangeSystemGroupFilter', {
        systemGroupFrom: selectedSystemGroup,
        systemGroupTo: newDashboard,
        currentActiveReport,
        tennantName,
        importedTennantId,
      });
      setSystemGroup(newDashboard);
      const allSystemsSelected = makeTreeData({
        rootElements: filterData[newDashboard].sites,
        parentElements: filterData[newDashboard].models.map(m => ({
          id: m.id,
          name: m.name,
          rootId: m.siteId,
        })),
        childElements: filterData[newDashboard].systems.map(s => ({
          id: s.name,
          name: s.name,
          rootId: s.siteId,
          parentId: s.modelId,
        })),
        rootSelectedIds: filterData[newDashboard].sites.map(site => site.id),
        parentSelectedIds: filterData[newDashboard].models.map(model => model.id),
        childSelectedIds: filterData[newDashboard].systems.map(system => system.name),

        enableLowLevelSelect: true,
      });
      setFilterSystemsState(allSystemsSelected);
    }
  };
  return (
    <ToggleButtonGroupStyled exclusive value={selectedSystemGroup} onChange={handleChangeDashboard}>
      {userSystemGroup.includes('Apollo') && (
        <ToggleButtonStyled value={UserSystemGroup.Apollo}>
          <RocketIcon width={20} height={18} />
        </ToggleButtonStyled>
      )}
      {userSystemGroup.includes('DTG') && (
        <ToggleButtonStyled value={UserSystemGroup.DTG}>
          <TshirtIcon width={25} height={25} />
        </ToggleButtonStyled>
      )}
      {userSystemGroup.includes('DTF') && (
        <ToggleButtonStyled value={UserSystemGroup.DTF}>
          <FabricIcon width={25} height={25} />
        </ToggleButtonStyled>
      )}
    </ToggleButtonGroupStyled>
  );
}
