import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiCloseIcon from '@material-ui/icons/Close';

import { removeNotification } from '../../actions/uiActions';
import { Store, Notification } from '../../store/storeTypes';

const IconButton = styled(MuiIconButton)`
  color: white;
  padding: 5px;
`;

const CloseIcon = styled(MuiCloseIcon)`
  font-size: 20px;
`;

interface NotifierProps extends WithSnackbarProps {
  notifications: Notification[];
  removeNotification: (key: string) => void;
}

const Notifier = React.memo((props: NotifierProps) => {
  const [displayed, setDisplayed] = useState<string[]>([]);
  const { notifications = [] }: { notifications: Notification[] } = props;

  useEffect(() => {
    notifications.forEach(notification => {
      const action = (key: string) => (
        <IconButton
          onClick={() => {
            props.closeSnackbar(key);
          }}>
          <CloseIcon />
        </IconButton>
      );

      const { message, key, options } = notification;

      // Do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // Display snackbar using notistack
      props.enqueueSnackbar(message, {
        action,
        preventDuplicate: true,
        ...options,
      });

      // Keep track of snackbars that we've displayed
      setDisplayed([...displayed, key]);

      // Dispatch action to remove snackbar from redux store
      props.removeNotification(key);
    });
  }, [notifications, displayed, props]);

  return null;
});

const mapStateToProps = (store: Store) => ({
  notifications: store.ui.notificationQueue,
});

const mapDispatchToProps = { removeNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));
