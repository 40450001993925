import { AuthService } from 'contracts/auth';

export function makeApiUrl(path: string): string {
  // const test = 'https://konnect-test-edge-portal-api-appservice.kornitkonnect.com';
  // return test + path;
  return localStorage.getItem('portalApiUrl') + path;
}

export function makeAuthHeader(authService: AuthService) {
  return {
    Authorization: `Bearer ${authService.accessToken}`,
  };
}
