import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import UserModal from './UserModal';
import { Store, MODAL_STATUS } from '../../store/storeTypes';
import { closeModalUser } from '../../actions/userUiActions';

const mapStateToProps = (state: Store) => ({
  isOpen: state.ui.userModal.status !== MODAL_STATUS.CLOSED,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModalUser,
    },
    dispatch
  );

export type UserModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
