import { combineReducers } from 'redux';
import { appUserReducer } from './appUserReducer';
import { apiReducer } from './apiReducer';
import { apiStateReducer } from './apiStateReducer';
import { entitiesReducer } from './entities';
import { uiReducer } from './ui';
import { reportsReducer } from './reports';
import { userSettingsReducer } from './userSettingsReducer';
import ServiceTicket from './serviceTicketReducer';
import systemAnalysisReducer from './systemAnalysisReducer';
import overviewReducer from './overviewReducer';
import machineConfigReducer from './machineConfigReducer';
import jobSummaryReducer from './jobSummaryReducer';
import jobAnalysisReducer from './jobAnalysisReducer';
import apiManagementReducer from './apiManagementReducer';
import systemPageReducer from './systemPageReducer';

import isEverReportedReducer from './isEverReportedReducer';
export default combineReducers({
  appUser: appUserReducer,
  userSettings: userSettingsReducer,
  api: apiReducer,
  apiState: apiStateReducer,
  entities: entitiesReducer,
  ui: uiReducer,
  reports: reportsReducer,
  systemAnalysis: systemAnalysisReducer,
  overview: overviewReducer,
  machineConfig: machineConfigReducer,
  jobSummary: jobSummaryReducer,
  jobAnalysis: jobAnalysisReducer,
  apiManagement: apiManagementReducer,
  systemPage: systemPageReducer,
  serviceTicket: ServiceTicket,
  isEverReported: isEverReportedReducer,
});
