import { createSelector } from 'reselect';
import i18next from 'i18next';

import { Store, Entries, Ids } from 'store/storeTypes';
import { CustomerData } from 'models/CustomerData';
import { SiteData } from 'models/SiteData';
import { SystemData } from 'models/SystemData';
import { userIdFromPropsSelector } from './propsSelectors';
import {
  usersSelector,
  userIdsSelector,
  customersSelector,
  customerIdsSelector,
  sitesSelector,
  systemsSelector,
} from './entitiesSelectors';
import { userPermissionsToRoles } from 'helpers/conversions';

// ----------- UI selectors ----------

export const userFormSelector = (state: Store) => state.ui.userForm;
export const selectedUserRowsSelector = (state: Store) => state.ui.selectedUserRows;
export const isAllRowsCheckedSelector = (state: Store) =>
  state.entities.users.allIds.length === state.ui.selectedUserRows.length;
export const userSearchSelector = (state: Store) => {
  return state.ui.userSearch;
};

/** Needed to check if user email is uniqueEmail */
export const allUsersButCurrentEmailsSelector = createSelector(
  [usersSelector, userIdsSelector, userFormSelector],
  (userByIds, userIds, userForm) => {
    return userIds.filter(id => id !== userForm.id).map(id => userByIds[id].email);
  }
);

/**
 * Selector factories (named like makeSomeSelector) are needed for components with multiple
 * instances, because selectors have only cache size of 1.
 * Details are here: {@link https://github.com/reduxjs/reselect}
 */
export const makeIsUserRowSelectedSelector = () =>
  createSelector([userIdFromPropsSelector, selectedUserRowsSelector], (userId, selectedUserRows) =>
    selectedUserRows.includes(userId)
  );

export const userCustomerIdsSelector = createSelector(
  [usersSelector, userIdFromPropsSelector, userFormSelector],
  (users, userId, form) => (userId ? users[userId].customerIds : form.customerIds)
);

export const userSiteIdsSelector = createSelector(
  [usersSelector, userIdFromPropsSelector, userFormSelector],
  (users, userId, form) => (userId ? users[userId].siteIds : form.siteIds)
);

export const userSystemIdsSelector = createSelector(
  [usersSelector, userIdFromPropsSelector, userFormSelector],
  (users, userId, form) => (userId ? users[userId].systemIds : form.systemIds)
);

export const makeUserScopeStringSelector = () =>
  createSelector(
    [
      // Entities data selectors
      customersSelector,
      customerIdsSelector,
      sitesSelector,
      systemsSelector,

      // User data selectors
      userCustomerIdsSelector,
      userSiteIdsSelector,
      userSystemIdsSelector,
    ],
    scopeStringPrepare
  );

export const schedulerFormCustomersSelector = (state: Store) => state.ui.schedulerForm.customerIds;
export const schedulerFormCustomerIdsSelector = (state: Store) =>
  state.ui.schedulerForm.customerIds;
export const schedulerFormSystemsSelector = (state: Store) => state.ui.schedulerForm.siteIds;

export const schedulerMarkedCustomersSelector = (state: Store) =>
  state.ui.schedulerForm.customerIds;
export const schedulerMarkedSitesSelector = (state: Store) => state.ui.schedulerForm.siteIds;
export const schedulerMarkedSystemsSelector = (state: Store) => state.ui.schedulerForm.systemIds;

export const schedulerScopeStringSelector = () =>
  createSelector(
    [
      // Entities data selectors
      customersSelector,
      customerIdsSelector,
      sitesSelector,
      systemsSelector,

      // User data selectors
      schedulerFormCustomersSelector,
      schedulerFormSystemsSelector,
      schedulerMarkedSystemsSelector,
    ],
    scopeStringPrepare
  );

/**
 * Makes an array of strings representing systems account has an access to.
 * Possible string forms:
 * "All"
 * "Customer(s) / All"
 * "Customer / Site(s) / All"
 * "Customer / Site / System(s)"
 */
const scopeStringPrepare = (
  customersById: Entries<CustomerData>,
  orgIds: Ids,
  sitesById: Entries<SiteData>,
  systemsById: Entries<SystemData>,
  userOrgIds: Ids,
  userSiteIds: Ids,
  userSystemIds: Ids
) => {
  // Resulting array of strings.
  const scopeParts: string[] = [];

  // Arrays of sites/systems filtered off elements covered by parents
  let inScopeSiteIds: string[] = userSiteIds;
  let inScopeSystemIds: string[] = userSystemIds;

  // Loop through user customers, make strings of customers with all sites granted
  const scopeOrgs: string[] = [];
  const scopeOrgIds: string[] = [];
  userOrgIds.forEach(orgId => {
    // Screen possible DB inconsistencies
    if (customersById[orgId]) {
      scopeOrgs.push(customersById[orgId].name);
      scopeOrgIds.push(orgId);

      // Filter off sites and systems of granted customers. There should not be
      // anything to filter here if our data is correct. But just for the case.
      inScopeSiteIds = inScopeSiteIds.filter(
        siteId => sitesById[siteId] && sitesById[siteId].customerId !== orgId
      );
      inScopeSystemIds = inScopeSystemIds.filter(
        systemId => systemsById[systemId] && systemsById[systemId].customerId !== orgId
      );
    } else {
      // TODO: throw an exception (error handling needed)
    }
  });

  // If all customers are granted to user, just return 'All'
  if (scopeOrgIds.sort().join(',') === orgIds.sort().join(',')) {
    return [i18next.t('userForm.all')];
  }
  // If not - from the scope representation string
  scopeOrgs.length && scopeParts.push(`${scopeOrgs.join(', ')} / ${i18next.t('userForm.all')}`);

  // Loop through user sites, make strings of sites with all systems granted
  // Sites are grouped by customers in temporary object
  const siteByOrg: { [key: string]: string[] } = {};
  inScopeSiteIds.forEach(siteId => {
    // Screen possible DB inconsistencies
    if (sitesById[siteId] && customersById[sitesById[siteId].customerId]) {
      const site = sitesById[siteId];
      const orgName = customersById[site.customerId].name;

      // If object property is already exist - push, if not - create new array
      if (siteByOrg[orgName]) {
        siteByOrg[orgName].push(site.name);
      } else {
        siteByOrg[orgName] = [site.name];
      }

      // Filter off systems of granted sites
      inScopeSystemIds = inScopeSystemIds.filter(
        systemId => systemsById[systemId] && systemsById[systemId].siteId !== siteId
      );
    } else {
      // TODO: throw an exception (error handling needed)
    }
  });
  Object.keys(siteByOrg).forEach(org => {
    scopeParts.push(`${org} / ${siteByOrg[org].join(', ')} / ${i18next.t('userForm.all')}`);
  });

  // Loop through systems left, make strings of systems.
  // Systems are being grouped by site and have additional field for that site's org
  const systemBySite: {
    [key: string]: { org: string; systems: string[] };
  } = {};
  inScopeSystemIds.forEach(systemId => {
    // Screen possible DB inconsistencies
    if (
      systemsById[systemId] &&
      sitesById[systemsById[systemId].siteId] &&
      customersById[systemsById[systemId].customerId]
    ) {
      const system = systemsById[systemId];
      const siteName = sitesById[system.siteId].name;

      // If object property is already exist - push, if not - create new array
      if (systemBySite[siteName]) {
        systemBySite[siteName].systems.push(system.serialNumber);
      } else {
        systemBySite[siteName] = {
          org: customersById[system.customerId].name,
          systems: [system.serialNumber],
        };
      }
    } else {
      // TODO: throw an exception (error handling needed)
    }
  });
  Object.keys(systemBySite).forEach(site => {
    const { org, systems } = systemBySite[site];
    scopeParts.push(`${org} / ${site} / ${systems.join(', ')}`);
  });

  if (scopeParts.length === 0) {
    scopeParts.push(i18next.t('userForm.allTheData'));
  }

  return scopeParts;
};

export const appUserToRoles = (state: Store) =>
  userPermissionsToRoles((state.appUser && state.appUser.permissions) || []);
