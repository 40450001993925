import { Entity, Store, UiStore, UserForm } from 'store/storeTypes';
import { ExtendedUserData } from 'models/ExtendedUserData';
import { CustomerData } from 'models/CustomerData';
import { SiteData } from 'models/SiteData';
import { SystemData } from 'models/SystemData';
import { userDataToTree } from './userScopeTreeConversions';
import { Permission, Permissions } from 'models/Permissions';

/** Converts User data from API model to Redux UI store model */
export function userApiToUi(
  customers: Entity<CustomerData>,
  sites: Entity<SiteData>,
  systems: Entity<SystemData>,
  user: ExtendedUserData
): UserForm {
  const systemsTree = userDataToTree(
    customers,
    sites,
    systems,
    user.customerIds,
    user.siteIds,
    user.systemIds
  );
  return {
    id: user.id,
    firstname: user.firstName,
    lastname: user.lastName,
    email: user.email,
    customerIds: user.customerIds,
    siteIds: user.siteIds,
    systemIds: user.systemIds,
    systemsTree,
    setGoals: user.permissions.includes(Permission.SetGoals),
    createReports: user.permissions.includes(Permission.CreateReports),
    adminPermissions: user.permissions.includes(Permission.Admin),
    avatarUrl: user.profilePictureUrl,
    isFormTouched: false,
    isRootUser: user.isRootUser || false,
    manageApi: user.permissions.includes(Permission.ManageApi),
  };
}

/** Converts User data from API model to Redux UI store model */
export function userUiToApi(uiStore: UiStore): ExtendedUserData {
  const {
    id,
    firstname,
    lastname,
    email,
    customerIds,
    siteIds,
    systemIds,
    avatarUrl,
    avatarBase64,
    adminPermissions,
    createReports,
    setGoals,
    isRootUser,
  } = uiStore.userForm;
  const userApi = {
    id,
    firstName: firstname,
    lastName: lastname,
    email: email.trim(),
    permissions: [] as Permissions,
    customerIds,
    siteIds,
    systemIds,
    avatar: avatarBase64,
    profilePictureUrl: avatarUrl,
  };
  adminPermissions && userApi.permissions.push(Permission.Admin);
  createReports && userApi.permissions.push(Permission.CreateReports);
  setGoals && userApi.permissions.push(Permission.SetGoals);
  isRootUser && userApi.permissions.push(Permission.Certificate);
  return userApi;
}

export function userUiToApiSaveUser(store: Store): ExtendedUserData {
  const {
    id,
    firstname,
    lastname,
    email,
    customerIds,
    siteIds,
    systemIds,
    avatarUrl,
    avatarBase64,
    adminPermissions,
    createReports,
    setGoals,
    isRootUser,
  } = store.ui.userForm;
  //here was changed API and istead of serial Number we need to pass id in request
  const printerIds = new Array();
  systemIds.forEach(serialNumber => {
    const ids = store.entities.systems.byId;
    const entitiesIds = Object.keys(ids);
    entitiesIds.forEach(entityId => {
      if (store.entities.systems.byId[entityId].serialNumber === serialNumber) {
        printerIds.push(store.entities.systems.byId[entityId].id);
      }
    });
  });
  const userApi = {
    id,
    firstName: firstname,
    lastName: lastname,
    email: email.trim(),
    permissions: [] as Permissions,
    customerIds,
    siteIds,
    systemIds: printerIds,
    avatar: avatarBase64,
    profilePictureUrl: avatarUrl,
  };
  adminPermissions && userApi.permissions.push(Permission.Admin);
  createReports && userApi.permissions.push(Permission.CreateReports);
  setGoals && userApi.permissions.push(Permission.SetGoals);
  isRootUser && userApi.permissions.push(Permission.Certificate);
  return userApi;
}
