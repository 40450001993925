import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ValidationError } from 'helpers/validationRules';
import { TimeFrame, TimeFrameEnum } from 'models/ReportData';
import { UserSystemGroupStrings } from 'store/storeTypes';

const FormControl = styled(MuiFormControl)`
  width: 100%;
`;

const InputLabel = styled(MuiInputLabel).attrs({
  classes: { focused: 'focused', shrink: 'shrink' },
})`
  font-size: 14px;
  font-family: Rubik, sans-serif;
  &.shrink {
    font-size: 16px;
  }
  &.focused {
    color: #7f8fa4;
  }
`;

interface SelectProps {
  view?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  items: string[];
  error?: ValidationError | false;
  onChange?: (e: React.SyntheticEvent) => void;
  value?: string | number | Record<string, unknown> | null | undefined;
  values?: (string | number)[];
  noEmpty?: boolean;
  noHelper?: boolean;
  className?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  formControlVariant?: 'standard' | 'outlined' | 'filled';
  open?: boolean;
  timeframe?: TimeFrame;
  selectedSystemGroup?: UserSystemGroupStrings;
}

const Select = (props: SelectProps) => {
  const { t } = useTranslation();
  const {
    id,
    label,
    items,
    value,
    values,
    onChange,
    error,
    noEmpty,
    noHelper,
    variant,
    formControlVariant,
    open,
    disabled,
    timeframe,
    selectedSystemGroup,
    view,
    ...rest
  } = props;
  if (view === 'mixed') {
    //*this case of mixed view (dropdown and button is used only on Job Analysis page in case of resolution <1920px)
    /**no need to show Custom option in case of timeframe !== Custom */
    const filteredItems =
      timeframe !== TimeFrameEnum.custom ? items.filter(item => item !== 'Custom') : items;
    const filteredValues =
      timeframe !== TimeFrameEnum.custom ? values?.filter(item => item !== 'custom') : values;

    /**index of Custom option in items array  is hardcoded on client side*/
    const INDEX_OF_CUSTOM_TIMEFRAME = 5;
    return (
      <FormControl {...rest} error={!!error} variant={formControlVariant}>
        {label && <InputLabel htmlFor="report">{label}</InputLabel>}

        <MuiSelect
          inputProps={{
            id,
            name: id,
            onChange,
          }}
          disabled={disabled}
          value={timeframe === TimeFrameEnum.custom ? values[INDEX_OF_CUSTOM_TIMEFRAME] : value}
          open={open}
          IconComponent={KeyboardArrowDownIcon}
          variant={variant}>
          {!noEmpty && (
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
          )}
          {filteredItems.map((v, i) => {
            return (
              <MenuItem value={filteredValues ? filteredValues[i] : i} key={v + i}>
                {v}
              </MenuItem>
            );
          })}
        </MuiSelect>

        {!noHelper && <FormHelperText>{error && t(error.key, error.values)}</FormHelperText>}
      </FormControl>
    );
  } else {
    return (
      <FormControl {...rest} error={!!error} variant={formControlVariant}>
        {label && <InputLabel htmlFor="report">{label}</InputLabel>}

        <MuiSelect
          inputProps={{
            id,
            name: id,
            onChange,
          }}
          disabled={disabled}
          value={value === null ? '' : value}
          open={open}
          IconComponent={KeyboardArrowDownIcon}
          variant={variant}>
          {!noEmpty && (
            <MenuItem value="">
              <em>{t('none')}</em>
            </MenuItem>
          )}
          {items.map((v, i) => (
            <MenuItem value={values ? values[i] : i} key={v + i}>
              {v}
            </MenuItem>
          ))}
        </MuiSelect>

        {!noHelper && <FormHelperText>{error && t(error.key, error.values)}</FormHelperText>}
      </FormControl>
    );
  }
};

export default Select;
