import { Store } from 'store/storeTypes';
import { createReducer } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { setCaptchaIsOpen } from 'actions/userUiActions';

const initState = { isOpen: false, userId: '' };
export default createReducer<any, any>(initState).handleAction(
  setCaptchaIsOpen,
  (state, action) => {
    return { ...state, isOpen: action.payload.isOpen, userId: action.payload.userId };
  }
);

const uiSelector = (state: Store) => {
  return state['ui'];
};
export const isCatpchaOpenSelector = createSelector([uiSelector], ui => {
  return ui['captcha'].isOpen;
});
export const userIdForCaptchaSelector = createSelector([uiSelector], ui => {
  return ui['captcha'].userId;
});
