import React, { Component, ReactNode, ErrorInfo } from 'react';
import styled from 'styled-components';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

const StyledContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2ea1f8;
`;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    KonnectAnalytics.sendErrorLog('Error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <StyledContainer>
          <p>Something went wrong.</p>
          <p>Please refresh the page to try again.</p>
        </StyledContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
