import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { PbiFilterDateProps } from './PbiFilterDateContainer';
import PbiFilterDays from './PbiFilterDaysContainer';
import PbiFilterYears from './PbiFilterYearsContainer';
import PbiFilterWeeks from './PbiFilterWeeksContainer';
import PbiFilterMonths from './PbiFilterMonthsContainer';
import PbiFilterQuarters from './PbiFilterQuartersContainer';
import PbiFilterCustom from './PbiFilterCustomContainer';

const PbiFilterDateWrapper = styled.div`
  position: relative;
  width: 410px;
  display: flex;
  align-items: center;
  margin: 0 30px;
`;

const RightPart = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    width: 56%;
  }
`;

const PbiFilterDate = React.memo((props: PbiFilterDateProps) => {
  const { filterState, screenWidth, isCustomTimeFrameOpen, setIsCustomTimeFrameOpen } = props;
  const timeframe = filterState.timeframe;

  const isMobile = screenWidth < 900;
  const isCustomFilter = isCustomTimeFrameOpen || timeframe === 'custom';
  return isMobile ? (
    <></>
  ) : (
    <>
      <PbiFilterDateWrapper>
        <Fragment>
          <RightPart>
            {isCustomFilter && (
              <PbiFilterCustom setIsCustomTimeFrameOpen={setIsCustomTimeFrameOpen} />
            )}
            {timeframe === 'day' && !isCustomTimeFrameOpen && <PbiFilterDays isMobile={false} />}
            {timeframe === 'week' && !isCustomTimeFrameOpen && <PbiFilterWeeks isMobile={false} />}
            {timeframe === 'month' && !isCustomTimeFrameOpen && (
              <PbiFilterMonths isMobile={false} />
            )}
            {timeframe === 'quarter' && !isCustomTimeFrameOpen && (
              <PbiFilterQuarters isMobile={false} />
            )}
            {timeframe === 'year' && !isCustomTimeFrameOpen && <PbiFilterYears isMobile={false} />}
          </RightPart>
        </Fragment>
      </PbiFilterDateWrapper>
    </>
  );
});

export default PbiFilterDate;
