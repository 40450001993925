import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setActiveOverviewChart } from 'actions/uiActions';
import { Store } from 'store/storeTypes';
import ToggleChart from './ToggleChart';
type OwnProps = {
  isMobile?: boolean;
};
const mapStateToProps = (state: Store) => {
  return {
    activeOverviewChart: state.ui.overview.activeChart,
    selectedSystemGroup: state.ui.selectedSystemGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setActiveOverviewChart }, dispatch);

export type ToggleChartProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(ToggleChart);
