import styled, { createGlobalStyle, keyframes } from 'styled-components/macro';
import { Tooltip, withStyles } from '@material-ui/core';
export const PBI_FILTER_ROW_HEIGHT = 49;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Rubik, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;

  }
  #root {
    height: 100%;
  }
  code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;
export const appearanceEffect = keyframes`
  0% {
     opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
`;

export const NoDataIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MobileDivider = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background: #c5d0de;
`;
export const VerticalDevider = styled.div<{ color: string; height: string }>`
  display: flex;
  width: 1px;
  height: ${props => props.height};
  margin-left: 10px;
  background-color: ${props => props.color};
`;

export const buttonOnHoverColor = '#edf7ff';

export const globalColors = {
  regular: {
    text: {
      primary: '#354052',
      grey: '#7F8FA4',
      blue: '#2EA1F8',
      red: '#FF4081',
      white: '#ffffff',
    },
    background: {
      grey: '#f2f5fc',
      blue: '#2EA1F8',
      white: '#ffffff',

      red: '#F10C00',
      yellow: '#FFAB2B',
      green: '#1BB934',
      greyCardBackround: '#EFF3F6',
      greyDevider: '#C5D0DE',
      buttonBorder: '#C5D0DE',
      cardBorder: '#E6E9ED',
      disabled: '#C5D0DE',
      datePickerArrow: '#354052',
    },
    buttons: {
      disableBorder: '#E6E9ED',
      regularBorder: '#354052',
      selectedBorder: '#2EA1F8',
      whiteBorder: '#ffffff',
      disableText: '#E6E9ED',
      regularText: '#354052',
      selectedText: '#2EA1F8',
      whiteText: '#ffffff',
    },
    arrows: {
      green: '#1BB934',
      red: '#FF4081',
      gray: '#C5D0DE',
    },
  },
};
export const StyledTooltip = withStyles({
  tooltip: {
    color: '#354052',
    backgroundColor: 'white',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  arrow: {
    color: 'white',
  },
})(Tooltip);
export const CardBorder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #c5d0de;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
export const backgroundColors = {
  bg: '#4158d0',
  bgImg: 'linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%)',
};
