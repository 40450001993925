import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Store } from 'store/storeTypes';
import PbiFilterSystems from './PbiFilterSystems';
import {
  setFilterSystemsState,
  setNodeFoldedState,
  resetFilterSystemsState,
  openFilterPopUpMenu,
  toggleFilterRowSystemsSelection,
} from 'actions/reportFilterActions';
import { toggleMachineFilterMenu } from 'actions/userUiActions';
import { isMachineFilterOpenSelector } from 'reducers/ui';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { filterStateBySystemGroup } from 'reducers/reports/filterStateReducer';
import {
  activeReportSelector,
  filterDataBySystemGroupSelector,
} from 'reducers/reports/filterDataReducer';
import { pbiReportHeightSelector, screenWidthSelector } from 'reducers/appUserReducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (state: Store) => ({
  filterData: filterDataBySystemGroupSelector(state),
  filterState: filterStateBySystemGroup(state),
  selectedSystemGroup: systemGroupSelector(state),
  isMachineFilterOpen: isMachineFilterOpenSelector(state),
  screenWidth: screenWidthSelector(state),
  pbiReportHeight: pbiReportHeightSelector(state),
  activeReport: activeReportSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFilterSystemsState,
      setNodeFoldedState,
      resetFilterSystemsState,
      openFilterPopUpMenu,
      toggleFilterRowSystemsSelection,
      toggleMachineFilterMenu,
    },
    dispatch
  );

export type PbiFilterSystemsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PbiFilterSystems));
