import { ConfigNamesEnum } from 'store/storeTypes';
import { config } from '../config';

const configAuto: config = {
  AUTH_CONFIG_STR: {
    domain: 'kornit-konnect.eu.auth0.com',
    clientID: 'kGxPhJqtw0IVMx8pgb24dzsgRn2Roy0K',
    redirectUri: 'https://autoadmin1portalapp.z6.web.core.windows.net',
    audience: 'https://konnect-auto.kornitkonnect.com',
    returnTo: 'https://autoadmin1portalapp.z6.web.core.windows.net',
  },
  API_ROUTER_HOST: 'https://tf-konnect-auto-admin-1-admin-handlers.azurewebsites.net/api/portalUrl',
  AUTH0_CLAIMS_NAMESPACE: 'https://konnect.kornit.com/',
  SELF_HOST: 'https://autoadmin1portalapp.z6.web.core.windows.net',
  USER_LOGOUT_TIMEOUT: 14400000,
  USER_AGREEMENT_VERSION: 1.5,
  WEEK_START: 1,
  FILTER_HIDE: false,
  SHOW_PBI_REPORT_PAGES_PANE: false,
  SHOW_PBI_REPORT_FILTERS_PANE: false,
  REPORTS_REFRESH_TIMEOUT_SECONDS: 300,
  MAX_LOAD_ATTEMPTS: 5,
  SPLIT_WEEK_BY_END_OF_YEAR: true,
  ENV_NAME: ConfigNamesEnum.Auto,
  PLATFORM: 'web',
  SHOW_VERSION_VERSION_LABEL: true,
  GENERATE_SOURCEMAP: false,
  LOGGER_INSTRUMENTAION_KEY: '7454a837-61fb-4a57-b407-5f1b43b7f130',
  REFRESH_API_INTERVAL: 600000,
  UNSUBSCRIBE_END_POINT:
    'https://konnect-stg-edge-portal-api-appservice.kornitkonnect.com/api/email-reports-scheduler/unsubscribe',
  SERVICE_TICKET_END_POINT: 'https://SandboxKornit.formtitan.com/CaseFormJuly',
  BLOB_URL: 'https://devconnectportaldata.blob.core.windows.net',
  API_URL: 'https://tf-konnect-auto-edge-1-portal-app.azurewebsites.net',
  RECAPTCHA_KEY: '6LdzndIjAAAAANQ7LVjhg7siwpSWmVM3A9YkXai8',
};

export default configAuto;
