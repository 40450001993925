import React from 'react';
import styled from 'styled-components/macro';

import DateFnsUtils from '@date-io/date-fns';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import { MuiThemeProvider } from '@material-ui/core';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import appTheme from '../../theme';

const Picker = styled(TimePicker)`
  width: 100%;

  input,
  label {
    font-family: Rubik, sans-serif;
  }

  input {
    font-size: 14px;
    color: #354052;
  }
`;

const theme = createMuiTheme({
  ...appTheme,
  overrides: {
    MuiInput: {
      underline: {
        height: '29px',
        '&:before': { borderBottomColor: 'rgb(197, 208, 222)' },
        '&:after': { borderBottomColor: 'rgb(32, 112, 173)' },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#7f8fa4',
        '&$focused': { color: '#7f8fa4' },
      },
    },
    MuiToolbar: {
      root: {
        // width: '15px',
        color: 'red',
        background: 'red',
      },
      regular: {
        color: 'red',
      },
    },
    MuiTypography: {
      h2: {
        color: 'red',
      },
    },
  },
});

interface TimePickerProps {
  id: string;
  label: string;
  error?: boolean;
  onChange?: (date: Date) => void;
  value?: string | number | Date | undefined;
  disabled: boolean;
}

const FilterTimePicker = React.memo((props: TimePickerProps) => {
  const { id, label, onChange, value, disabled } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Picker
          variant="inline"
          autoOk
          id={id}
          label={label}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
});

export default FilterTimePicker;
