import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import React, { Fragment } from 'react';
import styled from 'styled-components/macro';

import FilterArrLeft from './FilterArrLeft';

const ArrLeftIcon = styled(FilterArrLeft)`
  width: 22px;
  cursor: pointer;
  &.right {
    transform: rotate(180deg);
  }
  &:not(:last-child) {
    margin-right: 5px;
  }
  &.disabled {
    cursor: initial;
    path {
      stroke: #c5d0de;
    }
  }
  @media (max-width: 750px) {
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 750px) {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid #c5d0de;
    border-bottom: 1px solid #c5d0de;
  }
`;

const Desktop = styled.div`
  display: flex;
  min-width: 50px;
  @media (max-width: 750px) {
    display: none;
  }
`;

const DateTitle = styled.div`
  font-size: 16px;
  color: #354052;
`;

interface FilterArrowsProps {
  handleLeft: () => void;
  handleRight: () => void;
  disableLeft?: boolean;
  disableRight?: boolean;
  dateTitle?: string;
}

const FilterArrows = ({
  handleLeft,
  handleRight,
  disableLeft,
  disableRight,
  dateTitle,
}: FilterArrowsProps) => {
  const onHandleLeft = () => {
    if (!disableLeft) {
      handleLeft();
      KonnectAnalytics.decrementDateInDatePicker();
    }
  };
  const onHandleRight = () => {
    if (!disableRight) {
      handleRight();
      KonnectAnalytics.incrementDateInDatePicker();
    }
  };

  return (
    <Fragment>
      <Mobile>
        <ArrLeftIcon onClick={onHandleLeft} className={disableLeft ? 'disabled' : ''} />
        <DateTitle>{dateTitle}</DateTitle>
        <ArrLeftIcon
          onClick={onHandleRight}
          className={`right ${disableRight ? 'disabled' : ''}`}
        />
      </Mobile>
      <Desktop>
        <ArrLeftIcon onClick={onHandleLeft} className={disableLeft ? 'disabled' : ''} />
        <ArrLeftIcon
          onClick={onHandleRight}
          className={`right ${disableRight ? 'disabled' : ''}`}
        />
      </Desktop>
    </Fragment>
  );
};

export default FilterArrows;
