import React from 'react';
import { MobileDatePickerProps, Wrapper } from './MobileDatePicker';
import PbiFilterWeeks from '../PbiFilter/PbiFilterWeeksContainer';

const MobileWeekPicker = (props: MobileDatePickerProps) => {
  const { startDate, endDate, selectedDay, setActiveDate, closeCalendarModal } = props;

  return (
    <Wrapper data-id={'OuterWrapper'}>
      <PbiFilterWeeks closeCalendarModal={closeCalendarModal} isMobile={true} />
    </Wrapper>
  );
};

export default MobileWeekPicker;
