import { toggleMachineFilterMenu } from 'actions/userUiActions';
import { createSelector } from 'reselect';
import { Store } from 'store/storeTypes';
import { createReducer } from 'typesafe-actions';

export default createReducer<any, any>({ isOpen: false }).handleAction(
  toggleMachineFilterMenu,
  (state, action) => {
    return { ...state, isOpen: !state.isOpen };
  }
);
const uiSelector = (state: Store) => {
  return state['ui'];
};
export const isMachineFilterOpenSelector = createSelector([uiSelector], ui => {
  return ui['machineFilter'].isOpen;
});
