import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { UserAvatarUploadProps } from './UserAvatarUploadContainer';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const HiddenFileInput: StyledComponentWithAnyType = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const HiddenInputLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

const UserAvatarUpload = React.memo((props: UserAvatarUploadProps) => {
  const { t } = useTranslation();
  const { children, changeFormAvatar, enqueueNotification } = props;

  const onFileChange = (e: React.BaseSyntheticEvent) => {
    const files = Array.from(e.target.files as File[]);

    if (!files[0]) return;

    if (!files[0].name.match(/\.(jpe?g|png)$/) || files[0].size > 102400) {
      enqueueNotification(t('userAvatarUpload.warning'), {
        variant: 'warning',
      });
      return;
    }

    changeFormAvatar(files[0]);
  };

  return (
    <HiddenInputLabel htmlFor="avatar-input">
      {children}
      <HiddenFileInput id="avatar-input" onChange={onFileChange} />
    </HiddenInputLabel>
  );
});

export default UserAvatarUpload;
