import React from 'react';
import {
  DotProps,
  LineChart,
  Tooltip,
  TooltipProps,
  LabelList,
  Line,
  XAxis,
  ResponsiveContainer,
  Label,
} from 'recharts';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { globalColors } from 'globalStyle';
import { formatFloatToPercentage } from 'helpers/conversions';

import { TooltipText } from './SpeedChart';
import { Spinner } from '../Styles';
import { TimeFrame } from 'models/ReportData';
import { ChartRecord, UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import { getGraphMarginAvailability } from 'helpers/chartUtils';
import AxixTiksForCustomTimeframe from './AxixTiksForCustomTimeframe';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { normalizeApolloAllChartsRecords } from 'helpers/overviewUtils';

const TooltipContainer = styled.div`
  display: grid;
  grid-template-rows: 2fr 1fr 3px 2fr 1fr;
  border: 1px solid #c5d0de;
  background: white;
  width: auto;
  height: 72px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #00000022;
  padding: 8px;
`;

const TooltipValue = styled.div`
  font-size: 12px;
`;
const AvgAvailability = styled.div`
  font-size: 12px;
`;
const TooltipTitle = styled.div`
  font-size: 8px;
`;
const TooltipDivider = styled.div`
  border-bottom: 1px solid #c5d0de;
  width: 100%;
`;

export const CustomizeAvailabilityChartdDot = (props: DotProps & { value?: number }) => {
  const { value, cx: X, cy: Y } = props;
  if (!value) {
    return <svg viewBox="0 0 1024 1024"></svg>;
  }
  const cx = X || 0;
  const cy = Y || 0;
  return (
    <svg x={cx - 3} y={cy - 3} width={6} height={6} viewBox="0 0 1024 1024">
      <circle cx="512" cy="512" r="512" fill="#208CD7"></circle>
    </svg>
  );
};
type AvailabilityChartLabelProps = {
  x?: number;
  offset?: number;
  value?: string | undefined;
};
const AvailabilityChartLabel = (props: AvailabilityChartLabelProps) => {
  const { x = 0, offset, value } = props;

  return (
    <g>
      <text
        fontSize="18px"
        textAnchor="middle"
        fontWeight="500"
        fill={globalColors.regular.text.primary}
        x={x + 5}
        y={20}
        offset={offset}>
        {value !== undefined && formatFloatToPercentage(parseFloat(value as string) || 0, 0) + '%'}
      </text>
    </g>
  );
};

const CustomTooltip = (
  props: TooltipProps & { tooltipText: TooltipText } & {
    selectedSystemGroup: UserSystemGroupStrings;
  }
) => {
  const { t } = useTranslation();
  const { active, payload: propPayload, tooltipText, selectedSystemGroup } = props;
  if (active && propPayload && propPayload[0]) {
    const payload = propPayload[0].payload;
    const isFuturePeriod = payload?.isFuturePeriod;
    const isNotReported = payload?.isNotReported;
    const totalValue = isFuturePeriod
      ? ''
      : isNotReported
      ? t('tooltip.overview.availabilityChartNotReporting')
      : formatFloatToPercentage(payload.total) + '%';

    return (
      <TooltipContainer data-automationid="Overview-Avalabilty-by-hour-graph-tooltip">
        <TooltipValue data-automationid="Overview-Avalabilty-by-hour-graph-tooltip-upper-value">
          {totalValue}
        </TooltipValue>
        <TooltipTitle>{tooltipText.total}</TooltipTitle>
        <TooltipDivider></TooltipDivider>
        <TooltipValue data-automationid="Overview-Avalabilty-by-hour-graph-tooltip-bottom-value">
          {payload.totalAvg !== undefined
            ? formatFloatToPercentage(payload.totalAvg) + '%'
            : 'Not Reported'}
        </TooltipValue>
        <TooltipTitle>{tooltipText.totalAvg}</TooltipTitle>
      </TooltipContainer>
    );
  }

  return null;
};
const CustomizedLabelGrid = props => {
  //*this greed is needed only in case of week view
  const { timeframe, viewBox, offsetRight, offsetLeft, chartHeight } = props;

  const { width } = viewBox;
  const yOffset = 50;
  const heigth = chartHeight - yOffset;
  const LINES_COUNT = 5;
  const step = heigth / LINES_COUNT;
  const fullWidth = width + offsetLeft + offsetRight;

  return (
    <svg>
      <line
        x1="10"
        y1={heigth}
        x2={fullWidth}
        y2={heigth}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1="10"
        y1={heigth - step}
        x2={fullWidth}
        y2={heigth - step}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1="10"
        y1={heigth - step * 2}
        x2={fullWidth}
        y2={heigth - step * 2}
        stroke={globalColors.regular.background.greyDevider}
      />

      <line
        x1="10"
        y1={heigth - step * 3}
        x2={fullWidth}
        y2={heigth - step * 3}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1="10"
        y1={heigth - step * 4}
        x2={fullWidth}
        y2={heigth - step * 4}
        stroke={globalColors.regular.background.greyDevider}
      />
    </svg>
  );
};
type DesktopAvailabilityChartProps = {
  data: ChartRecord[];
  tooltipText: TooltipText;
  isWeek: boolean;
  timeframe: TimeFrame;
  screenWidth: number;
  selectedSystemGroup: UserSystemGroupStrings;
  customTimeframeProperties?: CustomTimeFrameProperties;
  isMachineFilterOpen: boolean;
  maxKeepAlive: Date | undefined;
};
const DesktopAvailabilityChart = (props: DesktopAvailabilityChartProps) => {
  const {
    data,
    tooltipText,
    isWeek,
    timeframe,
    screenWidth,
    selectedSystemGroup,
    customTimeframeProperties,
    isMachineFilterOpen,
    maxKeepAlive,
  } = props;

  const CHART_HEIGHT = 200;

  if (!data.length || timeframe === 'year' || timeframe === 'month' || timeframe === 'quarter') {
    return Spinner(60);
  } else {
    const { marginRight, marginLeft } = getGraphMarginAvailability(
      screenWidth,
      timeframe,
      data,
      isMachineFilterOpen
    );
    const filteredRecords = normalizeApolloAllChartsRecords(
      data,
      maxKeepAlive,
      selectedSystemGroup
    );
    return (
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <LineChart
          data={filteredRecords}
          margin={{
            right: marginRight,
            left: marginLeft,
            top: 35,
          }}>
          <Tooltip
            content={
              <CustomTooltip tooltipText={tooltipText} selectedSystemGroup={selectedSystemGroup} />
            }
          />

          <XAxis
            height={50}
            interval={0}
            axisLine={false}
            tickLine={{ stroke: '#C5D0DE' }}
            padding={{ left: 40, right: 40 }}
            tick={
              <AxixTiksForCustomTimeframe
                chart="availability"
                data={data}
                marginLeft={marginLeft}
                marginRight={marginRight}
                customTimeframeProperties={customTimeframeProperties}
                timeframe={timeframe}
              />
            }>
            <Label
              content={
                <CustomizedLabelGrid
                  timeframe={timeframe}
                  offsetLeft={marginLeft}
                  offsetRight={marginRight}
                  chartHeight={CHART_HEIGHT}
                />
              }
            />
          </XAxis>
          <Line isAnimationActive={false} dataKey="totalAvg" stroke="#E6E9ED" />
          <Line
            isAnimationActive={false}
            dataKey="total"
            stroke={globalColors.regular.background.blue}
            strokeWidth="1.5px"
            dot={<CustomizeAvailabilityChartdDot></CustomizeAvailabilityChartdDot>}>
            <LabelList
              content={<AvailabilityChartLabel></AvailabilityChartLabel>}
              dataKey="total"
              position="bottom"
            />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    );
  }
};
export default DesktopAvailabilityChart;
