import { Button } from '@material-ui/core';
import Select from 'components/_common/Select';
import { globalColors } from 'globalStyle';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import React from 'react';
import styled from 'styled-components';
import { HeaderButton } from './DesktopPbiFilterTimeFrame';
import { TimeFrameSelectorViews, UserSystemGroup } from 'store/storeTypes';
import { TimeFrameEnum } from 'models/ReportData';

const TimeframeSelect = styled(Select)`
  width: 135px;
  height: 31px;
  .MuiInputBase-root {
    height: 100%;
  }
  .MuiSelect-root {
    padding: 0 10px;
    font-size: 12px;
  }
  .MuiSelect-select {
    text-transform: capitalize;
    &:focus {
      background-color: transparent;
    }
  }
  .MuiFilledInput-root {
    border-radius: 4px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const TimeFrameSelector = props => {
  const {
    disabled,
    items,
    values,
    onChange,
    timeframe,
    view,
    startDate,
    setTimeframe,
    setIsCustomTimeFrameOpen,
    selectedSystemGroup,
  } = props;

  //*to hide/unhide custom timeframe when needed;
  const IS_CUSTOM_TIMEFRAME_BUTTON_VISIBLE = true;
  const filteredValues = values.filter(value => {
    if (!IS_CUSTOM_TIMEFRAME_BUTTON_VISIBLE) {
      return value !== 'custom';
    }
    return value;
  });
  const filteredItems = items.filter(item => {
    if (!IS_CUSTOM_TIMEFRAME_BUTTON_VISIBLE) {
      return item !== 'Custom';
    }
    return item;
  });

  const onClickHandler = nextTimeframe => {
    if (nextTimeframe === 'custom') {
      setIsCustomTimeFrameOpen(true);
      KonnectAnalytics.customClick({ selectedSystemGroup });
    } else {
      setIsCustomTimeFrameOpen(false);
      setTimeframe(nextTimeframe, startDate);
    }
  };
  if (view === TimeFrameSelectorViews.DropDown) {
    return (
      <TimeframeSelect
        disabled={disabled}
        data-automationid={`choose_date_type`}
        items={filteredItems}
        values={filteredValues}
        value={timeframe}
        onChange={onChange}
        noEmpty
        noHelper
        formControlVariant="filled"
        selectedSystemGroup={selectedSystemGroup}
      />
    );
  }
  if (view === TimeFrameSelectorViews.Mixed) {
    return (
      <ButtonWrapper>
        <TimeframeSelect
          view="mixed"
          disabled={disabled}
          data-automationid={`choose_date_type`}
          items={filteredItems}
          values={filteredValues}
          value={timeframe}
          onChange={onChange}
          noEmpty
          noHelper
          timeframe={timeframe}
          formControlVariant="filled"
          selectedSystemGroup={selectedSystemGroup}
        />

        {selectedSystemGroup !== UserSystemGroup.DTF && IS_CUSTOM_TIMEFRAME_BUTTON_VISIBLE && (
          <HeaderButton
            variant={'outlined'}
            style={{
              textTransform: 'none',
              border:
                timeframe === TimeFrameEnum.custom
                  ? `1px solid ${globalColors.regular.background.blue}`
                  : `1px solid ${globalColors.regular.background.buttonBorder}`,
            }}
            onClick={() => onClickHandler('custom')}
            disabled={disabled}>
            {'Custom'}
          </HeaderButton>
        )}
      </ButtonWrapper>
    );
  } else {
    return (
      <ButtonWrapper>
        {filteredValues.map(value => {
          const valueName = value[0].toUpperCase() + value.slice(1);
          return (
            <HeaderButton
              key={value}
              variant={'outlined'}
              style={{
                textTransform: 'none',
                border:
                  value === timeframe
                    ? `1px solid ${globalColors.regular.buttons.selectedBorder}`
                    : `1px solid ${globalColors.regular.buttons.regularBorder}`,
              }}
              onClick={() => onClickHandler(value)}
              disabled={disabled}>
              {valueName}
            </HeaderButton>
          );
        })}
      </ButtonWrapper>
    );
  }
};

export default TimeFrameSelector;
