import { Scope } from './Scope';

export interface Scheduler extends Scope {
  reportId?: string;
  subject: string;
  userId?: string;
  emailReportId: number;
  emailReportName: string;
  startSendDate: Date;
  endSendDate?: Date;
  repeat: SchedulerRepeat;
  lastSent?: Date;
  notes: string;
  isActive: boolean;
}

export interface ReportType {
  id: number;
  name: string;
  reportTemplate: string;
}

export interface SchedulerRepeat {
  frequency: Frequency;
  dayOfWeek?: Weekdays[];
  isSendOnSunday?: boolean;
  isSendOnMonday?: boolean;
  isSendOnTuesday?: boolean;
  isSendOnWednesday?: boolean;
  isSendOnThursday?: boolean;
  isSendOnFriday?: boolean;
  isSendOnSaturday?: boolean;
  // sendOnTheFirstDayOf?: boolean;
  // sendOnDayInMonth?: number;
}

export enum Frequency {
  'No Repeat',
  'Daily',
  'Weekly',
  'Monthly',
  'Yearly',
}

export enum Weekdays {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}
