import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Fade, Modal, ClickAwayListener } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as PrevIcon } from '../../assets/icons/DatePickerPrevDateIcon.svg';
import { ReactComponent as NextIcon } from '../../assets/icons/DatePickerNextDateIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/DatePickerCloseIcon.svg';

import {
  addDays,
  addMonths,
  addYears,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDate,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { globalColors } from 'globalStyle';
import { MainReportTypes, ReportTypesNew, TimeFrame, TimeFrameEnum } from 'models/ReportData';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { TimeModidficatorEnum, TimeModificator } from 'store/storeTypes';
import TimeSelector from './TimeSelector';
import {
  ButtonCancel,
  ButtonSubmit,
  ButtonsRow,
  CalendarRow,
  CalendarWrapper,
  CustomTimeFrameDateTimeSelectionRow,
  CustomTimeFrameForm,
  CustomTimeFrameFormWrapper,
  DatePickerWrapper,
  FormLabel,
  MonthButton,
  MonthSelector,
  MonthTimeframeCalendar,
  PickDateButton,
  QuarterButton,
  QuarterTimeframeCalendar,
  RowCell,
  RowCellHighlighted,
  TimeSelectRow,
  ValueWrapper,
  Wrapper,
  YearButton,
  YearSelector,
  YearTimeframeCalendar,
  popUpstyle,
} from './DatePickerPopUpStyles';
import DatePickerPopUpErrorMessage from './DatePickerPopUpErrorMessage';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
export type ErrorMessageType = {
  payload: string;
  type: 'warning' | 'error' | 'info' | '';
};

type DatePickerPopUpPropsType = {
  date: Date;
  isDatePickerOpen: boolean;
  timeframe: TimeFrame;
  prevTimeframe: TimeFrame;
  lastUpdate: Date;
  selectedDate: Date;
  startDate: Date;
  customTimeframeProperties: CustomTimeFrameProperties;
  activeReport: ReportTypesNew;
  setActiveDate: (date: Date) => void;
  setIsCustomTimeFrameOpen: (isOpen: boolean) => void;
  setIsDatePickerOpen: (isOpen: boolean) => void;
  setTimeframe: (
    timeframe: TimeFrame,
    startDate?: Date,
    customTimeFrameProperties?: CustomTimeFrameProperties
  ) => void;
};
const DatePickerPopUp = (props: DatePickerPopUpPropsType) => {
  const {
    date,
    isDatePickerOpen,
    timeframe,
    prevTimeframe,
    lastUpdate,
    selectedDate,
    startDate,
    activeReport,
    customTimeframeProperties,
    setActiveDate,
    setIsDatePickerOpen,
    setIsCustomTimeFrameOpen,
    setTimeframe,
  } = props;
  const [currentDate, setCurrentDate] = useState(new Date(selectedDate));
  const [selectedRange, setSelectedRange] = useState({ start: undefined, end: undefined });

  //*to keep calendar open always on range dedicated to selected date
  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  //*to keep arrows for year and month picker in proper state on first rended
  useEffect(() => {
    setIsPrevMonthDisabled(isSameMonth(currentDate, startDate) || isBefore(currentDate, startDate));
    setIsNextMonthDisabled(
      isSameMonth(currentDate, lastUpdate) || isAfter(currentDate, lastUpdate)
    );
    setIsNextYearDisabled(isSameYear(currentDate, lastUpdate));
    setIsPrevYearDisabled(isSameYear(currentDate, startDate));
  });
  useEffect(() => {
    if (timeframe === 'day') {
      setSelectedRange({
        start: selectedDate,
        end: selectedDate,
      });
    }
    if (timeframe === 'week') {
      const start = startOfWeek(selectedDate, { weekStartsOn: 1 });
      setSelectedRange({
        start,
        end: addDays(start, 6),
      });
    }
  }, [timeframe, selectedDate, customTimeframeProperties]);

  const [isPrevMonthDisabled, setIsPrevMonthDisabled] = useState(
    isSameMonth(currentDate, startDate)
  );
  const [isNextMonthDisabled, setIsNextMonthDisabled] = useState(
    isSameMonth(currentDate, lastUpdate)
  );
  const [isNextYearDisabled, setIsNextYearDisabled] = useState(isSameYear(currentDate, lastUpdate));
  const [isPrevYearDisabled, setIsPrevYearDisabled] = useState(isSameYear(currentDate, startDate));

  const onNextYearClickHandler = () => {
    if (isNextYearDisabled) return;
    if (isSameYear(currentDate, lastUpdate) && isSameMonth(currentDate, lastUpdate)) {
      setIsNextYearDisabled(true);
    } else {
      setIsPrevYearDisabled(false);
      setIsPrevMonthDisabled(false);

      const nextDate = addYears(currentDate, 1);
      if (isAfter(nextDate, lastUpdate)) {
        setCurrentDate(lastUpdate);
        setIsNextYearDisabled(true);
        setIsNextMonthDisabled(true);
      } else {
        setCurrentDate(nextDate);
        if (isSameYear(nextDate, lastUpdate)) {
          setIsNextYearDisabled(true);
        }
      }
    }
  };
  const onPrevYearClickHandler = () => {
    if (isPrevYearDisabled) return;
    if (isSameYear(currentDate, startDate) && isSameMonth(currentDate, startDate)) {
      setIsPrevYearDisabled(true);
    } else {
      setIsNextYearDisabled(false);
      setIsNextMonthDisabled(false);
      const nextDate = subYears(currentDate, 1);
      if (isBefore(nextDate, startDate)) {
        setCurrentDate(startDate);
        setIsPrevYearDisabled(true);
        setIsPrevMonthDisabled(true);
      } else {
        setCurrentDate(nextDate);
        if (isSameYear(nextDate, startDate)) {
          setIsPrevYearDisabled(true);
        }
      }
    }
  };
  const onPrevMonthClickHandler = () => {
    if (isPrevMonthDisabled) return;
    if (isSameMonth(currentDate, startDate)) {
      setIsPrevMonthDisabled(true);
    } else {
      const nextDate = subMonths(currentDate, 1);
      setCurrentDate(nextDate);
      setIsNextMonthDisabled(false);

      if (isSameMonth(nextDate, startDate)) {
        setIsPrevMonthDisabled(true);
        setIsPrevYearDisabled(true);
      }
      if (!isSameYear(nextDate, lastUpdate)) {
        setIsNextYearDisabled(false);
      }
    }
  };
  const onNextMonthClickHandler = () => {
    if (isNextMonthDisabled) return;
    if (isSameMonth(currentDate, lastUpdate)) {
      setIsNextMonthDisabled(true);
    } else {
      const nextDate = addMonths(currentDate, 1);
      setCurrentDate(nextDate);
      setIsPrevMonthDisabled(false);
      if (isSameMonth(nextDate, lastUpdate)) {
        setIsNextYearDisabled(true);
        setIsNextMonthDisabled(true);
      }
      if (isSameYear(nextDate, lastUpdate)) {
        setIsNextYearDisabled(true);
      }
    }
  };

  const onDateClickHandler = date => {
    if (timeframe !== 'year' && isInBetweenStartDateAndLastUpdate(date)) {
      return;
    } else {
      //*if timeframe is custom we are not supposed to set active date after date click, only after user select whole range and click on submit.
      //*if timeframe is not custom we direct set activeDate and close Picker;
      if (timeframe !== 'custom') {
        setActiveDate(date);
        setIsDatePickerOpen(false);
      } else {
        if (isFromButtonClicked) {
          let nextStartDate = date;
          if (endCustomDate && isBefore(endCustomDate, nextStartDate)) {
            {
              nextStartDate = new Date(endCustomDate);
            }
          }

          setStarCustomtDate(nextStartDate);
          setSelectedRange({
            start: nextStartDate,
            end: selectedRange.end,
          });
          KonnectAnalytics.changedDateinCustomTimeFrame({
            startDate: nextStartDate.toString(),
            endDate: selectedRange.end.toString(),
            customStartHour: startCustomHour,
            customEndHour: endCustomHour,
          });
        }
        if (isToButtonClicked) {
          let nextEndDate = date;
          if (startCustomDate && isAfter(startCustomDate, nextEndDate)) {
            nextEndDate = new Date(startCustomDate);
          }
          setSelectedRange({
            start: selectedRange.start,
            end: nextEndDate,
          });
          setEndCustomtDate(nextEndDate);
          KonnectAnalytics.changedDateinCustomTimeFrame({
            startDate: selectedRange.start.toString(),
            endDate: nextEndDate.toString(),
            customStartHour: startCustomHour,
            customEndHour: endCustomHour,
          });
        }
      }
    }
  };
  const weeks = eachWeekOfInterval(
    {
      start: startOfMonth(currentDate),
      end: endOfMonth(currentDate),
    },
    { weekStartsOn: 1 }
  );
  const isInBetweenStartDateAndLastUpdate = (date: Date) => {
    if (timeframe === 'quarter' || timeframe === 'month') {
      return (
        isAfter(date, lastUpdate) || (isBefore(date, startDate) && !isSameMonth(date, startDate))
      );
    } else {
      return isAfter(date, lastUpdate) || isBefore(date, startDate);
    }
  };
  const getPopUpHeight = timeframe => {
    switch (timeframe) {
      case 'custom':
        return 460;
      case 'year':
        return 130;
      case 'quarter':
        return 160;
      case 'month':
        return 200;

      default:
        return 365;
    }
  };
  const popUpHeight = getPopUpHeight(timeframe);

  //*this is block for custom timeframe selection only
  const [startCustomDate, setStarCustomtDate] = useState(
    customTimeframeProperties?.customTimeFrameStartDate
  );
  const [endCustomDate, setEndCustomtDate] = useState(
    customTimeframeProperties?.customTimeFrameEndDate
  );

  useEffect(() => {
    const getLastDateForPeriod = (lastUpdate, startDate, timeframe) => {
      if (timeframe === TimeFrameEnum.week) {
        const lastDayOfWeek = endOfWeek(startDate, { weekStartsOn: 1 });
        const result = isAfter(lastUpdate, lastDayOfWeek) ? lastDayOfWeek : lastUpdate;
        return result;
      } else {
        return startDate;
      }
    };

    if (timeframe === 'custom') {
      const start = currentDate;
      const end = getLastDateForPeriod(lastUpdate, start, prevTimeframe);
      if (!customTimeframeProperties?.customTimeFrameStartDate) {
        setStarCustomtDate(currentDate);
        setSelectedRange({ start: start, end: end });
      } else {
        setStarCustomtDate(customTimeframeProperties?.customTimeFrameStartDate);
        setSelectedRange({
          start: customTimeframeProperties?.customTimeFrameStartDate,
          // end: selectedRange.end,
          end: customTimeframeProperties.customTimeFrameEndDate,
        });
      }
      if (!customTimeframeProperties?.customTimeFrameEndDate) {
        setEndCustomtDate(end);
        setSelectedRange({ start: start, end: end });
      } else {
        setEndCustomtDate(customTimeframeProperties?.customTimeFrameEndDate);
        setSelectedRange({
          // start: selectedRange.start,
          start: customTimeframeProperties.customTimeFrameStartDate,
          end: customTimeframeProperties?.customTimeFrameEndDate,
        });
      }
    }
  }, [
    customTimeframeProperties?.customTimeFrameEndDate,
    customTimeframeProperties?.customTimeFrameStartDate,
  ]);

  const [isFromButtonClicked, setIsFromButtonClicked] = useState(true);
  const [isToButtonClicked, setIsToButtonClicked] = useState(false);
  const [timeIntervalErrorMessage, setTimeIntervalErrorMessage] = useState<ErrorMessageType>({
    payload: '',
    type: '',
  });
  const [isSubmitDisable, setIsSubmitDisabled] = useState(true);
  //*customTimeSelection is ability to select exact hour in custom timeframe.
  const [isCustomTimeSelectionDisabled, setIsCustomTimeSelectionDisabled] = useState(false);
  const [startCustomHour, setStartCustomHour] = useState(0);
  const [endCustomHour, setEndCustomHour] = useState(11);
  const [startTimeModificator, setStartTimeModificator] = useState<TimeModificator>(
    TimeModidficatorEnum.AM
  );
  const [endTimeModificator, setEndTimeModificator] = useState<TimeModificator>(
    TimeModidficatorEnum.PM
  );

  //*this useEffec uses only for disabling/enabling 'isCustomTimeSelectionDisabled'
  useEffect(() => {
    if (!startCustomDate || !endCustomDate) {
      setIsCustomTimeSelectionDisabled(false);
      setTimeIntervalErrorMessage({ payload: '', type: '' });
    } else {
      let start = startCustomDate || new Date();
      let end = endCustomDate || new Date();
      if (isAfter(start, end)) {
        end = start;
      }
      const interval = eachDayOfInterval({
        start: start,
        end: end,
      }).length;
      if (interval > 182) {
        //*if user selected more than half a year we need to1) disbale time selection, time modificator selectio, 2)set time and modificators to default state 3)and display worning message

        setIsCustomTimeSelectionDisabled(true);
        setStartCustomHour(0);
        setEndCustomHour(11);
        setStartTimeModificator('AM');
        setEndTimeModificator('PM');
        setTimeIntervalErrorMessage({
          payload:
            'Date range of more than 6 months does not support hour range selection. Full days will be considered',
          type: 'warning',
        });
        setIsCustomTimeSelectionDisabled(true);
      } else {
        setIsCustomTimeSelectionDisabled(false);
        setTimeIntervalErrorMessage({ payload: '', type: '' });
        setIsCustomTimeSelectionDisabled(false);
      }
    }
  }, [startCustomDate, endCustomDate, activeReport]);

  useEffect(() => {
    //when Sumbit button is disabled -3 cases;1 when start and end date is not setted 2 if date range more than 365 days. 3 if isShiftmod selected and date range more than 2 days.
    if (!startCustomDate || !endCustomDate) {
      setIsSubmitDisabled(true);
    } else if (
      startCustomDate &&
      endCustomDate &&
      eachDayOfInterval({ start: startCustomDate, end: endCustomDate }).length > 365
    ) {
      setIsSubmitDisabled(true);
      setTimeIntervalErrorMessage({
        payload: 'Date range of more than 12 months is not supported',
        type: 'error',
      });
    } else {
      setIsSubmitDisabled(false);
    }
  }, [startCustomDate, endCustomDate, timeframe]);

  const onEndTimeModificatorClickHandler = () => {
    if (!isCustomTimeSelectionDisabled) {
      setEndTimeModificator(endTimeModificator === 'AM' ? 'PM' : 'AM');
    }
  };
  const onStartTimeModificatorClickHandler = () => {
    if (!isCustomTimeSelectionDisabled) {
      setStartTimeModificator(startTimeModificator === 'AM' ? 'PM' : 'AM');
    }
  };
  const onFromButtonClickHandler = () => {
    setIsToButtonClicked(false);
    setIsFromButtonClicked(true);
  };
  const onToButtonClickHandler = () => {
    setIsFromButtonClicked(false);
    setIsToButtonClicked(true);
  };
  const onFromClearIconClickHandler = () => {
    setIsToButtonClicked(false);
    setIsFromButtonClicked(true);
    setStarCustomtDate(undefined);
    setSelectedRange({
      start: undefined,
      end: selectedRange.end,
    });
  };
  const onToClearIconClickHandler = () => {
    setIsFromButtonClicked(false);
    setIsToButtonClicked(true);
    setEndCustomtDate(undefined);
    setSelectedRange({
      start: selectedRange.start,
      end: undefined,
    });
  };
  const onSubmitClickHandler = () => {
    setTimeframe('custom', startDate, {
      customTimeFrameStartDate: startCustomDate,
      customTimeFrameStartHour: startCustomHour,
      customTimeFrameEndDate: endCustomDate,
      customTimeFrameEndHour: endCustomHour,
      customEndTimeModificator: endTimeModificator,
      customStartTimeModificator: startTimeModificator,
    });
    setTimeIntervalErrorMessage({ payload: '', type: '' });
    setIsDatePickerOpen(false);
    setIsCustomTimeFrameOpen && setIsCustomTimeFrameOpen(false);
    KonnectAnalytics.submitCustomClick({
      startDate: startCustomDate.toString(),
      endDate: endCustomDate.toString(),
      customStartHour: startCustomHour,
      customEndHour: endCustomHour,
    });
  };
  const onStartHourChange = hour => {
    setStartCustomHour(hour);
    KonnectAnalytics.changedDateinCustomTimeFrame({
      startDate: selectedRange.start.toString(),
      endDate: selectedRange.end.toString(),
      customStartHour: hour,
      customEndHour: endCustomHour,
    });
  };
  const onEndHourChange = hour => {
    setEndCustomHour(hour);
    KonnectAnalytics.changedDateinCustomTimeFrame({
      startDate: selectedRange.start.toString(),
      endDate: selectedRange.end.toString(),
      customStartHour: startCustomHour,
      customEndHour: hour,
    });
  };

  useEffect(() => {
    //if dates are same -we check selected time and if From time greater than To time - need to disable submit and set Error message.
    //else remove error and ublock submit.
    const startHour24hFormat =
      startTimeModificator === 'AM' ? startCustomHour : startCustomHour + 12;
    const endHour24hFormat = endTimeModificator === 'AM' ? endCustomHour : endCustomHour + 12;
    const isFromTimeBiggerThanTo = startHour24hFormat > endHour24hFormat;
    if (
      startCustomDate &&
      endCustomDate &&
      isSameDay(startCustomDate, endCustomDate) &&
      isFromTimeBiggerThanTo
    ) {
      setIsSubmitDisabled(true);
      setTimeIntervalErrorMessage({
        payload: '"From" time value should not be greater than "To" time value if dates are same',
        type: 'warning',
      });
    } else if (
      startCustomDate &&
      endCustomDate &&
      isSameDay(startCustomDate, endCustomDate) &&
      !isFromTimeBiggerThanTo
    ) {
      setTimeIntervalErrorMessage({ payload: '', type: '' });
      setIsSubmitDisabled(false);
    }
  }, [
    startTimeModificator,
    endTimeModificator,
    startCustomDate,
    endCustomDate,
    startCustomHour,
    endCustomHour,
  ]);
  return (
    <Modal
      open={isDatePickerOpen}
      onClose={() => {}}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        invisible: true,
      }}
      BackdropComponent={Backdrop}>
      <Fade in={isDatePickerOpen}>
        <ClickAwayListener
          onClickAway={event => {
            setIsDatePickerOpen(false);
            setIsCustomTimeFrameOpen && setIsCustomTimeFrameOpen(false);
          }}>
          <Box id="transition-modal-title">
            <Wrapper style={popUpstyle} data-automationid="date-picker-pop-up">
              <DatePickerWrapper height={popUpHeight} width={timeframe === 'quarter' ? 390 : 347}>
                {timeframe !== 'year' && (
                  <YearSelector>
                    <IconButton onClick={onPrevYearClickHandler}>
                      <PrevIcon
                        fill={
                          isPrevYearDisabled
                            ? globalColors.regular.background.disabled
                            : globalColors.regular.background.datePickerArrow
                        }
                      />
                    </IconButton>
                    {getYear(currentDate)}
                    <IconButton onClick={onNextYearClickHandler}>
                      <NextIcon
                        fill={
                          isNextYearDisabled
                            ? globalColors.regular.background.disabled
                            : globalColors.regular.background.datePickerArrow
                        }
                      />
                    </IconButton>
                  </YearSelector>
                )}
                {(timeframe === 'day' || timeframe === 'week' || timeframe === 'custom') && (
                  <>
                    <MonthSelector>
                      <IconButton onClick={onPrevMonthClickHandler}>
                        <PrevIcon
                          fill={
                            isPrevMonthDisabled
                              ? globalColors.regular.background.disabled
                              : globalColors.regular.background.datePickerArrow
                          }
                        />
                      </IconButton>
                      {format(currentDate, 'MMMM')}
                      <IconButton onClick={onNextMonthClickHandler}>
                        <NextIcon
                          fill={
                            isNextMonthDisabled
                              ? globalColors.regular.background.disabled
                              : globalColors.regular.background.datePickerArrow
                          }
                        />
                      </IconButton>
                    </MonthSelector>
                    <CalendarWrapper>
                      <CalendarRow>
                        <RowCell>Mon</RowCell>
                        <RowCell>Tue</RowCell>
                        <RowCell>Wed</RowCell>
                        <RowCell>Thu</RowCell>
                        <RowCell>Fri</RowCell>
                        <RowCell>Sat</RowCell>
                        <RowCell>Sun</RowCell>
                      </CalendarRow>
                      {weeks.map((firstWeekDay, index, weeksArray) => {
                        const days = eachDayOfInterval({
                          start: startOfWeek(firstWeekDay, { weekStartsOn: 1 }),
                          end: endOfWeek(firstWeekDay, { weekStartsOn: 1 }),
                        });
                        return (
                          <CalendarRow key={`${index + firstWeekDay.toDateString()}`}>
                            {days.map((day, index) => {
                              const isSelectedRangeValue =
                                isSameDay(selectedRange.start, day) ||
                                isSameDay(selectedRange.end, day);
                              const isOpacity =
                                (!isSelectedRangeValue && isInBetweenStartDateAndLastUpdate(day)) ||
                                !isSameMonth(day, currentDate);

                              let start = selectedRange.start;
                              let end = selectedRange.end;
                              if (timeframe === 'week' && !start && !end) {
                                start = selectedDate;
                                end = addDays(selectedDate, 6);
                              }
                              const isMoreThanStartAndLessTnanEndForWeek =
                                timeframe === 'week' &&
                                isWithinInterval(day, {
                                  start: start || startDate,
                                  end: end || start || lastUpdate,
                                });
                              const isLeftBorder = isSameDay(selectedRange?.start, day);
                              const isRightBorder = isSameDay(selectedRange?.end, day);

                              let weekWidth = isSelectedRangeValue ? '30px' : '40px';

                              //*custom timeframeHighLight
                              const isFirstAndLastDaysOfSelectionAreSame = isSameDay(
                                startCustomDate,
                                endCustomDate
                              );
                              const isShowHighLightForCustomTimeFrame =
                                timeframe === 'custom' &&
                                startCustomDate &&
                                endCustomDate &&
                                !isFirstAndLastDaysOfSelectionAreSame;
                              const isFirstDayOfSelection = isSameDay(day, startCustomDate);
                              const isLastDayofSelection = isSameDay(day, endCustomDate);

                              const isInSelectionInterval =
                                isAfter(day, startCustomDate) && isBefore(day, endCustomDate);
                              return (
                                <RowCell key={`${index + day.toDateString()}`}>
                                  {isMoreThanStartAndLessTnanEndForWeek && timeframe === 'week' && (
                                    <RowCellHighlighted
                                      style={{
                                        width: weekWidth,
                                        left: isLeftBorder ? '10px' : '0',
                                        right: isRightBorder ? '10px' : '0',
                                      }}></RowCellHighlighted>
                                  )}

                                  {isShowHighLightForCustomTimeFrame && (
                                    <RowCellHighlighted
                                      style={{
                                        background:
                                          isInSelectionInterval ||
                                          isLastDayofSelection ||
                                          isFirstDayOfSelection
                                            ? '#d6edff'
                                            : 'transparent',
                                        right: isLastDayofSelection ? '10px' : '0',
                                        left: isFirstDayOfSelection ? '10px' : '0',
                                        width:
                                          isLastDayofSelection || isFirstDayOfSelection
                                            ? '30px'
                                            : '40px',
                                      }}></RowCellHighlighted>
                                  )}
                                  <IconButton
                                    onClick={() => onDateClickHandler(day)}
                                    style={{
                                      width: 9,
                                      height: 9,
                                      fontSize: '12px',
                                      fontWeight: 'bolder',
                                      background:
                                        isSelectedRangeValue &&
                                        globalColors.regular.background.blue,

                                      zIndex: 10,
                                    }}>
                                    <ValueWrapper
                                      style={{
                                        opacity: isOpacity && 0.3,
                                        color: isSelectedRangeValue ? 'white' : 'inherit',
                                      }}>
                                      {getDate(day)}
                                    </ValueWrapper>
                                  </IconButton>
                                </RowCell>
                              );
                            })}
                          </CalendarRow>
                        );
                      })}
                      {timeIntervalErrorMessage.payload && (
                        <DatePickerPopUpErrorMessage error={timeIntervalErrorMessage} />
                      )}
                    </CalendarWrapper>
                  </>
                )}
                {timeframe === 'month' && (
                  <MonthTimeframeCalendar>
                    {eachMonthOfInterval({
                      start: startOfYear(currentDate),
                      end: endOfYear(currentDate),
                    }).map(month => {
                      const isSelectedMonth = isSameMonth(selectedDate, month);
                      return (
                        <MonthButton
                          variant={isSelectedMonth ? 'outlined' : 'text'}
                          style={{
                            textTransform: 'none',
                            border: isSelectedMonth ? '1px solid #2EA1F8' : 'none',
                            color: isSelectedMonth ? globalColors.regular.text.blue : 'inherit',
                            opacity: isInBetweenStartDateAndLastUpdate(month) ? 0.2 : 1,
                          }}
                          onClick={() => onDateClickHandler(month)}>
                          {format(month, 'MMM')}
                        </MonthButton>
                      );
                    })}
                  </MonthTimeframeCalendar>
                )}
                {timeframe === 'quarter' && (
                  <QuarterTimeframeCalendar>
                    {eachQuarterOfInterval({
                      start: startOfYear(currentDate),
                      end: endOfYear(currentDate),
                    }).map(quarter => {
                      const isSelectedQuarter = isSameQuarter(selectedDate, quarter);
                      return (
                        <QuarterButton
                          key={quarter.toDateString()}
                          variant={isSelectedQuarter ? 'outlined' : 'text'}
                          onClick={() => {
                            onDateClickHandler(quarter);
                          }}
                          style={{
                            width: isSelectedQuarter ? '85px' : '70px',
                            textTransform: 'none',
                            border: isSelectedQuarter ? '1px solid #2EA1F8' : 'none',
                            color: isSelectedQuarter ? globalColors.regular.text.blue : 'inherit',
                            opacity: isInBetweenStartDateAndLastUpdate(quarter) ? 0.2 : 1,
                          }}>{`Quarter ${format(quarter, 'q')}`}</QuarterButton>
                      );
                    })}
                  </QuarterTimeframeCalendar>
                )}
                {timeframe === 'year' && (
                  <YearTimeframeCalendar>
                    {eachYearOfInterval({ start: startDate, end: lastUpdate }).map(year => {
                      const isSelectedYear = isSameYear(selectedDate, year);

                      return (
                        <YearButton
                          key={year.toDateString()}
                          variant={isSelectedYear ? 'outlined' : 'text'}
                          onClick={() => {
                            onDateClickHandler(year);
                          }}
                          style={{
                            border: isSelectedYear ? '1px solid #2EA1F8' : 'none',
                            color: isSelectedYear ? globalColors.regular.text.blue : 'inherit',
                          }}>
                          {format(year, 'yyyy')}
                        </YearButton>
                      );
                    })}
                  </YearTimeframeCalendar>
                )}
              </DatePickerWrapper>
              {timeframe === 'custom' && (
                <CustomTimeFrameFormWrapper height={popUpHeight}>
                  <CustomTimeFrameForm>
                    <CustomTimeFrameDateTimeSelectionRow>
                      <FormLabel>From</FormLabel>
                      <PickDateButton
                        style={{
                          textTransform: 'none',
                          border:
                            timeIntervalErrorMessage.type === 'error'
                              ? `1px solid ${globalColors.regular.text.red}`
                              : isFromButtonClicked
                              ? '1px solid #2EA1F8'
                              : 'none',
                        }}
                        onClick={onFromButtonClickHandler}
                        endIcon={
                          startCustomDate && (
                            <IconButton
                              onClick={event => {
                                event.stopPropagation();
                                onFromClearIconClickHandler();
                              }}>
                              <CloseIcon
                                fill={
                                  timeIntervalErrorMessage.type === 'error'
                                    ? globalColors.regular.text.red
                                    : '#2EA1F8'
                                }
                              />
                            </IconButton>
                          )
                        }>
                        {startCustomDate
                          ? `${format(startCustomDate, 'd')} ${format(
                              startCustomDate,
                              'MMM'
                            )} ${format(startCustomDate, 'yyyy')}`
                          : 'Select a date'}
                      </PickDateButton>
                      {activeReport !== MainReportTypes.Jobs && (
                        <TimeSelectRow>
                          <TimeSelector
                            activeReport={activeReport}
                            selectorName={'start time'}
                            isHourSelectionDisabled={isCustomTimeSelectionDisabled}
                            value={startCustomHour}
                            timeModificator={startTimeModificator}
                            onHourChange={onStartHourChange}
                            onTimeModificatorClickHandler={onStartTimeModificatorClickHandler}
                          />
                        </TimeSelectRow>
                      )}
                    </CustomTimeFrameDateTimeSelectionRow>
                    <CustomTimeFrameDateTimeSelectionRow>
                      <FormLabel>To</FormLabel>
                      <PickDateButton
                        style={{
                          textTransform: 'none',
                          border:
                            timeIntervalErrorMessage.type === 'error'
                              ? `1px solid ${globalColors.regular.text.red}`
                              : isToButtonClicked
                              ? '1px solid #2EA1F8'
                              : 'none',
                        }}
                        onClick={onToButtonClickHandler}
                        endIcon={
                          endCustomDate && (
                            <IconButton
                              onClick={event => {
                                event.stopPropagation();
                                onToClearIconClickHandler();
                              }}>
                              <CloseIcon
                                fill={
                                  timeIntervalErrorMessage.type === 'error'
                                    ? globalColors.regular.text.red
                                    : '#2EA1F8'
                                }
                              />
                            </IconButton>
                          )
                        }>
                        {endCustomDate
                          ? `${format(endCustomDate, 'd')} ${format(endCustomDate, 'MMM')} ${format(
                              endCustomDate,
                              'yyyy'
                            )}`
                          : 'Select a date'}
                      </PickDateButton>
                      {activeReport !== MainReportTypes.Jobs && (
                        <TimeSelectRow>
                          <TimeSelector
                            activeReport={activeReport}
                            selectorName={'end time'}
                            isHourSelectionDisabled={isCustomTimeSelectionDisabled}
                            value={endCustomHour}
                            timeModificator={endTimeModificator}
                            onHourChange={onEndHourChange}
                            onTimeModificatorClickHandler={onEndTimeModificatorClickHandler}
                          />
                        </TimeSelectRow>
                      )}
                    </CustomTimeFrameDateTimeSelectionRow>
                  </CustomTimeFrameForm>
                  <ButtonsRow>
                    <ButtonCancel
                      onClick={() => {
                        setIsCustomTimeFrameOpen && setIsCustomTimeFrameOpen(false);
                        setIsDatePickerOpen(false);
                      }}
                      style={{ textTransform: 'none' }}
                      variant="outlined">
                      Close
                    </ButtonCancel>
                    <ButtonSubmit
                      onClick={onSubmitClickHandler}
                      style={{ textTransform: 'none' }}
                      variant="contained"
                      disabled={isSubmitDisable}>
                      Submit
                    </ButtonSubmit>
                  </ButtonsRow>
                </CustomTimeFrameFormWrapper>
              )}
            </Wrapper>
          </Box>
        </ClickAwayListener>
      </Fade>
    </Modal>
  );
};

export default DatePickerPopUp;
