import { combineReducers, Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from 'actions';
import { Scheduler } from 'models/Scheduler';
import { Entries, Ids } from 'store/storeTypes';
import { getSchedulerWeekdays } from '../_sharedLogic/schedulerConversions';

const {
  saveSchedulerSuccess,
  loadSchedulersSuccess,
  deleteSchedulerSuccess,
} = actions.schedulerApi;

const schedulerById = createReducer<Entries<Scheduler>, RootAction>({})
  .handleAction(loadSchedulersSuccess, (state, action) => {
    if (!action.payload || !action.payload.length) return state;

    const newState: Entries<Scheduler> = {};

    action.payload.forEach(scheduler => {
      const weekdays = getSchedulerWeekdays(scheduler);
      const repeat = { ...scheduler.repeat, dayOfWeek: weekdays };
      newState[scheduler.reportId!] = {
        ...scheduler,
        emailReportId: scheduler.emailReportId - 1,
        repeat,
      };
    });

    return newState;
  })

  .handleAction(saveSchedulerSuccess, (state, action) => {
    const { payload } = action;

    if (payload && payload.reportId) {
      const { reportId } = payload;
      const weekdays = getSchedulerWeekdays(payload);
      const repeat = { ...payload.repeat, dayOfWeek: weekdays };
      const newReport = {
        ...payload,
        emailReportId: payload.emailReportId - 1,
        repeat,
      };
      return { ...state, [reportId]: newReport };
    }

    return state;
  })
  .handleAction(deleteSchedulerSuccess, (state, action) => {
    const newState = { ...state };
    delete newState[action.payload];

    return newState;
  });

const schedulerAllIds = createReducer<Ids, RootAction>([])
  .handleAction(loadSchedulersSuccess, (state, action) => {
    const schedulers = action.payload;

    if (schedulers) {
      const newState: Ids = [];

      schedulers.forEach(scheduler => newState.push(scheduler.reportId!.toString()));

      return newState;
    }

    return state;
  })
  .handleAction(saveSchedulerSuccess, (state, action) => {
    const scheduler = action.payload;

    if (scheduler && scheduler.reportId && !state.includes(scheduler.reportId.toString())) {
      const newState = [...state];
      newState.push(scheduler.reportId.toString());
      return newState;
    }

    return state;
  })
  .handleAction(deleteSchedulerSuccess, (state, action) => {
    const newState = [...state];
    const index = newState.indexOf(action.payload.toString());

    if (index > -1) {
      newState.splice(index, 1);
    }

    return newState;
  });

const schedulerEntityReducer: Reducer = combineReducers({
  byId: schedulerById,
  allIds: schedulerAllIds,
});

export default schedulerEntityReducer;
