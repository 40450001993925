import { UserSystemGroupStrings } from 'store/storeTypes';

export enum MainReportTypes {
  Overview = 'overview',
  Systems = 'systems',
  Jobs = 'jobs',
}

export enum SubReportTypes {
  ServiceTicket = 'serviceTicket',
  MachineConfig = 'machineConfig',
  SystemPage = 'systemPage',
}

export type ReportTypesNew = MainReportTypes | SubReportTypes;

export type TimeFrame = 'day' | 'week' | 'month' | 'quarter' | 'year' | 'custom';
export enum TimeFrameEnum {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  custom = 'custom',
}
export type ReportsConfig = {
  [key in ReportTypesNew]: {
    defaultTimeFrame: TimeFrame;
    timeFrames: TimeFrame[];
  };
};
export type ReportsConfigByType = {
  [key in UserSystemGroupStrings]?: ReportsConfig;
};

export const REPORTS_CONFIG: ReportsConfigByType = {
  DTG: {
    [MainReportTypes.Overview]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week', 'custom'],
    },
    [MainReportTypes.Systems]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week', 'custom'],
    },
    [MainReportTypes.Jobs]: {
      defaultTimeFrame: 'week',
      timeFrames: ['day', 'week', 'month', 'quarter', 'year', 'custom'],
    },

    [SubReportTypes.SystemPage]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [SubReportTypes.MachineConfig]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [SubReportTypes.ServiceTicket]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
  },
  DTF: {
    [MainReportTypes.Overview]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [MainReportTypes.Systems]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [MainReportTypes.Jobs]: {
      defaultTimeFrame: 'week',
      timeFrames: ['week', 'month', 'quarter', 'year'],
    },

    [SubReportTypes.SystemPage]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [SubReportTypes.MachineConfig]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [SubReportTypes.ServiceTicket]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
  },
  Apollo: {
    [MainReportTypes.Overview]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week', 'custom'],
    },
    [MainReportTypes.Systems]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week', 'custom'],
    },
    [MainReportTypes.Jobs]: {
      defaultTimeFrame: 'week',
      timeFrames: ['day', 'week', 'month', 'quarter', 'year', 'custom'],
    },

    [SubReportTypes.SystemPage]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [SubReportTypes.MachineConfig]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
    [SubReportTypes.ServiceTicket]: {
      defaultTimeFrame: 'day',
      timeFrames: ['day', 'week'],
    },
  },
};
