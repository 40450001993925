import React from 'react';
import { ChartRecord, ActiveOverviewChart, UserSystemGroupStrings } from 'store/storeTypes';
import { TimeFrame } from 'models/ReportData';
import MobileSpeedChart from './MobileSpeedChart';
import DesktopSpeedChart from './DesktopSpeedChart';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';

export interface ISpeedChartProps {
  data: ChartRecord[];
  timeframe: TimeFrame;
  units?: string;
  tooltipText?: TooltipText;
  isMobileView?: boolean;
  selectedSystemGroup?: UserSystemGroupStrings;
  isMobile?: boolean;
  width?: number;
  height?: number;
  isWeek?: boolean;
  chartName?: ActiveOverviewChart;
  screenWidth?: number;
  customTimeframeProperties?: CustomTimeFrameProperties;
  maxKeepAlive?: Date | undefined;
}

export interface TooltipText {
  total: string;
  totalAvg: string;
}

export default function SpeedChart(props: ISpeedChartProps) {
  const {
    data,
    tooltipText,
    units,
    selectedSystemGroup,
    timeframe,
    isMobile,
    width,
    height,
    isWeek,
    chartName,
    screenWidth,
    customTimeframeProperties,
    maxKeepAlive,
  } = props;
  if (!isMobile) {
    return (
      <DesktopSpeedChart
        data={data}
        tooltipText={tooltipText}
        units={units}
        selectedSystemGroup={selectedSystemGroup}
        timeframe={timeframe}
        isWeek={isWeek}
        chartName={chartName}
        screenWidth={screenWidth}
        customTimeframeProperties={customTimeframeProperties}
        maxKeepAlive={maxKeepAlive}
      />
    );
  } else {
    return <MobileSpeedChart data={data} timeframe={timeframe} />;
  }
}
