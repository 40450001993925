import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import MuiDialog from '@material-ui/core/Dialog';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog, { InjectedProps } from '@material-ui/core/withMobileDialog';
import { WithWidth } from '@material-ui/core/withWidth';

const Dialog = styled(MuiDialog).attrs({
  classes: {
    paper: 'dialog-paper',
    root: 'dialog-root',
    container: 'dialog-container',
  },
  fullWidth: true,
})`
  .dialog-paper {
    border-radius: 10px;
    @media (max-width: 599px) {
      border-radius: 0;
    }
  }
`;

const IconButton = styled(MuiIconButton)<{
  position: 'right' | 'left';
}>`
  position: absolute;
  ${props => props.position}: 6px;
  top: 5px;
  color: #354052;
`;

export interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeIconColor?: string;
  closeIconPosition?: 'right' | 'left';
  className?: string;
  disableBackdropClick?: boolean;
  renderCloseButton?: boolean;
}

type ModalPropsWithMobile = ModalProps & InjectedProps & Partial<WithWidth>;

const Modal = (props: ModalPropsWithMobile) => {
  const { t } = useTranslation();
  const {
    children,
    onClose,
    isOpen,
    fullScreen,
    closeIconColor,
    closeIconPosition,
    maxWidth,
    className,
    disableBackdropClick,
    renderCloseButton = true,
  } = props;

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      maxWidth={maxWidth || 'md'}
      fullScreen={fullScreen}
      className={className}
      disableBackdropClick={disableBackdropClick || false}>
      {renderCloseButton && (
        <IconButton
          aria-label={t('close')}
          onClick={onClose}
          position={closeIconPosition || 'right'}>
          <CloseIcon style={{ color: closeIconColor || '' }} />
        </IconButton>
      )}

      {children}
    </Dialog>
  );
};

export default withMobileDialog<ModalProps>({ breakpoint: 'xs' })(Modal);
