import { createStandardAction, createAsyncAction } from 'typesafe-actions';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { authService } from 'services/AuthProvider';
import handleErrorAction from './_shared/handleErrorAction';
import apiAction, { apiActionSimple } from './_shared/apiAction';
import { makeApiUrl } from './_shared/utils';
import { AppUser, Store } from 'store/storeTypes';
import config from '../config/config';

export const setUserEmail = createStandardAction('SET_USER_EMAIL')<{ email: string }>();
export const setUserFirstName = createStandardAction('SET_USER_FIRST_NAME')<{
  firstName: string;
}>();
export const setUserLastName = createStandardAction('SET_USER_USER_LAST_NAME')<{
  lastName: string;
}>();

export const setAppUserParams = createStandardAction('SET_APP_USER_PARAMS')<AppUser>();

export const saveAppUserLocaleStarted = createStandardAction('SAVE_APP_USER_LOCALE_STARTED')();
export const saveAppUserLocaleSuccess = createStandardAction('SAVE_APP_USER_LOCALE_SUCCESS')<
  AppUser
>();
export const saveAppUserLocaleFailed = createStandardAction('SAVE_APP_USER_LOCALE_FAILED')<any>();

export function saveAppUserLocale(locale: string) {
  const userLocaleUrl = makeApiUrl('/api/users/locale');
  return apiActionSimple({
    request: {
      url: userLocaleUrl,
      method: 'POST',
      data: { localeCode: locale },
    },
    actions: [saveAppUserLocaleStarted, saveAppUserLocaleSuccess, saveAppUserLocaleFailed],
    interceptor: state => !state.appUser.id,
  });
}

export const loadAppUserFullStarted = createStandardAction('LOAD_APP_USER_FULL_STARTED')();
export const loadAppUserFullSuccess = createStandardAction('LOAD_APP_USER_FULL_SUCCESS')<AppUser>();
export const loadAppUserFullFailed = createStandardAction('LOAD_APP_USER_FULL_FAILED')<any>();

export function getAppUserFull() {
  const { isAuthenticated, idTokenPayload } = authService;

  const userFullUrl = makeApiUrl('/api/users/profile-data');
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiActionSimple({
      request: { url: userFullUrl },
      actions: [loadAppUserFullStarted, loadAppUserFullSuccess, loadAppUserFullFailed],
    });
  }
}

export const setAppUserAgreementVersionAC = createAsyncAction(
  'SET_APP_USER_AGREEMENT_VERSION_STARTED',
  'SET_APP_USER_AGREEMENT_VERSION_SUCCESS',
  'SET_APP_USER_AGREEMENT_VERSION_FAILED'
)<undefined, AppUser, any>();

export function setAppUserAgreementVersion(version: string) {
  const { isAuthenticated, idTokenPayload } = authService;
  const agreementVersion = `${idTokenPayload.sub}agreement_version`;
  if (isAuthenticated() && version && idTokenPayload && idTokenPayload.sub) {
    if (!localStorage.getItem(agreementVersion)) {
      localStorage.setItem(agreementVersion, JSON.stringify(version));
    }
    const userAgreementUrl = makeApiUrl('/api/users/agreement');
    return apiActionSimple({
      request: {
        url: userAgreementUrl,
        method: 'POST',
        data: { version },
      },
      actions: [
        setAppUserAgreementVersionAC.request,
        setAppUserAgreementVersionAC.success,
        setAppUserAgreementVersionAC.failure,
      ],
    });
  }
}

export const setAppUserAgreementAC = createAsyncAction(
  'SET_APP_USER_AGREEMENT_STARTED',
  'SET_APP_USER_AGREEMENT_SUCCESS',
  'SET_APP_USER_AGREEMENT_FAILED'
)<undefined, AppUser, any>();

export function setAppUserAgreement(isApproved: boolean, version: string) {
  const { isAuthenticated, idTokenPayload } = authService;
  const agreementVersion = `${idTokenPayload.sub}agreement_version`;
  const agreementApproved = `${idTokenPayload.sub}agreement_approved`;

  if (isAuthenticated() && version && idTokenPayload && idTokenPayload.sub) {
    if (!localStorage.getItem(agreementVersion)) {
      localStorage.setItem(agreementVersion, JSON.stringify(version));
    }
    if (!localStorage.getItem(agreementApproved)) {
      localStorage.setItem(agreementApproved, JSON.stringify(isApproved));
    }

    const userAgreementUrl = makeApiUrl('/api/users/agreement');
    return apiActionSimple({
      request: {
        url: userAgreementUrl,
        method: 'POST',
        data: { isApproved, version },
      },
      actions: [
        setAppUserAgreementAC.request,
        setAppUserAgreementAC.success,
        setAppUserAgreementAC.failure,
      ],
    });
  }
}

interface LoadPortalApiUrlPayload {
  apiUrl: string;
}

export const loadPortalApiUrlStarted = createStandardAction('LOAD_PORTAL_API_STARTED')();
export const loadPortalApiUrlSuccess = createStandardAction('LOAD_PORTAL_API_SUCCESS')<
  LoadPortalApiUrlPayload
>();
export const loadPortalApiUrlFailed = createStandardAction('LOAD_PORTAL_API_FAILED')<any>();

export function getPortalApiUrl() {
  const portalApiUrl = localStorage.getItem('portalApiUrl');
  if (!portalApiUrl) {
    return apiAction({
      request: {
        url: config.API_ROUTER_HOST as string,
        method: 'GET',
      },
      logic: {
        onStarted: dispatch => dispatch(loadPortalApiUrlStarted()),
        onSuccess: (data, dispatch) => {
          localStorage.setItem('portalApiUrl', data);
          dispatch(loadPortalApiUrlSuccess({ apiUrl: data }));
        },
        onFailed: (error, dispatch) => {
          dispatch(
            handleErrorAction(error, `API Router: ${error.message}`, [loadPortalApiUrlFailed])
          );
        },
      },
    });
  }

  return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    if (getState().appUser.apiUrl) return;
    dispatch(loadPortalApiUrlSuccess({ apiUrl: portalApiUrl! }));
  };
}
