import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';

interface IToggleItem {
  value: string | number | boolean;
  label: string;
  disabled?: boolean;
}
interface IToggleGroupProps {
  value: string | number | boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, value: string | number | boolean) => void;
  data: IToggleItem[];
  name?: string;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 25px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #818e9b;
  width: 150px;
`;

const StyledToggleButton = styled(ToggleButton)`
  font-family: Segoe UI, sans-serif;
  height: 100%;
  border: none;
  border-radius: 0;
  font-weight: 600;
  font-size: 12px;
  padding: 0;
  justify-content: center;
  width: 100%;
  &.Selected {
    background-color: #2ea1f8;
    color: #ffffff;
    &:hover {
      color: #2ea1f8;
    }
  }
`;

export const ToggleGroup = (props: IToggleGroupProps) => {
  const { value, name, onChange, data } = props;
  return (
    <StyledToggleButtonGroup value={value} exclusive onChange={onChange} data-name={name}>
      {data.map(item => {
        return (
          <StyledToggleButton
            key={item.label}
            value={item.value}
            disabled={item.disabled}
            classes={{
              selected: 'Selected',
            }}>
            {item.label}
          </StyledToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};
