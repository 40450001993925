import React from 'react';
import { MobileDatePickerProps, Wrapper } from './MobileDatePicker';
import PbiFilterYears from '../PbiFilter/PbiFilterYearsContainer';

const MobileYearPicker = (props: MobileDatePickerProps) => {
  const { closeCalendarModal } = props;
  return (
    <Wrapper data-id={'OuterWrapper'}>
      <PbiFilterYears isMobile={true} closeCalendarModal={closeCalendarModal} />
    </Wrapper>
  );
};
export default MobileYearPicker;
