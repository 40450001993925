import React, { useEffect, useRef } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import { LocalizedUtils } from 'components/_common/FilterDatePicker';
import styled from 'styled-components';
import appTheme from '../../theme';
import enLocale from 'date-fns/locale/en-US';
import { getWeekStartEnv } from 'helpers/envGetters';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import { addMonths, eachMonthOfInterval, format, isSameDay, isSameMonth } from 'date-fns';
import { activeTheme, MobileDatePickerProps, PickerWrapper, Wrapper } from './MobileDatePicker';

const WEEK_START = getWeekStartEnv();

const MobileDayPicker = (props: MobileDatePickerProps) => {
  const { startDate, endDate, selectedDay, setActiveDate, closeCalendarModal } = props;

  const onChangeDateHandler = date => {
    if (isSameDay(startDate, date)) {
      return;
    }
    setActiveDate(date);
    closeCalendarModal();
  };

  return (
    <Wrapper data-id={'OuterWrapper'}>
      <MuiThemeProvider theme={activeTheme}>
        <MuiPickersUtilsProvider
          utils={LocalizedUtils}
          locale={{
            ...enLocale,
            options: {
              ...enLocale.options,
              weekStartsOn: WEEK_START,
            },
          }}>
          <PickerWrapper>
            <DatePicker
              allowKeyboardControl={true}
              disableToolbar
              variant="static"
              value={selectedDay}
              onChange={onChangeDateHandler}
              minDate={startDate}
              maxDate={endDate}
            />
          </PickerWrapper>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Wrapper>
  );
};
export default MobileDayPicker;
