import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';

const { changeUserSearchValue } = actions.userUi;

export default createReducer<string, RootAction>('').handleAction(
  changeUserSearchValue,
  (state, action) => action.payload
);
