import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Store, UserSystemGroupStrings } from 'store/storeTypes';
import PbiFilterTimeframe from './PbiFilterTimeframe';
import { setActiveDate, setTimeframe, applyMobileFilters } from 'actions/reportFilterActions';
import { closeCalendarModal } from 'actions/filterCalendarUiAction';
import { isToday } from 'helpers/date';
import {
  activeReportSelector,
  allSystemsSelector,
  filterDataBySystemGroupSelector,
} from 'reducers/reports/filterDataReducer';
import { filterStateBySystemGroupSelector } from 'selectors/filterStateDataSelectors';
import { userSettingsSelector } from 'reducers/userSettingsReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import {
  customTimeFramePropertiesSelector,
  isSelectedSystemsEverReportedBySystemGroupSelector,
  selectedDateSelector,
  selectedSystemSerialsBySystemGroup,
  timeFrameSelector,
} from 'reducers/reports/filterStateReducer';
import {
  downloadInkConsumptionPerJobReport,
  isInkConsumptionPerJobValidToDownload,
} from 'actions/jobAnalysisActions';
import {
  jobAnalysisBySystemGroupDownloadInkConsumptionPerJobErrorSelector,
  jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoadingSelector,
  jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobSelector,
  jobAnalysisIsDownloadInkConsumptionPerJobLoadedSelector,
  jobAnalysisIsDownloadInkConsumptionPerJobLoadingSelector,
} from 'reducers/jobAnalysisReducer';
import { TimeFrame } from 'models/ReportData';
import { isEverReportedLoadedSelector } from 'reducers/isEverReportedReducer';
import { isReadOnlyRoleSelector } from 'reducers/appUserReducer';
import { screenWidthSelector } from 'reducers/appUserReducer';
type OwnProps = {
  isMobile?: boolean;
  setSelectedMobileTimeFrame?: (timeframe: TimeFrame) => void;
  selectedMobileTimeFrame?: TimeFrame;
  setTimeframe?: ({}: any) => void;
  selectedSystemGroup?: UserSystemGroupStrings;
};
const mapStateToProps = (state: Store) => {
  return {
    filterData: filterDataBySystemGroupSelector(state),
    filterState: filterStateBySystemGroupSelector(state),
    userSettings: userSettingsSelector(state),
    selectedSystemGroup: systemGroupSelector(state),
    activeReport: activeReportSelector(state),
    isToday: isToday(state),
    isSelectedSystemsEverReported: isSelectedSystemsEverReportedBySystemGroupSelector(state),
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading: jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoadingSelector(
      state
    ),
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage: jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobSelector(
      state
    ),
    downloadInkConsumptionPerJobError: jobAnalysisBySystemGroupDownloadInkConsumptionPerJobErrorSelector(
      state
    ),
    isDownloadInkConsumptionPerJobLoaded: jobAnalysisIsDownloadInkConsumptionPerJobLoadedSelector(
      state
    ),
    isDownloadInkConsumptionPerJobLoading: jobAnalysisIsDownloadInkConsumptionPerJobLoadingSelector(
      state
    ),
    allUserSystems: allSystemsSelector(state),
    timeframe: timeFrameSelector(state),
    selectedDate: selectedDateSelector(state),
    selectedSerialsIds: selectedSystemSerialsBySystemGroup(state),
    isEverReportedLoaded: isEverReportedLoadedSelector(state),
    customTimeframeProperties: customTimeFramePropertiesSelector(state),
    isReadOnlyRole: isReadOnlyRoleSelector(state),
    screenWidth: screenWidthSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      isInkConsumptionPerJobValidToDownload,
      downloadInkConsumptionPerJobReport,
      setActiveDate,
      setTimeframe,
      applyMobileFilters,
      closeCalendarModal,
    },
    dispatch
  );

export type PbiFilterTimeframeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(PbiFilterTimeframe);
