import { TreeNodeProps } from 'react-dropdown-tree-select';

import { Entity, Ids } from 'store/storeTypes';
import { CustomerData } from 'models/CustomerData';
import { SiteData } from 'models/SiteData';
import { SystemData } from 'models/SystemData';
import { makeTreeData } from 'components/_common/TreeSelect/treeHelpers';

/** Such a tree is needed to initially populate common/TreeSelect component with data */

export function userDataToTree(
  customers: Entity<CustomerData>,
  sites: Entity<SiteData>,
  systems: Entity<SystemData>,
  userOrgIds: Ids = [],
  userSiteIds: Ids = [],
  userSystemIds: Ids = [],
  prevOrgIds: Ids = [],
  prevSiteIds: Ids = [],
  disabledOrgIds: Ids = [],
  disabledSiteIds: Ids = []
): TreeNodeProps[] {
  const customersAll: CustomerData[] = Object.values(customers.byId);
  const sitesAll: SiteData[] = Object.values(sites.byId);
  const systemsAll: SystemData[] = Object.values(systems.byId);

  return makeTreeData({
    rootElements: customersAll,
    parentElements: sitesAll.map(site => ({
      id: site.id,
      name: site.name,
      rootId: site.customerId,
    })),
    childElements: systemsAll.map(sys => ({
      id: sys.serialNumber,
      name: sys.serialNumber,
      parentId: sys.siteId,
      rootId: sys.customerId,
    })),
    rootSelectedIds: userOrgIds,
    parentSelectedIds: userSiteIds,
    childSelectedIds: userSystemIds,
    rootForceUncheckIds: prevOrgIds,
    parentForceUncheckIds: prevSiteIds,
    rootDisabledIds: disabledOrgIds,
    parentDisabledIds: disabledSiteIds,
  });
}

export function treeCheckedNodesToUserData(
  nodes: TreeNodeProps[],
  prevOrgIds: Ids = [],
  prevSiteIds: Ids = []
): {
  customerIds: Ids;
  siteIds: Ids;
  systemIds: Ids;
} {
  const customerIds: Ids = [];
  const siteIds: Ids = [];
  const systemIds: Ids = [];

  // Customers
  nodes.forEach((node: TreeNodeProps) => {
    if (!node.rootId && !node.parentId) {
      customerIds.push(node.value);
    }
  });

  // Sites
  nodes.forEach((node: TreeNodeProps) => {
    if (
      // Check is node a site
      node.rootId &&
      !node.parentId &&
      // If parent is selected - skip
      !customerIds.includes(node.rootId) &&
      // If parent JUST unselected - skip
      !prevOrgIds.includes(node.rootId)
    ) {
      siteIds.push(node.value);
    }
  });

  // Systems
  nodes.forEach((node: TreeNodeProps) => {
    if (
      node.parentId &&
      // If ancestor is selected - skip
      !customerIds.includes(node.rootId) &&
      !siteIds.includes(node.parentId) &&
      // If ancestor JUST unselected - skip
      !prevOrgIds.includes(node.rootId) &&
      !prevSiteIds.includes(node.parentId)
    ) {
      systemIds.push(node.value);
    }
  });

  return { customerIds, siteIds, systemIds };
}
