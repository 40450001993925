import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { toggleUserTableAll } from 'actions/userUiActions';
import UsersTable from './UsersTable';
import { Store } from 'store/storeTypes';
import { userIdsWithSearchSelector } from 'selectors/entitiesSelectors';
import { isAllRowsCheckedSelector } from 'selectors/uiSelectors';

const mapStateToProps = (state: Store) => {
  const { isLoadingCustomers, isLoadingSites, isLoadingSystems, isLoadingUsers } = state.api;

  return {
    userIds: userIdsWithSearchSelector(state),
    isLoading: isLoadingCustomers || isLoadingSites || isLoadingSystems || isLoadingUsers,
    isAllRowsSelected: isAllRowsCheckedSelector(state),
    isSomeRowsSelected: state.ui.selectedUserRows.length > 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleUserTableAll,
    },
    dispatch
  );

export type UsersTableProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
