import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Paper, Table, TableHead, TableHeadRow, TableHeadCell, TableBody } from '../_common/Table';
import SchedulerTableRow from './SchedulerTableRowContainer';
import LinearProgress from '../_common/LinearProgress';
import { SchedulerTableProps } from './SchedulerTableContainer';

const SubjectHeadCell = styled(TableHeadCell)`
  width: 270px;
  min-width: 220px;
  @media (max-width: 1500px) {
    width: 190px;
    min-width: 190px;
  }
  @media (max-width: 500px) {
    width: 135px;
    min-width: 135px;
  }
  @media (max-width: 360px) {
    width: 115px;
    min-width: 115px;
    white-space: normal;
  }
`;
const ReportHeadCell = styled(TableHeadCell)`
  width: 125px;
  min-width: 125px;
  @media (max-width: 1500px) {
    width: 110px;
    min-width: 110px;
  }
  @media (max-width: 500px) {
    width: 85px;
    min-width: 85px;
  }
`;
const TimeHeadCell = styled(TableHeadCell)`
  width: 240px;
  min-width: 240px;
  @media (max-width: 1500px) {
    width: 200px;
    min-width: 200px;
  }
  @media (max-width: 550px) {
    min-width: 80px;
    width: auto;
  }
  @media (max-width: 800px) {
    flex-grow: 1;
  }
`;
const RepeatHeadCell = styled(TableHeadCell)`
  width: 450px;
  min-width: 150px;
  @media (max-width: 1500px) {
    width: 130px;
    min-width: 130px;
  }
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 1150px) {
    flex-grow: 1;
  }
`;
const LastSentHeadCell = styled(TableHeadCell)`
  width: 135px;
  max-width: 135px;
  @media (max-width: 1500px) {
    width: 120px;
    min-width: 120px;
  }
  @media (max-width: 1250px) {
    display: none;
  }
`;
const NotesHeadCell = styled(TableHeadCell)`
  flex-grow: 1;
  @media (max-width: 1150px) {
    display: none;
  }
`;
const SendingHeadCell = styled(TableHeadCell)`
  width: 130px;
  @media (max-width: 670px) {
    display: none;
  }
`;

const ButtonsHeadCell = styled(TableHeadCell)`
  width: 121px;
  @media (max-width: 930px) {
    display: none;
  }
`;

const SchedulerTable = React.memo((props: SchedulerTableProps) => {
  const { t } = useTranslation();
  const { isLoading, schedulerIds } = props;

  return (
    <Paper>
      {!isLoading && (
        <Table>
          <TableHead>
            <TableHeadRow>
              <SubjectHeadCell>{t('schedulerTable.emailSubject')}</SubjectHeadCell>
              <ReportHeadCell>{t('schedulerTable.report')}</ReportHeadCell>
              <TimeHeadCell>{t('schedulerTable.time')}</TimeHeadCell>
              <RepeatHeadCell>{t('schedulerTable.repeat')}</RepeatHeadCell>
              <LastSentHeadCell>{t('schedulerTable.lastSent')}</LastSentHeadCell>
              {/* <NotesHeadCell>{t('schedulerTable.notes')}</NotesHeadCell> */}
              <NotesHeadCell></NotesHeadCell>
              <SendingHeadCell>{t('schedulerTable.sendingReport')}</SendingHeadCell>
              <ButtonsHeadCell />
            </TableHeadRow>
          </TableHead>

          <TableBody>
            {schedulerIds.map((id: string) => (
              <SchedulerTableRow key={id} schedulerId={id} />
            ))}
          </TableBody>
        </Table>
      )}

      {isLoading && <LinearProgress style={{ width: '100%' }} />}
    </Paper>
  );
});

export default SchedulerTable;
