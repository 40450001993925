import { appearanceEffect } from 'globalStyle';
import { notUndefiend, valueAsString } from 'helpers/conversions';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { ICardProps, StatsData } from './Card';
import { Spinner, SpinnerContainer } from './Styles';
import { ReactComponent as ArrowUp } from '../../assets/icons/ArrowUp.svg';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;
const LeftColumn = styled.div<{ selectedSystemGroup: UserSystemGroupStrings }>`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: ${props => (props.selectedSystemGroup === UserSystemGroup.Apollo ? '100%' : '')};
  flex-direction: column;
  justify-content: space-between;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
const LeftColUpperRow = styled.div`
  height: 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
const LeftColUpperRowValueSubRow = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Icon = styled.div`
  margin-top: 5px;
`;
const Units = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #354052;
  margin-top: 15px;
  margin-left: 3px;
`;
const Value = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #354052;
  margin-left: 10px;
`;
const LeftColUpperRowDescriptionSubRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #7f8fa4;
`;
const LeftColBottomRow = styled.div`
  height: 30px;
  box-sizing: border-box;
  display: flex;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: flex-end;
  border-top: 1px solid #c5d0de;
`;

const RightColumn = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
const RightColumnRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c5d0de;
`;
const RightColumnRowValueSubRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const ValueAndUnitWrapper = styled.div.attrs({
  'data-id': 'ValueAndUnitWrapper',
})`
  display: flex;
  justify-content: start;
  margin-right: 5px;
`;
const RightColumnRowValueSubRowItemIcon = styled.div``;
const RightColumnRowValueSubRowItemUnits = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #354052;
  margin-top: 5px;
`;
const RightColumnRowValueSubRowItemValue = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #354052;
  margin-right: 5px;
`;

const RightColumnRowDescriptionSubRow = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #7f8fa4;
`;
const ProductionRateCardMobile = (props: ICardProps) => {
  const {
    value,
    units,
    description,
    icons,
    stats,
    statsDescription,
    addedData,
    isPercentage,
    selectedSystemGroup,
  } = props;
  const [isNegative, setIsNegative] = React.useState(false);
  const rightColumnRef = useRef(null);
  const [rightColumnWidth, setRightColumnWidth] = React.useState(0);
  React.useEffect(() => {
    if (notUndefiend([stats])) {
      //*for apollo we do not need to show right column
      if (selectedSystemGroup !== UserSystemGroup.Apollo) {
        const { width } = rightColumnRef.current.getBoundingClientRect();
        setRightColumnWidth(width);
      }

      if (stats! < 0) {
        setIsNegative(true);
      } else if (description === 'Average Availability' && value >= 0.999) {
        setIsNegative(false);
      } else {
        setIsNegative(false);
      }
    }
  }, [stats, selectedSystemGroup]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CardContent>
      {notUndefiend([value]) ? (
        <>
          <LeftColumn selectedSystemGroup={selectedSystemGroup}>
            <LeftColUpperRow>
              <LeftColUpperRowValueSubRow>
                <Icon>{isNegative ? icons.negative : icons.positive}</Icon>

                <Value
                  data-testid="production-rate-value"
                  style={{ letterSpacing: value > 999 ? '-2px' : 'normal' }}>
                  {valueAsString(value, isPercentage)}
                </Value>
                <Units data-testid="production-rate-units">{units}</Units>
              </LeftColUpperRowValueSubRow>
              <LeftColUpperRowDescriptionSubRow>{description}</LeftColUpperRowDescriptionSubRow>
            </LeftColUpperRow>
            <LeftColBottomRow data-testid="production-rate-description">
              {notUndefiend([stats]) && StatsData(stats)} {statsDescription}
            </LeftColBottomRow>
          </LeftColumn>

          {selectedSystemGroup !== UserSystemGroup.Apollo && (
            <RightColumn ref={rightColumnRef}>
              <RightColumnRow>
                <RightColumnRowValueSubRow style={{ width: rightColumnWidth }}>
                  <ValueAndUnitWrapper>
                    <RightColumnRowValueSubRowItemValue data-testid="added-value">
                      {addedData.upper.formatter
                        ? addedData.upper.formatter(addedData.upper.value)
                        : valueAsString(addedData.upper.value!)}
                    </RightColumnRowValueSubRowItemValue>
                    <RightColumnRowValueSubRowItemUnits data-testid="added-units">
                      {addedData.upper.unit}
                    </RightColumnRowValueSubRowItemUnits>
                  </ValueAndUnitWrapper>

                  <RightColumnRowValueSubRowItemIcon>
                    {addedData.upper.trend !== 0 && addedData.upper.trend !== undefined && (
                      <ArrowUp
                        width={14}
                        height={18}
                        style={{ transform: addedData.upper.trend < 0 ? 'rotate(180deg)' : '' }}
                      />
                    )}
                  </RightColumnRowValueSubRowItemIcon>
                </RightColumnRowValueSubRow>
                <RightColumnRowDescriptionSubRow data-testid="added-stats-description">
                  {addedData.upper.description}
                </RightColumnRowDescriptionSubRow>
              </RightColumnRow>

              <RightColumnRow style={{ borderBottom: 'none' }}>
                <RightColumnRowValueSubRow style={{ width: rightColumnWidth }}>
                  <ValueAndUnitWrapper>
                    <RightColumnRowValueSubRowItemValue data-testid="added-lower-value">
                      {addedData.lower.formatter
                        ? addedData.lower.formatter(addedData.lower.value)
                        : valueAsString(addedData.lower.value!)}
                    </RightColumnRowValueSubRowItemValue>
                    <RightColumnRowValueSubRowItemUnits data-testid="added-lower-units">
                      {addedData.lower.unit}
                    </RightColumnRowValueSubRowItemUnits>
                  </ValueAndUnitWrapper>

                  <RightColumnRowValueSubRowItemIcon>
                    {addedData.lower.trend !== 0 && addedData.lower.trend !== undefined && (
                      <ArrowUp
                        width={14}
                        height={18}
                        style={{ transform: addedData.lower.trend < 0 ? 'rotate(180deg)' : '' }}
                      />
                    )}
                  </RightColumnRowValueSubRowItemIcon>
                </RightColumnRowValueSubRow>
                <RightColumnRowDescriptionSubRow data-testid="added-lower-stats-description">
                  {addedData.lower.description}
                </RightColumnRowDescriptionSubRow>
              </RightColumnRow>
            </RightColumn>
          )}
        </>
      ) : (
        <SpinnerContainer>{Spinner()}</SpinnerContainer>
      )}
    </CardContent>
  );
};
export default ProductionRateCardMobile;
