import { Backdrop, Box, ClickAwayListener, Fade, Modal } from '@material-ui/core';
import { globalColors } from 'globalStyle';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../assets/icons/timeSelectorArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/timeSelectorArrowUp.svg';
import { convertHourtFrom12To24Format } from 'helpers/timelineUtils';
import { convertTo12HourFormat } from 'helpers/conversions';
const TimeSelect = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: transparent;
  height: 28px;
  width: 115px;
  padding: 8px 12px;
  background: #ffffff;
  color: ${props =>
    props.isDisabled ? globalColors.regular.text.grey : globalColors.regular.text.primary};
`;

const AMPMtogglerWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  opacity: ${props => (props.isDisabled ? 0.3 : 1)};
`;
const AmPmValue = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background: ${props => (props.selected ? globalColors.regular.background.blue : 'transparent')};
  color: ${props =>
    props.selected ? globalColors.regular.text.white : globalColors.regular.text.grey};
  border-radius: 4px;
`;
const TimeSelectRow = styled.div`
  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 178px;
`;
const TimeValue = styled.div``;
const ArrowWrapper = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 115px;
  height: 365px;
  background: #ffffff;
  padding: 6px 8px;
  gap: 10px;
`;
const TimeRecordRow = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  color: ${props =>
    props.isSelected ? globalColors.regular.text.blue : globalColors.regular.text.primary};
`;
type TimeSelectorProps = any; // TODO: Add props type

const popUpStartTimeRowPositionStyle = {
  position: 'absolute' as 'absolute',
  top: 274,
  left: 629,
};
const popUpEndTimeRowPositionStyle = {
  position: 'absolute' as 'absolute',
  top: 395,
  left: 629,
};

const hoursArray = [
  { value: 0, hour: '12:00' },
  { value: 1, hour: '01:00' },
  { value: 2, hour: '02:00' },
  { value: 3, hour: '03:00' },
  { value: 4, hour: '04:00' },
  { value: 5, hour: '05:00' },
  { value: 6, hour: '06:00' },
  { value: 7, hour: '07:00' },
  { value: 8, hour: '08:00' },
  { value: 9, hour: '09:00' },
  { value: 10, hour: '10:00' },
  { value: 11, hour: '11:00' },
];

const TimeSelector = (props: TimeSelectorProps) => {
  const {
    selectorName,
    isHourSelectionDisabled,
    value,
    timeModificator,
    onHourChange,
    onTimeModificatorClickHandler,
    activeReport,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const hour = convertTo12HourFormat(value).split(' ')[0];
  const onClickHandler = () => {
    if (isHourSelectionDisabled) {
      return;
    }
    setIsOpen(true);
  };
  const onAmPmClickHandler = () => {
    if (isHourSelectionDisabled) {
      return;
    }
    onTimeModificatorClickHandler();
  };
  const onTimeRowClickHandler = (hour: number) => {
    onHourChange(hour);
    setIsOpen(false);
  };
  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <>
          <TimeSelectRow>
            <TimeSelect isDisabled={isHourSelectionDisabled} onClick={onClickHandler}>
              <TimeValue>{hour}</TimeValue>
              <ArrowWrapper>
                {isOpen ? (
                  <ArrowUp
                    fill={
                      isHourSelectionDisabled
                        ? globalColors.regular.text.grey
                        : globalColors.regular.text.primary
                    }
                  />
                ) : (
                  <ArrowDown
                    fill={
                      isHourSelectionDisabled
                        ? globalColors.regular.text.grey
                        : globalColors.regular.text.primary
                    }
                  />
                )}
              </ArrowWrapper>
            </TimeSelect>
            <AMPMtogglerWrapper isDisabled={isHourSelectionDisabled}>
              <AmPmValue onClick={onAmPmClickHandler} selected={timeModificator === 'AM'}>
                AM
              </AmPmValue>
              <AmPmValue onClick={onAmPmClickHandler} selected={timeModificator === 'PM'}>
                PM
              </AmPmValue>
            </AMPMtogglerWrapper>
          </TimeSelectRow>
          <Modal
            open={isOpen}
            onClose={() => {}}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
              invisible: true,
            }}
            BackdropComponent={Backdrop}>
            <Fade in={isOpen}>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Box id="transition-modal-title">
                  <Wrapper
                    style={
                      selectorName === 'start time'
                        ? popUpStartTimeRowPositionStyle
                        : popUpEndTimeRowPositionStyle
                    }
                    data-automationid="time-picker-pop-up">
                    {hoursArray.map((item, index) => {
                      const isSelected = item.value === value;
                      return (
                        <TimeRecordRow
                          isSelected={isSelected}
                          key={index}
                          onClick={() => onTimeRowClickHandler(item.value)}>
                          {item.hour}
                        </TimeRecordRow>
                      );
                    })}
                  </Wrapper>
                </Box>
              </ClickAwayListener>
            </Fade>
          </Modal>
        </>
      </ClickAwayListener>
    </>
  );
};

export default TimeSelector;
