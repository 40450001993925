import React from 'react';
import styled from 'styled-components';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartRecord, UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import { ReactComponent as Fabric } from '../../../assets/icons/fabricIconNoColor.svg';
import { ReactComponent as Tshirt } from '../../../assets/icons/T-shirt.svg';
import { ReactComponent as Speed } from '../../../assets/icons/speed.svg';
import { ReactComponent as Dislike } from '../../../assets/icons/dislikeWithCircle.svg';
import { ReactComponent as Like } from '../../../assets/icons/likeWithCircle.svg';
import {
  cutAllValueAfrerDot,
  formatFloatToPercentage,
  numberFormatterNew,
} from 'helpers/conversions';
import { globalColors } from 'globalStyle';
import { Spinner } from '../Styles';
import { TimeFrame } from 'models/ReportData';
import { AggregatedRecord } from './ProductionChart';
import {
  getAdditionalOffsetForXAxisCentralLabelTotalProductionChart,
  getGraphMarginTotalProduction,
} from 'helpers/chartUtils';
import AxixTiksForCustomTimeframe from './AxixTiksForCustomTimeframe';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { normalizeApolloAllChartsRecords } from 'helpers/overviewUtils';
const TooltipContainer = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr 10px 25px 1fr 10px 25px 1fr;
  border: 1px solid #c5d0de;
  background: white;
  width: 332px;
  height: 30px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #00000022;
  padding: 8px;
  height: 100%;
  width: 100%;
`;

const Divider = styled.div`
  border-left: 1px solid #c5d0de;
  height: 100%;
  margin: 0 5px;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-rows: 13px 10px;
`;
const Value = styled.div`
  font-size: 12px;
  color: #354052;
`;
const DescriptionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Description = styled.div`
  font-size: 8px;
  color: #7f8fa4;
`;
type DesktopProductionChartProps = {
  timeframe: TimeFrame;
  aggregated: AggregatedRecord[];
  units: string;
  isWeek: boolean;
  selectedSystemGroup: UserSystemGroupStrings;
  legendText: any;
  screenWidth: number;
  data: ChartRecord[];
  customTimeframeProperties: CustomTimeFrameProperties;
  isMachineFilterOpen: boolean;
  maxKeepAlive: Date | undefined;
};

const DesktopProductionChart = (prpos: DesktopProductionChartProps) => {
  const {
    data,
    aggregated,
    isWeek,
    units,
    selectedSystemGroup,
    customTimeframeProperties,
    legendText,
    timeframe,
    screenWidth,
    isMachineFilterOpen,
    maxKeepAlive,
  } = prpos;
  const CHART_HEIGHT = 210;
  //default width of YAxis is 60px by official docs of recharts
  const DEFAULT_Y_AXIS_WIDTH = 60;
  //we need to make it wider to show all numbers on  YAxis
  const YAxisOffset = 5;
  if (!data || timeframe === 'year' || timeframe === 'month' || timeframe === 'quarter') {
    return Spinner(60);
  } else {
    const { marginRight, marginLeft } = getGraphMarginTotalProduction(
      screenWidth,
      timeframe,
      data,
      isMachineFilterOpen
    );
    const additionalOffsetForXAxisCentralLabel = getAdditionalOffsetForXAxisCentralLabelTotalProductionChart(
      {
        granularity: data[0].granularity,
        dataLength: data.length,
        screenWidth,
      }
    );
    //*filter future period records
    const filteredRecords = normalizeApolloAllChartsRecords(
      aggregated,
      maxKeepAlive,
      selectedSystemGroup
    );
    return (
      <ResponsiveContainer
        width="100%"
        height={CHART_HEIGHT}
        data-automationid="production-chart-responsive-container">
        <AreaChart
          data={filteredRecords}
          margin={{
            right: marginRight,
            left: -22,
            top: 20,
          }}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#E6E9ED" stopOpacity={0.8} />
              <stop offset="99%" stopColor="#E6E9ED" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="##2EA1F8" stopOpacity={0.1} />
              <stop offset="99%" stopColor="#2EA1F8" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <YAxis
            width={DEFAULT_Y_AXIS_WIDTH + YAxisOffset}
            axisLine={{ stroke: '#C5D0DE' }}
            tickLine={false}
            tick={{ fontSize: 12, fill: `${globalColors.regular.text.grey}` }}
            tickFormatter={cutAllValueAfrerDot}></YAxis>
          <XAxis
            height={50}
            interval={0}
            axisLine={false}
            tickLine={{ stroke: '#C5D0DE' }}
            padding={{ left: marginLeft - YAxisOffset, right: 40 }}
            tick={
              <AxixTiksForCustomTimeframe
                chart="totalProduction"
                data={aggregated}
                marginLeft={marginLeft / 2 + additionalOffsetForXAxisCentralLabel}
                marginRight={marginRight}
                customTimeframeProperties={customTimeframeProperties}
                timeframe={timeframe}
              />
            }>
            <Label
              content={
                <CustomizedLabelGrid
                  offsetLeft={marginLeft}
                  offsetRight={marginRight}
                  chartHeight={CHART_HEIGHT}
                />
              }
            />
          </XAxis>

          <Tooltip
            content={<CustomTooltip units={units} selectedSystemGroup={selectedSystemGroup} />}
          />
          <Area
            isAnimationActive={false}
            name={legendText.totalAvg}
            type="monotone"
            dataKey="totalAvg"
            stroke="#C5D0DE"
            // fill="#C5D0DE26"
            fill="url(#colorPv)" //Add the id 'colorUv' which is used in linearGradient
            strokeWidth={1}
          />
          <Area
            isAnimationActive={false}
            name={legendText.total}
            type="monotone"
            dataKey="total"
            stroke="#2EA1F8"
            fill="url(#colorUv)" //Add the id 'colorUv' which is used in linearGradient
            strokeWidth={2}
            dot={
              isWeek ? (
                { stroke: '#2EA1F8', fill: '#208CD7', strokeWidth: 1, fillOpacity: 1 }
              ) : (
                <CustomizedDot data={aggregated} />
              )
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
};

const CustomizedLabelGrid = props => {
  const { viewBox, offsetRight, offsetLeft, chartHeight } = props;
  const { width } = viewBox;

  const HEADER_HEIGHT = 50;
  const X_START_OFFSET = 38;
  const Y_START_OFFSET = 3;
  const LINES_COUNT = 5;

  const heigth = chartHeight - HEADER_HEIGHT;
  const step = heigth / LINES_COUNT + Y_START_OFFSET;
  const fullWidth = width + offsetLeft + offsetRight;

  return (
    <svg>
      <line
        x1={X_START_OFFSET}
        y1={heigth}
        x2={fullWidth}
        y2={heigth}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1={X_START_OFFSET}
        y1={heigth - step}
        x2={fullWidth}
        y2={heigth - step}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1={X_START_OFFSET}
        y1={heigth - step * 2}
        x2={fullWidth}
        y2={heigth - step * 2}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1={X_START_OFFSET}
        y1={heigth - step * 3}
        x2={fullWidth}
        y2={heigth - step * 3}
        stroke={globalColors.regular.background.greyDevider}
      />
      <line
        x1={X_START_OFFSET}
        y1={heigth - step * 4}
        x2={fullWidth}
        y2={heigth - step * 4}
        stroke={globalColors.regular.background.greyDevider}
      />
    </svg>
  );
};

export default DesktopProductionChart;

const CustomizedDot = props => {
  //*it calls only in day timeframe. and shows only last point with data
  const { cx, cy, value, data } = props;

  let indexOfLastPoint = data.findIndex(record => record.total === undefined);
  if (indexOfLastPoint !== -1) {
    indexOfLastPoint = indexOfLastPoint - 1;
    const date = props.payload.date;
    const hour = +date?.split(':')[0];
    if (indexOfLastPoint === hour) {
      return (
        <svg x={cx - 3} y={cy - 3} width={6} height={6} viewBox="0 0 1024 1024">
          {' '}
          <circle cx="512" cy="512" r="512" fill="#208CD7"></circle>
        </svg>
      );
    }
  }

  return <svg x={cx - 3} y={cy - 3} width={6} height={6} viewBox="0 0 1024 1024"></svg>;
};
const CustomTooltip = props => {
  const { active, payload: payloadProps, selectedSystemGroup, units } = props;
  if (active && payloadProps && payloadProps[0]) {
    const payload = payloadProps[0].payload;
    return (
      <TooltipContainer data-automationid="total_impression_graph_tooltip">
        {selectedSystemGroup === UserSystemGroup.DTF ? (
          <Fabric
            width={20}
            height="100%"
            fill={payload.total >= payload.totalAvg ? '#1BB934' : '#FF4081'}
          />
        ) : (
          <Tshirt
            width={20}
            height="100%"
            fill={payload.total >= payload.totalAvg ? '#1BB934' : '#FF4081'}
          />
        )}
        <InfoContainer>
          <Value>
            {numberFormatterNew(payload.total)}
            {selectedSystemGroup === UserSystemGroup.DTF && payload.total && ` ${units}`}
          </Value>
          <Description>
            {selectedSystemGroup === UserSystemGroup.DTG ? 'Total impressions' : 'Total production'}
          </Description>
        </InfoContainer>
        <Divider></Divider>
        <Speed
          width={20}
          height="100%"
          fill={payload.speed >= payload.speedAvg ? '#1BB934' : '#FF4081'}
        />
        <InfoContainer>
          <Value>
            {numberFormatterNew(payload.speed)}
            {selectedSystemGroup === UserSystemGroup.DTF && payload.speed && ` ${units}/h`}
            {selectedSystemGroup === UserSystemGroup.DTG && ' imp/h'}
          </Value>
          <Description>Avg. prod. rate</Description>
        </InfoContainer>
        <Divider></Divider>
        {payload.availability >= payload.availabilityAvg ? (
          <Like width={20} height="100%" fill={'#1BB934'} />
        ) : (
          <Dislike width={20} height="100%" fill={'#FF4081'} />
        )}

        <InfoContainer>
          <Value>
            {(payload.availability !== undefined &&
              formatFloatToPercentage(payload.availability)) ||
              0}
          </Value>
          <Description>Avg. availability</Description>
        </InfoContainer>
      </TooltipContainer>
    );
  }

  return null;
};
