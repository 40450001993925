import { createAsyncAction } from 'typesafe-actions';
import { IOverviewRequest } from '../models/OverviewData';
import { authService } from 'services/AuthProvider';
import { makeApiUrl } from 'actions/_shared/utils';
import apiAction from 'actions/_shared/apiAction';
import {
  UserSystemGroup,
  OverviewCards,
  OverviewAchievements,
  OverviewCharts,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { getCustomSearchParameters } from 'helpers/date';
import { normalizeGraphData } from 'helpers/apiCoversion';
import { sortByGranularity } from 'helpers/sorting';
import { getRealTimeHeaders } from './_shared/realTimeHeaders';

const converter = (data: any, map: any, systemGroup: UserSystemGroupStrings | undefined) => {
  if (!systemGroup) {
    return {};
  }
  const objectConverted: any = {};
  Object.keys(data).forEach(key => {
    if (!objectConverted[systemGroup]) objectConverted[systemGroup] = {};
    if (map[key]) {
      objectConverted[systemGroup][map[key]] = data[key];
    }
  });

  return objectConverted;
};

export const getOverviewProductionCardsAC = createAsyncAction(
  'GET_OVERVIEW_PRODUCTION_CARDS_STARTED',
  'GET_OVERVIEW_PRODUCTION_CARDS_SUCCESS',
  'GET_OVERVIEW_PRODUCTION_CARDS_FAILED'
)<IOverviewRequest, OverviewCards, Error>();

export const overviewCardsRequest = (
  { day, week, systems, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/overview-cards');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getOverviewProductionCardsAC.failure(error)),
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewProductionCardsAC.request({})),
        onSuccess: (data, dispatch) => {
          let dataWithoutTitles = {};
          Object.keys(data).forEach(objectTitle => {
            if (objectTitle === 'productivity') {
              data[objectTitle]['productivityUtilization'] = data[objectTitle]['utilization'];
              delete data[objectTitle]['utilization'];
            }
            dataWithoutTitles = { ...dataWithoutTitles, ...data[objectTitle] };
          });
          const mapApiToState = {
            totalProduction: 'totalProduction',
            productionTrend: 'productionTrend',
            productionRate: 'productionRate',
            productionRateTrend: 'productionRateTrend',
            loadingTime: 'loadingTime',
            loadingTimeTrend: 'loadingTimeTrend',
            darkImpressionsPercentage: 'darkImpressionsPercentage',
            darkImpressionsTrend: 'darkImpressionsTrend',
            trendUtilization: 'trendUtilization',
            trendPreparation: 'trendPreparation',
            utilization: 'utilization',
            preparation: 'preparation',
            productivity: 'productivity',
            productivityChange: 'productivityChange',
            printingTime: 'printingTime',
            printingTimeTrend: 'printingTimeTrend',
            productivityUtilization: 'productivityUtilization',
            utilizationTrend: 'productivityUtilizationTrend',
            availability: 'availability',
            averageAvailabilityTrend: 'availabilityTrend',
            errorDurationSeconds: 'errorDuration',
            errorDurationTrend: 'errorDurationTrend',
            maintenanceDurationSeconds: 'maintenanceDuration',
            maintenanceDurationTrend: 'maintenanceDurationTrend',
          };
          const converted = converter(
            dataWithoutTitles,
            mapApiToState,
            systemGroup
          ) as OverviewCards;
          Object.keys(UserSystemGroup).forEach(systemGroup => {
            if (converted[systemGroup]) {
              // converted[systemGroup].weeklyPercentage =
              //   converted[systemGroup].weeklyPercentage * 100;
              // converted[systemGroup].yearlyPercentage =
              //   converted[systemGroup].yearlyPercentage * 100;
              converted[systemGroup].darkImpressionsPercentage = (
                converted[systemGroup].darkImpressionsPercentage * 100
              ).toFixed(2);
              converted[systemGroup].utilization = (
                converted[systemGroup].utilization * 100
              ).toFixed(2);
              converted[systemGroup].preparation = (
                converted[systemGroup].preparation * 100
              ).toFixed(2);
              converted[systemGroup].productivityUtilization = (
                converted[systemGroup].productivityUtilization * 100
              ).toFixed(2);
            }
          });
          dispatch(getOverviewProductionCardsAC.success(converted));
        },
      },
    });
};

export const getOverviewImperssionsAvailabilityCardsAC = createAsyncAction(
  'GET_OVERVIEW_IMPRESSIONS_AVAILABILITY_CARDS_STARTED',
  'GET_OVERVIEW_IMPRESSIONS_AVAILABILITY_CARDS_SUCCESS',
  'GET_OVERVIEW_IMPRESSIONS_AVAILABILITY_CARDS_FAILED'
)<IOverviewRequest, Partial<OverviewCards>, Error>();

export const overviewImperssionsAvailabilityCardsRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/impressions-availability-cards');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) =>
          dispatch(getOverviewImperssionsAvailabilityCardsAC.failure(error)),
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewImperssionsAvailabilityCardsAC.request({})),
        onSuccess: (data, dispatch) => {
          let dataWithoutTitles = {};
          Object.keys(data).forEach(objectTitle => {
            if (objectTitle === 'productivity') {
              data[objectTitle]['productivityUtilization'] = data[objectTitle]['utilization'];
              delete data[objectTitle]['utilization'];
            }
            dataWithoutTitles = { ...dataWithoutTitles, ...data[objectTitle] };
          });
          const mapApiToState = {
            totalProduction: 'totalProduction',
            productionTrend: 'productionTrend',
            trendUtilization: 'trendUtilization',
            trendPreparation: 'trendPreparation',
            preparation: 'preparation',
            productivityUtilization: 'productivityUtilization',
            availability: 'availability',
            averageAvailabilityTrend: 'availabilityTrend',
            errorDurationSeconds: 'errorDuration',
            errorDurationTrend: 'errorDurationTrend',
            maintenanceDurationSeconds: 'maintenanceDuration',
            maintenanceDurationTrend: 'maintenanceDurationTrend',
            availabilityTotalDurationReported: 'availabilityTotalDurationReported',
            isNotReportingPeriod: 'isNotReportingPeriod',
          };
          const converted = converter(
            dataWithoutTitles,
            mapApiToState,
            systemGroup
          ) as OverviewCards;
          dispatch(getOverviewImperssionsAvailabilityCardsAC.success(converted));
        },
      },
    });
  }
};

export const getOverviewProductionRateCardAC = createAsyncAction(
  'GET_OVERVIEW_PRODUCTION_RATE_CARDS_STARTED',
  'GET_OVERVIEW_PRODUCTION_RATE_CARDS_SUCCESS',
  'GET_OVERVIEW_PRODUCTION_RATE_CARDS_FAILED'
)<IOverviewRequest, Partial<OverviewCards>, Error>();

export const overviewProductionRateRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/production-rate');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getOverviewProductionRateCardAC.failure(error)),
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewProductionRateCardAC.request({})),
        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            productionRate: 'productionRate',
            productionRateTrend: 'productionRateTrend',
            loadingTime: 'loadingTime',
            loadingTimeTrend: 'loadingTimeTrend',
            darkImpressionsPercentage: 'darkImpressionsPercentage',
            darkImpressionsTrend: 'darkImpressionsTrend',
            trendUtilization: 'trendUtilization',
            trendPreparation: 'trendPreparation',
            utilization: 'utilization',
            preparation: 'preparation',
          };
          const converted = converter(data, mapApiToState, systemGroup) as OverviewCards;
          Object.keys(UserSystemGroup).forEach(systemGroup => {
            if (converted[systemGroup]) {
              converted[systemGroup].darkImpressionsPercentage = (
                converted[systemGroup].darkImpressionsPercentage * 100
              ).toFixed(2);
              converted[systemGroup].utilization = (
                converted[systemGroup].utilization * 100
              ).toFixed(2);
              converted[systemGroup].preparation = (
                converted[systemGroup].preparation * 100
              ).toFixed(2);
            }
          });
          dispatch(getOverviewProductionRateCardAC.success(converted));
        },
      },
    });
  }
};

export const getOverviewProductivityCardAC = createAsyncAction(
  'GET_OVERVIEW_PRODUCTIVITY_CARDS_STARTED',
  'GET_OVERVIEW_PRODUCTIVITY_CARDS_SUCCESS',
  'GET_OVERVIEW_PRODUCTIVITY_CARDS_FAILED'
)<IOverviewRequest, Partial<OverviewCards>, Error>();

export const overviewProductivityRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/productivity');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getOverviewProductivityCardAC.failure(error)),
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewProductivityCardAC.request({})),
        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            productivity: 'productivity',
            productivityChange: 'productivityChange',
            printingTime: 'printingTime',
            printingTimeTrend: 'printingTimeTrend',
            utilization: 'productivityUtilization',
            utilizationTrend: 'productivityUtilizationTrend',
          };
          const converted = converter(data, mapApiToState, systemGroup) as OverviewCards;
          Object.keys(UserSystemGroup).forEach(systemGroup => {
            if (converted[systemGroup]) {
              converted[systemGroup].productivityUtilization = (
                converted[systemGroup].productivityUtilization * 100
              ).toFixed(2);
            }
          });
          dispatch(getOverviewProductivityCardAC.success(converted));
        },
      },
    });
  }
};

export const getOverviewAchievementsAC = createAsyncAction(
  'GET_OVERVIEW_ACHIEVEMENTS_STARTED',
  'GET_OVERVIEW_ACHIEVEMENTS_SUCCESS',
  'GET_OVERVIEW_ACHIEVEMENTS_FAILED'
)<IOverviewRequest, OverviewAchievements, Error>();

export const overviewAchievementsRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent = false,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/overview-report/achievements');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getOverviewAchievementsAC.failure(error)),
        onStarted: dispatch => {
          dispatch(getOverviewAchievementsAC.request({ isSilent }));
        },

        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            weekRecord: 'record',
            weeklyGoal: 'weeklyGoal',
            weeklyActual: 'weeklyCompleted',
            weeklyPercentage: 'weeklyPercentage',
            yearlyGoal: 'yearlyGoal',
            yearlyActual: 'yearlyCompleted',
            yearlyPercentage: 'yearlyPercentage',
          };
          const converted = converter(data, mapApiToState, systemGroup) as OverviewAchievements;
          Object.keys(UserSystemGroup).forEach(systemGroup => {
            if (converted[systemGroup]) {
              converted[systemGroup].weeklyPercentage =
                converted[systemGroup].weeklyPercentage * 100;
              converted[systemGroup].yearlyPercentage =
                converted[systemGroup].yearlyPercentage * 100;
            }
          });
          dispatch(getOverviewAchievementsAC.success(converted));
        },
      },
    });
  }
};

export const getOverviewProductionAllChartAC = createAsyncAction(
  'GET_OVERVIEW_PRODUCTION_ALL_CHART_STARTED',
  'GET_OVERVIEW_PRODUCTION_ALL_CHART_SUCCESS',
  'GET_OVERVIEW_PRODUCTION_ALL_CHART_FAILED'
)<IOverviewRequest, Partial<OverviewCharts>, Error>();
export const overviewProductionAllChartsRequest = (
  { day, week, systems, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/overview-graphs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getOverviewProductionAllChartAC.failure(error)),

        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewProductionAllChartAC.request({})),
        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            totalProductionGraph: 'productionChart',
            productionByHourGraph: 'speedChart',
            availability: 'availabilityChart',
            utilization: 'utilizationChart',
          };
          const mappedData = {};
          Object.keys(data).forEach(objectTitle => {
            mappedData[objectTitle] = data[objectTitle].records;
          });
          dispatch(
            getOverviewProductionAllChartAC.success(
              converter(mappedData, mapApiToState, systemGroup)
            )
          );
        },
      },
    });
};

export const getOverviewImpressionsAvailabilityChartsAC = createAsyncAction(
  'GET_OVERVIEW_IMPRESSIONS_AVAILABILITY_CHART_STARTED',
  'GET_OVERVIEW_IMPRESSIONS_AVAILABILITY_CHART_SUCCESS',
  'GET_OVERVIEW_IMPRESSIONS_AVAILABILITY_CHART_FAILED'
)<IOverviewRequest, Partial<OverviewCharts>, Error>();
export const overviewImpressionsAvailabilityChartsRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/impressions-availability-graphs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },

      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getOverviewImpressionsAvailabilityChartsAC.failure(error));
        },

        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewImpressionsAvailabilityChartsAC.request({})),
        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            totalProductionGraph: 'productionChart',
            availability: 'availabilityChart',
          };
          const mappedData = {};
          Object.keys(data).forEach(objectTitle => {
            mappedData[objectTitle] = data[objectTitle].records;
          });
          //*in case of for availability chart  recods have negative values like -1 we should delete this value from object. there is no posibility to do it on back end side
          mappedData['availability'].forEach((record: any) => {
            if (record.total < 0) {
              delete record.total;
            }
            if (record.totalAvg < 0) {
              delete record.totalAvg;
            }
          });
          //*in case of dtf we have to calculate for each record addition values for date field beacuse of there is no possibility to get it from api
          if (systemGroup === UserSystemGroup.DTF) {
            Object.keys(mappedData).forEach(graphName => {
              const records = mappedData[graphName];
              mappedData[graphName].records = normalizeGraphData({ records, day, week });
            });
          }
          dispatch(
            getOverviewImpressionsAvailabilityChartsAC.success(
              converter(mappedData, mapApiToState, systemGroup)
            )
          );
        },
      },
    });
  }
};

export const getOverviewSpeedChartAC = createAsyncAction(
  'GET_OVERVIEW_SPEED_CHART_STARTED',
  'GET_OVERVIEW_SPEED_CHART_SUCCESS',
  'GET_OVERVIEW_SPEED_CHART_FAILED'
)<IOverviewRequest, Partial<OverviewCharts>, Error>();
export const overviewSpeedChartRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,

  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/impressions-by-hour-matrix');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getOverviewSpeedChartAC.failure(error));
        },
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewSpeedChartAC.request({})),
        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            records: 'speedChart',
          };
          // *in case of dtf we have to calculate for each record addition values for date field beacuse of there is no possibility to get it from api
          if (systemGroup === UserSystemGroup.DTF) {
            data.records = normalizeGraphData({ records: data.records, day, week });
          }
          dispatch(getOverviewSpeedChartAC.success(converter(data, mapApiToState, systemGroup)));
        },
      },
    });
  }
};

export const getOverviewUtilizationChartAC = createAsyncAction(
  'GET_OVERVIEW_UTILIZATION_CHART_STARTED',
  'GET_OVERVIEW_UTILIZATION_CHART_SUCCESS',
  'GET_OVERVIEW_UTILIZATION_CHART_FAILED'
)<IOverviewRequest, Partial<OverviewCharts>, Error>();

export const overviewUtilizationChartRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/overview-report/utilization-productivity-matrix');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getOverviewUtilizationChartAC.failure(error));
        },
        onStarted: isSilent
          ? dispatch => {}
          : dispatch => dispatch(getOverviewUtilizationChartAC.request({})),
        onSuccess: (data, dispatch) => {
          const mapApiToState = {
            records: 'utilizationChart',
          };
          // *in case of dtf we have to calculate for each record addition values for date field beacuse of there is no possibility to get it from api
          if (systemGroup === UserSystemGroup.DTF) {
            data.records = normalizeGraphData({ records: data.records, day, week });
          }
          dispatch(
            getOverviewUtilizationChartAC.success(converter(data, mapApiToState, systemGroup))
          );
        },
      },
    });
  }
};
//*this request is for apollo group only
export const getOverviewReportMainRequestAC = createAsyncAction(
  'GET_OVERVIEW_MAIN_REQUEST_STARTED',
  'GET_OVERVIEW_MAIN_REQUEST_SUCCESS',
  'GET_OVERVIEW_MAIN_REQUEST_FAILED'
)<IOverviewRequest, any, Error>();

export const overviewReportMainRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/overview-report/impressionsKPIs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getOverviewReportMainRequestAC.failure(error));
        },
        onStarted: dispatch => dispatch(getOverviewReportMainRequestAC.request({ isSilent })),

        onSuccess: (data, dispatch, state) => {
          //* records comes from server in unordered way, we have to sort it by date field - date field format depends on granularity
          data.totalImpressionsMatrix.records = sortByGranularity({
            granularity: data.totalImpressionsMatrix.records[0].granularity,
            records: data.totalImpressionsMatrix.records,
          });
          data.utilizationProductivityMatrix.records = sortByGranularity({
            granularity: data.utilizationProductivityMatrix.records[0].granularity,
            records: data.utilizationProductivityMatrix.records,
          });
          data.impressionsByHourMatrix.records = sortByGranularity({
            granularity: data.impressionsByHourMatrix.records[0].granularity,
            records: data.impressionsByHourMatrix.records,
          });
          dispatch(getOverviewReportMainRequestAC.success({ ...data }));
        },
      },
    });
  }
};
export const getOverviewAvailabilityRequestAC = createAsyncAction(
  'GET_OVERVIEW_AVAILABILITY_REQUEST_STARTED',
  'GET_OVERVIEW_AVAILABILITY_REQUEST_SUCCESS',
  'GET_OVERVIEW_AVAILABILITY_REQUEST_FAILED'
)<IOverviewRequest, any, Error>();
export const overviewReportAvailabilityRequest = (
  { day, week, systems, custom, systemGroup }: IOverviewRequest,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/overview-report/availabilitiesKPIs');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getOverviewAvailabilityRequestAC.failure(error));
        },
        onStarted: dispatch => dispatch(getOverviewAvailabilityRequestAC.request({ isSilent })),
        onSuccess: (data, dispatch, state) => {
          //* records comes from server in unordered way, we have to sort it by date field - date field format depends on granularity
          data.availabilityMatrix.records = sortByGranularity({
            granularity: data.availabilityMatrix.records[0].granularity,
            records: data.availabilityMatrix.records,
          });
          dispatch(getOverviewAvailabilityRequestAC.success({ ...data }));
        },
      },
    });
  }
};

export const getMaxKeepAliveRequestAC = createAsyncAction(
  'GET_MAX_KEEP_ALIVE_REQUEST_STARTED',
  'GET_MAX_KEEP_ALIVE_REQUEST_SUCCESS',
  'GET_MAX_KEEP_ALIVE_REQUEST_FAILED'
)<IOverviewRequest, any, Error>();
export const getMaxKeepAliveRequest = (
  { day, week, month, quarter, year, custom, systems, systemGroup }: IOverviewRequest,
  isSilent,
  isRealTime = false
) => {
  const url = makeApiUrl('/api/reports/getMaxKeepAlive');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        headers: getRealTimeHeaders(isRealTime),
        params: {
          day,
          week,
          month,
          quarter,
          year,
          custom: customTimeframeParameters,
          systemGroupType: systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          error.systemGroup = systemGroup;
          dispatch(getMaxKeepAliveRequestAC.failure(error));
        },
        onStarted: dispatch =>
          dispatch(getMaxKeepAliveRequestAC.request({ isSilent, systemGroup })),
        onSuccess: (data, dispatch, state) => {
          dispatch(
            getMaxKeepAliveRequestAC.success({
              maxKeepAlive: data.maxKeepAliveValue,
              maxKeepAliveUtc: data.maxKeepAliveValueUtc,
              systemGroup,
            })
          );
        },
      },
    });
  }
};
