import React from 'react';
import { FilterCalenderModalProps } from './FilterCalendarModalContainer';
import MobileFilterCalendarModal from './MobileFilterCalendarModal';
import DesktopFilterCalendarModal from './DesktopFilterCalendarModal';

const FilterCalenderModal = React.memo((props: FilterCalenderModalProps) => {
  const {
    selectedSystemGroup,
    isOpen,
    filterState,
    filterData,
    isMobile,
    pbiReportHeight,
    screenHeight,
    screenWidth,
    timeframe,
    selectedDay,
    startDate,
    endDate,
    closeCalendarModal,
    applyMobileFilters,
    setFilterFromUriQuery,
    setTimeframe,
    setActiveDate,
  } = props;
  const { defaultQueriesFilter } = filterState;
  const onDone = () => {
    applyMobileFilters(true);
    closeCalendarModal();
  };

  const onClose = () => {
    setFilterFromUriQuery(defaultQueriesFilter);
    applyMobileFilters(false);
    closeCalendarModal();
  };
  return isMobile ? (
    <MobileFilterCalendarModal
      screenWidth={screenWidth}
      pbiReportHeight={pbiReportHeight}
      screenHeight={screenHeight}
      startDate={startDate}
      endDate={endDate}
      selectedDay={selectedDay}
      onClose={onClose}
      onDone={onDone}
      closeCalendarModal={closeCalendarModal}
      setActiveDate={setActiveDate}
      isOpen={isOpen}
      timeframe={timeframe}
      filterState={filterState}
      filterData={filterData[selectedSystemGroup]}
    />
  ) : (
    <DesktopFilterCalendarModal onClose={onClose} onDone={onDone} isOpen={isOpen} />
  );
});

export default FilterCalenderModal;
