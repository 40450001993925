import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import MuiSwitch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import MuiInfoIcon from '@material-ui/icons/InfoOutlined';
import MuiPopover from '@material-ui/core/Popover';

const SwitchLabel = styled(Typography)`
  font-family: Rubik, sans-serif;
  font-size: 14px;
  color: #7f8fa4;
  width: 9rem;
  margin-top: 2px;

  &.checked {
    color: #354052;
  }
`;

const InfoIcon = styled(MuiInfoIcon)`
  color: #208cd7;
  margin-top: 4px;
`;

const Popover = styled(MuiPopover)`
  pointer-events: none;

  .paper {
    box-shadow: none;
    background: none;
  }
`;

const PopoverPaper = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 9px;
  line-height: 11px;
  color: #354052;
  display: flex;
  align-items: center;
  padding: 4px;

  .arrow {
    position: relative;
    left: 5px;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotate(45deg);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .text {
    padding: 7px;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 120px;
  }
`;

interface SwitchProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onClick?: (event: React.SyntheticEvent) => void;
  id?: string;
  label?: string;
  labelStyle?: React.CSSProperties;
  disabled?: boolean;
  justify?: 'space-between' | 'center' | 'flex-start';
  tip?: string;
}

export default function Switch(props: SwitchProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const { onClick, onChange, label, tip, justify, labelStyle, ...rest } = props;
  const { id, checked } = rest;

  return (
    <Grid
      container
      spacing={label || tip ? 2 : 0}
      alignItems="center"
      justify={justify}
      onClick={onClick}>
      {label && (
        <Grid item>
          <SwitchLabel className={checked ? 'checked' : ''} style={labelStyle}>
            <label htmlFor={id}>{label}</label>
          </SwitchLabel>
        </Grid>
      )}
      <Grid item>
        <MuiSwitch color="primary" onChange={onChange} checked={checked} />
      </Grid>
      {tip && (
        <Grid item>
          <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <InfoIcon />
          </div>
          <Popover
            classes={{ paper: 'paper' }}
            open={isOpen}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            disableRestoreFocus>
            <PopoverPaper>
              <div className="arrow" />
              <div className="text">{tip}</div>
            </PopoverPaper>
          </Popover>
        </Grid>
      )}
    </Grid>
  );
}
