import React from 'react';

import history from '../helpers/navigation';
import errorsHandler from './errors-handler-service';
import { AuthServiceImpl } from './auth-service';
import { GenericPropsType } from 'store/storeTypes';

export const authService = new AuthServiceImpl(errorsHandler, history);

export const AuthContext = React.createContext(authService);

export function AuthProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>;
}

export function withAuth(Component: React.ComponentType<GenericPropsType>) {
  return function HocComponent(props: GenericPropsType) {
    return (
      <AuthContext.Consumer>
        {authService => <Component authService={authService} {...props} />}
      </AuthContext.Consumer>
    );
  };
}
