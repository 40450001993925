import React from 'react';
import { TimeFrame } from 'models/ReportData';
import MobileDayPicker from './MobileDayPicker';
import MobileWeekPicker from './MobileWeekPicker';
import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import appTheme from '../../theme';
import { appearanceEffect } from 'globalStyle';
import MobileMonthPicker from './MobileMonthPicker';
import MobileQuarterPicker from './MobileQuarterPicker';
import MobileYearPicker from './MobileYearPicker';

export const activeTheme = (theme: {
  breakpoints: { only: (string: string) => string | number | symbol };
}) =>
  createMuiTheme({
    ...appTheme,
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          width: '100%',
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: '600px',
          alignItems: 'stretch;',
          padding: '0 20px',
        },
      },
      MuiPickersCalendar: {
        week: {
          justifyContent: 'center',
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: { justifyContent: 'center' },
      },
      MuiPickersDay: {
        daySelected: {
          color: 'white',
        },
      },
      MuiTypography: {
        alignCenter: {
          fontFamily: 'Rubik',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '18px',
          textTransform: 'uppercase',
          color: '#1878BA',
        },
      },
    },
  });

export const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 40px;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
export const PickerWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
export type MobileDatePickerProps = {
  timeframe?: TimeFrame;
  startDate?: Date;
  endDate?: Date;
  selectedDay?: Date;
  setActiveDate?: (props: Date) => void;
  closeCalendarModal?: () => void;
};

const MobileDatePicker = (props: MobileDatePickerProps) => {
  const {
    timeframe = 'day',
    startDate,
    endDate,
    selectedDay,
    setActiveDate,
    closeCalendarModal,
  } = props;
  if (timeframe === 'day') {
    return (
      <MobileDayPicker
        startDate={startDate}
        endDate={endDate}
        selectedDay={selectedDay}
        setActiveDate={setActiveDate}
        closeCalendarModal={closeCalendarModal}
      />
    );
  }
  if (timeframe === 'week') {
    return <MobileWeekPicker closeCalendarModal={closeCalendarModal} />;
  }
  if (timeframe === 'month') {
    return <MobileMonthPicker closeCalendarModal={closeCalendarModal} />;
  }
  if (timeframe === 'quarter') {
    return <MobileQuarterPicker closeCalendarModal={closeCalendarModal} />;
  }
  if (timeframe === 'year') {
    return <MobileYearPicker closeCalendarModal={closeCalendarModal} />;
  }
};
export default MobileDatePicker;
