import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RouteComponentProps } from 'react-router';
import { AuthSupportProps } from 'contracts/auth';
import { Welcome } from './Welcome';
import { getPortalApiUrl } from 'actions/appUserActions';
import { Store } from 'store/storeTypes';

const mapStateToProps = (state: Store) => ({
  apiUrl: state.appUser.apiUrl,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPortalApiUrl,
    },
    dispatch
  );

export type WelcomeProps = RouteComponentProps<Record<string, string>> &
  AuthSupportProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
