import { globalColors } from 'globalStyle';
import { notUndefiend, valueAsString } from 'helpers/conversions';
import React from 'react';
import styled from 'styled-components';
import { StatsData } from '../Card';
import { productionIcon } from '../Icons';
import { InnerCardContent, Spinner } from '../Styles';
import { TimeFrame, TimeFrameEnum } from 'models/ReportData';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { getDescriptionTextForCustomTimeframe } from 'helpers/overviewUtils';

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
const UpperRow = styled.div`
  display: flex;
  box-sizing: border-box;

  flex-direction: column;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #e6e9ed;
`;
export const OverviewTopCardsUpperRowContent = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const OverviewTopCardsValue = styled.div`
  font-weight: 700;
  font-size: 32px;
  margin-left: 7px;
  color: ${globalColors.regular.text.primary};
  line-height: 26px;
`;

export const OverviewTopCardsUnits = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin-top: 12px;
  margin-right: 5px;
  color: ${globalColors.regular.text.primary};
  line-height: 14px;
`;
export const OverviewTopCardsBottomRow = styled.div`
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  max-height: 46px;
`;
export const OverviewTopCardsDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${globalColors.regular.text.grey};
  line-height: 14px;
`;
type ChartsHeadersForCustomTimeframeType = {
  timeframe: TimeFrame;
  customTimeframeProperties: CustomTimeFrameProperties;
};
const DesktopTotalImpressionCard = props => {
  const {
    stats,
    description,
    value,
    selectedSystemGroup,
    units,
    selectedSystemGroupText,
    timeframe,
    customTimeframeProperties,
  } = props;
  const [isNegative, setIsNegative] = React.useState(false);
  React.useEffect(() => {
    if (notUndefiend([stats])) {
      if (stats! < 0) {
        setIsNegative(true);
      } else if (description === 'Average Availability' && value >= 0.999) {
        setIsNegative(false);
      } else {
        setIsNegative(false);
      }
    }
  }, [stats]); // eslint-disable-line react-hooks/exhaustive-deps

  const icons = productionIcon(selectedSystemGroup, 30, 32);
  const getTimeframeTextKey = () => {
    return timeframe && timeframe.includes('lastUpdate') ? 'last' : 'weekDay';
  };
  const statsDescription =
    selectedSystemGroupText.impression.statsDescription[getTimeframeTextKey()];

  return notUndefiend([value]) ? (
    <InnerCardContent data-automationid="TotalProduction Card">
      <CardContent>
        <UpperRow>
          <OverviewTopCardsUpperRowContent>
            {isNegative ? icons.negative : icons.positive}
            <OverviewTopCardsValue data-testid="total-impression-value">
              {valueAsString(value, false)}
            </OverviewTopCardsValue>
            <OverviewTopCardsUnits data-testid="total-impression-units">
              {units}
            </OverviewTopCardsUnits>
          </OverviewTopCardsUpperRowContent>
          <OverviewTopCardsDescription data-testid="total-impression-description">
            {description}
          </OverviewTopCardsDescription>
        </UpperRow>
        <OverviewTopCardsBottomRow>
          {notUndefiend([stats]) && StatsData(stats!)}{' '}
          <div>
            {timeframe === TimeFrameEnum.custom
              ? getDescriptionTextForCustomTimeframe({ timeframe, customTimeframeProperties })
              : statsDescription}
          </div>
        </OverviewTopCardsBottomRow>
      </CardContent>{' '}
    </InnerCardContent>
  ) : (
    Spinner(60)
  );
};
export default DesktopTotalImpressionCard;
