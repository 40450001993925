import { combineReducers, Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { Entries, Ids } from '../../store/storeTypes';
import { SiteData } from '../../models/SiteData';

const { loadSitesSuccess } = actions.scopeApi;

const siteById = createReducer<Entries<SiteData>, RootAction>({}).handleAction(
  loadSitesSuccess,
  (state, action) => {
    if (action.payload) {
      const newState: Entries<SiteData> = {};

      action.payload.forEach((site: SiteData) => {
        newState[site.id] = site;
      });

      return newState;
    }

    return state;
  }
);

const siteAllIds = createReducer<Ids, RootAction>([]).handleAction(
  loadSitesSuccess,
  (state, action) => {
    if (action.payload) {
      const newState: Ids = [];

      action.payload.forEach((site: SiteData) => {
        newState.push(site.id);
      });

      return newState;
    }

    return state;
  }
);

const siteEntityReducer: Reducer = combineReducers({
  byId: siteById,
  allIds: siteAllIds,
});

export default siteEntityReducer;
