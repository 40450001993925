import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RouteComponentProps } from 'react-router';
import { AuthSupportProps } from 'contracts/auth';
import { Store } from 'store/storeTypes';
import SuperUserLoginPage from './SuperUserLoginPage';

const mapStateToProps = (state: Store) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
export type WelcomeProps = RouteComponentProps<Record<string, string>> &
  AuthSupportProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(SuperUserLoginPage);
