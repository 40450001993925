import { systemGroupSelector } from './../ui/systemGroupUiReducer';
import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';
import { actions, RootAction } from 'actions';
import { ReportFilterState, ReportFilterStateByType, Store } from 'store/storeTypes';
import { normalizeSelectedNodes } from 'components/_common/TreeSelect/treeHelpers';
import { getDateForTimeframe, isHistorical } from '../../helpers/date';
import {
  applyMobileFiltersAC,
  getDefaultFilterQueries,
  openFilterPopUpMenu,
  setActiveDateAC,
  setNodeFoldedStateAC,
  setSelectedSystemIdsAC,
  setTimeframeAC,
  toggleFilterRowSystemsSelection,
  toggleFilterModelRowSystemsSelection,
  toggleFilterSerialRowSystemSelection,
  setFilterValue,
  unselectSerialsByModel,
  toggleAllSystemsSelection,
  setCustomTimeFrameHoursToDefaultValue,
} from 'actions/reportFilterActions';
import _ from 'lodash';
import { convertTo24HourFormat, mergeCopyArrays } from 'helpers/conversions';
import { endDateSelector } from './filterDataReducer';
import { startOfWeek } from 'date-fns';
import { MainReportTypes } from 'models/ReportData';
import { TimeFrameEnum } from 'models/ReportData';

const {
  setFilterSystemsStateAC,
  setInitFilterStateAC,
  setFilterFromUriQueryAC,
} = actions.reportFilter;

const FILTER_INIT_STATE: ReportFilterState = {
  selectedSiteIds: [],
  selectedModelIds: [],
  selectedSystemSerials: [],
  forceUncheckSiteIds: [],
  forceUncheckModelIds: [],
  timeframe: undefined,
  customTimeFrameStartDate: undefined,
  customTimeFrameStartHour: undefined,
  customTimeFrameEndDate: undefined,
  customTimeFrameEndHour: undefined,
  isShiftModeSelected: false,
  foldedSiteIds: [],
  foldedModelIds: [],
  stateInitialized: false,
  isApplyMobileFilter: true,
  selectedDate: undefined,
  defaultQueriesFilter: null,
  filterValue: '',
  isSelectedSystemsEverReported: null,
  isTimeFrameWasChangedFromJobAnalysisPage: false,
};

const INIT_STATE: ReportFilterStateByType = {
  DTF: _.cloneDeep(FILTER_INIT_STATE),
  DTG: _.cloneDeep(FILTER_INIT_STATE),
  Apollo: _.cloneDeep(FILTER_INIT_STATE),
};

const filterStateReducer = createReducer<ReportFilterStateByType, any>(INIT_STATE)
  .handleAction(toggleAllSystemsSelection, (state, action) => {
    const { selectedSystemGroup, filterData, filterState } = action.payload;
    const selectedIds = state[selectedSystemGroup].selectedSystemSerials;
    if (selectedIds.length === 0) {
      //selectAll
      const allSystems = filterData.systems;
      const isEverReported = allSystems.some(system => system.isEverReported === true);
      const newModelIds = [...new Set(allSystems.map(system => system.modelId))];
      const newSiteIds = [...new Set(allSystems.map(system => system.siteId))];
      const newSerialIds = allSystems.map(system => system.name);
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSiteIds],
          selectedModelIds: [...newModelIds],
          selectedSystemSerials: [...newSerialIds],
        },
      };
    } else {
      //unselectAll
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: false,
          selectedSiteIds: [],
          selectedModelIds: [],
          selectedSystemSerials: [],
        },
      };
    }
  })
  .handleAction(unselectSerialsByModel, (state, action) => {
    let isEverReported = false;
    const { modelId, selectedSystemGroup, filterData } = action.payload;
    const selectedIds = state[selectedSystemGroup].selectedSystemSerials;
    const selectedSystems = filterData.systems.filter(system => selectedIds.includes(system.name));
    const newSelectedSystems = selectedSystems.filter(system => system.modelId !== modelId);
    const newSerialIds = newSelectedSystems.map(system => {
      //*if at least one system selected with status isEverReported: true in result goes true
      if (system.isEverReported) {
        isEverReported = true;
      }
      return system.name;
    });
    const newModelIds = [...new Set(newSelectedSystems.map(system => system.modelId))];
    const newSiteIds = [...new Set(newSelectedSystems.map(system => system.siteId))];

    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isSelectedSystemsEverReported: isEverReported,
        selectedSiteIds: [...newSiteIds],
        selectedModelIds: [...newModelIds],
        selectedSystemSerials: [...newSerialIds],
      },
    };
  })

  .handleAction(toggleFilterSerialRowSystemSelection, (state, action) => {
    const {
      siteId,
      modelId,
      serialId,
      selectedSystemGroup,
      filterState,
      filterData,
    } = action.payload;
    const allSelectedIds = filterState.selectedSystemSerials;
    //*if selected - unselect
    if (allSelectedIds.includes(serialId)) {
      let isEverReported = false;
      let newSiteIds;
      let newModelIds;
      let newSerialIds;
      //all serials without unselected serial
      newSerialIds = allSelectedIds.filter(selectedId => {
        if (selectedId !== serialId) {
          //*check if at least one system has status isEverReported:true -  it goes to result
          const system = filterData.systems.find(sys => sys.name === selectedId);
          if (system.isEverReported) {
            isEverReported = true;
          }
          //*get array of serials without selected
          return true;
        }
      });

      //*check if need unselect model;
      const allSerialsIdOfCurrentModel = filterData.systems
        .filter(system => system.modelId === modelId)
        .map(system => system.name);
      let allSelectedSerialsOfCurrentModel = new Array();
      for (let i = 0; i < allSerialsIdOfCurrentModel.length; i++) {
        if (allSelectedIds.includes(allSerialsIdOfCurrentModel[i])) {
          allSelectedSerialsOfCurrentModel.push(allSerialsIdOfCurrentModel[i]);
        }
      }
      const selectedModelIds = filterState.selectedModelIds;
      newModelIds =
        allSelectedSerialsOfCurrentModel.length === 1
          ? selectedModelIds.filter(selectedModelId => selectedModelId !== modelId)
          : selectedModelIds;
      //*check if need unselect site;
      const allSerialsOfCurrentSite = filterData.systems
        .filter(system => system.siteId === siteId)
        .map(system => system.name);
      let allSelectedSerialsOfCurrentSite = new Array();
      for (let i = 0; i < allSerialsOfCurrentSite.length; i++) {
        if (allSelectedIds.includes(allSerialsOfCurrentSite[i])) {
          allSelectedSerialsOfCurrentSite.push(allSerialsOfCurrentSite[i]);
        }
      }
      const selectedSiteIds = filterState.selectedSiteIds;
      newSiteIds =
        allSelectedSerialsOfCurrentSite.length === 1
          ? selectedSiteIds.filter(selectedSiteId => selectedSiteId !== siteId)
          : selectedSiteIds;
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSiteIds],
          selectedModelIds: [...newModelIds],
          selectedSystemSerials: [...newSerialIds],
        },
      };
    }
    //if not selected -select
    else {
      let isEverReported = false;
      let newSiteIds;
      let newModelIds;
      let newSerialIds;

      //adding serial number selection
      newSerialIds = [serialId, ...allSelectedIds];
      //*if at least 1 selected system has status isEverReported: true - it goes to result
      newSerialIds.forEach(serialId => {
        const system = filterData.systems.find(system => system.name === serialId);
        if (system.isEverReported) {
          isEverReported = true;
        }
      });
      //check if need add selection to modelsIds
      const allcurrentModelSerialsIds = filterData.systems
        .filter(system => system.modelId === modelId)
        .map(system => system.name);
      let allSelectedSerialsOfCurrentModel = new Array();
      for (let i = 0; i < allcurrentModelSerialsIds.length; i++) {
        if (newSerialIds.includes(allcurrentModelSerialsIds[i])) {
          allSelectedSerialsOfCurrentModel.push(allcurrentModelSerialsIds[i]);
        }
      }
      const selectedModelIds = filterState.selectedModelIds;
      newModelIds =
        allSelectedSerialsOfCurrentModel.length === 1
          ? [...selectedModelIds, modelId]
          : selectedModelIds;
      //check if need add selection to siteIds
      const allCurrentSiteSerialIds = filterData.systems
        .filter(system => system.siteId === siteId)
        .map(system => system.name);
      let allSelectedSerialsOfCurrentSite = new Array();

      for (let i = 0; i < allCurrentSiteSerialIds.length; i++) {
        if (newSerialIds.includes(allCurrentSiteSerialIds[i])) {
          allSelectedSerialsOfCurrentSite.push(allCurrentSiteSerialIds[i]);
        }
      }
      const selectedSiteIds = filterState.selectedSiteIds;
      newSiteIds =
        allSelectedSerialsOfCurrentSite.length === 1
          ? [...selectedSiteIds, siteId]
          : selectedSiteIds;
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSiteIds],
          selectedModelIds: [...newModelIds],
          selectedSystemSerials: [...newSerialIds],
        },
      };
    }
  })
  .handleAction(toggleFilterModelRowSystemsSelection, (state, action) => {
    const { selectedSystemGroup, modelId, siteId, filterData, filterState } = action.payload;
    const selectedModelIds = filterState.selectedModelIds;
    const currentModelId = modelId;
    //*if model row already selected - this block does unselection of model row and site row if needed
    if (selectedModelIds.includes(currentModelId)) {
      // unseelect model
      const newModelIds = selectedModelIds.filter(selectedId => selectedId !== currentModelId);

      // unselect serials
      const selectedSerials = filterState.selectedSystemSerials;
      const currentSerialIds = filterData.systems
        .filter(system => system.modelId === modelId)
        .map(system => system.name);
      const newSerialIds = selectedSerials.filter(id => !currentSerialIds.includes(id));
      //*if at least one selected system has status isEverReported:true - it goes to result
      let isEverReported = false;
      newSerialIds.forEach(serialId => {
        const sys = filterData.systems.find(system => system.name === serialId);
        if (sys.isEverReported) {
          isEverReported = true;
        }
      });

      // check if there more selected serials in site if no unselect site
      const selectedSiteIds = filterState.selectedSiteIds;
      const currentSiteId = filterData.systems.find(system =>
        currentSerialIds.includes(system.name)
      ).siteId;

      const allSiteSerials = filterData.systems
        .filter(system => system.siteId === currentSiteId)
        .map(system => system.name);
      let isAtLeastOnePrinterInSiteSelected;
      allSiteSerials.forEach(element => {
        if (newSerialIds.includes(element)) {
          isAtLeastOnePrinterInSiteSelected = true;
        }
      });
      const newSelectedSiteIds = selectedSiteIds.filter(id => id !== currentSiteId);

      const newSiteIds = isAtLeastOnePrinterInSiteSelected ? selectedSiteIds : newSelectedSiteIds;
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSiteIds],
          selectedModelIds: [...newModelIds],
          selectedSystemSerials: [...newSerialIds],
        },
      };
      //*if model row is not selected yet - model selection and site check
    } else {
      let isEverReported = false;
      let newSiteIds;
      let newModelIds;
      let newSerialIds;

      const currentModelId = modelId;
      const currentSiteId = siteId;
      //siteId check - if in site no selected serials except curently selected - we need to add site id to all selected siteIds else newSiteIds = alreadyselectedSiteIds
      const allSelectedSiteIds = filterState.selectedSiteIds;

      const allSellectedSerials = filterState.selectedSystemSerials;
      const allcurrentSiteSerials = filterData.systems
        .filter(system => system.siteId === currentSiteId)
        .map(system => system.name);
      let isAtLeastOneSerialInSiteSelected;
      for (let i = 0; i < allSellectedSerials.length; i++) {
        if (allcurrentSiteSerials.includes(allSellectedSerials[i])) {
          isAtLeastOneSerialInSiteSelected = true;
        }
      }
      newSiteIds = isAtLeastOneSerialInSiteSelected
        ? allSelectedSiteIds
        : [currentSiteId, ...allSelectedSiteIds];
      //model Selection
      const allModelsSelected = filterState.selectedModelIds;
      newModelIds = [currentModelId, ...allModelsSelected];
      //serials selection by model;
      const allModelSerialIds = filterData.systems
        .filter(system => system.modelId === currentModelId)
        .map(system => system.name);
      const allSelectedIds = filterState.selectedSystemSerials;
      newSerialIds = [...allModelSerialIds, ...allSelectedIds];
      //*if at least one selected system has status isEverReported:true - it goes to result
      newSerialIds.forEach(serialId => {
        const sys = filterData.systems.find(system => system.name === serialId);
        if (sys.isEverReported) {
          isEverReported = true;
        }
      });
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSiteIds],
          selectedModelIds: [...newModelIds],
          selectedSystemSerials: [...newSerialIds],
        },
      };
    }
  })

  .handleAction(toggleFilterRowSystemsSelection, (state, action) => {
    const { filterState, filterData, siteId, selectedSystemGroup } = action.payload;
    const selectedSitesIds = state[selectedSystemGroup].selectedSiteIds;
    const currentSiteId = siteId;
    const isCurrentSiteAlreadySelected = selectedSitesIds.includes(currentSiteId);
    const selectedIds = [...filterState.selectedSystemSerials];
    const siteSerials = filterData.systems
      .filter(system => system.siteId === siteId)
      .map(system => system.name);
    const isSiteFullySelected = siteSerials.some(id => selectedIds.includes(id));

    //*if row already selected remove selection from row
    if (isCurrentSiteAlreadySelected && isSiteFullySelected) {
      //*goes to result
      const newSiteIds = selectedSitesIds.filter(siteId => siteId !== currentSiteId);
      const allSelectedSerials = [...filterState.selectedSystemSerials];
      //*if at least one selected system has status isEverReported:true - it goes to result
      let isEverReported = false;
      const allSelectedSerialsWithoutCurrentSiteSerials = allSelectedSerials.filter(
        selectedSerial => !siteSerials.includes(selectedSerial)
      );
      allSelectedSerialsWithoutCurrentSiteSerials.forEach(selectedSerial => {
        const sys = filterData.systems.find(system => system.name === selectedSerial);
        if (sys.isEverReported) {
          isEverReported = true;
        }
      });

      const allSiteSystemSerials = filterData.systems
        .filter(system => system.siteId === siteId)
        .map(system => system.name);
      //*goes to result
      const newSiteSistemIds = allSelectedSerials.filter(id => !allSiteSystemSerials.includes(id));

      const allModelsSelectedIds = filterState.selectedModelIds;
      const allSiteModelIds = filterData.models
        .filter(model => model.siteId === siteId)
        .map(model => model.id);
      //*goes to result
      const newModelIds = allModelsSelectedIds.filter(id => !allSiteModelIds.includes(id));
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSiteIds],
          selectedModelIds: [...newModelIds],
          selectedSystemSerials: [...newSiteSistemIds],
        },
      };
    } else {
      const selectedSiteIds = state[selectedSystemGroup].selectedSiteIds;
      const newSelectedSiteIds = [siteId, ...selectedSiteIds];

      const selectedSiteModels = [...filterState.selectedModelIds];
      const currentModelIds = filterData.models
        .filter(model => model.siteId === siteId)
        .map(model => model.id);
      const newSelectedModelIds = [...currentModelIds, ...selectedSiteModels];

      const selectedSerialIds = filterState.selectedSystemSerials;
      const currentSystemSerials = filterData.systems
        .filter(system => system.siteId === siteId)
        .map(system => system.name);
      const newSelectedSerials = [...currentSystemSerials, ...selectedSerialIds];
      //*if at least one selected system has status isEverReported:true - it goes to result
      let isEverReported = false;
      newSelectedSerials.forEach(serialId => {
        const sys = filterData.systems.find(system => system.name === serialId);
        if (sys.isEverReported) {
          isEverReported = true;
        }
      });
      return {
        ...state,
        [selectedSystemGroup]: {
          ...state[selectedSystemGroup],
          isSelectedSystemsEverReported: isEverReported,
          selectedSiteIds: [...newSelectedSiteIds],
          selectedModelIds: [...newSelectedModelIds],
          selectedSystemSerials: [...newSelectedSerials],
        },
      };
    }
  })
  .handleAction(setFilterValue, (state, action) => {
    const { value, selectedSystemGroup } = action.payload;

    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        filterValue: value,
      },
    };
  })
  .handleAction(setInitFilterStateAC, (state, action) => {
    const {
      selectedSystemGroup,
      filterData: { models, endDate },
    } = action.payload;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = endDate ? new Date(endDate.getTime()) : today;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        foldedModelIds: models.map(m => m.id),
        selectedDate: selectedDate,
        stateInitialized: true,
      },
    };
  })
  .handleAction(setCustomTimeFrameHoursToDefaultValue, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        customTimeFrameStartHour: 0,
        customTimeFrameEndHour: 23,
      },
    };
  })
  .handleAction(setTimeframeAC, (state, action) => {
    const { selectedSystemGroup, timeframe, startDate, activeReport } = action.payload;
    const { selectedDate } = state[selectedSystemGroup];
    const newDate = getDateForTimeframe(selectedDate, timeframe);
    let resultDate = newDate < startDate ? startDate : newDate;
    if (timeframe === 'week') {
      startOfWeek(resultDate, { weekStartsOn: 1 });
    }
    const customStartHour =
      timeframe !== TimeFrameEnum.custom
        ? null
        : activeReport === MainReportTypes.Jobs
        ? 0
        : convertTo24HourFormat(
            action?.payload?.customTimeFrameProperties?.customTimeFrameStartHour,
            action.payload.customTimeFrameProperties.customStartTimeModificator
          );
    const customEndHour =
      timeframe !== TimeFrameEnum.custom
        ? null
        : activeReport === MainReportTypes.Jobs
        ? 23
        : convertTo24HourFormat(
            action?.payload?.customTimeFrameProperties?.customTimeFrameEndHour,
            action.payload.customTimeFrameProperties.customEndTimeModificator
          );
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        timeframe,
        selectedDate:
          timeframe !== 'custom'
            ? resultDate
            : action.payload.customTimeFrameProperties.customTimeFrameStartDate,
        isTimeFrameWasChangedFromJobAnalysisPage: activeReport === 'jobs' && timeframe !== 'week',
        customTimeFrameEndHour: customEndHour,

        customTimeFrameStartHour: customStartHour,

        customTimeFrameEndDate:
          timeframe !== 'custom'
            ? null
            : action.payload.customTimeFrameProperties.customTimeFrameEndDate,
        customTimeFrameStartDate:
          timeframe !== 'custom'
            ? null
            : action.payload.customTimeFrameProperties.customTimeFrameStartDate,
      },
    };
  })

  .handleAction(setActiveDateAC, (state, action) => {
    const { selectedSystemGroup, timeframe, date } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        timeframe,
        selectedDate: getDateForTimeframe(date, timeframe),
      },
    };
  })

  .handleAction(setFilterFromUriQueryAC, (state, action) => {
    const {
      selectedDate,
      timeframe,
      selectedSiteIds,
      selectedModelIds,
      selectedSystemSerials,
      selectedSystemGroup,
    } = action.payload;

    const result = {
      timeframe,
      selectedDate: selectedDate
        ? new Date(selectedDate)
        : state[selectedSystemGroup]?.selectedDate,
      selectedSiteIds: selectedSiteIds,
      selectedModelIds: selectedModelIds,
      selectedSystemSerials: selectedSystemSerials,
    };

    const cleanedResult = _.pickBy(result, _.identity);

    return {
      ...state,
      [selectedSystemGroup]: _.mergeWith(
        _.cloneDeep(state[selectedSystemGroup]),
        { ...cleanedResult },
        mergeCopyArrays
      ),
    };
  })

  .handleAction(setFilterSystemsStateAC, (state, action) => {
    const { selectedNodes, sites, models, systems, selectedSystemGroup } = action.payload;

    const {
      rootIds: selectedSiteIds,
      parentIds: selectedModelIds,
      childIds: selectedSystemSerials,
    } = normalizeSelectedNodes(
      selectedNodes,
      true,
      sites,
      models.map(m => ({ id: m.id, name: m.name, rootId: m.siteId })),
      systems.map(s => ({
        id: s.name,
        name: s.name,
        rootId: s.siteId,
        parentId: s.modelId,
      }))
    );

    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        selectedSiteIds,
        selectedModelIds,
        selectedSystemSerials,
      },
    };
  })

  .handleAction(setNodeFoldedStateAC, (state, action) => {
    const { node, selectedSystemGroup } = action.payload;
    const arrayName = node._depth === 0 ? 'foldedSiteIds' : 'foldedModelIds';
    const foldedArray = [...state[selectedSystemGroup]![arrayName]];

    if (node.expanded) {
      const i = foldedArray.indexOf(node.value);
      if (i !== -1) foldedArray.splice(i, 1);
    } else {
      foldedArray.includes(node.value) || foldedArray.push(node.value);
    }

    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        [arrayName]: foldedArray,
      },
    };
  })

  .handleAction(applyMobileFiltersAC, (state, action) => {
    const { selectedSystemGroup, isApplyMobileFilter } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isApplyMobileFilter,
      },
    };
  })

  .handleAction(getDefaultFilterQueries, (state, action) => {
    return {
      ...state,
      defaultQueriesFilter: action.payload,
    };
  })
  .handleAction(setSelectedSystemIdsAC, (state, action) => {
    const { selectedSystemGroup, selectedSystemSerials } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        selectedSystemSerials: [...selectedSystemSerials],
      },
    };
  })
  .handleAction(openFilterPopUpMenu, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      [selectedSystemGroup]: {
        ...state[selectedSystemGroup],
        isFilterPopUpOpen: !state[selectedSystemGroup].isFilterPopUpOpen,
      },
    };
  });

export default filterStateReducer;
//*selectors ===> ===> selectors
const reportsSelector = (state: Store) => {
  return state.reports;
};

export const filterStateSelector = createSelector([reportsSelector], reports => {
  return reports['filterState'];
});
export const activeReprotSelector = createSelector([reportsSelector], reports => {
  return reports['activeReport'];
});
export const filterStateBySystemGroup = createSelector(
  [filterStateSelector, systemGroupSelector],
  (filterState, systemGroup) => {
    return filterState[systemGroup];
  }
);
export const customTimeFramePropertiesSelector = createSelector(
  [filterStateBySystemGroup],
  filterState => {
    return {
      isShiftModeSelected: filterState?.isShiftModeSelected,
      customTimeFrameStartDate: filterState?.customTimeFrameStartDate,
      customTimeFrameStartHour: filterState?.customTimeFrameStartHour,
      customTimeFrameEndDate: filterState?.customTimeFrameEndDate,
      customTimeFrameEndHour: filterState?.customTimeFrameEndHour,
    };
  }
);
export const isTimeFrameWasChangedFromJobAnalysisPageSelector = createSelector(
  [filterStateBySystemGroup],
  filterState => {
    return filterState['isTimeFrameWasChangedFromJobAnalysisPage'];
  }
);
export const filterValueBySystemGroupSelector = createSelector(
  [filterStateBySystemGroup],
  filterState => {
    return filterState['filterValue'];
  }
);
export const isStateInitializedBySystemGroup = createSelector(
  [filterStateBySystemGroup],
  filterState => {
    return filterState['stateInitialized'];
  }
);
export const selectedSystemSerialsBySystemGroup = createSelector(
  [filterStateBySystemGroup],
  filterState => {
    return filterState['selectedSystemSerials'];
  }
);
export const timeFrameSelector = createSelector([filterStateBySystemGroup], filterState => {
  return filterState['timeframe'];
});
export const selectedDateSelector = createSelector([filterStateBySystemGroup], filterState => {
  return filterState['selectedDate'];
});
export const isSelectedSystemsEverReportedBySystemGroupSelector = createSelector(
  [filterStateBySystemGroup],
  filterState => {
    return filterState['isSelectedSystemsEverReported'];
  }
);
export const isTodaySelector = createSelector(
  [selectedDateSelector, endDateSelector],
  (selectedDate, endDate) => {
    return !isHistorical({
      day: new Date(selectedDate || '01.01.1990'),
      lastUpdate: endDate || new Date(),
    });
  }
);
//helper function
