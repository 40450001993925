import {
  convertSecondsToHours,
  formatSecToHourString,
  notUndefiend,
  secondsToDaysHoursMinutes,
  valueAsString,
} from 'helpers/conversions';
import React from 'react';
import styled from 'styled-components';
import { dashboardIcon, likeIcon, productionIcon } from '../Icons';
import { InnerCardContent, Spinner } from '../Styles';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/TopCardsArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/TopCardsArrowDown.svg';
import { StyledTooltip, globalColors } from 'globalStyle';
import {
  OverviewTopCardsBottomRow,
  OverviewTopCardsDescription,
  OverviewTopCardsUnits,
  OverviewTopCardsUpperRowContent,
  OverviewTopCardsValue,
} from './DesktopTotalImpressionCard';
import { StatsData } from '../Card';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { TimeFrame, TimeFrameEnum } from 'models/ReportData';
import {
  OverviewTopCardNames,
  getDescriptionTextForCustomTimeframe,
  getTopCardArrowColor,
} from 'helpers/overviewUtils';
import { UserSystemGroup } from 'store/storeTypes';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from 'react-i18next';
const LeftColumn = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 57%;
`;
const LeftColumnCardContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
const UpperRow = styled.div`
  display: flex;
  box-sizing: border-box;

  flex-direction: column;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #e6e9ed;
`;
const BottomRow = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  max-height: 46px;
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 40%;
  padding: 2%;
  background-color: ${globalColors.regular.background.greyCardBackround};
  border-radius: 8px;
`;
const RigthColumnUpperRow = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${globalColors.regular.background.greyDevider};
`;
const RigthColumnBottomRow = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding-top: 5px;
`;
const RightColumArrowPlaceHolder = styled.div`
  display: flex;
  width: 20px;
  align-self: center;
`;
const RightColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;
const RightColumnContentUpperRow = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${globalColors.regular.text.primary};
`;
const RightColumnContentBottomRow = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${globalColors.regular.text.grey};
`;
const ValueForAvailabilityHours = styled.div`
  display: inline;
  font-weight: 700;
  font-size: 19px;
  margin-left: 7px;
  color: ${globalColors.regular.text.primary};
`;
const DesktopAvarageAvailabilityCard = props => {
  const {
    stats,
    description,
    value,
    units,
    statsDescription,
    addedData,
    timeframe,
    customTimeframeProperties,
    selectedSystemGroup,
    availabilityTotalDurationReported,
    isNotReprotingPeriod,
  } = props;
  const { t } = useTranslation();
  const [isNegative, setIsNegative] = React.useState(false);
  React.useEffect(() => {
    if (notUndefiend([stats])) {
      if (stats! < 0) {
        setIsNegative(true);
      } else if (description === 'Average Availability' && value >= 0.999) {
        setIsNegative(false);
      } else {
        setIsNegative(false);
      }
    }
  }, [stats]); // eslint-disable-line react-hooks/exhaustive-deps
  const getArrowIcon = (addedData, row) => {
    const trend = addedData[row].trend;
    const description = addedData[row].description;
    if (trend === 0 || trend === undefined) {
      return <></>;
    }
    return trend > 0 ? (
      <ArrowUpIcon
        fill={getTopCardArrowColor({
          cardName: OverviewTopCardNames.averageAvailability,
          trend,
          description,
        })}
      />
    ) : (
      <ArrowDownIcon
        fill={getTopCardArrowColor({
          cardName: OverviewTopCardNames.averageAvailability,
          trend,
          description,
        })}
      />
    );
  };
  return notUndefiend([value]) ? (
    <InnerCardContent data-automationid="Availability Card">
      <LeftColumn>
        <LeftColumnCardContent>
          <UpperRow>
            <OverviewTopCardsUpperRowContent>
              <div>{isNegative ? likeIcon.negative : likeIcon.positive}</div>
              <OverviewTopCardsValue data-testid="avg-availability-value">
                {value === -1 ? '--' : valueAsString(value, true)}
              </OverviewTopCardsValue>
              <OverviewTopCardsUnits data-testid="avg-availability-units">
                {value === -1 ? '' : units}
              </OverviewTopCardsUnits>
            </OverviewTopCardsUpperRowContent>

            <OverviewTopCardsDescription data-testid="avg-availability-description">
              {description}
            </OverviewTopCardsDescription>
          </UpperRow>
          <OverviewTopCardsBottomRow>
            {notUndefiend([stats]) && StatsData(stats!)}
            <div>
              {timeframe === TimeFrameEnum.custom
                ? getDescriptionTextForCustomTimeframe({ timeframe, customTimeframeProperties })
                : statsDescription}
            </div>
          </OverviewTopCardsBottomRow>
        </LeftColumnCardContent>
      </LeftColumn>
      <RightColumn>
        <RigthColumnUpperRow>
          <RightColumArrowPlaceHolder>
            {getArrowIcon(addedData, 'upper')}
          </RightColumArrowPlaceHolder>
          <RightColumnContent>
            <RightColumnContentUpperRow>
              {formatSecToHourString(addedData?.upper?.value)} {addedData.upper.unit}
            </RightColumnContentUpperRow>
            <RightColumnContentBottomRow>{addedData.upper.description}</RightColumnContentBottomRow>
          </RightColumnContent>
        </RigthColumnUpperRow>
        <RigthColumnBottomRow>
          <RightColumArrowPlaceHolder>
            {getArrowIcon(addedData, 'lower')}
          </RightColumArrowPlaceHolder>
          <RightColumnContent>
            <RightColumnContentUpperRow>
              {formatSecToHourString(addedData?.lower?.value)} {addedData.lower.unit}
            </RightColumnContentUpperRow>
            <RightColumnContentBottomRow>{addedData.lower.description}</RightColumnContentBottomRow>
          </RightColumnContent>
        </RigthColumnBottomRow>
      </RightColumn>
    </InnerCardContent>
  ) : (
    Spinner(60)
  );
};
export default DesktopAvarageAvailabilityCard;
