import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { updateGoal } from 'actions/goalApiActions';
import GoalItem from './GoalItem';
import { Store } from 'store/storeTypes';
import {
  getGoalEntryByIdSelector,
  convertGoalTypeSelector,
  formatGoalCurrentValueSelector,
  formatGoalImpressionsValueSelector,
  goalLastUpdateNoteSelector,
  goalDateIndicatorSelector,
} from 'selectors/entitiesSelectors';

const mapStateToProps = (state: Store, props: Record<string, string | number>) => ({
  goalEntry: getGoalEntryByIdSelector(state, props),
  goalTypeStr: convertGoalTypeSelector(state, props),
  goalCurrentVal: formatGoalCurrentValueSelector(state, props),
  goalImpVal: formatGoalImpressionsValueSelector(state, props),
  lastUpdateNote: goalLastUpdateNoteSelector(state, props),
  passedDateIndicator: goalDateIndicatorSelector(state, props),
  userSettings: state.userSettings.data,
  selectedSystemGroup: state.ui.selectedSystemGroup,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateGoal,
    },
    dispatch
  );

export type GoalItemProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(GoalItem);
