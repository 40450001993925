import React from 'react';
import styled from 'styled-components/macro';
import { subDays, addDays, isBefore, isAfter } from 'date-fns';

import { MainReportTypes, TimeFrame } from 'models/ReportData';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import NewDatePicker from './NewDatePicker';
import { CustomTimeFrameProperties } from '../../actions/reportFilterActions';
const DaysContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div::before {
    border-bottom: none !important;
  }
  div::after {
    border-bottom: none;
  }
  input {
    font-weight: 400;
    font-size: 16px;
    color: #354052;
  }
  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const MobileView = styled.div`
  display: none;
  @media (max-width: 750px) {
    display: block;
    width: 100%;
  }
`;

export type CustomTimeframeProperties = {
  isShiftModeSelected: boolean;
  customTimeFrameStartDate: Date;
  customTimeFrameStartHour: number;
  customTimeFrameEndDate: Date;
  customTimeFrameEndHour: number;
};
type PbiFilterCustomType = {
  endDate: Date;
  selectedDate: Date;
  startDate: Date;
  activeReport: MainReportTypes;
  setActiveDate: (date: Date) => void;
  timeframe: TimeFrame;
  customTimeframeProperties: CustomTimeframeProperties;
  setIsCustomTimeFrameOpen: (isOpen: boolean) => void;
  setTimeframe: (
    timeframe: TimeFrame,
    startDate?: Date,
    customTimeFrameProperties?: CustomTimeFrameProperties
  ) => void;
};
const PbiFilterCustom = React.memo((props: PbiFilterCustomType) => {
  const {
    endDate,
    selectedDate,
    startDate,
    activeReport,
    timeframe,
    customTimeframeProperties,
    setIsCustomTimeFrameOpen,
    setTimeframe,
    setActiveDate,
  } = props;
  const [lowerDate, setLowerDate] = React.useState<Date | null>(null);
  const onChangeActiveDay = (d: Date | undefined) => {
    setActiveDate(d);
  };

  React.useEffect(() => {
    if (lowerDate) setActiveDate(lowerDate);
  }, [lowerDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isDisableLeft = (
    activeReport: MainReportTypes,
    customTimeframeProperties: CustomTimeFrameProperties,
    timeframe: TimeFrame
  ) => {
    if (timeframe !== 'custom') {
      return true;
    } else {
      //*if we are here it means that custom timeframe is active and we have all nessesary customTimeframeProperties to calculate interval;
      const customStartDate = customTimeframeProperties.customTimeFrameStartDate;
      const nextCustomStartDate = subDays(customStartDate, 1);
      return isBefore(nextCustomStartDate, startDate);
    }
  };
  const isDisableRight = (
    activeReport: MainReportTypes,
    customTimeframeProperties: CustomTimeFrameProperties,
    timeframe: TimeFrame
  ) => {
    if (timeframe !== 'custom') {
      return true;
    } else {
      //*if we are here it means that custom timeframe is active and we have all nessesary customTimeframeProperties to calculate interval;
      const customEndDate = customTimeframeProperties.customTimeFrameEndDate;
      const nextCustomEndDate = addDays(customEndDate, 1);
      return isAfter(nextCustomEndDate, endDate);
    }
  };
  const disableRight = isDisableRight(activeReport, customTimeframeProperties, timeframe);

  const disableLeft = isDisableLeft(activeReport, customTimeframeProperties, timeframe);

  const onHandleLeft = () => {
    if (!disableLeft) {
      const customTimeFrameStartHour = customTimeframeProperties?.customTimeFrameStartHour;
      const customTimeFrameEndHour = customTimeframeProperties?.customTimeFrameEndHour;
      const customStartDate = customTimeframeProperties.customTimeFrameStartDate;
      const customEndDate = customTimeframeProperties.customTimeFrameEndDate;
      //*on arrow click there is always offset of whole custom period on 1 day left
      const interval = 1;
      const prevCustomStartDate = subDays(customStartDate, interval);
      const prevCustomEndDate = subDays(customEndDate, interval);

      setTimeframe('custom', startDate, {
        customTimeFrameStartDate: prevCustomStartDate,
        customTimeFrameEndDate: prevCustomEndDate,
        customTimeFrameStartHour: customTimeFrameStartHour,
        customTimeFrameEndHour: customTimeFrameEndHour,
      });
      KonnectAnalytics.decrementDateInDatePicker();
    }
  };

  const onHandleRight = () => {
    if (!disableRight) {
      const customTimeFrameStartHour = customTimeframeProperties?.customTimeFrameStartHour;
      const customTimeFrameEndHour = customTimeframeProperties?.customTimeFrameEndHour;
      const customStartDate = customTimeframeProperties.customTimeFrameStartDate;
      const customEndDate = customTimeframeProperties.customTimeFrameEndDate;
      //*on arrow click there is always offset of whole custom period on 1 day right
      const interval = 1;
      const nextCustomStartDate = addDays(customStartDate, interval);
      const nextCustomEndDate = addDays(customEndDate, interval);
      // setActiveDate(addDays(selectedDate || endDate || new Date(), 1));
      setTimeframe('custom', startDate, {
        customTimeFrameStartDate: nextCustomStartDate,
        customTimeFrameEndDate: nextCustomEndDate,
        customTimeFrameStartHour: customTimeFrameStartHour,
        customTimeFrameEndHour: customTimeFrameEndHour,
      });
      KonnectAnalytics.incrementDateInDatePicker();
    }
  };
  const date = lowerDate || selectedDate || endDate;
  return (
    <NewDatePicker
      activeReport={activeReport}
      prevTimeframe={timeframe}
      timeframe={'custom'}
      disableLeft={disableLeft}
      disableRight={disableRight}
      date={date}
      lastUpdate={endDate}
      selectedDate={selectedDate}
      customTimeframeProperties={customTimeframeProperties}
      startDate={startDate}
      setActiveDate={setActiveDate}
      setIsCustomTimeFrameOpen={setIsCustomTimeFrameOpen}
      setTimeframe={setTimeframe}
      onHandleLeft={onHandleLeft}
      onHandleRight={onHandleRight}
    />
  );
});

export default PbiFilterCustom;
