import { ActionType } from 'typesafe-actions';

import * as appUser from './appUserActions';
import * as userSettings from './userSettingsActions';
import * as goalApi from './goalApiActions';
import * as reportApi from './reportApiActions';
import * as reportFilter from './reportFilterActions';
import * as reportUi from './reportUiActions';
import * as schedulerApi from './schedulerApiActions';
import * as schedulerUi from './schedulerUiActions';
import * as filterCalenderUi from './filterCalendarUiAction';
import * as scopeApi from './scopeApiActions';
import * as ui from './uiActions';
import * as userApi from './userApiActions';
import * as userUi from './userUiActions';
import * as systemAnalysis from './systemAnalysisActions';
import * as overview from './overviewActions';
import * as machineConfig from './machineConfigActions';
import * as jobSummary from './jobSummaryActions';
import * as jobAnalysis from './jobAnalysisActions';
import * as apiManagement from './apiManagementActions';
import * as systemPage from './systemPageActions';
import * as serviceTicket from './serviceTicketActions';
export const actions = {
  appUser,
  reportApi,
  reportFilter,
  reportUi,
  goalApi,
  schedulerApi,
  schedulerUi,
  filterCalenderUi,
  scopeApi,
  ui,
  userApi,
  userUi,
  userSettings,
  systemAnalysis,
  overview,
  machineConfig,
  jobSummary,
  jobAnalysis,
  apiManagement,
  systemPage,
  serviceTicket,
};

export type RootAction = ActionType<typeof actions>;
