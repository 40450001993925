import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { PayloadAC } from 'typesafe-actions';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import ErrorsHandlerService from '../../services/errors-handler-service';
import { enqueueNotification } from '../uiActions';
import { Store } from '../../store/storeTypes';

const customErrorCodePrefix = 'CODE:';
export const isAxiosError = (error: Error): error is AxiosError => (error as any).isAxiosError;

function resolveErrorMessage(error: Error, message = '') {
  let resolvedMessage = message;
  if (isAxiosError(error)) {
    if (error.response) {
      const { status } = error.response;
      if (status >= 400 && status < 500) {
        resolvedMessage = i18next.t(`errors.http.${status}`, i18next.t('errors.http.unknown'));
      } else if (
        status >= 500 &&
        typeof error.response.data === 'string' &&
        error.response.data.startsWith(customErrorCodePrefix)
      ) {
        const customErrorCode = error.response.data.substr(customErrorCodePrefix.length);
        resolvedMessage = i18next.t(
          `errors.customServerCodes.${customErrorCode}`,
          i18next.t('errors.http.unknown')
        );
      } else {
        resolvedMessage = i18next.t('errors.http.unknown');
      }
    } else if (error.message === 'Network Error') {
      resolvedMessage = i18next.t('errors.http.connection');
    }
  }

  return resolvedMessage || error.message;
}

export default function handleErrorAction(
  error: Error,
  message = '',
  moreHandlerActions: PayloadAC<any, any>[] = []
): (dispatch: ThunkDispatch<Store, any, Action>) => void {
  return function(dispatch) {
    if (message === 'API: Request failed with status code 429') {
      return;
    } else {
      moreHandlerActions.forEach(action => dispatch(action(error)));
      const errorMessage = resolveErrorMessage(error, message);
      dispatch(enqueueNotification(errorMessage, { variant: 'error' }));
      ErrorsHandlerService.onError(error, 'general');
    }
  };
}
