import { numberFormatterNew } from 'helpers/conversions';
import React from 'react';
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Area } from 'recharts';
import styled from 'styled-components';
import { CustomizedMobileTickProductionChart } from './ProductionChart';
const MobileChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const MobileProductionChart = props => {
  const { aggretatedData, isWeek, legendText } = props;

  return (
    <MobileChartContainer data-automationid={`total_impression_chart`}>
      <ResponsiveContainer width="100%" minHeight="260px">
        <AreaChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} data={aggretatedData}>
          <CartesianGrid color="C5D0DE" vertical={false} />

          <XAxis
            axisLine={{ stroke: '#C5D0DE' }}
            tick={<CustomizedMobileTickProductionChart data={aggretatedData} isWeek={isWeek} />}
            tickLine={false}
            padding={{ right: 3, left: 0 }}></XAxis>
          <YAxis
            width={50}
            axisLine={false}
            tickFormatter={value => numberFormatterNew(value)}
            tick={{ fontSize: 12 }}
            tickLine={false}></YAxis>
          <Area
            isAnimationActive={false}
            name={legendText.total}
            type="monotone"
            dataKey="total"
            stroke="#2EA1F8"
            fill="#2EA1F826"
            strokeWidth={2}
            dot={isWeek && { stroke: '#2EA1F8', fill: '#2EA1F8', strokeWidth: 1, fillOpacity: 1 }}
          />
          <Area
            isAnimationActive={false}
            name={legendText.totalAvg}
            type="monotone"
            dataKey="totalAvg"
            stroke="#C5D0DE"
            fill="#C5D0DE26"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </MobileChartContainer>
  );
};
export default MobileProductionChart;
