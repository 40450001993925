import React, { memo } from 'react';
import styled from 'styled-components';
import { IOverviewProps } from './OverviewContainer';
import TopCards, { text } from './TopCards';
import AchievementCardMobile from './AcievementCardMobile';
import { determineUnitToShow } from 'helpers/conversions';
import { useTranslation } from 'react-i18next';
import GoalsCard from './GoalsCard';
import { getLegendText } from 'helpers/overviewUtils';
import { ReactComponent as LegendItemIcon } from '../../assets/icons/overviewLegendIcon.svg';
import { ActiveOverviewChart, UserSystemGroup } from 'store/storeTypes';
import ToggleChart from './Charts/ToggleChartContainer';
import ProductionChart from './Charts/ProductionChart';
import { Spinner, SpinnerContainer } from './Styles';
import SpeedChart from './Charts/SpeedChart';
import { getUnitsForTooltip } from 'helpers/chartUtils';
import AvailabilityChart from './Charts/AvailabilityChart';
export const MobileReportWrapper = styled.div<{ reportHeight: string }>`
  display: flex;
  flex-direction: column;
  padding: 4%;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  height: ${props => props.reportHeight};

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;
export const EmptyBlock = styled.div`
  width: 100%;
  min-height: 60px;
  height: 60px;
`;
const ProductionOverviewChartsCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 965px;

  background: #ffffff;
  border: 1px solid #c5d0de;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;

  margin-bottom: 10px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;

  color: #354052;
`;

const BottomBorder = styled.div`
  min-height: 10px;
  width: 100%;
`;
const ChartsCardHeader = styled.div`
  box-sizing: border-box;
  height: 40px;
  font-size: 14px;
  border-bottom: 1px solid #c5d0de;
  padding: 10px 15px;
  display: flex;
  align-items: center;
`;
const ChartsCardLegend = styled.div`
  box-sizing: border-box;
  height: 54px;
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  font-size: 14px;
  letter-spacing: 0.5625px;

  color: #7f8fa4;
`;
const ChartsCardLegendItem = styled.div`
  display: flex;
  align-items: center;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LegendItemDescription = styled.div`
  margin-left: 5px;
`;
const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  margin: 10px;
`;
const ChartWrapper = styled.div<{ height: string }>`
  box-sizing: border-box;
  height: ${props => props.height};
  width: 100%;
  padding: 0 0;
  padding-right: 15px;
  margin-bottom: 47px;
`;
const OverviewMobile = memo((props: IOverviewProps) => {
  const {
    pbiReportHeight,
    timeFrame: timeframe,
    selectedSystemGroup,
    userSettings,
    productionChart,
    speedChart,
    availabilityChart,
    activeChart,
    utilizationChart,
    weekRecord,
  } = props;
  const { t } = useTranslation();
  const units = determineUnitToShow(selectedSystemGroup, userSettings, t);
  const { total: leftLegendItem, totalAvg: rightLegendItem } = getLegendText(timeframe);
  const selectedSystemGroupText = text[selectedSystemGroup]!;
  return (
    <MobileReportWrapper reportHeight={`${pbiReportHeight - 40}px`}>
      <TopCards isMobile={true} timeframe={timeframe} />

      <ProductionOverviewChartsCard>
        <ChartsCardHeader>Production Overview</ChartsCardHeader>
        <ChartsCardLegend>
          <ChartsCardLegendItem>
            <IconWrapper>
              <LegendItemIcon fill={'#2EA1F8'} />
            </IconWrapper>

            <LegendItemDescription data-testid="left-legend-item">
              {leftLegendItem}
            </LegendItemDescription>
          </ChartsCardLegendItem>
          <ChartsCardLegendItem style={{ marginLeft: '10px' }}>
            <IconWrapper>
              <LegendItemIcon fill={'#E6E9ED'} />{' '}
            </IconWrapper>
            <LegendItemDescription data-testid="right-legend-item">
              {rightLegendItem}
            </LegendItemDescription>
          </ChartsCardLegendItem>
        </ChartsCardLegend>

        <ChartHeader>
          {selectedSystemGroupText.impression.chart[timeframe]}
          {selectedSystemGroup === UserSystemGroup.DTF && ` (${units})`}
        </ChartHeader>
        <ChartWrapper height="264px" style={{ marginBottom: '30px' }}>
          {productionChart ? (
            <ProductionChart
              isWeek={timeframe === 'week'}
              legendText={getLegendText(timeframe)}
              units={units}
              selectedSystemGroup={selectedSystemGroup}
              data={productionChart}
              speedData={speedChart}
              availabilityData={availabilityChart}
              isMobile={true}
            />
          ) : (
            <SpinnerContainer>{Spinner()}</SpinnerContainer>
          )}
        </ChartWrapper>

        <ChartHeader>
          {<ToggleChart isMobile={true} />}
          <span style={{ marginLeft: '12px' }}>
            {activeChart === ActiveOverviewChart.Utilization
              ? selectedSystemGroupText.utilization?.chart[timeframe]
              : selectedSystemGroupText.productionRate.chart[timeframe]}
            {selectedSystemGroup === UserSystemGroup.DTF &&
              activeChart === ActiveOverviewChart.Speed &&
              ` (${units}/h)`}
          </span>
        </ChartHeader>
        <ChartWrapper height="178px" style={{ marginTop: '15px' }}>
          {speedChart ? (
            <SpeedChart
              isMobile={true}
              selectedSystemGroup={selectedSystemGroup}
              timeframe={timeframe}
              tooltipText={getLegendText(timeframe)}
              units={getUnitsForTooltip(
                selectedSystemGroup,
                'speedChart',
                activeChart,
                userSettings.lengthUnits
              )}
              data={
                activeChart === ActiveOverviewChart.Utilization
                  ? utilizationChart || []
                  : speedChart || []
              }
            />
          ) : (
            <SpinnerContainer>{Spinner()}</SpinnerContainer>
          )}
        </ChartWrapper>

        <ChartHeader>{selectedSystemGroupText.availability.chart[timeframe]}</ChartHeader>
        <ChartWrapper height="210px">
          {availabilityChart ? (
            <AvailabilityChart
              selectedSystemGroup={selectedSystemGroup}
              isWeek={timeframe === 'week'}
              isMobile={true}
              tooltipText={getLegendText(timeframe)}
              data={availabilityChart || []}></AvailabilityChart>
          ) : (
            <SpinnerContainer>{Spinner()}</SpinnerContainer>
          )}
        </ChartWrapper>
      </ProductionOverviewChartsCard>
      <BottomBorder></BottomBorder>
    </MobileReportWrapper>
  );
});

export default OverviewMobile;
