import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Store } from 'store/storeTypes';
import PbiFilterDays from './PbiFilterDays';
import { setActiveDate } from 'actions/reportFilterActions';
import {
  activeReportSelector,
  endDateSelector,
  startDateSelector,
} from 'reducers/reports/filterDataReducer';
import { printerStartDateSelector, pritnterEndDateSelector } from 'reducers/systemAnalysisReducer';
import { isBefore } from 'date-fns';
import { selectedDateSelector } from 'reducers/reports/filterStateReducer';
type OwnProps = {
  isMobile?: boolean;
};
const mapStateToProps = (state: Store) => {
  return {
    lastUpdate: endDateSelector(state),
    startDate: startDateSelector(state),
    selectedDate: selectedDateSelector(state),
    activeReport: activeReportSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveDate,
    },
    dispatch
  );

export type PbiFilterDaysProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(PbiFilterDays);
