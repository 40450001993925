import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Store } from 'store/storeTypes';
import PbiFilterCustom from './PbiFilterCustom';
import { setActiveDate, setTimeframe } from 'actions/reportFilterActions';
import {
  customTimeFramePropertiesSelector,
  timeFrameSelector,
} from 'reducers/reports/filterStateReducer';
import { activeReportSelector } from 'reducers/reports/filterDataReducer';
type OwnProps = {
  setIsCustomTimeFrameOpen?: (isOpen: boolean) => void;
};
const mapStateToProps = (state: Store) => {
  const { startDate, endDate } = state.reports.filterData[state.ui.selectedSystemGroup]!;
  const { selectedDate } = state.reports.filterState[state.ui.selectedSystemGroup]!;
  return {
    startDate,
    endDate,
    selectedDate,
    customTimeframeProperties: customTimeFramePropertiesSelector(state),
    timeframe: timeFrameSelector(state),
    activeReport: activeReportSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setActiveDate, setTimeframe }, dispatch);

export type PbiFilterCustomProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(PbiFilterCustom);
