import { createStandardAction } from 'typesafe-actions';

import { apiActionSimple } from './_shared/apiAction';
import { makeApiUrl } from './_shared/utils';
import {
  ReportFilterCurrentStateByType,
  ReportsFiltersDatesApi,
  ReportsFilterSitesApi,
} from 'models/ReportsFilterData';

import { ReportFilterDataByType } from 'store/storeTypes';

export const loadReportsFilterDataStarted = createStandardAction(
  'LOAD_REPORTS_FILTER_DATA_STARTED'
)();

export const loadReportsFilterDataSuccess = createStandardAction(
  'LOAD_REPORTS_FILTER_DATA_SUCCESS'
)<ReportFilterDataByType>();
export const loadReportsFilterDataFailed = createStandardAction('LOAD_REPORTS_FILTER_DATA_FAILED')<
  any
>();

export const loadReportsFilterSitesStarted = createStandardAction(
  'LOAD_REPORTS_FILTER_SITES_STARTED'
)();

export const loadReportsFilterSitesSuccess = createStandardAction(
  'LOAD_REPORTS_FILTER_SITES_SUCCESS'
)<ReportsFilterSitesApi>();
export const loadReportsFilterSitesFailed = createStandardAction(
  'LOAD_REPORTS_FILTER_SITES_FAILED'
)<any>();

export const loadReportsFilterDatesStarted = createStandardAction(
  'LOAD_REPORTS_FILTER_DATES_STARTED'
)();

export const loadReportsFilterDatesSuccess = createStandardAction(
  'LOAD_REPORTS_FILTER_DATES_SUCCESS'
)<ReportsFiltersDatesApi>();
export const loadReportsFilterDatesFailed = createStandardAction(
  'LOAD_REPORTS_FILTER_DATES_FAILED'
)<any>();

export const loadReportsCurrentStateStarted = createStandardAction(
  'LOAD_REPORTS_CURRRENT_STATE_STARTED'
)();

export const loadReportsCurrentStateSuccess = createStandardAction(
  'LOAD_REPORTS_CURRRENT_STATE_SUCCESS'
)<ReportFilterCurrentStateByType>();
export const loadReportsCurrentStateFailed = createStandardAction(
  'LOAD_REPORTS_CURRRENT_STATE_FAILED'
)<any>();

export const getReportsFilterSites = () => {
  const getReportsFilterSitesUrl = makeApiUrl('/api/reports/site-extensions');
  return apiActionSimple({
    request: { url: getReportsFilterSitesUrl },
    actions: [
      loadReportsFilterSitesStarted,
      loadReportsFilterSitesSuccess,
      loadReportsFilterSitesFailed,
    ],
    interceptor: state =>
      state.apiState.reportsFilterData.isInProgress ||
      state.apiState.reportsFilterData.reachedMaxLoadAttempts,
  });
};

export const getReportsFilterDates = () => {
  const getReportsFiltersDatesUrl = makeApiUrl('/api/reports/date-filters');
  return apiActionSimple({
    request: { url: getReportsFiltersDatesUrl },
    actions: [
      loadReportsFilterDatesStarted,
      loadReportsFilterDatesSuccess,
      loadReportsFilterDatesFailed,
    ],
    interceptor: state =>
      state.apiState.reportsFilterData.isInProgress ||
      state.apiState.reportsFilterData.reachedMaxLoadAttempts,
  });
};

export const getReportsCurrentState = () => {
  const getReportsCurrentStateUrl = makeApiUrl('/api/reports/current-state');
  return apiActionSimple({
    request: { url: getReportsCurrentStateUrl },
    actions: [
      loadReportsCurrentStateStarted,
      loadReportsCurrentStateSuccess,
      loadReportsCurrentStateFailed,
    ],
    interceptor: state =>
      state.apiState.reportsCurrentState.isInProgress ||
      state.apiState.reportsCurrentState.reachedMaxLoadAttempts,
  });
};
