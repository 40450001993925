import mixpanel from 'mixpanel-browser';
import env from '../config/config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import config from 'config/config';
import { authService } from 'services/AuthProvider';
import { AuthServiceImpl } from 'services/auth-service';
export const appInsightLogger = new ApplicationInsights({
  config: {
    instrumentationKey: config.LOGGER_INSTRUMENTAION_KEY,
  },
});
appInsightLogger.loadAppInsights();

const mixPannelKey = env.MIXPANEL_KEY;

mixpanel.init(mixPannelKey);

const actions = {
  login: user => {
    if (mixPannelKey) {
      mixpanel.identify(user.email);
      //*register action is binding income properties to every action
      mixpanel.register({
        isSuperUser: checkIsSuperUser(authService),
      });
      mixpanel.track('Login');
      mixpanel.track('StartSession', { reason: 'user entered in app' });
      //*sets registration date - works only once - first enter
      mixpanel.people.set_once({
        $email: user.email,
        'Sign up date': new Date().toISOString(),
      });
      //*all of parameters should be in func above   (set_once), but there was issue that some of them comes as undefined from time to time form server side. that's why they were moved to method - set()
      mixpanel.people.set({
        USER_ID: user.email,
        importedTennantId: user.importedTennantId,
        tennantName: user.tennantName,
        office: user.office,
        accountType: user.accountType,
        userSystemGroup: user.userSystemGroup.join(' / '),
        isSuperUser: checkIsSuperUser(authService),
      });
    }

    appInsightLogger.trackEvent(
      { name: 'Login' },
      { isSuperUser: checkIsSuperUser(authService), email: getUserEmail(authService) }
    );
    appInsightLogger.trackEvent(
      { name: 'StartSession' },
      {
        reason: 'user entered in app',
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    //@ts-ignore
    window.pendo.initialize({
      visitor: {
        id: user.email, // Required if user is logged in, default creates anonymous ID
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        isDtgUser: user.userSystemGroup.includes('DTG'),
        isDtfUser: user.userSystemGroup.includes('DTF'),
        isApolloUser: user.userSystemGroup.includes('Apollo'),
        userSystemGroup: user.userSystemGroup.join(' / '),
      },

      account: {
        id: user.tennantName, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  },
  startSession: (name, props?) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'Start Session',
        reason: props.reason,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'Start Session',
        reason: props.reason,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  sendErrorLog: (name, error, errorInfo) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'Error',
        error: error,
        errorInfo: errorInfo,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'Error',
        error: error,
        errorInfo: errorInfo,
      }
    );
  },
  finishSession: (name, props?) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'End Session',
        // reason: props.reason,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'End Session',
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
    //@ts-ignore
  },
  changeReportPage: (name, props?) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'Change',
        prevReport: props.prevReport,
        nextReport: props.nextReport,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'Change',
        prevReport: props.prevReport,
        nextReport: props.nextReport,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  changeTimeFrameFilter: (name, props?) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'Change',
        nextTimeFilter: props.nextTimeFilter,
        startDate: props.startDate,
        endDate: props.endDate,
        customStartHour: props.customStartHour,
        customEndHour: props.customEndHour,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'Change',
        nextTimeFilter: props.nextTimeFilter,
        startDate: props.startDate,
        endDate: props.endDate,
        customStartHour: props.customStartHour,
        customEndHour: props.customEndHour,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  changeSystemGroupFilter: (name, props) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'Change',
        prevSystemGroup: props.systemGroupFrom,
        nextSystemGroup: props.systemGroupTo,
        currentPage: props.currentActiveReport,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'Change',
        prevSystemGroup: props.systemGroupFrom,
        nextSystemGroup: props.systemGroupTo,
        currentPage: props.currentActiveReport,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  changePrinterNickName: (name, props) => {
    if (mixPannelKey) {
      mixpanel.track(name, {
        action: 'Change',
        serialNubmer: props.serialNumber,
        prevNickName: props.prevNickName,
        newNickName: props.newNickName,
      });
    }
    appInsightLogger.trackEvent(
      { name },
      {
        action: 'Change',
        serialNubmer: props.serialNumber,
        prevNickName: props.prevNickName,
        newNickName: props.newNickName,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  dowloadErrorAndMaintenanceReport: selectedSystem => {
    if (mixPannelKey) {
      mixpanel.track('DowloadErrorAndMaintenanceReport', {
        action: 'DownloadFile',
        systemName: selectedSystem,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'DowloadErrorAndMaintenanceReport' },
      {
        action: 'DownloadFile',
        systemName: selectedSystem,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  //*timeLine day view toggle view 24 hour / 1 hour
  toggleTimelineDayView: (prevView: string, nextView: string) => {
    if (mixPannelKey) {
      mixpanel.track('toggleTimelineDayView', {
        prevView,
        nextView,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'toggleTimelineDayView' },
      {
        prevView,
        nextView,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  //*timeline hour view hour seletction
  selectHourTimelineHourView: (prevHour, nextHour) => {
    if (mixPannelKey) {
      mixpanel.track('selectHourTimelineHourView', {
        prevHour,
        nextHour,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'selectHourTimelineHourView' },
      {
        prevHour,
        nextHour,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  toggleDatePickerCalendar: () => {
    if (mixPannelKey) {
      mixpanel.track('Open/Close Date Picker Calendar', {
        action: 'Open/Close Date Picker Calendar',
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Open/Close Date Picker Calendar' },
      {
        action: 'Open/Close Date Picker Calendar',
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  incrementDateInDatePicker: () => {
    if (mixPannelKey) {
      mixpanel.track('Increment date in Date Picker Calendar', {
        action: 'Increment date',
      });
    }
    appInsightLogger.trackEvent(
      { name: 'ncrement date in Date Picker Calendar' },
      {
        action: 'Increment date',
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  decrementDateInDatePicker: () => {
    if (mixPannelKey) {
      mixpanel.track('Decrement date in Date Picker Calendar', {
        action: 'Decrement date',
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Decrement date in Date Picker Calendar' },
      {
        action: 'Decrement date',
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  toggleMachineFilterMenu: () => {
    if (mixPannelKey) {
      mixpanel.track('Open/Close MachineFilterMenu', {
        action: 'Open/Close Machine Filter Menu',
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Open/Close MachineFilterMenu' },
      {
        action: 'Open/Close Machine Filter Menu',
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  JobAnalysisFilter: (props: JobAnalysisFilterArgs) => {
    const { systemGroup, actionType } = props;

    if (mixPannelKey) {
      mixpanel.track('Job Analysis Filter', {
        systemGroup,
        actionType,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Job Analysis Filter' },
      {
        systemGroup,
        actionType,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  //*profiles actions
  people: {
    set: props => {
      if (mixPannelKey) mixpanel.people.set(props);
    },
  },
  clickInkConsupmtionPerJob: timeframe => {
    if (mixPannelKey) {
      mixpanel.track('Click Ink Consumption Per Job', {
        action: 'User clicked on button',
        timeframe,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Click Ink Consumption Per Job' },
      {
        action: 'User clicked on button',
        timeframe,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  changeDateRangeInkConsuptionPerJob: ({
    timeframe,
    startDate,
    endDate,
    newStartDate,
    newEndDate,
  }) => {
    if (mixPannelKey) {
      mixpanel.track('Change Date Range Ink Consumption Per Job', {
        timeframe,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        newStartDate: newStartDate.toISOString(),
        newEndDate: newEndDate.toISOString(),
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Change Date Range Ink Consumption Per Job' },
      {
        timeframe,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        newStartDate: newStartDate.toISOString(),
        newEndDate: newEndDate.toISOString(),
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  downloadSystemAnalysisReport: ({ result }) => {
    if (mixPannelKey) {
      mixpanel.track('Download System Analysis Report', {
        action: 'DownloadFile',

        result,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Download System Analysis Report' },
      {
        action: 'DownloadFile',

        result,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  downloadInkConsumptionPerJobReport: ({ result }) => {
    if (mixPannelKey) {
      mixpanel.track('Download Ink Consupmtion Per Job Report', {
        action: 'DownloadFile',

        result,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Download Ink Consupmtion Per Job Report' },
      {
        action: 'DownloadFile',
        result,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  jobAnalysisInkConsumptionTrendGraphSelectColor: ({ selectedColors, color }) => {
    if (mixPannelKey) {
      mixpanel.track('Job Analysis Ink Consumption Trend Color Selection', {
        action: 'SelectColor',
        selectedColor: color,
      });
    }
    appInsightLogger.trackEvent(
      { name: 'Job Analysis Ink Consumption Trend Color Selection' },
      {
        action: 'SelectColor',
        selectedColor: color,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },

  customClick: ({ selectedSystemGroup }) => {
    if (mixPannelKey) {
      mixpanel.track('CustomClick', {
        action: 'Click',
        selectedSystemGroup,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      });
    }
    appInsightLogger.trackEvent(
      { name: 'CustomClick' },
      {
        action: 'Click',
        selectedSystemGroup,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },

  submitCustomClick: ({ startDate, endDate, customStartHour = 0, customEndHour = 0 }) => {
    if (mixPannelKey) {
      mixpanel.track('SubmitCustomClick', {
        action: 'Click',
        nextTimeFilter: 'Custom',
        startDate,
        endDate,
        customStartHour,
        customEndHour,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      });
    }
    appInsightLogger.trackEvent(
      { name: 'SubmitCustomClick' },
      {
        action: 'Click',
        nextTimeFilter: 'Custom',
        startDate,
        endDate,
        customStartHour,
        customEndHour,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },
  changedDateinCustomTimeFrame: ({
    startDate,
    endDate,
    customStartHour = 0,
    customEndHour = 0,
  }) => {
    if (mixPannelKey) {
      mixpanel.track('ChangedDateinCustomTimeFrame', {
        action: 'Change',
        nextTimeFilter: 'Custom',
        startDate,
        endDate,
        customStartHour,
        customEndHour,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      });
    }
    appInsightLogger.trackEvent(
      { name: 'ChangedDateinCustomTimeFrame' },
      {
        action: 'Change',
        nextTimeFilter: 'Custom',
        startDate,
        endDate,
        customStartHour,
        customEndHour,
        isSuperUser: checkIsSuperUser(authService),
        email: getUserEmail(authService),
      }
    );
  },

  trackHistory: location => {},
};

type JobAnalysisFilterArgs = {
  systemGroup: string;
  actionType: 'groupByResolution' | 'filter';
};
export const KonnectAnalytics = actions;

//*helper fucntions
export const checkIsSuperUser = (authService: AuthServiceImpl): string => {
  return authService.idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}is_super_user`]
    ? 'true'
    : 'false';
};

export const getUserEmail = (authService: AuthServiceImpl) => {
  return authService.idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}is_super_user`]
    ? authService.idTokenPayload.nickname
    : authService.idTokenPayload.name;
};
