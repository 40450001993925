import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from '../_common/Select';
import MuiButton from '@material-ui/core/Button';
import { isHistorical } from 'helpers/date';
import PbiFilterDate from 'components/PbiFilter/PbiFilterDateContainer';
import InkConsuptionPerJobButton from './InkConsuptionPerJobButton';
import PbiFilterSystemGroup from './PbiFilterSystemGroupContainer';
import {
  ReportFilterData,
  ReportFilterState,
  ReportSystem,
  TimeFrameSelectorViews,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { MainReportTypes, ReportTypesNew, TimeFrame, TimeFrameEnum } from 'models/ReportData';
import { globalColors, VerticalDevider } from 'globalStyle';
import TimeFrameSelector from './TimeFrameSelector';

const Desktop = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 18px;
  @media (max-width: 750px) {
    display: none;
  }
`;

const LeftPart = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  div::before {
    border-bottom: none !important;
  }
  div::after {
    border-bottom: none;
  }
  #select-active-page {
    font-weight: 500;
    font-size: 12px;
    color: #354052;
    border: 1px solid #c5d0de;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 8px 40px 9px 21px;
    &:focus {
      background-color: initial;
    }
    & ~ svg {
      right: 8px;
      fill: #354052;
    }
  }
  button {
    text-transform: uppercase;
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const HeaderButton = styled(MuiButton)`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  height: 30px;
  color: ${globalColors.regular.buttons.regularText};
  border-color: #2ea1f8;
  cursor: pointer;
`;

const RightPart = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

type DesktopPbiFilterTimeFrame = {
  view?: 'dropDown' | 'buttons' | 'mixed';
  isEmptyState: boolean;
  isShowInkPerJobButton: boolean;
  isShowSystemGroupFilter: boolean;
  isDownloadInkConsumptionPerJobLoaded: boolean;
  isDownloadInkConsumptionPerJobLoading: boolean;
  filterState: ReportFilterState;
  filterData: ReportFilterData;
  downloadInkConsumptionPerJobError: string | object;
  allUserSystems: ReportSystem[];
  timeframe: TimeFrame;
  selectedSystemGroup: UserSystemGroupStrings;
  items: string[];
  values: TimeFrame[];
  jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage: string;
  jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading: boolean;
  activeReport: ReportTypesNew;
  screenWidth: number;
  customTimeframeProperties: any;
  selectedSerialsIds: string[];
  selectedDate: Date;
  handleLastUpdateClick: () => void;
  handlePageChange: (e: React.SyntheticEvent) => void;
  downloadInkConsumptionPerJobReport: ({}) => void;
  setTimeframe: (props: any) => void;
};
const DesktopPbiFilterTimeFrame = (props: DesktopPbiFilterTimeFrame) => {
  const {
    activeReport,
    isEmptyState,
    filterState,
    filterData,
    timeframe,
    items,
    values,
    isShowInkPerJobButton,
    selectedSystemGroup,
    downloadInkConsumptionPerJobError,
    isDownloadInkConsumptionPerJobLoading,
    allUserSystems,
    isDownloadInkConsumptionPerJobLoaded,
    isShowSystemGroupFilter,
    selectedSerialsIds,
    customTimeframeProperties,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading,
    screenWidth,
    selectedDate,
    downloadInkConsumptionPerJobReport,
    handlePageChange,
    handleLastUpdateClick,
    setTimeframe,
  } = props;
  //*by buisness requirementst here for desktop this component should be flexible;
  //* in most casese there should be buttons view, but for expample in Job analysis page there should be mixed(drop down with all timeframes + button for custom timeframe)
  //*so that is why there is the fuction which is detect view for desktop which depends on screen width and report type.
  const [timeFrameSelectionView, setTimeFrameSelectionView] = useState('buttons');
  useEffect(() => {
    if (activeReport === MainReportTypes.Overview) {
      // for now in overview there will be only buttons view
      setTimeFrameSelectionView(TimeFrameSelectorViews.Buttons);
    } else if (activeReport === MainReportTypes.Jobs) {
      screenWidth < 1920
        ? setTimeFrameSelectionView(TimeFrameSelectorViews.Mixed)
        : setTimeFrameSelectionView(TimeFrameSelectorViews.Buttons);
    } else {
      setTimeFrameSelectionView(TimeFrameSelectorViews.Buttons);
    }
  }, [screenWidth, activeReport]);

  const [isCustomTimeFrameOpen, setIsCustomTimeFrameOpen] = React.useState(false);
  const isTodayDisabled = !isHistorical({
    day: filterState.selectedDate,
    lastUpdate: filterData.endDate,
  });
  return (
    <Desktop>
      <LeftPart>
        {!isEmptyState && (
          <>
            {timeframe !== TimeFrameEnum.custom && (
              <HeaderButton
                data-automationid={`today_button`}
                disabled={isTodayDisabled}
                onClick={handleLastUpdateClick}
                variant="outlined"
                style={{
                  color: `${globalColors.regular.buttons.regularText}`,
                  textTransform: 'none',
                  borderColor: `${
                    isTodayDisabled
                      ? globalColors.regular.buttons.selectedBorder
                      : globalColors.regular.buttons.regularBorder
                  }`,
                }}>
                Today
              </HeaderButton>
            )}

            <PbiFilterDate
              isCustomTimeFrameOpen={isCustomTimeFrameOpen}
              setIsCustomTimeFrameOpen={setIsCustomTimeFrameOpen}
            />

            {timeframe && (
              <TimeFrameSelector
                timeframe={timeframe}
                view={timeFrameSelectionView}
                selectedSystemGroup={selectedSystemGroup}
                disabled={false}
                data-automationid={`choose_date_type`}
                items={items}
                values={values}
                value={timeframe}
                startDate={filterData.startDate}
                setTimeframe={setTimeframe}
                onChange={handlePageChange}
                setIsCustomTimeFrameOpen={setIsCustomTimeFrameOpen}
              />
            )}
          </>
        )}
      </LeftPart>

      <RightPart>
        {isShowInkPerJobButton && (
          <InkConsuptionPerJobButton
            jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading={
              jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading
            }
            jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage={
              jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage
            }
            downloadInkConsumptionPerJobReport={downloadInkConsumptionPerJobReport}
            selectedSystemGroup={selectedSystemGroup}
            timeframe={timeframe}
            selectedDateByGroup={filterState?.selectedDate}
            downloadInkConsumptionPerJobError={downloadInkConsumptionPerJobError as string}
            isDownloadInkConsumptionPerJobLoading={isDownloadInkConsumptionPerJobLoading}
            isDownloadInkConsumptionPerJobLoaded={isDownloadInkConsumptionPerJobLoaded}
            customTimeframeProperties={customTimeframeProperties}
            selectedSerialsIds={selectedSerialsIds}
            selectedDate={selectedDate}
            allUserSystemsCount={allUserSystems.length}
            selectedSystemSerials={filterState?.selectedSystemSerials}
          />
        )}
        {isShowSystemGroupFilter && <PbiFilterSystemGroup />}
      </RightPart>
    </Desktop>
  );
};
export default DesktopPbiFilterTimeFrame;
