import React from 'react';
import styled from 'styled-components';
import PbiFilterTimeline from '../PbiFilter/PbiFilterTimeframeContainer';
import PbiFilterDate from '../PbiFilter/PbiFilterDateContainer';
import { StyledComponentWithAnyType } from 'store/storeTypes';
import ButtonOk from '../_common/ButtonOk';
import ButtonCancel from '../_common/ButtonCancel';
import Modal from '../_common/Modal';

const PbiFilterDateContainer = styled.div``;

const ButtonCancelFilter = styled<StyledComponentWithAnyType>(ButtonCancel)`
  border: none;
  font-size: 14px;
  line-height: 20px;
  width: 97px;
`;

const ButtonOkFilter = styled<StyledComponentWithAnyType>(ButtonOk)`
  width: 97px;
  font-size: 14px;
  line-height: 20px;
`;

const FilterModal = styled(Modal)``;

const FilterCalendarContainer = styled.div`
  padding: 15px;
`;

const PopupButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  .MuiButtonBase-root {
    line-height: 35px;
  }
  button:not(:last-child) {
    margin-right: 16px;
  }
  @media (max-width: 400px) {
    button {
      flex-grow: 1;
    }
  }
`;
const DesktopFilterCalendarModal = props => {
  const { isOpen, onClose, onDone } = props;
  return (
    <FilterModal
      isOpen={isOpen}
      onClose={onClose}
      closeIconColor="#fff"
      maxWidth="sm"
      disableBackdropClick
      renderCloseButton={false}>
      <FilterCalendarContainer>
        <PbiFilterDateContainer>
          <PbiFilterDate />
        </PbiFilterDateContainer>

        <PbiFilterTimeline />

        <PopupButtons>
          {/* <ButtonCancelFilter size="small" onClick={onClose}>
          Cancel
        </ButtonCancelFilter> */}
          <ButtonOkFilter size="small" disabled={false} onClick={onDone}>
            Done
          </ButtonOkFilter>
        </PopupButtons>
      </FilterCalendarContainer>
    </FilterModal>
  );
};
export default DesktopFilterCalendarModal;
