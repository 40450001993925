import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Overview from './Overview';
import {
  overviewAchievementsRequest,
  overviewProductionAllChartsRequest,
  overviewImperssionsAvailabilityCardsRequest,
  overviewProductivityRequest,
  overviewProductionRateRequest,
  overviewImpressionsAvailabilityChartsRequest,
  overviewSpeedChartRequest,
  overviewUtilizationChartRequest,
  overviewReportMainRequest,
  overviewReportAvailabilityRequest,
  getMaxKeepAliveRequest,
} from '../../actions/overviewActions';
import { setActiveOverviewChart } from '../../actions/uiActions';
import { Store } from 'store/storeTypes';
import { selectedSystemSerialsSelectorForRequest } from 'selectors/filterSelectors';
import { TimeFrame } from 'models/ReportData';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import {
  overviewBySystemGroupAvailabilityChart,
  overviewBySystemGroupProductionChart,
  overviewBySystemGroupRecords,
  overviewBySystemGroupSpeedChart,
  overviewBySystemGroupUtilizationChart,
  overviewMaxKeepAliveSelector,
  overviewMaxKeepAliveUTCSelector,
} from 'reducers/overviewReducer';
import { uiOverviewActiveChartSelectorBySystemGroupSelector } from 'reducers/ui/overviewUiReducer';
import {
  dataUserSettingsSelector,
  refreshRealTimePeriodSelector,
} from 'reducers/userSettingsReducer';
import {
  customTimeFramePropertiesSelector,
  selectedSystemSerialsBySystemGroup,
} from 'reducers/reports/filterStateReducer';
import {
  pbiReportHeightSelector,
  pbiReportWidthSelector,
  screenHeightSelector,
  screenWidthSelector,
} from 'reducers/appUserReducer';
import { allSystemsSelector, endDateSelector } from 'reducers/reports/filterDataReducer';
import { isMachineFilterOpenSelector } from 'reducers/ui';

export interface OwnProps {
  timeFrame: TimeFrame;
  day?: Date;
}

const mapStateToProps = (state: Store) => {
  return {
    weekRecord: overviewBySystemGroupRecords(state),
    productionChart: overviewBySystemGroupProductionChart(state),
    utilizationChart: overviewBySystemGroupUtilizationChart(state),
    speedChart: overviewBySystemGroupSpeedChart(state),
    availabilityChart: overviewBySystemGroupAvailabilityChart(state),
    activeChart: uiOverviewActiveChartSelectorBySystemGroupSelector(state),
    selectedSystemGroup: systemGroupSelector(state),
    userSettings: dataUserSettingsSelector(state),
    selectedSystemsRequest: selectedSystemSerialsSelectorForRequest(state),
    selectedSystemSerials: selectedSystemSerialsBySystemGroup(state),
    allUserSystems: allSystemsSelector(state),
    screenHeight: screenHeightSelector(state),
    screenWidth: screenWidthSelector(state),
    pbiReportWidth: pbiReportWidthSelector(state),
    pbiReportHeight: pbiReportHeightSelector(state),
    customTimeframeProperties: customTimeFramePropertiesSelector(state),
    isMachineFilterOpen: isMachineFilterOpenSelector(state),
    endDate: endDateSelector(state),
    refreshTime: refreshRealTimePeriodSelector(state),
    maxKeepAlive: overviewMaxKeepAliveSelector(state),
    maxKeepAliveUtc: overviewMaxKeepAliveUTCSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      overviewAchievementsRequest,
      setActiveOverviewChart,
      overviewProductionAllChartsRequest,
      overviewImperssionsAvailabilityCardsRequest,
      overviewProductivityRequest,
      overviewProductionRateRequest,
      overviewImpressionsAvailabilityChartsRequest,
      overviewSpeedChartRequest,
      overviewUtilizationChartRequest,
      overviewReportMainRequest,
      overviewReportAvailabilityRequest,
      getMaxKeepAliveRequest,
    },
    dispatch
  );

export type IOverviewProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
