import { numberFormatterNew } from 'helpers/conversions';
import { getTicksSpeedChart } from 'helpers/overviewUtils';
import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { ReactComponent as FlagIcon } from '../../../assets/icons/timeLineFlagIcon.svg';
import { ISpeedChartProps } from './SpeedChart';

const MobileChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const MobileSpeedChart = (props: ISpeedChartProps) => {
  const { data, timeframe } = props;
  const barWidth = timeframe === 'day' ? 6 : 16;
  return (
    <MobileChartContainer data-automationid={`overview-Total-impressions-by-hour`}>
      <ResponsiveContainer width="100%" minHeight="174px">
        <BarChart
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          barGap={-barWidth}
          barSize={barWidth}
          data={data}>
          <CartesianGrid color="#C5D0DE" vertical={false} />
          <YAxis
            width={50}
            axisLine={false}
            tickFormatter={value => numberFormatterNew(value)}
            tick={{ fontSize: 12 }}
            tickLine={false}></YAxis>
          <XAxis
            axisLine={{ stroke: '#C5D0DE' }}
            tick={<CustomizedMobileTickSpeedChart data={data} isWeek={timeframe === 'week'} />}
            tickLine={false}
            padding={{ right: 3, left: 0 }}></XAxis>
          <Bar isAnimationActive={false} dataKey="totalAvg" fill="#E6E9ED" radius={[2, 2, 0, 0]} />
          <Bar isAnimationActive={false} dataKey="total" fill="#208CD7" radius={[2, 2, 0, 0]}></Bar>
        </BarChart>
      </ResponsiveContainer>
    </MobileChartContainer>
  );
};
export default MobileSpeedChart;

const CustomizedMobileTickSpeedChart = props => {
  const { x, y, width, index, isWeek, data } = props;
  if (index > 6) {
    return null;
  }
  const { tickXcoordinate, tickText, textXCoordinate } = getTicksSpeedChart(
    index,
    width,
    6,
    isWeek
  );

  return (
    <svg>
      <line stroke="#7F8FA4" x1={tickXcoordinate} y1={y} x2={tickXcoordinate} y2={y - 10}></line>
      {tickText === 'flag' ? (
        <FlagIcon x={textXCoordinate + 3} y={y + 5} />
      ) : (
        <text
          fontSize={12}
          x={textXCoordinate}
          y={y + 20}
          fill="#7F8FA4
        ">
          {tickText}
        </text>
      )}
    </svg>
  );
};
