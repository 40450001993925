import React, { useContext } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import Welcome from 'components/Welcome/WelcomeContainer';
import { AuthCallback } from 'components/AuthCallback/AuthCallback';
import Shell from './components/Shell/ShellContainer';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import {
  authCallbackPath,
  defaultAppPath,
  userManagementPath,
  goalsPath,
  reportsPath,
  settingsPath,
  apiManagementPath,
  systemsSubReportsPath,
  schedulerPath,
  unsubscribePath,
  superUserLogin,
  privacyPolicyPath,
  termsOfUsePath,
  productTCPath,
  documentViewerPath,
  configurationPath,
} from 'contracts/constants';

import { AuthContext } from 'services/AuthProvider';
import UnsubscribeContainer from 'components/UnsubscribePage';
import SuperUserLoginPageContainer from 'components/SuperUserLoginPage';
import DocViewer from 'components/DocViewerPage/DocViewer';
export const App = () => {
  const authService = useContext(AuthContext);
  const renderWelcomeComponent = (routerProps: RouteComponentProps) => {
    return <Welcome authService={authService} {...routerProps} />;
  };
  const renderSuperUserLoginPage = (routerProps: RouteComponentProps) => {
    return <SuperUserLoginPageContainer authService={authService} {...routerProps} />;
  };
  const renderUnsubscribeComponent = (routerProps: RouteComponentProps) => {
    return <UnsubscribeContainer authService={authService} {...routerProps} />;
  };

  const renderShellComponent = (routerProps: RouteComponentProps) => {
    return <Shell authService={authService} {...routerProps} />;
  };

  const renderAuthCallBackComponent = (routerProps: RouteComponentProps) => {
    return <AuthCallback authService={authService} {...routerProps} />;
  };

  return (
    <Switch>
      <Route path={superUserLogin} render={renderSuperUserLoginPage} />
      <Route path={unsubscribePath} render={renderUnsubscribeComponent} />
      <Route path={authCallbackPath} render={renderAuthCallBackComponent} />
      <Route path={documentViewerPath} component={DocViewer} />
      <ProtectedRoute
        path={[
          userManagementPath,
          goalsPath,
          systemsSubReportsPath,
          reportsPath,
          settingsPath,
          apiManagementPath,
          schedulerPath,
          privacyPolicyPath,
          termsOfUsePath,
          productTCPath,
          configurationPath,
        ]}
        render={renderShellComponent}
      />
      <Route path={defaultAppPath} render={renderWelcomeComponent} />
    </Switch>
  );
};
