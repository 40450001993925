import { TimeLineDayViewUnit } from 'components/SystemPage/TimeLineChart/DayViewBarChart';
import { DayViewType } from 'store/storeTypes';

export function mapCahrtData(array) {
  if (!array) return;
  const result = new Array();
  array.forEach(element => {
    const { Error, Maintenance, Idle, Printing, NotReporting } = element;
    const newErrorValue = numberToRoundedPercent(Error);
    const newMaintenanceValue = numberToRoundedPercent(Maintenance);
    const newIdleValue = numberToRoundedPercent(Idle);
    const newPrintingValue = numberToRoundedPercent(Printing);
    const newNotReportingValue = numberToRoundedPercent(NotReporting);
    const date = element.date;
    const stringDate = element.stringDate;
    const newFormattedElement = {
      date,
      stringDate,
      Error: newErrorValue,
      Maintenance: newMaintenanceValue,
      NotReporting: newNotReportingValue,
      Idle: newIdleValue,
      Printing: newPrintingValue,
    };
    result.push(newFormattedElement);
  });
  return result;
}
export function numberToRoundedPercent(value) {
  return Math.round(value * 100);
}

export const mapResposeToTimeLineDayArray = (
  arr: Array<TimeLineDayViewUnit>,
  type: DayViewType
) => {
  let index = 0;
  let currentMinuteValue = 0;
  //*different step for different type resolution. 'hour' or 'day'
  const step = type === DayViewType.hour ? 1 : 5;
  const resultArray = new Array();
  arr.forEach(element => {
    const curretnElement = element;
    const status = curretnElement.systemStatus ? curretnElement.systemStatus : 'noData';
    const newElement = {
      ...curretnElement,
      systemStatus: status,
      minute: currentMinuteValue,
      height: 1,
    };
    resultArray.push(newElement);
    index++;
    currentMinuteValue += step;
    if (currentMinuteValue === 60 && type === DayViewType.hour) {
      // in case of apollo group we got 60*24records and minutes from server in each record just incremented - we need to reset it each hour
      currentMinuteValue = 0;
    }
  });

  return resultArray;
};

//*chart helpers

export function getSvgTextOffset24HourView(index) {
  const TEXT_OFFSET = -14;
  if (index === 0) {
    return TEXT_OFFSET - 6;
  }
  if (index === 2) {
    return TEXT_OFFSET;
  }
  return TEXT_OFFSET - 4;
}
export function getSvgTextOffset1HourView(index: number, width: number) {
  if (index === 4) {
    //* offset for  hour:40
    return -12;
  }
  if (index === 6) {
    return -15;
  }
  //*for all other ticks example: hour:10, hour:20 etc...
  return -11;
}
export function getTickTopBottomCoordinates1HourView(currentElementIndex: number, y: number) {
  //*harcoded long tick indexes
  const LONG_TICK_INDEX = [4, 6, 8];
  if (currentElementIndex === 0 || currentElementIndex === 12) {
    return { topY: 0, bottomY: y + 7 };
  }
  if (LONG_TICK_INDEX.includes(currentElementIndex)) {
    return { topY: y - 10, bottomY: y + 7 };
  } else {
    return { topY: y - 10, bottomY: y };
  }
}
export function getTickTopBottomCoordinates24HourView(index: number, y: number) {
  const longTicks = [0, 8, 12, 16, 24];
  if (longTicks.includes(index)) {
    if (index === 0 || index === 24) {
      return { topY: 0, bottomY: y + 7 };
    }
    return { topY: y - 10, bottomY: y + 7 };
  } else {
    return { topY: y - 10, bottomY: y };
  }
}
export function getXCoordinates24HourView(index: number, width: number, intervalsCount: number) {
  const RANGE = width / intervalsCount;
  const START = 43;
  const END = width + 44;
  if (index === 0) {
    return START;
  }
  if (index === 24) {
    return END + 2;
  }

  return START + index * RANGE;
}

export function getXCoordinates1HourView(index: number, width: number, intervalsCount: number) {
  const RANGE = width / intervalsCount;
  const START = 44;
  const END = width + 46;

  if (index === 0) {
    return START;
  }
  if (index === 12) {
    return END;
  }
  if (
    index === 1 ||
    index === 3 ||
    index === 5 ||
    index === 7 ||
    index === 8 ||
    index === 9 ||
    index === 10 ||
    index === 11
  ) {
    return START + index * RANGE + 1;
  }

  return START + index * RANGE - 1;
}
export function convertHourtFrom12To24Format(hour: number, modificator: string) {
  if (modificator === 'AM') {
    return +hour;
  }
  if (modificator === 'PM') {
    return Number(+hour + 12);
  }
}
export function convertHourFrom24To12Format(hour: number) {
  if (hour > 12) {
    return Number(hour - 12);
  }
  return +hour;
}
export function isPropsInitializedOnTimeline24HViewTooltip(props): boolean {
  if (props.label && props.payload && props.coordinate) {
    const { label, coordinate, payload } = props;
    if (coordinate.x && coordinate.y && payload && payload[0] && label) {
      const [payloadObject] = payload;
      if (payloadObject && payloadObject.payload) {
        return true;
      }
    }
  } else {
    return false;
  }
}
export function getXTooltipOffsetByHour(hour: number, width: number): number {
  const STEP = width / 24;
  const START = 43;
  const leftShift = hour >= 5 ? 2 : 0;

  if (hour === 0) {
    return START;
  } else {
    return START - leftShift + hour * STEP;
  }
}
export function getTooltipWidth(width: number, intervals: number) {
  return width / intervals + 4;
}

export function getSvgTextFor1hourViewGraph(
  index: number,

  timeLineSelectedHour: number,
  allTickPointIndexes: Array<number>,
  currentElementIndex: number,
  timeLineAMPMmodificator: string
): string | number | null {
  let resultHour =
    timeLineSelectedHour > 11 ? `${timeLineSelectedHour - 12}` : `${timeLineSelectedHour}`;
  if (timeLineAMPMmodificator === 'PM' && resultHour === '0') {
    resultHour = '12';
  }
  if (timeLineAMPMmodificator === 'AM' && resultHour === '0') {
    resultHour = '0';
  }
  const hourNames = [
    `${resultHour}:00 ${timeLineAMPMmodificator}`,
    `${resultHour}:10 ${timeLineAMPMmodificator}`,
    `${resultHour}:20 ${timeLineAMPMmodificator}`,
    `${resultHour}:30 ${timeLineAMPMmodificator}`,
    `${resultHour}:40 ${timeLineAMPMmodificator}`,
    `${resultHour}:50 ${timeLineAMPMmodificator}`,
    'lastElement',
  ];

  if (allTickPointIndexes.includes(index) && !isNaN(timeLineSelectedHour)) {
    return hourNames[currentElementIndex];
  } else {
    return null;
  }
}
