import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import config from 'config/config';
const logger = new ApplicationInsights({
  config: {
    instrumentationKey: config.LOGGER_INSTRUMENTAION_KEY,
  },
});
logger.loadAppInsights();

export const sendMetric = (
  name: string,
  startTime: number,
  properties?: { [key: string]: string | number | Record<string, unknown> }
) => {
  logger.trackMetric({
    name,
    average: new Date().getTime() - startTime,
    properties,
  });
};

export default logger;
