import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
  font-family: Rubik, sans-serif;
  font-size: 15px;
  color: #354052;
  text-decoration: none;
  line-height: 18px;

  &.active,
  &.active li {
    color: #2ea1f8;
    font-weight: 500;
  }
`;

export default StyledNavLink;
