import { createReducer } from 'typesafe-actions';
import { actions, RootAction } from 'actions';

const { toggleSchedulerAC } = actions.schedulerApi;
const INIT_STATE = '0';

export default createReducer<string, RootAction>(INIT_STATE).handleAction(
  toggleSchedulerAC,
  (state, action) => action.payload
);
