import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useTranslation } from 'react-i18next';

const OverviewMobile = React.lazy(() => import('./OverviewMobile'));
const OverviewDesktop = React.lazy(() => import('./OverviewDesktop'));

import { IOverviewProps } from './OverviewContainer';
import { getRequestForTimeFrame } from 'helpers/date';
import { TimeFrame } from 'models/ReportData';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import { isDateInCurrentSelectedRange, useInterval } from 'helpers/intervalUtils';

const Overview = memo((props: IOverviewProps) => {
  const { t } = useTranslation();
  const {
    overviewAchievementsRequest,
    overviewImperssionsAvailabilityCardsRequest,
    overviewProductionRateRequest,
    overviewProductivityRequest,
    overviewImpressionsAvailabilityChartsRequest,
    overviewSpeedChartRequest,
    overviewUtilizationChartRequest,
    overviewReportMainRequest,
    overviewReportAvailabilityRequest,
    getMaxKeepAliveRequest,
    timeFrame,
    selectedSystemGroup,
    day,
    selectedSystemsRequest,
    allUserSystems,
    screenWidth,
    screenHeight,
    pbiReportHeight,
    pbiReportWidth,
    customTimeframeProperties,
    isMachineFilterOpen,
    endDate,
    refreshTime,
  } = props;

  const { startInterval, clearExistingInterval } = useInterval();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (pbiReportWidth < 1366 || pbiReportHeight < 567) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [pbiReportHeight, pbiReportWidth, screenWidth, screenHeight]);

  const [debImperssionsAvailabilityCardsRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean) => {
      return overviewImperssionsAvailabilityCardsRequest(args);
    },
    1
  );
  const [debProductionRateRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean) => {
      return overviewProductionRateRequest(args);
    },
    1
  );
  const [debProductivityRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean) => {
      return overviewProductivityRequest(args);
    },
    1
  );

  const [debImpressionsAvailabilityChartsRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean) => {
      return overviewImpressionsAvailabilityChartsRequest(args, isSilent);
    },
    1
  );

  const [debSpeedChartRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean) => {
      return overviewSpeedChartRequest(args);
    },
    1
  );

  const [debUtilizationChartRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean) => {
      return overviewUtilizationChartRequest(args);
    },
    1
  );

  const [debAchievementsRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean, isRealTimeRequest: boolean = false) => {
      return overviewAchievementsRequest(args, isSilent, isRealTimeRequest);
    },
    1
  );
  const [debMaxKeepAliveRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean, isRealTimeRequest: boolean = false) => {
      //*all requests in application sends to server in body array with systems. and if all systems selected we send empty array
      //* but that exact request does not support it - that is why we have to send all serial numbers
      const argsObjWithAllSelectedSystems = { ...args, systems: selectedSystemsRequest };
      return getMaxKeepAliveRequest(argsObjWithAllSelectedSystems, isSilent, isRealTimeRequest);
    },
    1
  );
  const [debOverviewReportMainRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean, isRealTimeRequest: boolean = false) => {
      return overviewReportMainRequest(args, isSilent, isRealTimeRequest);
    },
    1
  );
  const [debOverviewReportAvailabilityRequest] = useDebouncedCallback(
    (args: Record<string, unknown>, isSilent: boolean, isRealTimeRequest: boolean = false) => {
      return overviewReportAvailabilityRequest(args, isSilent, isRealTimeRequest);
    },
    1
  );

  const getData = (
    day: Date,
    timeFrame: TimeFrame,
    isSilent = false,
    selectedSystemGroup?: UserSystemGroupStrings
  ) => {
    const filterByPage = getRequestForTimeFrame(day, timeFrame, customTimeframeProperties);
    if (selectedSystemsRequest) {
      //*if selected all systems we send [] instead of string with all systems - optimization logic
      filterByPage['systems'] =
        selectedSystemsRequest.length === allUserSystems.length ? [] : selectedSystemsRequest;
    }
    filterByPage['systemGroup'] = selectedSystemGroup;
    //* by new buisness logic there will be another requests for Apollo
    if (selectedSystemGroup === UserSystemGroup.Apollo) {
      //*requests for Apollo
      debMaxKeepAliveRequest(filterByPage, isSilent);
      debOverviewReportMainRequest(filterByPage, isSilent);
      debOverviewReportAvailabilityRequest(filterByPage, isSilent);
      debAchievementsRequest(filterByPage, isSilent);
    } else {
      //*for DTF and DTG
      debAchievementsRequest(filterByPage, isSilent);
      debImperssionsAvailabilityCardsRequest(filterByPage, isSilent);
      debProductionRateRequest(filterByPage, isSilent);
      debImpressionsAvailabilityChartsRequest(filterByPage, isSilent);
      debSpeedChartRequest(filterByPage, isSilent);
      debUtilizationChartRequest(filterByPage, isSilent);
      selectedSystemGroup === 'DTG' && debProductivityRequest(filterByPage, isSilent);
    }
  };

  //*this useEffect is special only for apollo group - because we have completely another async logic for this group
  const isTodayDateInCurrentSelectedRange = isDateInCurrentSelectedRange({
    selectedDay: day,
    timeFrame,
    customTimeframeProperties,
    endDate,
  });

  //this useEffect is used only for apollo group
  useEffect(() => {
    if (selectedSystemGroup !== UserSystemGroup.Apollo) {
      return;
    }

    const filterByPage = getRequestForTimeFrame(day, timeFrame, customTimeframeProperties);
    filterByPage['systemGroup'] = selectedSystemGroup;
    if (selectedSystemsRequest) {
      filterByPage['systems'] =
        selectedSystemsRequest.length === allUserSystems.length ? [] : selectedSystemsRequest;
    }

    clearExistingInterval();

    // Call immediately and set up the interval if the condition is true
    if (isTodayDateInCurrentSelectedRange) {
      startInterval(
        filterByPage,
        refreshTime,
        debMaxKeepAliveRequest,
        debOverviewReportMainRequest,
        debOverviewReportAvailabilityRequest,
        debAchievementsRequest
      );
    } else {
      debMaxKeepAliveRequest(filterByPage, false);
      debOverviewReportMainRequest(filterByPage, false);
      debOverviewReportAvailabilityRequest(filterByPage, false);
      debAchievementsRequest(filterByPage, false);
    }

    return () => {
      clearExistingInterval();
    };
  }, [
    timeFrame,
    day,
    selectedSystemsRequest,
    selectedSystemGroup,
    customTimeframeProperties,
    isTodayDateInCurrentSelectedRange,
    startInterval,
    clearExistingInterval,
    debMaxKeepAliveRequest,
    debOverviewReportMainRequest,
    debOverviewReportAvailabilityRequest,
    debAchievementsRequest,
  ]);

  useEffect(() => {
    if (selectedSystemGroup === UserSystemGroup.Apollo) {
      return;
    }
    if (timeFrame) {
      getData(day, timeFrame, false, selectedSystemGroup);
    }
  }, [timeFrame, day, selectedSystemsRequest, selectedSystemGroup, customTimeframeProperties]);

  return <>{isMobile ? <OverviewMobile {...props} /> : <OverviewDesktop {...props} />}</>;
});

export default Overview;
