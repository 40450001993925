import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import PageContainer from '../_common/PageContainer';
import { PageHeaderUIContainer } from '../_common/PageHeaderUi';
import { RadioLangGroup } from 'components/_common/RadioLangGroup';
import { ToggleGroup } from 'components/_common/ToggleGroup';
import { LengthUnits, RTRMetricUnit, UserSystemGroup } from 'store/storeTypes';
import LinearProgress from 'components/_common/LinearProgress';
import { defaultAppPath } from '../../contracts/constants';
import { MobileReportWrapper } from 'components/Overview/OverviewMobile';

const Icon = styled.img.attrs({
  src: '/img/setting-icon.svg',
})`
  margin-right: 15px;
  width: 30px;
  height: 30px;
  @media (max-width: 360px) {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 440px) {
    margin: 10px 0;
  }
`;

const Title = styled.h1`
  font-family: Rubik, sans-serif;
  font-weight: normal;
  font-size: 22px;
  color: #354052;
`;

const Section = styled.div`
  background-color: white;
  margin-bottom: 15px;
  border: 1px solid #e6eaee;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 750px) {
    &:last-child {
      margin-bottom: 15px;
    }
    padding-bottom: 30px;
  }
`;

const SectionTitleWrapper = styled.div`
  border-bottom: 1px solid #c5d0de;
  padding: 15px;
`;

const SectionTitle = styled.h3`
  font-family: Segoe UI, sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #323c47;
  @media (max-width: 750px) {
    font-size: 16px;
  }
`;

const SectionList = styled.div`
  padding: 0 24px;
`;

const SectionListItem = styled.div`
  border-bottom: 1px solid #c5d0de;
  padding: 30px 0;
`;

const SectionListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SectionListItemLeftPart = styled.div``;

const SectionListItemTitle = styled.p`
  margin: 0;
  font-family: Segoe UI, sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #323c47;
`;

const SectionListItemRightPart = styled.div``;

const SettingsWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const LeftPart = styled.div`
  flex: 1;
  margin-right: 15px;
  @media (max-width: 750px) {
    margin-right: 0;
    flex: auto;
  }
`;

const RightPart = styled.div`
  flex: 1;
  @media (max-width: 750px) {
    flex: auto;
  }
`;
const MobileSettings = props => {
  const {
    userSettings,
    updatedSettings,
    handleChangeDefaultValue,
    handleChangeLength,
    handleChangeMetrics,
    handleChangeLang,
  } = props;
  const { t } = useTranslation();

  return (
    <MobileReportWrapper reportHeight={'850px'}>
      {/* If userSystemGroup has ['DTF'] or ['DTF', 'DTG'] show settings menu item
  else REDIRECT TO DEFAULT PATH */}
      {userSettings.data.userSystemGroup?.includes(UserSystemGroup.DTF) ? (
        <PageContainer>
          <PageHeaderUIContainer>
            <TitleWrapper>
              <Icon />
              <Title>{t('settings.settings')}</Title>
            </TitleWrapper>
          </PageHeaderUIContainer>
          {!userSettings.isLoading ? (
            <SettingsWrapper>
              <LeftPart>
                {userSettings.data.userSystemGroup?.length === 2 && (
                  <Section>
                    <SectionTitleWrapper>
                      <SectionTitle>{t('settings.preferences')}</SectionTitle>
                    </SectionTitleWrapper>
                    <SectionList>
                      <SectionListItem>
                        <SectionListItemWrapper>
                          <SectionListItemLeftPart>
                            <SectionListItemTitle>{t('settings.defView')}</SectionListItemTitle>
                          </SectionListItemLeftPart>
                          <SectionListItemRightPart>
                            <ToggleGroup
                              value={
                                updatedSettings &&
                                updatedSettings.hasOwnProperty('defaultSystemGroup')
                                  ? updatedSettings.defaultSystemGroup
                                  : userSettings.data?.defaultSystemGroup
                              }
                              onChange={handleChangeDefaultValue}
                              name={'defaultSystemGroup'}
                              data={[
                                {
                                  value: UserSystemGroup.DTF,
                                  label: 'DTF',
                                },
                                {
                                  value: UserSystemGroup.DTG,
                                  label: 'DTG',
                                },
                              ]}
                            />
                          </SectionListItemRightPart>
                        </SectionListItemWrapper>
                      </SectionListItem>
                    </SectionList>
                  </Section>
                )}
                <Section>
                  <SectionTitleWrapper>
                    <SectionTitle>{t('settings.units')}</SectionTitle>
                  </SectionTitleWrapper>
                  <SectionList>
                    <SectionListItem>
                      <SectionListItemWrapper>
                        <SectionListItemLeftPart>
                          <SectionListItemTitle>{t('settings.length')}</SectionListItemTitle>
                        </SectionListItemLeftPart>
                        <SectionListItemRightPart>
                          <ToggleGroup
                            value={
                              updatedSettings && updatedSettings.hasOwnProperty('lengthUnits')
                                ? updatedSettings.lengthUnits
                                : userSettings.data?.lengthUnits
                            }
                            onChange={handleChangeLength}
                            name={'lengthUnits'}
                            data={[
                              {
                                value: LengthUnits.Yard,
                                label: 'Yard',
                              },
                              {
                                value: LengthUnits.Meter,
                                label: 'Meter',
                              },
                            ]}
                          />
                        </SectionListItemRightPart>
                      </SectionListItemWrapper>
                    </SectionListItem>
                    <SectionListItem>
                      <SectionListItemWrapper>
                        <SectionListItemLeftPart>
                          <SectionListItemTitle>{t('settings.rtrMetrics')}</SectionListItemTitle>
                        </SectionListItemLeftPart>
                        <SectionListItemRightPart>
                          <ToggleGroup
                            value={
                              updatedSettings && updatedSettings.hasOwnProperty('rtrMetricUnit')
                                ? updatedSettings.rtrMetricUnit
                                : userSettings.data?.rtrMetricUnit
                            }
                            name={'rtrMetricUnit'}
                            onChange={handleChangeMetrics}
                            data={[
                              {
                                value: RTRMetricUnit.Linear,
                                label: 'Linear',
                              },
                              {
                                value: RTRMetricUnit.Square,
                                label: 'Square',
                              },
                            ]}
                          />
                        </SectionListItemRightPart>
                      </SectionListItemWrapper>
                    </SectionListItem>
                  </SectionList>
                </Section>
              </LeftPart>
              <RightPart>
                <Section style={{ height: '100%' }}>
                  <SectionTitleWrapper>
                    <SectionTitle>{t('settings.lang')}</SectionTitle>
                  </SectionTitleWrapper>
                  <SectionList>
                    <RadioLangGroup
                      onChange={handleChangeLang}
                      value={
                        updatedSettings && updatedSettings.hasOwnProperty('portalLanguage')
                          ? updatedSettings.portalLanguage
                          : userSettings.data?.portalLanguage
                      }
                      data={[
                        {
                          flagIcon: 'US',
                          flagName: 'English (USA)',
                          value: 'en-US',
                          disabled: true,
                        },
                      ]}
                    />
                  </SectionList>
                </Section>
              </RightPart>
            </SettingsWrapper>
          ) : (
            <LinearProgress />
          )}
        </PageContainer>
      ) : (
        <Redirect to={defaultAppPath} />
      )}
    </MobileReportWrapper>
  );
};

export default MobileSettings;
