import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MobileDrawerOverwievIcon } from '../../assets/icons/MobileDrawerOverviewIcon.svg';
import { ReactComponent as MobileDrawerSystemAnalysisIcon } from '../../assets/icons/MobileDrawerSystemAnalysisIcon.svg';
import { ReactComponent as MobileDrawerJobAnalysisIcon } from '../../assets/icons/MobileDrawerJobAnalysisIcon.svg';
import { ReportTypesNew } from 'models/ReportData';
import { NavlinkType } from './ShellReportsNav';

const LogoLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
`;

const LinkItem = styled.div<{ isActive: boolean }>`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  background-color: ${props => (props.isActive ? '#EDF7FF' : 'ffffff')};
  align-items: center;
  width: 100%;
  height: 70px;
`;
const StatusBar = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 100%;
  background-color: ${props => (props.isActive ? '#2ea1f8' : 'transparent')};
  margin-right: 20px;
`;
const IconPlaceHolder = styled.div`
  margin-right: 20px;
`;
const Text = styled.div<{ isActive: boolean }>`
  color: ${props => (props.isActive ? '#2ea1f8' : '#354052')};
`;

type MobileShellReportsNavItemProps = {
  activeReport: ReportTypesNew;
  link: NavlinkType;
  currentReport: string;
  setIsOpen: (isOpen: boolean) => void;
};
const MobileShellReportsNavItem = (props: MobileShellReportsNavItemProps) => {
  const { link, currentReport, activeReport, setIsOpen } = props;

  const [isActive, setIsActive] = React.useState(false);

  useEffect(() => {
    setIsActive(currentReport === link.path);
  }, [currentReport, activeReport]);
  const getLinkIcon = (linkText: string, currentReport: string) => {
    switch (linkText) {
      case 'Overview':
        return (
          <MobileDrawerOverwievIcon
            fill={currentReport === '/reports/overview' ? '#2EA1F8' : '#7F8FA4'}
          />
        );
      case 'Systems Analysis':
        return (
          <MobileDrawerSystemAnalysisIcon
            fill={currentReport === '/reports/systems' ? '#2EA1F8' : '#7F8FA4'}
          />
        );
      case 'Jobs Analysis':
        return (
          <MobileDrawerJobAnalysisIcon
            fill={currentReport === '/reports/jobs' ? '#2EA1F8' : '#7F8FA4'}
          />
        );
      default:
        return <MobileDrawerOverwievIcon />;
    }
  };
  const onClickHandler = () => {
    setIsOpen(false);
  };
  return (
    <LogoLink
      to={link.path}
      onClick={() => onClickHandler()}
      data-automationid={`chooseReportButton_${link.text}`}>
      <LinkItem isActive={isActive}>
        <StatusBar isActive={isActive} />
        <IconPlaceHolder>{getLinkIcon(link.text, currentReport)}</IconPlaceHolder>
        <Text isActive={isActive}>{link.text}</Text>
      </LinkItem>
    </LogoLink>
  );
};
export default MobileShellReportsNavItem;
