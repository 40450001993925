import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';

import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import {
  changeNickNameAC,
  getSystemAnalysisAC,
  setInitialValueToChangeNameField,
  setSelectedSystemName,
  getSystemAnalysisNickNamesAC,
  setSortByHeaderCell,
  checkSystemAnalysisReportValidToDownloadAC,
  downloadSystemAnalysisReportAC,
} from '../actions/systemAnalysisActions';
import { setActiveReportAC } from '../actions/reportUiActions';
import {
  ISystemAnalysisState,
  Store,
  systemReportByType,
  NickNameRecord,
} from '../store/storeTypes';
import { sortDataByOrderDesc } from 'helpers/sorting';
import { ISystemReport } from 'models/SystemAnalysisData';

const INIT_STATE: ISystemAnalysisState = {
  isLoading: false,
  data: {
    DTF: [],
    DTG: [],
    Apollo: [],
    '': [],
  },
  selectedSystemName: '',
  sortBy: 'production',
  sortingOrder: undefined,
  view: '',
  changingNameInputValue: '',
  newNickName: 'not setted',
  isChangeNameLoading: false,
  isChangeNameLoaded: false,
  isNickNamesLoading: false,
  isNickNamesLoaded: false,
  isNickNamesLoadingError: '',
  nickNames: new Array<NickNameRecord>(),
  downloadReportValidation: {
    DTG: {
      isDownloadReportValidationLoading: false,
      isDownloadReportValidationLoaded: false,
      isDownloadReportValidationLoadingError: '',
      isValidToDownload: false,
      isFileDownloading: false,
      isFileDownloaded: false,
      fileDownloadError: '',
    },
    DTF: {
      isDownloadReportValidationLoading: false,
      isDownloadReportValidationLoaded: false,
      isDownloadReportValidationLoadingError: '',
      isValidToDownload: false,
      isFileDownloading: false,
      isFileDownloaded: false,
      fileDownloadError: '',
    },
    Apollo: {
      isDownloadReportValidationLoading: false,
      isDownloadReportValidationLoaded: false,
      isDownloadReportValidationLoadingError: '',
      isValidToDownload: false,
      isFileDownloading: false,
      isFileDownloaded: false,
      fileDownloadError: '',
    },
  },
};

const reducer = createReducer<ISystemAnalysisState, any>(INIT_STATE)
  .handleAction(checkSystemAnalysisReportValidToDownloadAC.request, (state, action) => {
    const { selectedSystemGroup } = action.payload;

    return {
      ...state,
      downloadReportValidation: {
        [selectedSystemGroup]: {
          isDownloadReportValidationLoading: true,
          isDownloadReportValidationLoaded: false,
          isDownloadReportLoaded: false,
          isDownloadReportLoadingError: '',
          isValidToDownload: false,
        },
      },
    };
  })
  .handleAction(checkSystemAnalysisReportValidToDownloadAC.failure, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      downloadReportValidation: {
        [selectedSystemGroup]: {
          isDownloadReportValidationLoaded: false,
          isDownloadReportValidationLoading: false,
          isDownloadReportLoadingError: 'error',
          isValidToDownload: false,
        },
      },
    };
  })
  .handleAction(checkSystemAnalysisReportValidToDownloadAC.success, (state, action) => {
    const { selectedSystemGroup, status } = action.payload;
    let message = '';
    if (status === 204) {
      message = 'No data to download For This Period';
    }
    if (status === 205) {
      message = 'Too much data to download, please select shorter period';
    }
    if (status === 200) {
      message = 'Ready to download';
    }
    return {
      ...state,
      downloadReportValidation: {
        [selectedSystemGroup]: {
          isDownloadReportValidationLoading: false,
          isDownloadReportValidationLoaded: true,
          isDownloadReportLoadingError: message,
          isValidToDownload: status === 200,
        },
      },
    };
  })
  .handleAction(downloadSystemAnalysisReportAC.request, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      downloadReportValidation: {
        [selectedSystemGroup]: {
          isFileDownloading: true,
          isFileDownloaded: false,
          fileDownloadError: '',
        },
      },
    };
  })
  .handleAction(downloadSystemAnalysisReportAC.failure, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      downloadReportValidation: {
        [selectedSystemGroup]: {
          isFileDownloading: false,
          isFileDownloaded: false,
          fileDownloadError: 'error',
        },
      },
    };
  })
  .handleAction(downloadSystemAnalysisReportAC.success, (state, action) => {
    const { selectedSystemGroup } = action.payload;
    return {
      ...state,
      downloadReportValidation: {
        [selectedSystemGroup]: {
          isFileDownloading: false,
          isFileDownloaded: true,
          fileDownloadError: '',
          isDownloadReportLoadingError: 'Report has been downloaded',
          isValidToDownload: false,
        },
      },
    };
  })
  .handleAction(getSystemAnalysisNickNamesAC.request, (state, action) => {
    const isSilent = action.payload;
    if (isSilent) {
      return { ...state };
    } else {
      return {
        ...state,
        isNickNamesLoading: true,
        isNickNamesLoaded: false,
        isNickNamesLoadingError: '',
        nickNames: new Array<NickNameRecord>(),
      };
    }
  })
  .handleAction(getSystemAnalysisNickNamesAC.success, (state, action) => {
    return {
      ...state,
      isNickNamesLoading: false,
      isNickNamesLoaded: true,
      isNickNamesLoadingError: '',
      nickNames: [...action.payload],
    };
  })
  .handleAction(getSystemAnalysisNickNamesAC.failure, (state, action) => {
    return {
      ...state,
      isNickNamesLoading: false,
      isNickNamesLoaded: false,
      isNickNamesLoadingError: 'nick names loading error',
      nickNames: new Array<NickNameRecord>(),
    };
  })
  .handleAction(getSystemAnalysisAC.request, (state, action) => {
    if (action.payload.isSilent) {
      return { ...state };
    } else {
      return {
        ...state,
        isLoading: true,
      };
    }
  })
  .handleAction(getSystemAnalysisAC.success, (state, action) => {
    const reducedData: systemReportByType = { DTF: [], DTG: [], Apollo: [], '': [] };
    reducedData[action.payload.systemGroup] = action.payload.systemsReports;
    const currentFilter = state.sortBy;
    const nickNamesArray = state.nickNames;
    const currentOrder = state.sortingOrder;
    const isEverReportedSystems = action.payload.isEverReportedSystems;
    const newSortedData = sortDataByOrderDesc(
      reducedData[action.payload.systemGroup],
      currentFilter,
      currentOrder,
      nickNamesArray,
      isEverReportedSystems
    );
    const newReducedData = { ...reducedData, [action.payload.systemGroup]: newSortedData };

    return {
      ...state,
      isLoading: false,
      data: newReducedData,
    };
  })
  .handleAction(getSystemAnalysisAC.failure, state => {
    return { ...state, isLoading: false };
  })
  .handleAction(setSelectedSystemName, (state, action) => {
    return { ...state, selectedSystemName: action.payload };
  })

  .handleAction(setInitialValueToChangeNameField, (state, action) => {
    return { ...state, changingNameInputValue: action.payload.systemName };
  })
  .handleAction(changeNickNameAC.failure, (state, action) => {
    return {
      ...state,
      changeNickNameError: 'error',
      isChangeNameLoading: false,
      isChangeNameLoaded: false,
    };
  })
  .handleAction(changeNickNameAC.request, (state, action) => {
    return {
      ...state,
      isChangeNameLoading: true,
      isChangeNameLoaded: false,
      changeNickNameError: '',
    };
  })
  .handleAction(changeNickNameAC.success, (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      isChangeNameLoading: false,
      isChangeNameLoaded: true,
      changeNickNameError: '',
      newNickName: data.nickName,
    };
  })
  .handleAction(setActiveReportAC, (state, action) => {
    return { ...state, newNickName: 'not setted' };
  })
  .handleAction(setSortByHeaderCell, (state, action) => {
    const { fieldName, selectedSystemGroup, view, isEverReportedSystems } = action.payload;
    const isChangeOrder = state.sortBy === fieldName;
    const order = state.sortingOrder;
    const newOrder: 'asc' | 'desc' = getNewOrder(order, isChangeOrder);
    const sortingFieldName = fieldName === 'systemName' ? 'serialNumber' : fieldName;
    const nickNamesArray = state.nickNames;
    const newData = sortDataByOrderDesc(
      state.data[selectedSystemGroup],
      sortingFieldName,
      newOrder,
      nickNamesArray,
      isEverReportedSystems
    );
    const sortedData: systemReportByType = { Apollo: [], DTF: [], DTG: [], '': [] };
    sortedData[selectedSystemGroup] = newData;
    return {
      ...state,
      sortBy: fieldName,
      sortingOrder: newOrder,
      data: sortedData,
      view: view,
    };
  });
export type OrderType = 'asc' | 'desc' | undefined;
const getNewOrder = (order: OrderType, isChangeOrder: boolean): OrderType => {
  if (!order) {
    //*in case of first render
    return 'desc';
  }
  const newOrder = order === 'asc' ? 'desc' : 'asc';
  return isChangeOrder ? newOrder : order;
};
export default reducer;
//*selectors

export const systemAnalysisSelector = (state: Store) => {
  return state.systemAnalysis;
};
export const systemAnalysisNickNamesSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['nickNames'];
  }
);
export const systemAnalysisIsNickNameLoadingsSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['isNickNamesLoading'];
  }
);
export const systemAnalysisIsNickNameLoadedsSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['isNickNamesLoaded'];
  }
);
export const isLoadingSystemAnalysisSelecor = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['isLoading'];
  }
);
export const systemAnalysisSortingOrderSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['sortingOrder'];
  }
);
export const systemAnalysisSortBySelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['sortBy'];
  }
);
export const systemAnalysisDataSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['data'];
  }
);
export const systemAnalysisDataBySelectedSystemGroup = createSelector(
  [systemGroupSelector, systemAnalysisDataSelector],
  (systemGroup, data) => {
    return data[systemGroup];
  }
);
export const selectedSystemNameSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['selectedSystemName'];
  }
);

export const printerSelector = createSelector(
  [systemAnalysisDataBySelectedSystemGroup, selectedSystemNameSelector],
  (systemAnalysis, name) => {
    return systemAnalysis.find((system: ISystemReport) => system.systemName === name);
  }
);
export const printerStartDateSelector = createSelector(
  [printerSelector],
  printer => new Date(printer?.modelStartDate)
);
export const pritnterEndDateSelector = createSelector(
  [printerSelector],
  printer => new Date(printer?.modelEndDate)
);
export const systemAnalysisNewNickNameSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['newNickName'];
  }
);
export const isChangeNameLoadingSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['isChangeNameLoading'];
  }
);
export const isChangeNameLoadedSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['isChangeNameLoaded'];
  }
);
export const changingNameInputValueSelector = createSelector(
  [systemAnalysisSelector],
  systemAnalysis => {
    return systemAnalysis['changingNameInputValue'];
  }
);
export const isDownloadReportValidationLoadingSelector = createSelector(
  [systemAnalysisSelector, systemGroupSelector],
  (systemAnalysis, selectedSystemGroup) => {
    if (systemAnalysis.downloadReportValidation[selectedSystemGroup]) {
      return systemAnalysis.downloadReportValidation[selectedSystemGroup]
        .isDownloadReportValidationLoading;
    }
  }
);
export const isSystemAnalysisReportValidToDownloadSelector = createSelector(
  [systemAnalysisSelector, systemGroupSelector],
  (systemAnalysis, selectedSystemGroup) => {
    if (systemAnalysis.downloadReportValidation[selectedSystemGroup]) {
      return systemAnalysis.downloadReportValidation[selectedSystemGroup].isValidToDownload;
    }
  }
);
export const downloadSystemAnalysisReportMessageSelector = createSelector(
  [systemAnalysisSelector, systemGroupSelector],
  (systemAnalysis, selectedSystemGroup) => {
    if (systemAnalysis.downloadReportValidation[selectedSystemGroup]) {
      return systemAnalysis.downloadReportValidation[selectedSystemGroup]
        .isDownloadReportLoadingError;
    }
  }
);
