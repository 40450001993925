import i18n from 'i18next';
// import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import resources from './languages';
import store from '../store/store';
import { Store } from '../store/storeTypes';
import { saveAppUserLocale } from '../actions/appUserActions';
import { authService } from './AuthProvider';
import config from 'config/config';

export const LOCALES = [
  { i18n: 'en', localName: 'English', flag: 'gb' },
  { i18n: 'de', localName: 'Deutsche', flag: 'de' },
];

i18n
  // .use(XHR) - to be used after locales API is ready
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    ns: ['common'],
    defaultNS: 'common',
    returnObjects: true,

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        }
        */
  });

i18n.on('languageChanged', lng => {
  authService.updateLocaleInLocalStorage(lng);
  const { idTokenPayload } = authService;
  const isSuperUser = idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}is_super_user`];
  if (!isSuperUser) {
    (store.dispatch as ThunkDispatch<Store, void, AnyAction>)(saveAppUserLocale(lng));
  }
});

export default i18n;
