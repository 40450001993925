import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SchedulerTableRow from './SchedulerTableRow';
import { Store } from '../../store/storeTypes';
import { schedulerByIdSelector } from '../../selectors/entitiesSelectors';
import { toggleSchedulerSending, deleteScheduler } from '../../actions/schedulerApiActions';
import {
  openModalSchedulerEdit,
  openModalSchedulerPreview,
  openModalSchedulerDuplicate,
} from '../../actions/schedulerUiActions';

interface OwnProps {
  schedulerId: string;
}

const makeMapStateToProps = () => {
  return (state: Store, ownProps: OwnProps) => ({
    scheduler: schedulerByIdSelector(state, ownProps),
    isSaving: state.api.isSavingScheduler,
    toggleSchedulerId: state.ui.toggleSchedulerId,
  });
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleSchedulerSending,
      deleteScheduler,
      openModalSchedulerEdit,
      openModalSchedulerPreview,
      openModalSchedulerDuplicate,
    },
    dispatch
  );

export type SchedulerTableRowProps = ReturnType<ReturnType<typeof makeMapStateToProps>> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(makeMapStateToProps, mapDispatchToProps)(SchedulerTableRow);
