import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Store, MODAL_STATUS } from 'store/storeTypes';

import { changeFormPlainValue, changeUserFormScope } from 'actions/userUiActions';
import { saveUser } from 'actions/userApiActions';
import {
  makeUserScopeStringSelector,
  allUsersButCurrentEmailsSelector,
} from 'selectors/uiSelectors';

import UserForm from './UserForm';

export interface OwnProps {
  closeThisModal: () => void;
}

const mapStateToProps = (state: Store, ownProps: OwnProps) => ({
  userForm: state.ui.userForm,
  isSaving: state.ui.userModal.status === MODAL_STATUS.SAVING,
  userScope: makeUserScopeStringSelector()(state, ownProps),
  allUsersButCurrentEmails: allUsersButCurrentEmailsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeFormPlainValue,
      changeUserFormScope,
      saveUser,
    },
    dispatch
  );

export type UserFormProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
