import styled from 'styled-components/macro';

const ModalHeader = styled.div`
  background: #2ea1f8;
  height: 55px;
  min-height: 55px;
  font-family: Rubik, sans-serif;
  font-size: 22px;
  color: #ffffff;
  padding: 14px 26px 15px;
  @media (max-width: 599px) {
    padding-right: 11px;
    padding-left: 11px;
  }
`;

export default ModalHeader;
