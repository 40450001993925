import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Fade, Modal, ClickAwayListener } from '@material-ui/core';
import styled from 'styled-components';
import PbiFilterTimeline from '../PbiFilter/PbiFilterTimeframeContainer';
import MobileDatePicker from './MobileDatePicker';
import { TimeFrame } from 'models/ReportData';
import { isHistorical } from 'helpers/date';

const MobileModalDatePicker = styled.div<{ screenWidth: number; popUpHeight: number }>`
  width: ${props => props.screenWidth}px;
  height: ${props => props.popUpHeight}px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const PbiFilterTimelinePlaceHolder = styled.div`
  width: 100%;
  min-height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DatePickerPlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const TodayButtonPlaceHolder = styled.div<{ popUpHeight: number; height: number }>`
  width: 100%;
  min-height: ${props => props.height}px;
  position: absolute;
  top: ${props => props.popUpHeight - props.height}px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(6px);
  border-top: 1px solid #c5d0de;
`;
const TodayButton = styled.button`
  all: unset;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2ea1f8;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  &:focus {
    background-color: #edf7ff;
  }
`;

type MobileFilterCalendarModalProps = {
  isOpen: boolean;
  pbiReportHeight: number;
  screenHeight: number;
  screenWidth: number;
  timeframe: TimeFrame;
  startDate: Date;
  endDate: Date;
  selectedDay: Date;
  filterState: any;
  filterData: any;
  setActiveDate: (date: Date) => void;
  closeCalendarModal: () => void;
  onClose: () => void;
  onDone: () => void;
};
const MobileFilterCalendarModal = (props: MobileFilterCalendarModalProps) => {
  const {
    isOpen,
    pbiReportHeight,
    screenHeight,
    screenWidth,
    timeframe,
    startDate,
    endDate,
    selectedDay,
    filterState,
    filterData,
    closeCalendarModal,
    setActiveDate,
    onClose,
    onDone,
  } = props;
  const popUpstyle = {
    position: 'absolute' as 'absolute',
    top: 0,
  };
  //*this is state for controlled component MobileFilterTimeFrame.tsx used as callback - need to be shared with date picker

  //*this value comes from css styles
  const verticalOffset = 77;
  const topBorder = screenHeight - pbiReportHeight + verticalOffset;
  popUpstyle.top = topBorder;
  const popUpHeight = screenHeight - topBorder;

  const handleLastUpdateClick = () => {
    setActiveDate(filterData.endDate || new Date());
    closeCalendarModal();
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      BackdropComponent={Backdrop}>
      <Fade in={isOpen}>
        <ClickAwayListener onClickAway={closeCalendarModal}>
          <Box id="transition-modal-title" style={popUpstyle}>
            <MobileModalDatePicker popUpHeight={popUpHeight} screenWidth={screenWidth}>
              <PbiFilterTimelinePlaceHolder>
                <PbiFilterTimeline isMobile={true} />
              </PbiFilterTimelinePlaceHolder>
              <DatePickerPlaceHolder>
                <MobileDatePicker
                  timeframe={timeframe}
                  startDate={startDate}
                  endDate={endDate}
                  selectedDay={selectedDay}
                  setActiveDate={setActiveDate}
                  closeCalendarModal={closeCalendarModal}
                />
              </DatePickerPlaceHolder>
              <TodayButtonPlaceHolder height={66} popUpHeight={popUpHeight}>
                <TodayButton
                  data-automationid={`today_button`}
                  disabled={
                    !isHistorical({
                      day: filterState.selectedDate,
                      lastUpdate: filterData.endDate,
                    })
                  }
                  onClick={handleLastUpdateClick}>
                  TODAY
                </TodayButton>
              </TodayButtonPlaceHolder>
            </MobileModalDatePicker>
          </Box>
        </ClickAwayListener>
      </Fade>
    </Modal>
  );
};
export default MobileFilterCalendarModal;
