import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { UserSystemGroupStrings, UserSystemGroup, Store } from '../../store/storeTypes';

const INIT_STATE: UserSystemGroupStrings = ''; // any value from enum can work, just for proper init

const { setSystemGroup } = actions.userUi;

export default createReducer<UserSystemGroupStrings, RootAction>(INIT_STATE).handleAction(
  setSystemGroup,
  (state, action) => action.payload
);
//*selectors
const uiSelector = (state: Store) => {
  return state.ui;
};
export const systemGroupSelector = createSelector([uiSelector], ui => {
  return ui['selectedSystemGroup'];
});

export const systemAnalysisViewSelector = createSelector([uiSelector], ui => {
  return ui['systemAnalysisView'];
});
