import React from 'react';
import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { StyledWrapperLoader } from 'components/SystemAnalysis/SystemsAnalysisStyles';
import config from 'config/config';
import { appearanceEffect, globalColors } from 'globalStyle';
import newSpinner from '../../assets/icons/newSpinner.gif';
export const AchievementsAndGoalsText = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  letter-spacing: 0.5625px;
  color: #354052;
  margin-bottom: 8px;

  @media screen and (min-width: 1920px) {
    font-size: 17px;
    line-height: 17px;
  }
`;
export const DateGoal = styled.div<{ done: boolean }>`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => (props.done ? globalColors.regular.background.blue : '#E6E9ED')};
`;
export const DateGoalWrapper = styled.div<{ width?: number }>`
  text-align: center;
  margin: 5px 3%;
  width: ${props => props.width || 8}%;
`;
export const HrHorizontal = styled.hr<{ width?: number }>`
  width: ${props => props.width || 100}%;
  height: 1px;
  border: none;
  margin: 4px 0px;
  background-color: #c5d0de;
`;
export const HrVertical = styled.hr<{ height?: number }>`
  height: ${props => props.height || 100}%;
  width: 1px;
  border: none;
  margin: 14px 8px;
  background-color: #7f8fa4;
`;
export const CardDivAcievmentCard = styled.div<{
  bigger?: boolean;
  height?: number;
  align?: boolean;
  isMobile?: boolean;
  selectedSystemGroup?: string;
}>`
  grid-area: 'systemPageCard';
  height: 370px;
  background: #ffffff;
  border: 1px solid #c5d0de;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.align === undefined ? 'center' : '')};
  margin-bottom: ${props => (props.isMobile === true ? '15px' : '')};
`;

export const CardDiv = styled.div<{
  bigger?: boolean;
  height?: number;
  align?: boolean;
  isMobile?: boolean;
  isHidden?: boolean;
}>`
  background: ${props => (props.isHidden ? 'transparent' : '#ffffff')};
  border: ${props => (props.isHidden ? 'none' : '1px solid #c5d0de')};
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 12px 20px 18px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  align-items: ${props => (props.align === undefined ? 'center' : '')};
  margin-bottom: ${props => (props.isMobile === true ? '10px' : '')};
`;
export const ValueDiv = styled.div<{ isMobile?: boolean }>`
  font-weight: 500;
  font-size: ${props => (props.isMobile === true ? '20px' : '30px')};
  line-height: ${props => (props.isMobile === true ? '24px' : '36px')};
  @media screen and (min-width: 1920px) {
    font-size: 33px;
    line-height: 33px;
  }
`;
export const UnitDiv = styled.div<{ isMobile?: boolean }>`
  font-size: ${props => (props.isMobile === true ? '16px' : '18px')};
  line-height: 21.33px;
  font-weight: 400;
  margin-left: 5px;
  color: #354052;
  @media screen and (min-width: 1920px) {
    font-size: 21px;
  }
`;
export const DescriptionDiv = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5625px;
  color: #7f8fa4;
  margin-top: 10px 0px 8px 0px;
  @media screen and (min-width: 1920px) {
    font-size: 17px;
    line-height: 17px;
  }
`;
export const ChartsHeader = styled.div`
  height: 20px;
  font-size: 18px;
  color: #354052;
  line-height: 21.33px;
  font-weight: 500;
`;
export const ChartHeader = styled.div`
  font-size: 14px;
  height: 15px;
  color: #354052;
  font-weight: 400;
  margin-bottom: 5px;
`;
export const ChartHeaderAvailability = styled.div`
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  margin-bottom: 20px;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
export const AchievementsImage = styled.div`
  background: url(${require('../../assets/icons/AchievementsImage.svg')});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;
export const VisibleOverflowResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-surface {
    overflow: visible;
  }
`;
// export const Spinner = (size = 50) => (
//   <StyledWrapperLoader>
//     <CircularProgress size={size} />
//   </StyledWrapperLoader>
// );
export const Spinner = (size = 50) => {
  return (
    <StyledWrapperLoader data-testid="spinner">
      <img width={size} height={size} src={newSpinner} alt="loader" />
    </StyledWrapperLoader>
  );
};
export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  caret-color: transparent;

  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #354052;
`;
export const InnerCardContent = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 4%;
  border-radius: inherit;

  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
  @media (min-width: 2250px) {
    padding: 1%;
  }
`;
