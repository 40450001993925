import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';

import { ValidationError } from '../../helpers/validationRules';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const TextFieldStyled: StyledComponentWithAnyType = styled(MuiTextField).attrs({
  InputLabelProps: {
    classes: { root: 'label', shrink: 'label-filled' },
  },
  InputProps: { classes: { root: 'input' } },
  fullWidth: true,
})`
  .label {
    font-size: 14px;
    font-family: Rubik, sans-serif;
  }
  .label-filled {
    color: #7f8fa4;
    font-size: 16px;
  }
  .input {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    color: #354052;
    &::before {
      border-color: #c5d0de;
    }
  }
`;

interface TextFieldProps {
  id?: string;
  label?: string;
  error?: ValidationError | false;
  onChange?: TextFieldProps & TextFieldProps;
  value?: number | string;
}

const TextField = React.memo((props: TextFieldProps & MuiTextFieldProps) => {
  const { t } = useTranslation();
  const { error, ...rest } = props;

  return (
    <TextFieldStyled
      error={!!error}
      helperText={(error && t(error.key, error.values)) || ' '}
      {...rest}
    />
  );
});
export default TextField;
