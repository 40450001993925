import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { makeApiUrl } from 'actions/_shared/utils';
import { authService } from 'services/AuthProvider';
import apiAction from 'actions/_shared/apiAction';
import { GenericPropsType } from 'store/storeTypes';

//models
export type GetCustomerDataBySerialRequest = { isLoading: boolean };
export type GetCustomerDataBySerialResponse = {
  id: string;
  name: string;
  importedCustomerId: string;
};

export const getCustomerDataBySerialIdAC = createAsyncAction(
  'GET_CUSTOMER_DATA_BY_SERIAL_STARTED',
  'GET_CUSTOMER_DATA_BY_SERIAL_SUCCESS',
  'GET_CUSTOMER_DATA_BY_SERIAL_FAILED'
)<GetCustomerDataBySerialRequest, GetCustomerDataBySerialResponse, Error>();

export const getCustomerDataBySerialIdRequest = (serialId: string) => {
  const url = makeApiUrl(`/api/data/customer-data?serialNumber=${serialId}`);
  const { isAuthenticated, idTokenPayload } = authService;

  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        // params: {

        // },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getCustomerDataBySerialIdAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getCustomerDataBySerialIdAC.request({ isLoading: true }));
        },
        onSuccess: (data, dispatch) => {
          const { name, id, importedCustomerId } = data;
          dispatch(getCustomerDataBySerialIdAC.success({ name, id, importedCustomerId }));
        },
      },
    });
};
