import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FunnelIcon } from '../../assets/icons/FunnelIcon.svg';
import { ReactComponent as FunnelDot } from '../../assets/icons/FilterDot.svg';
const FunnelContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const FunnelDotContainer = styled.div`
  position: absolute;
  top: 6px;
  left: 25px;
`;
type FunnelProps = {
  handleClick: (event: any) => void;
  automationId: string;
  funnelColor: string;
  filterNotEmpty: boolean;
};
const Funnel = (props: FunnelProps) => {
  const { handleClick, automationId, funnelColor, filterNotEmpty } = props;
  return (
    <FunnelContainer data-automationid={automationId} onClick={handleClick}>
      <FunnelIcon fill={funnelColor} />
      {filterNotEmpty && (
        <FunnelDotContainer>
          <FunnelDot />
        </FunnelDotContainer>
      )}
    </FunnelContainer>
  );
};
export default Funnel;
