import { GenericPropsType, Store } from '../store/storeTypes';

export const userIdFromPropsSelector = (state: Store, props: GenericPropsType) => props.userId;

export const schedulerIdFromPropsSelector = (state: Store, props: GenericPropsType) =>
  props.schedulerId;

export const idFromPropsSelector = (state: Store, props: GenericPropsType) => props.id;
export const embedTypeFromPropsSelector = (state: Store, props: GenericPropsType) =>
  props.embedType;
export const tokenTypeFromPropsSelector = (state: Store, props: GenericPropsType) =>
  props.tokenType;
export const filtersFromPropsSelector = (state: Store, props: GenericPropsType) => props.filters;
export const accessTokenFromPropsSelector = (state: Store, props: GenericPropsType) =>
  props.accessToken;
export const embedUrlFromPropsSelector = (state: Store, props: GenericPropsType) => props.embedUrl;
export const embedIdFromPropsSelector = (state: Store, props: GenericPropsType) => props.embedId;
export const permissionsFromPropsSelector = (state: Store, props: GenericPropsType) =>
  props.permissions;
export const extraSettingsFromPropsSelector = (state: Store, props: GenericPropsType) =>
  props.extraSettings;
