import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const Button = withStyles({
  root: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '12px',
    border: '1px solid #208cd7',
    borderRadius: '4px',
    width: '131px',
    padding: 0,
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      background: '#inherit',
    },
  },
  // select: {
  //   textTransform: 'capitalize',
  // },
})(MuiButton);

export default Button;
