import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as NewLogo } from '../../assets/icons/NewLogo.svg';
import { ReactComponent as LetterUnsubscribe } from '../../assets/icons/Letter-unsubscribe.svg';
import { ReactComponent as MailCover } from '../../assets/icons/mail-cover.svg';
import { Spinner } from 'components/Overview/Styles';
import env from '../../config/config';
const UnsubscribeBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #354052;
  margin: 0;
  padding: 0;
`;
const UnsubscribeGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 70px 1fr 100px 70px;
  grid-template-areas:
    'logo '
    'mainContent'
    'notificationZone'
    'footer';
`;
const Logo = styled.div`
  grid-area: logo;
  padding: 2%;
`;
const Footer = styled.div`
  grid-area: footer;
`;

const UnsubscribeGridPlaceHolder = styled.div`
  grid-area: mainContent;
  width: 60%;
  margin: 0 auto;
  max-width: 780px;
  min-width: 320px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const UnsubscriberRow = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #354052;
`;
const WeAreSorryWrapper = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7f8fa4;
`;

const EmailInputField = styled.div`
  background-color: #eff3f6;
  width: 80%;
  max-width: 480px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 5%;
  padding: 0 10px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #354052;
`;
const EmailStringWrapper = styled.div`
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const AreYouSureWrapper = styled.div`
  width: 85%;
  max-width: 500px;
  padding: 10px 5px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #354052;
`;
const Button = styled.button<{ isLoading: boolean }>`
  width: 30%;
  min-width: 140px;
  height: 48px;
  border-radius: 4px;
  padding: 12px 29px;
  cursor: pointer;
  border-width: 0px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.isLoading ? '#4b5257' : '#2ea1f8')};

  background-color: #2ea1f8;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  :hover {
    background-color: #1486dd;
  }
`;
const NotificationPlaceHolder = styled.div`
  grid-area: notificationZone;
  width: 100%;
  height: 100%;
  background-color: white;
  width: 60%;
  margin: 0 auto;
  max-width: 780px;
  min-width: 320px;
  background-color: white;
  text-align: center;
  padding-bottom: 60px;
`;
const NotificationSuccess = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 24px;
  text-align: center;
  color: green;
`;

const NotificationFailed = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 24px;
  text-align: center;
  color: #ff0000;
`;
const StyledInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
`;
const Unsubscribe = (props: any) => {
  const { authService, location } = props;
  const { _authOptions } = authService;
  const { search } = location;
  const START_TOKEN_INDEX = search.split('').indexOf('=') + 1;
  const token = search
    .split('')
    .splice(START_TOKEN_INDEX, search.length)
    .join('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const urlPerEnv = env.UNSUBSCRIBE_END_POINT;

  const clickHandler = async (urlRequest: string, inputValue: string) => {
    setLoading(true);
    setError('');
    setLoaded(false);
    const response = await fetch(urlRequest, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ email: inputValue, token: token }),
    });

    if (response.ok) {
      setLoading(false);
      setLoaded(true);
      setError('');
      const json = await response.json();
    } else {
      setLoading(false);
      setLoaded(false);
      if (response.status === 401 || response.status === 403) {
        setError('Wrong email, try again');
      }
      setError('something went wrong, try again');
    }
  };
  return (
    <UnsubscribeBackground>
      <UnsubscribeGridWrapper>
        <Logo>
          <NewLogo />
        </Logo>

        <UnsubscribeGridPlaceHolder>
          <LetterUnsubscribe />
          <UnsubscriberRow>Unsubscribe</UnsubscriberRow>
          <WeAreSorryWrapper>We are sorry to see you go</WeAreSorryWrapper>
          <EmailInputField>
            <MailCover />
            <EmailStringWrapper>
              <StyledInput
                value={email}
                onChange={e => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </EmailStringWrapper>
          </EmailInputField>
          <AreYouSureWrapper>
            Are you sure you wish to unsubscribe from Konnect's production performance weekly
            summary?
          </AreYouSureWrapper>
          <Button
            isLoading={loading}
            disabled={loading}
            onClick={() => {
              clickHandler(urlPerEnv, email);
            }}>
            Unsubscribe
          </Button>
        </UnsubscribeGridPlaceHolder>
        <NotificationPlaceHolder>
          {loaded ? <NotificationSuccess>Success</NotificationSuccess> : ''}
          {error !== '' ? <NotificationFailed>{error}</NotificationFailed> : ''}
          {loading ? Spinner() : ''}
        </NotificationPlaceHolder>
        <Footer />
      </UnsubscribeGridWrapper>
    </UnsubscribeBackground>
  );
};
export default Unsubscribe;
