import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Store } from 'store/storeTypes';
import Settings from './Settings';
import { setAppUserSettings } from 'actions/userSettingsActions';
import {
  screenHeightSelector,
  screenWidthSelector,
  pbiReportWidthSelector,
  pbiReportHeightSelector,
} from 'reducers/appUserReducer';
import { userSettingsSelector } from 'reducers/userSettingsReducer';

const mapStateToProps = (state: Store) => ({
  userSettings: userSettingsSelector(state),
  screenHeight: screenHeightSelector(state),
  screenWidth: screenWidthSelector(state),
  pbiReportWidth: pbiReportWidthSelector(state),
  pbiReportHeight: pbiReportHeightSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAppUserSettings,
    },
    dispatch
  );

export type SettingsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
