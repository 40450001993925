import { UserSystemGroupStrings } from 'store/storeTypes';
import * as React from 'react';
import { ReactComponent as Tshirt } from '../../assets/icons/T-shirt.svg';
import { ReactComponent as Fabric } from '../../assets/icons/fabricIconNoColor.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as Chart } from '../../assets/icons/Chart.svg';
import { ReactComponent as Dislike } from '../../assets/icons/dislikeWithCircle.svg';
import { ReactComponent as Like } from '../../assets/icons/likeWithCircle.svg';
const TSHIRT_OLD_STANDART_SIZE = 37;
const FABRIC_OLD_STANDART_SIZE = 20;
export const productionIcon = (
  selectedSystemGroup: UserSystemGroupStrings,
  width?: number,
  height?: number
) => {
  return {
    positive:
      selectedSystemGroup === 'DTG' || selectedSystemGroup === 'Apollo' ? (
        <Tshirt
          fill={'#1BB934'}
          width={width ? width : TSHIRT_OLD_STANDART_SIZE}
          height={height ? height : TSHIRT_OLD_STANDART_SIZE}
        />
      ) : (
        <Fabric
          style={{ marginTop: '4px' }}
          fill={'#1BB934'}
          width={width ? width : FABRIC_OLD_STANDART_SIZE}
          height={height ? height : FABRIC_OLD_STANDART_SIZE}
        />
      ),
    negative:
      selectedSystemGroup === 'DTG' || selectedSystemGroup === 'Apollo' ? (
        <Tshirt
          fill={'#FF4081'}
          width={width ? width : TSHIRT_OLD_STANDART_SIZE}
          height={height ? height : TSHIRT_OLD_STANDART_SIZE}
        />
      ) : (
        <Fabric
          style={{ marginTop: '4px' }}
          fill={'#FF4081'}
          width={width ? width : FABRIC_OLD_STANDART_SIZE}
          height={height ? height : FABRIC_OLD_STANDART_SIZE}
        />
      ),
  };
};
export const dashboardIcon = {
  positive: <Dashboard fill={'#1BB934'} width={'30px'} height={'30px'} />,
  negative: <Dashboard fill={'#FF4081'} width={'30px'} height={'30px'} />,
};
export const chartIcon = {
  positive: <Chart fill={'#1BB934'} width={'30px'} height={'30px'} />,
  negative: <Chart fill={'#FF4081'} width={'30px'} height={'30px'} />,
};
export const likeIcon = {
  positive: <Like fill={'#1BB934'} width={'30px'} height={'30px'} />,
  negative: <Dislike fill={'#FF4081'} width={'30px'} height={'30px'} />,
};
