import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';
import { IUserSettingsState, Store } from 'store/storeTypes';
import { actions, RootAction } from 'actions';

const INIT_STATE: IUserSettingsState = {
  data: {
    userSystemGroup: [],
    defaultSystemGroup: '',
    lengthUnits: '',
    volumeUnits: '',
    rtrMetricUnit: '',
    portalLanguage: '',
    tenantName: '',
    importedTenantId: '',
    realTimeRefreshRateSeconds: undefined,
  },
  isError: false,
  isLoading: false,
};

const {
  setAppUserSettingsAC,
  loadAppUserSettingsFailed,
  loadAppUserSettingsStarted,
  loadAppUserSettingsSuccess,
} = actions.userSettings;

export const userSettingsReducer = createReducer<IUserSettingsState, any>(INIT_STATE)
  .handleAction(loadAppUserSettingsStarted, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .handleAction(loadAppUserSettingsSuccess, (state, action) => {
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
  })
  .handleAction(loadAppUserSettingsFailed, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  })
  .handleAction(setAppUserSettingsAC.request, (state, action) => {
    return {
      ...state,
    };
  })
  .handleAction(setAppUserSettingsAC.success, (state, action) => {
    return {
      ...state,
      data: { ...state.data, ...action.payload },
      isLoading: false,
    };
  })
  .handleAction(setAppUserSettingsAC.failure, (state, action) => {
    return {
      ...state,
      isError: true,
    };
  });

//*selectors

export const userSettingsSelector = (state: Store) => {
  return state.userSettings;
};
export const dataUserSettingsSelector = createSelector([userSettingsSelector], userSettings => {
  return userSettings.data;
});
export const defaultSystemGroupSelector = createSelector([dataUserSettingsSelector], data => {
  return data['defaultSystemGroup'];
});
export const userSystemGroupSelector = createSelector([dataUserSettingsSelector], data => {
  return data.userSystemGroup;
});
export const tenantNameSelector = createSelector([dataUserSettingsSelector], data => {
  return data.tenantName;
});
export const importedTenantIdSelector = createSelector([dataUserSettingsSelector], data => {
  return data.importedTenantId;
});
export const officeSelector = createSelector([dataUserSettingsSelector], data => {
  return data.office;
});
export const accountTypeSelector = createSelector([dataUserSettingsSelector], data => {
  return data.accountType;
});
export const refreshRealTimePeriodSelector = createSelector([dataUserSettingsSelector], data => {
  return data.realTimeRefreshRateSeconds;
});
