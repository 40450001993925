import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import UserModalLayout from './UserModalLayout';
import { Store, MODAL_STATUS } from 'store/storeTypes';
import { closeModalUser } from 'actions/userUiActions';

const mapStateToProps = (state: Store) => ({
  modalUserIsNew: !state.ui.userForm.id,
  done: state.ui.userModal.status === MODAL_STATUS.DONE,
  avatarBase64: state.ui.userForm.avatarBase64,
  avatarUrl: state.ui.userForm.avatarUrl,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModalUser,
    },
    dispatch
  );

export type UserModalLayoutProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserModalLayout);
