import { setFilterValue } from 'actions/reportFilterActions';
import { connect } from 'react-redux';
import { filterValueBySystemGroupSelector } from 'reducers/reports/filterStateReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { Store } from 'store/storeTypes';
import FilterSearcher from './FilterSearcher';

export interface OwnProps {}

const mapStateToProps = (state: Store) => {
  return {
    selectedSystemGroup: systemGroupSelector(state),
    filterValue: filterValueBySystemGroupSelector(state),
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ setFilterValue }, dispatch);

export type IOverviewProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FilterSearcher);
