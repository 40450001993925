import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from 'actions';
import { AppUser, Store } from 'store/storeTypes';
import { SaveUserSuccessPayload } from 'actions/userApiActions';
import { ReportType } from 'models/Scheduler';
import { setUserEmail, setUserFirstName, setUserLastName } from 'actions/appUserActions';
import { setPbiReportSize, setScreenSize } from '../actions/userUiActions';

const INIT_STATE: AppUser = {
  id: '',
  profilePictureUrl: '',
  customerIds: [],
  siteIds: [],
  systemIds: [],
  reportTypes: [],
  userEmail: '',
  userFirstName: '',
  userLastName: '',
};

const { setAppUserParams, loadAppUserFullSuccess, loadPortalApiUrlSuccess } = actions.appUser;
const { saveUserSuccess } = actions.userApi;
const { loadSchedulerTypesSuccess } = actions.schedulerApi;

export const appUserReducer = createReducer<AppUser, any>(INIT_STATE)
  .handleAction(loadSchedulerTypesSuccess, (state, action) => setReportTypes(state, action.payload))
  .handleAction(setAppUserParams, (state, action) => {
    return { ...state, ...action.payload };
  })
  .handleAction(saveUserSuccess, (state, action) => applySavedUserProperties(state, action.payload))
  .handleAction(loadAppUserFullSuccess, (state, action) => setAppUserFull(state, action.payload))
  .handleAction(loadPortalApiUrlSuccess, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction(setUserFirstName, (state, action) => {
    return { ...state, userFirstName: action.payload.firstName };
  })

  .handleAction(setUserLastName, (state, action) => {
    return { ...state, userLastName: action.payload.lastName };
  })
  .handleAction(setUserEmail, (state, action) => {
    return { ...state, userEmail: action.payload.email };
  })
  .handleAction(setScreenSize, (state, action) => {
    const { width, height } = action.payload;
    return { ...state, screenWidth: width, screenHeight: height };
  })
  .handleAction(setPbiReportSize, (state, action) => {
    const { width, height } = action.payload;
    return {
      ...state,
      pbiReportWidth: width,
      pbiReportHeight: height,
    };
  });
//*selectors
const appUserSelector = (state: Store) => {
  return state.appUser;
};
export const userEmailSelector = createSelector([appUserSelector], appUser => {
  return appUser.userEmail;
});
export const screenWidthSelector = createSelector([appUserSelector], appUser => {
  return appUser.screenWidth;
});
export const screenHeightSelector = createSelector([appUserSelector], appUser => {
  return appUser.screenHeight;
});
export const pbiReportHeightSelector = createSelector([appUserSelector], appUser => {
  return appUser.pbiReportHeight;
});
export const pbiReportWidthSelector = createSelector([appUserSelector], appUser => {
  return appUser.pbiReportWidth;
});
export const profilePictureUrlSelector = createSelector([appUserSelector], appUsesr => {
  return appUsesr.profilePictureUrl;
});
export const apiUrlSelector = createSelector([appUserSelector], appUsesr => {
  return appUsesr.apiUrl;
});
export const userAgreementSelector = createSelector([appUserSelector], appUsesr => {
  return appUsesr.userAgreement;
});
export const userAgreementVersionSelector = createSelector([appUserSelector], appUsesr => {
  return appUsesr.userAgreementVersion;
});
export const isReadOnlyRoleSelector = createSelector([appUserSelector], appUser => {
  return appUser.permissions?.includes(7);
});

function applySavedUserProperties(state: AppUser, payload: SaveUserSuccessPayload): AppUser {
  const savedUser = Array.isArray(payload.newUsers) && payload.newUsers[0];

  if (savedUser && savedUser.id === state.id) {
    return {
      ...state,
      ...savedUser,
      profilePictureUrl: savedUser.profilePictureUrl || state.profilePictureUrl || undefined,
    };
  }

  return state;
}

function setAppUserFull(state: AppUser, appUser: AppUser): AppUser {
  if (appUser) {
    return {
      ...state,
      ...appUser,
      profilePictureUrl: appUser.profilePictureUrl || state.profilePictureUrl || undefined,
    };
  }

  return state;
}

function setReportTypes(state: AppUser, value: ReportType[]): AppUser {
  if (value && value.length > 0) {
    const reportTypes: string[] = [];

    value.forEach((type: ReportType) => {
      reportTypes.push(type.name);
    });

    return { ...state, reportTypes };
  }

  return state;
}
