import { Reducer, combineReducers } from 'redux';

import filterSitesReducer from './filterDataReducer';
import filterStateReducer from './filterStateReducer';
import activeReportReducer from './activeReportReducer';

export const reportsReducer: Reducer = combineReducers({
  filterData: filterSitesReducer,
  filterState: filterStateReducer,
  activeReport: activeReportReducer,
});

export default reportsReducer;
