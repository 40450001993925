import * as React from 'react';
import { ActiveOverviewChart } from 'store/storeTypes';
import styled from 'styled-components/macro';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleChartProps } from './ToggleChartContainer';

import DesktopToggleChart from './DesktopToggleChart';
import MobileToggleChart from './MobileToggleChart';

export default function ToggleChart(props: ToggleChartProps) {
  const { setActiveOverviewChart, activeOverviewChart, selectedSystemGroup, isMobile } = props;
  const handleChartChange = (
    event: React.MouseEvent<HTMLElement>,
    newChart: ActiveOverviewChart
  ) => {
    if (newChart !== activeOverviewChart[selectedSystemGroup] && newChart !== null) {
      const payload = {};
      payload[selectedSystemGroup] = newChart;
      setActiveOverviewChart(payload);
    }
  };

  if (!isMobile) {
    return (
      <DesktopToggleChart
        handleChartChange={handleChartChange}
        activeOverviewChart={activeOverviewChart}
        selectedSystemGroup={selectedSystemGroup}
      />
    );
  } else {
    return (
      <MobileToggleChart
        setActiveOverviewChart={setActiveOverviewChart}
        activeOverviewChart={activeOverviewChart}
        selectedSystemGroup={selectedSystemGroup}
      />
    );
  }
}
