import { createStandardAction } from 'typesafe-actions';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { ReportTypesNew } from 'models/ReportData';
import { Store, ReportFilterData, UserSystemGroupStrings } from 'store/storeTypes';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
interface SetActiveReportPayload {
  activeReport: ReportTypesNew;
  filterData: ReportFilterData;
  selectedSystemGroup: UserSystemGroupStrings;
}
export const setActiveReportAC = createStandardAction('SET_ACTIVE_REPORT')<
  SetActiveReportPayload
>();
export function setActiveReport(activeReport: ReportTypesNew) {
  return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const { filterData, activeReport: currentReport } = getState().reports;
    const { selectedSystemGroup } = getState().ui;

    if (currentReport) {
      //* if there is no current report it means user just load the first overview page
      KonnectAnalytics.changeReportPage('changeReport', {
        prevReport: currentReport,
        nextReport: activeReport,
      });
    }
    dispatch(
      setActiveReportAC({
        activeReport,
        selectedSystemGroup,
        filterData: filterData[selectedSystemGroup]!,
      })
    );
  };
}
