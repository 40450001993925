import { UserSystemGroupStrings } from './../store/storeTypes';
import { ReportFilterData } from 'store/storeTypes';

export const isNeverReported = (
  filterData: ReportFilterData,
  isSelectedSystemsEverReported: boolean,
  selectedSystemGroup: UserSystemGroupStrings,
  isEverReportedLoaded: boolean
) => {
  //*till this endpoing : "reported-printers-status" gives back resposnse we can not say that we have empty state
  if (!isEverReportedLoaded) {
    return false;
  }
  //*if start and end dates are null it means empty state
  const { startDate, endDate } = filterData;
  if (!startDate || !endDate) {
    return true;
  }
  //   //*if startDate and endDate === new Date(0) and === each other(only in this case) it means that whole selectedSystem group with all systems not connected - we have empty state
  const isWholeSelectedSystemGroupHasEmptyState =
    startDate.getTime() === endDate.getTime() && endDate.getTime() === new Date(0).getTime();
  // start date === end date
  if (isWholeSelectedSystemGroupHasEmptyState) {
    return true;
  }
  //each system in filterData has isEverReported - false
  if (!filterData.systems.some(system => system.isEverReported)) {
    return true;
  }
  //   //*if user did some system filter selection  and if there is no selected serialids in filterState - that means that we have empty state.
  else if (isSelectedSystemsEverReported === false) {
    return true;
  }
  //*in other cases return false - it means there is no empty state
  else {
    return false;
  }
};
