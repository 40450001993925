import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReactNode } from 'react';

import UserAvatarUpload from './UserAvatarUpload';
import { Store } from '../../store/storeTypes';
import { changeFormAvatar } from '../../actions/userUiActions';
import { enqueueNotification } from '../../actions/uiActions';

interface OwnProps {
  children: ReactNode;
}

const mapStateToProps = (state: Store) => ({
  userId: state.ui.userForm.id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeFormAvatar,
      enqueueNotification,
    },
    dispatch
  );

export type UserAvatarUploadProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatarUpload);
