import { TextByType } from 'components/JobAnalysis/JobAnalysis';
import { appearanceEffect } from 'globalStyle';
import { formatSecToHourString, formatSecToMinString } from 'helpers/conversions';
import { TimeFrame } from 'models/ReportData';
import React from 'react';
import { UserSystemGroupStrings } from 'store/storeTypes';
import styled from 'styled-components';
import DesktopAvarageAvailabilityCard from './DesktopAvarageAvailabilityCard';
import DesktopAvarageProductionRateCard from './DesktopAvarageProductionRateCard';
import DesktopProductivityCard from './DesktopProductivityCard';
import DesktopTotalImpressionCard from './DesktopTotalImpressionCard';
import { ITopCardsProps } from './TopCardsContainer';
import { CustomTimeframeProperties } from 'components/PbiFilter/PbiFilterCustom';

export const TopCardsDesktopWrapper = styled.div`
  display: flex;
  gap: 16px;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;

export const TotalImpressionCardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 144px;
  width: clamp(202px, 25%, 25%);
  min-width: 202px;
  background: #ffffff;
  border: 1px solid #e6e9ed;
  border-radius: 8px;

  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
export const AvarageProductionRateCardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 144px;
  width: 25%;
  min-width: 344px;
  background: #ffffff;
  border: 1px solid #e6e9ed;
  border-radius: 8px;

  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
export const ProductivityCardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 144px;
  width: 25%;
  min-width: 304px;
  background: #ffffff;
  border: 1px solid #e6e9ed;
  border-radius: 8px;

  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
export const AverageAvailabilityCardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 144px;
  width: 25%;
  min-width: 296px;
  background: #ffffff;
  border: 1px solid #e6e9ed;
  border-radius: 8px;

  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
type AddedCardDataUnitType = {
  description: string;
  trend: number;
  unit: string;
  value: number;
  formatter?: Function;
};
type AddedCardDataType = {
  upper: AddedCardDataUnitType;
  lower?: AddedCardDataUnitType;
};

export type CardProps = {
  stats: number;
  description: string;
  value: number;
  units: string;
  statsDescription: string;
  addedData: AddedCardDataType;
  timeframe?: TimeFrame;
  customTimeframeProperties: CustomTimeframeProperties;
  selectedSystemGroup?: UserSystemGroupStrings;
  cardName?: string;
};
const DesktopDtgApolloTopCards = (props: ITopCardsProps) => {
  const {
    totalProduction,
    units,
    selectedSystemGroup,
    productionTrend,
    selectedSystemGroupText,
    timeframe,
    productionRate,
    productionRateTrend,
    loadingTime,
    utilization,
    loadingTimeTrend,
    trendUtilization,
    darkImpressionsPercentage,
    preparation,
    trendPreparation,
    darkImpressionsTrend,
    productivityChange,
    productivity,
    printingTimeTrend,
    printingTime,

    availabilityTrend,
    availability,

    customTimeframeProperties,
    availabilityTotalDurationReported,
    isNotReprotingPeriod,
    maintenanceDurationTrend,
    maintenanceDuration,
    errorDurationTrend,
    errorDuration,
  } = props;
  const getTimeframeTextKey = () => {
    return timeframe && timeframe.includes('lastUpdate') ? 'last' : 'weekDay';
  };
  const changeTextPerPage = (timeframe: TimeFrame | '', text: string) => {
    if (timeframe && timeframe.includes('lastUpdate')) return text.replace('Average', 'Current');
    return text;
  };
  return (
    <TopCardsDesktopWrapper data-automationid={`TopCardsDesktopWrapper`}>
      <TotalImpressionCardWrapper>
        <DesktopTotalImpressionCard
          customTimeframeProperties={customTimeframeProperties}
          value={totalProduction}
          units={units}
          stats={productionTrend}
          selectedSystemGroup={selectedSystemGroup}
          selectedSystemGroupText={selectedSystemGroupText}
          description={selectedSystemGroupText.impression.description[getTimeframeTextKey()]}
          timeframe={timeframe}
        />
      </TotalImpressionCardWrapper>
      <AvarageProductionRateCardWrapper>
        <DesktopAvarageProductionRateCard
          selectedSystemGroup={selectedSystemGroup}
          timeframe={timeframe}
          customTimeframeProperties={customTimeframeProperties}
          value={productionRate}
          units={units + '/h'}
          description={selectedSystemGroupText.productionRate.description[getTimeframeTextKey()]}
          stats={productionRateTrend}
          statsDescription={
            selectedSystemGroupText.productionRate.statsDescription[getTimeframeTextKey()]
          }
          addedData={{
            upper: {
              unit: selectedSystemGroup === 'DTF' ? '%' : 'sec/' + units,
              value: selectedSystemGroup === 'DTF' ? utilization : loadingTime,
              description: changeTextPerPage(
                timeframe,
                selectedSystemGroupText.productionRate.upper
              ),
              trend: selectedSystemGroup === 'DTF' ? trendUtilization : loadingTimeTrend,
            },
            lower: {
              value: selectedSystemGroup === 'DTF' ? preparation : darkImpressionsPercentage,
              description: selectedSystemGroupText.productionRate.lower,
              unit: '%',
              trend: selectedSystemGroup === 'DTF' ? trendPreparation : darkImpressionsTrend,
            },
          }}
        />
      </AvarageProductionRateCardWrapper>
      <ProductivityCardWrapper>
        <DesktopProductivityCard
          selectedSystemGroup={selectedSystemGroup}
          timeframe={timeframe}
          customTimeframeProperties={customTimeframeProperties}
          description={'Productivity'}
          units={units + '/h'}
          value={productivity}
          stats={productivityChange}
          statsDescription={
            selectedSystemGroupText.productionRate.statsDescription[getTimeframeTextKey()]
          }
          addedData={{
            upper: {
              unit: 'min',
              value: printingTime,
              description: 'Avg. Print Time',
              trend: printingTimeTrend,
              formatter: formatSecToMinString,
            },
          }}
          cardName="productivity"
        />
      </ProductivityCardWrapper>
      <AverageAvailabilityCardWrapper>
        <DesktopAvarageAvailabilityCard
          selectedSystemGroup={selectedSystemGroup}
          timeframe={timeframe}
          customTimeframeProperties={customTimeframeProperties}
          cardName="availability"
          value={availability}
          units={'%'}
          isPercentage={true}
          description={selectedSystemGroupText.availability.description[getTimeframeTextKey()]}
          stats={availabilityTrend}
          statsDescription={
            selectedSystemGroupText.availability.statsDescription[getTimeframeTextKey()]
          }
          addedData={{
            upper: {
              unit: '',
              value: errorDuration,
              description: selectedSystemGroupText.availability.upper,
              trend: errorDurationTrend,
              formatter: formatSecToHourString,
            },
            lower: {
              value: maintenanceDuration,
              unit: '',
              description: selectedSystemGroupText.availability.lower,
              trend: maintenanceDurationTrend,
              formatter: formatSecToHourString,
            },
          }}
          availabilityTotalDurationReported={availabilityTotalDurationReported}
          isNotReprotingPeriod={isNotReprotingPeriod}
        />
      </AverageAvailabilityCardWrapper>
    </TopCardsDesktopWrapper>
  );
};
export default DesktopDtgApolloTopCards;
