import React from 'react';
import styled from 'styled-components/macro';
import { PbiFilterSystemsProps } from './PbiFilterSystemsContainer';
import PbiFilterSystemsMobile from './PbiFilterSystemsMobile';
import PbiFilterSystemsDesktop from './PbiFilterSystemsDesktop';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';

export const SystemsFilterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #c5d0de;
  width: 69px;
  height: 49px;
  margin-bottom: 4px;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 750px) {
    margin-left: 0;
  }
`;

const PbiFilterSystems = React.memo((props: PbiFilterSystemsProps) => {
  const {
    filterState,
    filterData,
    isMachineFilterOpen,
    screenWidth,
    pbiReportHeight,
    toggleMachineFilterMenu,
  } = props;
  const { selectedSiteIds, selectedModelIds, selectedSystemSerials } = filterState;
  const MAX_SYSTEMS_COUNT = filterData.systems.length;
  const filterNotEmpty = !!(
    (selectedSiteIds.length || selectedModelIds.length || selectedSystemSerials.length) &&
    selectedSystemSerials.length !== MAX_SYSTEMS_COUNT
  );

  const funnelColor = isMachineFilterOpen ? '#1878BA' : '#7F8FA4';

  const onFunnelClickHandler = () => {
    toggleMachineFilterMenu();
    KonnectAnalytics.toggleMachineFilterMenu();
  };
  return (
    <>
      {screenWidth <= 1400 ? (
        <PbiFilterSystemsMobile
          pbiReportHeight={pbiReportHeight}
          filterNotEmpty={filterNotEmpty}
          funnelColor={funnelColor}
          isMachineFilterOpen={isMachineFilterOpen}
          {...props}
        />
      ) : (
        <PbiFilterSystemsDesktop
          pbiReportHeight={pbiReportHeight}
          filterNotEmpty={filterNotEmpty}
          funnelColor={funnelColor}
          onFunnelClickHandler={onFunnelClickHandler}
          {...props}
        />
      )}
    </>
  );
});

export default PbiFilterSystems;
