import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { SchedulerModal, MODAL_STATUS } from '../../store/storeTypes';

const INIT_STATE: SchedulerModal = {
  status: MODAL_STATUS.CLOSED,
  previewStatus: MODAL_STATUS.CLOSED,
};

const {
  openModalSchedulerCreateAC,
  openModalSchedulerEditAC,
  openModalSchedulerDuplicateAC,
  closeModalScheduler,
  openModalSchedulerPreviewAC,
  closeModalSchedulerPreview,
  setStatusModalScheduler,
} = actions.schedulerUi;

const { deleteSchedulerSuccess } = actions.schedulerApi;

export default createReducer<SchedulerModal, RootAction>(INIT_STATE)
  .handleAction(
    [openModalSchedulerCreateAC, openModalSchedulerEditAC, openModalSchedulerDuplicateAC],
    state => ({
      ...state,
      status: MODAL_STATUS.EDITING,
      previewStatus: MODAL_STATUS.CLOSED,
    })
  )
  .handleAction(closeModalScheduler, state => ({
    ...state,
    status: MODAL_STATUS.CLOSED,
  }))
  .handleAction(openModalSchedulerPreviewAC, state => ({
    ...state,
    previewStatus: MODAL_STATUS.EDITING,
  }))
  .handleAction([closeModalSchedulerPreview, deleteSchedulerSuccess], state => ({
    ...state,
    previewStatus: MODAL_STATUS.CLOSED,
  }))
  .handleAction(setStatusModalScheduler, (state, action) => ({
    ...state,
    status: action.payload,
  }));
