import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import UserModalSuccess from './UserModalSuccess';
import { Store } from '../../store/storeTypes';
import { closeModalUser } from '../../actions/userUiActions';
import { inviteUser } from '../../actions/userApiActions';
import { isInvitationPending } from '../../selectors/apiSelectors';

const mapStateToProps = (state: Store) => ({
  lastSavedUserId: state.ui.userModal.lastSavedUserId,
  isPendingInvitation: isInvitationPending(state, state.ui.userModal.lastSavedUserId),
  isInvitationSent: state.ui.userModal.isInvitationSent,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModalUser,
      inviteUser,
    },
    dispatch
  );

export type UserModalSuccessProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserModalSuccess);
