import { TreeNodeProps } from 'react-dropdown-tree-select';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from 'actions';
import { Entity, UserForm } from 'store/storeTypes';
import { userApiToUi } from '../_sharedLogic/userConversions';
import {
  userDataToTree,
  treeCheckedNodesToUserData,
} from '../_sharedLogic/userScopeTreeConversions';
import { CustomerData } from 'models/CustomerData';
import { SiteData } from 'models/SiteData';
import { SystemData } from 'models/SystemData';
import { ExtendedUserData } from 'models/ExtendedUserData';

const INIT_STATE: UserForm = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  customerIds: [],
  siteIds: [],
  systemIds: [],
  systemsTree: [],
  setGoals: false,
  createReports: false,
  adminPermissions: false,
  isFormTouched: false,
  isRootUser: false,
  manageApi: false,
};

const {
  openModalUserCreateAC,
  openModalUserEditAC,
  openModalUserDuplicateAC,
  changeFormPlainValue,
  changeUserFormScopeAC,
} = actions.userUi;

export default createReducer<UserForm, RootAction>(INIT_STATE)
  .handleAction(openModalUserCreateAC, (state, action) =>
    openModalUserCreate(state, action.payload)
  )
  .handleAction(openModalUserEditAC, (state, action) => openModalUserEdit(state, action.payload))
  .handleAction(openModalUserDuplicateAC, (state, action) =>
    openModalUserDuplicate(state, action.payload)
  )
  .handleAction(changeFormPlainValue, (state, action) =>
    changeFormPlainValueRF(state, action.payload)
  )
  .handleAction(changeUserFormScopeAC, (state, action) =>
    changeUserFormScope(state, action.payload)
  );
export type OpenModalUserCreateType = {
  customers: Entity<CustomerData>;
  sites: Entity<SiteData>;
  systems: Entity<SystemData>;
};
function openModalUserCreate(state: UserForm, payload: OpenModalUserCreateType): UserForm {
  const { customers, sites, systems } = payload;

  return {
    ...INIT_STATE,
    systemsTree: userDataToTree(customers, sites, systems),
  };
}
export type OpenModalUserEditPropsType = {
  customers: Entity<CustomerData>;
  sites: Entity<SiteData>;
  systems: Entity<SystemData>;
  user: ExtendedUserData;
};
function openModalUserEdit(state: UserForm, payload: OpenModalUserEditPropsType): UserForm {
  const { customers, sites, systems, user } = payload;

  return userApiToUi(customers, sites, systems, user);
}
export type OpenModalUserDuplicateTypeProps = {
  customers: Entity<CustomerData>;
  sites: Entity<SiteData>;
  systems: Entity<SystemData>;
  user: ExtendedUserData;
};
function openModalUserDuplicate(
  state: UserForm,
  payload: OpenModalUserDuplicateTypeProps
): UserForm {
  const { user, customers, sites, systems } = payload;

  const form = userApiToUi(customers, sites, systems, user);
  form.id = '';
  form.avatarUrl = '';
  form.isFormTouched = true;

  return form;
}
export type ChangeFormPlainValueRFPropsType = {
  field: string | number | symbol;
  value: unknown | Record<string, unknown>;
};
function changeFormPlainValueRF(
  state: UserForm,
  payload: ChangeFormPlainValueRFPropsType
): UserForm {
  const { field, value } = payload;

  return { ...state, [field]: value, isFormTouched: true };
}
export type ChangeUserFormScopePropsType = {
  customers: Entity<CustomerData>;
  sites: Entity<SiteData>;
  systems: Entity<SystemData>;
  selectedNodes: Array<TreeNodeProps>;
};
function changeUserFormScope(state: UserForm, payload: ChangeUserFormScopePropsType): UserForm {
  const { customers, sites, systems } = payload;
  const nodes: TreeNodeProps[] = payload.selectedNodes;

  // Convert selected nodes came from SelectTree component to arrays of IDs
  const { customerIds, siteIds, systemIds } = treeCheckedNodesToUserData(
    nodes,
    state.customerIds,
    state.siteIds
  );

  // Make new tree for SelectTree
  const systemsTree = userDataToTree(
    // Directories of all entries
    customers,
    sites,
    systems,
    // Currently selected entries
    customerIds,
    siteIds,
    systemIds,
    // Previously selected entries to perform descendants unchecking
    state.customerIds,
    state.siteIds
  );

  return {
    ...state,
    customerIds,
    siteIds,
    systemIds,
    systemsTree,
    isFormTouched: true,
  };
}
