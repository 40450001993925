import { SubReportTypes } from 'models/ReportData';
import React from 'react';
import styled from 'styled-components';
import { PbiFilterSystemsProps } from './PbiFilterSystemsContainer';
import { ReactComponent as BackArrow } from '../../assets/icons/BackArrow.svg';
import FilterSearcher from '../FilterSystemsPopUpMenu/FilterSearcherContainer';
import FilterSystemsPopUpMenu from 'components/FilterSystemsPopUpMenu/FilterSystemsPopUpMenuContainer';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { Drawer } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIconDownloadInkPerJob.svg';
import Funnel from 'components/_common/Funnel';

const BackArrowWrapper = styled.div`
  min-width: 50px;
  min-height: 40px;
  max-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ececec;
  }
`;

const SearchingBar = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
`;

const FilterSystemsPopUpMenuWrapper = styled.div<{ pbiReportHeight: string }>`
  height: ${props => props.pbiReportHeight};
  padding: 10px;
  width: 100%;
  background-color: white;

  display: flex;

  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`;
const FilterHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Header = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
`;
const CloseIconPlaceHolder = styled.div``;

const SystemsFilterContainerMobile = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #c5d0de;
  width: 41px;
  min-width: 41px;
  max-width: 41px;
  height: 49px;
  margin-bottom: 4px;
  margin-right: 10px;
  cursor: pointer;
`;
const PbiFilterSystemsMobile = React.memo(
  (props: PbiFilterSystemsProps & { funnelColor: string; filterNotEmpty: boolean }) => {
    const {
      activeReport,
      funnelColor,
      filterNotEmpty,
      history,
      pbiReportHeight,
      isMachineFilterOpen,
      toggleMachineFilterMenu,
    } = props;

    //*mobile slider

    const handleClick = event => {
      toggleMachineFilterMenu();
      KonnectAnalytics.toggleMachineFilterMenu();
    };
    return (
      <>
        <SystemsFilterContainerMobile data-id={'SystemsFilterContainer'}>
          {activeReport !== SubReportTypes.SystemPage ? (
            <Funnel
              automationId={`filter_systems_Btn`}
              handleClick={handleClick}
              funnelColor={funnelColor}
              filterNotEmpty={filterNotEmpty}
            />
          ) : (
            <BackArrowWrapper onClick={() => history.goBack()}>
              <BackArrow />
            </BackArrowWrapper>
          )}
        </SystemsFilterContainerMobile>

        <Drawer
          open={isMachineFilterOpen}
          anchor={'bottom'}
          // onOpen={handleClick}
          onClose={handleClick}>
          <FilterSystemsPopUpMenuWrapper pbiReportHeight={`${pbiReportHeight - 60}px`}>
            <FilterHeaderRow>
              <Header>Filter</Header>
              <CloseIconPlaceHolder onClick={handleClick}>
                <CloseIcon />
              </CloseIconPlaceHolder>
            </FilterHeaderRow>
            <SearchingBar>
              <FilterSearcher />
            </SearchingBar>
            <FilterSystemsPopUpMenu pbiReportHeight={pbiReportHeight} isMobile={true} />
          </FilterSystemsPopUpMenuWrapper>
        </Drawer>
      </>
    );
  }
);

export default PbiFilterSystemsMobile;
