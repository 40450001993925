import { appearanceEffect } from 'globalStyle';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SpeedIcon } from '../../../assets/icons/SpeedForMobileToggler.svg';
import { ReactComponent as ProductivityIcon } from '../../../assets/icons/ProductivityForMobileToggler.svg';
import { ActiveOverviewChart } from 'store/storeTypes';

const TogglerChartsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 2px;
  background: #ffffff;
  border: 1px solid #c5d0de;
  border-radius: 4px;
  width: 74px;
  height: 31px;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
const Button = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 49%;
  border-radius: 2px;
  background-color: ${props => (props.isActive ? '#2ea1f8' : '#FFFFFF')};
`;
const MobileToggleChart = props => {
  const { activeOverviewChart, selectedSystemGroup, setActiveOverviewChart } = props;
  const activeChart: ActiveOverviewChart = activeOverviewChart[selectedSystemGroup];

  const onClickHandler = (chart: ActiveOverviewChart) => {
    if (chart === activeChart) {
      return;
    }
    setActiveOverviewChart({ [selectedSystemGroup]: chart });
  };
  return (
    <TogglerChartsWrapper>
      <Button
        onClick={() => onClickHandler(ActiveOverviewChart.Speed)}
        isActive={activeChart === ActiveOverviewChart.Speed}>
        <SpeedIcon fill={activeChart === ActiveOverviewChart.Speed ? '#ffffff' : '#C5D0DE'} />
      </Button>
      <Button
        onClick={() => onClickHandler(ActiveOverviewChart.Utilization)}
        isActive={activeChart === ActiveOverviewChart.Utilization}>
        <ProductivityIcon
          fill={activeChart === ActiveOverviewChart.Utilization ? '#ffffff' : '#C5D0DE'}
        />
      </Button>
    </TogglerChartsWrapper>
  );
};

export default MobileToggleChart;
