import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { isSameDay, startOfMonth, eachYearOfInterval, isAfter, isBefore } from 'date-fns';
import { PbiFilterMonthsProps } from './PbiFilterMonthsContainer';
import { addMonths, isSameMonth, subMonths } from 'date-fns';
import { generateDatesForPeriod, getMonthForDate } from 'helpers/date';
import NewDatePicker from './NewDatePicker';

const MobileMonthItem = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MobileMonthItemHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1878ba;
  margin-bottom: 25px;
`;

const MobileMonthItemBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const MobileMonthItemBodyRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;
const MobileMonthItemBodyRowItem = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 34px;
  background: ${props => (props.isActive ? '#2ea1f8' : 'transparent')};
  border-radius: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${props => (props.isActive ? '#ffffff' : props.isDisabled ? '#C5D0DE' : '354052')};
`;
const EmptyBlock = styled.div`
  display: flex;
  height: 70px;
  min-height: 70px;
`;
const PbiFilterMonths = React.memo((props: PbiFilterMonthsProps) => {
  const {
    selectedDate,
    startDate,
    endDate,
    isMobile,
    activeReport,
    closeCalendarModal,
    setActiveDate,
  } = props;

  const monthStartDates = useMemo(
    () => generateDatesForPeriod(startDate, endDate, addMonths, startOfMonth),
    [startDate, endDate]
  );
  const handleChange = (d: Date) => {
    const i = monthStartDates.findIndex(monthStartDate =>
      isSameDay(startOfMonth(d), monthStartDate)
    );
    i >= 0 && setActiveDate(d);
  };
  const disableLeft = selectedDate <= monthStartDates[0];
  const disableRight = selectedDate >= monthStartDates[monthStartDates.length - 1];
  const getActiveMonth = () => getMonthForDate(selectedDate).title;

  const onHandleLeft = () => {
    if (!disableLeft) {
      setActiveDate(subMonths(selectedDate, 1));
    }
  };

  const onHandleRight = () => {
    if (!disableRight) {
      setActiveDate(addMonths(selectedDate, 1));
    }
  };

  if (!isMobile) {
    return (
      <>
        <NewDatePicker
          activeReport={activeReport}
          timeframe={'month'}
          onHandleLeft={onHandleLeft}
          onHandleRight={onHandleRight}
          disableLeft={disableLeft}
          disableRight={disableRight}
          date={selectedDate}
          lastUpdate={endDate}
          selectedDate={selectedDate}
          startDate={startDate}
          setActiveDate={setActiveDate}
        />
      </>
    );
  } else {
    const years = eachYearOfInterval({ start: startDate, end: endDate });
    return (
      <>
        {years.map((year, index) => {
          return <MonthsItemCalendarComponent key={year.getFullYear()} year={year} {...props} />;
        })}
        <EmptyBlock data-automationid={`empty block`} style={{ minHeight: '150px' }}></EmptyBlock>
      </>
    );
  }
});

export default PbiFilterMonths;

const MonthsItemCalendarComponent = (props: PbiFilterMonthsProps & { year: Date }) => {
  const {
    selectedDate,
    startDate,
    endDate,
    isMobile,
    year,
    closeCalendarModal,
    setActiveDate,
  } = props;
  const yearName = year.getFullYear();
  const handleMobileChange = (month: Date, isDisabled: boolean) => {
    if (isDisabled) {
      return;
    }
    setActiveDate(startOfMonth(month));
    closeCalendarModal();
  };
  const firstRow = ['JAN', 'FEB', 'MAR', 'APR'];
  const secondRow = ['MAY', 'JUN', 'JUL', 'AUG'];
  const thirdRow = ['SEP', 'OKT', 'NOV', 'DEC'];
  return (
    <MobileMonthItem>
      <MobileMonthItemHeader>{yearName}</MobileMonthItemHeader>
      <MobileMonthItemBody>
        <MobileMonthItemBodyRow>
          {firstRow.map((month, index) => {
            const monthIndexOffset = 0;
            const currentMonth = new Date(yearName, index + monthIndexOffset, 1);
            const isActive = isSameMonth(selectedDate.getTime(), currentMonth);
            const isDisabled = isAfter(currentMonth, endDate) || isBefore(currentMonth, startDate);
            return (
              <MobileMonthItemBodyRowItem
                onClick={() => handleMobileChange(currentMonth, isDisabled)}
                key={year + month}
                isDisabled={isDisabled}
                isActive={isActive}>
                {month}
              </MobileMonthItemBodyRowItem>
            );
          })}
        </MobileMonthItemBodyRow>
        <MobileMonthItemBodyRow>
          {secondRow.map((month, index) => {
            const monthIndexOffset = 4;
            const currentMonth = new Date(yearName, index + monthIndexOffset, 1);
            const isActive = isSameMonth(selectedDate.getTime(), currentMonth);
            const isDisabled = isAfter(currentMonth, endDate) || isBefore(currentMonth, startDate);
            return (
              <MobileMonthItemBodyRowItem
                onClick={() => handleMobileChange(currentMonth, isDisabled)}
                key={year + month}
                isActive={isActive}
                isDisabled={isDisabled}>
                {month}
              </MobileMonthItemBodyRowItem>
            );
          })}
        </MobileMonthItemBodyRow>
        <MobileMonthItemBodyRow>
          {thirdRow.map((month, index) => {
            const monthIndexOffset = 8;
            const currentMonth = new Date(yearName, index + monthIndexOffset, 1);
            const isActive = isSameMonth(selectedDate.getTime(), currentMonth);
            const isDisabled = isAfter(currentMonth, endDate) || isBefore(currentMonth, startDate);
            return (
              <MobileMonthItemBodyRowItem
                onClick={() => handleMobileChange(currentMonth, isDisabled)}
                key={year + month}
                isActive={isActive}
                isDisabled={isDisabled}>
                {month}
              </MobileMonthItemBodyRowItem>
            );
          })}
        </MobileMonthItemBodyRow>
      </MobileMonthItemBody>
    </MobileMonthItem>
  );
};
