import { formatSecToHourString } from 'helpers/conversions';
import { TimeFrame } from 'models/ReportData';
import React from 'react';
import DesktopAvarageAvailabilityCard from './DesktopAvarageAvailabilityCard';
import DesktopAvarageProductionRateCard from './DesktopAvarageProductionRateCard';
import {
  AvarageProductionRateCardWrapper,
  AverageAvailabilityCardWrapper,
  TopCardsDesktopWrapper,
  TotalImpressionCardWrapper,
} from './DesktopDtgApolloTopCards';
import DesktopTotalImpressionCard from './DesktopTotalImpressionCard';
import { ITopCardsProps } from './TopCardsContainer';

const DesktopDtfTopCards = (props: ITopCardsProps) => {
  const {
    totalProduction,
    units,
    productionTrend,
    selectedSystemGroup,
    selectedSystemGroupText,
    timeframe,
    productionRateTrend,
    productionRate,
    loadingTime,
    utilization,
    loadingTimeTrend,
    trendUtilization,
    darkImpressionsPercentage,
    preparation,
    trendPreparation,
    darkImpressionsTrend,
    errorDuration,
    availabilityTrend,
    availability,
    maintenanceDurationTrend,
    maintenanceDuration,
    errorDurationTrend,
    customTimeframeProperties,
  } = props;
  const getTimeframeTextKey = () => {
    return timeframe && timeframe.includes('lastUpdate') ? 'last' : 'weekDay';
  };
  const changeTextPerPage = (timeframe: TimeFrame | '', text: string) => {
    if (timeframe && timeframe.includes('lastUpdate')) return text.replace('Average', 'Current');
    return text;
  };
  return (
    <TopCardsDesktopWrapper>
      <TotalImpressionCardWrapper style={{ width: '33%' }}>
        <DesktopTotalImpressionCard
          customTimeframeProperties={customTimeframeProperties}
          value={totalProduction}
          units={units}
          stats={productionTrend}
          selectedSystemGroup={selectedSystemGroup}
          selectedSystemGroupText={selectedSystemGroupText}
          description={selectedSystemGroupText.impression.description[getTimeframeTextKey()]}
          timeframe={timeframe}
        />
      </TotalImpressionCardWrapper>

      <AvarageProductionRateCardWrapper style={{ width: '33%' }}>
        <DesktopAvarageProductionRateCard
          customTimeframeProperties={customTimeframeProperties}
          value={productionRate}
          units={units + '/h'}
          description={selectedSystemGroupText.productionRate.description[getTimeframeTextKey()]}
          stats={productionRateTrend}
          statsDescription={
            selectedSystemGroupText.productionRate.statsDescription[getTimeframeTextKey()]
          }
          addedData={{
            upper: {
              unit: selectedSystemGroup === 'DTF' ? '%' : 'sec/' + units,
              value: selectedSystemGroup === 'DTF' ? utilization : loadingTime,
              description: changeTextPerPage(
                timeframe,
                selectedSystemGroupText.productionRate.upper
              ),
              trend: selectedSystemGroup === 'DTF' ? trendUtilization : loadingTimeTrend,
            },
            lower: {
              value: selectedSystemGroup === 'DTF' ? preparation : darkImpressionsPercentage,
              description: selectedSystemGroupText.productionRate.lower,
              unit: '%',
              trend: selectedSystemGroup === 'DTF' ? trendPreparation : darkImpressionsTrend,
            },
          }}
        />
      </AvarageProductionRateCardWrapper>
      <AverageAvailabilityCardWrapper style={{ width: '33%' }}>
        <DesktopAvarageAvailabilityCard
          customTimeframeProperties={customTimeframeProperties}
          cardName="availability"
          value={availability}
          units={'%'}
          isPercentage={true}
          description={selectedSystemGroupText.availability.description[getTimeframeTextKey()]}
          stats={availabilityTrend}
          statsDescription={
            selectedSystemGroupText.availability.statsDescription[getTimeframeTextKey()]
          }
          addedData={{
            upper: {
              unit: '',
              value: errorDuration,
              description: selectedSystemGroupText.availability.upper,
              trend: errorDurationTrend,
              formatter: formatSecToHourString,
            },
            lower: {
              value: maintenanceDuration,
              unit: '',
              description: selectedSystemGroupText.availability.lower,
              trend: maintenanceDurationTrend,
              formatter: formatSecToHourString,
            },
          }}
        />
      </AverageAvailabilityCardWrapper>
    </TopCardsDesktopWrapper>
  );
};
export default DesktopDtfTopCards;
