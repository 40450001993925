import React from 'react';
import styled from 'styled-components';
import BurgerMobile from './BurgerMobile';
import { ShellHeaderUiProps } from './ShellHeader';
import { ShellHeaderProps } from './ShellHeaderContainer';
import { ReactComponent as KornitMobileIcon } from '../../assets/icons/KornitIconMobile.svg';
import { ReactComponent as KornitNameIcon } from '../../assets/icons/KornitNameIcon.svg';
import { ReactComponent as PrinterIcon } from '../../assets/icons/PrinterIconMobile.svg';
import { format, isValid } from 'date-fns';
import ShellUserNav from './ShellUserNav';
import { StyledAvatar } from './ShellHeaderDesktop';
import { Link } from 'react-router-dom';

const MobileHeaderWrapper = styled.div`
  box-sizing: border-box;
  height: 88px;
  min-height: 88px;
  max-height: 88px;
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #2ea1f8;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const BurgerPlaceHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LogoLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
`;
const LogoPlaceHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  margin-left: 20px;
`;
const LogoItemPlaceHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-left: 1px solid #ffffff;
  padding-left: 14px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  width: 164px;
`;
const LastUpdatePlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
`;
const LastUpdateUpperRow = styled.div.attrs({
  'data-automationid': ' totalPrintersCount',
})`
  display: flex;
  font-size: 14px;
`;
const LastUpdateUpperRowSpan = styled.span`
  margin-left: 10px;
`;
const LastUpdateBottomRow = styled.div`
  display: flex;
  font-size: 14px;
`;

const AvatarPlaceHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 51px;
`;
const LoadingPlaceHolder = styled.div`
  display: flex;
  width: 100%;
  padding-right: 30px;
`;
const LogoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CustomerName = styled.div`
  width: 100px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  margin-top: 4px;
`;
const ShellHeaderMobile = (props: ShellHeaderUiProps) => {
  const {
    profilePictureUrl,
    selectedSystemGroup,
    activeSystemsMap,
    checkedLastUpdate,
    activeReport,
    isReadOnlyRole,
    tennantName,
  } = props;
  return (
    <MobileHeaderWrapper>
      <LeftSide>
        <BurgerPlaceHolder>
          <BurgerMobile activeReport={activeReport} />
        </BurgerPlaceHolder>
        <LogoBlock>
          <LogoLink to="/reports/overview">
            <LogoPlaceHolder>
              <LogoItemPlaceHolder>
                <KornitMobileIcon />
              </LogoItemPlaceHolder>
              <LogoItemPlaceHolder>
                <KornitNameIcon />
              </LogoItemPlaceHolder>
            </LogoPlaceHolder>
          </LogoLink>
          {isReadOnlyRole && <CustomerName>{tennantName}</CustomerName>}
        </LogoBlock>
      </LeftSide>
      <RightSide>
        <LastUpdatePlaceHolder>
          <LastUpdateUpperRow>
            {activeSystemsMap.length > 1 ? (
              <>
                <PrinterIcon />{' '}
                <LastUpdateUpperRowSpan>
                  {activeSystemsMap[0]}
                  {' / '}
                  {activeSystemsMap[2]}
                </LastUpdateUpperRowSpan>
              </>
            ) : (
              <LoadingPlaceHolder>Loading...</LoadingPlaceHolder>
            )}
          </LastUpdateUpperRow>
          <LastUpdateBottomRow>
            {isValid(checkedLastUpdate) && format(checkedLastUpdate, 'MMMM dd, yyyy, hh:mm aaaa')}
          </LastUpdateBottomRow>
        </LastUpdatePlaceHolder>
        <AvatarPlaceHolder>
          <ShellUserNav isReadOnlyRole={isReadOnlyRole} selectedSystemGroup={selectedSystemGroup}>
            <StyledAvatar
              isMobile={true}
              size="small"
              background="#2ea1f8"
              avatarUrl={profilePictureUrl}
            />
          </ShellUserNav>
        </AvatarPlaceHolder>
      </RightSide>
    </MobileHeaderWrapper>
  );
};
export default ShellHeaderMobile;
