import { createReducer } from 'typesafe-actions';
import { RootAction } from 'actions';
import { getMachineConfigAC, setMachineConfigDevice } from 'actions/machineConfigActions';
import { MachineConfig } from '../store/storeTypes';

const INIT_STATE: MachineConfig = {
  params: [],
  device: {
    id: '',
    name: '',
    imageUrl: '',
  },
};

const reducer = createReducer<MachineConfig, RootAction>(INIT_STATE)
  .handleAction(getMachineConfigAC.success, (state, action) => {
    return { ...state, params: action.payload };
  })
  .handleAction(setMachineConfigDevice, (state, action) => {
    return { ...state, device: action.payload };
  });

export default reducer;
