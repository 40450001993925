import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { goalsArraySelector } from 'selectors/entitiesSelectors';
import { Store } from 'store/storeTypes';
import { getGoalsData } from 'actions/goalApiActions';
import Goals from './Goals';

const mapStateToProps = (state: Store) => ({
  goalsEntries: goalsArraySelector(state),
  isLoadingGoals: state.api.isLoadingGoals,
  isSavingGoals: state.api.isSavingGoals,
  selectedSystemGroup: state.ui.selectedSystemGroup,
  userSettings: state.userSettings,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getGoalsData,
    },
    dispatch
  );

export type GoalsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Goals);
