import { createSelector } from 'reselect';
import { RootAction } from 'actions';
import { setActiveOverviewChart } from 'actions/uiActions';
import { ActiveOverviewChart, Store } from 'store/storeTypes';
import { createReducer } from 'typesafe-actions';
import { systemGroupSelector } from './systemGroupUiReducer';

export type OverviewUI = {
  activeChart: { DTF: ActiveOverviewChart; DTG: ActiveOverviewChart };
};

const INIT_STATE: OverviewUI = {
  activeChart: {
    DTF: ActiveOverviewChart.Speed,
    DTG: ActiveOverviewChart.Speed,
  },
};
const reducer = createReducer<OverviewUI, RootAction>(INIT_STATE).handleAction(
  setActiveOverviewChart,
  (state, action) => {
    return {
      ...state,
      activeChart: { ...state.activeChart, ...action.payload },
    };
  }
);

export default reducer;

const uiStateSelector = (state: Store) => {
  return state.ui;
};
const uiOverviewSelector = createSelector([uiStateSelector], uiState => {
  return uiState['overview'];
});
export const uiOverviewActiveChartSelector = createSelector([uiOverviewSelector], uiOverview => {
  return uiOverview['activeChart'];
});
export const uiOverviewActiveChartSelectorBySystemGroupSelector = createSelector(
  [uiOverviewActiveChartSelector, systemGroupSelector],
  (uiOverviewActiveChart, systemGroup) => {
    return uiOverviewActiveChart[systemGroup];
  }
);
