import React from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { format, isValid } from 'date-fns';

import { ConfigNamesEnum } from 'store/storeTypes';

import Avatar from '../Avatar/Avatar';
import { ReactComponent as KornitLogo } from 'assets/icons/kornitLogo.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/syncIcon.svg';
import { ReactComponent as PrinterIcon } from 'assets/icons/printerIcon.svg';
import VersionLabelHeader from '../_common/VersionLabelHeader';
import ShellReportsNav from './ShellReportsNav';
import ShellUserNav from './ShellUserNav';
import { ShellHeaderUiProps } from './ShellHeader';
import config from 'config/config';
import { getShowVersionLabel } from 'helpers/envGetters';

const SHOW_VERSION_VERSION_LABEL = getShowVersionLabel();

const Desktop = styled.div`
  display: flex;
  //flex: 1;
  align-items: center;
  @media (max-width: 750px) {
    display: none;
  }
`;

const AppBar = styled(MuiAppBar)`
  box-shadow: none;
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  background: #2ea1f8;
  padding: 0 0 0 15px;
  min-height: 58px;
  justify-content: space-between;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    order: 2;
  }
`;

const AvatarContainer = styled.div`
  order: 3;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 1rem;
  cursor: pointer;
  width: 44px;
`;

const LastUpdateTimeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const LastUpdateTimeLeftPart = styled.div`
  position: relative;
  display: flex;
  margin-right: 5px;
`;

const LastUpdateTimeRightPart = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const LastUpdateTimeTopPart = styled.div``;

const Text = styled.span`
  font-family: Rubik, serif;
  font-style: normal;
  font-size: 14px;
  color: white;
  white-space: nowrap;
`;
const LastUpdateTimeText = styled.span`
  font-family: Rubik, serif;
  font-style: normal;
  font-size: 14px;
  color: white;
  white-space: nowrap;
`;

const ActiveSystemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ActiveSystemLeftPart = styled.div`
  position: relative;
  display: flex;
  margin-right: 5px;
`;

const ActiveSystemRightPart = styled.div.attrs({
  'data-id': 'header-totalPrintersCount',
  'data-automationid': ' totalPrintersCount',
})`
  position: relative;
  display: flex;
`;

const ActiveSystemTextBold = styled.span`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: white;
  white-space: nowrap;
`;

const ActiveSystemText = styled.span`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
  white-space: nowrap;
`;

const Cell = styled.div`
  position: relative;
  padding: 0 20px;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 19px;
    width: 1px;
    background-color: white;
    transform: translateY(-50%);
  }
`;

const LoadingText = styled.div`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: white;
`;

const ShellHeaderDesktop = (props: ShellHeaderUiProps) => {
  const {
    profilePictureUrl,
    selectedSystemGroup,
    activeSystemsMap,
    checkedLastUpdate,
    userEmail,
    tennantName,
    isReadOnlyRole,
    lastResponseUpdateTime,
  } = props;
  return (
    <AppBar position="static">
      <Toolbar>
        <LogoContainer to="/reports/overview">
          <KornitLogo width={102} height={37} />
        </LogoContainer>

        {SHOW_VERSION_VERSION_LABEL && <VersionLabelHeader />}

        <ShellReportsNav userEmail={userEmail} />

        <Desktop>
          {isReadOnlyRole && (
            <Cell>
              <Text>{tennantName}</Text>
            </Cell>
          )}
          {config.ENV_NAME === ConfigNamesEnum.Dev && lastResponseUpdateTime && (
            <Cell>
              <Text>
                Last Update Time:{' '}
                {format(lastResponseUpdateTime, "dd/MM/yyyy : HH'h:'mm'm:'ss'sec'")}
              </Text>
            </Cell>
          )}
          <Cell>
            {checkedLastUpdate && isValid(checkedLastUpdate) && (
              <LastUpdateTimeWrapper>
                <LastUpdateTimeLeftPart>
                  <SyncIcon width={30} height={30} />
                </LastUpdateTimeLeftPart>
                <LastUpdateTimeRightPart>
                  <LastUpdateTimeTopPart>
                    <LastUpdateTimeText>
                      {format(checkedLastUpdate, 'MMMM dd, yyyy, hh:mm aaaa')}
                    </LastUpdateTimeText>
                  </LastUpdateTimeTopPart>
                </LastUpdateTimeRightPart>
              </LastUpdateTimeWrapper>
            )}
          </Cell>

          <Cell>
            {activeSystemsMap.length > 1 ? (
              <ActiveSystemWrapper>
                <ActiveSystemLeftPart>
                  <PrinterIcon width={30} height={30} />
                </ActiveSystemLeftPart>
                <ActiveSystemRightPart>
                  <ActiveSystemTextBold>{activeSystemsMap[0]}</ActiveSystemTextBold>
                  &nbsp;
                  <ActiveSystemTextBold> {activeSystemsMap[1]}</ActiveSystemTextBold>
                  &nbsp;
                  <ActiveSystemText> {activeSystemsMap[2]}</ActiveSystemText>
                </ActiveSystemRightPart>
              </ActiveSystemWrapper>
            ) : (
              <LoadingText>Loading...</LoadingText>
            )}
          </Cell>
        </Desktop>

        <AvatarContainer>
          <ShellUserNav isReadOnlyRole={isReadOnlyRole} selectedSystemGroup={selectedSystemGroup}>
            <StyledAvatar size="small" background="#2ea1f8" avatarUrl={profilePictureUrl} />
          </ShellUserNav>
        </AvatarContainer>
      </Toolbar>
    </AppBar>
  );
};
export default ShellHeaderDesktop;
