import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Scheduler from './Scheduler';
import { Store } from 'store/storeTypes';
import { openModalSchedulerCreate } from 'actions/schedulerUiActions';
import { getAppUserFull } from 'actions/appUserActions';
import { getScopeData } from 'actions/scopeApiActions';
import { getSchedulers, getSchedulersReportTypes } from 'actions/schedulerApiActions';

const mapStateToProps = (state: Store) => ({
  appUser: state.appUser,
  isLoadingTypes: state.api.isLoadingSchedulerTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModalSchedulerCreate,
      getAppUserFull,
      getScopeData,
      getSchedulers,
      getSchedulersReportTypes,
    },
    dispatch
  );

export type SchedulerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
