import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from 'actions';
import { FilterCalendarModal, MODAL_STATUS } from 'store/storeTypes';

const INIT_STATE: FilterCalendarModal = {
  status: MODAL_STATUS.CLOSED,
  previewStatus: MODAL_STATUS.CLOSED,
  selectedFilter: '',
};

const { openCalendarModal, closeCalendarModal } = actions.filterCalenderUi;

export default createReducer<FilterCalendarModal, any>(INIT_STATE)
  .handleAction(openCalendarModal, state => ({
    ...state,
    status: MODAL_STATUS.EDITING,
    previewStatus: MODAL_STATUS.CLOSED,
  }))
  .handleAction(closeCalendarModal, state => ({
    ...state,
    status: MODAL_STATUS.CLOSED,
    previewStatus: MODAL_STATUS.EDITING,
  }));
