import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouterProps, match } from 'react-router';

import PbiReportsPage from './PbiReportsPage';
import { Store } from 'store/storeTypes';
import { setActiveReport } from 'actions/reportUiActions';
import { SubReportTypes } from 'models/ReportData';
import { setPbiReportSize, setSystemGroup, toggleMachineFilterMenu } from 'actions/userUiActions';
import {
  activeReprotSelector,
  isSelectedSystemsEverReportedBySystemGroupSelector,
  isStateInitializedBySystemGroup,
  selectedDateSelector,
  selectedSystemSerialsBySystemGroup,
  timeFrameSelector,
} from 'reducers/reports/filterStateReducer';
import { filterDataBySystemGroupSelector } from 'reducers/reports/filterDataReducer';
import { isMachineFilterOpenSelector } from 'reducers/ui';
import { setFilterSystemsState } from 'actions/reportFilterActions';
import {
  pbiReportHeightSelector,
  pbiReportWidthSelector,
  screenHeightSelector,
  screenWidthSelector,
} from 'reducers/appUserReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { isEverReportedLoadedSelector } from 'reducers/isEverReportedReducer';

interface OwnProps extends RouterProps {
  isLoading: boolean;
  match?: match<{ reportType: string; subReportType: SubReportTypes; systemName: string }>;
}

const mapStateToProps = (state: Store) => {
  return {
    filterData: filterDataBySystemGroupSelector(state),
    timeFrame: timeFrameSelector(state),
    activeReport: activeReprotSelector(state),
    day: selectedDateSelector(state),
    isSelectedSystemsEverReported: isSelectedSystemsEverReportedBySystemGroupSelector(state),
    isEverReportedLoaded: isEverReportedLoadedSelector(state),
    isStateInitialized: isStateInitializedBySystemGroup(state),
    isMachineFilterOpen: isMachineFilterOpenSelector(state),
    screenHeight: screenHeightSelector(state),
    screenWidth: screenWidthSelector(state),
    pbiReportWidth: pbiReportWidthSelector(state),
    pbiReportHeight: pbiReportHeightSelector(state),
    selectedSystems: selectedSystemSerialsBySystemGroup(state),
    selectedSystemGroup: systemGroupSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveReport,
      setSystemGroup,
      setFilterSystemsState,
      toggleMachineFilterMenu,
      setPbiReportSize,
    },
    dispatch
  );

export type PbiReportsPageProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PbiReportsPage);
