import { combineReducers, Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { Entries, Ids } from '../../store/storeTypes';
import { CustomerData } from '../../models/CustomerData';

const { loadCustomersSuccess } = actions.scopeApi;

const customerById = createReducer<Entries<CustomerData>, RootAction>({}).handleAction(
  loadCustomersSuccess,
  (state, action) => {
    if (action.payload) {
      const newState: Entries<CustomerData> = {};

      action.payload.forEach((org: CustomerData) => {
        newState[org.id] = org;
      });

      return newState;
    }

    return state;
  }
);

const customerAllIds = createReducer<Ids, RootAction>([]).handleAction(
  loadCustomersSuccess,
  (state, action) => {
    if (action.payload) {
      const newState: Ids = [];

      action.payload.forEach((org: CustomerData) => {
        newState.push(org.id);
      });

      return newState;
    }

    return state;
  }
);

const customerEntityReducer: Reducer = combineReducers({
  byId: customerById,
  allIds: customerAllIds,
});

export default customerEntityReducer;
