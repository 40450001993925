import { GenericPropsType } from './../store/storeTypes';
import { History, Location } from 'history';
import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import i18n from 'i18next';
import { AuthService } from 'contracts/auth';
import { ErrorsHandler } from 'contracts/errors-handling';
import { AUTH0_CLAIMS_NAMESPACE, authCallbacksRegex, defaultAppPath } from 'contracts/constants';
import config from 'config/config';
import { Browser } from '@capacitor/browser';
interface AuthConfig {
  domain: string;
  clientID: string;
  redirectUri: string;
  audience: string;
  returnTo: string;
}

const { domain, clientID, redirectUri, audience, returnTo }: AuthConfig = config.AUTH_CONFIG_STR!;

const LOGGED_IN_STORAGE_DATA_KEY = 'loginData';

interface LoginParams {
  idTokenPayload: GenericPropsType;
  idToken: string;
  accessToken?: string;
  expiresAt: number;
}

export class AuthServiceImpl implements AuthService {
  private readonly _authOptions = {
    domain,
    clientID,
    redirectUri,
    audience,
    responseType: 'token id_token',
    scope: 'openid profile user_metadata app_metadata',
  };

  private readonly _auth0Client = new WebAuth(this._authOptions);

  private _loginParams: LoginParams | null = null;

  private tokenRenewalTimeout: number | undefined;

  constructor(private readonly _errorsHandler: ErrorsHandler, private readonly _history: History) {
    this._loadSession();
  }

  public get accessToken() {
    return (this._loginParams && this._loginParams.accessToken) || null;
  }

  public get idTokenPayload() {
    return (this._loginParams && this._loginParams.idTokenPayload) || {};
  }

  private _loadSession() {
    const serializedSession = localStorage.getItem(LOGGED_IN_STORAGE_DATA_KEY);
    if (serializedSession) {
      this._loginParams = JSON.parse(serializedSession);
      this._scheduleRenewal();
    }
  }

  private _setSession(authResult: Auth0DecodedHash) {
    this._loginParams = {
      expiresAt: authResult.expiresIn! * 1000 + Date.now(),
      accessToken: authResult.accessToken!,
      idToken: authResult.idToken!,
      idTokenPayload: authResult.idTokenPayload!,
    };
    // localStorage.setItem(LOGGED_IN_STORAGE_DATA_KEY, JSON.stringify(this._loginParams));

    this._scheduleRenewal();
  }

  private _renewSession = () => {
    this._auth0Client.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this._setSession(authResult);
      } else if (err) {
        this.logout();
        // TODO: handle errors
        console.log(err);
      }
    });
  };

  private _scheduleRenewal = () => {
    const expiresAt = this._loginParams && this._loginParams.expiresAt;
    if (expiresAt) {
      const timeout = expiresAt - Date.now() - 15000;
      if (timeout && timeout > 0) {
        // @ts-ignore
        this.tokenRenewalTimeout = setTimeout(() => {
          this._renewSession();
        }, timeout);
      }
    }
  };

  public handleAuthentication = (location: Location<History.LocationState>) => {
    if (this.isAuthUrl(location)) {
      this._auth0Client.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this._setSession(authResult);
          this._history.replace(defaultAppPath);
          return;
        }

        if (err) {
          this._errorsHandler.onError(
            new Error(err.toString()),
            'auth',
            i18n.t('authErrors.authenticationFailed', 'User authentication failed')
          );
        }

        this._history.replace(defaultAppPath);
      });
    }
  };

  public isAuthUrl = (location: Location<History.LocationState>) => {
    return authCallbacksRegex.test(location.hash);
  };

  // public login = () => {
  //   this._auth0Client.authorize();
  // };

  public login = async () => {
    const platform = config.PLATFORM;
    if (platform !== 'web') {
      const url = config.AUTH_CONFIG_STR.redirectUri;
      await Browser.open({
        url,
      });
    } else {
      this._auth0Client.authorize();
    }
  };
  public logout = () => {
    localStorage.removeItem(LOGGED_IN_STORAGE_DATA_KEY);
    this._loginParams = null;
    this.tokenRenewalTimeout && clearTimeout(this.tokenRenewalTimeout);
    this._auth0Client.logout({ clientID, returnTo });
  };

  public isAuthenticated = () => {
    const expiresAt = (this._loginParams && this._loginParams.expiresAt) || 0;
    return Date.now() < expiresAt;
  };

  public getUserRoles = () => {
    return (
      this._loginParams &&
      this._loginParams.idTokenPayload &&
      this._loginParams.idTokenPayload[`${AUTH0_CLAIMS_NAMESPACE}roles`]
    );
  };

  public updateLocaleInLocalStorage = (locale: string) => {
    const data = localStorage.getItem(LOGGED_IN_STORAGE_DATA_KEY);
    if (!data) return;

    const token = JSON.parse(data);
    token.idTokenPayload.locale = locale;
    token.idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}locale`] = locale;

    localStorage.setItem(LOGGED_IN_STORAGE_DATA_KEY, JSON.stringify(token));
  };
}
