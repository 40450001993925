import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';
import _ from 'lodash';
import { actions } from 'actions';
import {
  ReportSite,
  ReportModel,
  ReportSystem,
  ReportFilterDataByType,
  UserSystemGroupStrings,
  ReportsFilterDataByTypePartial,
  Store,
} from 'store/storeTypes';
import { parse } from 'date-fns';
import { ReportsFiltersDatesApi, ReportsFilterSitesApi } from 'models/ReportsFilterData';
import { changeNickNameAC } from 'actions/systemAnalysisActions';
import { getEmptyStateAC } from 'actions/reportFilterActions';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { updateFilterDataStateWithEverReportedStatus } from 'helpers/filter-data';

const INIT_STATE: ReportFilterDataByType = {
  DTG: {
    sites: [],
    models: [],
    systems: [],
    startDate: undefined,
    endDate: undefined,
    activeSystems: '',
  },
  DTF: {
    sites: [],
    models: [],
    systems: [],
    startDate: undefined,
    endDate: undefined,
    activeSystems: '',
  },
  Apollo: {
    sites: [],
    models: [],
    systems: [],
    startDate: undefined,
    endDate: undefined,
    activeSystems: '',
  },
};

const {
  loadReportsFilterDatesSuccess,
  loadReportsFilterSitesSuccess,
  loadReportsCurrentStateSuccess,
} = actions.reportApi;

const filterSitesReducer = createReducer<ReportsFilterDataByTypePartial, any>(INIT_STATE)
  .handleAction(loadReportsFilterSitesSuccess, (state, action) => {
    const sites = apiFilterSitesNormalize(state, action.payload);

    return _.merge(_.cloneDeep(state), sites);
  })
  .handleAction(loadReportsFilterDatesSuccess, (state, action) => {
    const dates = apiFilterDatesNormalize(state, action.payload);
    return _.merge(_.cloneDeep(state), dates);
  })
  .handleAction(loadReportsCurrentStateSuccess, (state, action) => {
    const currentStateByType: BuildCurrentStateByType = {};
    Object.keys(action.payload).forEach((type: string) => {
      currentStateByType[type as UserSystemGroupStrings] = {
        activeSystems: action.payload[type as UserSystemGroupStrings].activeSystemsLabel,
      };
    });

    return _.merge(_.cloneDeep(state), { ...currentStateByType });
  })
  .handleAction(changeNickNameAC.success, (state, action) => {
    const { data, selectedSystemGroup } = action.payload;
    const { serialNumber, nickName } = data;
    const currentSystem = state[selectedSystemGroup].systems.find(
      system => system.name === serialNumber
    );
    //*this one goes to result
    const updatedSystem = { ...currentSystem, systemNickName: nickName };
    //*those goes to result
    const restSystems = state[selectedSystemGroup].systems.filter(
      system => system.name !== serialNumber
    );
    //*result
    const updatedSystems = new Array();
    updatedSystems.push(updatedSystem);
    restSystems.forEach(system => updatedSystems.push({ ...system }));
    const newState = { ...state[selectedSystemGroup], systems: updatedSystems };
    return { ...state, [selectedSystemGroup]: newState };
  })
  .handleAction(getEmptyStateAC.success, (state, action) => {
    const newState = updateFilterDataStateWithEverReportedStatus(state, action);
    return newState;
  });

export default filterSitesReducer;

//*selectors
const reportsSelector = (state: Store) => state.reports;

export const activeReportSelector = createSelector([reportsSelector], reports => {
  return reports['activeReport'];
});
export const filterDataSelector = createSelector([reportsSelector], reports => {
  return reports['filterData'];
});

export const filterDataBySystemGroupSelector = createSelector(
  [filterDataSelector, systemGroupSelector],
  (filterData, systemGroup) => {
    return filterData[systemGroup];
  }
);
export const activeSystemsBySystemGroupSelector = createSelector(
  [filterDataBySystemGroupSelector],
  filterDataByGroup => {
    return filterDataByGroup['activeSystems'];
  }
);
export const endDateSelector = createSelector([filterDataBySystemGroupSelector], filterData => {
  return filterData['endDate'];
});
export const startDateSelector = createSelector([filterDataBySystemGroupSelector], filterData => {
  return filterData['startDate'];
});
export const allSystemsSelector = createSelector(
  [filterDataBySystemGroupSelector],
  filterData => filterData['systems']
);
export const allSystemSerialsSelector = createSelector([allSystemsSelector], systems =>
  systems.map(system => system.name)
);
//*selectors
function apiFilterDatesNormalize(
  state: ReportsFilterDataByTypePartial,
  apiData: ReportsFiltersDatesApi
): ReportsFilterDataByTypePartial {
  const result: ReportsFilterDataByTypePartial = {};
  apiData.dateFilters.forEach(dateFilter => {
    const { startDate, endDate } = dateFilter.dateRange;
    //*by requirments if end and start date === null -  it means empty state page should be shown and we need to set instead of null some value to calculate empty state later in PBIREPORTSPAGE
    const isDateRangeNull = isStartEndDateOnNull(startDate, endDate);
    const initDate = new Date(0);
    const start = isDateRangeNull ? initDate : parse(startDate, 'yyyyMMdd', new Date());
    const end = isDateRangeNull ? initDate : parse(endDate, 'yyyyMMdd', new Date());
    const range = {
      startDate: start,
      endDate: end,
    };
    result[dateFilter.systemGroup] = range;
  });
  return result;
}
function isStartEndDateOnNull(startDate, endDate) {
  //*by last server changes null and empty string are possible. both of them are sings of empty state
  if (startDate === null || endDate === null || startDate === '' || endDate === '') {
    return true;
  } else {
    return false;
  }
}
function apiFilterSitesNormalize(
  state: ReportsFilterDataByTypePartial,
  apiData: ReportsFilterSitesApi
): ReportsFilterDataByTypePartial {
  const result: ReportsFilterDataByTypePartial = {};

  apiData.totalSiteExtensions.forEach(extention => {
    const sites: ReportSite[] = [];
    const models: ReportModel[] = [];
    const systems: ReportSystem[] = [];
    extention.siteExtensions.forEach(site => {
      sites.push({
        id: site.name,
        name: site.name,
      });

      site.modelFilters.forEach(model => {
        const modelId = `${site.name}_${model.name}`;

        models.push({
          id: modelId,
          name: model.name,
          siteId: site.name,
        });

        model.systemFilters.forEach(system => {
          systems.push({
            id: system.name,
            name: system.serialNumber,
            siteId: site.name,
            modelId,
            systemNickName: system.systemNickName,
          });
        });
      });
    });
    result[extention.systemGroup] = { sites, models, systems };
  });

  return result;
}

export const filterEmptyStrings = (arr: string[]) => {
  return arr.filter(item => item !== '' && item !== null);
};

type BuildCurrentStateByType = {
  [key in UserSystemGroupStrings]?: { activeSystems: string };
};
