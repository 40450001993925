import { ConfigNamesEnum } from 'store/storeTypes';
import { config } from '../config';

const configTest: config = {
  AUTH_CONFIG_STR: {
    domain: 'dev-sbafxfr4.eu.auth0.com',
    clientID: 'mcBsdgXJy65MnLNlzlFFAmo8sTTRPhRw',
    redirectUri: 'https://kncttestadminportalapp.z6.web.core.windows.net',
    audience: 'https://test.konnect.kornit.com',
    returnTo: 'https://kncttestadminportalapp.z6.web.core.windows.net',
  },
  API_ROUTER_HOST:
    'https://konnect-test-admin-function-app-handlers.azurewebsites.net/api/portalUrl',
  AUTH0_CLAIMS_NAMESPACE: 'https://schemas.dev-sbafxfr4.com/',
  SELF_HOST: 'https://kncttestadminportalapp.z6.web.core.windows.net',
  USER_LOGOUT_TIMEOUT: 14400000,
  USER_AGREEMENT_VERSION: 1.5,
  WEEK_START: 1,
  FILTER_HIDE: false,
  SHOW_PBI_REPORT_PAGES_PANE: false,
  SHOW_PBI_REPORT_FILTERS_PANE: false,
  REPORTS_REFRESH_TIMEOUT_SECONDS: 300,
  MAX_LOAD_ATTEMPTS: 5,
  SPLIT_WEEK_BY_END_OF_YEAR: true,
  ENV_NAME: ConfigNamesEnum.Test,
  PLATFORM: 'web',
  SHOW_VERSION_VERSION_LABEL: true,
  GENERATE_SOURCEMAP: true,
  LOGGER_INSTRUMENTAION_KEY: 'c3391ca2-a3dd-4a33-bdf8-cf99d3325403',
  REFRESH_API_INTERVAL: 600000,
  UNSUBSCRIBE_END_POINT:
    'https://konnect-test-edge-portal-api-appservice.kornitkonnect.com/api/email-reports-scheduler/unsubscribe',
  SERVICE_TICKET_END_POINT: 'https://SandboxKornit.formtitan.com/CaseFormJuly',
  BLOB_URL: 'https://devconnectportaldata.blob.core.windows.net',
  API_URL: 'https://konnect-test-edge-portal-api-appservice.kornitkonnect.com',
  RECAPTCHA_KEY: '6LdzndIjAAAAANQ7LVjhg7siwpSWmVM3A9YkXai8',
};
export default configTest;
