import React, { Fragment, useState, useRef } from 'react';
import { SwipeableDrawer, RootRef, List, Popover } from '@material-ui/core';

import PopupMenuItem, { PopupMenuItemValueType } from './PopupMenuItem';

interface IPopMenuProps {
  children: React.ReactNode;
  menuItems: PopupMenuItemValueType[];
  isDrawer?: boolean;
}

const PopupMenu = (props: IPopMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const anchorEl = useRef(null);

  const { children, menuItems, isDrawer } = props;
  return (
    <Fragment>
      <RootRef rootRef={anchorEl}>
        <div onClick={handleToggle}>{children}</div>
      </RootRef>

      {isDrawer ? (
        <SwipeableDrawer open={open} onClose={handleClose} onOpen={handleOpen}>
          <List>
            {menuItems.map((item, i) => {
              return <PopupMenuItem key={i} item={item} handleClose={handleClose} />;
            })}
          </List>
        </SwipeableDrawer>
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <List>
            {menuItems.map((item, i) => {
              return <PopupMenuItem key={i} item={item} handleClose={handleClose} />;
            })}
          </List>
        </Popover>
      )}
    </Fragment>
  );
};

export default PopupMenu;
