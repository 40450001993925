import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import PageContainer from '../_common/PageContainer';
import { PageHeaderUIContainer, PageHeaderUISpacer } from '../_common/PageHeaderUi';
import UsersTable from '../UsersTable/UsersTableContainer';
import UserModal from '../UserModal/UserModalContainer';
import ButtonHeader from '../_common/ButtonHeader';
import Header1 from '../_common/Header1';
import SearchBarGeneric from '../_common/SearchBar';
import { AccountManagementProps } from './AccountManagementContainer';
import config from 'config/config';
import ReCAPTCHA from 'react-google-recaptcha';
const SearchBar = styled(SearchBarGeneric)`
  margin-left: 20px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const RecaptchaWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const AccountManagement = React.memo((props: AccountManagementProps) => {
  const { t } = useTranslation();
  const {
    isLoadingEntities,
    isCaptchaOpen,
    userIdForCaptcha,
    openModalUserCreate,
    changeUserSearchValue,
    getUsers,
    getScopeData,
    setCaptchaIsOpen,
    resetUserPassword,
  } = props;
  useEffect(() => {
    getUsers();
    getScopeData();
  }, [getUsers, getScopeData]);

  const onCaptchaChange = value => {
    //*this event fires only if captcha completed succesfull
    resetUserPassword(userIdForCaptcha, true, value);
    setCaptchaIsOpen({ isOpen: false, userId: '' });
  };

  return (
    <PageContainer>
      {isCaptchaOpen ? (
        <RecaptchaWrapper>
          <ReCAPTCHA sitekey={config.RECAPTCHA_KEY} onChange={onCaptchaChange} />,
        </RecaptchaWrapper>
      ) : (
        <>
          <PageHeaderUIContainer>
            <Header1 data-testid="account-management-header">
              {t('accountManagement.header')}
            </Header1>
            <PageHeaderUISpacer>
              {!isLoadingEntities && (
                <ButtonHeader
                  data-testid="button-add-user"
                  onClick={openModalUserCreate}
                  data-automationid={`accountManagmentAddUserButton`}>
                  <AddIcon data-testid="button-add-user-icon" /> {t('accountManagement.addUser')}
                </ButtonHeader>
              )}
            </PageHeaderUISpacer>
            <SearchBar onChange={changeUserSearchValue} />
          </PageHeaderUIContainer>

          <UsersTable />

          <UserModal />
        </>
      )}
    </PageContainer>
  );
});

export default AccountManagement;
