import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { isSameDay, startOfYear, addYears } from 'date-fns';
import { PbiFilterYearsProps } from './PbiFilterYearsContainer';
import { generateDatesForPeriod, getYearForDate } from 'helpers/date';
import { subYears } from 'date-fns';
import NewDatePicker from './NewDatePicker';

const EmptyBlock = styled.div`
  display: flex;
  width: 100%;
  min-height: 110px;
`;
const YearItem = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 97px;
  height: 40px;
  border-radius: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: ${props => (props.isActive ? '#FFFFFF' : '#354052')};
  background-color: ${props => (props.isActive ? '#2EA1F8' : 'transparent')};
`;

const PbiFilterYears = React.memo((props: PbiFilterYearsProps) => {
  const {
    startDate,
    endDate,
    selectedDate,
    isMobile,
    activeReport,
    closeCalendarModal,
    setActiveDate,
  } = props;

  const yearStartDates = useMemo(
    () => generateDatesForPeriod(startDate, endDate, addYears, startOfYear),
    [startDate, endDate]
  );
  const handleChange = (d: Date) => {
    const i = yearStartDates.findIndex(yearStartDate => isSameDay(startOfYear(d), yearStartDate));
    i >= 0 && setActiveDate(d);
  };
  const handleMobileChange = selectedYear => {
    setActiveDate(new Date(selectedYear, 0, 1));
    closeCalendarModal();
  };
  const disableLeft = selectedDate.getFullYear() <= yearStartDates[0].getFullYear();
  const disableRight =
    selectedDate.getFullYear() >= yearStartDates[yearStartDates.length - 1].getFullYear();
  const onHandleLeft = () => {
    if (!disableLeft) {
      setActiveDate(subYears(selectedDate, 1));
    }
  };
  const onHandleRight = () => {
    if (!disableRight) {
      setActiveDate(addYears(selectedDate, 1));
    }
  };

  if (isMobile) {
    return (
      <>
        {yearStartDates.map((date, index) => {
          const yearName = date.getFullYear();
          const isActive = date.getFullYear() === selectedDate.getFullYear();

          return (
            <YearItem
              key={yearName}
              isActive={isActive}
              onClick={() => handleMobileChange(yearName)}>
              {String(yearName).toUpperCase()}
            </YearItem>
          );
        })}
        <EmptyBlock data-automationid={`empty block`} style={{ height: '200px' }}></EmptyBlock>
      </>
    );
  } else {
    return (
      <NewDatePicker
        activeReport={activeReport}
        timeframe={'year'}
        onHandleLeft={onHandleLeft}
        onHandleRight={onHandleRight}
        disableLeft={disableLeft}
        disableRight={disableRight}
        date={selectedDate}
        lastUpdate={endDate}
        selectedDate={selectedDate}
        startDate={startDate}
        setActiveDate={setActiveDate}
      />
    );
  }
});

export default PbiFilterYears;
