import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { deleteUser, inviteUser, resetUserPassword } from 'actions/userApiActions';
import {
  toggleUserTableRow,
  openModalUserEdit,
  openModalUserDuplicate,
} from 'actions/userUiActions';
import UsersTableRow from './UsersTableRow';
import { Store } from 'store/storeTypes';
import { userByIdSelector } from 'selectors/entitiesSelectors';
import { makeUserScopeStringSelector, makeIsUserRowSelectedSelector } from 'selectors/uiSelectors';

interface OwnProps {
  userId: string;
}

const makeMapStateToProps = () => {
  const userScopeSelector = makeUserScopeStringSelector();
  const isUserRowSelectedSelector = makeIsUserRowSelectedSelector();

  return (state: Store, ownProps: OwnProps) => ({
    userEntry: userByIdSelector(state, ownProps),
    userScope: userScopeSelector(state, ownProps),
    isRowSelected: isUserRowSelectedSelector(state, ownProps),
  });
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleUserTableRow,
      inviteUser,
      resetUserPassword,
      openModalUserEdit,
      openModalUserDuplicate,
      deleteUser,
    },
    dispatch
  );

export type UsersTableRowProps = OwnProps &
  ReturnType<ReturnType<typeof makeMapStateToProps>> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(makeMapStateToProps, mapDispatchToProps)(UsersTableRow);
