import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AccountManagement from './AccountManagement';
import {
  openModalUserCreate,
  changeUserSearchValue,
  setCaptchaIsOpen,
} from 'actions/userUiActions';
import { Store } from 'store/storeTypes';
import { getScopeData } from 'actions/scopeApiActions';
import { getUsers, resetUserPassword } from 'actions/userApiActions';
import { isCatpchaOpenSelector, userIdForCaptchaSelector } from 'reducers/ui/captchaReducer';

const mapStateToProps = (state: Store) => {
  const { isLoadingCustomers, isLoadingSites, isLoadingSystems } = state.api;

  return {
    isLoadingEntities: isLoadingCustomers || isLoadingSites || isLoadingSystems,
    isCaptchaOpen: isCatpchaOpenSelector(state),
    userIdForCaptcha: userIdForCaptchaSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModalUserCreate,
      changeUserSearchValue,
      getUsers,
      getScopeData,
      setCaptchaIsOpen,
      resetUserPassword,
    },
    dispatch
  );

export type AccountManagementProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);
