import React from 'react';
import { SVGProps } from 'react';

// tslint:disable:max-line-length
const FilterArrLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.9355 14.871L9.06458 11L12.9355 7.12903"
        stroke="#354052"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6775 16.6774C20.6775 18.8865 18.8866 20.6774 16.6775 20.6774L5.32265 20.6774C3.11351 20.6774 1.32265 18.8865 1.32265 16.6774L1.32265 5.32256C1.32265 3.11342 3.11351 1.32256 5.32265 1.32256L16.6775 1.32256C18.8866 1.32256 20.6775 3.11342 20.6775 5.32256L20.6775 16.6774Z"
        stroke="#354052"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterArrLeft;
