import styled from 'styled-components/macro';
import Button from './Button';

const ButtonOk = styled(Button).attrs({
  classes: { disabled: 'disabled' },
})`
  color: #fff;
  font-weight: 500;
  background: #109cf1;
  border: none;

  &:hover {
    background: #109cf1;
  }

  &.disabled {
    background: #eee;
  }
`;

export default ButtonOk;
