import { setTimeframe } from 'actions/reportFilterActions';
import { connect } from 'react-redux';
import { screenWidthSelector } from 'reducers/appUserReducer';
import { activeReportSelector } from 'reducers/reports/filterDataReducer';
import { filterStateBySystemGroup } from 'reducers/reports/filterStateReducer';
import { bindActionCreators, Dispatch } from 'redux';

import { Store } from 'store/storeTypes';
import PbiFilterDate from './PbiFilterDate';
type OwnProps = {
  isCustomTimeFrameOpen?: boolean;
  setIsCustomTimeFrameOpen?: (isOpen: boolean) => void;
};
const mapStateToProps = (state: Store) => ({
  filterState: filterStateBySystemGroup(state),
  screenWidth: screenWidthSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type PbiFilterDateProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(PbiFilterDate);
