import PbiFilterMonths from 'components/PbiFilter/PbiFilterMonths';
import PbiFilterMonthsContainer from 'components/PbiFilter/PbiFilterMonthsContainer';
import React from 'react';
import { MobileDatePickerProps, Wrapper } from './MobileDatePicker';

const MobileMonthPicker = (props: MobileDatePickerProps) => {
  const { startDate, endDate, selectedDay, setActiveDate, closeCalendarModal } = props;
  return (
    <Wrapper data-id={'OuterWrapper'}>
      <PbiFilterMonthsContainer closeCalendarModal={closeCalendarModal} isMobile={true} />
    </Wrapper>
  );
};
export default MobileMonthPicker;
