import { useRef, useCallback } from 'react';
import {
  subDays,
  addDays,
  isWithinInterval,
  startOfWeek,
  endOfWeek,
  isSameDay,
  isSameMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
} from 'date-fns';
import { TimeFrame, TimeFrameEnum } from 'models/ReportData';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';

type IsDateInSelectedRangeArgType = {
  selectedDay: Date;
  timeFrame: TimeFrame;
  customTimeframeProperties: CustomTimeFrameProperties;
  endDate?: Date;
  maxKeepAlive?: Date;
  maxKeepAliveUtc?: Date;
};
export function useInterval() {
  const intervalRef = useRef(null);

  const clearExistingInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      console.log('Interval cleared');
      intervalRef.current = null;
    }
  }, []);

  const startInterval = useCallback(
    (request, refreshTime, ...requestFunctions) => {
      const isRealTime = true;
      clearExistingInterval();

      requestFunctions.forEach(fn => fn(request, isRealTime));
      intervalRef.current = setInterval(async () => {
        requestFunctions.forEach(async fn => await fn({ ...request, isSilent: true }, isRealTime));
        console.log('Interval running');
      }, refreshTime * 1000);
      console.log('Interval started');
    },
    [clearExistingInterval]
  );

  return { startInterval, clearExistingInterval };
}

export const isDateInCurrentSelectedRange = ({
  selectedDay,
  timeFrame,
  customTimeframeProperties,
  endDate,
}: IsDateInSelectedRangeArgType): boolean => {
  if (timeFrame === TimeFrameEnum.day) {
    //* in real time range - +- 1 day from today
    const startRealTimeRange = subDays(new Date(), 1);
    const endRealTimeRange = addDays(new Date(), 1);

    return isWithinInterval(selectedDay, { start: startRealTimeRange, end: endRealTimeRange });
  }
  if (timeFrame === TimeFrameEnum.week) {
    const today = new Date();
    const startOfTodayWeek = startOfWeek(today, { weekStartsOn: 1 });
    const endOfTodayWeek = endOfWeek(today, { weekStartsOn: 1 });
    return (
      isWithinInterval(selectedDay, { start: startOfTodayWeek, end: endOfTodayWeek }) &&
      isSameDay(endDate, today)
    );
  }
  if (timeFrame === TimeFrameEnum.month) {
    const today = new Date();
    return isSameMonth(selectedDay, today) && isSameDay(endDate, today);
  }
  if (timeFrame === TimeFrameEnum.quarter) {
    const today = new Date();
    const todayQuarterStartDate = startOfQuarter(today);
    const todayQuarterEndDate = endOfQuarter(today);

    const isInCurrentQuarter = isWithinInterval(selectedDay, {
      start: todayQuarterStartDate,
      end: todayQuarterEndDate,
    });
    return isInCurrentQuarter && isSameDay(endDate, today);
  }
  if (timeFrame === TimeFrameEnum.year) {
    const today = new Date();
    const todayYearStart = startOfYear(today);
    const todayYearEnd = endOfYear(today);
    const isInCurrentYear = isWithinInterval(selectedDay, {
      start: todayYearStart,
      end: todayYearEnd,
    });

    return isInCurrentYear && isSameDay(endDate, today);
  }
  if (timeFrame === TimeFrameEnum.custom) {
    //* if todayDate is equal to customTimeFrameEndDate
    return isSameDay(new Date(), customTimeframeProperties.customTimeFrameEndDate);
  }

  return false;
};
