import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { format, isSameDay } from 'date-fns';

import MuiIconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconMoreVert from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Frequency } from 'models/Scheduler';

import Modal from '../_common/Modal';
import ModalForm from '../_common/ModalForm';
import ModalHeaderGeneric from '../_common/ModalHeader';
import Switch from '../_common/Switch';
import { SchedulerPreviewProps } from './SchedulerPreviewContainer';
import CircularSpinner from '../_common/CircularSpinner';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const ModalHeader = styled<StyledComponentWithAnyType>(ModalHeaderGeneric)`
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 8px;
  }
`;
const IconButton = styled<StyledComponentWithAnyType>(MuiIconButton)`
  padding: 8px;
  color: #fff;
`;
const PreviewBody = styled<StyledComponentWithAnyType>(ModalForm)`
  padding: 50px 24px;
  @media (max-width: 599px) {
    padding: 50px 11px;
  }
`;
const LastSent: StyledComponentWithAnyType = styled<StyledComponentWithAnyType>(Typography).attrs({
  variant: 'subtitle1',
})`
  font-size: 14px;
  color: #7f8fa4;
`;
const Name: StyledComponentWithAnyType = styled<StyledComponentWithAnyType>(Typography).attrs({
  variant: 'h2',
})`
  font-weight: 500;
  font-size: 22px;
  color: #354052;
  margin: 30px 0 13px;
`;
const ReportType: StyledComponentWithAnyType = styled<StyledComponentWithAnyType>(Typography).attrs(
  {
    variant: 'subtitle1',
  }
)`
  font-size: 16px;
  color: #354052;
`;
const Repeat = styled<StyledComponentWithAnyType>(ReportType)`
  max-width: 250px;
`;
const Dates = styled<StyledComponentWithAnyType>(ReportType)`
  span {
    font-weight: 500;
  }
  @media (max-width: 599px) {
    text-align: right;
  }
`;
const Hr = styled.hr`
  height: 1px;
  border: 0;
  background-color: #c5d0de;
  margin: 30px 0;
`;
const NotesHeader: StyledComponentWithAnyType = styled<StyledComponentWithAnyType>(
  Typography
).attrs({
  variant: 'h3',
})`
  color: #354052;
  font-weight: 500;
  font-size: 16px;
  margin: 40px 0 10px;
`;
const NotesBody: StyledComponentWithAnyType = styled<StyledComponentWithAnyType>(Typography).attrs({
  variant: 'body1',
})`
  color: #354052;
  font-size: 14px;
  margin: 0;
`;

const PreviewCircularSpinner = styled<StyledComponentWithAnyType>(CircularSpinner)`
  bottom: 10%;
  right: 15%;
  @media (max-width: 599px) {
    bottom: 35%;
    right: 25%;
  }
`;

const SchedulerPreview = React.memo((props: SchedulerPreviewProps) => {
  const { t } = useTranslation();
  const { scheduler, isSaving } = props;
  const {
    reportId,
    notes,
    subject,
    emailReportId,
    startSendDate,
    endSendDate,
    repeat,
    lastSent,
    isActive,
  } = scheduler;
  const {
    closeModalSchedulerPreview,
    changeSchedulerFormValue,
    openModalSchedulerEdit,
    isOpen,
  } = props;
  const { deleteScheduler, toggleSchedulerSending } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModalSchedulerPreview}
      closeIconColor="#fff"
      maxWidth="sm"
      closeIconPosition="left">
      <ModalHeader>
        <IconButton
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
            openModalSchedulerEdit(reportId!);
          }}>
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
            deleteScheduler(reportId!);
          }}>
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
          }}>
          <IconMoreVert />
        </IconButton>
      </ModalHeader>
      <PreviewBody>
        <LastSent>
          {t('schedulerTable.lastSent')}:{' '}
          {lastSent ? format(new Date(lastSent), 'dd/MM/yyyy') : <b>{t('schedulerTable.never')}</b>}
        </LastSent>
        <Name>{subject}</Name>
        <ReportType>{t('schedulerTable.reportTypes')[emailReportId]}</ReportType>
        <Hr />
        <Grid container>
          <Grid item xs={6}>
            <Repeat>
              {t('schedulerTable.repeatTypes')[repeat.frequency]}{' '}
              {repeat.frequency === Frequency.Weekly &&
                repeat.dayOfWeek &&
                ` ${t('schedulerTable.on')}${' '} ${repeat.dayOfWeek
                  .map(w => t('schedulerTable.weekdays')[w])
                  .join(', ')}`}
            </Repeat>
          </Grid>
          <Grid item xs={6}>
            <Dates>
              {t('schedulerTable.startDate')}:
              {isSameDay(new Date(startSendDate).getTime(), Date.now()) ? (
                <span> {t('schedulerTable.today')}</span>
              ) : (
                <span className="date"> {format(new Date(startSendDate), 'dd/MM/yyyy')}</span>
              )}
            </Dates>
            <Dates>
              {t('schedulerTable.endDate')}:{' '}
              {endSendDate ? (
                <span className="date">{format(new Date(endSendDate), 'dd/MM/yyyy')}</span>
              ) : (
                <b>{t('schedulerTable.never')}</b>
              )}
            </Dates>
          </Grid>
        </Grid>
        {!!notes && (
          <div>
            <NotesHeader>{t('schedulerTable.notes')}</NotesHeader>
            <NotesBody>{notes}</NotesBody>
          </div>
        )}
        <Hr />
        <Switch
          checked={isActive}
          onChange={(e: React.ChangeEvent) => {
            changeSchedulerFormValue({
              field: 'sending',
              value: (e.target as HTMLInputElement).checked,
            });
            e.stopPropagation();
            toggleSchedulerSending(reportId!, (e.target as HTMLInputElement).checked);
          }}
          id="sendings"
          label={t('schedulerTable.sendingReport')}
          labelStyle={{ fontWeight: 500 }}
          disabled={false}
          justify="space-between"
        />
        {isSaving && <PreviewCircularSpinner />}
      </PreviewBody>
    </Modal>
  );
});

export default SchedulerPreview;
