import styled from 'styled-components/macro';

export const PageHeaderUIContainer = styled.div`
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  //height: 71px;

  .spacer {
    margin-left: 50px;
    flex-grow: 1;
  }
  @media (max-width: 850px) {
    .spacer {
      margin-left: 10px;
      text-align: right;
    }
  }
  //@media (max-width: 440px) {
  //  height: 63px;
  //}
`;

export const PageHeaderUISpacer = styled.div.attrs({
  className: 'spacer',
})``;

export default PageHeaderUIContainer;
