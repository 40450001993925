import styled from 'styled-components/macro';

const PageContainer = styled.div.attrs({
  'data-id': 'PageContainer',
})`
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  caret-color: transparent;
  //padding: 0 15px;
`;

export default PageContainer;
