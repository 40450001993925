import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SchedulerForm from './SchedulerForm';
import { Store, MODAL_STATUS } from 'store/storeTypes';
import {
  closeModalScheduler,
  changeSchedulerFormScope,
  changeSchedulerFormValue,
  changeSchedulerFormStartDate,
  changeSchedulerFormStartTime,
  changeSchedulerFormRepeat,
} from 'actions/schedulerUiActions';
import { saveScheduler } from 'actions/schedulerApiActions';
import { schedulerScopeStringSelector } from 'selectors/uiSelectors';

const mapStateToProps = (state: Store) => ({
  scopeStrings: schedulerScopeStringSelector()(state),
  formData: state.ui.schedulerForm,
  isSaving: state.ui.schedulerModal.status === MODAL_STATUS.SAVING,
  reportTypes: state.appUser.reportTypes,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModalScheduler,
      changeSchedulerFormScope,
      changeSchedulerFormValue,
      changeSchedulerFormStartDate,
      changeSchedulerFormStartTime,
      changeSchedulerFormRepeat,
      saveScheduler,
    },
    dispatch
  );

export type SchedulerFormProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerForm);
