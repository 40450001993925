import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MobilePbiFilterCloseIcon } from '../../assets/icons/MobilePbiFilterCloseIcon.svg';
import TimeframeButton from './TimeframeButton';
const ButtonGroup = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.button`
  all: unset;
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding-top: 15px;
  padding-left: 10px;
`;
const MobilePbiFilterTimeFrame = props => {
  const {
    items,
    timeframe,
    closeCalendarModal,
    startDate,
    endDate,
    setTimeframe,
    activeReport,
  } = props;
  // on mobile there should not be custom timeframe till it will be developed
  const filteredItems = items.filter(item => item !== 'Custom');
  return (
    <>
      <ButtonGroup>
        {filteredItems.map(item => {
          return (
            <TimeframeButton
              activeReport={activeReport}
              setTimeframe={setTimeframe}
              timeframe={timeframe}
              startDate={startDate}
              key={item}
              item={item}
            />
          );
        })}
      </ButtonGroup>
      <CloseButton onClick={closeCalendarModal}>
        <MobilePbiFilterCloseIcon />
      </CloseButton>
    </>
  );
};
export default MobilePbiFilterTimeFrame;
