import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { authService } from 'services/AuthProvider';
import { makeApiUrl } from 'actions/_shared/utils';
import apiAction from 'actions/_shared/apiAction';
import { MachineConfigDevice, MachineConfigParam } from 'store/storeTypes';
import { MachineConfigServer, IMachineConfigRequest } from 'models/MachineConfigData';

export const getMachineConfigAC = createAsyncAction(
  'GET_MACHINE_CONFIG_STARTED',
  'GET_MACHINE_CONFIG_SUCCESS',
  'GET_MACHINE_CONFIG_FAILED'
)<IMachineConfigRequest, MachineConfigParam[] | 'no data', Error>();

const converter = (data: MachineConfigServer): MachineConfigParam[] | 'no data' => {
  if (!data) {
    return 'no data';
  }
  return data.params.map(param => {
    return {
      ...param,
      min: !isNaN(param.min as number) ? parseFloat((param.min as unknown) as string) : undefined,
      max: !isNaN(param.max as number) ? parseFloat((param.max as unknown) as string) : undefined,
      value:
        param.value !== '' && !isNaN(param.value as number)
          ? parseFloat(param.value as string)
          : param.value,
    };
  });
};
export const machineConfigRequest = (
  { systemId, day }: IMachineConfigRequest,
  isSilent = false
) => {
  const url = makeApiUrl('/api/system-data/settings');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        params: {
          systemId,
          day,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getMachineConfigAC.failure(error)),
        onStarted: isSilent ? dispatch => {} : dispatch => dispatch(getMachineConfigAC.success([])),
        onSuccess: (data, dispatch) => {
          dispatch(
            getMachineConfigAC.success(
              converter({ ...data, params: data.params.filter(element => element !== null) })
            )
          );
        },
      },
    });
};

export const setMachineConfigDevice = createStandardAction('SET_MACHINE_CONFIG_DEVICE')<
  MachineConfigDevice
>();
