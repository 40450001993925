import React from 'react';
import {
  CartesianGrid,
  DotProps,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { IAvailabilityChartProps } from './AvailabilityChart';
import { formatFloatToPercentage, numberFormatterNew } from 'helpers/conversions';
import { ReactComponent as FlagIcon } from '../../../assets/icons/timeLineFlagIcon.svg';
import { getTicksAvailabilityChart } from 'helpers/overviewUtils';
const MobileChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
type MobileCustomizeAvailabilityChartdDot = {
  value?: number;
  isWeek: boolean;
  isTotalValue: boolean;
  index?: number;
};

export const MobileCustomizeAvailabilityChartdDot = (
  props: DotProps & MobileCustomizeAvailabilityChartdDot
) => {
  const { value, cx: X, cy: Y, index, isWeek, isTotalValue } = props;
  const dots = [0, 4, 8, 12, 16, 20, 23];
  const color = isTotalValue ? '#E6E9ED' : '#208CD7';

  if (isNaN(value)) {
    return <svg viewBox="0 0 1024 1024"></svg>;
  }

  const cx = X || 0;
  const cy = Y || 0;
  //*in case of week view we need to show every dot and in case of day only 7 dots from array above
  if (isWeek || dots.includes(index)) {
    return (
      <svg x={cx - 3} y={cy - 3} width={6} height={6} viewBox="0 0 1024 1024">
        <circle cx="512" cy="512" r="512" stroke="#E6E9ED" fill={color}></circle>
      </svg>
    );
  } else {
    return null;
  }
};
const MobileAvailabilityChart = (props: IAvailabilityChartProps) => {
  const { data, isWeek } = props;
  return (
    <MobileChartContainer data-automationid="Overview-Avalability-by-hour-graph">
      <ResponsiveContainer width="100%" minHeight="206px">
        <LineChart margin={{ top: 30, right: 0, bottom: 0, left: 0 }} data={data}>
          <CartesianGrid color="#C5D0DE" vertical={false} />

          <XAxis
            axisLine={{ stroke: '#C5D0DE' }}
            tick={<CustomizedMobileTickAvailabilityChart data={data} isWeek={isWeek} />}
            tickLine={false}
            padding={{ right: 3, left: 0 }}
          />
          <YAxis
            width={10}
            axisLine={false}
            tickFormatter={value => ''}
            // tick={{ fontSize: 12 }}
            tickLine={false}
          />
          <Line
            dot={<MobileCustomizeAvailabilityChartdDot isWeek={isWeek} isTotalValue={true} />}
            isAnimationActive={false}
            dataKey="totalAvg"
            stroke="#E6E9ED"
          />
          <Line
            isAnimationActive={false}
            dataKey="total"
            stroke="#7F8FA4"
            strokeWidth="1.5px"
            dot={<MobileCustomizeAvailabilityChartdDot isWeek={isWeek} isTotalValue={false} />}>
            <LabelList
              content={<MobileAvailabilityChartLabel isWeek={isWeek} data={data} />}
              dataKey="total"
              position="top"
            />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </MobileChartContainer>
  );
};
export default MobileAvailabilityChart;

const CustomizedMobileTickAvailabilityChart = props => {
  const { x, y, width, index, isWeek, data } = props;
  if (index > 6) {
    return null;
  }
  const { tickXcoordinate, tickText, textXCoordinate } = getTicksAvailabilityChart(
    index,
    width,
    6,
    isWeek
  );

  return (
    <svg>
      <line stroke="#7F8FA4" x1={tickXcoordinate} y1={y} x2={tickXcoordinate} y2={y - 10}></line>
      {tickText === 'flag' ? (
        <FlagIcon x={textXCoordinate + 3} y={y + 5} />
      ) : (
        <text
          fontSize={12}
          x={textXCoordinate}
          y={y + 20}
          fill="#7F8FA4
        ">
          {tickText}
        </text>
      )}
    </svg>
  );
};

const MobileAvailabilityChartLabel = (props: any) => {
  const { index, x, offset, value, isWeek, data } = props;

  if (isWeek) {
    //*timeframe ===  week
    let xForLabelWeek = x - 11;
    if (index === 0) {
      xForLabelWeek = x + 10;
    }
    if (index === 1) {
      xForLabelWeek = x;
    }
    if (index === 2) {
      xForLabelWeek = x;
    }
    return (
      <g>
        <text
          fontSize="12px"
          textAnchor="middle"
          fontWeight="400"
          fill="#7F8FA4"
          x={xForLabelWeek}
          y={14}
          offset={offset}>
          {value !== undefined &&
            formatFloatToPercentage(parseFloat(value as string) || 0, 0) + '%'}
        </text>
      </g>
    );
  } else {
    //*timeframe ===  day we need to show label on 0:00 , 04:00 , 08:00,12:00:16:00,20:00, 24:00
    const isShowLabel = index % 4 === 0 || index === 23;
    let xForLabelDay = x - 11;
    if (index === 0) {
      xForLabelDay = x + 10;
    }
    if (index === 4) {
      xForLabelDay = x + 2;
    }
    if (index === 8 || index === 12 || index === 16) {
      xForLabelDay = x;
    }

    if (isShowLabel) {
      return (
        <g>
          <text
            fontSize="12px"
            textAnchor="middle"
            fontWeight="400"
            fill="#7F8FA4"
            x={xForLabelDay}
            y={14}
            offset={offset}>
            {value !== undefined &&
              formatFloatToPercentage(parseFloat(value as string) || 0, 0) + '%'}
          </text>
        </g>
      );
    } else {
      return null;
    }
  }
};
