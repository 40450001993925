import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setFilterSystemsState } from 'actions/reportFilterActions';
import { Store } from 'store/storeTypes';
import PbiFilterSystemGroup from './PbiFilterSystemGroup';
import { setSystemGroup } from 'actions/userUiActions';
import {
  defaultSystemGroupSelector,
  importedTenantIdSelector,
  tenantNameSelector,
  userSystemGroupSelector,
} from 'reducers/userSettingsReducer';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { activeReportSelector, filterDataSelector } from 'reducers/reports/filterDataReducer';
const mapStateToProps = (state: Store) => {
  return {
    userSystemGroup: userSystemGroupSelector(state),
    defaultSystemGroup: defaultSystemGroupSelector(state),
    selectedSystemGroup: systemGroupSelector(state),
    currentActiveReport: activeReportSelector(state),
    tennantName: tenantNameSelector(state),
    importedTennantId: importedTenantIdSelector(state),
    filterData: filterDataSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setSystemGroup, setFilterSystemsState }, dispatch);

export type PbiFilterSystemGroupProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PbiFilterSystemGroup);
