import styled from 'styled-components/macro';

const Desktop = styled.div`
  flex-grow: 1;
  display: flex;
  @media (max-width: 750px) {
    display: none;
  }
`;

export default Desktop;
