import React from 'react';
import styled from 'styled-components/macro';
import MuiEditIcon from '@material-ui/icons/Edit';
import { Fade, Theme, Tooltip, withStyles } from '@material-ui/core';
import MaskSmall from './MaskSmall';
import MaskBig from './MaskBig';

interface IAvatarContainerProps {
  width: string;
  height: string;
  maskWidth: string;
  maskHeight: string;
  maskPositionTop: string;
  maskPositionLeft: string;
}
const AvatarContainer = styled.div`
  position: relative;
  width: ${(props: IAvatarContainerProps) => props.width};
  height: ${(props: IAvatarContainerProps) => props.height};
  display: inline-block;

  & > svg {
    position: absolute;
    top: ${(props: IAvatarContainerProps) => props.maskPositionTop};
    left: ${(props: IAvatarContainerProps) => props.maskPositionLeft};
    display: block;
    width: ${(props: IAvatarContainerProps) => props.maskWidth};
    height: ${(props: IAvatarContainerProps) => props.maskHeight};
  }

  .hover-mask {
    display: none;
  }

  &:hover .hover-mask {
    display: block;
  }
`;

interface IAvatarImageProps {
  width: string;
  height: string;
  avatarBase64?: null | string | undefined | number | unknown | Record<string, unknown>;
  avatarUrl?: string;
}
const AvatarImage = styled.div`
  width: ${(props: IAvatarImageProps) => props.width};
  height: ${(props: IAvatarImageProps) => props.height};
  background-image: ${(props: IAvatarImageProps) =>
      props.avatarBase64 || props.avatarUrl
        ? props.avatarBase64
          ? `url('data:image;base64,${props.avatarBase64}'),`
          : `url('${props.avatarUrl}'),`
        : ''}
    url('/img/avatar-add.png');
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: center, center;
  overflow: hidden;
  position: relative;

  & > img {
    width: ${(props: IAvatarImageProps) => props.width};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const EditIcon = styled(MuiEditIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #354052;
`;

const HoverMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
`;

const AdminStar = styled.img.attrs({
  src: '/img/admin-star.svg',
  alt: '',
})`
  width: 12.6px;
  position: absolute;
  left: 37px;
  top: 3px;
`;
const RootUserStar = styled.img.attrs({
  src: '/img/rootUser-star.svg',
  alt: '',
})`
  width: 12.6px;
  position: absolute;
  left: 37px;
  top: 3px;
`;
const CustomizedToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#354052',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
    width: '90px',
    textAlign: 'center',
  },
  arrow: {
    color: theme.palette.common.white,
    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
    fontSize: 2,
  },
  tooltipPlacementRight: {
    position: 'absolute',
    bottom: '10px',
  },
}))(Tooltip);
interface IAvatarProps {
  size: 'small' | 'large';
  background?: string;
  avatarBase64?: null | string | undefined | number | unknown | Record<string, unknown>;
  avatarUrl?: string;
  hoverMask?: boolean;
  adminStar?: boolean;
  className?: string;
  rootUserStar?: boolean;
  isMobile?: boolean;
}

const Avatar = (props: IAvatarProps) => {
  const {
    size,
    background = 'white',
    avatarBase64,
    avatarUrl,
    hoverMask,
    adminStar,
    rootUserStar,
    className,
    isMobile,
  } = props;

  const width = size === 'small' ? '46px' : '138px';
  const height = size === 'small' ? '47px' : '146px';
  const maskWidth = size === 'small' ? '56px' : '162px';
  const maskHeight = size === 'small' ? '56px' : '162px';
  const maskPositionTop = size === 'small' ? '-3px' : '-7px';
  const maskPositionLeft = size === 'small' ? '-5px' : '-12px';
  const isTooltip = adminStar || rootUserStar;

  const getToolTipText = (rootUserStar): string => {
    return rootUserStar ? 'Root User' : 'Admin User';
  };
  const tooltipText = isTooltip && getToolTipText(rootUserStar);

  return (
    <CustomizedToolTip
      style={{ background: 'red', position: 'relative' }}
      disableHoverListener={!isTooltip}
      title={tooltipText || ''}
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}>
      <AvatarContainer
        {...{
          width,
          height,
          maskWidth,
          maskHeight,
          maskPositionTop,
          maskPositionLeft,
          className,
        }}>
        <AvatarImage {...{ width, height, avatarBase64, avatarUrl }} />

        {/* Hover mask for edit user form (semitransparent background and edit icon) */}
        {hoverMask && (
          <HoverMask className="hover-mask">
            <EditIcon />
          </HoverMask>
        )}

        {/* Hexagon-shaped mask with borders and shadows */}
        {size === 'small' && <MaskSmall color={background} width={maskWidth} height={maskHeight} />}
        {size === 'large' && <MaskBig color={background} width={maskWidth} height={maskHeight} />}

        {size === 'small' && adminStar && <AdminStar />}
        {size === 'small' && rootUserStar && <RootUserStar />}
      </AvatarContainer>
    </CustomizedToolTip>
  );
};

export default Avatar;
