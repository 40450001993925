import React, { useState } from 'react';
import styled from 'styled-components/macro';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import NavLink from './NavLink';

interface PopupMenuItemValue {
  text: string;
}

interface CommandPopupMenuItem extends PopupMenuItemValue {
  onClick: (event?: React.MouseEvent) => void;
}

function isCommandMenuItem(value: PopupMenuItemValueType): value is CommandPopupMenuItem {
  return typeof (value as CommandPopupMenuItem).onClick === 'function';
}

interface HierarchicalPopupMenuItem extends PopupMenuItemValue {
  children: PopupMenuItemValueType[];
}

function isHierarchicalPopupMenuItem(
  value: PopupMenuItemValueType
): value is HierarchicalPopupMenuItem {
  return Array.isArray((value as HierarchicalPopupMenuItem).children);
}

interface NavigationPopupMenuItem extends PopupMenuItemValue {
  path: string;
}

function isNavigationPopupMenuItem(
  value: PopupMenuItemValueType
): value is NavigationPopupMenuItem {
  return typeof (value as NavigationPopupMenuItem).path === 'string';
}

export type PopupMenuItemValueType =
  | string
  | CommandPopupMenuItem
  | HierarchicalPopupMenuItem
  | NavigationPopupMenuItem
  | 'divider';

interface PopupMenuItemProps {
  item: PopupMenuItemValueType;
  handleClose: () => void;
  style?: Record<string, unknown>;
}

const Divider = styled.div`
  margin: 15px;
  height: 1px;
  border-bottom: 1px solid #c5d0de;
`;

const ChildrenList = styled(List)`
  max-height: 215px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background: #e3e0e0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1baba;
    border-radius: 10px;
  }
`;

const dummyElement = <></>;
type ReactElement = typeof dummyElement;

const PopupMenuItem = (props: PopupMenuItemProps) => {
  const { item, handleClose, style } = props;
  const [open, setOpen] = useState(false);
  if (item === '') return null;

  let inner: string | ReactElement = typeof item === 'string' ? item : item.text;
  if (isHierarchicalPopupMenuItem(item)) {
    inner = (
      <>
        {item.text}
        {open ? <ExpandLess /> : <ExpandMore />}
      </>
    );
  }

  const toggleOpen = () => setOpen(!open);

  const onClick = () => {
    if (isHierarchicalPopupMenuItem(item)) {
      toggleOpen();
    } else {
      if (isCommandMenuItem(item)) {
        item.onClick();
      }
      handleClose();
    }
  };

  const ListItemInstance = (
    <ListItem
      onClick={onClick}
      button
      style={{ justifyContent: 'space-between', ...style }}
      data-automationid={typeof inner === 'string' ? inner : inner.props.children[0]}>
      {inner}
    </ListItem>
  );
  return (
    <>
      {item === 'divider' && <Divider></Divider>}
      {item !== 'divider' &&
        (isNavigationPopupMenuItem(item) ? (
          <NavLink to={item.path}>{ListItemInstance}</NavLink>
        ) : (
          ListItemInstance
        ))}

      {item !== 'divider' && isHierarchicalPopupMenuItem(item) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ChildrenList disablePadding>
            {item.children.map((child, i) => (
              <PopupMenuItem
                item={child}
                key={i}
                handleClose={handleClose}
                style={{ paddingLeft: '2rem' }}
              />
            ))}
          </ChildrenList>
        </Collapse>
      )}
    </>
  );
};

export default PopupMenuItem;
