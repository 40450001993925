import { createReducer, PayloadAC } from 'typesafe-actions';
import { RootAction } from 'actions';
import { ServiceTicketStateBySystem } from 'store/storeTypes';
import { getCustomerDataBySerialIdAC } from 'actions/serviceTicketActions';

const INIT_STATE: ServiceTicketStateBySystem = {
  isLoading: false,
  isLoaded: false,
  error: '',
  id: '',
  name: '',
  importedCustomerId: '',
};

const reducer = createReducer<ServiceTicketStateBySystem, RootAction>(INIT_STATE)
  .handleAction(getCustomerDataBySerialIdAC.request, (state, action) => {
    return { ...state, isLoading: true, isLoaded: false, error: '' };
  })
  .handleAction(getCustomerDataBySerialIdAC.success, (state, action) => {
    const { name, id, importedCustomerId } = action.payload;
    return {
      ...state,
      id: id,
      name: name,
      importedCustomerId: importedCustomerId,
      error: '',
      isLoading: false,
      isLoaded: true,
    };
  })
  .handleAction(getCustomerDataBySerialIdAC.failure, (state, error) => {
    return { ...state, isLoading: false, isLoaded: false, error: 'error' };
  });

export default reducer;
