import { appInsightLogger, checkIsSuperUser } from './../../KonnectAnalytics/konnectAnalytics';
import axios, {
  Method,
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
  AxiosResponse,
} from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { PayloadAC } from 'typesafe-actions';

import { Store } from 'store/storeTypes';
import { authService } from 'services/AuthProvider';
import { makeAuthHeader } from './utils';
import handleErrorAction from './handleErrorAction';
import { sendMetric } from '../../logger/logger';
import config from 'config/config';
export interface ApiRequest {
  url: string | ((state: Store) => string);
  method?: Method;
  data?: object | ((state: Store) => object);
  headers?: any;
  params?: any;
  responseType?: ResponseType;
  credentials?: string;
}

export interface ApiActionLogic {
  onStarted: (dispatch: ThunkDispatch<Store, any, Action>, state: Store, data: any) => void;
  onSuccess: (
    data: any,
    dispatch: ThunkDispatch<Store, any, Action>,
    state: Store,
    status: number
  ) => void;
  onFailed: (error: any, dispatch: ThunkDispatch<Store, any, Action>, state: Store) => void;
}

export interface InterceptorFunc {
  (state: Store): boolean;
}

export interface ApiActionConfig {
  request: ApiRequest;
  logic: ApiActionLogic;
  interceptor?: InterceptorFunc;
}

const pendingRequests = new Map<string, CancelTokenSource>();
const noNeedToCancelEndpointsArray = ['current-state'];
export default function apiAction(conf: ApiActionConfig) {
  const { request, logic, interceptor } = conf;
  return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    let tokenSource: CancelTokenSource | undefined;
    const state = getState();
    const quieryString = generateQuieryString(request.params);
    const params = request.params;
    request.params = undefined;
    request.url = typeof request.url === 'function' ? request.url(state) : request.url;
    const requestId = request.url;
    tokenSource = axios.CancelToken.source();
    const splittedBySlashUrl = requestId.split('/');
    const currentEndPoint = splittedBySlashUrl[splittedBySlashUrl.length - 1];
    // //*to set cookies
    if (currentEndPoint === 'portalUrl') {
      axios.defaults.withCredentials = false;
    } else {
      axios.defaults.withCredentials = true;
    }
    //*to cancel repeated request
    if (!noNeedToCancelEndpointsArray.includes(currentEndPoint)) {
      pendingRequests.get(requestId)?.cancel();
    }
    request.url += quieryString;
    request.data = typeof request.data === 'function' ? request.data(state) : request.data;
    request.method = request.method || 'GET';
    const authHeader = makeAuthHeader(authService);
    const contentTypeHeader = { 'Content-Type': 'application/x-www-form-urlencoded' };
    request.headers = request.headers
      ? { ...authHeader, ...contentTypeHeader, ...request.headers }
      : authHeader;
    //*in case we need to get blob -we use it in system page => dowload exeel report
    if (params && params.responseType) {
      request.responseType = params.responseType;
    }
    if (interceptor && interceptor(state)) return;
    logic.onStarted(dispatch, state, request.data);
    try {
      pendingRequests.set(requestId, tokenSource);
      const startTime = new Date().getTime();

      const response = await axios.request(({
        ...request,
        cancelToken: tokenSource?.token,
      } as unknown) as AxiosRequestConfig);
      sendMetric(requestId.substring(requestId.indexOf('api/') + 4), startTime, params);
      logic.onSuccess(response.data, dispatch, state, response.status);
      sendLogToAppInsight({ request, response, error: null, canceled: false, state });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('request was canceled', request);
        sendLogToAppInsight({ request, error, canceled: true, state });
      } else {
        logic.onFailed(error, dispatch, state);
        dispatch(handleErrorAction(error, `API: ${error.message}`));
        sendLogToAppInsight({ request, error, canceled: false, state });
      }
    } finally {
      tokenSource?.cancel();
    }
  };
}
type LogProps = {
  request: ApiRequest;
  response?: AxiosResponse<any>;
  error?: any;
  canceled: boolean;
  state: Store;
};
const sendLogToAppInsight = (props: LogProps) => {
  const { request, response, error, canceled, state } = props;
  const isSuperUser = authService.idTokenPayload[`${config.AUTH0_CLAIMS_NAMESPACE}is_super_user`];
  //value from server side comes not correct in case of superUser - so we need to fix it on client side  in case of super user the email will be in nickname field;
  const email = isSuperUser ? authService.idTokenPayload.nickname : authService.idTokenPayload.name;
  if (response) {
    appInsightLogger.trackEvent(
      { name: `http-response-success: ${request.url} from: ${email}` },
      {
        type: 'http-response-success',
        payload: response.data,
        endPoint: request.url,
        userEmail: state.appUser.userEmail,
        id: state.appUser.id,
        day: request?.params?.day,
        week: request?.params?.week,
        isSuperUser: checkIsSuperUser(authService),
      }
    );
  }
  if (error) {
    const email = isSuperUser
      ? authService.idTokenPayload.nickname
      : authService.idTokenPayload.name;
    appInsightLogger.trackEvent(
      {
        name: `http-response-${canceled ? 'canceled' : 'failed'}: ${request.url} from: ${email}`,
      },
      {
        type: `http-response-${canceled ? 'canceled' : 'failed'}`,
        payload: error,
        endPoint: request.url,
        userEmail: state.appUser.userEmail,
        id: state.appUser.id,
        day: request?.params?.day,
        week: request?.params?.week,
        isSuperUser: checkIsSuperUser(authService),
      }
    );
  }
};
const generateQuieryString = (params: any | undefined): string => {
  let quieryString = '';
  if (params) {
    quieryString = '?';
    Object.keys(params).forEach((key, index) => {
      if (params[key] !== undefined && params[key] !== null) {
        if (Array.isArray(params[key])) {
          params[key].forEach((param: string | number, i: number) => {
            quieryString += `${key}=${param}`;
            if (i !== params[key].length - 1) {
              quieryString += '&';
            }
          });
        } else if (params[key] instanceof Date) {
          quieryString += `${key}=${(params[key] as Date).toDateString()}`;
        } else if (typeof params[key] === 'object') {
          Object.keys(params[key]).forEach(innerKey => {
            if (Array.isArray(params[key][innerKey])) {
              params[key][innerKey].forEach((el: string | number, index: number) => {
                quieryString += `${key}.${innerKey}=${el}`;
                quieryString += '&';
              });
            } else {
              quieryString += `${key}.${innerKey}=${params[key][innerKey]}`;
              quieryString += '&';
            }
          });
          if (quieryString.charAt(quieryString.length - 1) === '&') {
            quieryString = quieryString.slice(0, quieryString.length - 1);
          }
        } else {
          quieryString += `${key}=${params[key]}`;
        }
        quieryString += '&';
      }
    });
  }
  if (quieryString.charAt(quieryString.length - 1) === '&') {
    quieryString = quieryString.slice(0, quieryString.length - 1);
  }
  return quieryString;
};

export interface ApiActionSimpleConfig {
  request: ApiRequest;
  actions: [any, PayloadAC<any, any>, any];
  interceptor?: InterceptorFunc;
}

export const apiActionSimple = (conf: ApiActionSimpleConfig) => {
  const { request, actions, interceptor } = conf;

  return apiAction({
    request,
    logic: {
      onStarted: (dispatch, state, data = null) => {
        return dispatch(actions[0](data));
      },
      onSuccess: (data, dispatch) => {
        return dispatch(actions[1](data));
      },
      onFailed: (error, dispatch) => {
        return dispatch(actions[2](error));
      },
    },
    interceptor,
  });
};
