import React from 'react';
import styled from 'styled-components/macro';

import MuiModal from '../_common/Modal';
import UserModalLayout from './UserModalLayoutContainer';
import { UserModalProps } from './UserModalContainer';

const Modal = styled(MuiModal)`
  @media (max-width: 599px) {
    .dialog-paper > button svg {
      /* close modal button */
      color: #fff;
    }
  }
`;

const CreateUserModal = React.memo((props: UserModalProps) => {
  const { closeModalUser, isOpen } = props;

  return (
    <Modal isOpen={isOpen} onClose={closeModalUser} disableBackdropClick>
      <UserModalLayout />
    </Modal>
  );
});

export default CreateUserModal;
