import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import * as Flags from 'components/_common/Icons/Flags';
import styled from 'styled-components/macro';

interface IRadioItem {
  value: string | number;
  disabled?: boolean;
  flagIcon: string;
  flagName: string;
}
interface IRadioLangGroupProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  data: IRadioItem[];
  defaultValue?: string | string[];
}

const StyledLabel = styled.div`
  display: flex;
`;

const StyledWrapperLabelIcon = styled.div`
  margin-right: 10px;
  display: flex;
`;

const StyledLabelText = styled.span`
  font-family: Segoe UI, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #323c47;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
  border-bottom: 1px solid #c5d0de;
  padding: 30px 0;
  display: flex;
  .LabelRoot {
    line-height: 1.4;
  }
  &:hover:not(.LabelDisabled) {
    ${StyledLabelText} {
      color: #208cd7;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledRadio = styled(Radio)`
  &.RadioRoot {
    padding: 0;
  }
`;

export const RadioLangGroup = (props: IRadioLangGroupProps) => {
  const { data, value, onChange, defaultValue } = props;
  const renderLabel = (flagIcon: string, flagName: string) => {
    const Icon = Flags[flagIcon];
    return (
      <StyledLabel>
        <StyledWrapperLabelIcon>
          <Icon />
        </StyledWrapperLabelIcon>
        <StyledLabelText>{flagName}</StyledLabelText>
      </StyledLabel>
    );
  };
  return (
    <StyledFormControl>
      <RadioGroup
        aria-label="language"
        name="language"
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}>
        {data.map(item => {
          return (
            <StyledFormControlLabel
              key={item.value}
              value={item.value}
              control={
                <StyledRadio
                  color="primary"
                  classes={{
                    root: 'RadioRoot',
                    disabled: 'RadioDisabled',
                  }}
                />
              }
              classes={{
                label: 'LabelRoot',
                disabled: 'LabelDisabled',
              }}
              label={renderLabel(item.flagIcon, item.flagName)}
              disabled={item.disabled}
              labelPlacement="start"
            />
          );
        })}
      </RadioGroup>
    </StyledFormControl>
  );
};
