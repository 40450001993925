import { create } from 'jss';
import { Store } from 'store/storeTypes';
import { createSelector } from 'reselect';

const selectedSystemGroupSelector = (state: Store) => state.ui.selectedSystemGroup;
const filterDataSelector = (state: Store) => state.reports.filterData;
const filterStateSelector = (state: Store) => state.reports.filterState;
export const filterDataBySystemGroupSelector = createSelector(
  [selectedSystemGroupSelector, filterDataSelector],
  (selectedSystemGroup, filterData) => {
    return filterData[selectedSystemGroup];
  }
);
export const allFilterDataSystemsBySelectedSystemGroupSelector = createSelector(
  [filterDataBySystemGroupSelector],
  data => {
    return data['systems'];
  }
);
export const filterStateBySystemGroupSelector = createSelector(
  [selectedSystemGroupSelector, filterStateSelector],
  (selectedSystemGroup, filterState) => {
    return filterState[selectedSystemGroup];
  }
);
export const filterValueSelector = createSelector(
  [filterStateBySystemGroupSelector],
  filterStateBySystemGroup => {
    return filterStateBySystemGroup.filterValue;
  }
);
//searching in filter pop up
export const filteredSitesSelector = createSelector(
  [filterDataBySystemGroupSelector, filterValueSelector],
  (filterData, filterValue) => {
    return filterData.sites.filter(site =>
      site.name.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
);
export const fitleredModelsSelector = createSelector(
  [filterDataBySystemGroupSelector, filterValueSelector],
  (filterData, filterValue) => {
    return filterData.models.filter(model =>
      model.name.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
);
export const filteredSystemSelector = createSelector(
  [filterDataBySystemGroupSelector, filterValueSelector],
  (filterData, filterValue) => {
    return filterData.systems.filter(system => {
      const nickName = system.systemNickName;
      const isSerialFounded = system.name.toLowerCase().includes(filterValue.toLowerCase());

      const isNickNameFounded =
        nickName && nickName.toLowerCase().includes(filterValue.toLowerCase());
      if (isSerialFounded) {
        return true;
      }
      if (isNickNameFounded) {
        return true;
      }
    });
  }
);
