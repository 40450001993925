import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../actions';
import { Id, ApiStore } from '../store/storeTypes';

// TODO: move all logic to new apiStateReducer

const INIT_STATE: ApiStore = {
  isLoadingAppUser: false,
  isLoadingUsers: false,
  isSavingUser: false,
  isDeletingUser: false,
  isLoadingCustomers: false,
  isLoadingSites: false,
  isLoadingSystems: false,
  isLoadingPbiDefinitions: false,
  isLoadingGoals: false,
  isSavingGoals: false,
  isLoadingSchedulers: false,
  isLoadingSchedulerTypes: false,
  isSavingScheduler: false,
  pendingInvitationUserIds: [],
  errors: [],
};

const {
  loadUsersStarted,
  loadUsersSuccess,
  loadUsersFailed,
  saveUserStarted,
  saveUserSuccess,
  saveUserFailed,
  deleteUserStarted,
  deleteUserSuccess,
  deleteUserFailed,
  inviteUserStarted,
  inviteUserSuccess,
  inviteUserFailed,
} = actions.userApi;

const {
  loadCustomersStarted,
  loadCustomersSuccess,
  loadCustomersFailed,
  loadSitesStarted,
  loadSitesSuccess,
  loadSitesFailed,
  loadSystemsStarted,
  loadSystemsSuccess,
  loadSystemsFailed,
} = actions.scopeApi;

const {
  loadGoalsStarted,
  loadGoalsSuccess,
  saveGoalStarted,
  saveGoalSuccess,
  saveGoalFailed,
} = actions.goalApi;

const {
  loadSchedulersStarted,
  loadSchedulersSuccess,
  loadSchedulersFailed,
  saveSchedulerStarted,
  saveSchedulerSuccess,
  saveSchedulerFailed,
  loadSchedulerTypesStarted,
  loadSchedulerTypesSuccess,
  loadSchedulerTypesFailed,
} = actions.schedulerApi;

export const apiReducer = createReducer<ApiStore, RootAction>(INIT_STATE)
  .handleAction(loadUsersStarted, state => toggleState(state, 'Loading', 'Users', true))
  .handleAction([loadUsersSuccess, loadUsersFailed], (state, action) =>
    toggleState(state, 'Loading', 'Users', false, action.payload)
  )

  .handleAction(saveUserStarted, state => toggleState(state, 'Saving', 'User', true))
  .handleAction([saveUserSuccess, saveUserFailed], (state, action) =>
    toggleState(state, 'Saving', 'User', false, action.payload)
  )

  .handleAction(deleteUserStarted, state => toggleState(state, 'Deleting', 'User', true))
  .handleAction([deleteUserSuccess, deleteUserFailed], (state, action) =>
    toggleState(state, 'Deleting', 'User', false, action.payload)
  )

  .handleAction(inviteUserStarted, (state, action) => addPendingInvitation(state, action.payload))
  .handleAction(inviteUserSuccess, (state, action) => dropPendingInvitation(state, action.payload))
  .handleAction(inviteUserFailed, (state, action) =>
    addError(dropPendingInvitation(state, action.payload.id), action.payload.error)
  )

  .handleAction(loadCustomersStarted, state => toggleState(state, 'Loading', 'Customers', true))
  .handleAction([loadCustomersSuccess, loadCustomersFailed], (state, action) =>
    toggleState(state, 'Loading', 'Customers', false, action.payload)
  )

  .handleAction(loadSitesStarted, state => toggleState(state, 'Loading', 'Sites', true))
  .handleAction([loadSitesSuccess, loadSitesFailed], (state, action) =>
    toggleState(state, 'Loading', 'Sites', false, action.payload)
  )

  .handleAction(loadSystemsStarted, state => toggleState(state, 'Loading', 'Systems', true))
  .handleAction([loadSystemsSuccess, loadSystemsFailed], (state, action) =>
    toggleState(state, 'Loading', 'Systems', false, action.payload)
  )

  .handleAction(loadGoalsStarted, state => toggleState(state, 'Loading', 'Goals', true))
  .handleAction(loadGoalsSuccess, state => toggleState(state, 'Loading', 'Goals', false))

  .handleAction(saveGoalStarted, state => toggleState(state, 'Saving', 'Goals', true))
  .handleAction([saveGoalSuccess, saveGoalFailed], (state, action) =>
    toggleState(state, 'Saving', 'Goals', false, action.payload)
  )

  .handleAction(loadSchedulersStarted, state => toggleState(state, 'Loading', 'Schedulers', true))
  .handleAction([loadSchedulersSuccess, loadSchedulersFailed], (state, action) =>
    toggleState(state, 'Loading', 'Schedulers', false, action.payload)
  )

  .handleAction(loadSchedulerTypesStarted, state =>
    toggleState(state, 'Loading', 'SchedulerTypes', true)
  )
  .handleAction([loadSchedulerTypesSuccess, loadSchedulerTypesFailed], (state, action) =>
    toggleState(state, 'Loading', 'SchedulerTypes', false, action.payload)
  )

  .handleAction(saveSchedulerStarted, state => toggleState(state, 'Saving', 'Scheduler', true))
  .handleAction([saveSchedulerSuccess, saveSchedulerFailed], (state, action) =>
    toggleState(state, 'Saving', 'Scheduler', false, action.payload)
  );

function toggleState(
  state: ApiStore,
  actionName: string,
  apiName: string,
  isInAction: boolean,
  payload?: Error
): ApiStore {
  const newState = {
    ...state,
    [`is${actionName}${apiName}`]: isInAction,
  };
  return payload ? addError(newState, payload) : newState;
}

function addError(state: ApiStore, error: Error): ApiStore {
  const newState = { ...state, errors: [...state.errors, error] };
  return newState;
}

function addPendingInvitation(state: ApiStore, id: Id): ApiStore {
  const { pendingInvitationUserIds } = state;
  return {
    ...state,
    pendingInvitationUserIds: [...pendingInvitationUserIds, id],
  };
}

function dropPendingInvitation(state: ApiStore, id: Id): ApiStore {
  const i = state.pendingInvitationUserIds.indexOf(id);
  if (i < 0) return state;

  const pendingInvitationUserIds = [...state.pendingInvitationUserIds];
  pendingInvitationUserIds.splice(i, 1);
  return { ...state, pendingInvitationUserIds };
}
