import styled from 'styled-components/macro';
import Button from './Button';

const ButtonHeader = styled(Button)`
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  background: #109cf1;
  border: none;
  height: 30px;
  line-height: 30px;
  padding: 0;

  svg {
    margin-right: 6px;
    font-size: 32px;
    height: 30px;
  }

  &:hover {
    background: #109cf1;
  }

  @media (max-width: 440px) {
    width: 110px;
    svg {
      font-size: 20px;
    }
  }
`;

export default ButtonHeader;
