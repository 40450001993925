import * as React from 'react';
import Card from '../Card';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import { ITopCardsProps } from './TopCardsContainer';
import {
  determineUnitToShow,
  formatSecToMinString,
  formatSecToHourString,
} from 'helpers/conversions';
import { useTranslation } from 'react-i18next';

import { chartIcon, dashboardIcon, likeIcon, productionIcon } from '../Icons';
import { TimeFrame } from 'models/ReportData';
import TotalImpressionCardMobile from '../TotatImpressionCardMobile';
import styled from 'styled-components';
import ProductionRateCardMobile from '../ProductionRateCardMobile';
import ProductivityCardMobile from '../ProductivityCardMobile';
import AvailabilityCardMobile from '../AvailabilityCardMobile';
import DesktopTopCards from './DesktopTopCards';

type TextByType = {
  [key in UserSystemGroupStrings]?: Text;
};
export type Text = {
  impression: {
    description: { last: string; weekDay: string };
    statsDescription: { last: string; weekDay: string };
    chart: { day: string; week: string; custom?: string };
  };
  productionRate: {
    description: { last: string; weekDay: string };
    statsDescription: { last: string; weekDay: string };
    upper: string;
    lower: string;
    chart: { day: string; week: string; custom?: string };
  };
  utilization?: {
    chart: { day: string; week: string; custom?: string };
  };
  availability: {
    description: { last: string; weekDay: string };
    statsDescription: { last: string; weekDay: string };
    upper: string;
    lower: string;
    chart: { day: string; week: string; custom?: string };
  };
};
export const text: TextByType = {
  DTF: {
    impression: {
      description: { last: 'Total Production', weekDay: 'Total Production' },
      statsDescription: { last: 'From last week', weekDay: 'From last week' },
      chart: {
        day: 'Total Production',
        week: 'Total Production',
      },
    },
    productionRate: {
      description: { last: 'Current prod. rate', weekDay: 'Average prod. rate' },
      statsDescription: { last: 'From last hour', weekDay: 'From last week' },
      upper: 'Avg. Utilization',
      lower: 'Preparation',
      chart: {
        day: 'Total Production by Hour',
        week: 'Average Production by Day',
      },
    },
    utilization: {
      chart: {
        day: 'Utilization by Hour (%)',
        week: 'Average Utilization by Day (%)',
      },
    },
    availability: {
      description: { last: 'Current Availability', weekDay: 'Average Availability' },
      statsDescription: { last: 'From last hour', weekDay: 'From last week' },
      upper: 'Error Dur.',
      lower: 'Maintenance Dur.',
      chart: {
        day: 'Availability by Hour',
        week: 'Availability by Day',
      },
    },
  },
  DTG: {
    impression: {
      description: { last: 'Total Impressions', weekDay: 'Total Impressions' },
      statsDescription: { last: 'From last week', weekDay: 'From last week' },
      chart: {
        day: 'Total Impressions',
        week: 'Total Impressions',
        custom: 'Total Impressions',
      },
    },
    productionRate: {
      description: { last: 'Current prod. rate', weekDay: 'Average prod. rate' },
      statsDescription: { last: 'From last hour', weekDay: 'From last week' },
      upper: 'Avg. Handling Time',
      lower: 'Dark Impressions',
      chart: {
        day: 'Total Impressions by Hour',
        week: 'Average Prod. Rate by Day',
        custom: 'Average Prod. Rate',
      },
    },
    utilization: {
      chart: {
        day: 'Productivity by Hour',
        week: 'Productivity by Day',
        custom: 'Productivity',
      },
    },
    availability: {
      description: { last: 'Current Availability', weekDay: 'Average Availability' },
      statsDescription: { last: 'From last hour', weekDay: 'From last week' },
      upper: 'Error Dur.',
      lower: 'Maintenance Dur.',
      chart: {
        day: 'Availability by Hour',
        week: 'Availability by Day',
        custom: 'Availability',
      },
    },
  },
  Apollo: {
    impression: {
      description: { last: 'Total Impressions', weekDay: 'Total Impressions' },
      statsDescription: { last: 'From last week', weekDay: 'From last week' },
      chart: {
        day: 'Total Impressions',
        week: 'Total Impressions',
        custom: 'Average Prod. Rate',
      },
    },
    productionRate: {
      description: { last: 'Current prod. rate', weekDay: 'Average prod. rate' },
      statsDescription: { last: 'From last hour', weekDay: 'From last week' },
      upper: 'Avg. Handling Time',
      lower: 'Dark Impressions',
      chart: {
        day: 'Total Impressions by Hour',
        week: 'Average Prod. Rate by Day',
        custom: 'Average Prod. Rate',
      },
    },
    utilization: {
      chart: {
        day: 'Productivity by Hour',
        week: 'Productivity by Day',
        custom: 'Productivity',
      },
    },
    availability: {
      description: { last: 'Current Availability', weekDay: 'Average Availability' },
      statsDescription: { last: 'From last hour', weekDay: 'From last week' },
      upper: 'Error Dur.',
      lower: 'Maintenance Dur.',
      chart: {
        day: 'Availability by Hour',
        week: 'Availability by Day',
        custom: 'Availability',
      },
    },
  },
};

export const OverviewTopCard = styled.div`
  box-sizing: border-box;
  display: flex;

  width: 100%;
  min-height: 130px;

  background: #ffffff;
  border: 1px solid #c5d0de;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;

  margin-bottom: 10px;
  padding: 12px 15px;
`;

const TopCards = (props: ITopCardsProps) => {
  const { t } = useTranslation();
  const {
    timeframe: timeFrame,
    totalProduction,
    productionRateTrend,
    maintenanceDuration,
    darkImpressionsPercentage,
    errorDuration,
    productionTrend,
    productionRate,
    loadingTime,
    availabilityTrend,
    availability,
    selectedSystemGroup,
    darkImpressionsTrend,
    loadingTimeTrend,
    maintenanceDurationTrend,
    errorDurationTrend,
    preparation,
    utilization,
    trendPreparation,
    trendUtilization,
    productivity,
    productivityChange,
    printingTime,
    printingTimeTrend,
    userSettings,
    customTimeframeProperties,
    availabilityTotalDurationReported,
    isNotReprotingPeriod,
    isMobile,
  } = props;
  const changeTextPerPage = (timeframe: TimeFrame | '', text: string) => {
    if (timeframe && timeframe.includes('lastUpdate')) return text.replace('Average', 'Current');
    return text;
  };
  const getTimeframeTextKey = () => {
    return timeFrame && timeFrame.includes('lastUpdate') ? 'last' : 'weekDay';
  };
  const units = determineUnitToShow(selectedSystemGroup, userSettings, t);
  const selectedSystemGroupText = text[selectedSystemGroup]!;

  if (!isMobile) {
    return (
      <DesktopTopCards
        selectedSystemGroup={selectedSystemGroup}
        units={units}
        selectedSystemGroupText={selectedSystemGroupText}
        {...props}
      />
    );
  }

  if (isMobile) {
    return (
      <>
        <OverviewTopCard>
          <TotalImpressionCardMobile
            value={totalProduction}
            units={units}
            icons={productionIcon(selectedSystemGroup)}
            description={selectedSystemGroupText.impression.description[getTimeframeTextKey()]}
            stats={productionTrend}
            statsDescription={
              selectedSystemGroupText.impression.statsDescription[getTimeframeTextKey()]
            }
            cardName="totalProduction"
          />
        </OverviewTopCard>
        <OverviewTopCard>
          <ProductionRateCardMobile
            selectedSystemGroup={selectedSystemGroup}
            value={productionRate}
            units={units + '/h'}
            icons={dashboardIcon}
            description={selectedSystemGroupText.productionRate.description[getTimeframeTextKey()]}
            stats={productionRateTrend}
            statsDescription={
              selectedSystemGroupText.productionRate.statsDescription[getTimeframeTextKey()]
            }
            addedData={{
              upper: {
                unit: selectedSystemGroup === 'DTF' ? '%' : 'sec/' + units,
                value: selectedSystemGroup === 'DTF' ? utilization : loadingTime,
                description: changeTextPerPage(
                  timeFrame,
                  selectedSystemGroupText.productionRate.upper
                ),
                trend: selectedSystemGroup === 'DTF' ? trendUtilization : loadingTimeTrend,
              },
              lower: {
                value: selectedSystemGroup === 'DTF' ? preparation : darkImpressionsPercentage,
                description: selectedSystemGroupText.productionRate.lower,
                unit: '%',
                trend: selectedSystemGroup === 'DTF' ? trendPreparation : darkImpressionsTrend,
              },
            }}
            cardName="averageProductionRate"
          />
        </OverviewTopCard>
        {(selectedSystemGroup === UserSystemGroup.DTG ||
          selectedSystemGroup === UserSystemGroup.Apollo) && (
          <OverviewTopCard>
            <ProductivityCardMobile
              selectedSystemGroup={selectedSystemGroup}
              value={productivity}
              units={units + '/h'}
              icons={chartIcon}
              description={'Productivity'}
              stats={productivityChange}
              statsDescription={
                selectedSystemGroupText.productionRate.statsDescription[getTimeframeTextKey()]
              }
              addedData={{
                upper: {
                  unit: 'min',
                  value: printingTime,
                  description: 'Avg. Print Time',
                  trend: printingTimeTrend,
                  formatter: formatSecToMinString,
                },
              }}
              cardName="productivity"
            />
          </OverviewTopCard>
        )}
        <OverviewTopCard>
          <AvailabilityCardMobile
            cardName="availability"
            value={availability}
            units={'%'}
            icons={likeIcon}
            isPercentage={true}
            description={selectedSystemGroupText.availability.description[getTimeframeTextKey()]}
            stats={availabilityTrend}
            statsDescription={
              selectedSystemGroupText.availability.statsDescription[getTimeframeTextKey()]
            }
            addedData={{
              upper: {
                unit: '',
                value: errorDuration,
                description: selectedSystemGroupText.availability.upper,
                trend: errorDurationTrend,
                formatter: formatSecToHourString,
              },
              lower: {
                value: maintenanceDuration,
                unit: '',
                description: selectedSystemGroupText.availability.lower,
                trend: maintenanceDurationTrend,
                formatter: formatSecToHourString,
              },
            }}
          />
        </OverviewTopCard>
      </>
    );
  }
};
export default React.memo(TopCards);
