import * as React from 'react';
import styled from 'styled-components';
import { UnitDiv, DescriptionDiv, HrHorizontal, Spinner, ValueDiv } from './Styles';
import { ReactComponent as ArrowUp } from '../../assets/icons/ArrowUp.svg';
import { notUndefiend, valueAsString } from 'helpers/conversions';
import { appearanceEffect, NoDataIconWrapper } from 'globalStyle';
import { uniqIdentificatorsObject } from 'helpers/AutomationTest/overwievIds';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import EmptyStateIcon from 'components/_common/EmptyState';

const Stats = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14.22px;
  margin-top: 8px 0px 17px 0px;
  @media only screen and (max-width: 430px) {
    font-size: 10px;
    line-height: 12.22px;
    margin-top: 6px 0px 15px 0px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 15px;
    line-height: 15px;
  }
`;

const AddedDataAmount = styled.div`
  font-size: 21px;
  line-height: 20.44px;
  font-weight: 300;
  margin-right: 9px;
  @media only screen and (max-width: 430px) {
    font-size: 18px;
    line-height: 19px;
    font-weight: 300;
    margin-right: 6px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 24px;
    line-height: 24px;
  }
`;
const AddedDataType = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7f8fa4;
  @media only screen and (max-width: 430px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #7f8fa4;
  }
  @media screen and (min-width: 1920px) {
    font-size: 17px;
    line-height: 17px;
  }
`;
const AddedDataUnit = styled.div`
  font-size: 16px;
  line-height: 18.96px;
  font-weight: 300;

  @media screen and (min-width: 1920px) {
    font-size: 19px;
    line-height: 19px;
  }
`;
export const StatsData = (percentage: number) => {
  const color = percentage >= 0 ? '#1BB934' : '#FF4081';
  const sign = percentage > 0 ? '+' : '';
  return (
    <div style={{ color: color, marginRight: '5px' }}>
      {sign}
      {percentage && (percentage * 100).toFixed(2)}%
    </div>
  );
};
type Data = {
  value: number | Date | undefined;
  unit?: string;
  description: string;
  trend: number | undefined;
  formatter?: (value: number | string | undefined | Record<string, unknown> | Date) => string;
};
export interface ICardProps {
  value: number | undefined;
  units: string;
  description: string;
  icons: { positive: React.ReactElement; negative?: React.ReactElement };
  stats?: number | undefined;
  statsDescription?: string;
  addedData?: {
    upper: Data;
    lower?: Data;
  };
  isPercentage?: boolean;
  cardName?: string;
  showOnlyBody?: boolean;
  width?: number;
  selectedSystemGroup?: UserSystemGroupStrings;
  isHidden?: boolean;
}

const AddedData = (
  value: string,
  description: string,
  trend: number | undefined,
  unit?: string,
  cardName?: string,
  upOrBottom?: string,
  upOrBottomArrow?: string
) => {
  return (
    <div style={{ minWidth: '105px' }}>
      <div
        style={{
          display: 'flex',
          marginBottom: '3px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{ display: 'flex' }}>
          <AddedDataAmount
            data-automation={cardName && uniqIdentificatorsObject[cardName][upOrBottom]}>
            {value}
          </AddedDataAmount>
          {unit && <AddedDataUnit>{unit}</AddedDataUnit>}
        </div>
        {trend !== 0 && trend !== undefined && (
          <ArrowUp
            data-automation={cardName && uniqIdentificatorsObject[cardName][upOrBottomArrow]}
            width={14}
            height={18}
            style={{ transform: trend < 0 ? 'rotate(180deg)' : '' }}
          />
        )}
      </div>
      <AddedDataType>{description}</AddedDataType>
    </div>
  );
};
const InnerCardWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const UpperRow = styled.div``;
const BottomRow = styled.div``;
const RightColumn = styled.div``;
const CardDiv = styled.div<{
  width: number;
  bigger?: boolean;
  height?: number;
  align?: boolean;
  isMobile?: boolean;
  selectedSystemGroup?: string;
  order?: number;
  isHidden?: boolean;
}>`
  background: ${props => (props.isHidden ? 'transparent' : '#ffffff')};
  border: ${props => (props.isHidden ? 'none' : '1px solid #c5d0de')};
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: ${props => props.width}%;

  align-items: ${props => (props.align === undefined ? 'center' : '')};
  margin-bottom: ${props => (props.isMobile === true ? '10px' : '')};
`;
export default function Card(props: ICardProps) {
  const {
    value,
    units,
    description,
    icons,
    stats,
    statsDescription,
    addedData,
    isPercentage,
    cardName,
    showOnlyBody,
    width,
    selectedSystemGroup,
    isHidden,
  } = props;

  const [isNegative, setIsNegative] = React.useState(false);
  React.useEffect(() => {
    if (notUndefiend([stats])) {
      if (stats! < 0) {
        setIsNegative(true);
      } else if (description === 'Average Availability' && value >= 0.999) {
        setIsNegative(false);
      } else {
        setIsNegative(false);
      }
    }
  }, [stats]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardDiv width={width} isHidden={isHidden}>
      {selectedSystemGroup === UserSystemGroup.Apollo && cardName === 'availability' ? (
        <NoDataIconWrapper>
          <EmptyStateIcon icon="no-icon" message="Under Construction" />
        </NoDataIconWrapper>
      ) : notUndefiend([value]) ? (
        <InnerCardWrapper data-automationid="top-card-overview">
          <LeftColumn>
            <UpperRow>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                }}>
                {isNegative ? icons.negative : icons.positive}
                <div style={{ display: 'flex', alignItems: 'baseline', marginLeft: '8px' }}>
                  <ValueDiv
                    data-automationid={cardName && uniqIdentificatorsObject[cardName]!.totalField}>
                    {valueAsString(value, isPercentage)}
                  </ValueDiv>
                  <UnitDiv>{units}</UnitDiv>
                </div>
              </div>
              <DescriptionDiv>{description}</DescriptionDiv>
            </UpperRow>

            {notUndefiend([stats]) && !showOnlyBody && (
              <BottomRow>
                <HrHorizontal />
                <Stats
                  data-automationid={
                    cardName && uniqIdentificatorsObject[cardName]!.fromLastWeekField
                  }>
                  {StatsData(stats!)}
                  <div>{statsDescription}</div>
                </Stats>
              </BottomRow>
            )}
          </LeftColumn>
          {!!addedData && selectedSystemGroup !== UserSystemGroup.Apollo && (
            <RightColumn>
              {AddedData(
                addedData.upper.formatter
                  ? addedData.upper.formatter(addedData.upper.value)
                  : valueAsString(addedData.upper.value!),
                addedData.upper.description,
                addedData.upper.trend,
                addedData.upper.unit,
                cardName,
                'upperValue',
                'upperArrow'
              )}
              <HrHorizontal />
              {addedData.lower &&
                AddedData(
                  addedData.lower.formatter
                    ? addedData.lower.formatter(addedData.lower.value)
                    : valueAsString(addedData.lower.value!),
                  addedData.lower.description,
                  addedData.lower.trend,
                  addedData.lower.unit,
                  cardName,
                  'bottomValue',
                  'bottomArrow'
                )}
            </RightColumn>
          )}
        </InnerCardWrapper>
      ) : (
        Spinner(30)
      )}
    </CardDiv>
  );
}
