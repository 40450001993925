import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Overview/Styles';
import { buttonOnHoverColor, StyledTooltip } from 'globalStyle';
import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { TimeFrame } from 'models/ReportData';
import {
  inkConsuptionPerJobStatuses,
  UserSystemGroup,
  UserSystemGroupStrings,
} from 'store/storeTypes';
import { ReactComponent as DownloadIncConsupmtionPerJobIcon } from '../../assets/icons/DownloadIncConsupmtionPerJobIcon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/WarningIcon.svg';
import { getRequestForTimeFrame } from 'helpers/date';
import { isShowInkConsumptionButtonForDtf } from 'helpers/jobAnalysisUtils';
import SpinnerComponent from 'components/_common/Spinner';
const Button = styled.button<{ isDisabled: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 150px;
  height: 36px;
  box-sizing: border-box;
  padding: 0 10px;
  background-color: white;
  border: ${props => (props.isDisabled ? '1px solid #C5D0DE' : '1px solid #2ea1f8')};
  color: ${props => (props.isDisabled ? '#C5D0DE' : '#2ea1f8')};

  border-radius: 4px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  transition-duration: 0.4s;
  &:focus {
    background: ${buttonOnHoverColor};
  }
  &:hover {
    background: ${buttonOnHoverColor};
  }
  &:active {
    color: ${props => (props.isDisabled ? '#C5D0DE' : '#1878ba')};
    border: ${props => (props.isDisabled ? '1px solid #C5D0DE' : '1px solid #1878ba')};
  }
  svg {
    fill: ${props => (props.isDisabled ? '#C5D0DE' : '#2ea1f8')};
  }
`;
const Div = styled.div`
  margin-left: 10px;
  width: 120px;
`;
const MessageDiv = styled.div`
  padding: 0 5px;
  width: 161px;
  height: 22px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: rgba(255, 171, 43, 0.2);
  font-size: 12px;

  color: #354052;
`;

const IconPlaceHolder = styled.div`
  height: 17px;
  width: 17px;
  margin-right: 5px;
`;
type InkConsuptionPerJobButtonProps = {
  jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage: string;
  jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading: boolean;
  selectedSystemGroup: UserSystemGroupStrings;
  timeframe: TimeFrame;
  selectedDateByGroup: Date;
  selectedSystemSerials: Array<string>;
  downloadInkConsumptionPerJobError: string;
  isDownloadInkConsumptionPerJobLoading: boolean;
  isDownloadInkConsumptionPerJobLoaded: boolean;
  allUserSystemsCount: number;
  customTimeframeProperties: any;
  selectedSerialsIds: string[];
  selectedDate: Date;
  downloadInkConsumptionPerJobReport: Function;
};

const InkConsuptionPerJobButton = (props: InkConsuptionPerJobButtonProps) => {
  const {
    downloadInkConsumptionPerJobReport,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading,
    isDownloadInkConsumptionPerJobLoading,
    allUserSystemsCount,
    selectedSystemGroup,
    customTimeframeProperties,
    selectedSerialsIds,
    selectedDate,
    timeframe,
  } = props;
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);
  const [tooltipMessage, setTooltipMessage] = useState('Checking data...');
  const isShowMessageIcon =
    tooltipMessage === inkConsuptionPerJobStatuses.tooMuchData ||
    tooltipMessage === inkConsuptionPerJobStatuses.noData;

  useEffect(() => {
    //for dtf case we got additional logic - start date should 31 of January 2024
    const isToShowButton = isShowInkConsumptionButtonForDtf(timeframe, selectedDate);
    if (selectedSystemGroup === UserSystemGroup.DTF && !isToShowButton) {
      setIsDisabled(true);
      setTooltipMessage('The data available starting from January 31, 2024');
    } else {
      if (
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage ===
          inkConsuptionPerJobStatuses.initialState ||
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage ===
          inkConsuptionPerJobStatuses.tooMuchData ||
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage ===
          inkConsuptionPerJobStatuses.noData ||
        isDownloadInkConsumptionPerJobLoading ||
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading
      ) {
        setIsDisabled(true);
        setTooltipMessage(
          jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage || 'Loading...'
        );
      }
      if (
        jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage ===
          inkConsuptionPerJobStatuses.validToDownload &&
        !jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading &&
        !isDownloadInkConsumptionPerJobLoading
      ) {
        setIsDisabled(false);

        setTooltipMessage(
          jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage || 'Loading...'
        );
      }
    }
  }, [
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobMessage,
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading,
    isDownloadInkConsumptionPerJobLoading,
    selectedDate,
    selectedSystemGroup,
  ]);
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    if (
      jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading ||
      jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading ||
      isDisabled
    ) {
      return;
    }
    //*initial Http requst
    const filterByPage = getRequestForTimeFrame(selectedDate, timeframe, customTimeframeProperties);
    if (selectedSerialsIds) {
      filterByPage['systems'] =
        selectedSerialsIds.length === allUserSystemsCount ? [] : selectedSerialsIds;
    }
    filterByPage['SystemGroupType'] = selectedSystemGroup;
    downloadInkConsumptionPerJobReport(filterByPage);
    //*send analytics
    KonnectAnalytics.clickInkConsupmtionPerJob(timeframe);
  };
  const isBtnLoading =
    jobAnalysisBySystemGroupIsValidToDownloadInkConsuptionPerJobIsLoading ||
    isDownloadInkConsumptionPerJobLoading;
  return (
    <>
      <StyledTooltip
        arrow
        title={isBtnLoading ? t('inkConsumptionPerJob.loading') : tooltipMessage}>
        <Button tabIndex={0} isDisabled={isDisabled} onClick={handleButtonClick}>
          {isBtnLoading ? <SpinnerComponent size={15} /> : <DownloadIncConsupmtionPerJobIcon />}
          <Div>Data Per Job</Div>

          {isShowMessageIcon && (
            <MessageDiv>
              <IconPlaceHolder>
                <WarningIcon />
              </IconPlaceHolder>
              {tooltipMessage === inkConsuptionPerJobStatuses.tooMuchData
                ? t('inkConsumptionPerJob.tooMuchDataShortMsg')
                : t('inkConsumptionPerJob.noData')}
            </MessageDiv>
          )}
        </Button>
      </StyledTooltip>
    </>
  );
};
export default InkConsuptionPerJobButton;
