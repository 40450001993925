import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyStateTankIcon } from '../../assets/icons/EmptyStateTankIcon.svg';
import { ReactComponent as EmptyStateEventIcon } from '../../assets/icons/EmptyStateEventIcon.svg';
import { ReactComponent as NothingWasSelected } from '../../assets/icons/NothingWasSelected.svg';
import { ReactComponent as EmptyState } from '../../assets/icons/EmptyState.svg';
import { ReactComponent as PrinterNotReportedIcon } from '../../assets/icons/printer-not-reported-icon.svg';

import { appearanceEffect } from 'globalStyle';
type IconType = 'tank' | 'event' | 'list' | 'empty-state' | 'no-icon' | 'printer-not-reported';
export const EmptyStateWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
  max-height: 100px;
`;
const IconPlaceHolder = styled.div``;
const MessagePlaceHolder = styled.div<{ maxHeight?: number }>`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: ${props => (props.maxHeight ? 400 : 500)};
  font-size: ${props => (props.maxHeight ? '14px' : '16px')};
  color: #93c4e7;
`;
const DescriptionPlaceHolder = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
type EmptyStateProps = {
  message?: string;
  icon: IconType;
  description?: string;
  maxHeight?: number;
};
const EmptyStateIcon = (props: EmptyStateProps) => {
  const { message = 'no data for this period', description, icon, maxHeight } = props;
  const icons = [
    { iconName: 'tank', icon: <EmptyStateTankIcon /> },
    {
      iconName: 'event',
      icon: <EmptyStateEventIcon />,
    },
    { iconName: 'list', icon: <NothingWasSelected /> },
    { iconName: 'empty-state', icon: <EmptyState /> },
    { iconName: 'no-icon', icon: <></> },
    { iconName: 'printer-not-reported', icon: <PrinterNotReportedIcon /> },
  ];
  const currentIcon = icons.find(elem => elem.iconName === icon).icon;
  return (
    <InnerWrapper>
      <IconPlaceHolder>{currentIcon}</IconPlaceHolder>
      <MessagePlaceHolder maxHeight={maxHeight}>{message}</MessagePlaceHolder>
      <DescriptionPlaceHolder>{description}</DescriptionPlaceHolder>
    </InnerWrapper>
  );
};
export default EmptyStateIcon;
