import { createBrowserHistory } from 'history';
import { MainReportTypes, ReportTypesNew, SubReportTypes } from 'models/ReportData';
const browserHistory = createBrowserHistory();

export default browserHistory;

export function updateUriQueryAndPushHistory(
  setParams: { [k: string]: string | undefined },
  activeReport?: ReportTypesNew
) {
  const query = getQueryParams();

  Object.keys(setParams).forEach(name => {
    if (setParams[name]) {
      query.set(name, setParams[name] || '');
    }
  });

  const oldSearch = browserHistory.location.search.replace(/^\?/, '');
  const newSearch = query.toString();
  const newUri = `${browserHistory.location.pathname}?${newSearch}`;

  if (
    oldSearch &&
    oldSearch !== newSearch &&
    activeReport !== MainReportTypes.Jobs &&
    activeReport !== SubReportTypes.SystemPage &&
    activeReport !== SubReportTypes.MachineConfig
  ) {
    //*doesn't add new element to history object
    browserHistory.push(newUri);
  } else {
    browserHistory.replace(newUri);
  }
}

export function getQueryParams(search: string = browserHistory.location.search) {
  return new URLSearchParams(search);
}

export const openLinkInNewTab = (link: string) => {
  window.open(link, '_blank');
};
