import { TreeNodeProps } from 'react-dropdown-tree-select';
import { OptionsObject } from 'notistack';
import { ExtendedUserData } from 'models/ExtendedUserData';
import { CustomerData } from 'models/CustomerData';
import { SiteData } from 'models/SiteData';
import { SystemData } from 'models/SystemData';
import { TimeFrame, ReportTypesNew } from 'models/ReportData';
import { GoalsData } from 'models/GoalsData';
import { Scheduler, Frequency, Weekdays } from 'models/Scheduler';
import { SetFilterFromUriQuery } from 'actions/reportFilterActions';
import { ISystemReport } from 'models/SystemAnalysisData';
import { OverviewUI } from 'reducers/ui/overviewUiReducer';
import {
  ColorCompareChart,
  ColorConsumptionChart,
  ColorRecordBasic,
  InkConsumptionChart,
  JobAnalysisChartCard,
} from 'models/JobAnalysisData';
import { ReportDateRequest } from 'models/ReportsFilterData';

//
// ------ Entities ------

export interface Entries<T> {
  [key: string]: T & unknown;
}

export type Id = string;
export type Ids = Id[];

export interface Entity<T> {
  byId: Entries<T> & Record<string, any>;
  allIds: Ids;
}
export type GoalsByTypes = {
  [key in UserSystemGroupStrings]?: Entity<GoalsData>;
};
export interface EntityStore {
  users: Entity<ExtendedUserData>;
  customers: Entity<CustomerData>;
  sites: Entity<SiteData>;
  systems: Entity<SystemData>;
  goals: GoalsByTypes;
  schedulers: Entity<Scheduler>;
}

//
// ------ UI ------
export interface Notification {
  message: string;
  key: string;
  options?: OptionsObject;
}

export enum MODAL_STATUS {
  CLOSED = 'CLOSED',
  EDITING = 'EDITING',
  SAVING = 'SAVING',
  DONE = 'DONE',
}

export interface UserForm {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  customerIds: Ids;
  siteIds: Ids;
  systemIds: Ids;
  systemsTree: TreeNodeProps[];
  setGoals: boolean;
  createReports: boolean;
  adminPermissions: boolean;
  avatarUrl?: string;
  avatarBase64?: unknown | Record<string, unknown>;
  //avatarBase64?: WindowsBase64;
  isFormTouched: boolean;
  isRootUser: boolean;
  manageApi: boolean;
}

export interface SchedulerForm {
  reportId: Id;
  emailSubject: string;
  report: string | number | null;
  startDate: Date;
  repeat: Frequency | null;
  weekdays: Weekdays[];
  endDate?: Date;
  notes: string;
  sending: boolean;
  customerIds: Ids;
  siteIds: Ids;
  systemIds: Ids;
  userOrgs?: Entity<CustomerData>;
  userSites?: Entity<SiteData>;
  userSystems?: Entity<SystemData>;
  disabledOrgIds?: Ids;
  disabledSiteIds?: Ids;
  systemsTree: TreeNodeProps[];
  isFormTouched: boolean;
  lastSent?: Date;
}

export interface UserModal {
  status: MODAL_STATUS;
  lastSavedUserId: Id;
  isInvitationSent: boolean;
}

export interface SchedulerModal {
  status: MODAL_STATUS;
  previewStatus: MODAL_STATUS;
}

export interface FilterCalendarModal {
  status: MODAL_STATUS;
  previewStatus: MODAL_STATUS;
  selectedFilter: string;
}
export interface MachineFilter {
  isOpen: boolean;
}
export type SystemAnalysisViews = 'grid' | 'table';
export interface UiStore {
  notificationQueue: Notification[];
  selectedUserRows: string[];
  userModal: UserModal;
  userForm: UserForm;
  userSearch: string;
  schedulerModal: SchedulerModal;
  schedulerForm: SchedulerForm;
  logoutTimer: number;
  toggleSchedulerId: string;
  schedulerPreview: Scheduler;
  filterCalenderModal: FilterCalendarModal;
  selectedSystemGroup: UserSystemGroupStrings;
  systemAnalysisView: SystemAnalysisViews;
  overview: OverviewUI;
  machineFilter: MachineFilter;
}

//
// ------ API ------

export interface ApiStore {
  isLoadingAppUser: boolean;
  isLoadingUsers: boolean;
  isSavingUser: boolean;
  isSavingGoals: boolean;
  isDeletingUser: boolean;
  isLoadingCustomers: boolean;
  isLoadingSites: boolean;
  isLoadingSystems: boolean;
  isLoadingPbiDefinitions: boolean;
  isLoadingGoals: boolean;
  isLoadingSchedulers: boolean;
  isLoadingSchedulerTypes: boolean;
  isSavingScheduler: boolean;
  pendingInvitationUserIds: string[];
  errors: unknown[];
}

export interface ApiEndpointState {
  isInProgress: boolean;
  isDone: boolean;
  loadAttempts: number;
  reachedMaxLoadAttempts: boolean;
  dTGLastUpdate?: Date | undefined;
  apolloLastUpdate?: Date | undefined;
  dTFLastUpdate?: Date | undefined;
  isSiteExtensionIsLoaded?: any;
}
export interface ApiState {
  isSiteExtensionIsLoaded?: any;
  reportsFilterData: ApiEndpointState;
  reportsCurrentState: ApiEndpointState;
}

//
// ------ App User -------
export interface AppUser extends Partial<ExtendedUserData> {
  systemsTree?: TreeNodeProps[];
  userAgreementVersion?: string;
  userAgreement?: boolean;
  apiUrl?: string;
  reportTypes: string[];
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  screenWidth?: number;
  screenHeight?: number;
  pbiReportHeight?: number;
  pbiReportWidth?: number;
}

export interface ReportSite {
  id: string;
  name: string;
}

export interface ReportModel {
  id: string;
  name: string;
  siteId: string;
}

export interface ReportSystem {
  isEverReported?: boolean | null;
  id: string;
  name: string;
  siteId: string;
  modelId: string;
  systemNickName: string;
}

export type ReportFilterDataByType = {
  [key in UserSystemGroupStrings]?: ReportFilterData;
};

export type ReportFilterStateByType = {
  [key in UserSystemGroupStrings]?: ReportFilterState;
};

export type ReportsFilterDataByTypePartial = {
  [key in UserSystemGroupStrings]?: Partial<ReportFilterData>;
};

export interface ReportFilterData {
  sites: ReportSite[];
  models: ReportModel[];
  systems: ReportSystem[];
  startDate: Date;
  endDate: Date;
  activeSystems: string;
}

export interface ReportFilterState {
  selectedSiteIds: string[];
  selectedModelIds: string[];
  selectedSystemSerials: string[];
  forceUncheckSiteIds: string[];
  forceUncheckModelIds: string[];
  foldedSiteIds: string[];
  foldedModelIds: string[];
  timeframe: TimeFrame;
  customTimeFrameStartDate: Date | undefined;
  customTimeFrameEndDate: Date | undefined;
  customTimeFrameStartHour: number | undefined;
  customTimeFrameEndHour: number | undefined;
  isShiftModeSelected: boolean;
  selectedSiteIdsStringified?: string;
  selectedModelIdsStringified?: string;
  selectedSystemSerialsStringified?: string;
  stateInitialized: boolean;
  isApplyMobileFilter: boolean;
  selectedDate: Date | undefined;
  defaultQueriesFilter: SetFilterFromUriQuery | null;
  filterValue: string;
  isSelectedSystemsEverReported: boolean | null;
  isTimeFrameWasChangedFromJobAnalysisPage: boolean;
}

export interface ReportStore {
  activeReport: ReportTypesNew;
  filterData: ReportFilterDataByType;
  filterState: ReportFilterStateByType;
}

export type systemReportByType = {
  [key in UserSystemGroupStrings]: ISystemReport[];
};
export type NickNameRecord = {
  nickName: string | null;
  serialNumber: string;
};
export type IsEverReportedSystemType = {
  isEverReported: boolean;
  serialNumber: string;
};
export interface ISystemAnalysisState {
  isLoading: boolean;
  data: systemReportByType;
  selectedSystemName: string;
  sortingOrder: 'asc' | 'desc';
  sortBy: string;
  view?: string;
  changingNameInputValue?: string;
  changeNickNameError?: string;
  isChangeNameLoaded?: boolean;
  isChangeNameLoading?: boolean;
  isNickNamesLoading: boolean;
  isNickNamesLoaded: boolean;
  isNickNamesLoadingError: string;
  nickNames: Array<NickNameRecord>;
  newNickName?: string;
  downloadReportValidation: {
    [key in NewSystemGroupsStrings]: {
      isDownloadReportValidationLoading: boolean;
      isDownloadReportValidationLoaded: boolean;
      isDownloadReportValidationLoadingError: string;
      isValidToDownload: boolean;
      isFileDownloading: boolean;
      isFileDownloaded: boolean;
      fileDownloadError: string;
    };
  };
}
export type TimeModificator = 'AM' | 'PM';
export enum TimeModidficatorEnum {
  AM = 'AM',
  PM = 'PM',
}
export type Granularity = 'Day' | 'Week' | 'Hour' | 'Month';
export enum GranularityEnum {
  Day = 'Day',
  Week = 'Week',
  Hour = 'Hour',
  Month = 'Month',
}
export type ChartRecord = {
  date: string;
  total?: number;
  totalAvg: number;
  granularity: Granularity;
};
export type OverviewByType = {
  [key in UserSystemGroupStrings]?: Overview;
};
export type OverviewCards = {
  totalProduction: number | undefined;
  productionTrend: number | undefined;
  productionRate: number | undefined;
  productionRateTrend: number | undefined;
  loadingTime: number | undefined;
  loadingTimeTrend: number | undefined;
  trendPreparation: number | undefined;
  trendUtilization: number | undefined;
  utilization: number | undefined;
  preparation: number | undefined;
  darkImpressionsPercentage: number | undefined;
  darkImpressionsTrend: number | undefined;
  productivity: number | undefined;
  productivityChange: number | undefined;
  printingTime: number | undefined;
  printingTimeTrend: number | undefined;
  productivityUtilization: number | undefined;
  productivityUtilizationTrend: number | undefined;
  errorDuration: number | undefined;
  errorDurationTrend: number | undefined;
  maintenanceDuration: number | undefined;
  maintenanceDurationTrend: number | undefined;
  availability: number | undefined;
  availabilityTrend: number | undefined;
  averageLoadingTime: number | undefined;
  // next two fields are only for apollo
  isNotReportingPeriod?: boolean | undefined;
  availabilityTotalDurationReported?: number | undefined;
};

export type OverviewAchievements = {
  record: number | undefined;
  weeklyGoal: number | undefined;
  weeklyCompleted: number | undefined;
  weeklyPercentage: number | undefined;
  yearlyGoal: number | undefined;
  yearlyCompleted: number | undefined;
  yearlyPercentage: number | undefined;
};
export type OverviewCharts = {
  productionChart: ChartRecord[] | undefined;
  speedChart: ChartRecord[] | undefined;
  utilizationChart: ChartRecord[] | undefined;
  availabilityChart: ChartRecord[] | undefined;
  isOverviewMainRequestLoading?: boolean;
};
export enum ActiveOverviewChart {
  Speed = 'Speed',
  Utilization = 'Utilization',
}
type CommonOverviewProps = {
  timeOfLastMainResponse: Date | undefined;
  timeOfLastAvailabilityResponseResponse: Date | undefined;
  maxKeepAlive: Date | undefined;
};
export type Overview = OverviewCharts & OverviewCards & OverviewAchievements & CommonOverviewProps;

// Machine Config

export interface MachineConfigParam {
  name: string;
  section: string;
  value: string | number | boolean;
  min?: number;
  max?: number;
}

export interface MachineConfigSection {
  name: string;
  isExpanded: boolean;
  hasOutOfRange: boolean;
  params: MachineConfigParam[];
}

export interface MachineConfigDevice {
  id: string;
  name: string;
  imageUrl: string | null;
}
export interface MachineConfig {
  params: MachineConfigParam[] | string;
  device: MachineConfigDevice;
}
// JobAnalysis
export type JobAnaylsisByType = {
  [key in UserSystemGroupStrings]?: JobAnalysis;
};

export type JobAnaylsisByTypePartial = {
  [key in UserSystemGroupStrings]?: Partial<JobAnalysis>;
};
export type JobAnalysis = {
  shouldBeUpdatedAfterGroupSelection?: boolean;
  bottlesLoaded: ColorRecordBasic[] | undefined;
  totalProduction: number | undefined;
  totalPrintingTime: number | undefined;
  printingTimePerUnit: JobAnalysisChartCard | undefined;
  inkConsumptionPerUnit: JobAnalysisChartCard | undefined;
  mediaWithResponse: JobAnalysisChartCard | undefined;
  inkConsumptionChart: InkConsumptionChart | undefined;
  colorConsumptionChart: ColorConsumptionChart | undefined;
  colorCompareChart: ColorCompareChart | undefined;
  selectedColors: string[] | undefined;
  isJobAnalysisChartsLoading: boolean;
  isJobAnalysisChartsLoaded: boolean;
  isTotalCardsLoading: boolean;
  isDownloadInkConsumptionPerJobLoading: boolean;
  isDownloadInkConsumptionPerJobLoaded: boolean;
  downloadInkConsumptionPerJobError: object | string;
  isInkConsumptionPerJobValidToDownloadMessage?: undefined | string;
  isTimeFrameWasChanged?: boolean;
  lastUpdate?: Date;
  maxKeepAlive: Date | undefined;
};
//
// ------ The Store ------

export interface Store {
  appUser: AppUser;
  api: ApiStore;
  apiState: ApiState;
  entities: EntityStore;
  ui: UiStore;
  reports: ReportStore;
  systemAnalysis: ISystemAnalysisState;
  userSettings: IUserSettingsState;
  overview: OverviewByType;
  jobAnalysis: JobAnaylsisByType;
  machineConfig: MachineConfig;
  jobSummary: JobSummaryStateTypes;
  apiManagement: IApiManagementState;
  systemPage: SystemPageState;
  serviceTicket: ServiceTicketStateBySystem;
  isEverReported: IsEverReportedStateTypes;
  webSocket: WebSocket;
}
export type ReportedSystemsUnit = {
  serialNumber: string;
  isEverReported: boolean;
};
export type ReportedSystemsResponseByGroup = {
  DTG: Array<ReportedSystemsUnit>;
  DTF: Array<ReportedSystemsUnit>;
  Apollo: Array<ReportedSystemsUnit>;
};
export type IsEverReportedStateTypes = {
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
  data: ReportedSystemsResponseByGroup;
};
// ------ User Settings -----
export enum UserSystemGroup {
  DTG = 'DTG',
  DTF = 'DTF',
  Apollo = 'Apollo',
}
export enum DtfCardsName {
  Production = 'Production',
  ProdSolid = 'Prod. Solid',
  Quality = 'Quality',
  QualitySolid = 'Quality Solid',
}
export type UserSystemGroupStrings = keyof typeof UserSystemGroup | '';

export type NewSystemGroupsStrings = Exclude<UserSystemGroupStrings, ''>;
export enum LengthUnits {
  Meter = 'Meter',
  Feet = 'Feet',
  Yard = 'Yard',
}

export type LengthUnitsGroupStrings = keyof typeof LengthUnits | '';

export enum VolumeUnits {
  Litter = 'Litter',
  Bottle = 'Bottle',
}

export type VolumeUnitsGroupStrings = keyof typeof VolumeUnits | '';

export enum RTRMetricUnit {
  Linear = 'Linear',
  Square = 'Square',
}

export type RTRMetricUnitGroupStrings = keyof typeof RTRMetricUnit | '';

export interface UserSettings {
  userSystemGroup?: UserSystemGroupStrings[];
  defaultSystemGroup: UserSystemGroupStrings;
  lengthUnits: LengthUnitsGroupStrings;
  volumeUnits: VolumeUnitsGroupStrings;
  rtrMetricUnit: RTRMetricUnitGroupStrings;
  portalLanguage: string;
  tenantName: string;
  importedTenantId: string;
  office?: null | string;
  accountType?: null | string;
  realTimeRefreshRateSeconds: number;
}

export interface IUserSettingsState {
  data: UserSettings;
  isLoading: boolean;
  isError: boolean;
}

export interface CertificateDetails {
  userId: string;
  tenantId: number;
  isDeleted: boolean;
  email: string;
  thumbprint: string;
  expirationDate: Date;
  validFrom: Date;
  certificate: string;
}

export interface IApiManagementState {
  certificate: CertificateDetails | undefined;
  isLoading: boolean;
  isError: boolean;
}

//*system page

export type ErrorMessage = {
  message: string;
  displayMessage: string;
  count?: number;
  eventsCount?: number;
};
export type UniversalSystemPageMesage = {
  displayMessage: string;
  eventsCount: number;
};
export type CommonMaintenanceEvent = ErrorMessage;
export type DTFBottlesType = {
  colorName: string;
  colorValue: number;
  TankType?: TankType;
};
export type SystemPageState = {
  isTopCardsLoading: boolean;
  isLoading: boolean;
  isCommonErrorsLoading: boolean;
  isCommonMaintenanceEventsLoading: boolean;
  isAchievementCardLoading: boolean;
  error: string | Record<string, unknown>;
  loadedBottles: Array<ColorDataItem>;

  isTimeLineDayLoading: boolean;
  isTimeLineDayLoaded: boolean;
  errorTimeLineDay: string;
  timeLineDataDay: null | Object;

  isTimeLineWeekLoading: boolean;
  isTimeLineWeekLoaded: boolean;
  errorTimeLineWeek: string;
  timeLineDataWeek: null | Object;

  timeLineDayView: string;
  timeLineSelectedHour: number;
  timeLineAMPMmodificator: string;
  errorMessages: Array<ErrorMessage>;
  commonMaintenanceEvents: Array<CommonMaintenanceEvent>;
  achievement: number;
  productionRateChangeLastWeek: number;
  productionRateChangeFleet: number;
  productionRate: number;
  productionChangeLastWeek: number;
  productionChangeFleet: number;
  production: number;
  printerImage: string;
  lastStatus: string;
  lastSoftwareUpdate: string;
  availabilityChangeLastWeek: number;
  availabilityChangeFleet: number;
  availability: number;
  appVersion: string;
  maintenanceDuration: number;
  errorDuration: number;
  isQualisetLoading: boolean;
  qualisetData: null | any;
  isNotReportingPeriod?: boolean;
  availabilityTotalDurationReported?: number;
  isAvailabilityCardLoading?: boolean;
  isImpressionCardsLoading?: boolean;
  systemModel: string;
};

export enum TankType {
  small = 'Small',
  big = 'Big',
}
export type SystemPageCardResponseType = {
  achievement: number;
  productionRateChangeLastWeek: number;
  productionRateChangeFleet: number;
  productionRate: number;
  productionChangeLastWeek: number;
  productionChangeFleet: number;
  production: number;
  printerImage: string;
  lastStatus: string;
  lastSoftwareUpdate: string;
  availabilityChangeLastWeek: number;
  availabilityChangeFleet: number;
  availability: number;
  appVersion: string;
  errorDuration: number;
  errorPercentage: number;
  maintenanceDuration: number;
  maintenancePercentage: number;
  productivity?: number;
  productivityChangeFleet?: number;
  productivityChangeLastWeek?: number;
  isNotReportingPeriod?: boolean;
  availabilityTotalDurationReported?: number;
  systemModel?: string;
};

export type UniversalSystemPageRequestType = ReportDateRequest & {
  systemGroup: string;
  system: string;
  timeLineGranularity?: string;
  isSilent?: boolean;
};

export type ColorDataItem = {
  colorName: string;
  colorValue: number;
  isBottleMode?: boolean;
  allColorSort?: number;
};
export enum DtgCardSizes {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}
export enum DtgSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
export enum DtfCardNames {
  Production = 'Production',
  ProdSolid = 'Prod. Solid',
  Quality = 'Quality',
  QualitySolid = 'Quality Solid',
}
export enum DtgCardColors {
  white = 'white',
  black = 'black',
}
export enum DtfPageSelector {
  BySize = 'BySize',
  ByPrintType = 'ByPrintType',
}
export enum DtgCardButtonID {
  whiteSmall = 'white_small',
  whiteMedium = 'white_medium',
  whiteLarge = 'white_large',
  blackSmall = 'black_small',
  blackMedium = 'black_medium',
  blackLarge = 'black_large',
}

export type JobSummaryStateTypes = {
  loading: boolean;
  isApolloLoading: boolean;
  isApolloLoaded: boolean;
  isDTGLoading: boolean;
  isDTGLoaded: boolean;
  isDTFLoading: boolean;
  isDTFLoaded: boolean;
  error: string | {} | Array<any>;
  DTG: Array<SizeDTGCardType>;
  Apollo: Array<SizeDTGCardType>;
  DTF: Array<ModeDTFTypeCards>;
  dtfPageSelector: DtfPageSelector.BySize | DtfPageSelector.ByPrintType | string;
  isDtfResolutionGroupSelected?: boolean;
  isJobAnalysisChartsLoading?: boolean;
};
export type SizeDTGCardType = {
  impressionSize: number;
  mediaType: number;
  isSummaryData: boolean;
  inkConsumption: number;
  printingTime: number;
  production: number;
  productionPercentFromTotal: number;
  selected?: boolean;
  id?: string;
  sizeString?: string;
  colorString?: string;
  isGrandTotal?: boolean;
};
export type ModeDTFTypeCards = {
  mode: number;
  modeResolution: string;
  inkConsumption: number;
  printingTime: number;
  production: number;
  productionPercentFromTotal: number;
  selected: boolean;
  modeName: string;
};

export type StyledComponentWithAnyType = any;
export type GenericPropsType = any;

export enum PeriodTypes {
  Week = 'Week',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
}

export enum ActiveReportsTypes {
  Overview = 'Overview',
  SystemAnalysis = 'SystemAnalysis',
  JobAnalysis = 'JobAnalysis',
}
export enum MobileTabsTypes {
  JobSummary = 'JobSummary',
  Charts = 'Charts',
}
export enum TimeFrameSelectorViews {
  Mixed = 'mixed',
  Buttons = 'buttons',
  DropDown = 'dropdown',
}
//serviceTicketPage
export type ServiceTicketStateBySystem = {
  id: string;
  name: string;
  importedCustomerId: string;
  isLoading: boolean;
  isLoaded: boolean;
  error: string;
};
export enum DayViewType {
  day,
  hour,
}
export enum ConfigNamesEnum {
  Dev = 'Dev',
  Production = 'Production',
  Test = 'Test',
  Staging = 'Staging',
  DevOps = 'dvps',
  Auto = 'Auto',
  CustomAutomation = 'CustomAutomation',
}
export const inkConsuptionPerJobStatuses = {
  validToDownload: 'Download report',
  tooMuchData:
    'Export limited to 200k Jobs. Please change the date range to reduce the number of jobs.',
  tooMuchDataShortMsg: 'Exceeds 1.048M limit.',
  noData: 'No Data for this period.',
  initialState: 'initialState',
};
export const systemAnalysisDownloadStatuses = inkConsuptionPerJobStatuses;
export type WebSocketUnit = { SerialNumber: string; NumberOfImpressions: number };
export type WebSocketPageState = { data: Array<WebSocketUnit> };
export type CustomTimeFrameProperites = {
  isShiftModeSelected: boolean;
  customTimeFrameStartDate: Date;
  customTimeFrameStartHour: number;
  customTimeFrameEndDate: Date;
  customTimeFrameEndHour: number;
};
declare global {
  interface Window {
    domain: { domain: string };
    clientID: { clientID: string };
    redirectUri: { redirectUri: string };
    audience: { audience: string };
    returnTo: { returnTo: string };
    env: string;
    API_ROUTER_HOST: { API_ROUTER_HOST: string };
    AUTH0_CLAIMS_NAMESPACE: { AUTH0_CLAIMS_NAMESPACE: string };
    SELF_HOST: { SELF_HOST: string };
    USER_LOGOUT_TIMEOUT: { USER_LOGOUT_TIMEOUT: number };
    USER_AGREEMENT_VERSION: { USER_AGREEMENT_VERSION: string };
    WEEK_START: { WEEK_START: number };
    FILTER_HIDE: { FILTER_HIDE: boolean };
    SHOW_PBI_REPORT_PAGES_PANE: { SHOW_PBI_REPORT_PAGES_PANE: boolean };
    SHOW_PBI_REPORT_FILTERS_PANE: { SHOW_PBI_REPORT_FILTERS_PANE: boolean };
    REPORTS_REFRESH_TIMEOUT_SECONDS: { REPORTS_REFRESH_TIMEOUT_SECONDS: number };
    MAX_LOAD_ATTEMPTS: { MAX_LOAD_ATTEMPTS: number };
    SPLIT_WEEK_BY_END_OF_YEAR: { SPLIT_WEEK_BY_END_OF_YEAR: boolean };
    ENV_NAME: { ENV_NAME: string };
    PLATFORM: { PLATFORM: string };
    SHOW_VERSION_VERSION_LABEL: { SHOW_VERSION_VERSION_LABEL: boolean };
    GENERATE_SOURCEMAP: { GENERATE_SOURCEMAP: boolean };
    LOGGER_INSTRUMENTAION_KEY: { LOGGER_INSTRUMENTAION_KEY: string };
    REFRESH_API_INTERVAL: { REFRESH_API_INTERVAL: number };
    UNSUBSCRIBE_END_POINT: { UNSUBSCRIBE_END_POINT: string };
    SERVICE_TICKET_END_POINT: { SERVICE_TICKET_END_POINT: string };
    BLOB_URL: { BLOB_URL: string };
    API_URL: { API_URL: string };
    RECAPTCHA_KEY: { RECAPTCHA_KEY: string };
    REAL_TIME_USER_LIST: { REAL_TIME_USER_LIST: string };
  }
}
