import styled from 'styled-components/macro';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonSpinner = styled(CircularProgress).attrs({
  size: 20,
})`
  color: #fff;
`;

export default ButtonSpinner;
