export const updateFilterDataStateWithEverReportedStatus = (oldState, action) => {
  if (!oldState) {
    return;
  }
  const result = {};
  const systemGroups = Object.keys(oldState);

  systemGroups.forEach(sGroup => {
    const currentGroupWithEveReportedStatus = action.payload.reportedSystems.find(
      group => group.systemGroup === sGroup
    );
    const oldGroupState = oldState[sGroup];
    const updatedSystems = new Array();
    oldGroupState.systems.forEach(system => {
      const systemWithStatus = currentGroupWithEveReportedStatus.reportedSystemsData.find(
        s => s.serialNumber === system.name
      );

      const updatedSystem = {
        ...system,
        isEverReported: !!systemWithStatus?.isEverReported,
      };
      updatedSystems.push(updatedSystem);
    });
    const newGroupState = { ...oldGroupState, systems: updatedSystems };
    result[sGroup] = newGroupState;
  });
  return result;
};
