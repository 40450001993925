import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8065 3.09677H21.6774C22.5326 3.09677 23.2258 3.79001 23.2258 4.64516V22.4516C23.2258 23.3068 22.5326 24 21.6774 24H1.54839C0.693236 24 0 23.3068 0 22.4516V4.64516C0 3.79001 0.693236 3.09677 1.54839 3.09677H5.41935V0.774194C5.41935 0.346618 5.76597 0 6.19355 0C6.62112 0 6.96774 0.346618 6.96774 0.774194V3.09677H16.2581V0.774194C16.2581 0.346618 16.6047 0 17.0323 0C17.4598 0 17.8065 0.346618 17.8065 0.774194V3.09677ZM2.32258 22.4516H20.9032C21.3308 22.4516 21.6774 22.105 21.6774 21.6774V7.74194C21.6774 7.31436 21.3308 6.96774 20.9032 6.96774H2.32258C1.89501 6.96774 1.54839 7.31436 1.54839 7.74194V21.6774C1.54839 22.105 1.89501 22.4516 2.32258 22.4516Z"
        fill="#7F8FA4"
      />
      <rect x="3.0968" y="15.4839" width="4.64516" height="3.87097" fill="#7F8FA4" />
      <rect x="3.0968" y="10.0645" width="4.64516" height="3.87097" fill="#7F8FA4" />
      <rect x="9.29034" y="15.4839" width="4.64516" height="3.87097" fill="#7F8FA4" />
      <rect x="9.29034" y="10.0645" width="4.64516" height="3.87097" fill="#7F8FA4" />
      <rect x="15.4839" y="10.0645" width="4.64516" height="3.87097" fill="#7F8FA4" />
    </SvgIcon>
  );
};

export default CalendarIcon;
