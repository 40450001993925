import React from 'react';
import MaskProps from './MaskProps';

const MaskBig = (props: MaskProps) => {
  // tslint:disable: max-line-length
  const { color, height, width } = props;
  return (
    <svg
      width={width || '136'}
      height={height || '158'}
      viewBox="0 0 136 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136 0H0V158H136V0ZM65.3921 6.75406C64.7834 6.98407 64.1822 7.33115 62.9799 8.02533L10.24 38.4747L10.2395 38.4749C9.03754 39.1689 8.43643 39.516 7.93295 39.9281C6.42545 41.162 5.41998 42.9035 5.10516 44.826C5 45.4681 5 46.1623 5 47.5506V108.449C5 109.838 5 110.532 5.10516 111.174C5.41998 113.096 6.42545 114.838 7.93295 116.072C8.43643 116.484 9.03754 116.831 10.2395 117.525L10.24 117.525L62.9799 147.975C64.1822 148.669 64.7834 149.016 65.3921 149.246C67.2144 149.935 69.2253 149.935 71.0477 149.246C71.6563 149.016 72.2575 148.669 73.4598 147.975L126.2 117.525L126.201 117.525C127.402 116.831 128.003 116.484 128.507 116.072C130.014 114.838 131.02 113.096 131.335 111.174C131.44 110.532 131.44 109.838 131.44 108.449V47.5506C131.44 46.1623 131.44 45.4681 131.335 44.826C131.02 42.9035 130.014 41.162 128.507 39.9281C128.003 39.5161 127.402 39.169 126.201 38.4752L126.2 38.4747L73.4599 8.02533C72.2575 7.33115 71.6563 6.98407 71.0477 6.75406C69.2253 6.06546 67.2144 6.06546 65.3921 6.75406Z"
        fill={color || '#2ea1f8'}
      />
      <g filter="url(#filter0_dd)">
        <path
          d="M62.9799 8.02531C64.1822 7.33114 64.7834 6.98406 65.3921 6.75405C67.2144 6.06546 69.2253 6.06546 71.0477 6.75405C71.6563 6.98406 72.2575 7.33114 73.4599 8.02532L126.2 38.4747C127.402 39.1689 128.003 39.5159 128.507 39.9281C130.014 41.162 131.02 42.9035 131.335 44.826C131.44 45.4681 131.44 46.1623 131.44 47.5506V108.449C131.44 109.838 131.44 110.532 131.335 111.174C131.02 113.097 130.014 114.838 128.507 116.072C128.003 116.484 127.402 116.831 126.2 117.525L73.4599 147.975C72.2575 148.669 71.6563 149.016 71.0477 149.246C69.2253 149.935 67.2144 149.935 65.3921 149.246C64.7834 149.016 64.1822 148.669 62.9799 147.975L10.24 117.525C9.03766 116.831 8.43648 116.484 7.93295 116.072C6.42545 114.838 5.41997 113.097 5.10515 111.174C5 110.532 5 109.838 5 108.449V47.5506C5 46.1623 5 45.4681 5.10515 44.826C5.41997 42.9035 6.42545 41.162 7.93295 39.9281C8.43648 39.5159 9.03766 39.1689 10.24 38.4747L62.9799 8.02531Z"
          stroke="#354052"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="2.25"
          y="5.48755"
          width="131.94"
          height="149.025"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MaskBig;
