import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../_common/Modal';
import ModalHeader from '../_common/ModalHeader';
import SchedulerForm from './SchedulerFormContainer';
import { SchedulerModalProps } from './SchedulerModalContainer';

const SchedulerModal = React.memo((props: SchedulerModalProps) => {
  const { t } = useTranslation();
  const { closeModalScheduler, isOpen, modalSchedulerIsNew } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModalScheduler}
      closeIconColor="#fff"
      maxWidth="sm"
      disableBackdropClick>
      <ModalHeader>
        {modalSchedulerIsNew ? t('schedulerTable.newScheduler') : t('schedulerTable.editScheduler')}
      </ModalHeader>

      <SchedulerForm />
    </Modal>
  );
});

export default SchedulerModal;
