import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import { ActiveOverviewChart } from 'store/storeTypes';
import styled from 'styled-components/macro';
import { ReactComponent as SpeedIcon } from 'assets/icons/speed.svg';
import { ReactComponent as UtilizationIcon } from 'assets/icons/utilization.svg';
const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  width: 65px;
  height: 32px;
  border: 0.5px solid #7f8fa4;
  border-radius: 4px;
  padding: 3px;
`;

const ToggleButtonStyled = styled(ToggleButton)`
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 2px !important;
  svg {
    fill: #c5d0de;
  }
  &.Mui-selected {
    background-color: #2ea1f8;
    svg {
      fill: white;
    }
  }
`;

const DesktopToggleChart = props => {
  const { activeOverviewChart, selectedSystemGroup, handleChartChange } = props;

  return (
    <ToggleButtonGroupStyled
      style={{ marginRight: '10px', height: '25px', lineHeight: '25px', verticalAlign: 'sub' }}
      exclusive
      value={activeOverviewChart[selectedSystemGroup]}
      onChange={handleChartChange}>
      <ToggleButtonStyled value={ActiveOverviewChart.Speed}>
        <SpeedIcon />
      </ToggleButtonStyled>
      <ToggleButtonStyled value={ActiveOverviewChart.Utilization}>
        <UtilizationIcon />
      </ToggleButtonStyled>
    </ToggleButtonGroupStyled>
  );
};

export default DesktopToggleChart;
