import { createAsyncAction } from 'typesafe-actions';
import { IOverviewRequest } from '../models/OverviewData';
import { authService } from 'services/AuthProvider';
import { makeApiUrl } from 'actions/_shared/utils';
import apiAction from 'actions/_shared/apiAction';
import FileDownload from 'js-file-download';
import { CertificateDetails } from 'store/storeTypes';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Store } from 'store/storeTypes';

const convert = (data: any) => {
  return !data.email
    ? undefined
    : {
        ...data,
        expirationDate: new Date(data.expirationDate),
        validFrom: new Date(data.validFrom),
      };
};

export const getCertificateDetailsAC = createAsyncAction(
  'GET_CERTIFICATE_DETAILS_STARTED',
  'GET_CERTIFICATE_DETAILS_SUCCESS',
  'GET_CERTIFICATE_DETAILS_FAILED'
)<{}, CertificateDetails | undefined, Error>();

export const getCertificateDetailsRequest = () => {
  const url = makeApiUrl('/api/users/certificate-details');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getCertificateDetailsAC.failure(error)),
        onStarted: dispatch => dispatch(getCertificateDetailsAC.request({})),
        onSuccess: (data, dispatch) => dispatch(getCertificateDetailsAC.success(convert(data))),
      },
    });
};

export const getCertificateFileAC = createAsyncAction(
  'GET_CERTIFICATE_FILE_STARTED',
  'GET_CERTIFICATE_FILE_SUCCESS',
  'GET_CERTIFICATE_FILE_FAILED'
)<{}, {}, Error>();

export const getCertificateFileRequest = () => {
  const url = makeApiUrl('/api/users/certificate-file');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
      },
      logic: {
        onFailed: (error, dispatch) => {},
        onStarted: dispatch => {},
        onSuccess: (data, dispatch) => {
          FileDownload(data, 'certificate.crt');
        },
      },
    });
};

export const createCertificateAC = createAsyncAction(
  'CREATE_CERTIFICATE_DETAILS_STARTED',
  'CREATE_CERTIFICATE_DETAILS_SUCCESS',
  'CREATE_CERTIFICATE_DETAILS_FAILED'
)<{}, CertificateDetails, Error>();

export const createCertificateRequest = () => {
  const url = makeApiUrl('/api/users/create-certificate');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        method: 'POST',
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(createCertificateAC.failure(error)),
        onStarted: dispatch => {
          dispatch(createCertificateAC.request({}));
        },
        onSuccess: (data, dispatch) => dispatch(createCertificateAC.success(convert(data))),
      },
    });
};

export const deleteCertificateAC = createAsyncAction(
  'DELETE_CERTIFICATE_DETAILS_STARTED',
  'DELETE_CERTIFICATE_DETAILS_SUCCESS',
  'DELETE_CERTIFICATE_DETAILS_FAILED'
)<{}, {}, Error>();

export const deleteCertificateRequest = () => {
  const url = makeApiUrl('/api/users/delete-certificate');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return apiAction({
      request: {
        url,
        method: 'DELETE',
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(deleteCertificateAC.failure(error)),
        onStarted: dispatch => dispatch(deleteCertificateAC.request({})),
        onSuccess: (data, dispatch) => dispatch(deleteCertificateAC.success({})),
      },
    });
};

export const refreshCertificateRequest = () => {
  const deleteUrl = makeApiUrl('/api/users/delete-certificate');
  const createUrl = makeApiUrl('/api/users/create-certificate');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub)
    return async function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
      await dispatch(
        apiAction({
          request: {
            url: deleteUrl,
            method: 'DELETE',
          },
          logic: {
            onFailed: (error, dispatch) => dispatch(deleteCertificateAC.failure(error)),
            onStarted: dispatch => dispatch(deleteCertificateAC.request({})),
            onSuccess: (data, dispatch) => {},
          },
        })
      );
      await dispatch(
        apiAction({
          request: {
            url: createUrl,
            method: 'POST',
          },
          logic: {
            onFailed: (error, dispatch) => dispatch(deleteCertificateAC.failure(error)),
            onStarted: dispatch => dispatch(deleteCertificateAC.request({})),
            onSuccess: (data, dispatch) => dispatch(createCertificateAC.success(convert(data))),
          },
        })
      );
    };
};
