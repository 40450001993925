import {
  addDays,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';
import { CustomTimeFrameProperties } from 'actions/reportFilterActions';
import { TimeFrame, TimeFrameEnum } from 'models/ReportData';
import { convertSecondsToHours } from './conversions';
import { globalColors } from 'globalStyle';
import { isFuturePeriod } from './chartUtils';
import { AggregatedRecord } from 'components/Overview/Charts/ProductionChart';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';

const ticksName = {
  day: ['0:00', '4:00', '8:00', '12:00', '16:00', '20:00', 'flag'],
  week: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
};
export const getLegendText = timeframe => {
  if (timeframe === 'day') {
    return {
      total: 'Selected day',
      totalAvg: 'Same Day, Previous week',
    };
  }
  if (timeframe === 'week') {
    return {
      total: 'Selected week',
      totalAvg: 'Previous week',
    };
  }
  if (timeframe === TimeFrameEnum.custom) {
    return {
      total: 'Selected period',
      totalAvg: 'Previuos period',
    };
  } else {
    return {
      total: 'Today',
      totalAvg: 'Same Day, Previous week',
    };
  }
};
export function getTicks(
  index: number,
  width: number,
  intervalsCount: number,
  isWeek: boolean
): { tickXcoordinate: number; tickText: string; textXCoordinate: number } {
  const currentTickArray = isWeek ? ticksName.week : ticksName.day;
  const RANGE = width / intervalsCount;
  const OFFSET = 36;
  const TEXT_OFFSET = -14;
  const START = OFFSET + 13;
  const END = width + OFFSET + 11;
  if (index === 0) {
    return {
      tickXcoordinate: START,
      tickText: currentTickArray[index],
      textXCoordinate: START + TEXT_OFFSET + 5,
    };
  }
  if (index === 6) {
    const weekLastDayOffset = isWeek ? -9 : 0;
    return {
      tickXcoordinate: END + 2,
      tickText: currentTickArray[index],
      textXCoordinate: END + 2 + TEXT_OFFSET + weekLastDayOffset,
    };
  }

  return {
    tickXcoordinate: START + index * RANGE,
    tickText: currentTickArray[index],
    textXCoordinate: START + index * RANGE + TEXT_OFFSET,
  };
}
export function getTicksSpeedChart(
  index: number,
  width: number,
  intervalsCount: number,
  isWeek: boolean
): { tickXcoordinate: number; tickText: string; textXCoordinate: number } {
  const currentTickArray = isWeek ? ticksName.week : ticksName.day;
  const RANGE = isWeek ? (width - 40) / intervalsCount : (width - 5) / intervalsCount;
  const TEXT_OFFSET = -14;
  const START = isWeek ? 67 : 51;
  const END = isWeek ? width + 25 : width + 46;
  if (index === 0) {
    return {
      tickXcoordinate: START,
      tickText: currentTickArray[index],
      textXCoordinate: START + TEXT_OFFSET + 5,
    };
  }
  if (index === 6) {
    const weekLastDayOffset = isWeek ? -9 : 0;
    return {
      tickXcoordinate: END + 2,
      tickText: currentTickArray[index],
      textXCoordinate: END + 2 + TEXT_OFFSET + weekLastDayOffset,
    };
  }

  return {
    tickXcoordinate: START + index * RANGE,
    tickText: currentTickArray[index],
    textXCoordinate: START + index * RANGE + TEXT_OFFSET,
  };
}
export function getTicksAvailabilityChart(
  index: number,
  width: number,
  intervalsCount: number,
  isWeek: boolean
): { tickXcoordinate: number; tickText: string; textXCoordinate: number } {
  const currentTickArray = isWeek ? ticksName.week : ticksName.day;
  const RANGE = width / intervalsCount;
  const TEXT_OFFSET = -14;
  const START = 12;
  const END = width + 7;
  if (index === 0) {
    const xOffset = isWeek ? -2 : -2;
    return {
      tickXcoordinate: START + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: START + TEXT_OFFSET + 10,
    };
  }
  if (index === 1) {
    const xOffset = isWeek ? -3 : 0;

    return {
      tickXcoordinate: START + index * RANGE + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: START + index * RANGE + TEXT_OFFSET,
    };
  }
  if (index === 2) {
    const xOffset = isWeek ? -3 : 1;

    return {
      tickXcoordinate: START + index * RANGE + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: START + index * RANGE + TEXT_OFFSET,
    };
  }
  if (index === 3) {
    const xOffset = isWeek ? -3 : 3;

    return {
      tickXcoordinate: START + index * RANGE + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: START + index * RANGE + TEXT_OFFSET,
    };
  }
  if (index === 4) {
    const xOffset = isWeek ? -4 : +5;

    return {
      tickXcoordinate: START + index * RANGE + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: START + index * RANGE + TEXT_OFFSET,
    };
  }
  if (index === 5) {
    const xOffset = isWeek ? -4 : +7;

    return {
      tickXcoordinate: START + index * RANGE + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: START + index * RANGE + TEXT_OFFSET,
    };
  }
  if (index === 6) {
    const weekLastDayOffset = isWeek ? -9 : 0;
    const xOffset = isWeek ? +2 : +2;

    return {
      tickXcoordinate: END + xOffset,
      tickText: currentTickArray[index],
      textXCoordinate: END + 2 + TEXT_OFFSET + weekLastDayOffset,
    };
  }

  return {
    tickXcoordinate: START + index * RANGE,
    tickText: currentTickArray[index],
    textXCoordinate: START + index * RANGE + TEXT_OFFSET,
  };
}
export type ChartsHeadersForCustomTimeframeType = {
  timeframe: TimeFrame;
  customTimeframeProperties: CustomTimeFrameProperties;
};
export const getDescriptionTextForCustomTimeframe = ({
  timeframe,
  customTimeframeProperties,
}: ChartsHeadersForCustomTimeframeType) => {
  if (timeframe !== TimeFrameEnum.custom) {
    return;
  } else {
    const {
      customTimeFrameStartDate,
      customTimeFrameEndDate,
      customTimeFrameStartHour,
      customTimeFrameEndHour,
    } = customTimeframeProperties;
    customTimeFrameStartDate.setHours(customTimeFrameStartHour);
    customTimeFrameEndDate.setHours(customTimeFrameEndHour);
    const diffInDays =
      (customTimeFrameEndDate.getTime() - customTimeFrameStartDate.getTime()) /
      (1000 * 60 * 60 * 24);
    return diffInDays >= 8 ? 'From last year' : 'From last week';
  }
};
type AvailabilityTotalDurationReportedTextArgType = {
  availabilityTotalDurationReported: number;
  availabilityTotalDurationReportedAvg: number;
};
type AvailabilityTotalDurationReportedTextResultType = {
  availabilityTotalDurationReportedText: string;
  availabilityTotalDurationReportedAvgText: string;
};
export const getAvailabilityTotalDurationReportedText = ({
  availabilityTotalDurationReported,
  availabilityTotalDurationReportedAvg,
}: AvailabilityTotalDurationReportedTextArgType): AvailabilityTotalDurationReportedTextResultType => {
  //* in case of negative values, the text should be 'Not Reporting'
  const result = {
    availabilityTotalDurationReportedText: 'Not Reporting',
    availabilityTotalDurationReportedAvgText: 'Not Reporting',
  };
  if (availabilityTotalDurationReported >= 0) {
    result.availabilityTotalDurationReportedText = convertSecondsToHours(
      availabilityTotalDurationReported
    );
  }
  if (availabilityTotalDurationReportedAvg >= 0) {
    result.availabilityTotalDurationReportedAvgText = convertSecondsToHours(
      availabilityTotalDurationReportedAvg
    );
  }
  return result;
};
export enum OverviewTopCardNames {
  averageProductionRate = 'averageProductionRate',
  productivity = 'productivity',
  averageAvailability = 'averageAvailability',
}
type GetTopCardArrowColorArgsType = {
  cardName: string;
  trend: number;
  description: string;
};
export const getTopCardArrowColor = ({
  cardName,
  trend,
  description,
}: GetTopCardArrowColorArgsType): string => {
  //* special logic for each card
  if (cardName === OverviewTopCardNames.averageProductionRate) {
    if (description === 'Dark Impressions') {
      //* those arrows are always gray
      return globalColors.regular.arrows.gray;
    }
    if (description === 'Avg. Handling Time') {
      //*if trend is negative - green , if positive - red
      return trend < 0 ? globalColors.regular.arrows.green : globalColors.regular.arrows.red;
    }
    if (description === 'Avg. Utilization') {
      //*if trend is negative - red , if positive - green
      return trend < 0 ? globalColors.regular.arrows.red : globalColors.regular.arrows.green;
    }
    if (description === 'Preparation') {
      //*if trend is negative - green , if positive - red
      return trend < 0 ? globalColors.regular.arrows.green : globalColors.regular.arrows.red;
    }
  }
  if (cardName === OverviewTopCardNames.productivity) {
    //*always gray arrow
    return globalColors.regular.arrows.gray;
  }
  if (cardName === OverviewTopCardNames.averageAvailability) {
    //*if description is Error Dur.
    if (description === 'Error Dur.') {
      //*if trend is negative - green , if positive - red
      return trend < 0 ? globalColors.regular.arrows.green : globalColors.regular.arrows.red;
    }
    //*if description is Maintenance Dur.
    if (description === 'Maintenance Dur.') {
      //*if trend is negative - green , if positive - red
      return trend < 0 ? globalColors.regular.arrows.green : globalColors.regular.arrows.red;
    }
    //*if description is Maintenance Dur.
    if (description === 'Maintenance Dur.') {
      //*if trend is negative - green , if positive - red
      return trend < 0 ? globalColors.regular.arrows.green : globalColors.regular.arrows.red;
    }
  }
  return 'black';
};
type ChartRecord = {
  date: string;
  total: number;
  totalAvg: number;
  granularity: string;
  isNotReported?: boolean;
  isFuturePeriod?: boolean;
  Total?: number;
};
export const normalizeApolloAllChartsRecords = (
  records: ChartRecord[] | AggregatedRecord[],
  lastUpdate: Date,
  selectedSystemGroup: UserSystemGroupStrings
): ChartRecord[] => {
  if (!records) return [];
  if (selectedSystemGroup !== UserSystemGroup.Apollo) {
    return records as ChartRecord[];
  }
  return records.map(record => {
    //*in any case check totalAvg on -1;
    if (record.totalAvg === -1) {
      delete record.totalAvg;
      record.isNotReported = true;
      record.isFuturePeriod = false;
    }

    //*here possible to get both cases -1 and undefined - in that case we have to detect is it future period or not. in any case we have to remove record.total field fi exitst, and mark this record as isNotReported or isFuturePeriod
    const granularity = record.granularity;
    const isFuture = isFuturePeriod({
      date: record.date,
      granularity: granularity,
      lastUpdate: lastUpdate,
    });
    if (isFuture) {
      //*in overview there is total fields with small 't'
      delete record.total;
      //*in job analysis Total field starts with capital 'T'
      delete record.Total;
      //*if it is future than it is 100 % not notReported status
      record.isFuturePeriod = isFuture;
    }

    return record;
  });
};
