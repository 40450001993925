import React from 'react';
import { MobileDatePickerProps, Wrapper } from './MobileDatePicker';
import PbiFilterQuarters from '../PbiFilter/PbiFilterQuartersContainer';

const MobileQuarterPicker = (props: MobileDatePickerProps) => {
  const { closeCalendarModal } = props;
  return (
    <Wrapper data-id={'OuterWrapper'}>
      <PbiFilterQuarters isMobile={true} closeCalendarModal={closeCalendarModal} />
    </Wrapper>
  );
};
export default MobileQuarterPicker;
