import React from 'react';
import { DatePickerProps } from '@material-ui/pickers';
import cn from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import { Theme, withStyles } from '@material-ui/core/styles';
import { createStyles, WithStyles } from '@material-ui/core';

import { ValidationError } from 'helpers/validationRules';
import { format, getWeekForDate, isSameDay, isWithinInterval } from '../../helpers/date';
import FilterDatePicker from './FilterDatePicker';

const DateWeekPicker = React.memo((props: DateWeekPickerProps & { isMobile?: boolean }) => {
  const { variant, error, classes, isMobile, ...rest } = props;
  const renderWrappedWeekDay = (date: Date, selectedDate: Date, dayInCurrentMonth: boolean) => {
    const dateClone = new Date(date.getTime());
    const selectedDateClone = new Date(selectedDate.getTime());
    const start = getWeekForDate(selectedDateClone).startDate;
    const end = getWeekForDate(selectedDateClone).endDate;

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = cn({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = cn(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  return <FilterDatePicker {...rest} renderDay={renderWrappedWeekDay} variant={variant} />;
});

const styles = (theme: Theme) =>
  createStyles({
    dayWrapper: {
      position: 'relative',
    },
    day: {
      width: '32px',
      height: '32px',
      fontSize: '14px',
      lineHeight: '18px',
      margin: '0 3px',
      color: 'inherit',
      padding: '0 !important',
    },
    customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '4px',
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: '#676767',
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  });

interface DateWeekPickerProps
  extends Pick<DatePickerProps, Exclude<keyof DatePickerProps, 'error'>>,
    WithStyles<typeof styles> {
  error?: ValidationError | false;
  noHelper?: boolean;
  variant: 'dialog' | 'inline' | 'static';
}

export default withStyles(styles)(DateWeekPicker);
