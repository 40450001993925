import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SchedulerModal from './SchedulerModal';
import { Store, MODAL_STATUS } from 'store/storeTypes';
import { closeModalScheduler } from 'actions/schedulerUiActions';

const mapStateToProps = (state: Store) => ({
  isOpen: state.ui.schedulerModal.status !== MODAL_STATUS.CLOSED,
  modalSchedulerIsNew: !state.ui.schedulerForm.reportId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModalScheduler,
    },
    dispatch
  );

export type SchedulerModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerModal);
