import React from 'react';
import styled from 'styled-components/macro';
import MuiPaper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import Avatar from '../Avatar/Avatar';
import UserForm from '../UserForm/UserFormContainer';
import UserAvatarUpload from '../UserAvatarUpload/UserAvatarUploadContainer';
import UserModalSuccess from './UserModalSuccessContainer';
import { UserModalLayoutProps } from './UserModalLayoutContainer';

const Paper = styled(MuiPaper)`
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;

  .user-avatar {
    width: 36%;
    background: #2ea1f8;
    padding-bottom: 30px;
    text-align: center;

    h2 {
      font-family: Rubik, sans-serif;
      font-weight: normal;
      font-size: 24px;
      margin: 50px 0 40px;
      color: #fff;
    }

    @media (max-width: 599px) {
      width: 100%;
      h2 {
        font-size: 22px;
        margin: 0;
        padding: 14px 11px 15px;
      }
    }
  }

  .user-form {
    width: 64%;
    margin: 38px 27px 21px 24px;

    &::-webkit-scrollbar-track {
      border-left: 1px solid #ddd;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #109cf1;
      border-radius: 0 10px 10px 0;
    }

    @media (max-width: 599px) {
      margin: 0;
      padding: 15px;
      width: 100%;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
      }
    }
  }

  @media (max-width: 599px) {
    border-radius: 0;
    box-shadow: none;
    display: block;
    overflow-y: scroll;
  }
`;

const CreateUserModal = React.memo((props: UserModalLayoutProps) => {
  const { t } = useTranslation();
  const { done, closeModalUser, modalUserIsNew, avatarBase64, avatarUrl } = props;

  return (
    <Paper>
      {done && <UserModalSuccess />}

      {!done && (
        <>
          <div className="user-avatar">
            <h2>{modalUserIsNew ? t('userModal.createNewUser') : t('userModal.updateUser')}</h2>

            <UserAvatarUpload>
              <Avatar
                size="large"
                background="#2ea1f8"
                avatarBase64={avatarBase64}
                avatarUrl={avatarUrl}
                hoverMask
              />
            </UserAvatarUpload>
          </div>

          <div className="user-form">
            <UserForm closeThisModal={closeModalUser} />
          </div>
        </>
      )}
    </Paper>
  );
});

export default CreateUserModal;
