import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useForm, useField } from 'react-final-form-hooks';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import MuiPaper from '@material-ui/core/Paper';
import MuiTextField from '../_common/TextField';
import GoalCalenderIndicator from './GoalCalenderIndicator';
import { GoalItemProps } from './GoalItemContainer';
import { requiredPositiveInteger } from 'helpers/validationRules';
import { determineUnitToShow } from 'helpers/conversions';
import { StyledComponentWithAnyType } from 'store/storeTypes';

const Goal = styled(MuiPaper)`
  border: 1px solid #e6eaee;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @media (max-width: 380px) {
    border-radius: 0;
  }
`;

const GoalHeader: StyledComponentWithAnyType = styled(Grid).attrs({
  item: true,
})`
  border-bottom: 1px solid #e6e8f1;

  h3 {
    color: #354052;
    font-family: Rubik, serif;
    font-weight: 500;
    font-size: 18px;
    margin: 25px 20px 16.5px;
  }
`;

const GoalBody: StyledComponentWithAnyType = styled(Grid).attrs({
  item: true,
  container: true,
})`
  padding: 20px;
  overflow: auto;
`;

const GoalMain: StyledComponentWithAnyType = styled(Grid).attrs({
  item: true,
  container: true,
  xs: 12,
  md: 6,
})`
  border-bottom: 1px solid #c5d0de;
  border-right: 0;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 0 0 20px 0;
  @media (min-width: 960px) {
    padding: 20px;
    flex-wrap: nowrap;
    margin-bottom: 0;
    border-bottom: 0;
    border-right: 1px solid #c5d0de;
  }
`;

const Impressions: StyledComponentWithAnyType = styled(Grid).attrs({
  item: true,
  container: true,
  xs: 12,
  md: 6,
})`
  justify-content: flex-start;
  @media (min-width: 960px) {
    justify-content: center;
  }
`;

const GoalName: StyledComponentWithAnyType = styled.h3`
  font-family: Rubik, serif;
  font-weight: normal;
  font-size: 18px;
  color: #354052;
  margin: 0;
`;

const GoalInfoWrapper: StyledComponentWithAnyType = styled(Grid).attrs({
  item: true,
  container: true,
})`
  justify-content: space-between;
`;

const GoalValue: StyledComponentWithAnyType = styled.div`
  margin: 0;
  color: #208cd7;
  h1 {
    font-family: Rubik, serif;
    display: inline;
    font-weight: normal;
    font-size: 45px;
    margin: 0;
  }
  span {
    font-size: 24px;
  }
`;

const CurrentValue = styled(GoalValue)`
  color: #354052;
`;

const FormWrapper = styled.div`
  display: flex;
`;

const TextField = styled(MuiTextField)`
  input {
    font-family: Rubik, serif;
    font-size: 12px;
    padding: 10.5px;
    width: 212px;
    &::-webkit-inner-spin-button {
      display: none;
    }
    -moz-appearance: textfield;
    @media (max-width: 360px) {
      width: inherit;
    }
  }
`;

const FormButton = styled(Fab)`
  margin-left: 15px;
  color: white;
  width: 75px;
  height: 35px;
  border-radius: 4px;
  background: #2ea1f8;
  @media (max-width: 410px) {
    margin-left: 10px;
  }
`;

const FormattedDate = styled.h4`
  margin: 13px 0 0;
  font-family: Rubik, serif;
  font-weight: normal;
  font-size: 12px;
  color: #354052;
  text-align: left;
`;

const GoalItem = (props: GoalItemProps) => {
  const { t } = useTranslation();
  const {
    goalTypeStr,
    goalCurrentVal,
    goalImpVal,
    lastUpdateNote,
    goalEntry,
    passedDateIndicator,
    userSettings,
    selectedSystemGroup,
    updateGoal,
  } = props;

  const validate = useCallback(
    values => ({
      goals: requiredPositiveInteger(values.goals),
    }),
    []
  );

  const onSubmit = useCallback(
    values => {
      updateGoal(goalEntry.goalType, values.goals, selectedSystemGroup);
      values.goals = '';
    },
    [updateGoal, goalEntry, selectedSystemGroup]
  );

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate,
  });

  const goalsInput = useField('goals', form);

  return (
    <Goal>
      <GoalHeader>
        <h3>{t(goalTypeStr)}</h3>
      </GoalHeader>

      <GoalBody>
        <GoalMain>
          <GoalName>{t('goals.myGoal')}</GoalName>
          <GoalInfoWrapper>
            <GoalValue>
              <h1 data-automationid={`${goalTypeStr}Value`}>{goalCurrentVal}</h1>
              <span>{determineUnitToShow(selectedSystemGroup, userSettings, t)}</span>
            </GoalValue>
            <Grid item>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <FormWrapper>
                  <TextField
                    data-automationid={`${goalTypeStr} setNewGoalField`}
                    {...goalsInput.input}
                    type="number"
                    error={goalsInput.meta.touched && goalsInput.meta.error}
                    name="newGoal"
                    variant="outlined"
                    placeholder={t('goals.setNewGoal')}
                  />

                  <FormButton
                    type="submit"
                    disabled={!!goalsInput.meta.error || !goalsInput.input.value}>
                    <span>{t('goals.set')}</span>
                  </FormButton>
                </FormWrapper>
                <FormattedDate>{lastUpdateNote}</FormattedDate>
              </form>
            </Grid>
          </GoalInfoWrapper>
        </GoalMain>

        <Impressions>
          <div>
            <GoalName>{t('goals.currentImpressions')}</GoalName>

            <CurrentValue>
              <h1 data-automationid={`${goalTypeStr}CurrentProductionValue`}>{goalImpVal}</h1>
              <span>{determineUnitToShow(selectedSystemGroup, userSettings, t)}</span>
            </CurrentValue>

            <GoalCalenderIndicator
              key={goalEntry.goalType}
              goalId={goalEntry.goalType}
              passedDateIndicator={passedDateIndicator}
            />
          </div>
        </Impressions>
      </GoalBody>
    </Goal>
  );
};

export default GoalItem;
