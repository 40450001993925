import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import _debounce from 'lodash/debounce';
import { Spinner, SpinnerContainer } from 'components/Overview/Styles';
import config from 'config/config';
import { Button, TextField, createMuiTheme } from '@material-ui/core';
import { globalColors } from 'globalStyle';
import { useEffect, useState } from 'react';
import { ReactComponent as KonnectIcon } from '../../assets/icons/appIconSuperUserPage.svg';
import { Autocomplete } from '@material-ui/lab';

const LoginPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-contetn: start;
  justify-content: center;
`;
const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  min-height: 600px;
  width: 360px;
`;
const ConfirmButton = styled(Button)`
  background-color: ${globalColors.regular.background.blue};
  color: ${globalColors.regular.text.white};
  margin-top: 20px;
`;
const ErrorMessageWrapper = styled.div`
  display: flex;
  place-content: center;
  color: ${globalColors.regular.text.red};
`;
const IconWrapper = styled.div`
  display: flex;
  place-content: center;
  margin-bottom: 50px;
`;
const EmptyBox = styled.div`
  display: flex;
  min-height: 300px;
`;
const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;
const SuperUserLoginPage = props => {
  const MIN_SEARCH_LENGTH = 3;
  const { location } = props;
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [userList, setUserList] = useState([]);

  const params = new URLSearchParams(location.search);
  const sessionToken = params.get('sessionToken');
  const state = params.get('state');

  const [selectedUser, setSelectedUser] = useState(undefined);
  const onChangeHandler = event => {
    setErrorMessage('');
    const newValue = event.target.value;
    if (newValue.length < MIN_SEARCH_LENGTH || newValue === '') {
      setSelectedUser(undefined);
      setUserList([]);
    }

    setValue(newValue);
    request(newValue);
  };
  const request = value => {
    if (value.length < MIN_SEARCH_LENGTH || value === '') {
      setSelectedUser(undefined);
      setUserList([]);
      return;
    }

    setIsLoading(true);
    fetch(
      `${config.API_URL}/api/super-users/get-super-user-tenants?sessionToken=${sessionToken}&namePattern=${value}`
    )
      .then(response => response.json())
      .then(result => {
        if (result.status >= 400) {
          setErrorMessage('Something Went Wrong, try later');
        } else {
          const sortedUsers = result.sort((user1, user2) => {
            return user1.name.toLowerCase() > user2.name.toLowerCase() ? 1 : -1;
          });
          setUserList(sortedUsers);
          if (!sortedUsers.length) {
            setErrorMessage('There are no users in you account with this name');
          }
          setIsLoading(false);
        }
      })
      .catch(error => {
        setErrorMessage('Something Went Wrong, try later');
        setIsLoading(false);
      });
  };

  const onConfirmClickHandler = () => {
    if (!selectedUser) return;
    setIsLoading(true);
    fetch(
      `${config.API_URL}/api/super-users/login-callback?selectedTenantId=${selectedUser.id}&state=${state}&sessionToken=${sessionToken}`
    )
      .then(result => result.json())
      .then(res => {
        window.location.replace(res.callbackUrl);
      })
      .catch(error => console.log(error));
  };
  const onOptionClickHandler = option => {
    const selectedUser = userList.find(user => user.name === option);
    if (selectedUser) {
      setSelectedUser(selectedUser);
    } else {
      setSelectedUser(undefined);
    }
  };
  const onBlureHandler = event => {
    //@ts-ignore
    const value = event.target.value;
    const selectedUser = userList.find(user => user.name === value);
    if (selectedUser) {
      setSelectedUser(selectedUser);
    } else {
      setSelectedUser(undefined);
      setUserList([]);
    }
  };

  return (
    <LoginPage>
      <LoginWrapper>
        <EmptyBox></EmptyBox>
        <IconWrapper>
          <KonnectIcon />
        </IconWrapper>

        <StyledAutocomplete
          options={userList.map(user => user.name)}
          getOptionSelected={(option, value) => option.value === value.value}
          renderOption={option => {
            return <div onClick={() => onOptionClickHandler(option)}>{option}</div>;
          }}
          onBlur={event => onBlureHandler(event)}
          renderInput={params => (
            <TextField
              value={value}
              onChange={(event: any) => onChangeHandler(event)}
              {...params}
              label={userList.length ? 'Select customer' : 'Start typing customer name'}
            />
          )}
        />
        <ConfirmButton
          disabled={!selectedUser}
          style={{
            textTransform: 'none',
          }}
          variant="contained"
          onClick={onConfirmClickHandler}>
          Continue
        </ConfirmButton>
        {errorMessage && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}
        {isLoading && <SpinnerContainer>{Spinner(30)}</SpinnerContainer>}
      </LoginWrapper>
    </LoginPage>
  );
};
export default SuperUserLoginPage;
