import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ResponsiveDialogProps {
  title: string;
  text: string;
  cb: (answer: boolean) => void;
  fullScreen?: boolean;
}

function ModalPrompt(props: ResponsiveDialogProps) {
  const { t } = useTranslation();
  const { title, text, cb, fullScreen } = props;
  const handleClose = (answer: boolean) => cb(answer);

  return (
    <Dialog
      fullScreen={fullScreen}
      open
      onClose={() => handleClose(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {t('no')}
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus>
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalPrompt;
