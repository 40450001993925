import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeNode, TreeNodeProps } from 'react-dropdown-tree-select';
import { useForm, useField } from 'react-final-form-hooks';
import addMonths from 'date-fns/addMonths';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components/macro';
import ModalForm from '../_common/ModalForm';
import TextField from '../_common/TextField';
import TreeSelect from '../_common/TreeSelect/TreeSelect';
import ButtonOk from '../_common/ButtonOk';
import ButtonCancel from '../_common/ButtonCancel';
import ButtonSpinner from '../_common/ButtonSpinner';
import Select from '../_common/Select';
import FilterDatePicker from '../_common/FilterDatePicker';
import FilterTimePicker from '../_common/FilterTimePicker';
import WeekdaysSelector from '../_common/WeekdaysSelector';
import FormRow from '../_common/FormRow';
import Switch from '../_common/Switch';
import RadiosField from '../_common/RadiosField';
import Grid from '../_common/Grid';
import PopupButtons from '../_common/PopupButtons';

import { Frequency, Weekdays } from 'models/Scheduler';
import { required, minArrayLength } from 'helpers/validationRules';
import { SchedulerFormProps } from './SchedulerFormContainer';

import { startOfDay } from 'helpers/date';

const FormSelect = styled(Select)`
  .MuiSelect-root {
    padding-bottom: 5px !important;
  }
`;

const SchedulerForm = React.memo((props: SchedulerFormProps) => {
  const { t } = useTranslation();
  const {
    formData,
    scopeStrings,
    isSaving,
    reportTypes,
    closeModalScheduler,
    changeSchedulerFormScope,
    changeSchedulerFormValue,
    changeSchedulerFormStartDate,
    changeSchedulerFormStartTime,
    changeSchedulerFormRepeat,
    saveScheduler,
  } = props;
  const history = useHistory();

  const onSubmit = useCallback(() => {
    saveScheduler();
  }, [saveScheduler]);

  type ValidateProps = {
    emailSubject: string | number;
    report: string | number;
    repeat: string | number | boolean;
  };
  const validate = useCallback(
    (values: ValidateProps) => ({
      emailSubject: required(values.emailSubject),
      report: required(values.report),
      // Tree select could be validated only after it's data is processed by reducer.
      // So we use here value from state for validation
      scope: minArrayLength(1, scopeStrings),
      repeat:
        required(values.repeat) ||
        (formData.repeat === 0 ? minArrayLength(1, formData.weekdays) : undefined),
    }),
    [scopeStrings, formData]
  );

  const initialFormData = useMemo(() => ({ ...formData, scope: scopeStrings }), [
    formData,
    scopeStrings,
  ]);

  const { form, handleSubmit, errors } = useForm({
    onSubmit,
    //validate,
    initialValues: initialFormData,
  });

  const id = useField('id', form);
  const emailSubject = useField('emailSubject', form);
  const report = useField('report', form);
  const scope = useField('scope', form);
  const repeat = useField('repeat', form);
  const weekdays = useField('weekdays', form);
  const startDay = startOfDay(new Date());
  const repeatTypes = useMemo(
    () =>
      Object.keys(Frequency).filter(k => {
        return Number(Frequency[k]) === 2; // Show only weekly, TODO need resolved problem with Object key === number
      }),
    [Frequency] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ModalForm onSubmit={handleSubmit}>
      <input {...id.input} type="hidden" name="id" value={formData.reportId} />
      <FormRow spacing={2} container className="with-helpers">
        <Grid item xs={12} sm={7}>
          <TextField
            {...emailSubject.input}
            id="email-subject"
            label={t('schedulerTable.emailSubject')}
            error={emailSubject.meta.touched && emailSubject.meta.error}
            // onChange={useCallback(
            //   (e: React.ChangeEvent) => {
            //     changeSchedulerFormValue({
            //       field: 'emailSubject',
            //       value: (e.target as HTMLInputElement).value,
            //     });
            //     emailSubject.input.onChange(e);
            //   },
            //   [emailSubject, changeSchedulerFormValue]
            // )}
            //value={emailSubject.input.value}
            value="Default Report"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <FormSelect
            id="report"
            label={t('schedulerTable.report')}
            items={reportTypes}
            error={report.meta.touched && report.meta.error}
            value={1}
            disabled={true}
            // onChange={useCallback(
            //   (e: React.SyntheticEvent) => {
            //     changeSchedulerFormValue({
            //       field: 'report',
            //       value: (e.target as HTMLInputElement).value,
            //     });
            //     report.input.onChange(e);
            //     report.input.onBlur();
            //   },
            //   [report, changeSchedulerFormValue]
            // )}
            //value={formData.report}
          />
        </Grid>
      </FormRow>
      <FormRow container spacing={2} className="with-helpers">
        <Grid item xs={12}>
          <TreeSelect
            label={t('userForm.scope')}
            userScope={scopeStrings}
            data={formData.systemsTree}
            disabled={true}
            // onChange={useCallback(
            //   (currentNode: TreeNode, selectedNodes: TreeNodeProps[]) => {
            //     changeSchedulerFormScope(selectedNodes);
            //     // scope.input.onChange(selectedNodes);
            //     // scope.input.onBlur();
            //   },
            //   [changeSchedulerFormScope]
            // )}
            error={scope.meta.touched && scope.meta.error}
          />
        </Grid>
      </FormRow>
      <FormRow container spacing={2}>
        <Grid container item xs={12} sm={6}>
          <FilterDatePicker
            id="start-date"
            label={t('schedulerTable.date')}
            // onChange={useCallback(
            //   (date: any) => {
            //     changeSchedulerFormStartDate(date);
            //   },
            //   [changeSchedulerFormStartDate]
            // )}
            onChange={() => {}}
            disabled={true}
            value={formData.startDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FilterTimePicker
            id="start-date-time"
            label={t('schedulerTable.time')}
            // onChange={useCallback(
            //   (time: Date) => {
            //     changeSchedulerFormStartTime(time);
            //   },
            //   [changeSchedulerFormStartTime]
            // )}
            onChange={() => {
              return -1;
            }}
            disabled={true}
            value={startDay}
          />
        </Grid>
      </FormRow>
      <FormRow container spacing={2} className="with-helpers">
        <Grid container item xs={12} sm={6} className="repeat-grid-item">
          <FormSelect
            {...repeat.input}
            id="repeat"
            label={t('schedulerTable.repeat')}
            items={repeatTypes}
            error={repeat.meta.touched && repeat.meta.error}
            // onChange={useCallback(
            //   (e: React.SyntheticEvent) => {
            //     const { value } = e.target as HTMLInputElement;
            //     changeSchedulerFormRepeat(value === '' ? null : Number(value));
            //     repeat.input.onChange(value);
            //     repeat.input.onBlur();
            //   },
            //   [repeat, changeSchedulerFormRepeat]
            // )}
            //value={formData.repeat}
            value={0}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WeekdaysSelector
            //disabled={!formData.repeat && formData.repeat !== 0}
            disabled={true}
            {...weekdays.input}
            error={repeat.meta.touched && repeat.meta.error}
            // onChange={useCallback(
            //   (value: Weekdays[]) => {
            //     changeSchedulerFormValue({
            //       field: 'weekdays',
            //       value,
            //     });
            //     weekdays.input.onChange(value);
            //   },
            //   [weekdays, changeSchedulerFormValue]
            // )}
            //value={formData.weekdays}
            value={[Weekdays.Tuesday]}
          />
        </Grid>
      </FormRow>
      <FormRow container spacing={2}>
        <Grid item xs={11} sm={6} className="ends-grid-item">
          <RadiosField
            name="ends"
            fieldLabel={t('schedulerTable.ends')}
            labels={[t('schedulerTable.on'), t('schedulerTable.never')]}
            defaultValue="1"
            // onChange={useCallback(
            //   (v: string) => {
            //     changeSchedulerFormValue({
            //       field: 'endDate',
            //       value: v === '1' ? null : addMonths(new Date(), 3),
            //     });
            //   },
            //   [changeSchedulerFormValue]
            // )}
            disabled={true}
          />
          {/* <div style={{ display: formData.endDate ? 'block' : 'none' }}>
            <FilterDatePicker
              id="end-date"
              label=""
              onChange={useCallback(
                (v: Date | null) => {
                  changeSchedulerFormValue({
                    field: 'endDate',
                    value: v,
                  });
                },
                [changeSchedulerFormValue]
              )}
              value={"formData.endDate"}
            />
          </div> */}
        </Grid>
        <Grid item xs={1} sm={6} />
      </FormRow>
      <FormRow container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="notes"
            label={t('schedulerTable.addNotes')}
            // onChange={useCallback(
            //   (e: React.ChangeEvent) => {
            //     changeSchedulerFormValue({
            //       field: 'notes',
            //       value: (e.target as HTMLInputElement).value,
            //     });
            //   },
            //   [changeSchedulerFormValue]
            // )}
            disabled={true}
            value={formData.notes}
            multiline
            rowsMax="8"
          />
        </Grid>
      </FormRow>
      <Switch
        id="sendings"
        label={t('schedulerTable.sendingReport')}
        onChange={useCallback(
          (e: React.ChangeEvent) => {
            changeSchedulerFormValue({
              field: 'sending',
              value: (e.target as HTMLInputElement).checked,
            });
          },
          [changeSchedulerFormValue]
        )}
        checked={formData.sending}
        disabled={false}
        justify="space-between"
      />

      <PopupButtons>
        <ButtonCancel onClick={() => history.goBack()}>Cancel</ButtonCancel>
        {/* <ButtonOk
              type="submit"
              disabled={!formData.isFormTouched || isSaving || !isEmpty(errors)}> */}
        <ButtonOk type="submit" disabled={isSaving}>
          {isSaving ? <ButtonSpinner /> : t('userForm.save')}
        </ButtonOk>
      </PopupButtons>
    </ModalForm>
  );
});

export default SchedulerForm;
