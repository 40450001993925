import { combineReducers, Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { Entries, Ids } from '../../store/storeTypes';
import { ExtendedUserData } from '../../models/ExtendedUserData';

const { loadUsersSuccess, saveUserSuccess, deleteUserSuccess } = actions.userApi;

const userById = createReducer<Entries<ExtendedUserData>, RootAction>({})
  .handleAction([loadUsersSuccess], (state, action) => {
    if (action.payload) {
      const newState: Entries<ExtendedUserData> = {};

      action.payload.forEach((user: ExtendedUserData) => {
        newState[user.id] = user;
      });

      return newState;
    }

    return state;
  })

  .handleAction(saveUserSuccess, (state, action) => {
    const newUser =
      action.payload && Array.isArray(action.payload.newUsers) && action.payload.newUsers[0];

    if (newUser && newUser.id) {
      return { ...state, [newUser.id]: newUser };
    }

    return state;
  })

  .handleAction(deleteUserSuccess, (state, action) => {
    const newState = { ...state };
    delete newState[action.payload];

    return newState;
  });

const userAllIds = createReducer<Ids, RootAction>([])
  .handleAction(loadUsersSuccess, (state, action) => {
    const users = action.payload;

    if (users) {
      const newState: Ids = [];

      users.sort((a, b) => {
        if (a.lastName === b.lastName) {
          return a.firstName > b.firstName ? 1 : -1;
        }

        return a.lastName > b.lastName ? 1 : -1;
      });
      users.forEach(user => newState.push(user.id));

      return newState;
    }

    return state;
  })

  .handleAction(saveUserSuccess, (state, action) => {
    const newUser =
      action.payload && Array.isArray(action.payload.newUsers) && action.payload.newUsers[0];
    const usersById = action.payload && action.payload.usersById;

    if (newUser && newUser.id && usersById) {
      usersById[newUser.id] = newUser;

      const newState = [...state];
      if (!state.includes(newUser.id)) {
        newState.push(newUser.id);
      }
      newState.sort((a, b) => {
        if (usersById[a].lastName === usersById[b].lastName) {
          return usersById[a].firstName > usersById[b].firstName ? 1 : -1;
        }

        return usersById[a].lastName > usersById[b].lastName ? 1 : -1;
      });

      return newState;
    }

    return state;
  })

  .handleAction(deleteUserSuccess, (state, action) => {
    const newState = [...state];
    const index = newState.indexOf(action.payload);

    if (index > -1) {
      newState.splice(index, 1);
    }

    return newState;
  });

const userEntityReducer: Reducer = combineReducers({
  byId: userById,
  allIds: userAllIds,
});

export default userEntityReducer;
