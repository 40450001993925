import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Store } from 'store/storeTypes';
import {
  getReportsFilterDates,
  getReportsFilterSites,
  getReportsCurrentState,
} from 'actions/reportApiActions';
import {
  setInitFilterState,
  setFilterFromUriQuery,
  setFilterSystemsState,
  applyMobileFilters,
  getDefaultFilterQueries,
} from 'actions/reportFilterActions';
import { openCalendarModal } from 'actions/filterCalendarUiAction';
import PbiFilter from './PbiFilter';
import { setTimeframe, getEmptyState } from 'actions/reportFilterActions';
import { screenWidthSelector } from 'reducers/appUserReducer';
import {
  reportCurrentStateIsDoneSelector,
  reportsFilterDataIsDoneSelector,
  reportsFilterDataIsSiteExtensionIsLoadedSelector,
} from 'reducers/apiStateReducer';
import { filterCalenderModalSelector } from 'reducers/ui';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { activeReportSelector, filterDataSelector } from 'reducers/reports/filterDataReducer';
import {
  filterStateBySystemGroup,
  isStateInitializedBySystemGroup,
  isTimeFrameWasChangedFromJobAnalysisPageSelector,
  selectedDateSelector,
} from 'reducers/reports/filterStateReducer';
import { dataUserSettingsSelector } from 'reducers/userSettingsReducer';
import { systemAnalysisNewNickNameSelector } from 'reducers/systemAnalysisReducer';
const mapStateToProps = (state: Store) => {
  return {
    isTimeFrameWasChangedFromJobAnalysisPage: isTimeFrameWasChangedFromJobAnalysisPageSelector(
      state
    ),
    isSiteExtensionIsLoaded: reportsFilterDataIsSiteExtensionIsLoadedSelector(state),
    filterDataLoaded: reportsFilterDataIsDoneSelector(state),
    filterStateLoaded: reportCurrentStateIsDoneSelector(state),
    filterCalenderModal: filterCalenderModalSelector(state),
    selectedSystemGroup: systemGroupSelector(state),
    filterData: filterDataSelector(state),
    activeReport: activeReportSelector(state),
    filterState: filterStateBySystemGroup(state),
    stateInitialized: isStateInitializedBySystemGroup(state),
    day: selectedDateSelector(state),
    userSettings: dataUserSettingsSelector(state),
    newNickName: systemAnalysisNewNickNameSelector(state),
    screenWidth: screenWidthSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInitFilterState,
      getReportsFilterDates,
      getReportsFilterSites,
      getReportsCurrentState,
      setFilterFromUriQuery,
      setFilterSystemsState,
      openCalendarModal,
      applyMobileFilters,
      getDefaultFilterQueries,
      setTimeframe,
      getEmptyState,
    },
    dispatch
  );

export type PbiFilterProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PbiFilter));
