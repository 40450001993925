import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  Tooltip,
  IconButton,
  Typography,
  Button,
  createMuiTheme,
  Tabs,
  Tab,
  Select,
  MenuItem,
} from '@material-ui/core';
import { appearanceEffect } from 'globalStyle';
import { SpinnerProps } from 'components/_common/Spinner';

interface IStyledSystemsAnalysisCardProps {
  statusColor: string;
  canClick?: boolean;
}
export const MobileReportWrapperSystemAnalysis = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation-name: ${appearanceEffect};
  animation-duration: 500ms;
`;

export const StatusButtonWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #c5d0de;
  padding: 0px 15px;
  margin-bottom: 10px;
`;
export const StatusWrapper = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #354052;
`;
export const SortingButton = styled.div`
  display: flex;
  width: 40px;
  justify-content: space-between;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #354052;
`;
export const MobileContentWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #c5d0de;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  padding: 11px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledSystemsAnalysisRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledSystemsAnalysisTable = styled.div`
  position: relative;
  width: 100%;
`;

export const ReportWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  margin: 20px;
  padding-bottom: 80px;
  border: 1px solid #e6eaee;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  //overflow: hidden;
  @media only screen and (max-width: 1480px) {
    display: none;
  }
`;

// CARD
export const StyledSystemsAnalysisCard = styled.div(
  (props: IStyledSystemsAnalysisCardProps) => `
    ${props.canClick && 'cursor: pointer;'}
    background-color: ${props.statusColor};
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    overflow: hidden;
    border: 1px solid #e6eaee;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: ${props.statusColor};
      height: 8px;
    }
    &.selected,
    &.focus,
    &:hover {
      background: #f2f9ff;
    }
    cursor: pointer;
  `
);

export const StyledSystemsAnalysisCardHeader = styled.div(props => {
  return css`
    position: relative;
    height: 55px;
    border-bottom: 1px solid #e6eaee;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
  `;
});

export const StyledSystemsAnalysisCardHeaderLeftPart = styled.div(props => {
  return css`
    position: relative;
    //width: 50%;
    display: flex;
    justify-content: flex-start;
  `;
});

export const StyledSystemsAnalysisCardHeaderRightPart = styled.div`
  position: relative;
  //width: 50%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledSystemsAnalysisCardBody = styled.div`
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

export const StyledSystemsAnalysisCardBodyLeftPart = styled.div`
  width: 73px;
  height: 55px;
  position: relative;
`;

export const StyledSystemsAnalysisCardBodyRightPart = styled.div`
  position: relative;
`;

export const StyledSystemsAnalysisCardBodyMidPart = styled.div`
  position: relative;
  display: flex;
`;

export const StyledSystemsAnalysisCardFooter = styled.div`
  position: relative;
  display: flex;
  border-top: 1px solid #e6eaee;
  height: 67px;
`;

export const StyledSystemsAnalysisCardFooterLeftPart = styled.div`
  position: relative;
  border-right: 1px solid #e6eaee;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: center;
`;

export const StyledSystemsAnalysisCardFooterRightPart = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const StyledToolTip = styled(props => (
  <Tooltip classes={{ popper: props.className, tooltip: 'ToolTip', arrow: 'Arrow' }} {...props} />
))`
  .Arrow {
  }
  .ToolTip {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
    &:before {
      box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.6);
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -5px;
  right: -5px;
  &.Root {
    padding: 0;
  }
`;

export const StyledNamePrinter = styled(Typography)`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #354052;
`;

export const StyledCardMedia = styled.img`
  width: 73px;
  height: 55px;
`;

export const StyledSystemsAnalysisCardBodyInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledSystemsAnalysisCardBodyInfoTitleWrap = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

export const StyledSystemsAnalysisCardBodyInfoTitle = styled(Typography)`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #354052;
`;

export const StyledSystemsAnalysisCardBodyInfoNumbersWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const StyledSystemsAnalysisCardBodyInfoNumberBig = styled(Typography)`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #354052;
`;

export const StyledSystemsAnalysisCardBodyInfoNumberSlash = styled.span`
  font-size: 14px;
  color: #7f8fa4;
`;

export const StyledSystemsAnalysisCardBodyInfoNumberSmall = styled(Typography)`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #7f8fa4;
`;

export const StyledButton = styled(Button)`
  border: 1px solid #1bb934;
  border-radius: 4px;
  color: #1bb934;
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
`;

export const StyledSystemsAnalysisCardFooterNumbersWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

export const StyledSystemsAnalysisCardFooterNumber = styled(Typography)`
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #354052;
  margin-right: 4px;
`;

export const StyledSystemsAnalysisCardFooterSubTitleWrap = styled.div`
  position: relative;
`;

export const StyledSystemsAnalysisCardFooterSubTitle = styled.div`
  position: relative;
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #7f8fa4;
`;

// TABS

export const StyledTabsIcon = styled(Tabs)(props => {
  return css`
    min-height: auto;
    width: 100%;
    .Indicator {
      display: none;
    }
  `;
});

export const StyledTabIcon = styled(Tab)(props => {
  return css`
    min-width: 50%;
    min-height: auto;
    padding: 2px;
    border-radius: 2px;
    overflow: hidden;
    color: #c5d0de;
    opacity: 1;
    &.Selected {
      color: white;
      background-color: #2ea1f8;
    }
  `;
});

export const StyledWrapperHeader = styled.div(props => {
  return css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #e6e8f1;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 25px;
  `;
});
export const StyledWrapperLoader = styled.div<SpinnerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  flex: 1;
`;

export const StyledWrapperEmptyData = styled.div(props => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  `;
});

export const StyledWrapperControls = styled.div(props => {
  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;
});

export const StyledWrapperTabPanels = styled.div`
  padding: 20px;
  height: -webkit-fill-available;
  display: flex;
  overflow: auto;
  flex-grow: 1;
  &::-webkit-scrollbar-track {
    border-left: 0;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7f8fa4;
    border-radius: 10px;
  }
`;

export const StyledViewButtonWrapper = styled.div(props => {
  return css`
    box-sizing: border-box;
    min-width: 93px;
    height: 36px;
    border: 1px solid #7f8fa4;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
});

export const StyledSystemAnalysisLastUpdate = styled.div`
  vertical-align: middle;
  color: #7f8fa4;
  font-size: 12px;
  line-height: 50px;
  /* margin-right: 10px; */
`;

export const StyledSystemsAnalysisFilter = styled(Select)(
  props => css`
    width: 20px;
    margin-right: 15px;
    font-size: 12px;
    svg {
      position: absolute;
      left: 10px;
      pointer-events: none;
      height: 25px;
    }
    div {
      padding-left: 40px;
      width: 100px;
    }
  `
);

export const StyledSystemsAnalysisFilterMobile = styled(Select)(
  props => css`
    width: 20px;
    margin-right: 15px;
    font-size: 12px;
    svg {
      position: absolute;
      left: 10px;
      pointer-events: none;
      height: 25px;
    }
    div {
      width: 100px;
    }
  `
);
export const StyledSystemsAnalysisFilterItem = styled(MenuItem).attrs({
  'data-automationid': 'SystemAnalysis-filter-filter-options',
})``;

export const tableTheme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: 'white',
        borderCollapse: 'separate',
        // padding: '15px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 10px',
        borderBottom: 'none',
      },
      head: {
        fontWeight: 600,
      },
    },
    MuiTableRow: {
      root: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        height: '90px !important',
        overflow: 'hidden',
        marginBottom: '8px',
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:last-child': {
          marginBottom: 0,
        },
        '&:hover .errorTicketButton': {
          opacity: 1,
        },
      },
      head: {
        display: 'flex',
        borderRadius: 0,
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: '#EFF3F6',
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
});

export const filterTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      root: {},
      select: {
        paddingLeft: '5px',
        paddingRight: '0 !important',
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          background: 'none',
          color: '#2EA1F8',
          '&:hover': {
            background: 'none',
            color: '#2EA1F8',
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#2EA1F8',
        },
      },
      gutters: {},
    },
  },
});
