import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Store } from 'store/storeTypes';
import PbiFilterYears from './PbiFilterYears';
import { setActiveDate } from 'actions/reportFilterActions';
import { activeReportSelector } from 'reducers/reports/filterDataReducer';
type OwnProps = {
  isMobile?: boolean;
  closeCalendarModal?: () => void;
};
const mapStateToProps = (state: Store) => {
  const { startDate, endDate } = state.reports.filterData[state.ui.selectedSystemGroup]!;
  const { selectedDate } = state.reports.filterState[state.ui.selectedSystemGroup]!;
  return {
    startDate,
    endDate,
    selectedDate,
    activeReport: activeReportSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ setActiveDate }, dispatch);

export type PbiFilterYearsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export default connect(mapStateToProps, mapDispatchToProps)(PbiFilterYears);
