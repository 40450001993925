import React, { useState } from 'react';
import styled from 'styled-components/macro';

import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

const FormControl = styled(MuiFormControl)`
  width: 100%;
`;

const FormLabel = styled(MuiFormLabel).attrs({
  classes: { focused: 'focused' },
})`
  font-family: Rubik, sans-serif;
  font-size: 12px;
  color: #7f8fa4;

  &.focused {
    color: #7f8fa4;
  }
`;

const RadioGroup = styled(MuiRadioGroup)`
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 599px) {
    justify-content: left;
  }
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  & > span:last-child {
    margin-top: 3px;
    color: #7f8fa4;
  }
  @media (max-width: 599px) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`;

const Radio = styled(MuiRadio).attrs({
  classes: { checked: 'checked' },
})`
  &.checked ~ span {
    color: #354052;
  }
`;

interface RadiosFieldProps {
  name: string;
  fieldLabel?: string;
  labels: string[];
  values?: string[];
  defaultValue?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
}

const RadiosField = React.memo((props: RadiosFieldProps) => {
  const { defaultValue = '', labels, values, fieldLabel = 'Choose one', name, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  return (
    <FormControl>
      <FormLabel>{fieldLabel}</FormLabel>
      <RadioGroup
        aria-label={fieldLabel}
        name={name}
        value={'1'}
        // value={value}
        // onChange={(e: React.ChangeEvent) => {
        //   const v = (e.target as HTMLInputElement).value;
        //   setValue(v);
        //   onChange && onChange(v);
        // }}
      >
        {labels.map((label, i) => (
          <FormControlLabel
            key={label + i}
            value={(values && values[i]) || String(i)}
            control={<Radio color="primary" />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});

export default RadiosField;
