import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import { ApiManagementProps } from './ApiManagementContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { openLinkInNewTab } from 'helpers/navigation';
import { API_PDF } from 'contracts/constants';

const StyledButton = styled(Button)`
  color: #ffffff;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr;
  background-color: #eff3f6;
`;

const Header = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 230px 180px 180px 1fr;
  border-bottom: 1px solid #c5d0de;
  background-color: #ffffff;
  justify-items: center;
  align-items: center;
`;

const HeaderTitle = styled.div`
  width: 100%;
  border-right: 1px solid #c5d0de;
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #354052;
  text-align: center;
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #c5d0de;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  display: grid;
  grid-template-rows: repeat(auto-fill, 60px);
  padding: 0 20px 0 20px;
`;

const Table = styled.div`
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #7f8fa4;
    border-radius: 10px;
  }
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 2fr 2fr 4fr;
  align-items: center;
  border-bottom: 1px solid #c5d0de;
  padding-left: 25px;
`;

const TableHeaderTitle = styled.div`
  font-weight: 500;
  color: #354052;
  font-size: 14px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 2fr 2fr 4fr;
  align-items: center;
  border-bottom: 1px solid #c5d0de;
  padding-left: 25px;
  height: 100%;
`;

const TableCell = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 25px;
`;

const ActionsCell = styled.div`
  max-width: 400px;
  display: grid;
  grid-template-columns: 30px auto auto;
`;

const StatusCell = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
`;

const AnimatedLoader = styled(Loader)`
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const ClickableText = styled.div`
  color: #2ea1f8;
  cursor: pointer;
`;

const AccountManagement = (props: ApiManagementProps) => {
  const {
    certificate,
    isLoading,
    getCertificateDetailsRequest,
    createCertificateRequest,
    deleteCertificateRequest,
    getCertificateFileRequest,
    refreshCertificateRequest,
  } = props;
  const isExpired = () => {
    const now = new Date();
    return !!certificate && certificate.expirationDate < now;
  };

  const generate = () => {
    if (!!certificate && isExpired) {
      refreshCertificateRequest();
    } else if (!certificate) {
      createCertificateRequest();
    }
  };
  useEffect(() => {
    getCertificateDetailsRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container>
      <Header>
        <HeaderTitle>API Management</HeaderTitle>
        <StyledButton
          onClick={generate}
          disabled={isLoading || (!!certificate && !isExpired())}
          startIcon={<AddIcon />}
          color="primary"
          variant="contained">
          Generate
        </StyledButton>
        <StyledButton
          onClick={() => deleteCertificateRequest()}
          disabled={isLoading || !certificate}
          startIcon={<DeleteIcon />}
          color="secondary"
          variant="contained">
          Delete
        </StyledButton>
      </Header>
      <div style={{ margin: '15px' }}>
        <Card>
          <TableHeader>
            <TableHeaderTitle style={{ cursor: 'pointer' }}>
              Name
              <TableSortLabel></TableSortLabel>
            </TableHeaderTitle>
            <TableHeaderTitle style={{ cursor: 'pointer' }}>Thumbprint</TableHeaderTitle>
            <TableHeaderTitle style={{ cursor: 'pointer' }}>Status</TableHeaderTitle>
            <TableHeaderTitle>Expiration Date</TableHeaderTitle>
            <TableHeaderTitle>Actions</TableHeaderTitle>
          </TableHeader>
          <Table>
            {!!certificate && (
              <TableRow>
                <TableCell>{certificate.email}</TableCell>
                <TableCell title={certificate.thumbprint}>{certificate.thumbprint}</TableCell>
                {!isLoading && !isExpired() && (
                  <StatusCell>
                    <Success></Success>
                    <span style={{ color: '#1BB934' }}>Approved</span>
                  </StatusCell>
                )}
                {!isLoading && isExpired() && (
                  <StatusCell>
                    <Warning></Warning>
                    <span style={{ color: '#FF4081' }}>Expired</span>
                  </StatusCell>
                )}
                {isLoading && (
                  <StatusCell>
                    <AnimatedLoader></AnimatedLoader>
                    <span style={{ color: '#FFAB2B' }}>Waiting</span>
                  </StatusCell>
                )}
                <TableCell>{certificate.expirationDate.toLocaleString()}</TableCell>
                {!isExpired() && !isLoading && (
                  <ActionsCell>
                    <Download></Download>
                    <ClickableText onClick={() => getCertificateFileRequest()}>
                      Download certificate
                    </ClickableText>
                    <ClickableText onClick={() => openLinkInNewTab(API_PDF)}>
                      API documentation
                    </ClickableText>
                  </ActionsCell>
                )}
              </TableRow>
            )}
          </Table>
        </Card>
      </div>
    </Container>
  );
};

export default AccountManagement;
