import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';

const { toggleUserTableRow, toggleUserTableAll } = actions.userUi;

export default createReducer<string[], RootAction>([])
  .handleAction(toggleUserTableRow, (state, action) => toggleUserTableRowRF(state, action.payload))
  .handleAction(toggleUserTableAll, (state, action) => toggleUserTableAllRF(state, action.payload));

function toggleUserTableRowRF(state: string[], id: string): string[] {
  let selectedUserRows: string[] = [];
  if (state.includes(id)) {
    selectedUserRows = state.filter(e => e !== id);
  } else {
    selectedUserRows = [...state];
    selectedUserRows.push(id);
  }

  return selectedUserRows;
}

function toggleUserTableAllRF(state: string[], ids: string[]): string[] {
  let selectedUserRows: string[] = [];
  if (state.length !== ids.length) {
    selectedUserRows = [...ids];
  }

  return selectedUserRows;
}
