import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { Scheduler, Frequency } from '../../models/Scheduler';

const INIT_STATE: Scheduler = {
  reportId: '',
  subject: '',
  userId: '',
  emailReportId: 0,
  emailReportName: '',
  startSendDate: new Date(),
  endSendDate: new Date(),
  repeat: { frequency: Frequency.Weekly },
  lastSent: new Date(),
  notes: '',
  isActive: false,
  customerIds: [],
  siteIds: [],
  systemIds: [],
};

const { openModalSchedulerPreviewAC } = actions.schedulerUi;

const { saveSchedulerSuccess } = actions.schedulerApi;

export default createReducer<Scheduler, RootAction>(INIT_STATE)
  .handleAction(openModalSchedulerPreviewAC, (state, action) => action.payload)
  .handleAction(saveSchedulerSuccess, (state, action) =>
    toggleSchedulerPreview(state, action.payload)
  );

function toggleSchedulerPreview(state: Scheduler, payload: Scheduler): Scheduler {
  if (state.reportId === payload.reportId) {
    return payload;
  }

  return state;
}
