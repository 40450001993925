import { TreeNodeProps } from 'react-dropdown-tree-select';
import { SchedulerForm, Entries, Ids, Entity, AppUser } from 'store/storeTypes';
import { Scheduler, Frequency, Weekdays } from 'models/Scheduler';
import { CustomerData } from 'models/CustomerData';
import { SiteData } from 'models/SiteData';
import { SystemData } from 'models/SystemData';
import { Scope } from 'models/Scope';
import { userDataToTree } from './userScopeTreeConversions';
import ScopeData from 'models/ScopeData';

export function schedulerFormToApi(
  schedulerForm: SchedulerForm,
  userId: string,
  appUser: AppUser
): Scheduler {
  const userCustomerIds = appUser.customerIds;
  const userSiteIds = appUser.siteIds;
  const userSystemIds = appUser.systemIds;
  const { reportTypes } = appUser;

  const {
    reportId,
    emailSubject,
    report,
    startDate,
    endDate,
    weekdays,
    notes,
    sending,
    customerIds,
    siteIds,
    systemIds,
    lastSent,
  } = schedulerForm;
  //this is previous functionality which enable to user to choose smth
  // return {
  //   reportId: reportId || '0',
  //   subject: emailSubject,
  //   userId,
  //   emailReportId: (report as number) + 1,
  //   emailReportName: reportTypes[report as number],
  //   startSendDate: startDate,
  //   endSendDate: endDate,
  //   repeat: {
  //     frequency: Frequency.Weekly, // repeat
  //     ...mapDaysOfTheWeekToSchedulerFrequency(weekdays),
  //   },
  //   notes,
  //   isActive: sending,
  //   ...setScopeForUser(
  //     customerIds,
  //     siteIds,
  //     systemIds,
  //     userCustomerIds!,
  //     userSiteIds!,
  //     userSystemIds!
  //   ),
  //   lastSent,
  // };
  //this is predefault funct user not allowed to choose

  return {
    reportId: reportId || '0',
    subject: emailSubject,
    userId,
    emailReportId: (report as number) + 1,
    emailReportName: reportTypes[report as number],
    startSendDate: startDate,
    endSendDate: endDate,
    repeat: {
      frequency: Frequency.Weekly, // repeat
      ...mapDaysOfTheWeekToSchedulerFrequency([Weekdays.Tuesday]),
    },
    notes,
    isActive: sending,
    customerIds: [],
    siteIds: [],
    systemIds: [],
    lastSent,
  };
}

function setScopeForUser(
  customerIds: Ids,
  siteIds: Ids,
  systemIds: Ids,
  userCustomerIds: Ids,
  userSiteIds: Ids,
  userSystemIds: Ids
): Scope {
  if (customerIds.length === 0 && siteIds.length === 0 && systemIds.length === 0) {
    return {
      customerIds: userCustomerIds,
      siteIds: userSiteIds,
      systemIds: userSystemIds,
    };
  }

  return {
    customerIds,
    siteIds,
    systemIds,
  };
}

const mapDaysOfTheWeekToSchedulerFrequency = (weekdays: Weekdays[]) => {
  let isSendOnMonday = false;
  let isSendOnTuesday = false;
  let isSendOnWednesday = false;
  let isSendOnThursday = false;
  let isSendOnFriday = false;
  let isSendOnSaturday = false;
  let isSendOnSunday = false;

  weekdays.forEach((day: Weekdays) => {
    switch (day) {
      case Weekdays.Monday:
        isSendOnMonday = true;
        break;
      case Weekdays.Tuesday:
        isSendOnTuesday = true;
        break;
      case Weekdays.Wednesday:
        isSendOnWednesday = true;
        break;
      case Weekdays.Thursday:
        isSendOnThursday = true;
        break;
      case Weekdays.Friday:
        isSendOnFriday = true;
        break;
      case Weekdays.Saturday:
        isSendOnSaturday = true;
        break;
      case Weekdays.Sunday:
        isSendOnSunday = true;
        break;
    }
  });

  return {
    isSendOnSunday,
    isSendOnMonday,
    isSendOnTuesday,
    isSendOnWednesday,
    isSendOnThursday,
    isSendOnFriday,
    isSendOnSaturday,
  };
};

export const toggleScheduler = (
  id: string,
  schedulers: Entries<Scheduler>,
  sending: boolean
): Scheduler => {
  const schedulerReport = schedulers[id] as Scheduler;
  const { emailReportId } = schedulerReport;
  return {
    ...schedulerReport,
    isActive: sending,
    emailReportId: emailReportId + 1,
  };
};

export const getSchedulerWeekdays = (scheduler: Scheduler): Weekdays[] => {
  const {
    isSendOnSunday,
    isSendOnMonday,
    isSendOnTuesday,
    isSendOnWednesday,
    isSendOnThursday,
    isSendOnFriday,
    isSendOnSaturday,
  } = scheduler.repeat;

  const weekdays: Weekdays[] = [];

  if (isSendOnMonday) {
    weekdays.push(Weekdays.Monday);
  }
  if (isSendOnThursday) {
    weekdays.push(Weekdays.Thursday);
  }
  if (isSendOnWednesday) {
    weekdays.push(Weekdays.Wednesday);
  }
  if (isSendOnTuesday) {
    weekdays.push(Weekdays.Tuesday);
  }
  if (isSendOnFriday) {
    weekdays.push(Weekdays.Friday);
  }
  if (isSendOnSaturday) {
    weekdays.push(Weekdays.Saturday);
  }
  if (isSendOnSunday) {
    weekdays.push(Weekdays.Sunday);
  }

  return weekdays;
};

export const schedulerApiToSchedulerForm = (
  scheduler: Scheduler,
  customers: Entity<CustomerData>,
  sites: Entity<SiteData>,
  systems: Entity<SystemData>
): SchedulerForm => {
  const {
    reportId,
    subject,
    emailReportId,
    startSendDate,
    endSendDate,
    repeat,
    notes,
    isActive,
    customerIds,
    siteIds,
    systemIds,
    lastSent,
  } = scheduler;

  const systemsTree = userDataToTree(customers, sites, systems, customerIds, siteIds, systemIds);

  return {
    reportId: reportId!,
    emailSubject: subject,
    report: emailReportId,
    startDate: startSendDate,
    endDate: endSendDate,
    repeat: 0, // repeat.Frequency,
    weekdays: repeat.dayOfWeek!,
    notes,
    sending: isActive,
    customerIds,
    siteIds,
    systemIds,
    isFormTouched: true,
    systemsTree,
    lastSent,
  };
};

export function treeCheckedNodesToSchedulerData(
  nodes: TreeNodeProps[],
  prevOrgIds: Ids = [],
  prevSiteIds: Ids = [],
  userCustomerIds: Ids,
  userSiteIds: Ids,
  userSystemsIds: Ids,
  customers: Entity<CustomerData>,
  sites: Entity<SiteData>,
  systems: Entity<SystemData>
): {
  customerDataIds: Ids;
  siteDataIds: Ids;
  systemDataIds: Ids;
} {
  const customerDataIds: Ids = [];
  const siteDataIds: Ids = [];
  const systemDataIds: Ids = [];
  const currentNodes: TreeNodeProps[] = [];

  let isAllDataAccessible = false;
  if (userCustomerIds!.length === 0 && userSiteIds!.length === 0 && userSystemsIds!.length === 0) {
    isAllDataAccessible = true;
  }
  // Customers
  nodes.forEach((node: TreeNodeProps) => {
    if (
      // Check is node a Customer
      !node.rootId &&
      !node.parentId
    ) {
      // Check user permission about that nodes
      if (isAllDataAccessible || userCustomerIds.includes(node.value)) {
        customerDataIds.push(node.value);
      } else {
        sites.allIds.forEach(site => {
          const currentSite = sites.byId[site];
          if (currentSite.customerId === node.value) {
            currentNodes.push({
              label: currentSite.name,
              value: currentSite.id,
              rootId: node.value,
            });
          }
        });
      }
    }
  });

  nodes = nodes.concat(currentNodes);

  // Sites
  nodes.forEach((node: TreeNodeProps) => {
    if (
      // Check is node a site
      node.rootId &&
      !node.parentId &&
      // If parent is selected - skip
      !customerDataIds.includes(node.rootId) &&
      // If parent JUST unselected - skip
      !prevOrgIds.includes(node.rootId)
    ) {
      // Check user permission about that nodes
      if (isAllDataAccessible || userSiteIds.includes(node.value)) {
        siteDataIds.push(node.value);
      } else {
        const currentSite = sites.byId[node.value];
        if (userCustomerIds.includes(currentSite.customerId)) {
          siteDataIds.push(node.value);
        }

        systems.allIds.forEach(system => {
          const currentSystem = systems.byId[system];
          if (currentSystem.siteId === node.value) {
            currentNodes.push({
              label: currentSystem.serialNumber,
              value: currentSystem.id,
              parentId: node.value,
            });
          }
        });
      }
    }
  });

  nodes = nodes.concat(currentNodes);

  // Systems
  nodes.forEach((node: TreeNodeProps) => {
    if (
      node.parentId &&
      // If ancestor is selected - skip
      !customerDataIds.includes(node.rootId) &&
      !siteDataIds.includes(node.parentId) &&
      // If ancestor JUST unselected - skip
      !prevOrgIds.includes(node.rootId) &&
      !prevSiteIds.includes(node.parentId)
    ) {
      systemDataIds.push(node.value);
    }
  });

  return { customerDataIds, siteDataIds, systemDataIds };
}

/**
 * Following block makes Scope Sub-Tree to allow user to choose not from full scope, but
 * only from granted to him part of the scope.
 */

export function appUserDataScopeData(
  customerIds: Ids,
  siteIds: Ids,
  systemIds: Ids,
  customers: Entity<CustomerData>,
  sites: Entity<SiteData>,
  systems: Entity<SystemData>
): ScopeData {
  let scope = {};
  if (customerIds!.length === 0 && siteIds!.length === 0 && systemIds!.length === 0) {
    scope = {
      customers,
      sites,
      systems,
    };

    return scope as ScopeData;
  }
  const filteredOrgIds = [...customerIds];
  const filteredSiteIds = [...siteIds];
  const filteredSysIds = [...systemIds];

  const disabledOrgIds: Ids = [];
  const disabledSiteIds: Ids = [];

  const siteArray: SiteData[] = Object.values(sites.byId);
  const sysArray: SystemData[] = Object.values(systems.byId);

  siteArray.forEach(site => {
    const siteIsInUserScope = siteIds.indexOf(site.id) > -1;
    const parentOrgIsNotInScope = filteredOrgIds.indexOf(site.customerId) === -1;

    if (siteIsInUserScope && parentOrgIsNotInScope) {
      filteredOrgIds.push(site.customerId); // add this org to subtree
      disabledOrgIds.push(site.customerId); // and render always disabled
    }

    const siteIsNotInScope = filteredSiteIds.indexOf(site.id) === -1;
    const parentIsInScope = customerIds.indexOf(site.customerId) > -1;

    if (siteIsNotInScope && parentIsInScope) {
      filteredSiteIds.push(site.id); // add this site to subtree
    }
  });

  sysArray.forEach(sys => {
    const sysIsInUserScope = systemIds.indexOf(sys.id) > -1;
    const parentOrgIsNotInScope = filteredOrgIds.indexOf(sys.customerId) === -1;
    const parentSiteIsNotInScope = filteredSiteIds.indexOf(sys.siteId) === -1;

    if (sysIsInUserScope) {
      if (parentOrgIsNotInScope) {
        filteredOrgIds.push(sys.customerId);
        disabledOrgIds.push(sys.customerId);
      }
      if (parentSiteIsNotInScope) {
        filteredSiteIds.push(sys.siteId);
        disabledSiteIds.push(sys.siteId);
      }
    }

    const sysIsNotInScope = filteredSysIds.indexOf(sys.id) === -1;
    const parentIsInScope =
      siteIds.indexOf(sys.siteId) > -1 || customerIds.indexOf(sys.customerId) > -1;

    if (sysIsNotInScope && parentIsInScope) {
      filteredSysIds.push(sys.id);
    }
  });
  const userOrgs = _filterEntityStore(customers, filteredOrgIds);
  const userSites = _filterEntityStore(sites, filteredSiteIds);
  const userSystems = _filterEntityStore(systems, filteredSysIds);

  scope = {
    customers: userOrgs,
    sites: userSites,
    systems: userSystems,
  };

  return scope as ScopeData;
}

function _filterEntityStore<T>(entity: Entity<T>, ids: Ids): Entity<T> {
  const result: Entity<T> = { byId: {}, allIds: [] };

  ids.forEach(id => {
    result.byId[id] = entity.byId[id];
    result.allIds.push(id);
  });

  return result;
}
