import { Permission, Permissions, PermissionAuth0 } from '../models/Permissions';
import {
  UserSystemGroup,
  RTRMetricUnit,
  UserSettings,
  UserSystemGroupStrings,
  TimeModificator,
} from 'store/storeTypes';
import _ from 'lodash';

export function userRolesToPermissions(roles: string[]) {
  return roles
    .map(r => {
      switch (r) {
        case PermissionAuth0.ReadOnly:
          return Permission.ReadOnly;
        case PermissionAuth0.Admin:
          return Permission.Admin;
        case PermissionAuth0.Scheduler:
          return Permission.CreateReports;
        case PermissionAuth0.GoalsSetter:
          return Permission.SetGoals;
        case PermissionAuth0.ManageApi:
          return Permission.ManageApi;
        case PermissionAuth0.Certificate:
          return Permission.Certificate;
        default:
          return null;
      }
    })
    .filter(p => !!p) as Permissions;
}

export function userPermissionsToRoles(permissions: Permissions) {
  return permissions
    .map(p => {
      switch (p) {
        case Permission.ReadOnly:
          return PermissionAuth0.ReadOnly;
        case Permission.Admin:
          return PermissionAuth0.Admin;
        case Permission.SetGoals:
          return PermissionAuth0.GoalsSetter;
        case Permission.CreateReports:
          return PermissionAuth0.Scheduler;
        case Permission.ManageApi:
          return PermissionAuth0.ManageApi;
        case Permission.Certificate:
          return PermissionAuth0.Certificate;
        default:
          return null;
      }
    })
    .filter(r => !!r);
}

export const determineUnitToShow = (
  selectedSystemGroup: UserSystemGroupStrings,
  userSettings: UserSettings,
  translationFunction: (str: string) => string
) => {
  let string = '';
  if (selectedSystemGroup === UserSystemGroup.DTG || selectedSystemGroup === UserSystemGroup.Apollo)
    return string.concat(translationFunction('goals.imp'));
  else {
    if (userSettings.lengthUnits === 'Meter')
      string = string.concat(translationFunction('goals.meter'));
    else if (userSettings.lengthUnits === 'Feet')
      string = string.concat(translationFunction('goals.feet'));
    else if (userSettings.lengthUnits === 'Yard')
      string = string.concat(translationFunction('goals.yard'));
    if (userSettings.rtrMetricUnit === RTRMetricUnit.Square)
      string = string.concat(translationFunction('goals.square'));
  }
  return string;
};

export const numberFormatFromString = (number: string) =>
  parseFloat(parseFloat(number).toFixed(2)).toLocaleString();
export const numberFormat = (number: number) => {
  if (isNaN(number)) {
    //*NaN case should not occures by contract but  there was case when we got Nan here - so it is decided to add handling exact for this case
    return '-';
  }
  if (!number) {
    return '0';
  }
  return parseFloat(number.toFixed(2)).toLocaleString();
};

export const formatFloatToPercentage = (number: number, fraction = 2) => {
  return (number * 100).toFixed(fraction);
};

export const notUndefiend = (arrayOfValues: (number | Date | undefined)[]): boolean => {
  return !arrayOfValues.some((value: number | Date | undefined) => value === undefined);
};
export const mergeCopyArrays = (
  objValue: Record<string, unknown> | Array<Record<string, unknown>>,
  srcValue: Record<string, unknown> | Array<Record<string, unknown>>
) => {
  if (_.isArray(objValue)) {
    return srcValue;
  }
};
export const numberFormatterNew = (
  num: number | undefined,
  units = '',
  digitsNumber = 4
): string => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  if (num === undefined || num === null || isNaN(num)) {
    return '';
  }
  num = Math.floor(+num) !== +num ? +(+num).toFixed(2) : +num;
  if (num < 1000) {
    return `${num} ${units}`;
  }
  if (num < 10000) {
    const hundreds = parseFloat((num % 1000).toFixed(2));
    let hundredsString = '';
    if (hundreds < 10) {
      hundredsString = '00' + hundreds;
    } else if (hundreds < 100) {
      hundredsString = '0' + hundreds;
    } else {
      hundredsString = '' + hundreds;
    }
    return `${Math.floor(num / 1000)},${hundredsString} ${units}`;
  }
  const numLenght = num.toFixed(0).toString().length;
  const digitsToCut = Math.floor((numLenght - 1) / 3) * 3;
  const fraction = Math.abs(numLenght - digitsToCut - digitsNumber);
  const digitsSuffixId = Math.floor((numLenght - 1) / 3);
  return (
    (num / Math.pow(10, digitsToCut)).toFixed(fraction) + si[digitsSuffixId].symbol + ' ' + units
  );
};
export const cutAllValueAfrerDot = value => {
  const formattedString = numberFormatterNew(value).trim();
  const regex = /^(\d+\.\d+)k$/;
  const match = formattedString.match(regex);
  if (match) {
    const roundedNumber = Math.floor(parseFloat(match[1])) + 'k';
    return roundedNumber;
  } else {
    return formattedString;
  }
};
export const dateToTimeString = (value: Date) => {
  let time = '';
  const mins = value.getMinutes() + '';
  const seconds = value.getSeconds() + '';
  const hours = value.getHours() + '';

  time = time.concat(hours.length < 2 ? '0' + hours : hours).concat(':');

  time = time.concat(mins.length < 2 ? '0' + mins : mins).concat(':');

  time = time.concat(seconds.length < 2 ? '0' + seconds : seconds);

  return time;
};

export const formatStringToPercentage = (number: string, multiplier = 100, fraction = 2) => {
  if (!number) {
    return '0 %';
  }
  if (isNaN(parseFloat(number))) {
    return '- -';
  }

  return (parseFloat(number) * multiplier).toFixed(fraction) + '%';
};

export const formatSecToHourString = (duration: number) => {
  if (duration === undefined) {
    return '';
  }
  let time = '';
  const hours = Math.floor(duration / 3600) + '';
  const mins = Math.floor((duration % 3600) / 60) + '';
  const seconds = Math.floor(duration % 60) + '';
  time = time.concat(hours.length < 2 ? '0' + hours : hours).concat(':');
  time = time.concat(mins.length < 2 ? '0' + mins : mins).concat(':');
  time = time.concat(seconds.length < 2 ? '0' + seconds : seconds);

  return time;
};

export const formatSecToMinString = (duration: number) => {
  let time = '';
  const mins = Math.floor(duration / 60) + '';
  const seconds = Math.floor(duration % 60) + '';
  time = time.concat(mins.length < 2 ? '0' + mins : mins).concat(':');
  time = time.concat(seconds.length < 2 ? '0' + seconds : seconds);

  return time;
};

export const valueAsString = (value: number | Date | undefined, isPercentage?: boolean) => {
  if (typeof value === 'string') return value;
  if (value instanceof Date) {
    return dateToTimeString(value);
  } else if (isPercentage) {
    return formatFloatToPercentage(value!);
  } else {
    return numberFormatterNew(value!);
  }
};
export const addZeroToNumber = (number: number) => {
  const numberAsString = number.toString();
  if (numberAsString.length >= 2) return numberAsString;
  return '0' + number;
};

export const minutesToDaysHoursMinutes = (minutes: number) => {
  if (minutes > 0) {
    const days = Math.floor(minutes / 60 / 24);
    const hours = Math.floor(minutes / 60 - days * 24);
    const minutesResiduals = Math.floor(minutes - days * 24 * 60 - hours * 60);
    return {
      days: addZeroToNumber(days),
      hours: addZeroToNumber(hours),
      minutes: addZeroToNumber(minutesResiduals),
    };
  }
  return { days: '00', hours: '00', minutes: '00' };
};
export const secondsToDaysHoursMinutes = (seconds: number, noZeroPrefix?: boolean) => {
  if (seconds > 0) {
    const days = Math.floor(seconds / 60 / 60 / 24);
    const hours = Math.floor((seconds - days * 24 * 60 * 60) / 60 / 60);
    const minutesResiduals = Math.floor((seconds - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
    return {
      days: noZeroPrefix ? String(days) : addZeroToNumber(days),
      hours: noZeroPrefix ? String(hours) : addZeroToNumber(hours),
      minutes: noZeroPrefix ? String(minutesResiduals) : addZeroToNumber(minutesResiduals),
    };
  }
  return { days: '00', hours: '00', minutes: '00' };
};
export const convertSecondsToHours = seconds => {
  if (!seconds) return '0';
  return `${Math.floor(seconds / 3600)}`;
};

export const lastUpdateToNewFormat = lastUpdate => {
  if (!lastUpdate) return '- -';
  const [date, time] = lastUpdate.split('T');
  const [year, month, day] = date.split('-');
  const [hour, minutes] = time.split(':');
  return `${day}/${month}/${year} ${hour}:${minutes}`;
};
type RoundForTotalProductionGraphType = { value: number; maxValue: number };
export const roundForTotalProductionGraph = (props: RoundForTotalProductionGraphType): string => {
  const { maxValue, value } = props;
  if (maxValue < 1000) {
    return value.toFixed(2);
  } else {
    return `${(value / 1000).toFixed(1)}k`;
  }
};
export const transformTime = timeString => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':');

  // Create a new Date object with the current date and the given hours and minutes
  const dateObj = new Date();
  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);

  // Format the date object using the toLocaleString() method
  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
  });

  return formattedTime;
};

export const convertTo24HourFormat = (hour: number, amPm: TimeModificator): number => {
  if (hour === 12 && amPm === 'AM') {
    // special case for midnight (12:00 AM)
    return 0;
  } else if (hour === 12 && amPm === 'PM') {
    // special case for noon (12:00 PM)
    return 12;
  } else if (amPm === 'PM') {
    // for PM hours (after noon), add 12 to the hour
    return hour + 12;
  } else {
    // for AM hours (before noon), return the hour as-is
    return hour;
  }
};

export const convertTo12HourFormat = (hour: number): string => {
  if (hour === 0) {
    // special case for midnight (12:00 AM)
    return '12:00 AM';
  } else if (hour === 12) {
    // special case for noon (12:00 PM)
    return '12:00 PM';
  } else if (hour < 12) {
    // for AM hours (before noon), return the hour and minutes with 'AM'
    return `${hour.toString().padStart(2, '0')}:00 AM`;
  } else {
    // for PM hours (after noon), subtract 12 from the hour and return the hour and minutes with 'PM'
    const pmHour = (hour - 12).toString().padStart(2, '0');
    return `${pmHour}:00 PM`;
  }
};
