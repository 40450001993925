import { SubReportTypes } from 'models/ReportData';
import React from 'react';
import styled from 'styled-components';
import { PbiFilterSystemsProps } from './PbiFilterSystemsContainer';
import { ReactComponent as FunnelIcon } from '../../assets/icons/FunnelIcon.svg';
import { ReactComponent as FunnelDot } from '../../assets/icons/FilterDot.svg';
import { ReactComponent as BackArrow } from '../../assets/icons/BackArrow.svg';
import { globalColors } from 'globalStyle';

const BackArrowWrapper = styled.div`
  min-width: 50px;
  min-height: 40px;
  max-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ececec;
  }
`;

const FunnelDotContainer = styled.div`
  position: absolute;
  top: -2px;
  left: 15px;
`;
const SystemsFilterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 69px;
  height: 49px;
  margin-bottom: 4px;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 750px) {
    margin-left: 0;
  }
`;
const FunnelContainer = styled.div`
  position: relative;
  height: 22px;
`;
{
  /* desktop version of filter menu is in Slider component */
}
const VerticalDevider = styled.div`
  display: flex;
  width: 1px;
  height: 26px;
  margin-left: 10px;
  background-color: ${globalColors.regular.background.greyDevider};
`;
const PbiFilterSystemsDesktop = React.memo(
  (
    props: PbiFilterSystemsProps & {
      filterNotEmpty: boolean;
      funnelColor: string;
      onFunnelClickHandler: () => void;
    }
  ) => {
    const { activeReport, funnelColor, filterNotEmpty, history, onFunnelClickHandler } = props;
    const isSubReport =
      activeReport === SubReportTypes.SystemPage ||
      activeReport === SubReportTypes.ServiceTicket ||
      activeReport === SubReportTypes.MachineConfig;
    return (
      <SystemsFilterContainer>
        {!isSubReport ? (
          <FunnelContainer data-automationid={`filter_systems_Btn`} onClick={onFunnelClickHandler}>
            <FunnelIcon fill={funnelColor} />
            {filterNotEmpty && (
              <FunnelDotContainer>
                <FunnelDot />
              </FunnelDotContainer>
            )}
          </FunnelContainer>
        ) : (
          <BackArrowWrapper onClick={() => history.goBack()}>
            <BackArrow />
          </BackArrowWrapper>
        )}
        <VerticalDevider></VerticalDevider>
      </SystemsFilterContainer>
    );
  }
);

export default PbiFilterSystemsDesktop;
