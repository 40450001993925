import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SchedulerPreview from './SchedulerPreview';
import { Store, MODAL_STATUS } from 'store/storeTypes';
import {
  closeModalSchedulerPreview,
  changeSchedulerFormValue,
  openModalSchedulerEdit,
} from 'actions/schedulerUiActions';

import { toggleSchedulerSending, deleteScheduler } from 'actions/schedulerApiActions';

const mapStateToProps = (state: Store) => ({
  isOpen: state.ui.schedulerModal.previewStatus !== MODAL_STATUS.CLOSED,
  scheduler: state.ui.schedulerPreview,
  isSaving: state.api.isSavingScheduler,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModalSchedulerPreview,
      changeSchedulerFormValue,
      openModalSchedulerEdit,
      toggleSchedulerSending,
      deleteScheduler,
    },
    dispatch
  );

export type SchedulerPreviewProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerPreview);
