import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { createStandardAction as crAction } from 'typesafe-actions';

import { Store, AppUser, MODAL_STATUS, Ids } from '../store/storeTypes';
import { Scheduler, Frequency } from '../models/Scheduler';
import ScopeData from '../models/ScopeData';
import { scopeSelector } from '../selectors/entitiesSelectors';

interface OpenModalSchedulerCreatePayload extends ScopeData {
  appUser: AppUser;
}
export const openModalSchedulerCreateAC = crAction('OPEN_MODAL_SCHEDULER_CREATE')<
  OpenModalSchedulerCreatePayload
>();
export function openModalSchedulerCreate() {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();

    dispatch(
      openModalSchedulerCreateAC({
        appUser: state.appUser,
        ...scopeSelector(state),
      })
    );
  };
}

interface OpenModalSchedulerEditPayload extends ScopeData {
  scheduler: Scheduler;
  appUser: AppUser;
}
export const openModalSchedulerEditAC = crAction('OPEN_MODAL_SCHEDULER_EDIT')<
  OpenModalSchedulerEditPayload
>();
export function openModalSchedulerEdit(id: string) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();

    dispatch(
      openModalSchedulerEditAC({
        scheduler: state.entities.schedulers.byId[id],
        appUser: state.appUser,
        ...scopeSelector(state),
      })
    );
  };
}

interface OpenModalSchedulerDuplicatePayload extends ScopeData {
  scheduler: Scheduler;
  appUser: AppUser;
}
export const openModalSchedulerDuplicateAC = crAction('OPEN_MODAL_SCHEDULER_DUPLICATE')<
  OpenModalSchedulerDuplicatePayload
>();
export function openModalSchedulerDuplicate(id: string) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();

    dispatch(
      openModalSchedulerDuplicateAC({
        scheduler: state.entities.schedulers.byId[id],
        appUser: state.appUser,
        ...scopeSelector(state),
      })
    );
  };
}

export const closeModalScheduler = crAction('CLOSE_MODAL_EMAIL_SCHEDULER')();

export const openModalSchedulerPreviewAC = crAction('OPEN_MODAL_SCHEDULER_PREVIEW')<Scheduler>();
export function openModalSchedulerPreview(id: string) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();
    const scheduler = state.entities.schedulers.byId[id];
    dispatch(openModalSchedulerPreviewAC(scheduler));
  };
}

export const closeModalSchedulerPreview = crAction('CLOSE_MODAL_SCHEDULER_PREVIEW')();

interface PlainValue {
  field: string;
  value: any;
}
export const changeSchedulerFormValue = crAction('CHANGE_SCHEDULER_FORM_PLAIN_VALUE')<PlainValue>();

export const changeSchedulerFormStartDate = crAction('CHANGE_SCHEDULER_FORM_START_DATE')<Date>();

export const changeSchedulerFormStartTime = crAction('CHANGE_SCHEDULER_FORM_START_TIME')<Date>();

export const changeSchedulerFormRepeat = crAction(
  'CHANGE_SCHEDULER_FORM_REPEAT'
)<Frequency | null>();

export const setStatusModalScheduler = crAction('SET_STATUS_MODAL_SCHEDULER')<MODAL_STATUS>();

export interface ChangeSchedulerFormScopePayload extends ScopeData {
  selectedNodes: TreeNodeProps[];
  userCustomerIds: string[];
  userSiteIds: Ids;
  userSystemIds: Ids;
}

export const changeSchedulerFormScopeAC = crAction('CHANGE_SCHEDULER_FORM_SCOPE')<
  ChangeSchedulerFormScopePayload
>();
export function changeSchedulerFormScope(selectedNodes: TreeNodeProps[]) {
  return function(dispatch: ThunkDispatch<Store, any, Action>, getState: () => Store) {
    const state: Store = getState();
    const { userOrgs, userSites, userSystems } = state.ui.schedulerForm;
    const { customerIds, siteIds, systemIds } = state.appUser;

    dispatch(
      changeSchedulerFormScopeAC({
        selectedNodes,
        // ...scopeSelector(state),
        customers: userOrgs!,
        sites: userSites!,
        systems: userSystems!,
        userCustomerIds: customerIds!,
        userSiteIds: siteIds!,
        userSystemIds: systemIds!,
      })
    );
  };
}
