import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableHead, TableHeadRow, TableHeadCell, TableBody } from '../_common/Table';
import UsersTableRow from './UsersTableRowContainer';
import LinearProgress from '../_common/LinearProgress';

import { UsersTableProps } from './UsersTableContainer';

const AvatarHeadCell = styled(TableHeadCell)`
  padding-right: 37px;
  width: 83px;
  min-width: 83px;
`;
const ButtonsHeadCell = styled(TableHeadCell)`
  text-align: right;
  width: 121px;
  min-width: 121px;

  svg {
    color: #7f8fa4;
  }

  & > * {
    display: inline-block;
  }
`;
const NameHeadCell = styled(TableHeadCell)`
  width: 130px;
  min-width: 130px;
  @media (max-width: 840px) {
    flex-grow: 1;
  }
  @media (max-width: 350px) {
    min-width: 90px;
    font-size: 14px;
  }
`;
const LastNameHeadCell = styled(TableHeadCell)`
  width: 130px;
  min-width: 130px;
  @media (max-width: 595px) {
    display: none;
  }
`;
const ScopeHeadCell = styled(TableHeadCell)`
  flex-grow: 1;
  @media (max-width: 840px) {
    display: none;
  }
`;
const GoalsHeadCell = styled(TableHeadCell)`
  width: 90px;
  min-width: 90px;
  text-align: center;
  @media (max-width: 455px) {
    display: none;
  }
`;

// const IconButton = styled(MuiIconButton)`
//     margin: 0 5px;
//     padding: 0;
// `;

const UsersTable = React.memo((props: UsersTableProps) => {
  const { t } = useTranslation();
  const { isLoading, userIds } = props;

  const tableBodyRef = useRef(null);

  return (
    <Paper>
      {!isLoading && (
        <Table>
          <TableHead>
            <TableHeadRow>
              {/*
                            <CheckboxHeadCell style={{ paddingBottom: '7px' }}>
                                <Checkbox
                                    checked={isAllRowsSelected}
                                    onClick={() => toggleUserTableAll(userIds)}
                                />
                            </CheckboxHeadCell>
                            */}
              <AvatarHeadCell />
              <NameHeadCell>{t('usersTable.name')}</NameHeadCell>
              <LastNameHeadCell>{t('usersTable.lastName')}</LastNameHeadCell>
              <ScopeHeadCell>{t('usersTable.scope')}</ScopeHeadCell>
              <GoalsHeadCell>{t('usersTable.goals')}</GoalsHeadCell>
              <ButtonsHeadCell></ButtonsHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody ref={tableBodyRef}>
            {userIds.map((id: string) => (
              <UsersTableRow key={id} userId={id} />
            ))}
          </TableBody>
        </Table>
      )}

      {isLoading && <LinearProgress style={{ width: '100%' }} />}
    </Paper>
  );
});

export default UsersTable;
