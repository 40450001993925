import React from 'react';
import { StyledComponentWithAnyType } from 'store/storeTypes';
import styled from 'styled-components/macro';
import { Weekdays } from '../../models/Scheduler';

interface WeekdayContainerProps {
  day: string;
}
const WeekdayContainer: StyledComponentWithAnyType = styled.label.attrs(
  (props: WeekdayContainerProps) => ({
    htmlFor: props.day,
  })
)`
  display: block;
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #7f8fa4;
  text-align: center;
  line-height: 25px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #7f8fa4;
  cursor: pointer;
  &.checked {
    background: #2ea1f8;
    border-color: transparent;
    color: #fff;
  }
  &.error {
    border: 1px solid red;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
  input {
    position: absolute;
    opacity: 0;
  }
  @media (max-width: 599px) {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
`;

interface WeekdayProps {
  day: string;
  index: number;
  isChecked: boolean;
  setChecked: (index: number, checked: boolean) => void;
  disabled?: boolean;
  error?: boolean;
}

const Weekday = React.memo((props: WeekdayProps) => {
  const { day, index, disabled, error } = props;
  const letter = day.substr(0, 1);
  const { isChecked, setChecked } = props;

  return (
    <WeekdayContainer day={day} className={error ? 'error' : isChecked ? 'checked' : ''}>
      {letter}
      <input
        type="checkbox"
        name="weekdays[]"
        id={day}
        value={index}
        checked={isChecked}
        disabled={disabled}
        onChange={() => setChecked(index, !isChecked)}
      />
    </WeekdayContainer>
  );
});

const WeekdaysSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  &.disabled {
    opacity: 0.25;

    label {
      cursor: auto;
    }
  }

  @media (max-width: 599px) {
    margin-top: 8px;
  }
`;

interface WeekdaysSelectorProps {
  value: Weekdays[];
  onChange: (value: Weekdays[]) => void;
  error?: boolean;
  disabled?: boolean;
}

const WeekdaysSelector = React.memo((props: WeekdaysSelectorProps) => {
  const { value, onChange, disabled, error } = props;
  const wDays = Object.keys(Weekdays).filter(k => typeof Weekdays[k] === 'number');

  const setChecked = (index: number, checked: boolean) => {
    if (disabled) return;

    const inArray = value.indexOf(index);

    if (checked && inArray < 0) {
      value.push(index);
    }
    if (!checked && inArray >= 0) {
      value.splice(inArray, 1);
    }

    onChange(value);
  };

  return (
    <WeekdaysSelectorContainer className={disabled ? 'disabled' : ''}>
      {wDays.map((d, i) => (
        <Weekday
          day={d}
          index={i}
          key={d + i}
          isChecked={value.indexOf(i) > -1}
          setChecked={setChecked}
          error={error}
        />
      ))}
    </WeekdaysSelectorContainer>
  );
});

export default WeekdaysSelector;
