import {
  ReportedSystemsResponseByGroup,
  ReportedSystemsUnit,
  UserSystemGroup,
} from './../store/storeTypes';
import { createSelector } from 'reselect';
import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../actions';
import { systemGroupSelector } from 'reducers/ui/systemGroupUiReducer';
import { IsEverReportedStateTypes, Store } from 'store/storeTypes';

const INIT_STATE = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  data: { DTG: [], DTF: [], Apollo: [] },
};

const { getEmptyStateAC } = actions.reportFilter;

const reducer = createReducer<IsEverReportedStateTypes, RootAction>(INIT_STATE)
  .handleAction(getEmptyStateAC.request, (state, action) => {
    return { ...state, isLoading: true, isLoaded: false, isError: false, data: INIT_STATE.data };
  })
  .handleAction(getEmptyStateAC.failure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      isError: true,
      data: INIT_STATE.data,
    };
  })
  .handleAction(getEmptyStateAC.success, (state, action) => {
    const { DTG, DTF, Apollo } = mapIsEverReportedData(action.payload);
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      isError: false,
      data: { DTG, DTF, Apollo },
    };
  });

export default reducer;
//*selectors
const isEverReportedSelector = (state: Store) => state?.isEverReported;
export const isEverReportedLoadingSelector = createSelector([isEverReportedSelector], data => {
  return data.isLoading;
});
export const isEverReportedLoadedSelector = createSelector([isEverReportedSelector], data => {
  {
    return data.isLoaded;
  }
});
export const isEverReportedBySystemGroupSelector = createSelector(
  [isEverReportedSelector, systemGroupSelector],
  (data, systemGroup) => {
    const result = data.data[systemGroup];
    return result;
  }
);
type IsEverReportedApiResponse = {
  reportedSystems: Array<ReportedSystemsByGroup>;
};

type ReportedSystemsByGroup = {
  systemGroup: UserSystemGroup;
  reportedSystemsData: Array<ReportedSystemsUnit>;
};

const mapIsEverReportedData = (
  resposne: IsEverReportedApiResponse
): ReportedSystemsResponseByGroup => {
  const result = { DTG: [], DTF: [], Apollo: [] };
  const { reportedSystems } = resposne;
  reportedSystems.forEach(
    reportedSystem => (result[reportedSystem.systemGroup] = reportedSystem.reportedSystemsData)
  );
  return result;
};
