import styled from 'styled-components/macro';
import CircularProgress from '@material-ui/core/CircularProgress';

const CircularSpinner = styled(CircularProgress)`
  size: 20;
  color: #2ea1f8;
  position: absolute;
`;

export default CircularSpinner;
