import FileDownload from 'js-file-download';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ISystemAnalysisRequest, ISystemAnalysisResponse } from '../models/SystemAnalysisData';
import { authService } from 'services/AuthProvider';
import { makeApiUrl } from 'actions/_shared/utils';
import apiAction from 'actions/_shared/apiAction';
import { getCustomSearchParameters } from 'helpers/date';

import { KonnectAnalytics } from 'KonnectAnalytics/konnectAnalytics';
import { add, format } from 'date-fns';
import { TimeFrameEnum } from 'models/ReportData';
import { UserSystemGroup } from 'store/storeTypes';

export const getSystemAnalysisAC = createAsyncAction(
  'GET_SYSTEM_ANALYSIS_STARTED',
  'GET_SYSTEM_ANALYSIS_SUCCESS',
  'GET_SYSTEM_ANALYSIS_FAILED'
)<ISystemAnalysisRequest, ISystemAnalysisResponse, Error>();

export function getSystemAnalysis({
  day,
  week,
  custom,
  systemGroup,
  isSilent,
  isEverReportedSystems,
  systems,
}: ISystemAnalysisRequest) {
  const { isAuthenticated, idTokenPayload } = authService;
  const url = makeApiUrl('/api/reports/systems');
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: systemGroup === UserSystemGroup.Apollo ? 'POST' : 'GET',
        params: {
          day,
          week,
          custom: customTimeframeParameters,
          systemGroup,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(getSystemAnalysisAC.failure(error)),
        onStarted: dispatch => {
          dispatch(getSystemAnalysisAC.request({ isSilent }));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getSystemAnalysisAC.success({ ...data, isEverReportedSystems }));
        },
      },
    });
  }
}

export const getSystemAnalysisNickNamesAC = createAsyncAction(
  'GET_SYSTEM_ANALYSIS_NICK_NAMES_STARTED',
  'GET_SYSTEM_ANALYSIS_NICK_NAMES_SUCCESS',
  'GET_SYSTEM_ANALYSIS_NICK_NAMES_FAILED'
)();
export function getSystemAnalysisNickNames({ isSilent, systems, systemGroup }) {
  const { isAuthenticated, idTokenPayload } = authService;
  const url = makeApiUrl('/api/system-data/systems-name');
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
        method: 'GET',
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(getSystemAnalysisNickNamesAC.failure(error));
        },
        onStarted: dispatch => {
          dispatch(getSystemAnalysisNickNamesAC.request({ isSilent }));
        },
        onSuccess: (data, dispatch) => {
          dispatch(getSystemAnalysisNickNamesAC.success(data.nicknameResponse));
        },
      },
    });
  }
}

export const changeNickNameAC = createAsyncAction(
  'CHANGE_NICKNAME_STARTED',
  'CHANGE_NICKNAME_SUCCESS',
  'CHANGE_NICKNAME_FAILED'
)();
export function changeNickName({ serialNumber, nickName, selectedSystemGroup }) {
  const { isAuthenticated, idTokenPayload } = authService;
  const url = makeApiUrl('/api/system-data/system-name');
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    return apiAction({
      request: {
        url,
        method: 'POST',
        data: {
          serialNumber,
          nickName,
        },
      },
      logic: {
        onFailed: (error, dispatch) => dispatch(changeNickNameAC.failure(error)),
        onStarted: dispatch => dispatch(changeNickNameAC.request({ isLoading: true })),
        onSuccess: (data, dispatch) =>
          dispatch(changeNickNameAC.success({ data, selectedSystemGroup })),
      },
    });
  }
}
export const setInitialValueToChangeNameField = createStandardAction(
  'SET_INITIAL_VALUE_TO_CHANGE_NAME_FIELD'
)<{ systemName: string }>();
export const setSelectedSystemName = createStandardAction('SET_SELECTED_SYSTEM_NAME')<string>();
export const setSortByHeaderCell = createStandardAction('SET_SORT_BY_HEADER_CELL_NAME')<any>();

//* Download System Analysis Report

export const downloadSystemAnalysisReportAC = createAsyncAction(
  'DOWNLOAD_SYSTEM_ANALYSIS_REPORT_STARTED',
  'DOWNLOAD_SYSTEM_ANALYSIS_REPORT_SUCCESS',
  'DOWNLOAD_SYSTEM_ANALYSIS_REPORT_FAILED'
)<any, any, any>();

export const downloadSystemAnalysisReport = ({
  week,
  day,
  custom,
  systems,
  SystemGroupType,
}: any) => {
  const url = makeApiUrl('/api/job-analysis-report/errors-and-maintenance-file');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);
    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
          responseType: 'blob',
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(
            downloadSystemAnalysisReportAC.failure({
              selectedSystemGroup: SystemGroupType,
              name: error.name,
              message: 'something went wrong, try again later',
            })
          );
        },
        onStarted: dispatch => {
          dispatch(
            downloadSystemAnalysisReportAC.request({
              selectedSystemGroup: SystemGroupType,
            })
          );
        },
        onSuccess: async (data, dispatch, state, status) => {
          //* 2 cases - the file is too big and there is no file.
          const result =
            status === 204
              ? 'not downloaded, no content'
              : status === 205
              ? 'not downloaded, file is too big'
              : 'downloaded';

          KonnectAnalytics.downloadSystemAnalysisReport({
            result,
          });
          //download only in case of 200 status
          const timeframe = state.reports.filterState[SystemGroupType].timeframe;
          const dateStr = getFileName({
            date: state.reports.filterState[SystemGroupType].selectedDate,
            timeframe,
            customTimeframeParameters,
          });
          status === 200 && FileDownload(data, `Events_Error_Maintenance_${dateStr}.xlsx`);
          dispatch(
            downloadSystemAnalysisReportAC.success({ selectedSystemGroup: SystemGroupType, status })
          );
        },
      },
    });
  }
};
const getFileName = ({ date, timeframe, customTimeframeParameters }) => {
  if (timeframe === TimeFrameEnum.day) {
    const formattedDate = format(date, 'MMMM_d_yyyy');
    const finalString = formattedDate.replace(/\s+/g, '_');
    return finalString;
  }
  if (timeframe === TimeFrameEnum.week) {
    const formattedDate = format(date, 'MMMM_d_yyyy');
    const finalString = formattedDate.replace(/\s+/g, '_');
    const endDate = add(date, { days: 6 });
    const formattedEndDate = format(endDate, 'MMMM_d_yyyy');
    const finalEndDateString = formattedEndDate.replace(/\s+/g, '_');
    return `${finalString}-${finalEndDateString}`;
  }
  if (timeframe === TimeFrameEnum.custom) {
    return customTimeframeParameters.split('&')[0];
  } else {
    return '';
  }
};
//*Validating possibility of  Download System Analysis Report
export const checkSystemAnalysisReportValidToDownloadAC = createAsyncAction(
  'IS_SYSTEM_ANALYSIS_REPORT_VALID_TO_DOWNLOAD_STARTED',
  'IS_SYSTEM_ANALYSIS_REPORT_VALID_TO_DOWNLOAD_SUCCESS',
  'IS_SYSTEM_ANALYSIS_REPORT_VALID_TO_DOWNLOAD_FAILED'
)<any, any, any>();

export const checkSystemAnalysisReportValidToDownload = ({
  week,
  day,
  custom,
  systems,
  SystemGroupType,
}: any) => {
  const url = makeApiUrl('/api/job-analysis-report/check-errors-and-maintenance-file');
  const { isAuthenticated, idTokenPayload } = authService;
  if (isAuthenticated() && idTokenPayload && idTokenPayload.sub) {
    const customTimeframeParameters = getCustomSearchParameters(custom);

    return apiAction({
      request: {
        url,
        method: 'POST',
        params: {
          week,
          day,
          custom: customTimeframeParameters,
          SystemGroupType,
        },
        data: {
          systems,
        },
      },
      logic: {
        onFailed: (error, dispatch) => {
          dispatch(
            checkSystemAnalysisReportValidToDownloadAC.failure({
              selectedSystemGroup: SystemGroupType,
              name: error.name,
              message: 'something went wrong, try again later',
            })
          );
        },
        onStarted: dispatch => {
          dispatch(
            checkSystemAnalysisReportValidToDownloadAC.request({
              selectedSystemGroup: SystemGroupType,
            })
          );
        },
        onSuccess: (data, dispatch, state, status) => {
          dispatch(
            checkSystemAnalysisReportValidToDownloadAC.success({
              selectedSystemGroup: SystemGroupType,
              status,
            })
          );
        },
      },
    });
  }
};
