import { createReducer } from 'typesafe-actions';

import { actions, RootAction } from '../../actions';
import { GoalsData, GoalFromApi } from '../../models/GoalsData';
import { GoalsByTypes, UserSystemGroupStrings, Entity } from '../../store/storeTypes';

const { loadGoalsSuccess, saveGoalSuccess } = actions.goalApi;
const INIT_STATE: GoalsByTypes = {
  DTF: {
    allIds: [],
    byId: {},
  },
  DTG: {
    allIds: [],
    byId: {},
  },
};
const goalById = createReducer<GoalsByTypes, RootAction>(INIT_STATE).handleAction(
  [loadGoalsSuccess, saveGoalSuccess],
  (state, action) => {
    const goals: GoalsByTypes = {};
    Object.keys(action.payload).forEach((type: string) => {
      const goal: Entity<GoalsData> = {
        allIds: [],
        byId: {},
      };
      action.payload[type as UserSystemGroupStrings].forEach((goalFromApi: GoalFromApi) => {
        goal.byId[goalFromApi.goalPeriod] = transformToGoal(goalFromApi);
        goal.allIds.push(goalFromApi.goalPeriod.toString());
      });
      goals[type as UserSystemGroupStrings] = goal;
    });
    return goals;
  }
);

const transformToGoal = (goalFromApi: GoalFromApi) => {
  return {
    dateModified: goalFromApi.time,
    goalType: goalFromApi.goalPeriod,
    ...goalFromApi,
  };
};

export default goalById;
