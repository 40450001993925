import i18next from 'i18next';
import { createStandardAction } from 'typesafe-actions';

import { GoalType, GoalFromApiByType } from '../models/GoalsData';
import handleErrorAction from './_shared/handleErrorAction';
import { makeApiUrl } from './_shared/utils';
import apiAction, { apiActionSimple } from './_shared/apiAction';
import { enqueueNotification } from './uiActions';
import { getCurrentIsoDateTimeNoMilliAndOffset } from '../helpers/date';

export const loadGoalsStarted = createStandardAction('LOAD_GOALS_STARTED')();
export const loadGoalsSuccess = createStandardAction('LOAD_GOALS_SUCCESS')<GoalFromApiByType>();
export const loadGoalsFailed = createStandardAction('LOAD_GOALS_FAILED')<any>();

export const saveGoalStarted = createStandardAction('SAVE_GOAL_STARTED')();
export const saveGoalSuccess = createStandardAction('SAVE_GOAL_SUCCESS')<GoalFromApiByType>();
export const saveGoalFailed = createStandardAction('SAVE_GOAL_FAILED')<any>();

export function getGoalsData() {
  const goalsUrl = makeApiUrl('/api/data/goals');
  const { timeString, timezoneOffset } = getCurrentIsoDateTimeNoMilliAndOffset();
  return apiActionSimple({
    request: {
      url: `${goalsUrl}?time=${encodeURIComponent(timeString)}&timezoneOffset=${timezoneOffset}`,
    },
    actions: [loadGoalsStarted, loadGoalsSuccess, loadGoalsFailed],
    interceptor: state => state.api.isLoadingGoals,
  });
}

export function updateGoal(id: GoalType, value: string, selectedSystemGroup: string) {
  const { timeString, timezoneOffset } = getCurrentIsoDateTimeNoMilliAndOffset();
  const postGoalsUrl = makeApiUrl('/api/data/goals');
  return apiAction({
    request: {
      url: postGoalsUrl,
      method: 'POST',
      data: {
        goalValue: value,
        goalPeriod: Number(id),
        time: timeString,
        timezoneOffset,
        systemGroup: selectedSystemGroup,
      },
    },
    logic: {
      onStarted: dispatch => dispatch(saveGoalStarted()),

      onSuccess: (data, dispatch, store) => {
        dispatch(saveGoalSuccess(data));
        dispatch(
          enqueueNotification(i18next.t('notifications.goalSaved'), {
            variant: 'success',
          })
        );
      },

      onFailed: (error, dispatch) => {
        dispatch(handleErrorAction(error, `API: ${error.message}`, [saveGoalFailed]));
      },
    },
  });
}
