import { createStandardAction } from 'typesafe-actions';
import i18next from 'i18next';

import { MODAL_STATUS } from 'store/storeTypes';
import { Scheduler, ReportType } from 'models/Scheduler';

import apiAction, { apiActionSimple } from './_shared/apiAction';
import handleErrorAction from './_shared/handleErrorAction';
import { makeApiUrl } from './_shared/utils';
import { enqueueNotification } from './uiActions';
import { setStatusModalScheduler } from './schedulerUiActions';
import { schedulerFormToApi, toggleScheduler } from 'reducers/_sharedLogic/schedulerConversions';

export const loadSchedulersStarted = createStandardAction('LOAD_SCHEDULERS_STARTED')();
export const loadSchedulersSuccess = createStandardAction('LOAD_SCHEDULERS_SUCCESS')<Scheduler[]>();
export const loadSchedulersFailed = createStandardAction('LOAD_SCHEDULERS_FAILED')<any>();

export const loadSchedulerTypesStarted = createStandardAction('LOAD_SCHEDULER_TYPES_STARTED')();
export const loadSchedulerTypesSuccess = createStandardAction('LOAD_SCHEDULER_TYPES_SUCCESS')<
  ReportType[]
>();
export const loadSchedulerTypesFailed = createStandardAction('LOAD_SCHEDULER_TYPES_FAILED')<any>();

export const saveSchedulerStarted = createStandardAction('SAVE_SCHEDULER_STARTED')();
export const saveSchedulerSuccess = createStandardAction('SAVE_SCHEDULER_SUCCESS')<Scheduler>();
export const saveSchedulerFailed = createStandardAction('SAVE_SCHEDULER_FAILED')<any>();

export const deleteSchedulerStarted = createStandardAction('DELETE_SCHEDULER_STARTED')();
export const deleteSchedulerSuccess = createStandardAction('DELETE_SCHEDULER_SUCCESS')<string>();
export const deleteSchedulerFailed = createStandardAction('DELETE_SCHEDULER_FAILED')<any>();

export const toggleSchedulerAC = createStandardAction('TOGGLE_SCHEDULER')<string>();

export const toggleSchedulerSending = (id: string, sending: boolean) => {
  const postSchedulersUrl = makeApiUrl('/api/email-reports-scheduler/entries');

  return apiAction({
    request: {
      url: postSchedulersUrl,
      method: 'POST',
      data: store => toggleScheduler(id, store.entities.schedulers.byId, sending),
    },
    logic: {
      onStarted: dispatch => {
        dispatch(saveSchedulerStarted());
        dispatch(toggleSchedulerAC(id));
      },
      onSuccess: (data, dispatch, store) => {
        dispatch(saveSchedulerSuccess(data as Scheduler));
        dispatch(
          enqueueNotification(i18next.t('notifications.schedulerSaved'), { variant: 'success' })
        );
      },
      onFailed: (error, dispatch) => {
        dispatch(handleErrorAction(error, `API: ${error.message}`, [saveSchedulerFailed]));
      },
    },
  });
};

export const getSchedulers = () => {
  const getSchedulersUrl = makeApiUrl('/api/email-reports-scheduler/entries');
  return apiActionSimple({
    request: { url: getSchedulersUrl },
    actions: [loadSchedulersStarted, loadSchedulersSuccess, loadSchedulersFailed],
    interceptor: state =>
      state.api.isLoadingSchedulers || state.entities.schedulers.allIds.length > 0,
  });
};

export const saveScheduler = () => {
  const postSchedulersUrl = makeApiUrl('/api/email-reports-scheduler/entries');
  return apiAction({
    request: {
      url: postSchedulersUrl,
      method: 'POST',
      data: store =>
        schedulerFormToApi(store.ui.schedulerForm, store.appUser.id || '', store.appUser),
    },
    logic: {
      onStarted: dispatch => {
        dispatch(saveSchedulerStarted());
        dispatch(setStatusModalScheduler(MODAL_STATUS.SAVING));
      },

      onSuccess: (data, dispatch, store) => {
        dispatch(saveSchedulerSuccess(data as Scheduler));
        dispatch(setStatusModalScheduler(MODAL_STATUS.CLOSED));
        dispatch(
          enqueueNotification(i18next.t('notifications.schedulerSaved'), { variant: 'success' })
        );
      },

      onFailed: (error, dispatch) => {
        dispatch(setStatusModalScheduler(MODAL_STATUS.EDITING));
        dispatch(handleErrorAction(error, `API: ${error.message}`, [saveSchedulerFailed]));
      },
    },
  });
};

export const deleteScheduler = (id: string) => {
  const deleteSchedulersUrl = makeApiUrl('/api/email-reports-scheduler/entries');
  return apiAction({
    request: {
      url: `${deleteSchedulersUrl}/${id}`,
      method: 'DELETE',
    },
    logic: {
      onStarted: dispatch => dispatch(deleteSchedulerStarted()),

      onSuccess: (data, dispatch, store) => {
        dispatch(deleteSchedulerSuccess(id));

        dispatch(
          enqueueNotification(i18next.t('notifications.schedulerDeleted'), { variant: 'success' })
        );
      },

      onFailed: (error, dispatch) => dispatch(deleteSchedulerFailed(error)),
    },
  });
};

export const getSchedulersReportTypes = () => {
  const getSchedulersTypesUrl = makeApiUrl('/api/email-reports-scheduler/templates');
  return apiActionSimple({
    request: { url: getSchedulersTypesUrl },
    actions: [loadSchedulerTypesStarted, loadSchedulerTypesSuccess, loadSchedulerTypesFailed],
    interceptor: state => state.api.isLoadingSchedulerTypes || state.appUser.reportTypes.length > 0,
  });
};
