import React from 'react';
import styled from 'styled-components';

import { globalColors } from 'globalStyle';
import { notUndefiend, valueAsString } from 'helpers/conversions';
import { StatsData } from '../Card';
import { dashboardIcon } from '../Icons';
import { InnerCardContent, Spinner } from '../Styles';
import {
  OverviewTopCardsBottomRow,
  OverviewTopCardsDescription,
  OverviewTopCardsUnits,
  OverviewTopCardsUpperRowContent,
  OverviewTopCardsValue,
} from './DesktopTotalImpressionCard';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/TopCardsArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/TopCardsArrowDown.svg';
import { TimeFrameEnum } from 'models/ReportData';
import {
  OverviewTopCardNames,
  getDescriptionTextForCustomTimeframe,
  getTopCardArrowColor,
} from 'helpers/overviewUtils';
import { UserSystemGroup, UserSystemGroupStrings } from 'store/storeTypes';
import { CardProps } from './DesktopDtgApolloTopCards';

const LeftColumn = styled.div<{ selectedSystemGroup: UserSystemGroupStrings }>`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: ${({ selectedSystemGroup }) =>
    selectedSystemGroup === UserSystemGroup.Apollo ? '100%' : '50%'};
`;
const LeftColumnCardContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
const UpperRow = styled.div`
  display: flex;
  box-sizing: border-box;

  flex-direction: column;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #e6e9ed;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
  width: 40%;
  min-width: 140px;
  padding: 2%;
  background-color: ${globalColors.regular.background.greyCardBackround};
  border-radius: 8px;
`;
export const RigthColumnUpperRow = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${globalColors.regular.background.greyDevider};
`;
const RigthColumnBottomRow = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding-top: 5px;
`;
const RightColumArrowPlaceHolder = styled.div`
  display: flex;
  width: 20px;
  align-self: center;
`;
const RightColumnContent = styled.div`
  display: flex;
  margin-left: 5px;
  flex-direction: column;
`;
const RightColumnContentUpperRow = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${globalColors.regular.text.primary};
`;
const RightColumnContentBottomRow = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${globalColors.regular.text.grey};
`;

const DesktopAvarageProductionRateCard = (props: CardProps) => {
  const {
    stats,
    description,
    value,
    units,
    statsDescription,
    addedData,
    timeframe,
    customTimeframeProperties,
    selectedSystemGroup,
  } = props;
  const [isNegative, setIsNegative] = React.useState(false);
  React.useEffect(() => {
    if (notUndefiend([stats])) {
      if (stats! < 0) {
        setIsNegative(true);
      } else if (description === 'Average Availability' && value >= 0.999) {
        setIsNegative(false);
      } else {
        setIsNegative(false);
      }
    }
  }, [stats]); // eslint-disable-line react-hooks/exhaustive-deps

  const getArrowIcon = (addedData, row) => {
    const trend = addedData[row].trend;
    const description = addedData[row].description;
    if (trend === 0 || trend === undefined) {
      return <></>;
    }
    return trend > 0 ? (
      <ArrowUpIcon
        fill={getTopCardArrowColor({
          cardName: OverviewTopCardNames.averageProductionRate,
          trend,
          description,
        })}
      />
    ) : (
      <ArrowDownIcon
        fill={getTopCardArrowColor({
          cardName: OverviewTopCardNames.averageProductionRate,
          trend,
          description,
        })}
      />
    );
  };

  return notUndefiend([value]) ? (
    <InnerCardContent data-automationid="AvarageProductionRate Card">
      <LeftColumn selectedSystemGroup={selectedSystemGroup}>
        <LeftColumnCardContent>
          <UpperRow>
            <OverviewTopCardsUpperRowContent>
              {isNegative ? dashboardIcon.negative : dashboardIcon.positive}{' '}
              <OverviewTopCardsValue data-testid="avg-prod-rate-value">
                {valueAsString(value, false)}
              </OverviewTopCardsValue>
              <OverviewTopCardsUnits data-testid="avg-prod-rate-units">
                {units}
              </OverviewTopCardsUnits>
            </OverviewTopCardsUpperRowContent>
            <OverviewTopCardsDescription
              data-testid="avg-prod-rate-description"
              style={{ paddingTop: '2px' }}>
              {description}
            </OverviewTopCardsDescription>
          </UpperRow>
          <OverviewTopCardsBottomRow>
            {notUndefiend([stats]) && StatsData(stats!)}{' '}
            <div>
              {timeframe === TimeFrameEnum.custom
                ? getDescriptionTextForCustomTimeframe({ timeframe, customTimeframeProperties })
                : statsDescription}
            </div>
          </OverviewTopCardsBottomRow>
        </LeftColumnCardContent>
      </LeftColumn>
      {selectedSystemGroup !== UserSystemGroup.Apollo && (
        <RightColumn>
          <RigthColumnUpperRow>
            <RightColumArrowPlaceHolder>
              {getArrowIcon(addedData, 'upper')}
            </RightColumArrowPlaceHolder>
            <RightColumnContent>
              <RightColumnContentUpperRow>
                {valueAsString(addedData?.upper?.value)} {addedData.upper.unit}
              </RightColumnContentUpperRow>{' '}
              <RightColumnContentBottomRow>
                {addedData.upper.description}
              </RightColumnContentBottomRow>
            </RightColumnContent>
          </RigthColumnUpperRow>
          <RigthColumnBottomRow>
            <RightColumArrowPlaceHolder>
              {getArrowIcon(addedData, 'lower')}
            </RightColumArrowPlaceHolder>
            <RightColumnContent>
              <RightColumnContentUpperRow>
                {valueAsString(addedData?.lower?.value)} {addedData.lower.unit}
              </RightColumnContentUpperRow>{' '}
              <RightColumnContentBottomRow>
                {addedData.lower.description}
              </RightColumnContentBottomRow>
            </RightColumnContent>
          </RigthColumnBottomRow>
        </RightColumn>
      )}
    </InnerCardContent>
  ) : (
    Spinner(60)
  );
};
export default DesktopAvarageProductionRateCard;
