//*this is a helper function to convert the api data to the format we need for all graphs in dtf mode. we need it beacuse back end could not send data in consistent format for systemGroups. e.g - DTF.

import { addDays, format, getDate, getMonth, parse, startOfMonth } from 'date-fns';
type normalizeGraphDataProps = {
  records: Array<any>;
  day?: string;
  week?: string;
  month?: string;
  quarter?: string;
  year?: string;
};
//*helper function to normalize data for dtf

export const normalizeGraphData = ({
  records,
  day,
  week,
  month,
  quarter,
  year,
}: normalizeGraphDataProps) => {
  //*filtering out records with null colorName - could not be filtered on server side
  const filterColorNameNull = data => {
    // Iterate through each object in the array
    return data.map(item => {
      // Filter the "color" array to remove elements with null "colorName"
      item.color = item.color?.filter(colorItem => colorItem.colorName !== null);
      return item;
    });
  };
  const filteredRecords = filterColorNameNull(records);
  if (day) {
    filteredRecords.forEach(record => {
      const initialHour = record.date;
      const [shortDayName, monthName, dayNumber, year] = day.split(' ');
      const dayName = getFullNameOfDay(shortDayName);
      record['granularity'] = 'Hour';
      record['date'] = `${initialHour} ${dayName} ${dayNumber} ${monthName} ${year}`;
    });

    return filteredRecords;
  }
  if (week) {
    const [monthName, dayNumber, _, __, ___, ____, year] = week.split(' ');
    const startDate = new Date(`${monthName} ${dayNumber} ${year}`);
    filteredRecords.forEach((record, index) => {
      const currentDate = addDays(startDate, index);
      const fullDayName = format(currentDate, 'eeee');
      const dayNumber = getDate(currentDate);
      const monthName = format(currentDate, 'MMMM');
      const year = format(currentDate, 'yyyy');
      record['granularity'] = 'Day';
      record['date'] = `${fullDayName} ${dayNumber} ${monthName} ${year}`;
    });

    return filteredRecords;
  }
  if (month) {
    const [monthName, year] = month.split(',');
    const startDate = startOfMonth(new Date(`${monthName} ${year}`));
    filteredRecords.forEach((record, index) => {
      const currentDate = addDays(startDate, index);
      const fullDayName = format(currentDate, 'eeee');
      const dayNumber = getDate(currentDate);
      const monthName = format(currentDate, 'MMMM');
      const year = format(currentDate, 'yyyy');
      record['granularity'] = 'Day';
      record['date'] = `${fullDayName} ${dayNumber} ${monthName} ${year}`;
    });
    return filteredRecords;
  }
  if (quarter) {
    filteredRecords.forEach(record => {
      const [monthName, year] = record.date.split(',');
      const date = parse(monthName, 'MMMM', new Date());
      const monthNumber = (getMonth(date) + 1).toString();
      record['granularity'] = 'Month';
      record['date'] = `${year.trim()}${
        monthNumber.length === 1 ? `0${monthNumber}` : monthNumber
      }`;
    });
    return filteredRecords;
  }
  if (year) {
    filteredRecords.forEach(record => {
      const monthShorName = record.date;
      const date = parse(`${monthShorName} ${year}`, 'MMM yyyy', new Date());
      const monthNumber = (getMonth(date) + 1).toString();
      record['date'] = `${year}${monthNumber.length === 1 ? `0${monthNumber}` : monthNumber}`;
      record['granularity'] = 'Month';
    });
    return filteredRecords;
  }
};
function getFullNameOfDay(shortDay) {
  const parsedDate = parse(shortDay, 'EEE', new Date());
  const fullNameOfDay = format(parsedDate, 'eeee');
  return fullNameOfDay;
}
